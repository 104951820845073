import React, { useContext } from 'react';
import { Button, message, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getUrlWithFilters } from '../../../../utils/urlParams';
import TableList from '../../../table/TableList';
import FormPresetFilter from '../../../filters/FormPresetFilter';
import withUserCompany from '../../../hocs/withUserCompany';
import { deleteVacancyFormPresetHandler } from '../../../../actions/handlers';
import { VACANCY_FORM_PRESET } from '../../../../constants/urls';
import ActionsContext from '../../../../contexts/ActionsContext';
import { pathOr } from 'ramda';

const FormPresetsSettings = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);

    const getColumns = () => {
        return [
            {
                title: t('settings.name'),
                dataIndex: 'title',
                key: 'title',
                render: (title, item) =>
                    <span className='span-link' onClick={() => props.history.push(`/forms/formPresets/${item.id}`)}>
                        { title }
                    </span>
            },
            {
                title: t('vacancy.form.domains'),
                dataIndex: ['_relations', 'domains'],
                key: 'domains',
                className: 'domains-col',
                render: domains => domains && domains.map(domain => domain && (
                    <div key={domain.id}>
                        {domain.title} <a
                            className='domain-link'
                            target='_blank'
                            href={`http://${domain.name}`}
                            rel='noopener noreferrer'
                        >{domain.name}</a>
                    </div>
                )),
            },
            {
                title: t('vacancy.form.questionsAmount'),
                dataIndex: 'questions',
                key: 'questions',
                className: 'questions-amount-col',
                render: questions => {
                    const allQuestionsAmount = questions.reduce((res, cur) =>
                        res + (cur.type === 'composite' ? pathOr([], ['settings', 'questions'], cur).length : 1), 0);

                    return `${questions.length} (${allQuestionsAmount})`
                }
            },
            {
                key: 'links',
                render: item => (
                    <div>
                        <Link className='applicants-link' to={getUrlWithFilters('/applicants', { formPreset: item.id })}>{ t('vacancy.detail.applicants') }</Link>
                        <br />
                        <Link className='vacancies-link' to={getUrlWithFilters('/', { formPreset: item.id })}>{ t('vacancy.form.vacancies') }</Link>
                    </div>
                ),
            },
            {
                key: 'actions',
                dataIndex: 'id',
                width: 110,
                render: (id, { user }) => props.isUser && (props.userId !== user) ? null : (
                    <div className='section-buttons'>
                        <Button
                            icon={<EditOutlined />}
                            onClick={() => props.history.push(`/forms/formPresets/${id}`)}
                            className='form-edit-btn'
                        />
                        <Popconfirm
                            title={t('vacancy.questions.formPresetDeleteConfirm')}
                            okText={t('vacancy.form.yes')}
                            cancelText={t('vacancy.form.no')}
                            onConfirm={() => deleteVacancyFormPresetHandler(id).then(() => {
                                revalidateAction(VACANCY_FORM_PRESET.stringify());
                                message.success(t('vacancy.questions.formPresetSuccessDelete'));
                            }).catch(() => message.error(t('vacancy.questions.formPresetErrorDelete')))}
                            placement='left'>
                            <Button
                                type='danger'
                                icon={<DeleteOutlined />}
                            />
                        </Popconfirm>
                    </div>
                )
            }
        ];
    }

    const getButtons = () => {
        return <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => props.history.push('/forms/formPresets/add')}>
            { t('vacancy.questions.add') }
        </Button>
    }

    return <TableList
        tableLayout='auto'
        filterForm={FormPresetFilter}
        staticFilter={{
            user: null,
        }}
        sorting={{
            field: 'createdAt',
            order: 'desc',
        }}
        actionUrl={VACANCY_FORM_PRESET.stringify()}
        columns={getColumns()}
        buttons={getButtons()}
        relations={['domains']} />
}

export default withUserCompany(FormPresetsSettings);
