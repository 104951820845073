import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import styled from 'styled-components';

import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';
import { PhoneInput } from './formComponents/MaskedInput';
import Switch from './formComponents/Switch';
import ListenerField from './ListenerField';

const HeadGroup = styled.div`
    margin: 0 -15px 10px;
    background: #f5f4f4;
    color: #444c63;
    font-size: 15px;
    height: 54px;
    line-height: 54px;
    padding: 0 16px;
    font-weight: 500;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
`;

const ProfileForm = () => {
    const { t } = useTranslation();

    return <Fragment>
        <HeadGroup className="head-group">{ t('settings.main') }</HeadGroup>
        <Row gutter={10}>
            <Col sm={8} xs={24}>
                <Field
                    name='lastName'
                    component={Input}
                    label={t('profile.lastName')}/>
            </Col>
            <Col sm={8} xs={24}>
                <Field
                    name='firstName'
                    component={Input}
                    label={t('profile.firstName')} />
            </Col>
            <Col sm={8} xs={24}>
                <Field
                    name='middleName'
                    component={Input}
                    label={t('profile.middleName')}/>
            </Col>
        </Row>
        <HeadGroup className="head-group">{ t('applicant.contacts') }</HeadGroup>
        <Row gutter={10}>
            <Col sm={8} xs={24}>
                <Field
                    name='phone'
                    component={PhoneInput}
                    label={t('profile.phone')} />
            </Col>
            <Col sm={8} xs={24}>
                <Field
                    name='username'
                    component={Input}
                    disabled={true}
                    icon={<MailOutlined />}
                    label='Email' />
            </Col>
        </Row>
        <HeadGroup className="head-group">{ t('app.sidebar.settings') }</HeadGroup>
        <div className='profile-block-settings'>
            <Field
                name='settings.applicantNotificationsEnabled'
                component={Switch}
                label={t('profile.receiveEmailNotifications')} />
            <Field
                name='passwordChange'
                component={Switch}
                label={t('profile.passwordChange')} />
        </div>
        <ListenerField listenFieldName='passwordChange'>
            { ({ passwordChange }) => passwordChange ? (
                <Row gutter={10}>
                    <Col sm={8} xs={24}>
                        <Field
                            name='password'
                            component={Input}
                            icon={<LockOutlined />}
                            htmlType='password'
                            label={t('profile.newPassword')} />
                    </Col>
                    <Col sm={8} xs={24}>
                        <Field
                            name='passwordRepeat'
                            component={Input}
                            rows={10}
                            icon={<LockOutlined />}
                            htmlType='password'
                            label={t('profile.passwordRepeat')} />
                    </Col>
                </Row>
            ) : null}
        </ListenerField>
        <SubmitButton style={{ marginTop: 25 }}>{ t('form.save') }</SubmitButton>
    </Fragment>;
}

const validationSchema = ({ minPasswordLength }) => yup.object().shape({
    username: yup.string().nullable(),
    firstName: yup.string().nullable().required(),
    lastName: yup.string().nullable().required(),
    middleName: yup.string().nullable(),
    phone: yup.string().nullable().phone(),
    passwordChange: yup.bool().nullable(),
    password: yup.string()
        .when('passwordChange', {
            is: true,
            then: yup.string().nullable().min(minPasswordLength).required(),
            otherwise: yup.string().nullable(),
        }),
    passwordRepeat: yup.string()
        .when('passwordChange', {
            is: true,
            then: yup.string().nullable().match('password').required(),
            otherwise: yup.string().nullable(),
        }),
});

export default withTranslation()(withFormWrapper(ProfileForm, {
    autoComplete: 'off',
    validationSchema,
    mapPropsToValues: ({ user }) => user,
    mapBeforeSubmit: values => ({
        ...values,
        password: values.passwordChange  ? values.password : undefined,
    }),
}));
