import React, { useContext } from 'react';
import { Field } from 'react-final-form';
import { Col } from 'antd';
import { path, contains } from 'ramda';
import { useTranslation } from 'react-i18next';

import BaseFilter from './base/BaseFilter';
import Select from '../forms/formComponents/Select';
import withFilterForm from './base/withFilterForm';
import { ADMIN, enabledState } from '../../constants/roles';
import UserContext from '../../contexts/UserContext';
import CompanyCodeContext from '../../contexts/CompanyCodeContext';
import { COMPANY, USERS_ADMIN, JURISTIC_ENTITY } from '../../constants/urls';

export default withFilterForm(({ values }) => {
    const { user } = useContext(UserContext);
    const { companyCode } = useContext(CompanyCodeContext);
    const { t } = useTranslation();
    const showCompany = contains(ADMIN, user.roles) && !companyCode;

    return <BaseFilter
        actionUrl={USERS_ADMIN.stringify()}
        searchFieldName='name'
        searchFieldPlaceholder={t('userAdmin.user')}
        span={8}
        sorting={{
            field: 'createdAt',
            order: 'desc',
        }}
    >
        <Col className='text-left' span={8}>
            <Field
                name='enabled'
                component={Select}
                options={enabledState}
                placeholder={t('bot.subscribed')}
                namePath='value'
            />
        </Col>
        { showCompany &&
            <Col span={8}>
                <Field
                    name='company'
                    placeholder={t('userAdmin.company')}
                    component={Select}
                    actionUrl={COMPANY.stringify()}
                    searchKey='name'
                    namePath='name'
                    searchable
                    allowClear
                />
            </Col>
        }
        <Col span={8}>
            <Field
                name='juristicEntity'
                placeholder={t('company.juristicEntity')}
                component={Select}
                actionUrl={JURISTIC_ENTITY.stringify()}
                searchKey='title'
                namePath='title'
                relations={['company']}
                filter={{
                    company: values.company
                }}
                getNamePath={showCompany ? item => `${path(['title'], item) || ''} (${path(['_relations', 'company', 'name'], item) || '-'})` : null}
                searchable
                allowClear
            />
        </Col>
    </BaseFilter>;
});
