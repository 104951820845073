import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { highlightAuto } from 'highlight.js';
import 'highlight.js/styles/default.css';

import { DEEP_LINK_COPY_ERROR, DEEP_LINK_COPY_SUCCESS } from '../../constants/messages';
import { getWidgetLink } from '../../utils/botLink';
import usePrevious from '../../utils/usePrevious';

const WidgetLink = props => {
    const { t } = useTranslation();
    const [highlightedLink, setHighlightedLink] = useState('');

    const getLink = () => {
        const { username, code, vacancy, link } = props;

        return link || getWidgetLink(username, vacancy, code);
    }

    const { username, code, vacancy } = props;
    const widgetLink = getLink(username, vacancy, code);
    const prevWidgetLink = usePrevious(widgetLink);

    const setHighlighted = () => {
        setHighlightedLink(highlightAuto(widgetLink).value);
    }

    useEffect(() => {
        setHighlighted();
    }, []);

    useEffect(() => {
        if (widgetLink !== prevWidgetLink) {
            setHighlighted();
        }
    });

    const onCopy = (text, result) => {
        result ?
            message.success(t(DEEP_LINK_COPY_SUCCESS)) :
            message.error(t(DEEP_LINK_COPY_ERROR));
    }

    return (
        <CopyToClipboard text={widgetLink} onCopy={onCopy}>
            <div className='copy-block'>
                <code dangerouslySetInnerHTML={{ __html: highlightedLink }} />
                <CopyOutlined />
            </div>
        </CopyToClipboard>
    );
}

export default WidgetLink;
