import React, { useContext } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { QrcodeOutlined, PlusOutlined } from '@ant-design/icons';

import TableList from '../../table/TableList';
import { useTranslation } from 'react-i18next';
import { BANNER } from '../../../constants/urls';
import ModalsContext from '../../../contexts/ModalsContext';
import { OPEN_QR_BANNER_MODAL, OPEN_QR_BUILDER_MODAL } from '../../../constants/actionTypes';

const QrBanners = () => {
    const { t } = useTranslation();
    const modals = useContext(ModalsContext);

    const getColumns = () => {
        return [
            {
                title: t('banner.name'),
                key: 'name',
                dataIndex: 'name',
                render: (name, item) =>
                    <span className='span-link' onClick={() => modals.open(OPEN_QR_BANNER_MODAL, item)}>
                        { name }
                    </span>
            },
            {
                title: t('banner.unit'),
                key: 'unit',
                dataIndex: ['_relations', 'vacancyUnit', 'title']
            },
            {
                title: t('banner.vacancy'),
                key: 'vacancy',
                dataIndex: ['_relations', 'vacancy'],
                render: vacancy => vacancy && <Link to={`/vacancy/${vacancy.id}`}>{ vacancy.title }</Link>
            },
            {
                title: t('banner.banner'),
                key: 'banner',
                render: item => <Button
                    onClick={() => modals.open(OPEN_QR_BUILDER_MODAL, item)}
                    icon={<QrcodeOutlined />}
                    className='dropdown-qrcode-button'
                />
            }
        ];
    }

    const renderButtons = () => {
        return <Button
            icon={<PlusOutlined />}
            type='primary'
            onClick={() => modals.open(OPEN_QR_BUILDER_MODAL, { newItem: true })}>
            { t('banner.add') }
        </Button>;
    }

    return <TableList
        actionUrl={BANNER.stringify()}
        columns={getColumns()}
        buttons={renderButtons()}
        relations={['vacancy', 'vacancyUnit']} />;
}

export default QrBanners;
