import React, { useContext } from 'react';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { Select } from './Select';
import withFieldWrapper from '../../hocs/withFieldWrapper';
import ModalsContext from '../../../contexts/ModalsContext';
import { OPEN_FORM_SELECT_MODAL } from '../../../constants/actionTypes';

const FormSelectComponent = props => {
    const { t } = useTranslation();
    const modals = useContext(ModalsContext);

    const onChange = value => props.onChange(value);

    return (
        <Row className='button-select' gutter={10}>
            <Col xs={20}>
                <Select {...props} />
            </Col>
            <Col xs={4}>
                <Button onClick={() => modals.open(OPEN_FORM_SELECT_MODAL, {
                    onSelect: onChange,
                    value: props.input.value,
                    name: props.input.name,
                })}>
                    { t('form.choose') }
                </Button>
            </Col>
        </Row>
    );
}

FormSelectComponent.defaultProps = {
    type: 'text'
};

export default withFieldWrapper(FormSelectComponent);
