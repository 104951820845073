import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import { pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import Switch from '../formComponents/Switch';
import Editor from '../formComponents/Editor';
import ListenerField from '../ListenerField';

const DialogVacancyFields = props => {
    const { t } = useTranslation();
    const { vacancy, isMaster } = props;

    return <Fragment>
        { !isMaster &&
            <Field
                name='botPublished'
                component={Switch}
                label={t('vacancy.form.published')} />
        }
        <ListenerField listenFieldName='botPublished'>
            { values => (values.botPublished || isMaster) &&
                <Fragment>
                    <ListenerField listenFieldName='title'>
                        { ({ title }) => (
                            <Field
                                name='chatSettings.directOpenMessage'
                                component={Editor}
                                label={t('vacancy.form.directOpenMessage')}
                                placeholder={t('vacancy.placeholders.directOpenMessage', { vacancy: pathOr(title, ['title'], vacancy) || '(название вакансии)' })}
                            />
                        )}
                    </ListenerField>
                    <Field
                        name='chatSettings.greetingMessage'
                        component={Editor}
                        label={t('vacancy.form.greetingMessage')}
                        placeholder='vacancy.placeholders.greetingMessage'
                    />
                    <Field
                        name='chatSettings.farewellMessage'
                        component={Editor}
                        label={t('vacancy.form.farewellMessage')}
                        placeholder='vacancy.placeholders.farewellMessage'
                    />
                </Fragment>
            }
        </ListenerField>
    </Fragment>;
}

export default DialogVacancyFields;
