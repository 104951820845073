import React, { useEffect, useRef, useState } from 'react';
import ScrollBar from 'react-scrollbars-custom';
import ResizeObserver from 'resize-observer-polyfill';

const CustomScroll = props => {
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [height, setHeight] = useState(0);
    const [observe, setObserve] = useState(false);
    const scroll = useRef();
    let resizeObserver = null;

    const scrollToBottom = () => {
        setTimeout(() => scroll.current && scroll.current.scrollToBottom());
    }

    const onScroll = e => {
        const { onScroll } = props;

        onScroll && onScroll(e);
        setIsAtBottom(e.clientHeight + e.scrollTop === e.scrollHeight);
    }

    useEffect(() => {
        resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                if (isAtBottom && height !== entry.contentRect.height) {
                    setHeight(entry.contentRect.height);
                    scrollToBottom();
                }
            }
        });

        return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
        if (!observe) {
            resizeObserver.observe(scroll.current.contentElement.firstChild);
            setObserve(true);
            scrollToBottom();
        }
    }, [observe]);

    return <ScrollBar
        {...props}
        onScroll={onScroll}
        ref={scroll}>
        { props.children }
    </ScrollBar>
}

export default CustomScroll;
