import qs from 'qs';
import { prop } from 'ramda';

import { addDefaultHeader, removeDefaultHeader } from './http';

export function handleTokenChange(token, isUrl) {
    if (token) {
        !isUrl && localStorage.setItem('token', token);
        addDefaultHeader('Authorization', `Bearer ${token}`);
    } else {
        !isUrl && localStorage.removeItem('token');
        removeDefaultHeader('Authorization');
    }
}

export function getToken() {
    return getUrlToken() || localStorage.getItem('token');
}

export function getUrlToken() {
    return prop('authToken', qs.parse(window.location.search, { ignoreQueryPrefix: true }));
}
