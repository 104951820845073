import React, { Fragment, useContext, useState } from 'react';
import { Button, message, Popconfirm, Select, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, GlobalOutlined, PlusOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { all, equals, values, path, pathOr, filter, includes, assoc, append } from 'ramda';
import { useTranslation } from 'react-i18next';

import TableList from '../../../table/TableList';
import { getClassifiersTranslationStatus } from '../../../../utils/translation';
import { CLASSIFIERS } from '../../../../constants/urls';
import { deleteClassifierHandler, deleteClassifierOptionHandler } from '../../../../actions/handlers';
import ActionsContext from '../../../../contexts/ActionsContext';
import ModalsContext from '../../../../contexts/ModalsContext';
import { OPEN_CLASSIFIERS_LANGUAGE_MODAL, OPEN_CLASSIFIER_MODAL, OPEN_CLASSIFIER_OPTION_MODAL } from '../../../../constants/actionTypes';
import { AVAILABLE_LANGUAGES } from '../../../../constants/dictionary';

const Classifiers = () => {
    const { t } = useTranslation();
    const { revalidateAction, getAction } = useContext(ActionsContext);
    const modals = useContext(ModalsContext);
    const [language, setLanguage] = useState(['en', 'ua']);

    const renderLanguageButton = () => {
        const items = pathOr([], ['data', 'items'], getAction(CLASSIFIERS.stringify()));
        const translations = getClassifiersTranslationStatus(items);
        const isTransalated = all(equals(true))(values(translations));

        const getTooltip = () => (
            <div className='transalation-status'>
                <div className='transalation-status-item'>
                    <div className='language'>RU:</div>
                    <GlobalOutlined className={cx('status-icon', { translated: translations.ru })} />
                </div>
                <div className='transalation-status-item'>
                    <div className='language'>UA:</div>
                    <GlobalOutlined className={cx('status-icon', { translated: translations.ua })} />
                </div>
                <div className='transalation-status-item'>
                    <div className='language'>EN:</div>
                    <GlobalOutlined className={cx('status-icon', { translated: translations.en })} />
                </div>
            </div>
        );

        return (
            <Tooltip
                title={getTooltip()}
                align={{ offset: [0, 3] }}
            >
                <GlobalOutlined
                    key={isTransalated}
                    className={cx('language-button', {
                        translated: isTransalated,
                    })}
                    onClick={() => modals.open(OPEN_CLASSIFIERS_LANGUAGE_MODAL, { items })}
                />
            </Tooltip>
        );
    }

    const getLanguageColumn = index => {
        return {
            title: <div>
                { t('settings.chooseLanguage') }
                <Select
                    value={language[index]}
                    onChange={lang => {
                        const value = assoc(index, lang, language);
                        setLanguage(value);
                    }}
                    style={{ width: 90, marginLeft: 10 }}>
                    { filter(lang => lang === language[index] || !includes(lang, language), AVAILABLE_LANGUAGES).map(lang =>
                        <Select.Option key={`language-${lang}-${index}`} value={lang}>
                            { lang.toUpperCase() } <span className={`flag flag-${lang}`} />
                        </Select.Option>
                    )}
                </Select>
            </div>,
            key: 'translations',
            render: item => item.add ? {
                props: { colSpan: 0 }
            } : path(['translations', language[index]], item)
        };
    }

    const openClassifierOptionModal = (item, classifier) => {
        modals.open(OPEN_CLASSIFIER_OPTION_MODAL, { classifier: item.classifier || classifier, item });
    }

    const getColumns = () => {
        return [
            {
                title: t('settings.classifierName'),
                dataIndex: 'name',
                key: 'name',
                render: (title, item) => {
                    return item.add ? {
                        children: <Button
                            className='add-section-btn'
                            style={{ marginLeft: 10 }}
                            icon={<PlusOutlined />}
                            onClick={() => openClassifierOptionModal({ level: item.lavel, parent: item.parent, classifier: item.classifier })}>
                            { t('settings.addClassifier') }
                        </Button>,
                        props: {
                            colSpan: 4,
                            className: 'add-section-column'
                        }
                    } : (
                        <span>
                            { title }
                        </span>
                    );
                },
            },
            getLanguageColumn(0),
            getLanguageColumn(1),
            {
                key: 'actions',
                width: 138,
                render: (item, _, index) => {
                    return item.add ? {
                        props: { colSpan: 0 }
                    } : (
                        <div className='classifier-section-buttons'>
                            <Tooltip placement="top" title={(
                                <div>
                                    <div><b>EN:</b> { path(['translations', 'en'], item)}</div>
                                    <div><b>UA:</b> { path(['translations', 'ua'], item)}</div>
                                </div>
                            )}>
                                <Button
                                    id={`edit-${item.classifier ? 'section' : 'item'}-btn-${index}`}
                                    icon={<EditOutlined />}
                                    onClick={() => item.classifier ? openClassifierOptionModal(item) : modals.open(OPEN_CLASSIFIER_MODAL, { item })}
                                />
                            </Tooltip>
                            <Popconfirm
                                title={t('settings.deleteClassifierConfirm1')}
                                okText={t('settings.yes')}
                                cancelText={t('settings.no')}
                                onConfirm={() => item.classifier ?
                                    deleteClassifierOptionHandler({
                                        classifier: item.classifier,
                                        optionId: item.id,
                                    }).then(() => {
                                        message.success(t('settings.deleteClassifierSectionSuccess'));
                                        revalidateAction(CLASSIFIERS.stringify());
                                    }).catch(() => message.success(t('settings.deleteClassifierSectionError'))) :
                                    deleteClassifierHandler(item.id).then(() => {
                                        message.success(t('settings.classifierDeleteSuccess'));
                                        revalidateAction(CLASSIFIERS.stringify());
                                    }).catch(() => message.error(t('settings.classifierDeleteError')))
                                }
                                placement='left'
                            >
                                <Button
                                    id={`delete-${item.classifier ? 'section' : 'item'}-btn-${index}`}
                                    type='danger'
                                    icon={<DeleteOutlined />}
                                />
                            </Popconfirm>
                        </div>
                    );
                }
            }
        ];
    };

    const getButtons = () => (
        <Fragment>
            { renderLanguageButton() }
            <Button
                id='add-item-btn'
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => modals.open(OPEN_CLASSIFIER_MODAL)}
            >
                { t('settings.addClassifier1') }
            </Button>
        </Fragment>
    );

    const parseItems = data => {
        const getChildren = (item, level, options) => {
            const children = append(
                { id: `add-btn-${item.id}`, add: true, level: level + 1, parent: item.id, classifier: item.classifier },
                filter(child => child.level === level && child.parent === item.id, options).map(child => getChildren(child, level + 1, options))
            );

            return {
                ...item,
                children
            };
        };

        return data.map(item => {
            const options = pathOr([], ['_relations', 'options'], item);
            const children = append(
                { id: `add-btn-${item.id}`, add: true, level: 1, classifier: item.id },
                filter(child => child.level === 0, options).map(child => getChildren(child, 1, options))
            );

            return {
                ...item,
                children
            };
        });
    }

    return (
        <TableList
            className='classifier-table'
            actionUrl={CLASSIFIERS.stringify()}
            parseItems={parseItems}
            headers={{
                'Accept-Language': 'ru',
            }}
            columns={getColumns()}
            buttons={getButtons()}
            relations={['options']}
            multipleExpanded
        />
    );
}

export default Classifiers;
