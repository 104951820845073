import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import ClassifierOptionForm from '../../../forms/ClassifierOptionForm';
import { postClassifierOptionHandler, putClassifierOptionHandler } from '../../../../actions/handlers';
import { CLASSIFIERS, CLASSIFIERS_OPTIONS } from '../../../../constants/urls';
import ActionsContext from '../../../../contexts/ActionsContext';
import useCompanyCodeSwr from '../../../../utils/useCompanyCodeSwr';

const SectionModal = props => {
    const { t } = useTranslation();
    const { modal, params: { item, classifier }, close } = props;
    const classifierOption = useCompanyCodeSwr(CLASSIFIERS_OPTIONS.stringify({ classifier }));
    const { revalidateAction } = useContext(ActionsContext);

    return (
        <Modal
            {...modal}
            title={item.id ? t('settings.editClassifier') : t('settings.addClassifier')}
            footer={null}
        >
            <ClassifierOptionForm
                formAction={item.id ? putClassifierOptionHandler : postClassifierOptionHandler}
                item={item}
                classifier={classifier}
                onSuccess={() => {
                    message.success(t(item.id ? 'settings.classifierSaveSuccess' : 'settings.classifierAddSuccess'));
                    revalidateAction(CLASSIFIERS.stringify());
                    classifierOption.mutate();
                    close();
                }}
                onSubmitFail={() => message.error(t(item.id ? 'settings.classifierSaveError' : 'settings.classifierAddError'))}
            />
        </Modal>
    );
}

export default SectionModal;
