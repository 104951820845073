import React, { Fragment, useContext } from 'react';
import { without, pathOr } from 'ramda';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import ClassifiersView from '../../views/vacancy/ClassifiersView';
import { CLASSIFIERS } from '../../../constants/urls';
import ModalsContext from '../../../contexts/ModalsContext';
import { OPEN_SELECT_CLASSIFIERS_MODAL } from '../../../constants/actionTypes';
import { mapClassifiers } from '../vacancyForm/VacancyForm';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const Classifiers = props => {
    const { t } = useTranslation();
    const modals = useContext(ModalsContext);

    const removeTreeNode = (node) => {
        const isParent = !!node.children;
        const { input: { value = [], onChange } } = props;

        if (isParent) {
            const children = node.children || [];
            const keys = children.map(({ key }) => key);

            onChange(without(keys, value));
        } else {
            onChange(without([node.key], value));
        }
    }

    const { input: { value = [], onChange }, classifiers } = props;
    const items = pathOr([], ['data', 'items'], classifiers);
    const selectedClassifiers = mapClassifiers(value, items);

    return classifiers.data ? (
        <Fragment>
            { classifiers.data &&
                <Fragment>
                    { !!items.length &&
                        <ClassifiersView
                            selectedClassifiers={selectedClassifiers}
                            onRemove={removeTreeNode}
                        />
                    }
                    <span
                        className='span-link classifier-edit'
                        style={{ marginBottom: 15 }}
                        onClick={() => modals.open(OPEN_SELECT_CLASSIFIERS_MODAL, {
                            value: selectedClassifiers,
                            items,
                            onChange
                        })}>
                        { value.length ? t('form.changeClassifiers') : t('form.selectClassifiers') }
                    </span>
                </Fragment>
            }
        </Fragment>
    ) : classifiers.isValidating ? <Spin /> : null;
}

export const withClassifiers = WrappedComponent => props => {
    const classifiers = useCompanyCodeSwr(CLASSIFIERS.stringify(), url => axios.get(url, {
        params: {
            relations: ['options']
        }
    }));

    return <WrappedComponent {...props} classifiers={classifiers} />;
}

export default withClassifiers(withFieldWrapper(Classifiers));
