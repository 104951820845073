import React from 'react';
import { Field } from 'react-final-form';
import { Button, Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';

import withFilterForm from './base/withFilterForm';
import RangeDatePicker from '../forms/formComponents/RangeDatePicker';

export default withTranslation()(withFilterForm(({ t, setLocation }) => (
    <Row gutter={5}>
        <Col className='text-left' span={10}>
            <Field
                name='applicantsPeriodFrom'
                component={RangeDatePicker}
                toPath='applicantsPeriodTo'
                picker='month'
            />
        </Col>
        <Col span={2}>
            <Button type='primary' onClick={() => {
                setLocation();
            }}>
                { t('reports.generate') }
            </Button>
        </Col>
    </Row>
)));
