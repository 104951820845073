import React from 'react';
import { Button, Modal } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import LandingLink from './LandingLink';

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
    }

    tr th:first-child,
    tr td:first-child {
        padding-left: 24px;
    }
`;

const LandingLinkModal = props => {
    const { t } = useTranslation();

    const renderFooter = () => (
        <Button onClick={() => props.close()}>
            OK
        </Button>
    );

    const { modal, params } = props;

    return <StyledModal
        {...modal}
        className='landing-link-modal'
        title={t('landing.landings')}
        footer={renderFooter()}
        width={900}
        destroyOnClose>
        <LandingLink vacancy={params} />
    </StyledModal>;
}

export default LandingLinkModal;
