import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import UserAdminForm from '../../forms/UserAdminForm';
import UserContext from '../../../contexts/UserContext';
import CompanyCodeContext from '../../../contexts/CompanyCodeContext';
import { postUserAdminHandler, putUserAdminHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { CHANGE_LOG, USERS_ADMIN } from '../../../constants/urls';
import withUserCompany from '../../hocs/withUserCompany';

const UserAdminModal = props => {
    const { user } = useContext(UserContext);
    const { revalidateAction } = useContext(ActionsContext);
    const { companyCode } = useContext(CompanyCodeContext);
    const { t } = useTranslation();
    const { modal, params, close, isAdmin, isManager } = props;

    const isCurrentUser = prop('id', props.params.user) === user.id;

    const onPostSuccess = () => {
        revalidateAction(USERS_ADMIN.stringify());
        message.success(t('userAdmin.userAddSuccess'));
        close();
    };

    const onPutSuccess = () => {
        if (isCurrentUser) {
            user.load();
        }

        revalidateAction(USERS_ADMIN.stringify());
        revalidateAction(CHANGE_LOG.stringify());
        message.success(t('userAdmin.userEditSuccess'));
        close();
    };

    return <Modal
        {...modal}
        title={params.user ? t('userAdmin.editUser') : t('userAdmin.addUser')}
        footer={null}>
        <UserAdminForm
            formAction={params.user ? putUserAdminHandler : postUserAdminHandler}
            user={params.user}
            hasCompany={params.hasCompany}
            isAdmin={isAdmin || companyCode}
            isManager={isManager}
            company={user.company}
            isFullLicense={params.isFullLicense}
            isCurrentUser={isCurrentUser}
            onSuccess={params.user ? onPutSuccess : onPostSuccess}
        />
    </Modal>;
}

export default withUserCompany(UserAdminModal);
