import React, { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { assoc, dissoc, forEach, isEmpty, startsWith, filter, keys, path } from 'ramda';
import { Switch, Route, useHistory } from 'react-router-dom';
import { YMaps } from 'react-yandex-maps';
import { useTranslation } from 'react-i18next';

import { ExperiumUserRoutes } from '../UserRoutes';
import UserLayout, { UserLayoutContent } from './user/UserLayout';
import GuestLayout from './guest/GuestLayout';
import UserContext from '../../contexts/UserContext';
import { USER } from '../../constants/urls';
import ERRORS, { PASSWORD_EXPIRED } from '../../constants/errors';
import { getToken, handleTokenChange, getUrlToken } from '../../utils/token';
import CompanyCodeContext from '../../contexts/CompanyCodeContext';
import { addDefaultHeader } from '../../utils/http';
import axios from 'axios';
import { checkMultiCompany, getCompanyCode, handleCompanyCodeChange } from '../../utils/companyCode';
import ActionsContext from '../../contexts/ActionsContext';

const checkUser = (user, loading) => {
    if (loading && !user) {
        return null;
    }

    return !!user && !isEmpty(user);
};

const SecureLayout = props => {
    const history = useHistory();
    const { i18n } = useTranslation();
    const [user, setUser] = useState(null);
    const [userError, setUserError] = useState(null);
    const [userLoading, setUserLoading] = useState(false);
    const [companyCode, changeCompanyCode] = useState(null);
    const [actions, setActions] = useState({});
    const [onSuccessFn, setOnSuccessFn] = useState({});
    const didMount = useRef(null);
    const isAuthenticated = checkUser(user, userLoading);
    const onChangeCompanyCode = code => {
        handleCompanyCodeChange(code);
        changeCompanyCode(code);
    }

    const loadUser = () => {
        setUserLoading(true);
        setUserError(null);

        axios.get(USER.stringify(), {
            params: {
                relations: ['company', 'companies']
            }
        }).then(data => {
            setUserLoading(false);
            if (checkMultiCompany(data)) {
                onChangeCompanyCode(getCompanyCode());
            }
            setUser(data);
        }).catch(e => {
            const error = e.data;
            if (ERRORS[path(['message'], error) || error] === PASSWORD_EXPIRED) {
                setUserLoading(false);
                history.push('/password-expired');
            } else {
                setUserLoading();
                setUserError(error);
                onChangeCompanyCode(null);
                handleTokenChange(null);
            }
        });
    }

    const resetUser = () => setUser(null);

    const addAction = (name, fn) => setActions(actions => assoc(name, fn, actions));
    const removeAction = name => setActions(actions => dissoc(name, actions));
    const revalidateAction = name => {
        const actionKeys = filter(key => startsWith(name, key), keys(actions));
        forEach(key => actions[key].mutate(), actionKeys);
    }
    const getAction = name => {
        const filteredActions = filter(key => startsWith(name, key), keys(actions));
        return filteredActions.length < 2 ? actions[path([0], filteredActions)] : filteredActions.map(key => actions[key]);
    }

    const addOnSuccessFn = (name, fn) => setOnSuccessFn(assoc(name, fn, onSuccessFn));
    const removeOnSuccessFn = name => setOnSuccessFn(dissoc(name, onSuccessFn));
    const getOnSuccessFn = (name, props) => onSuccessFn[name] && onSuccessFn[name](props);

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;

            addDefaultHeader('Accept-Language', i18n.language || 'ru');
            const accessToken = getToken();

            if (accessToken) {
                handleTokenChange(accessToken, !!getUrlToken());
                loadUser();
            }
        }
    });

    if (isAuthenticated === null) {
        return (
            <div className='auth-spin-wrapper'>
                <Spin />
            </div>
        );
    }

    return <ActionsContext.Provider value={{
        actions,
        addAction,
        removeAction,
        revalidateAction,
        addOnSuccessFn,
        removeOnSuccessFn,
        onSuccessFn: getOnSuccessFn,
        getAction
    }}>
        <UserContext.Provider value={{
            user,
            loading: userLoading,
            error: userError,
            load: loadUser,
            reset: resetUser
        }}>
            <CompanyCodeContext.Provider value={{ companyCode, changeCompanyCode: onChangeCompanyCode }}>
                { isAuthenticated ? (
                    <YMaps query={{ apikey: process.env.REACT_APP_YMAPS_TOKEN, coordorder: 'longlat' }}>
                        <Switch>
                            <Route path='/experium'>
                                <UserLayoutContent>
                                    <ExperiumUserRoutes {...props} />
                                </UserLayoutContent>
                            </Route>
                            <Route>
                                <UserLayout {...props} />
                            </Route>
                        </Switch>
                    </YMaps>
                ) : <GuestLayout {...props} />}
            </CompanyCodeContext.Provider>
        </UserContext.Provider>
    </ActionsContext.Provider>;
};

export default SecureLayout;
