import React from 'react';
import { filter, pathOr } from 'ramda';
import { Tree, Spin } from 'antd';
import styled from 'styled-components';

import { CLASSIFIERS } from '../../../constants/urls';
import axios from 'axios';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const StyledTree = styled(Tree)`
    .ant-tree-treenode:not(.ant-tree-treenode-checkbox-checked):not(.ant-tree-treenode-checkbox-indeterminate),
    .ant-tree-checkbox {
        display: none;
    }
`;

const ClassifiersView = props => {
    const classifiers = useCompanyCodeSwr(CLASSIFIERS.stringify(), url => axios.get(url, {
        params: {
            relations: ['options'],
        }
    }));

    const getSelectedTree = data => {
        const getChildren = (item, level, options) => {
            const children = filter(child => child.level === level && child.parent === item.id, options).map(child => getChildren(child, level + 1, options));

            return {
                title: item.name,
                key: item.id,
                children
            };
        };

        return filter(item => !!(item.children || []).length, data.map(item => {
            const options = pathOr([], ['_relations', 'options'], item);

            return {
                title: item.name,
                key: item.id,
                children: filter(child => child.level === 0, options).map(child => getChildren(child, 1, options))
            }
        }));
    }

    const { selectedClassifiers } = props;
    const items = pathOr([], ['data', 'items'], classifiers);
    const treeData = getSelectedTree(items);

    return !!classifiers.data && !!items.length ? (
        <StyledTree
            key={JSON.stringify(selectedClassifiers)}
            className='classifiers-view'
            treeData={treeData}
            selectable={false}
            defaultExpandAll={true}
            disabled
            checkedKeys={selectedClassifiers}
            checkable
        />
    ) : classifiers.isValidating ? <Spin /> : null;
}

export default ClassifiersView;
