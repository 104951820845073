import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Modal, List, Button, Popconfirm, Input, message } from 'antd';
import { without, pathOr, contains, path } from 'ramda';
import { Link } from 'react-router-dom';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { LINES_PER_PAGE } from '../../../constants/lists';
import { ACTIVE_ID } from '../../../constants/vacancy';
import { getVacanciesHandler, putLandingHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { LANDING } from '../../../constants/urls';
import { CHANGE_LANDING } from '../../../constants/actionTypes';

const { Search } = Input;

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 15px 24px;
    }
`;

const filter = {
    published: ACTIVE_ID
};

const LandingLinkVacanciesModal = props => {
    const { t } = useTranslation();
    const { revalidateAction, onSuccessFn } = useContext(ActionsContext);
    const [vacancies, setVacancies] = useState(props.params.vacancies || []);
    const [putPending, setPutPending] = useState(false);
    const [getVacancies, setGetVacancies] = useState(null);
    const [getVacanciesPending, setGetVacanciesPending] = useState(null);
    const didMount = useRef(null);

    const fetchVacancies = params => {
        setGetVacanciesPending(true);
        getVacanciesHandler(params).then(data => {
            setGetVacancies(data);
            setGetVacanciesPending(false);
        }).catch(() => setGetVacanciesPending(false))
    }

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;

            fetchVacancies({
                pagination: { offset: 0, limit: LINES_PER_PAGE },
                filter
            });
        }
    });

    const unlink = vacancyId => {
        const { params: { landing }} = props;
        const items = without([vacancyId], vacancies.map(({ id }) => id));

        setPutPending(true);
        putLandingHandler({
            ...landing,
            vacancies: items.length ? items : null
        }).then(data => {
            setPutPending(false);
            onSuccessFn(CHANGE_LANDING);
            revalidateAction(LANDING.stringify());
            setVacancies(pathOr([], ['_relations', 'vacancies'], data));
            props.params.setVacancies(pathOr([], ['_relations', 'vacancies'], data));
            message.success(t('landing.unlinkSuccess'));
        }).catch(() => {
            setPutPending(false);
        });
    }

    const link = (vacancyId) => {
        const { params: { landing }} = props;
        const items = vacancies.map(({ id }) => id);

        setPutPending(true);
        putLandingHandler({
            ...landing,
            vacancies: [...items, vacancyId]
        }).then(data => {
            setPutPending(false);
            onSuccessFn(CHANGE_LANDING);
            revalidateAction(LANDING.stringify());
            setVacancies(pathOr([], ['_relations', 'vacancies'], data));
            props.params.setVacancies(pathOr([], ['_relations', 'vacancies'], data));
            message.success(t('landing.linkSuccess'));
        }).catch(() => {
            setPutPending(false);
        });
    }

    const setOffset = (page, pageSize) => {
        const offset = (page - 1) * pageSize;

        fetchVacancies({
            pagination: {
                offset,
                limit: LINES_PER_PAGE,
            },
            filter
        });
    }

    const onSearch = data => {
        fetchVacancies({
            filter: {
                title: data,
                ...filter
            },
        });
    }

    const renderFooter = () => (
        <Button onClick={() => props.close()}>
            OK
        </Button>
    );

    const { modal } = props;
    const linkedVacanciesIds = vacancies.map(({ id }) => id);
    const pending = getVacanciesPending || putPending;

    return (
        <StyledModal
            {...modal}
            title={t('landing.linkVacancy')}
            footer={renderFooter()}
            destroyOnClose
        >
            <Search
                placeholder={t('landing.typeVacancyName')}
                enterButton={t('landing.search')}
                onSearch={onSearch}
                allowClear={true}
            />
            <List
                loading={pending}
                dataSource={pathOr([], ['items'], getVacancies)}
                locale={{
                    emptyText: t('landing.emptyVacanciesList')
                }}
                pagination={{
                    onChange: setOffset,
                    pageSize: LINES_PER_PAGE,
                    total: path(['_meta', 'count'], getVacancies),
                    hideOnSinglePage: true,
                    }}
                renderItem={item => {
                    const isLinked = contains(item.id, linkedVacanciesIds);

                    return (
                        <List.Item actions={[
                            <Fragment key='link-action'>
                                { isLinked ? (
                                    <Popconfirm
                                        title={t('landing.unlinkConfirm')}
                                        placement='topRight'
                                        onConfirm={() => unlink(item.id)}
                                        okText={t('landing.yes')}
                                        cancelText={t('landing.no')}
                                        disabled={pending}
                                    >
                                        <Button icon={<MinusOutlined />} shape='circle' />
                                    </Popconfirm>
                                ) : (
                                    <Button
                                        shape='circle'
                                        icon={<PlusOutlined />}
                                        onClick={() => link(item.id)}
                                        disabled={pending}
                                    />
                                )}
                            </Fragment>

                        ]}>
                            <Link to={`/vacancy/${item.id}`}>{ item.title }</Link>
                        </List.Item>
                    );
                }}
            />
        </StyledModal>
    );
}

export default LandingLinkVacanciesModal;
