import React, { useContext } from 'react';
import { Avatar } from 'antd';
import { head } from 'ramda';

import { getUrl } from '../../../utils/http';
import { FILE } from '../../../constants/urls';
import ModalsContext from '../../../contexts/ModalsContext';
import { OPEN_AVATAR_MODAL } from '../../../constants/actionTypes';

const AvatarComponent = props => {
    const { avatar, name } = props;
    const className = avatar ? 'avatar with-image' : 'avatar without-image';
    const modals = useContext(ModalsContext);

    return (
        <Avatar
            src={avatar && getUrl(FILE, { id: avatar })}
            onClick={()=> avatar && modals.open(OPEN_AVATAR_MODAL, {avatar, name})}
            className={className}
        >
            { name ? head(name).toUpperCase() : 'Б' }
        </Avatar>
    );
}

export default AvatarComponent;
