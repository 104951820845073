import { Col } from 'antd';
import React, { useContext } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { LANDING } from '../../constants/urls';
import { LANDINGS_CREATED } from '../../constants/vacancy';
import UserContext from '../../contexts/UserContext';
import Select from '../forms/formComponents/Select';
import BaseFilter from './base/BaseFilter';
import withFilterForm from './base/withFilterForm';

export default withFilterForm(() => {
    const { user } = useContext(UserContext);
    const { t } = useTranslation();

    return (
        <BaseFilter
            actionUrl={LANDING.stringify()}
            searchFieldName='code'
            namePath='code'
            searchFieldPlaceholder={t('landing.name')}
            span={12}
            sorting={{
                field: 'createdAt',
                order: 'desc',
            }}
        >
            <Col span={12}>
                <Field
                    name='createdBy'
                    component={Select}
                    options={LANDINGS_CREATED(user.id)}
                    namePath='value'
                    placeholder={t('landing.allLandings')}
                />
            </Col>
        </BaseFilter>
    );
});
