import React from 'react';
import { Modal } from 'antd';

const DomainWidgetPreviewModal = props => {
    const { modal, params } = props;

    return <Modal {...modal} width='900px' footer={null}>
        <iframe
            title='Domain Widget Preview'
            width='100%'
            height='600px'
            frameBorder='0'
            src={params.url}
        />
        <hr />
        <a href={params.url} target='_blank' rel="noopener noreferrer">{params.url}</a>
    </Modal>;
}

export default DomainWidgetPreviewModal;
