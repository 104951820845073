import * as yup from 'yup';
import { setLocale } from 'yup';
import { all, forEachObjIndexed, has, findIndex, propEq, path, contains, values, test, keys, last, includes, is, any } from 'ramda';
import moment from 'moment';
import i18n from 'i18next';

import { DATE_FORMAT } from '../constants/date';
import phoneValidation from './phoneValidation';

export const validationLocale = t => ({
    mixed: {
        required: t('form.validation.mixed.required'),
        match: t('form.validation.mixed.match'),
        mimeIsContains: t('form.validation.mixed.mimeIsContains'),
        mimeIsInvalid: t('form.validation.mixed.mimeIsInvalid'),
        fileTypeNameIsContains: t('form.validation.mixed.fileTypeNameIsContains'),
        fileType: t('form.validation.mixed.fileType'),
        fileTypeImport: t('form.validation.mixed.fileTypeImport')
    },
    string: {
        required: t('form.validation.mixed.required'),
        email: t('form.validation.string.email'),
        min: t('form.validation.string.min'),
        code: t('form.validation.string.code'),
        url: t('form.validation.string.url'),
        matches: t('form.validation.string.url')
    },
    number: {
        required: t('form.validation.mixed.required'),
        positive: t('form.validation.number.positive'),
        moreThan: t('form.validation.number.moreThan'),
    },
    date: {
        required: t('form.validation.mixed.required'),
        moreThan: t('form.validation.date.moreThan')
    },
    array: {
        required: t('form.validation.mixed.required'),
        min: t('form.validation.array.min'),
        nosame: t('form.validation.array.nosame')
    }
});

export function buildYupLocale(_, t) {
    setLocale(validationLocale(t));
}

const t = i18n.t.bind(i18n);

yup.addMethod(yup.string, 'phone', phoneValidation);
yup.addMethod(yup.mixed, 'match', function (matchField, message = validationLocale(t).mixed.match) {
    return this.test('match', message, function (value) {
        const matchFieldValue = this.parent[matchField];
        return value === matchFieldValue;
    });
});
yup.addMethod(yup.string, 'code', function (regexp, message = validationLocale(t).string.code) {
    return this.test({
        name: 'code',
        exclusive: false,
        message,
        test: function (value) {
            const { path, createError } = this;

            if (value && !regexp.test(value)) {
                return createError({ path, message: validationLocale(t).string.code });
            }

            return true;
        }
    });
});

yup.addMethod(yup.mixed, 'fileType', function (fileTypes, message = 'mixed.fileType') {
    return this.test({
        name: 'fileType',
        exclusive: false,
        message,
        test: function (value) {
            const { path : errorPath, createError } = this;

            if (value && !all(file => contains(last(file.name.split('.')), fileTypes), value)) {
                return createError({ path: errorPath, message: path(message.split('.'), validationLocale(t)) });
            }

            return true;
        }
    });
});

yup.addMethod(yup.string, 'containString', function (string, message) {
    return this.test({
        name: 'containString',
        exclusive: false,
        message,
        test: function (value) {
            return value ? (is(Array, string) ? any(s => includes(s, value), string) : includes(string, value)) : true;
        }
    });
});

yup.addMethod(yup.string, 'fileTypeName', function (fileTypes, message = validationLocale(t).mixed.mimeIsInvalid) {
    return this.test({
        name: 'fileTypeName',
        exclusive: false,
        message,
        test: function (value) {
            const { path, createError } = this;

            if (contains(value, keys(fileTypes))) {
                return createError({ path, message: validationLocale(t).mixed.fileTypeNameIsContains });
            }

            return true;
        }
    });
});

yup.addMethod(yup.string, 'mime', function (fileTypes, message = validationLocale(t).mixed.mimeIsInvalid) {
    return this.test({
        name: 'mime',
        exclusive: false,
        message,
        test: function (value) {
            const { path, createError } = this;

            if (value && !test(/^.*\/.*$/g, value)) {
                return createError({path, message: validationLocale(t).mixed.mimeIsInvalid });
            } else if (contains(value, values(fileTypes))) {
                return createError({path, message: validationLocale(t).mixed.mimeIsContains });
            }

            return true;
        }
    });
});

export const getRequiredFields = schema => {
    const requiredFields = [];

    if (!schema) {
        return requiredFields;
    }

    const fn = (fields, path) => forEachObjIndexed((field, key) => {
        const fieldPath = path ? `${path}.${key}` : key;
        field.fields ?
            fn(field.fields, fieldPath) :
            has('required', field._exclusive) && requiredFields.push(fieldPath);
    }, fields);

    fn(schema.fields);

    return requiredFields;
};

export const required = value => {
    return !value ? 'form.requiredField' : undefined;
};

export const parentField = (parentFieldName, currentFieldName, formQuestions = [], t) => {
    const parentIndex = findIndex(propEq('field', parentFieldName), formQuestions);
    const currentFieldIndex = findIndex(propEq('field', currentFieldName), formQuestions);

    if (currentFieldIndex < 0) {
        return undefined;
    } else if (parentIndex > currentFieldIndex) {
        return t('vacancy.questions.questionShouldAfterQuestion', { question: path([currentFieldIndex, 'label'], formQuestions), afterQuestion: path([parentIndex, 'label'], formQuestions) });
    }

    return undefined;
};

export const dateMoreThem = (min, max, format = DATE_FORMAT) => {
    const minDate = min === 'now' ? moment() : moment(`${min}`, format);
    const maxDate = max === 'now' ? moment() : moment(`${max}`, format);

    return minDate.isAfter(maxDate, 'day');
};
