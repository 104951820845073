import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import CompanyDictionaryItemForm from '../../../forms/CompanyDictionaryItemForm';
import { postCompanyDictionaryItemHandler, putCompanyDictionaryItemHandler } from '../../../../actions/handlers';
import ActionsContext from '../../../../contexts/ActionsContext';
import { COMPANY_DICTIONARY_ITEM } from '../../../../constants/urls';

const CompanyDictionaryItemModal = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const { modal, params, close } = props;
    const item = prop('item', params);
    const parentDictionaryId = prop('parentDictionaryId', params);
    const dictionaryId = prop('dictionaryId', params);

    return (
        <Modal
            {...modal}
            title={t('settings.editElementName')}
            footer={null}
        >
            <CompanyDictionaryItemForm
                formAction={item.id ? putCompanyDictionaryItemHandler : postCompanyDictionaryItemHandler}
                item={item}
                parentDictionaryId={parentDictionaryId}
                dictionaryId={dictionaryId}
                onSuccess={() => {
                    revalidateAction(COMPANY_DICTIONARY_ITEM.stringify());
                    message.success(t('settings.elementSaveSuccess'));
                    close();
                }}
                onSubmitFail={() => message.error(t('settings.elementSaveError'))}
            />
        </Modal>
    );
}

export default CompanyDictionaryItemModal;
