import React from 'react';
import { Field } from 'react-final-form';
import { Col, Tooltip } from 'antd';
import { filter, includes, path } from 'ramda';
import { InfoCircleTwoTone } from '@ant-design/icons';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { applicantState, applicantTypes, rejectedState } from '../../constants/vacancy';
import withUserCompany from '../hocs/withUserCompany';
import BaseFilter from './base/BaseFilter';
import Select from '../forms/formComponents/Select';
import withFilterForm from './base/withFilterForm';
import { DOMAIN, VACANCY, VACANCY_FORM_PRESET } from '../../constants/urls';

const RejectedWrapper = styled.div`
    display: flex;
    .ant-form-item {
        width: 100%;
    }
    .anticon-info-circle {
        margin-top: 8px;
        margin-left: 8px;
    }
`;

export default withTranslation()(withUserCompany(withFilterForm(({ form, publicationSettings, action, t, isAdmin, isFunctionalLicense }) => (
    <BaseFilter
        searchFieldName="name"
        searchFieldPlaceholder={t('applicant.name')}
        span={6}
    >
        <Col span={6}>
            <Field
                name='state'
                placeholder={t('applicant.allApplicants')}
                component={Select}
                options={applicantState}
                namePath="value"
            />
        </Col>
        <Col span={6}>
            <Field
                name='type'
                placeholder={t('applicant.allPublicationTypes')}
                component={Select}
                options={filter(item =>
                    (item.permission ? publicationSettings[item.permission] : true) && (isFunctionalLicense && !isAdmin ? !includes(item.id, ['chat', 'terminal']) : true),
                applicantTypes)}
                namePath="value"
            />
        </Col>
        <Col span={6}>
            <RejectedWrapper>
                <Field
                    name='rejectedState'
                    placeholder={t('applicant.barierQuestionsAllStatuses')}
                    component={Select}
                    options={rejectedState}
                    namePath="value"
                />
                <Tooltip placement='bottomRight' title={t('applicant.barierQuestionsTooltip')}>
                    <InfoCircleTwoTone />
                </Tooltip>
            </RejectedWrapper>
        </Col>
        { publicationSettings.domain && (
            <Col span={6}>
                <Field
                    name="domain"
                    placeholder={t('applicant.domain')}
                    component={Select}
                    actionUrl={DOMAIN.stringify({ id: undefined })}
                    namePath="title"
                    onChange={() => form.batch(() => {
                        form.change('formPreset', undefined);
                        form.change('vacancy', undefined);
                    })}
                    searchable
                    allowClear
                />
            </Col>
        )}
        <Col span={6}>
            <Field
                name="formPreset"
                placeholder={t('applicant.form')}
                component={Select}
                actionUrl={VACANCY_FORM_PRESET.stringify()}
                namePath="title"
                searchKey='title'
                filterBy={['domain']}
                searchable
                allowClear
            />
        </Col>
        <Col span={6}>
            <Field
                name="vacancy"
                placeholder={t('applicant.vacancy')}
                component={Select}
                actionUrl={VACANCY.stringify()}
                namePath="title"
                allowClear
                filterBy={['domain']}
                searchable
                searchKey='term'
                searchLimit
                filter={{
                    active: true,
                    published: true,
                }}
                sorting={{
                    field: 'createdAt',
                    order: 'desc',
                }}
            />
        </Col>
        <Col span={6}>
            <div className='text-center'>
                {t('applicant.applicantsAmount')}: {path(['data', '_meta', 'count'], action)}
            </div>
        </Col>
    </BaseFilter>
))));
