import React, { useContext, useEffect } from 'react';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, GlobalOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import useCompanyCodeSwr from '../../../../utils/useCompanyCodeSwr';
import { QUESTION_PRESET, RESERVED_QUESTIONS_LIMIT } from '../../../../constants/questions';
import TableList from '../../../table/TableList';
import withUserCompany from '../../../hocs/withUserCompany';
import { deleteQuestionPresetHandler } from '../../../../actions/handlers';
import ActionsContext from '../../../../contexts/ActionsContext';
import { QUESTIONS_PRESET } from '../../../../constants/urls';
import ModalsContext from '../../../../contexts/ModalsContext';
import { OPEN_QUESTION_PRESET_MODAL, OPEN_VACANCY_QUESTION_LANGUAGE_MODAL } from '../../../../constants/actionTypes';

const QuestionsSettings = props => {
    const { isCompanyAdmin } = props;
    const { t } = useTranslation();
    const { addAction, removeAction, revalidateAction } = useContext(ActionsContext);
    const actionUrl = QUESTIONS_PRESET.stringify();
    const limit = useCompanyCodeSwr(QUESTIONS_PRESET.stringify(), url => axios(url, {
        params: {
            pagination: { limit: 1 },
            filter: { customizable: true }
        }
    }));
    const questionsCount = pathOr(0, ['data', '_meta', 'count'], limit);

    const modals = useContext(ModalsContext);

    useEffect(() => {
        addAction(actionUrl, limit);
        return () => {
            removeAction(actionUrl);
        }
    }, []);

    const getColumns = () => {
        return [
            {
                title: t('vacancy.questions.name'),
                dataIndex: 'label',
                key: 'label',
                className: 'name-col'
            },
            {
                title: t('vacancy.questions.question'),
                dataIndex: 'question',
                key: 'question',
                className: 'bot-name-col'
            },
            {
                key: 'controls',
                width: 126,
                render: item => isCompanyAdmin &&
                    <Button.Group>
                        <Button className='edit-btn' icon={<EditOutlined />} onClick={() => modals.open(OPEN_QUESTION_PRESET_MODAL, item)} />
                        <Button
                            className='translations-btn'
                            icon={<GlobalOutlined />}
                            onClick={() => modals.open(OPEN_VACANCY_QUESTION_LANGUAGE_MODAL, {
                                item,
                                questionsType: QUESTION_PRESET,
                        })} />
                        <Popconfirm
                            title={t('vacancy.questions.questionDeleteConfirm')}
                            placement='topRight'
                            onConfirm={() => deletePreset(item.id)}
                            okText={t('vacancy.form.yes')}
                            cancelText={t('vacancy.form.no')}>
                            <Button className='delete-btn' type='danger' icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Button.Group>
            }
        ]
    };

    const deletePreset = id => {
        deleteQuestionPresetHandler(id).then(() => {
            message.success(t('vacancy.questions.questionDeleteConfirmSuccess'));
            revalidateAction(actionUrl);
            limit.mutate();
        }).catch(() => message.error(t('vacancy.questions.questionDeleteConfirmError')));
    }

    const renderAddButton = () => {
        return !limit.isValidating && questionsCount >= RESERVED_QUESTIONS_LIMIT ?
            <Tooltip title={t('vacancy.questions.reservedQuestionsLimit', { limit: RESERVED_QUESTIONS_LIMIT })} placement='left'>
                <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    disabled>
                    {t('vacancy.questions.add')}
                </Button>
            </Tooltip> :
            <Button
                type='primary'
                icon={<PlusOutlined />}
                loading={limit.isValidating}
                disabled={limit.isValidating}
                onClick={() => modals.open(OPEN_QUESTION_PRESET_MODAL)}>
                {t('vacancy.questions.add')}
            </Button>;
    }

    return <TableList
        actionUrl={actionUrl}
        location={props.location}
        columns={getColumns()}
        buttons={isCompanyAdmin && renderAddButton()}
        staticFilter={{ customizable: true }}
    />;
}

export default withUserCompany(QuestionsSettings);
