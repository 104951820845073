import React from 'react';
import { path } from 'ramda';

import Error from '../Error';

const ErrorHandler = props => {
    const errorObject = path(['error', 'data'], props.action);
    const refresh = props.action.mutate;

    return errorObject ? <Error error={errorObject} refresh={refresh}/> : props.children;
}

export default ErrorHandler;
