import React from 'react';
import { Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';

import LettersSettingsForm from '../../forms/LettersSettingsForm';
import { putLettersSettingsHandler } from '../../../actions/handlers';
import { LETTERS_SETTINGS } from '../../../constants/urls';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const LettersSettings = () => {
    const { t } = useTranslation();
    const lettersSettings = useCompanyCodeSwr(LETTERS_SETTINGS.stringify());

    return lettersSettings.data ?
        <LettersSettingsForm
            formAction={putLettersSettingsHandler}
            item={lettersSettings.data}
            onSuccess={() => message.success(t('settings.settingsSaveSuccess'))}
            onSubmitFail={() => message.error(t('settings.settingsSaveError'))} />
        : <Spin className='central-spin' spinning={lettersSettings.isValidating} />;
}

export default LettersSettings;
