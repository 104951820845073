import React from 'react';
import { Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';

import ThemeSettingsForm from '../../forms/ThemeSettingsForm';
import { putCompanySettingsHandler } from '../../../actions/handlers';
import { COMPANY_SETTINGS } from '../../../constants/urls';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const ThemeSettings = () => {
    const { t } = useTranslation();
    const companySettings = useCompanyCodeSwr(COMPANY_SETTINGS.stringify());

    return companySettings.data ?
        <ThemeSettingsForm
            formAction={putCompanySettingsHandler}
            item={companySettings.data}
            onSuccess={() => message.success(t('settings.companyThemeSaveSuccess'))}
            onSubmitFail={() => message.error(t('settings.companyThemeSaveError'))} />
        : <Spin className='central-spin' spinning={companySettings.isValidating} />;
}

export default ThemeSettings;
