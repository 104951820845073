import React from 'react';
import { Redirect } from 'react-router-dom';
import { any, includes } from 'ramda';

import { COMPANY_MANAGER } from '../constants/roles';
import Route from './Route';
import withUserCompany from './hocs/withUserCompany';

const SecureRoute = ({
    isAdmin, isFullLicense, userRoles, stateCompanyCode, isFunctionalLicense,
    roles, withoutCompany, withCompany, redirectTo, fullLicense, hideFunctionalLicense,
    ...props
}) => {
    const hasLicense = hideFunctionalLicense && !isAdmin && isFunctionalLicense ? false :
        fullLicense && !isAdmin ? (hideFunctionalLicense ? isFullLicense : (isFullLicense || isFunctionalLicense)) :
        true;
    const hasRole = (!roles ||
        any(role => includes(role, userRoles), roles) ||
        (stateCompanyCode && includes(COMPANY_MANAGER, roles))
    );
    const hasCompany = stateCompanyCode ? !withoutCompany : !withCompany;

    return hasLicense && hasRole && hasCompany ?
        <Route key={stateCompanyCode} {...props} /> :
        <Redirect to={isAdmin && !stateCompanyCode ? redirectTo || '/settings/users' : '/'} />
};

export default withUserCompany(SecureRoute);
