import React from 'react';
import { Tabs } from 'antd';
import { contains, path } from 'ramda';
import { useTranslation } from 'react-i18next';

import NoMatch from '../../NoMatch';
import Route from '../../Route';
import VacancyApplicantsReport from './VacancyApplicantsReport';

const Reports = props => {
    const { t } = useTranslation();
    const { location } = props;
    const activeKey = path([0], location.pathname.replace(/\/reports\/?/, '').split('/'));

    const onChange = key => {
        props.history.push(`/reports/${key}`);
    };

    return contains(activeKey, ['']) ?
        <Tabs
            className='settings-tabs'
            animated={{ tabPane: false }}
            activeKey={activeKey}
            onChange={onChange}
        >
            <Tabs.TabPane tab={t('reports.reportVacancyApplicant')} key=''>
                <Route path='/reports' component={VacancyApplicantsReport} />
            </Tabs.TabPane>
        </Tabs> :
        <Route component={NoMatch} />;
}

export default Reports;
