import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import Input from './formComponents/Input';
import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';

const LoginForm = () => {
    const { t } = useTranslation();

    return <Fragment>
        <Field
            name='username'
            component={Input}
            icon={<MailOutlined />}
            placeholder='Email' />
        <Field
            name='password'
            component={Input}
            icon={<LockOutlined />}
            placeholder={t('guest.password')}
            htmlType='password' />
        <SubmitButton>{ t('guest.login') }</SubmitButton>
    </Fragment>;
}

const validationSchema = () => yup.object().shape({
    username: yup.string().email().required(),
    password: yup.string().required()
});

export default withFormWrapper(LoginForm, {
    mapPropsToValues: () => ({}),
    validationSchema
});
