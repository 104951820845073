import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import CompanyLicense from './CompanyLicense';

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
    }
`;

const CompanyLicenseLogModal = props => {
    const { t } = useTranslation();
    const { modal, params } = props;

    return (
        <StyledModal
            {...modal}
            title={t('company.activationHistory')}
            footer={null}
        >
            <CompanyLicense company={params} />
        </StyledModal>
    );
}

export default CompanyLicenseLogModal;
