import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileExcelOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { find, path, propEq } from 'ramda';
import qs from 'qs';

import { CHANGE_LOG } from '../../../constants/urls';
import { getFullName } from '../../../utils/fieldsHelpers';
import { getToken } from '../../../utils/token';
import DateTime from '../../table/DateTime';
import TableList from '../../table/TableList';

const UserLog = props => {
    const { t } = useTranslation();

    const renderStringLogChange = (field, changes) => {
        const item = find(propEq('path', field), changes || []);

        return item && item.actual ? item.actual : item && item.previous && !item.actual ? <span style={{ color: 'red' }}>{ t('userAdmin.deleted') }</span> : '';
    };

    const renderBoolLogChange = (field, changes) => {
        const item = find(propEq('path', field), changes || []);

        return item ? (item.actual ? t('app.yes') : t('app.no')) : '';
    };

    const renderRoleLogChange = changes => {
        const item = find(propEq('path', 'roles'), changes || []);

        return item && path(['actual', 0], item) ? t(`app.roles.${path(['actual', 0], item)}`) : '';
    };

    const getColumns = () => {
        return [
            {
                title: t('auth.date'),
                key: 'date',
                dataIndex: 'date',
                width: 130,
                render: date => <DateTime date={date} />
            },
            {
                title: t('userAdmin.lastName'),
                key: 'lastName',
                dataIndex: 'changes',
                render: changes => renderStringLogChange('lastName', changes)
            },
            {
                title: t('userAdmin.firstName'),
                key: 'firstName',
                dataIndex: 'changes',
                render: changes => renderStringLogChange('firstName', changes)
            },
            {
                title: t('userAdmin.middleName'),
                key: 'middleName',
                dataIndex: 'changes',
                render: changes => renderStringLogChange('middleName', changes)
            },
            {
                title: t('userAdmin.role'),
                key: 'role',
                dataIndex: 'changes',
                render: changes => renderRoleLogChange(changes)
            },
            {
                title: 'Email',
                key: 'username',
                dataIndex: 'changes',
                render: changes => renderStringLogChange('username', changes)
            },
            {
                title: t('userAdmin.phone'),
                key: 'phone',
                dataIndex: 'changes',
                render: changes => renderStringLogChange('phone', changes)
            },
            {
                title: t('userAdmin.publishDomain'),
                key: 'domain',
                dataIndex: 'changes',
                render: changes => renderBoolLogChange('publicationSettings.domain', changes)
            },
            {
                title: t('userAdmin.publishLanding'),
                key: 'landing',
                dataIndex: 'changes',
                render: changes => renderBoolLogChange('publicationSettings.landing', changes)
            },
            {
                title: t('userAdmin.publishBot'),
                key: 'bot',
                dataIndex: 'changes',
                render: changes => renderBoolLogChange('publicationSettings.bot', changes)
            },
            {
                title: t('userAdmin.publishTerminal'),
                key: 'terminal',
                dataIndex: 'changes',
                render: changes => renderBoolLogChange('publicationSettings.terminal', changes)
            },
            {
                title: t('userAdmin.receiveNotifications'),
                key: 'applicantNotificationsEnabled',
                dataIndex: 'changes',
                render: changes => renderBoolLogChange('settings.applicantNotificationsEnabled', changes)
            },
            {
                title: t('bot.subscribed'),
                key: 'enabled',
                dataIndex: 'changes',
                render: changes => renderBoolLogChange('enabled', changes)
            },
            {
                title: t('userAdmin.whoChangeInfo'),
                key: 'user',
                dataIndex: ['_relations', 'user'],
                render: user => getFullName(user)
            }
        ]
    }

    const getButtons = () => {
        const searchPath = qs.stringify({
            access_token: getToken(),
            filter: {
                entity: props.user.id
            }
        });

        return <Button
            type='primary'
            target='_blank'
            rel='noopener noreferrer'
            href={`/api/changelog/user/export?${searchPath}`}
            icon={<FileExcelOutlined />}
            download>
            { t('chat.download') }
        </Button>;
    }

    return <TableList
        buttons={getButtons()}
        actionUrl={CHANGE_LOG.stringify()}
        columns={getColumns()}
        relations={['user', 'entity']}
        staticFilter={{ entity: props.user.id }}
        sorting={{
            field: 'date',
            order: 'desc',
        }} />;
}

export default UserLog;
