import React from 'react';
import { Tag } from 'antd';
import { Popover } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const PublicationDate = props => {
    const { t } = useTranslation();
    const { published, notPublished, expired, startDate, endDate } = props.publicationStatus;

    if (published) {
        return (
            <div className='published-date'>
                <Popover
                    content={endDate ? <Tag color="warning">{endDate}</Tag> : t('app.noLimit')}
                    overlayClassName='published-date-popover'
                >
                    <CalendarOutlined style={{ color: endDate ? '#fa8c16' : '#52c41a' }} />
                </Popover>
            </div>
        );
    }

    if (notPublished) {
        return (
            <div className='published-date'>
                { startDate ? (
                    <Popover
                        content={<Tag color="processing">{ startDate }</Tag>}
                        overlayClassName='published-date-popover'
                    >
                        <CalendarOutlined style={{ color: '#13c2c2' }} />
                    </Popover>
                ) : (
                    <CalendarOutlined style={{ color: '#13c2c2' }} />
                )}
            </div>
        );
    }

    if (expired) {
        return (
            <div className='published-date'>
                { endDate ? (
                    <Popover
                        content={<Tag color="error">{ endDate }</Tag>}
                        overlayClassName='published-date-popover'
                    >
                        <Tag color="error">{ t('app.expired') }</Tag>
                    </Popover>
                ) : (
                    <Tag color="error">{ t('app.expired') }</Tag>
                )}
            </div>
        );
    }

    return null;
}

export default PublicationDate;
