import React from 'react';
import { Input } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const InputComponent = props => {
    const onChange = e => props.onChange(e.target.value, props.input.value);

    const { icon, htmlType, placeholder, disabled, input: { value, name }, className, addonAfter, getRef, addonBefore } = props;

    return (
        <Input
            id={name}
            name={name}
            value={value || ''}
            disabled={!!disabled}
            className={className}
            onChange={onChange}
            prefix={icon || null}
            type={htmlType}
            placeholder={placeholder}
            addonAfter={addonAfter}
            addonBefore={addonBefore}
            ref={getRef}
        />
    );
}

InputComponent.defaultProps = {
    type: 'text'
};

export default withFieldWrapper(InputComponent);
