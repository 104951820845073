import React, { useState } from 'react';
import { startsWith, split, last, isNil } from 'ramda';
import Viewer from 'react-viewer';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const isHttps = url => startsWith('https', url);
const isHttpsCurrent = isHttps(window.location.origin);

const Image = ({ url, text }) => {
    const [ visible, setVisible ] = useState(false);
    const { t } = useTranslation();

    return (
        <div className='file-wrapper'>
            <img
                src={url}
                onClick={() => setVisible(true)}
                alt={t('chat.imagePreview')}
                className='web-chat-media-image'
            />
            <Viewer
                visible={visible}
                onClose={() => { setVisible(false); } }
                images={[{ src: url, alt: t('chat.imagePreview') }]}
                noToolbar={true}
                noFooter={true}
                zoomSpeed={0.1}
            />
            <FileComponent url={url} text={text} />
        </div>
    );
};

const Video = ({ url }) => {
    const { t } = useTranslation();

    return <div className='file-wrapper'>
        <video width="320" height="240" controls className='web-chat-media-video'>
            <source src={url} type="video/mp4"/>
            {t('chat.videoNoSupport')}
        </video>
        <FileComponent url={url} />
    </div>
};

const FileComponent = ({ url, text }) => {
    const { t } = useTranslation();

    return <a href={url} className='web-chat-media-file-link' download={isHttpsCurrent && isHttps(url)} title={text}>
        <div className='web-chat-media-file'>
            <DownloadOutlined className='web-chat-media-file-icon' />
            {t('chat.download')}
        </div>
    </a>;
};

const Audio = ({ url }) => {
    const { t } = useTranslation();

    return <div className='file-wrapper'>
        <audio controls>
            <source src={url} type='audio/webm' />
            {t('chat.audioNoSupport')}
        </audio>
        <FileComponent url={url} />
    </div>;
}

const mediaComponents = {
    'image': Image,
    'video': Video,
    'file': FileComponent,
    'audio': Audio,
};

const Media = ({ payload }) => {
    const getMediaComponent = (payload = {}) => {
        const MediaComponent = mediaComponents[payload.type] || FileComponent;

        if (!isNil(payload.url)) {
            const key = last(split('/', payload.url));
            const apiUrl = isHttpsCurrent && !isHttps(payload.url) ? `${window.location.origin}/api/${last(split('/api/', payload.url))}` : payload.url;

            return <MediaComponent url={apiUrl} text={payload.text} key={key} />;
        } else {
            return null;
        }
    }

    if (Array.isArray(payload)) {
        return payload.map((payloadItem) => getMediaComponent(payloadItem));
    } else {
        return getMediaComponent(payload);
    }
};

export default Media;
