import React, { Fragment, useContext } from 'react';
import { Button, Tabs } from 'antd';
import { contains } from 'ramda';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

import NoMatch from '../../../NoMatch';
import CitiesDictionary from './CitiesDictionary';
import CountriesDictionary from './CountriesDictionary';
import RegionDictionary from './RegionDictionary';
import CurrencyDictionary from './CurrencyDictionary';
import DictionaryTab from './DictionaryTab';
import Route from '../../../Route';
import { DICTIONARIES, DICTIONARIES_NAMES } from '../../../../constants/dictionary';
import withUserCompany from '../../../hocs/withUserCompany';
import { getToken } from '../../../../utils/token';
import ModalsContext from '../../../../contexts/ModalsContext';
import { OPEN_DICTIONARIES_IMPORT_MODAL } from '../../../../constants/actionTypes';

const availableDictionaries = ['countries', 'regions', 'currency', ...DICTIONARIES];

const Dictionaries = props => {
    const { t } = useTranslation();
    const modals = useContext(ModalsContext);

    const onChange = key => {
        const routePath = props.match.path.replace('/:type?', '');
        props.history.push(`${routePath}/${key === 'item_0' ? '' : key}`);
    }

    const { match: { params: { type }, path }, companyId, isAdminOnly } = props;
    const routePath = path.replace('/:type?', '');

    return !type || contains(type, availableDictionaries) ?
        !companyId && !isAdminOnly ? null :
        <Fragment>
            { isAdminOnly &&
                <div className='toolbar'>
                    <Button.Group>
                        <Button
                            target='_blank'
                            rel='noopener noreferrer'
                            href={`/api/user/admin/export?access_token=${getToken()}`}
                            icon={<DownloadOutlined />}
                            download>
                            { t('settings.exportAllDictionaries') }
                        </Button>
                        <Button
                            icon={<UploadOutlined />}
                            onClick={() => modals.open(OPEN_DICTIONARIES_IMPORT_MODAL)}>
                            { t('settings.importAllDictionaries') }
                        </Button>
                    </Button.Group>
                </div>
            }
            <Tabs
                className='dictionaries'
                onChange={onChange}
                activeKey={type || ''}
                animated={{ tabPane: false }}
                destroyInactiveTabPane
            >
                <Tabs.TabPane tab={t('settings.cities')} key=''>
                    <Route
                        path={routePath}
                        component={CitiesDictionary} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('settings.regions')} key='regions'>
                    <Route
                        path={`${routePath}/regions`}
                        component={RegionDictionary} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('settings.countries')} key='countries'>
                    <Route
                        path={`${routePath}/countries`}
                        component={CountriesDictionary} />
                </Tabs.TabPane>
                { DICTIONARIES.map(dictionary => (
                    <Tabs.TabPane tab={t(DICTIONARIES_NAMES[dictionary])} key={dictionary}>
                        <Route
                            key={dictionary}
                            dictionaryType={dictionary}
                            path={`${routePath}/:dictionaryType`}
                            component={DictionaryTab} />
                    </Tabs.TabPane>
                ))}
                <Tabs.TabPane tab={t('app.dictionariesNames.Currency')} key='currency'>
                    <Route
                        path={`${routePath}/currency`}
                        component={CurrencyDictionary} />
                </Tabs.TabPane>
            </Tabs>
        </Fragment> :
        <Route component={NoMatch} />;
}

export default withUserCompany(Dictionaries);
