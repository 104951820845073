import React, { Fragment, useEffect, useState } from 'react';
import { OnChange } from 'react-final-form-listeners';
import { path, split, assocPath } from 'ramda';

import InitialValuesContext from '../../contexts/InitialValuesContext';

const ListenerField = props => {
    const [state, setState] = useState({});
    const { listenFieldName, contextValues } = props;
    let initialState = {};

    useEffect(() => {
        if (Array.isArray(listenFieldName)) {
            listenFieldName.forEach(name => {
                const pathArray = split('.', name);
                const fieldValue = path(pathArray, contextValues);
                initialState = assocPath(pathArray, fieldValue, initialState);
            })

            setState(initialState);
        } else {
            const pathArray = split('.', listenFieldName);
            const fieldValue = path(pathArray, contextValues);
            setState(assocPath(pathArray, fieldValue, {}));
        }
    }, []);

    const setValue = (name, value) => {
        setState(prevState => assocPath(split('.', name), value, prevState));
    }

    return (
        <Fragment>
            { Array.isArray(listenFieldName) ? (
                listenFieldName.map(name => (
                    <OnChange key={name} name={name}>
                        { value => setValue(name, value)}
                    </OnChange>
                ))
            ) : (
                <OnChange name={listenFieldName}>
                    { value => setValue(listenFieldName, value)}
                </OnChange>
            )}
            { props.children(state) }
        </Fragment>
    );
}

const WithContext = props => {
    return (
        <InitialValuesContext.Consumer>
            { contextValues => (
                    <ListenerField
                        contextValues={contextValues}
                        {...props}
                    />
                )
            }
        </InitialValuesContext.Consumer>
    );
}

export default WithContext;
