import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { fromPairs, pathOr, toPairs } from 'ramda';

import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import { TranslationFields } from './ClassifiersForm';

const ClassifierOptionForm = props => {
    const { t } = useTranslation();
    const { form } = props;

    return (
        <Fragment>
            <Field
                name='name'
                component={Input}
                label={t('settings.classifierName1')}
                addonAfter={<div style={{ width: 48 }}>RU</div>}
            />
            <FieldArray name='translations'>
                { ({ fields }) => <TranslationFields fields={fields} form={form} /> }
            </FieldArray>
            <SubmitButton>
                { t('form.save') }
            </SubmitButton>
        </Fragment>
    );
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
});

export default withFormWrapper(ClassifierOptionForm, {
    validationSchema,
    mapPropsToValues: ({ classifier, item }) => ({
        ...item,
        translations: toPairs(pathOr({}, ['translations'], item)).map(([lang, name]) => ({ name, lang })),
        classifier,
    }),
    mapBeforeSubmit: values => ({
        ...values,
        translations: fromPairs((values.translations || []).map(({ name, lang }) => [lang, name]))
    })
});
