import React, { Fragment, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Button, Popover, Spin, Select } from 'antd';
import { any, contains, is, map, prop, path, propOr, pathOr, filter, startsWith } from 'ramda';
import * as linkify from 'linkifyjs';
import Linkify from 'linkifyjs/react';
import { ArrowLeftOutlined, CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import UrlPattern from 'url-pattern';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { boolToString, isWeb } from '../../../utils/applicant';
import { getUrlWithFilters } from '../../../utils/urlParams';

import DeepLink from '../../table/DeepLink';
import BotImage from '../../table/BotImage';
import Phone from '../../table/Phone';
import Mail from '../../table/Mail';
import { ApplicantMoney } from '../../table/Money';
import DateTime from '../../table/DateTime';
import { getFullName } from '../../../utils/fieldsHelpers';
import { getBlockQuestions } from '../../../utils/vacancy';
import Avatar from '../dialog/Avatar';
import Media from '../chat/Media';
import CorrectValuesView, { getTranslateOption } from '../forms/question/CorrectValuesView';
import { isEmpty } from '../../../utils/ramdaAdditions';
import ModalsContext from '../../../contexts/ModalsContext';
import { OPEN_PDA_HTML_MODAL } from '../../../constants/actionTypes';
import { APPLICANT } from '../../../constants/urls';
import { AVAILABLE_TRANSLATIONS } from '../../../constants/dictionary';
import ErrorHandler from '../../hocs/withErrorHandler';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const FormItem = (props) => {
    const { i18n } = useTranslation();
    const correctValues = path([props.field], props.blockQuestions);
    const correctValuesItems = path(props.fieldPath ? props.fieldPath.split('.') : [props.field], props.correctValuesItems);
    const textClass = cx({ error: props.block });

    return props.data ? (
        <Form.Item
            colon={true}
            {...formItemLayout}
            label={<span className={textClass}>{props.label}</span>}
            className={textClass}
            extra={!!props.block && correctValues ? (
                <div id={props.field}>
                    ({ path(['translation', 'applicant', 'correctValues'], i18n.getDataByLanguage(`${props.language}`) || {}) }: <CorrectValuesView
                        correctValues={correctValues}
                        correctValuesItems={correctValuesItems}
                        type={props.type}
                        field={props.field}
                        settings={props.settings}
                        language={props.language}
                    />)
                </div>
            ) : undefined}
        >
            <span>{props.data}</span>
        </Form.Item>
    ) : null;
};

const addLinktoString = (string) => {
    if (!string) {
        return '';
    }

    if (linkify.test(`${string}`)) {
        return (
            <Linkify
                tagName='span'
                options={{ format: (value) => {
                    var pattern = new UrlPattern('?(http(s)\\://)(:subdomain.):domain.:tld(/*)');
                    const { domain, tld } = pattern.match(value) || {};

                    return domain && tld ? `${domain}.${tld}` : value;
                }}}
            >
                {string}
            </Linkify>
        );
    } else {
        return `${string}`;
    }
}

const renderDictField = (field, language) => {
    const items = is(Array, field.items) ? field.items : field;

    return (
        is(Array, items) ? (
            items.length ? map(item => (
                <div key={item.id}>{getTranslateOption(item, language)} {item.userValue ? item.userValue : ''}</div>
            ), items) : null
        ) : `${getTranslateOption(field, language)} ${field.userValue ? field.userValue : ''}`
    );
};

const renderOPDField = (field, actions, t) => {
    const htmlContent = prop('htmlContent', field);
    const modals = useContext(ModalsContext);

    return (
        prop('value', field) ? (
            <div className='pda-result'>
                <CheckOutlined />
                { htmlContent ? (
                    <Button
                        className='pda-modal-button'
                        onClick={() => modals.open(OPEN_PDA_HTML_MODAL, { htmlContent })}
                    >
                        { t('applicant.openOpd') }
                    </Button>
                ) : null}
            </div>
        ) : <CloseOutlined />
    );
};

export const renderFieldValue = (type, field, settings, actions, t, language) => {
    if (field === undefined || field === null) {
        return '';
    }

    switch(type) {
        case 'company_dictionary':
        case 'dictionary':
        case 'choice':
        case 'country':
        case 'region':
        case 'city':
            return renderDictField(field, language);
        case 'personalDataAgreement':
            return renderOPDField(field, actions, t, language);
        case 'email':
            return <Mail value={field} />;
        case 'phone':
            return <Phone value={field} />;
        case 'file':
            return <Media payload={field} />;
        case 'money':
            return <ApplicantMoney field={field} />
        case 'date':
            return <DateTime date={field} format={prop('format', settings)} />;
        case 'boolean':
            return field ? <CheckOutlined /> : <CloseOutlined />;
        default:
            return addLinktoString(field);
    }
};

const renderCompositeFields = (fields, settings, key, blockLabel, blockTranslations, rejectedQuestions, blockQuestions, correctValuesItems, t, language) => {
    const questions = propOr([], 'questions', settings);
    const fieldsBloks = fields ? filter(item => !isEmpty(item), (Array.isArray(fields) ? fields : [fields])) : [];

    return fieldsBloks.length ? (
        <Fragment key={key}>
            <FormItem
                label={pathOr(blockLabel, ['label', language], blockTranslations)}
                data=' '
                block={any(startsWith(key), rejectedQuestions)}
                language={language}
            />
            <div className='composite-block'>
                { fieldsBloks.map((blockFields, index) => (
                    <div className='composite-fields' key={index}>
                        { questions.map((fieldSettings) => {
                            const { type, field, label, settings, translations } = fieldSettings;

                            return (
                                <FormItem
                                    key={field}
                                    type={type}
                                    field={field}
                                    fieldPath={`${key}.${field}`}
                                    label={pathOr(label, ['label', language], translations)}
                                    settings={settings}
                                    data={renderFieldValue(type, blockFields[field], settings, null, t, language)}
                                    block={contains(`${key}[${index}].${field}`, rejectedQuestions) || contains(`${key}.${field}`, rejectedQuestions) }
                                    blockQuestions={blockQuestions}
                                    correctValuesItems={correctValuesItems}
                                    language={language}
                                />
                            );
                        })}
                    </div>
                ))}
            </div>
        </Fragment>
    ) : null;
}

const VacancyFormFields = ({ vacancyForm, vacancyQuestions = [], rejectedQuestions = [], blockQuestions, correctValuesItems, actions = {}, t, language }) => (
    vacancyQuestions.map(({ field, type, label, settings, translations }) => (
        type === 'composite' ? (
            renderCompositeFields(vacancyForm[field], settings, field, label, translations, rejectedQuestions, blockQuestions, correctValuesItems, t, language)
        ) : (
            <FormItem
                key={field}
                type={type}
                field={field}
                label={pathOr(label, ['label', language], translations)}
                data={renderFieldValue(type, vacancyForm[field], settings, actions, t, language)}
                settings={settings}
                block={contains(field, rejectedQuestions)}
                blockQuestions={blockQuestions}
                correctValuesItems={correctValuesItems}
                language={language}
            />
        )
    ))
);

const Applicant = props => {
    const { t, i18n } = useTranslation();
    const [ language, setLanguage ] = useState(i18n.language);
    const applicant = useCompanyCodeSwr([APPLICANT.stringify({ id: props.match.params.id }), props.match.params.id], url => axios.get(url, {
        params: {
            relations: ['vacancy', 'dialog', 'dialog.bot', 'domain', 'formPreset']
        }
    }));

    const { data, isValidating } = applicant;
    const { vacancyForm, vacancyQuestions, rejectedQuestions = [], correctValuesItems = {}, hasCorrectValues, rejected, avatar, _relations } = data || {};
    const history = useHistory();
    const dialog = path(['dialog'], _relations);
    const domain = path(['domain'], _relations);
    const bot = path(['_relations', 'bot'], dialog);
    const formPreset = path(['formPreset'], _relations);
    const blockQuestions = getBlockQuestions(
        formPreset ?
            path(['_relations', 'formPreset', 'questions'], data)
            : path(['_relations', 'vacancy', 'questions'], data)
    );

    return <ErrorHandler action={applicant}>
        { data ? (
            <div className='applicant-wrapper'>
                <div className='applicant-toolbar'>
                    <div className='applicant-toolbar-group'>
                        <Button shape='circle' icon={<ArrowLeftOutlined />} onClick={history.goBack} />
                        { dialog && <Avatar avatar={avatar} name={dialog.name} /> }
                        <h1>{getFullName(vacancyForm) || t('applicant.withoutName')}</h1>
                        <div className="applicant-toolbar-actions">
                            <Select
                                value={language}
                                onChange={lang => {
                                    setLanguage(lang);
                                }}
                                style={{ width: 200, marginLeft: 10 }}
                            >
                                { AVAILABLE_TRANSLATIONS.map(lang =>
                                    <Select.Option key={`language-${lang}`} value={lang}>
                                        { lang.toUpperCase() } <span className={`flag flag-${lang}`} /> { lang === data.language && t('applicant.language')}
                                    </Select.Option>
                                )}
                            </Select>
                        </div>
                    </div>
                    { bot && dialog && (
                        <div>
                            <BotImage type={dialog.type} />
                            <Link to={{
                                pathname: `/dialogs/${dialog.bot}/messages/${dialog.id}`,
                                state: {
                                    userId: dialog.userId,
                                    botToken: bot.token
                                },
                            }}>
                                <Button type='primary'>
                                    { t('applicant.openDialog') }
                                </Button>
                            </Link>
                        </div>
                    )}
                </div>
                <div className='applicant-title'>{ t('applicant.response') }</div>
                { vacancyForm ? (
                    <div className='form-item-group'>
                        <VacancyFormFields
                            vacancyForm={vacancyForm}
                            vacancyQuestions={vacancyQuestions}
                            rejectedQuestions={rejectedQuestions}
                            correctValuesItems={correctValuesItems}
                            blockQuestions={blockQuestions}
                            t={t}
                            language={language}
                        />
                    </div>
                ) : (
                    <div className='empty-response'>{ t('applicant.emptyResponse') }</div>
                )}
                <div className='applicant-title'>{ t('applicant.additionalInfo') }</div>
                <div className='form-item-group'>
                    { domain && (
                        <FormItem
                            label={t('applicant.domain')}
                            data={path(['name'], domain)}
                        />
                    )}
                    { _relations.vacancy && (
                        <FormItem
                            label={t('applicant.vacancy')}
                            data={
                                <Link to={`/vacancy/${path(['id'], _relations.vacancy)}`}>
                                    {path(['title'], _relations.vacancy)}
                                </Link>
                            }
                        />
                    )}
                    { formPreset && (
                        <FormItem
                            label={t('applicant.questionnaireOfResponse')}
                            data={
                                <Link to={`/forms/formPresets/${formPreset.id}`}>
                                    { formPreset.title }
                                </Link>
                            }
                        />
                    )}
                    { data.utm && (
                        <FormItem
                            label='UTM'
                            data={
                                <Popover content={(
                                    <div>
                                        utm_source: {path(['utm', 'source'], data)}<br />
                                        utm_medium: {path(['utm', 'medium'], data)}<br />
                                        utm_campaign: {path(['utm', 'campaign'], data)}<br />
                                        utm_term: {path(['utm', 'term'], data)}<br />
                                        utm_content: {path(['utm', 'content'], data)}<br />
                                    </div>
                                )} trigger="hover">
                                    <Button><InfoCircleOutlined /></Button>
                                </Popover>
                            }
                        />
                    )}
                    { data.tool && (
                        <FormItem
                            label={t('applicant.source')}
                            data={
                                <a href={data.tool}>
                                    { data.tool }
                                </a>
                            }
                        />
                    )}
                    { dialog && (
                        <Fragment>
                            <FormItem
                                label={t('applicant.responseFinished')}
                                data={t(boolToString[data.finished])}
                            />
                            <FormItem
                                label={t('applicant.bot')}
                                data={bot && (
                                    <div>
                                        <DeepLink bot={bot}/>
                                        <Link to={getUrlWithFilters('/dialogs', { bot: bot.id })}>
                                                {bot.username}
                                        </Link>
                                    </div>
                                )}
                            />
                            { !isWeb(dialog) && (
                                <FormItem
                                    label={t('applicant.userName')}
                                    data={(
                                        <span>
                                            {dialog.name} {dialog.username && `(${dialog.username})`}
                                        </span>
                                    )}
                                />
                            )}
                            <FormItem
                                label={t('applicant.lastMessage')}
                                data={dialog.lastMessage}
                            />
                            <FormItem
                                label={t('applicant.lastUpdate')}
                                data={new Date(dialog.updatedAt).toLocaleDateString('ru')}
                            />
                        </Fragment>
                    )}
                    { !!(hasCorrectValues || rejected) && (
                        <FormItem
                            label={t('applicant.blockQuestions')}
                            data={rejectedQuestions && rejectedQuestions.length && rejected ? t('applicant.noPassedBarier') : t('applicant.passedBarier')}
                        />
                    )}
                    <FormItem
                        label={t('applicant.responseDate')}
                        data={(
                            <span>
                                {new Date(data.createdAt).toLocaleDateString('ru')} {new Date(data.createdAt).toLocaleTimeString('ru')}
                            </span>
                        )}
                    />
                    { data.createdAt !== data.updatedAt ? (
                        <FormItem
                            label={t('applicant.responseUpdateDate')}
                            data={(
                                <span>
                                    {new Date(data.createdAt).toLocaleDateString('ru')} {new Date(data.createdAt).toLocaleTimeString('ru')}
                                </span>
                            )}
                        />
                    ) : null}
                </div>
            </div>
        ) : isValidating && (
            <div className='applicant-wrapper'>
                <Spin className='central-spin' />
            </div>
        )}
    </ErrorHandler>;
}

export default Applicant;
