import { Spin, message } from 'antd';
import { pathOr } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { putPdaSettingsHandler } from '../../../actions/handlers';
import { PDA_SETTINGS } from '../../../constants/urls';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';
import PdaSettingsForm from '../../forms/PdaSettingsForm';

const PdaSettings = () => {
    const { t } = useTranslation();
    const pdaSettings = useCompanyCodeSwr(PDA_SETTINGS.stringify());

    return pdaSettings.data ?
        <PdaSettingsForm
            formAction={putPdaSettingsHandler}
            item={pathOr({}, ['data'], pdaSettings)}
            onSuccess={() => message.success(t('settings.pdaSettingsEditSuccess'))}
            onSubmitFail={() => message.error(t('settings.pdaSettingsEditError'))} />
        : <Spin className='central-spin' spinning={pdaSettings.isValidating} />;
}

export default PdaSettings;
