import React, { useState } from 'react';
import qs from 'qs';
import { path } from 'ramda';
import { useTranslation } from 'react-i18next';

import LoginComponent from '../guest/Login';
import PasswordRefreshConfirm from '../guest/PasswordRefreshConfirm';
import PasswordRecovery from '../guest/PasswordRecovery';
import { GuestLayout } from '../../layout/guest/GuestLayout';
import { handleTokenChange } from '../../../utils/token';
import { getProfileHandler } from '../../../actions/handlers';

const OAuthLoginView = props => {
    const [token, setToken] = useState(null);
    const [needPasswordUpdate, setNeedPasswordUpdate] = useState(false);

    const checkProfile = (code) => {
        getProfileHandler(code)
            .then(() => {
                if (code) {
                    handleTokenChange(code);
                    const url = path(['redirect_uri'], qs.parse(props.location.search, { ignoreQueryPrefix: true })) || '/oauth/redirect';
                    window.location.href = `${url}?code=${code}`;
                }
            })
            .catch(() => {
                setToken(code);
                setNeedPasswordUpdate(true);
            })
    };

    return (
        <GuestLayout
            {...props}
            disableLanguageReload
            routes={needPasswordUpdate ? (
                <PasswordRefreshConfirm
                    {...props}
                    restoreToken={token}
                    onSuccess={(formProps, data) => {
                        const code = path(['accessToken'], data);

                        if (code) {
                            handleTokenChange(code);
                            const url = path(['redirect_uri'], qs.parse(props.location.search, { ignoreQueryPrefix: true })) || '/oauth/redirect';
                            window.location.href = `${url}?code=${code}`;
                        }
                    }}
                    experiumRecovery
                />
            ) : (
                <LoginComponent
                    {...props}
                    onSuccess={(formProps, data) => {
                        const code = path(['accessToken'], data);
                        checkProfile(code);
                    }}
                    experiumRecovery
                />
            )}
        />
    );
};

export const OAuthRedirectView = props => {
    const { t } = useTranslation();

    return <GuestLayout
        {...props}
        routes={t('guest.authSuccess')}
    />;
};

export const ExperiumPasswordRecovery = props => (
    <GuestLayout
        {...props}
        disableLanguageReload
        routes={(
            <PasswordRecovery
                {...props}
                experiumRecovery
            />
        )}
    />
);

export default OAuthLoginView;
