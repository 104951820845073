import React, { useState } from 'react';
import { Input } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const InputSearchComponent = props => {
    const [value, setValue] = useState(props.input.value || '');

    const onChange = e => setValue(e.target.value);

    const onBlur = e => props.onChange(e.target.value);

    const { input, placeholder, className, onChange: onSearch } = props;

    return <Input.Search
        id={input.name}
        name={input.name}
        className={className}
        onSearch={onSearch}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
    />;
}

export default withFieldWrapper(InputSearchComponent);
