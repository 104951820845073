import React from 'react';
import qs from 'qs';
import { head, pathOr } from 'ramda';
import { Spin } from 'antd';
import axios from 'axios';

import QrModal from '../qr/QrModal';
import { BANNER } from '../../../constants/urls';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const PublicVacanciesPrintModal = props => {
    const banners = useCompanyCodeSwr([BANNER.stringify(), props.params.id], (url, vacancyUnit) => axios.get(url, {
        params: {
            filter: {
                vacancyUnit
            },
            relations: ['vacancy', 'vacancyUnit']
        }
    }));

    const getData = () => {
        const { params } = props;
        const data = head(pathOr([], ['data', 'items'], banners));

        return data || {
            vacancyUnit: params.id,
            name: params.title
        };
    }

    const getPublicVacanciesLink = () => {
        const searchParams = qs.stringify({ vacancyUnit: props.params.id }, { addQueryPrefix: true });

        return `${window.location.origin}/t${searchParams}`;
    }

    return <QrModal
        {...props}
        data={getData()}
        title='Распечатать вакансии'
        qr={getPublicVacanciesLink()}
        renderContent={node =>
            banners.isValidating ?
                <Spin className='central-spin' /> :
                node
        } />;
}

export default PublicVacanciesPrintModal;
