import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { useTranslation, withTranslation } from 'react-i18next';

import Upload from './formComponents/Upload';
import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import { document } from '../../constants/files';
import withUserCompany from '../hocs/withUserCompany';

const UserImportForm = props => {
    const { t } = useTranslation();
    const { isAdmin, isFunctionalLicense } = props;

    return (
        <Fragment>
            <Field
                name='file'
                component={Upload}
                accept={`${document.xlsx},.xlsx`}
                label={t('userAdmin.chooseFile')}
                uploadHelper={(
                    <a
                        className='ant-btn'
                        href={isFunctionalLicense && !isAdmin ? '/assets/Шаблон для импорта пользователей (Работающий функционал).xlsx' : '/assets/Шаблон для импорта пользователей.xlsx'}
                        download
                        style={{ float: 'right', marginRight: 55 }}>
                        {t('userAdmin.downloadFile')}
                    </a>
                )}
                required
                disableClear
            />
            <SubmitButton>
                {t('userAdmin.import')}
            </SubmitButton>
        </Fragment>
    );
}

const validationSchema = () => yup.object().shape({
    file: yup.mixed().fileType(['xlsx'], 'mixed.fileTypeImport').required(),
});

export default withUserCompany(withTranslation()(withFormWrapper(UserImportForm, {
    validationSchema,
    mapPropsToValues: ({ isAdmin, companyId }) => isAdmin ? ({
        company: companyId,
    }) : {},
    mapBeforeSubmit: ({ file, company }) => {
        const formData = new FormData();
        file.forEach(file => {
            formData.append('file', file);
        });

        return {
            file: formData,
            company,
        };
    },
})));
