import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableList from '../../table/TableList';
import CallRequestsFilter from '../../filters/CallRequestsFilter';
import { CALL_REQUEST } from '../../../constants/urls';

const CallRequests = () => {
    const { t } = useTranslation();

    const getColumns = () => {
        return [
            {
                title: t('call.phone'),
                dataIndex: 'phone',
                key: 'phone'
            },
            {
                title: t('call.name'),
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: t('call.vacancy'),
                dataIndex: ['_relations', 'vacancy'],
                key: 'vacancy',
                render: (vacancy, { vacancy : vacancyId }) =>
                    vacancy && <Link to={`/vacancy/${vacancyId}`}>{ vacancy.title }</Link>
            }
        ];
    };

    return <TableList
        actionUrl={CALL_REQUEST.stringify()}
        filterForm={CallRequestsFilter}
        columns={getColumns()}
        relations={['vacancy', 'company']} />;
}

export default CallRequests;
