import React, { useEffect, useState } from 'react';
import { equals, assocPath, find, forEachObjIndexed, path, toLower, has, is, keys, startsWith } from 'ramda';
import { Form, FormSpy } from 'react-final-form';
import { Form as FormComponent, Alert } from 'antd';
import arrayMutators from 'final-form-arrays';
import { withState } from 'recompose';
import { useTranslation } from 'react-i18next';

import { getRequiredFields } from '../../utils/getRequiredFields';
import RequiredFieldsContext from '../../contexts/RequiredFieldsContext';
import FormSubmittingContext from '../../contexts/FormSubmittingContext';
import FormServerErrorsContext from '../../contexts/FormServerErrorsContext';
import ERRORS, { ERRORS_LABELS, DEFAULT_ERROR } from '../../constants/errors';
import InitialValuesContext from '../../contexts/InitialValuesContext';
import usePrevious from '../../utils/usePrevious';

const getServerError = (values, e, t) => {
    const fields = path(['data', 'errors'], e);
    const fieldErrors = {};

    if (fields) {
        forEachObjIndexed((errors, field) => {
            errors.forEach(error => {
                const template = ERRORS[toLower(error.messageTemplate)] || ERRORS[toLower(error.message)];

                let message = error.message;

                if (template) {
                    message = t(template)
                        .replace('$property', t(ERRORS_LABELS[field]) || field)
                        .replace('$value', values[field]);

                    if (error.constraints) {
                        error.constraints.forEach((value, index) => {
                            message = message.replace(`$constraint${index + 1}`, value);
                        });
                    }
                }

                fieldErrors[field] = message;
            });
        }, fields);
    }

    return fieldErrors;
};

export default (WrappedComponent, formOptions = {}) => {
    const enableReinitialize = has('enableReinitialize', formOptions) ? formOptions.enableReinitialize : true;

    const FormWrapper = props => {
        const getInitialValues = () => {
            return formOptions.mapPropsToValues ? formOptions.mapPropsToValues(props) : {};
        }

        const { t } = useTranslation();
        const [initialValues, setInitialValues] = useState(getInitialValues());
        const [pending, setPending] = useState(false);
        const [data, setData] = useState({});
        const [error, setError] = useState(null);
        const prevInitialValues = usePrevious(getInitialValues());
        const prevProps = usePrevious(props);

        const isSubmittingFn = () => {
            return pending;
        }

        useEffect(() => {
            const newInitialValues = getInitialValues();
            const reinitialize = is(Function, enableReinitialize) ? enableReinitialize(prevProps, props) : enableReinitialize;

            if (!equals(newInitialValues, prevInitialValues) && reinitialize) {
                setInitialValues(newInitialValues);
            }
        });

        const onSubmit = (values, form) => {
            const { formAction, setServerErrors, onSuccess, onSubmitFail } = props;
            const mapBeforeSubmit = props.mapBeforeSubmit || formOptions.mapBeforeSubmit;
            const formProps = {
                ...props,
                form
            };

            setServerErrors({});
            setError(null);

            if (!isSubmittingFn()) {
                setPending(true);

                Promise.resolve(formAction(mapBeforeSubmit ? mapBeforeSubmit(values, props) : values, props))
                    .then(data => {
                        setPending(false);
                        setData(data);
                        onSuccess && onSuccess(formProps, data);
                        formOptions.onSuccess && formOptions.onSuccess(formProps, data);
                    })
                    .catch(e => {
                        const errorMessage = path(['data', 'message'], e);
                        const networkErrorMessage = path(['origin', 'message'], e);
                        const error = {
                            message: errorMessage || networkErrorMessage,
                            status: e.status
                        };
                        setServerErrors(getServerError(values, e, t));
                        setPending(false);
                        setError(error);
                        onSubmitFail && onSubmitFail(formProps, error);
                        formOptions.onSubmitFail && formOptions.onSubmitFail(formProps, e);
                    });
            }
        }

        const handleSubmit = (e, props) => {
            onChangeSubmitFailed();
            const { afterSubmit } = formOptions;

            props.handleSubmit(e);

            if (afterSubmit && !props.invalid) {
                afterSubmit(props);
            }
        }

        const validate = values => {
            const schema = getValidationSchema();

            if (!schema) {
                return {};
            }

            try {
                schema.validateSync(values, { abortEarly: false });
            } catch (e) {
                return e.inner.reduce((errors, error) => {
                    const errorPath = error.path.split(/\.|\].|\[/).map(p => isNaN(Number(p)) ? p : Number(p));
                    return assocPath(errorPath, error.message, errors);
                }, {});
            }
        }

        const getValidationSchema = () => {
            const schema = formOptions.validationSchema;

            return schema ? (typeof schema === 'function' ? schema(props) : schema) : null;
        }

        const renderError = () => {
            let constraint = '';
            const template = find(text => startsWith(text, `${error.message}`), keys(ERRORS));
            if (template && !ERRORS[error.message]) {
                constraint = `${error.message}`.replace(template, '');
            }
            const message = error.status < 500 ? (ERRORS[template || error.message] || error.message) : (ERRORS[template || error.message] || DEFAULT_ERROR);

            return message && <Alert message={t(message).replace('$constraint1', constraint)} type='error' className='form-alert' />;
        }

        const onChangeSubmitFailed = () => {
            setTimeout(() => {
                const field = document.querySelector('.has-error');
                const item = document.querySelector('.ant-form-item-has-error');
                const fieldArray = document.querySelector('.has-array-error');

                if (field || item || fieldArray) {
                    (field || item || fieldArray).scrollIntoView({ behavior: 'smooth' });
                }
            });
        }

        const { serverErrors, layout, keepDirtyOnReinitialize } = props;
        const isSubmitting = isSubmittingFn();

        return (
            <InitialValuesContext.Provider value={initialValues}>
                <FormServerErrorsContext.Provider value={serverErrors}>
                    <RequiredFieldsContext.Provider value={getRequiredFields(getValidationSchema())}>
                        <Form
                            subscription={{ submitting: true, invalid: true, submitFailed: true, error: true, errors: true, values: true }}
                            onSubmit={onSubmit}
                            validate={validate}
                            mutators={arrayMutators}
                            initialValues={initialValues}
                            keepDirtyOnReinitialize={keepDirtyOnReinitialize}
                            render={formProps =>
                                <FormComponent
                                    layout={layout}
                                    onFinish={e => handleSubmit(e, formProps)}
                                    noValidate
                                    autoComplete={formOptions.autoComplete}
                                >
                                    { !formOptions.noErrorRender && error && renderError() }
                                    <FormSpy
                                        subscription={{ submitFailed: true }}
                                        onChange={onChangeSubmitFailed} />
                                    <FormSubmittingContext.Provider value={isSubmitting}>
                                        <WrappedComponent
                                            {...props}
                                            {...formProps}
                                            pending={pending}
                                            actionData={data}
                                            isSubmitting={isSubmitting}
                                        />
                                    </FormSubmittingContext.Provider>
                                </FormComponent>
                            }
                        />
                    </RequiredFieldsContext.Provider>
                </FormServerErrorsContext.Provider>
            </InitialValuesContext.Provider>
        );
    }

    FormWrapper.defaultProps = {
        layout: 'vertical',
        keepDirtyOnReinitialize: false,
    };

    return withState('serverErrors', 'setServerErrors', {})(FormWrapper);
}
