import React, { useContext } from 'react';
import { Button, Popconfirm, message } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined, UserOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { getUrlWithFilters } from '../../../utils/urlParams';
import TableList from '../../table/TableList';
import EntityFilter from '../../filters/EntityFilter';
import { JURISTIC_ENTITY } from '../../../constants/urls';
import ModalsContext from '../../../contexts/ModalsContext';
import ActionsContext from '../../../contexts/ActionsContext';
import { deleteJuristicEntityHandler } from '../../../actions/handlers';
import { OPEN_JURISTIC_ENTITY_MODAL } from '../../../constants/actionTypes';

const JuristicEntities = ({ isCompanyRoute }) => {
    const { t } = useTranslation();
    const modals = useContext(ModalsContext);
    const { revalidateAction } = useContext(ActionsContext);

    const actionUrl = JURISTIC_ENTITY.stringify();

    const deleteEntity = id => {
        deleteJuristicEntityHandler(id).then(() => {
            message.success(t('company.entityDeleteSuccess'));
            revalidateAction(actionUrl);
        }).catch(() => message.error(t('company.entityDeleteError')));
    }

    const getColumns = () => {
        return [
            {
                title: t('company.name'),
                key: 'title',
                dataIndex: 'title',
                render: (name, item) =>
                    <span className='span-link company-edit-btn' onClick={() => modals.open(OPEN_JURISTIC_ENTITY_MODAL, { item })}>
                        { name }
                    </span>
            },
            {
                title: t('company.company'),
                key: 'name',
                dataIndex: ['_relations', 'company', 'name'],
            },
            {
                key: 'users',
                dataIndex: 'id',
                title: t('company.users'),
                width: 140,
                className: 'company-users',
                render: (id, item) =>
                    <Link to={getUrlWithFilters(isCompanyRoute ? '/settings/users' : '/users', { company: item.company, juristicEntity: id })}>
                        <UserOutlined className='company-users-link' />
                    </Link>
            },
            {
                key: 'actions',
                width: 110,
                render: item =>
                    <div className='section-buttons'>
                        <Button
                            className='form-edit-btn'
                            icon={<EditOutlined />}
                            onClick={() => modals.open(OPEN_JURISTIC_ENTITY_MODAL, {
                                item,
                            })}
                        />
                        <Popconfirm
                            title={t('company.entityDeleteConfirm')}
                            placement='left'
                            onConfirm={() => deleteEntity(item.id)}
                            okText={t('form.delete')}
                            cancelText={t('userAdmin.cancel')}
                        >
                            <Button className='delete-btn' type='danger' icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </div>
            }
        ];
    }

    const renderButtons = () => {
        return (
            <Button
                className='toolbar-button'
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => modals.open(OPEN_JURISTIC_ENTITY_MODAL, {})}
            >
                { t('company.add') }
            </Button>
        )
    }

    return <TableList
            actionUrl={actionUrl}
            columns={getColumns()}
            buttons={renderButtons()}
            filterForm={EntityFilter}
            relations={['company']}
            param='table'
        />;
}

JuristicEntities.defaultProps = {
    staticFilter: { hasCompany: true }
};

export default JuristicEntities;
