import React from 'react';
import { Row, Col, Modal, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { path } from 'ramda';

const DictionaryLanguageModal = props => {
    const { t } = useTranslation();
    const { modal, params } = props;

    return (
        <Modal
            {...modal}
            width='calc(100% - 30px)'
            style={{ maxWidth: '1440px' }}
            title={t('vacancy.detail.translations')}
            closable={false}
            footer={null}
        >
            <div className='question-translations'>
                <Row gutter={20} className='question-translations-content'>
                    <Col span={3} className='labels-col'>
                        <div className='labels-wrapper'>
                            <div className='label'>{ t('vacancy.form.name') }:</div>
                        </div>
                    </Col>
                    <Col span={7}>
                        <div className='language'>{ t('vacancy.form.russian') }</div>
                        <Input
                            name={'title'}
                            value={path([params.name || 'name'], params.item)}
                            disabled
                        />
                    </Col>
                    <Col span={7}>
                        <div className='language'>{ t('vacancy.form.ukranian') }</div>
                        <Input
                            name={'translations.title.ua'}
                            value={path([...(params.path || ['translations']), 'ua'], params.item)}
                            disabled
                        />
                    </Col>
                    <Col span={7}>
                        <div className='language'>{ t('vacancy.form.english') }</div>
                        <Input
                            name={'translations.title.en'}
                            value={path([...(params.path || ['translations']), 'en'], params.item)}
                            disabled
                        />
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}

export default DictionaryLanguageModal;
