import React, { useContext } from 'react';
import { Field } from 'react-final-form';
import { Col } from 'antd';
import { contains } from 'ramda';
import { useTranslation } from 'react-i18next';

import BaseFilter from './base/BaseFilter';
import Select from '../forms/formComponents/Select';
import withFilterForm from './base/withFilterForm';
import { ADMIN } from '../../constants/roles';
import UserContext from '../../contexts/UserContext';
import CompanyCodeContext from '../../contexts/CompanyCodeContext';
import { COMPANY, JURISTIC_ENTITY } from '../../constants/urls';

export default withFilterForm(() => {
    const { user } = useContext(UserContext);
    const { companyCode } = useContext(CompanyCodeContext);
    const { t } = useTranslation();
    const showCompany = contains(ADMIN, user.roles) && !companyCode;

    return <BaseFilter
        actionUrl={JURISTIC_ENTITY.stringify()}
        searchFieldName='title'
        searchFieldPlaceholder={t('company.juristicEntity')}
        span={12}
        sorting={{
            field: 'createdAt',
            order: 'desc',
        }}
    >
        { showCompany &&
            <Col span={12}>
                <Field
                    name='company'
                    placeholder={t('userAdmin.company')}
                    component={Select}
                    actionUrl={COMPANY.stringify()}
                    searchKey='name'
                    namePath='name'
                    searchable
                    allowClear
                />
            </Col>
        }
    </BaseFilter>;
});
