import React from 'react';
import { Field } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';

import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';

const HookSettingsForm = ({ t }) => {
    return <div className='about-company-wrapper'>
        <Field
            name='applicantHook'
            component={Input}
            label={t('settings.applicantHook')} />
        <SubmitButton>
            { t('form.save') }
        </SubmitButton>
    </div>
}

const validationSchema = ({ t }) => yup.object().shape({
    applicantHook: yup.string().url(t('form.validation.string.url')).nullable()
});

export default withTranslation()(withFormWrapper(HookSettingsForm, {
    mapPropsToValues: ({ item }) => item,
    validationSchema
}));
