import { Col } from 'antd';
import React from 'react';
import { Field } from 'react-final-form';
import { withTranslation } from 'react-i18next';

import { LANDING, USERS_ADMIN } from '../../constants/urls';
import { getFullName } from '../../utils/fieldsHelpers';
import Select from '../forms/formComponents/Select';
import BaseFilter from './base/BaseFilter';
import withFilterForm from './base/withFilterForm';

export default withTranslation()(withFilterForm(({ t }) => (
    <BaseFilter
        actionUrl={LANDING.stringify()}
        searchFieldName='code'
        namePath='code'
        searchFieldPlaceholder={t('landing.name')}
        span={6}
        sorting={{
            field: 'createdAt',
            order: 'desc',
        }}
    >
        <Col span={6}>
            <Field
                name='createdBy'
                component={Select}
                placeholder={t('landing.author')}
                actionUrl={USERS_ADMIN.stringify()}
                searchKey='name'
                getNamePath={getFullName}
                sorting={{
                    field: 'lastName',
                    order: 'asc',
                }}
                searchable
                allowClear
            />
        </Col>
    </BaseFilter>
)));
