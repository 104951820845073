import moment from 'moment';

export const messageTimeOptions = {
    hour: 'numeric',
    minute: 'numeric',
}

export const getPublicationStatus = (datePublishedStart, datePublishedEnd) => {
    const currentDate = moment();
    const startDate = moment(datePublishedStart);
    const endDate = moment(datePublishedEnd);
    const expired =  currentDate.isAfter(endDate);
    const notPublished = currentDate.isBefore(startDate);

    return ({
        published: !expired && !notPublished && (currentDate.isBetween(startDate, endDate) || currentDate.isBefore(endDate) || currentDate.isAfter(startDate)),
        notPublished,
        expired,
        startDate: datePublishedStart && moment(datePublishedStart).format('DD.MM.YYYY'),
        endDate: datePublishedEnd && moment(datePublishedEnd).format('DD.MM.YYYY'),
    });
}

export const getMonths = (datePublishedStart, datePublishedEnd) => {
    const months = [];
    let currentMonth = moment(datePublishedStart);

    while (currentMonth.isBefore(moment(datePublishedEnd))) {
        months.push(currentMonth.format('YYYY-MM'));
        currentMonth = currentMonth.add(1, 'month');
    }

    return months;
}
