import React from 'react';
import { Switch } from 'react-router-dom';

import Login from './views/guest/Login';
import PasswordRecovery from './views/guest/PasswordRecovery';
import PasswordRecoveryConfirm from './views/guest/PasswordRecoveryConfirm';
import PasswordRefreshConfirm from './views/guest/PasswordRefreshConfirm';
import RegistrationConfirm from './views/guest/RegistrationConfirm';
import MessengerSelect from './views/public/MessengerSelect';
import Pda from './views/public/Pda';
import OAuthLogin, { OAuthRedirectView, ExperiumPasswordRecovery } from './views/oauth/OAuthLogin';
import Route from './Route';

const GuestRoutes = () => (
    <Switch>
        <Route path='/password-expired' component={PasswordRefreshConfirm} />
        <Route path='/password-recovery/:restoreToken' component={PasswordRecoveryConfirm} />
        <Route path='/password-recovery' component={PasswordRecovery} />
        <Route path='/registration/:id' component={RegistrationConfirm} />
        <Route path='/' component={Login} />
    </Switch>
);

export const PublicRoutes = () => [
    <Route key='messenger-select' path='/messenger-select' component={MessengerSelect} />,
    <Route key='pda' path='/pda' component={Pda} />,
];

export const ExperiumGuestRoutes = () => [
    <Route key='authorize' path='/oauth/authorize' component={OAuthLogin} />,
    <Route key='recovery' path='/oauth/password-recovery' component={ExperiumPasswordRecovery} />,
    <Route key='redirect' path='/oauth/redirect' component={OAuthRedirectView} />,
];

export default GuestRoutes;
