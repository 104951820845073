import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, message } from 'antd';
import { prop, has, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { BASE_URL, BOT, LANDING_BUILDER, LANDING_BUILDER_ANCOR, POST_FILE } from '../../../constants/urls';
import { getBotsPageLink, botLink } from '../../../utils/botLink';
import withUserCompany from '../../hocs/withUserCompany';
import { getToken } from '../../../utils/token';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const MODAL_FOOTER_HEIGHT = 53;

const LandingBuilder = props => {
    const { t, i18n } = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [height, setHeight] = useState(0);
    const [showFooter, setShowFooter] = useState(true);
    const iframe = useRef();
    const didMount = useRef(null);

    const postMessage = data => {
        const items = pathOr([], ['items'], data);
        const botLinks = items
            .filter(prop('subscribed'))
            .map(bot => ({
                type: bot.type,
                link: botLink(bot, props.vacancy),
            }));

        iframe.current.contentWindow.postMessage({
            command: 'setParams',
            params: {
                hideTemplates: ['gray', 'lime'],
                botLinks: [
                    ...botLinks,
                    {
                        type: 'jobot',
                        link: getBotsPageLink(props.vacancy),
                    }
                ],
                vacancies: props.vacancies,
            },
        }, '*');
    }

    const bots = useCompanyCodeSwr(BOT.stringify(), {
        onSuccess: data => postMessage(data)
    });

    const receiveData = ({ data }) => {
        if (data.fromLanding) {
            if (data.data) {
                props.onGetData(JSON.parse(data.data));
            } else if (has('showSettings', data)) {
                setShowFooter(!data.showSettings);
            } else {
                message.warning(t('landing.chooseTemplate'));
            }
        }
    }

    const load = () => {
        const url = `${window.location.hostname === 'localhost' ? 'https://findy.dev.experium.net' : window.location.origin}${BASE_URL}`;

        iframe.current.contentWindow.postMessage({
            command: 'setLanding',
            landing: props.data,
            language: i18n.language || 'ru',
            iframeUrl: window.location.origin,
            fileSettings: {
                fileGetUrl: `${url}/file/:id`,
                filePostUrl: `${url}${POST_FILE.stringify({ token: getToken() })}`
            },
            defaultSettings: {
                common: {
                    vacancyLink: getBotsPageLink(props.vacancy),
                    jobotForm: true,
                }
            }
        }, '*');

        setLoaded(true);
        postMessage(bots.data);
    }

    const setBuilderHeight = () => {
        const documentHeight = document.documentElement.clientHeight;

        if (height !== documentHeight) {
            setHeight(documentHeight);
        }
    }

    const getData = () => {
        iframe.current.contentWindow.postMessage({
            command: 'getData'
        }, '*');
    }

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            iframe.current.onload = load;
            setBuilderHeight();
            window.addEventListener('resize', setBuilderHeight);
            window.addEventListener('message', receiveData);
        }
    });

    useEffect(() => {
        return () => {
            window.removeEventListener('resize', setBuilderHeight);
            window.removeEventListener('message', receiveData);
        };
    }, []);

    const contentHeight = height - (showFooter ? MODAL_FOOTER_HEIGHT : 0);

    return <Fragment>
        <div style={{ height: contentHeight }}>
            <iframe
                ref={iframe}
                data-cy='builder'
                title='landing builder'
                src={props.companyCode === 'ancor' ? LANDING_BUILDER_ANCOR : LANDING_BUILDER}
                width='100%'
                height='100%'
                frameBorder='0'
            />
        </div>
        { showFooter &&
            <div className='ant-modal-footer'>
                <Button
                    type='primary'
                    disabled={!loaded || props.pending}
                    onClick={getData}>
                    {t('form.save')}
                </Button>
                <Button
                    onClick={props.close}>
                    {t('landing.close')}
                </Button>
            </div>
        }
    </Fragment>;
}

export default withUserCompany(LandingBuilder);
