import React from 'react';
import { Tabs } from 'antd';
import { contains, path } from 'ramda';
import { useTranslation } from 'react-i18next';

import NoMatch from '../../NoMatch';
import Route from '../../Route';
import withUserCompany from '../../hocs/withUserCompany';
import QuestionsSettings from './question/QuestionsSettings';
import FormPresetsSettings from './formPreset/FormPresetsSettings';

const SettingsForms = props => {
    const { t } = useTranslation();

    const onChange = key => {
        props.history.push(`/forms/${key}`);
    }

    const { location } = props;
    const activeKey = path([0], location.pathname.replace(/\/forms\/?/, '').split('/'));

    return contains(activeKey, ['', 'questions', 'form', 'domains']) ?
        <Tabs
            className='settings-tabs'
            animated={{ tabPane: false }}
            activeKey={activeKey}
            onChange={onChange}
        >
            <Tabs.TabPane tab={t('vacancy.questions.presetQuestionnaires')} key=''>
                <Route path='/forms' component={FormPresetsSettings} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('vacancy.questions.reserved')} key='questions'>
                <Route path='/forms/questions' component={QuestionsSettings} />
            </Tabs.TabPane>
        </Tabs> :
        <Route component={NoMatch} />;
}

export default withUserCompany(SettingsForms);
