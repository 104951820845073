import React from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import SmsLogsFilter from '../../filters/SmsLogsFilter';
import TableList from '../../table/TableList';
import DateCell from '../../table/tableCells/DateCell';
import { SMS } from '../../../constants/urls';

const getStatusIcon = checked => checked ? <CheckOutlined style={{ color: 'rgb(82, 196, 26)' }} /> : <CloseOutlined style={{ color: '#f5222d' }} />;

const SmsLogs = () => {
    const { t } = useTranslation();

    const getColumns = () => {
        return [
            {
                title: t('call.phone'),
                dataIndex: ['_relations', 'phone', 'number'],
                key: 'phone'
            },
            {
                title: t('call.terminal'),
                dataIndex: 'terminal',
                key: 'terminal'
            },
            {
                title: t('call.vacancy'),
                dataIndex: ['_relations', 'vacancy', 'title'],
                key: 'vacancy'
            },
            {
                title: t('call.createdDate'),
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: date => <DateCell date={date} />,
            },
            {
                title: t('call.code'),
                dataIndex: ['_relations', 'phone', 'code'],
                key: 'code'
            },
            {
                title: t('call.numberVerified'),
                dataIndex: ['_relations', 'phone', 'verified'],
                key: 'verified',
                render: getStatusIcon,
                align: 'center'
            },
            {
                title: t('call.delivered'),
                dataIndex: 'delivered',
                key: 'delivered',
                render: getStatusIcon,
                align: 'center'
            }
        ];
    };

    return <TableList
        actionUrl={SMS.stringify()}
        columns={getColumns()}
        filterForm={SmsLogsFilter}
        relations={['phone', 'vacancy']}
        sorting={{
            field: 'createdAt',
            order: 'desc',
        }} />;
}

export default SmsLogs;
