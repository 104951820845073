import React, { useContext, useState } from 'react';
import { Button, Spin, Popconfirm, Select, message } from 'antd';
import { ArrowLeftOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { assoc, filter, includes, path } from 'ramda';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import TableList from '../../../table/TableList';
import { COMPANY_DICTIONARY, COMPANY_DICTIONARY_ITEM } from '../../../../constants/urls';
import { deleteCompanyDictionaryItemHandler } from '../../../../actions/handlers';
import ModalsContext from '../../../../contexts/ModalsContext';
import { OPEN_COMPANY_DICTIONARY_ITEM_MODAL, OPEN_COMPANY_DICTIONARY_MODAL } from '../../../../constants/actionTypes';
import ActionsContext from '../../../../contexts/ActionsContext';
import { AVAILABLE_LANGUAGES } from '../../../../constants/dictionary';
import useCompanyCodeSwr from '../../../../utils/useCompanyCodeSwr';

const CompanyDictionaryItem = props => {
    const { t } = useTranslation();
    const history = useHistory();
    const dictionaryId = path(['params', 'id'], props.match);
    const companyDictionary = useCompanyCodeSwr(COMPANY_DICTIONARY.stringify({ id: dictionaryId }), url => axios.get(url, {
        params: {
            relations: ['parent']
        }
    }));
    const modals = useContext(ModalsContext);
    const actions = useContext(ActionsContext);
    const [language, setLanguage] = useState(['en', 'ua']);
    const companyDictionaryParentId = path(['data', 'parent'], companyDictionary);

    const getLanguageColumn = index => {
        return {
            title: <div>
                { t('settings.chooseLanguage') }
                <Select
                    value={language[index]}
                    onChange={lang => {
                        const value = assoc(index, lang, language);
                        setLanguage(value);
                    }}
                    style={{ width: 90, marginLeft: 10 }}>
                    { filter(lang => lang === language[index] || !includes(lang, language), AVAILABLE_LANGUAGES).map(lang =>
                        <Select.Option key={`language-${lang}-${index}`} value={lang}>
                            { lang.toUpperCase() } <span className={`flag flag-${lang}`} />
                        </Select.Option>
                    )}
                </Select>
            </div>,
            key: 'translation',
            render: path(['translations', 'value', language[index]])
        };
    }

    const getColumns = () => {
        return ([
            {
                title: t('settings.name'),
                dataIndex: 'value',
                key: 'name',
            },
            getLanguageColumn(0),
            getLanguageColumn(1),
            {
                title: t('settings.parentElement'),
                key: 'parent',
                render: item => {
                    const parentName = path(['_relations', 'parent', 'value'], item);

                    return (
                        <span className='span-link'>
                            { parentName }
                        </span>
                    );
                }
            },
            {
                key: 'actions',
                width: 106,
                render: (item, _, index) => {
                    const parentDictionaryId = path(['data', '_relations', 'parent', 'id'], companyDictionary);

                    return (
                        <div className='classifier-section-buttons'>
                            <Button
                                id={`edit-item-btn-${index}`}
                                className={`edit-item-btn`}
                                icon={<EditOutlined />}
                                onClick={() => modals.open(OPEN_COMPANY_DICTIONARY_ITEM_MODAL, { item, parentDictionaryId })}
                            />
                            <Popconfirm
                                title={<div className='table-confirm-title'>
                                    <p>{t('settings.deleteDictionaryElementAttention')}</p>
                                    <p>{t('settings.deleteDictionaryElementMessage', { name: `${item.value || ''}` })}</p>
                                    {t('settings.deleteDictionaryElementConfirm', { name: `${item.value || ''}` })}
                                </div>}
                                okText={t('settings.yes')}
                                cancelText={t('settings.no')}
                                onConfirm={() => deleteCompanyDictionaryItemHandler(item.id).then(() => {
                                    message.success(t('settings.deleteDictionaryItemSuccess'))
                                    actions.revalidateAction(COMPANY_DICTIONARY_ITEM.stringify());
                                }).catch(() => message.error('settings.deleteDictionaryItemError'))}
                                placement='left'
                            >
                                <Button
                                    id={`delete-item-btn-${index}`}
                                    className={`delete-item-btn`}
                                    type='danger'
                                    icon={<DeleteOutlined />}
                                />
                            </Popconfirm>
                        </div>
                    );
                }
            }
        ]);
    }

    const getButtons = () => <Button
        icon={<PlusOutlined />}
        id='add-item-btn'
        type='primary'
        onClick={() => modals.open(OPEN_COMPANY_DICTIONARY_ITEM_MODAL, {
            item: { dictionary: dictionaryId, parentDictionaryId: companyDictionaryParentId },
            parentDictionaryId: companyDictionaryParentId
        })}>
        { t('company.addNewElement') }
    </Button>;

    return <div>
        <div className='toolbar vacancy-toolbar'>
            <Button
                shape='circle'
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                    history.length === 1 ? history.push('/settings/dictionaries') : history.goBack();
                }}
            />
            <h1>{ path(['data', 'name'], companyDictionary) }</h1>

            <div className='toolbar-actions'>
                <Button
                    icon={<EditOutlined />}
                    onClick={() => modals.open(OPEN_COMPANY_DICTIONARY_MODAL, {
                        item: companyDictionary.data,
                        refreshDictionary: () => companyDictionary.mutate()
                    })}
                >
                    { t('settings.edit') }
                </Button>
            </div>
        </div>
        {/* <CompanyDictionaryAddItemForm
            formAction={postCompanyDictionaryItemHandler}
            item={{ dictionary: dictionaryId }}
            onSuccess={() => actions.revalidateAction(COMPANY_DICTIONARY_ITEM.stringify())}
        /> */}
        { companyDictionary.isValidating && !companyDictionary.data ?
            <Spin className='central-spin' /> :
            <div style={{ padding: 20 }}>
                <TableList
                    actionUrl={COMPANY_DICTIONARY_ITEM.stringify()}
                    staticFilter={{ dictionary: dictionaryId }}
                    headers={{
                        'Accept-Language': 'ru',
                    }}
                    columns={getColumns()}
                    relations={['parent']}
                    buttons={getButtons()}
                />
            </div>
        }
    </div>;
}

export default CompanyDictionaryItem;
