import React, { useContext } from 'react';
import { Alert, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import PasswordRefreshConfirmForm from '../../forms/PasswordRefreshConfirmForm';
import UserContext from '../../../contexts/UserContext';
import { postPasswordRefreshConfirmHandler } from '../../../actions/handlers';
import { getToken, handleTokenChange } from '../../../utils/token';
import GuestLink from './GuestLink';

const PasswordRefreshConfirm = props => {
    const history = useHistory();
    const user = useContext(UserContext);
    const { t } = useTranslation();
    const restoreToken = getToken() || props.restoreToken;

    const onSuccess = () => {
        user.load();
        notification.success({
            message: t('guest.passwordRecoveryTitle'),
            description: t('guest.recoverySuccess'),
        });
        props.history.push('/');
    };

    return (
        <div>
            <h3>{ t('app.errors.changeExpiredPassword') }</h3>
            { !restoreToken ? (
                <div>
                    <div>{ t('guest.linkInactive') }</div>
                    <div className='link-block'>
                        <GuestLink to={props.experiumRecovery ? '/oauth/authorize' : '/'}>{ t('guest.login') }</GuestLink>
                    </div>
                </div>
            ) : (
                <div>
                    <Alert className='form-alert' message={ t('app.errors.passwordExpired') } />
                    <PasswordRefreshConfirmForm
                        formAction={postPasswordRefreshConfirmHandler}
                        restoreToken={restoreToken}
                        onSuccess={props.onSuccess || onSuccess}
                        onSubmitFail={() => notification.error({
                            message: t('guest.passwordRecoveryTitle'),
                            description: t('guest.recoveryLinkError'),
                        })}
                    />
                    <div className='link-block'>
                        <a onClick={() => {
                            if (props.experiumRecovery) {
                                history.push('/oauth/password-recovery');
                            } else {
                                user.reset();
                                handleTokenChange(null);
                                history.push('/password-recovery');
                            }
                        }}>{ t('guest.passwordRecovery') }</a>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PasswordRefreshConfirm;
