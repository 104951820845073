import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import { prop, split } from 'ramda';
import * as yup from 'yup';
import { useTranslation, withTranslation } from 'react-i18next';

import { URL_CODE } from '../../constants/regexps';
import Input from './formComponents/Input';
import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import { transformCodeValue } from '../../utils/companyCode';

const LandingCodeForm = props => {
    const { t } = useTranslation();

    const getLandingAddress = () => {
        const { companyCode } = props;
        const hostname = window.location.hostname;

        if (hostname === 'localhost') {
            return `http://${companyCode}.landing-findy.dev.experium.net/`;
        } else {
            const url = split('.', hostname);
            const hostType = url[1];

            switch (hostType) {
                case 'demo':
                    return `http://${companyCode}.landing-findy-demo.dev.experium.net/`;
                case 'dev':
                    return `http://${companyCode}.landing-findy.dev.experium.net/`;
                default:
                    return url[0] === `web-test`
                        ? `http://${companyCode}.landing-findy-test.dev.experium.net/`
                        : `https://${companyCode}.findy.hr-land.ru/`;
            }
        }
    };

    return <Fragment>
        <Field
            name='code'
            component={Input}
            label={t('landing.address')}
            parse={transformCodeValue}
            addonBefore={getLandingAddress()}
        />
        <Field
            name='settings.yandexMetrics'
            component={Input}
            label={t('landing.yandexMetricsNumber')}
            placeholder='48423391' />
        <Field
            name='settings.googleMetrics'
            component={Input}
            label={t('landing.googleMetricsNumber')}
            placeholder='UA-76488621-1' />
        <div className='modal-form-toolbar'>
            <SubmitButton>
                { t('form.save') }
            </SubmitButton>
        </div>
    </Fragment>;
}

const validationSchema = yup.object().shape({
    code: yup.string().code(URL_CODE).required()
});

export default withTranslation()(withFormWrapper(LandingCodeForm, {
    mapPropsToValues: prop('item'),
    validationSchema
}));
