import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { useTranslation, withTranslation } from 'react-i18next';

import Upload from './formComponents/Upload';
import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import { document } from '../../constants/files';

const CompanyDictionaryImportForm = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Field
                name='file'
                component={Upload}
                accept={`${document.xlsx},.xlsx`}
                label={t('company.chooseFile')}
                uploadHelper={(
                    <a className='ant-btn' href='/assets/Шаблон для импорта словаря компании.xlsx' download style={{ float: 'right', marginRight: 90 }}>
                        { t('company.downloadFileDictionaryImport') }
                    </a>
                )}
                required
                disableClear
            />
            <SubmitButton>
                { t('userAdmin.import') }
            </SubmitButton>
        </Fragment>
    );
}

const validationSchema = yup.object().shape({
    file: yup.mixed().fileType(['xlsx'], 'mixed.fileTypeImport').required()
});

export default withTranslation()(withFormWrapper(CompanyDictionaryImportForm, {
    validationSchema,
    mapBeforeSubmit: ({ file }) => {
        const formData = new FormData();
        file.forEach(file => {
            formData.append('file', file);
        });

        return {
            file: formData,
        };
    },
}));
