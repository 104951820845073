import React from 'react';
import { Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';

import CaptchaSettingsForm from '../../forms/CaptchaSettingsForm';
import { putCompanySettingsHandler } from '../../../actions/handlers';
import { COMPANY_SETTINGS } from '../../../constants/urls';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const CaptchaSettings = () => {
    const { t } = useTranslation();
    const companySettings = useCompanyCodeSwr(COMPANY_SETTINGS.stringify());

    return companySettings.data ?
        <CaptchaSettingsForm
            formAction={putCompanySettingsHandler}
            item={companySettings.data}
            onSuccess={() => message.success(t('settings.captchaSettingsSaveSuccess'))}
            onSubmitFail={() => message.error(t('settings.captchaSettingsSaveError'))} />
        : <Spin className='central-spin' spinning={companySettings.isValidating} />;
}

export default CaptchaSettings;
