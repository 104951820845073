import React from 'react';
import { Tabs } from 'antd';
import { contains, path } from 'ramda';
import { useTranslation } from 'react-i18next';

import NoMatch from '../../NoMatch';
import Route from '../../Route';
import MessageNodes from './messageNodes/MessageNodes';
import TextSettings from './TextSettings';
import BotsSettings from '../bot/BotsSettings';

const SettingsBots = props => {
    const { t } = useTranslation();
    const { location } = props;
    const activeKey = path([0], location.pathname.replace(/\/settings\/bots\/?/, '').split('/'));

    const onChange = key => {
        props.history.push(`/settings/bots/${key}`);
    };

    return contains(activeKey, ['', 'content', 'list']) ?
        <Tabs
            className='settings-tabs'
            animated={{ tabPane: false }}
            activeKey={activeKey}
            onChange={onChange}
        >
            <Tabs.TabPane tab={t('settings.main')} key=''>
                <Route path='/settings/bots' exact component={TextSettings} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('settings.content')} key='content'>
                <Route path='/settings/bots/content' component={MessageNodes} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('settings.bots')} key='list'>
                <Route path='/settings/bots/list' component={BotsSettings} />
            </Tabs.TabPane>
        </Tabs> :
        <Route component={NoMatch} />;
}

export default SettingsBots;
