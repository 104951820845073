import React, { useContext } from 'react';

import { StyledBannerModal } from './UnitBannerModal';
import BannerModalContent from './BannerModalContent';
import { putVacancyHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { COMPANY_SETTINGS, VACANCY } from '../../../constants/urls';
import { PUT_VACANCY } from '../../../constants/actionTypes';

const VacancyBannerModal = props => {
    const { modal, close } = props;
    const { revalidateAction, onSuccessFn } = useContext(ActionsContext);

    return <StyledBannerModal
        {...modal}
        title={null}
        footer={null}
        closable={false}>
        <BannerModalContent
            {...props}
            formAction={putVacancyHandler}
            onSuccess={() => {
                onSuccessFn(PUT_VACANCY);
                revalidateAction(COMPANY_SETTINGS.stringify());
                revalidateAction(VACANCY.stringify())
                close();
            }} />
    </StyledBannerModal>;
}

export default VacancyBannerModal;
