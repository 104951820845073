import React, { useState } from 'react';
import { Modal, Checkbox, Button, Tooltip, Spin } from 'antd';
import { propEq, pathOr, contains, prop, any, groupBy, append, remove, findIndex, find, assocPath, path, propOr, range } from 'ramda';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import withUserCompany from '../../hocs/withUserCompany';
import { QUESTIONS_PRESET } from '../../../constants/urls';
import { RESERVED_QUESTIONS_LIMIT } from '../../../constants/questions';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const VacancyQuestionsModal = props => {
    const { t, i18n } = useTranslation();
    const [questions, setQuestions] = useState(propOr([], 'questions', props.params));
    const questionsPreset = useCompanyCodeSwr(QUESTIONS_PRESET.stringify(), url => axios.get(url, {
        params: {
            filter: {
                company: props.params.company
            },
            pagination: {
                limit: 0
            }
        },
        headers: {
            'Accept-Language': 'ru',
        }
    }))

    const addQuestion = question => {
        setQuestions(append(question, questions));
    };

    const removeQuestion = question => {
        setQuestions(remove(
            findIndex(propEq('field', question.field), questions),
            1,
            questions
        ));
    };

    const addSubQuestion = (question, subQuestion) => {
        const questionIndex = findIndex(propEq('field', question.field), questions);
        const subQuestions = path([questionIndex, 'settings', 'questions'], questions);

        setQuestions(assocPath([
            questionIndex,
            'settings',
            'questions',
        ], [...subQuestions, subQuestion], questions));
    };

    const removeSubQuestion = (question, subQuestion) => {
        const questionIndex = findIndex(propEq('field', question.field), questions);
        const subQuestions = path([questionIndex, 'settings', 'questions'], questions);
        const subQuestionIndex = findIndex(propEq('field', subQuestion.field), subQuestions);
        const newSub = remove(subQuestionIndex, 1, subQuestions);

        setQuestions(assocPath([
            questionIndex,
            'settings',
            'questions',
        ], newSub, questions));

        if (!newSub.length) {
            removeQuestion(question)
        }
    };

    const getQuestions = () => {
        const items = pathOr([], ['data', 'items'], questionsPreset);

        return {
            ...groupBy(item =>
                item.type === 'composite' && !item.customizable ? (item.field === 'experience' ? 'experienceBlock' : 'block') :
                item.customizable ? 'own' : 'preset',
                items
            ),
            reserved: range(1, 16).map(num => ({
                label: t('vacancy.questions.reservedQuestion', { num }),
                question: t('vacancy.questions.reservedQuestion', { num }),
                field: `field_${num}`,
                type: 'text',
                customizable: true,
                required: true
            }))
        };
    }

    const getSelected = () => {
        return (questions || []).map(prop('field'));
    }

    const getSelectedSubQuestions = (fieldName) => {
        const blockQuestion = find(propEq('field', fieldName), questions);

        return pathOr([], ['settings', 'questions'], blockQuestion).map(prop('field'));
    }

    const toggleQuestion = (e, question) => {
        e.target.checked ? addQuestion(question) : removeQuestion(question);
    }

    const toggleSubQuestion = (e, question, subQuestion) => {
        if (e.target.checked) {
            !contains(question.field, getSelected()) ? (
                addQuestion(assocPath(['settings', 'questions'], [subQuestion], question))
            ) : (
                addSubQuestion(question, subQuestion)
            );
        } else {
            removeSubQuestion(question, subQuestion);
        }
    }

    const renderNotCustomizableQuestion = (question, selected) => {
        const selectedQuestion = contains(question.field, selected);
        const isOPD = question.field === 'personalDataAgreement';

        return question.type === 'composite' ? renderBlockQuestion(question, selected) : <label
            key={`not-customizable-${question.field}`}
            className='vacancy-questions-item'>
            <Tooltip
                title={selectedQuestion ? t('vacancy.questions.fieldAdded') : null}
                placement='right'>
                <Checkbox
                    checked={!!(selectedQuestion || any(propEq('field', question.field), questions)) || isOPD}
                    disabled={isOPD}
                    onChange={e => toggleQuestion(e, question)} />
            </Tooltip>
            <div>{ pathOr(question.label, ['translations', 'label', i18n.language], question) }</div>
        </label>;
    }

    const renderBlockQuestion = (question, selected) => {
        const selectedQuestion = contains(question.field, selected);
        const blockQuestions = pathOr([], ['settings', 'questions'], question);
        const selectedSubQuestions = getSelectedSubQuestions(question.field);

        return (
            <div className='block-question' key={`not-customizable-${question.field}`}>
                <label className='vacancy-questions-item'>
                    <Tooltip
                        title={selectedQuestion ? t('vacancy.questions.fieldAdded') : null}
                        placement='right'>
                        <Checkbox
                            checked={!!(selectedQuestion || any(propEq('field', question.field), questions))}
                            onChange={e => toggleQuestion(e, question)}
                        />
                    </Tooltip>
                    <div>{ pathOr(question.label, ['translations', 'label', i18n.language], question) }</div>
                </label>
                <div className='block-questions'>
                    { blockQuestions.map(subQuestion => {
                        return (
                            <label
                                key={`not-customizable-${subQuestion.field}`}
                                className='vacancy-questions-item'
                            >
                                <Checkbox
                                    checked={contains(subQuestion.field, selectedSubQuestions)}
                                    onChange={e => toggleSubQuestion(e, question, subQuestion)}
                                />
                                <div>{ pathOr(subQuestion.label, ['translations', 'label', i18n.language], subQuestion) }</div>
                            </label>
                        );
                    })}
                </div>
            </div>
        );
    }

    const save = () => {
        props.params.onChange(questions);
        props.close();
    }

    const renderFooter = () => {
        return (
            <div>
                <Button
                    style={{ marginBottom: 7, float: 'left' }}
                    onClick={() => {
                        props.remove();
                        props.params.openQuestionModal();
                    }}
                >
                    { t('vacancy.questions.createCustom') }
                </Button>
                <Button type='primary' onClick={save}>
                    { t('vacancy.questions.add') }
                </Button>
            </div>
        );
    }

    const { modal } = props;
    const { preset = [], own = [], block = [], experienceBlock = [] } = getQuestions();
    const selected = getSelected();

    return <Modal
        {...modal}
        width='calc(100% - 30px)'
        style={{ maxWidth: '1440px' }}
        title={t('vacancy.questions.chooseQuestion')}
        footer={renderFooter()}>
        { questionsPreset.isValidating && !questionsPreset.data ?
            <Spin className='central-spin' /> :
            <div className='vacancy-questions-preset'>
                <div className='vacancy-questions-preset-col-block'>
                    <div className='vacancy-questions-preset-title'>{ t('vacancy.questions.presets') }</div>
                    <div className='vacancy-questions-preset'>
                        <div className='vacancy-questions-preset-col'>
                            { preset.map(question =>
                                renderNotCustomizableQuestion(question, selected)
                            )}
                        </div>
                        <div className='vacancy-questions-preset-col'>
                            { block.map(question =>
                                renderBlockQuestion(question, selected)
                            )}
                            { experienceBlock.map(question =>
                                renderBlockQuestion(question, selected)
                            )}
                        </div>
                    </div>
                </div>
                <div className='vacancy-questions-preset-col-reserved'>
                    <div className='vacancy-questions-preset-title'>{ t('vacancy.questions.reserved') }</div>
                    { own.map(question =>
                        renderNotCustomizableQuestion(question, selected)
                    )}
                    <div className='vacancy-questions-preset-help' dangerouslySetInnerHTML={{ __html: t('vacancy.questions.presetsHelp', { limit: RESERVED_QUESTIONS_LIMIT }) }}></div>
                </div>
            </div>
        }
    </Modal>;
}

export default withUserCompany(VacancyQuestionsModal);
