import React from 'react';
import { Field } from 'react-final-form';
import { Col } from 'antd';
import { withTranslation } from 'react-i18next';

import { manualState } from '../../constants/chat';
import BaseFilter from './base/BaseFilter';
import Select from '../forms/formComponents/Select';
import RangeDatePicker from '../forms/formComponents/RangeDatePicker';
import withFilterForm from './base/withFilterForm';
import BotSelect from '../forms/formComponents/BotSelect';
import { BOT, DIALOGS } from '../../constants/urls';

export default withTranslation()(withFilterForm(({ t }) => (
    <BaseFilter
        searchFieldName='name'
        searchFieldPlaceholder={t('dialog.user')}
        actionUrl={DIALOGS.stringify()}
        span={6}
        sorting={{
            field: 'updatedAt',
            order: 'desc',
        }}
    >
        <Col span={4}>
            <Field
                name='manual'
                component={Select}
                options={manualState}
                namePath='value'
            />
        </Col>
        <Col span={4}>
            <Field
                name='bot'
                placeholder={t('dialog.bot')}
                component={Select}
                actionUrl={BOT.stringify()}
                namePath='username'
                valuePath='id'
                selectComponent={BotSelect}
                allowClear
            />
        </Col>
        <Col span={6}>
            <Field
                name='minUpdatedAt'
                component={RangeDatePicker}
                toPath='maxUpdatedAt'
            />
        </Col>
    </BaseFilter>
)));
