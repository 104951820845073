import React from 'react';
import { Button, Modal, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';

import withUserCompany from '../../../hocs/withUserCompany';
import TableStateList from '../../../table/TableStateList';
import FormSelectFilter from '../../../filters/FormSelectFilter';
import { VACANCY_FORM_PRESET } from '../../../../constants/urls';

const FormSelectModal = props => {
    const { t } = useTranslation();

    const getColumns = () => {
        return [
            {
                title: t('vacancy.questions.formPresetName'),
                dataIndex: 'title',
                key: 'title'
            },
            {
                key: 'control',
                width: 180,
                render: item => {
                    const selected = props.params.value === item.id;

                    return props.params.confirmText && !selected ? (
                        <Popconfirm
                            title={props.params.confirmText}
                            placement='topRight'
                            onConfirm={() => { props.params.onSelect(item.id, item); props.modal.onCancel(); }}
                        >
                            <Button
                                disabled={selected}
                            >
                                { selected ? t('vacancy.questions.selected') : t('vacancy.questions.select') }
                            </Button>
                        </Popconfirm>
                    ) : (
                        <div>
                            <Button
                                disabled={selected}
                                onClick={() => { props.params.onSelect(item.id, item); props.modal.onCancel(); }}
                            >
                                { selected ? t('vacancy.questions.selected') : t('vacancy.questions.select') }
                            </Button>
                            { props.params.onClear && (
                                <Popconfirm
                                    title={props.params.confirmText}
                                    placement='topRight'
                                    onConfirm={() => { props.params.onClear(); props.modal.onCancel(); }}
                                >
                                    <Button type='danger' style={{ marginLeft: 8 }}>
                                        <DeleteOutlined />
                                    </Button>
                                </Popconfirm>
                            )}
                        </div>
                    );
                }
            }
        ];
    };

    const { modal, params } = props;

    return (
        <Modal
            {...modal}
            title={null}
            footer={null}
        >
            <TableStateList
                filterForm={FormSelectFilter}
                staticFilter={{
                    user: null,
                }}
                sorting={{
                    field: 'createdAt',
                    order: 'desc',
                }}
                param={{ type: `form-select-${params.name}` }}
                actionUrl={VACANCY_FORM_PRESET.stringify()}
                columns={getColumns()}
                relations={['domains']}
            />
        </Modal>
    );
}

export default withUserCompany(FormSelectModal);
