import React, { useContext } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import VacancyTranslationForm from '../../forms/vacancyForm/VacancyTranslationForm';
import { putVacancyHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { COMPANY_SETTINGS, VACANCY } from '../../../constants/urls';
import { PUT_VACANCY } from '../../../constants/actionTypes';

const VacancyTranslationModal = props => {
    const { t } = useTranslation();
    const { revalidateAction, onSuccessFn } = useContext(ActionsContext);
    const { modal, params: { values }, close }  = props;

    return (
        <Modal
            {...modal}
            wrapClassName='vacancy-translation-modal'
            title={t('vacancy.detail.addTranslations')}
            footer={null}
        >
            <VacancyTranslationForm
                formAction={putVacancyHandler}
                values={values}
                {...modal}
                onSuccess={() => {
                    onSuccessFn(PUT_VACANCY);
                    revalidateAction(COMPANY_SETTINGS.stringify());
                    revalidateAction(VACANCY.stringify());
                    close();
                }}
                width='initial'
            />
        </Modal>
    );
}

export default VacancyTranslationModal;
