import React from 'react';
import { Modal } from 'antd';
import { Map, Placemark } from 'react-yandex-maps';

const MapModal = props => {
    const { params: { address, location }, modal } = props;

    return (
        <Modal
            {...modal}
            className='map-modal'
            title={address}
            footer={null}
        >
            <div className='ymap map-preview'>
                <Map
                    defaultState={{
                        center: location,
                        zoom: 15,
                    }}
                    width='100%'
                    height='100%'
                >
                    { location && <Placemark geometry={location} /> }
                </Map>
            </div>
        </Modal>
    );
}

export default MapModal;
