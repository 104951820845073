import React, { Fragment } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { path } from 'ramda';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';

import ColorPicker from '../formComponents/ColorPicker';
import Switch from '../formComponents/Switch';
import ListenerField from '../ListenerField';
import CubeVacancyPreview from './CubeVacancyPreview';
import { PhoneInput } from '../formComponents/MaskedInput';
import Select from '../formComponents/Select';
import { VACANCY_CATEGORIES } from '../../../constants/urls';

const TerminalFields = props => {
    const { t } = useTranslation();

    const onChangeUseCustomColor = e => {
        const { companySettings, form } = props;
        const useCompanyColor = e.target.value;

        form.batch(() => {
            form.change('companySettings.theme.vacancyColor', path(['theme', 'vacancyColor'], companySettings)),
            form.change('terminalSettings.backgroundColor', useCompanyColor ? null : '#001529')
        });
    }

    const onChangeShowPhone = () => {
        props.form.change(
            'companySettings.theme.phone',
            path(['theme', 'phone'], props.companySettings)
        );
    }

    const renderColorFields = values => {
        const { validateOnChange, highlightError, edit } = props;
        const useCompanyColor = !path(['terminalSettings', 'backgroundColor'], values);

        return <Fragment>
            { edit && (
                <Field
                    name='terminalPublished'
                    component={Switch}
                    label={t('vacancy.terminal.published')}
                />
            )}
            { values.terminalPublished &&
                <Fragment>
                    <Field
                        name='category'
                        component={Select}
                        label={t('vacancy.terminal.category')}
                        placeholder={t('vacancy.terminal.chooseCategory')}
                        actionUrl={VACANCY_CATEGORIES.stringify()}
                        namePath='name'
                        allowClear={true}
                        disableLimit={true}
                        disabled={!edit}
                        searchable
                    />
                    <Field
                        name='terminalSettings.callRequestAllowed'
                        component={Switch}
                        label={t('vacancy.terminal.callRequestAllowed')}
                        disableClear
                        disabled={!edit}
                    />
                    <ListenerField listenFieldName='terminalSettings.callRequestAllowed'>
                        { ({ terminalSettings }) => path(['callRequestAllowed'], terminalSettings) &&
                            <Fragment>
                                <Field
                                    name='terminalSettings.showPhoneAllowed'
                                    component={Switch}
                                    label={t('vacancy.terminal.showCompanyPhone')}
                                    onChange={onChangeShowPhone}
                                    disableClear
                                    disabled={!edit}
                                />
                                <ListenerField listenFieldName='terminalSettings.showPhoneAllowed'>
                                    { ({ terminalSettings }) => path(['showPhoneAllowed'], terminalSettings) &&
                                        <Field
                                            name='companySettings.theme.phone'
                                            component={PhoneInput}
                                            label={t('vacancy.terminal.companyPhone')}
                                            required
                                            validateOnChange={validateOnChange}
                                            highlightError={highlightError}
                                            disableClear
                                            disabled={!edit}
                                        />
                                    }
                                </ListenerField>
                            </Fragment>
                        }
                    </ListenerField>
                    <Radio.Group onChange={onChangeUseCustomColor} value={useCompanyColor}>
                        <Radio.Button value={true}>{t('vacancy.terminal.useThemeColor')}</Radio.Button>
                        <Radio.Button value={false}>{t('vacancy.terminal.chooseOwnColor')}</Radio.Button>
                    </Radio.Group>
                    <Field
                        name={useCompanyColor ? 'companySettings.theme.vacancyColor' : 'terminalSettings.backgroundColor'}
                        component={ColorPicker}
                        label={t('vacancy.terminal.color')}
                        disableClear />
                    <Field
                        name={useCompanyColor ? 'terminalSettings.backgroundColor' : 'companySettings.theme.vacancyColor'}
                        component={() => null}
                        disableClear />
                </Fragment>
            }
        </Fragment>;
    }

    return <Fragment>
        <ListenerField listenFieldName={['terminalSettings.backgroundColor', 'terminalPublished']}>
            { renderColorFields }
        </ListenerField>
        <FormSpy subscription={{ values: true }}>
            { ({ values }) => values.terminalPublished ? <CubeVacancyPreview vacancy={values} /> : null }
        </FormSpy>
    </Fragment>;
}

export default TerminalFields;
