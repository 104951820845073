import React from 'react';
import { Switch } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const SwitchField = props => {
    const { onChange, input: { value, name }, disabled, size } = props;

    return (
        <Switch
            id={name}
            checked={!!value}
            onChange={onChange}
            disabled={disabled}
            size={size}
        />
    );
}

export default withFieldWrapper(SwitchField);
