import React, { useState } from 'react';
import { Modal, Tree, Input, Button } from 'antd';
import { toLower, trim, includes, filter, pathOr, prop, reduce, max, forEach, range, propEq, append, uniq, find, flatten, reverse } from 'ramda';
import debounce from 'debounce';
import { useTranslation } from 'react-i18next';

const parseItems = items => {
    return filter(({ children }) => !!children.length, items.map(item => {
        const options = pathOr([], ['_relations', 'options'], item);
        const getChildren = (item, level, options) => {
            const children = filter(child => child.level === level && child.parent === item.id, options).map(child => getChildren(child, level + 1, options));

            return {
                title: item.name,
                key: item.id,
                children
            };
        };

        return {
            title: item.name,
            key: item.id,
            children: filter(child => child.level === 0, options).map(child => getChildren(child, 1, options))
        };
    }))
}

const getExpanded = (items, selected) => {
    if (!(selected || []).length) {
        return [];
    }

    let expandedKeys = [];

    forEach(item => {
        const options = pathOr([], ['_relations', 'options'], item);
        const maxLevel = reduce(max, 0, options.map(prop('level')));
        let filteredOptions = [];

        forEach(level => {
            const levelOptions = filter(propEq('level', level), options);

            forEach(i => {
                if (includes(i.id, selected) || includes(i.id, filteredOptions)) {
                    filteredOptions = append(i.id, filteredOptions);
                    filteredOptions = append(i.classifier, filteredOptions);

                    if (i.parent) {
                        filteredOptions = append(i.parent, filteredOptions);
                    }
                }
            }, levelOptions);
        }, reverse(range(0, maxLevel + 1)));

        filteredOptions = uniq(filteredOptions);
        expandedKeys = append(filteredOptions, expandedKeys);

        return {
            ...item,
            _relations: {
                options: filteredOptions.map(id => find(propEq('id', id), options))
            }
        };
    }, items);

    return flatten(expandedKeys);
}

const SelectClassifiersModal = props => {
    const { t } = useTranslation();
    const [expandedKeys, setExpandedKeys] = useState(getExpanded(props.params.items, props.params.value));
    const [checkedKeys, setCheckedKeys] = useState(props.params.value);
    const [searchValue, setSearchValue] = useState('');
    const [tree, setTree] = useState(parseItems(props.params.items));

    const onExpand = keys => {
        setExpandedKeys(keys);
    };

    const onCheck = keys => {
        setCheckedKeys(keys);
    }

    const searchTree = value => {
        const searchValue = trim(toLower(value || ''));

        if (!searchValue) {
            setTree(parseItems(props.params.items));
            setExpandedKeys([]);

            return;
        }

        let expandedKeys = [];
        let tree = props.params.items;

        tree = filter(item => pathOr([], ['_relations', 'options'], item).length, tree.map(item => {
            const options = pathOr([], ['_relations', 'options'], item);
            const maxLevel = reduce(max, 0, options.map(prop('level')));
            let filteredOptions = [];

            forEach(level => {
                const levelOptions = filter(propEq('level', level), options);

                forEach(i => {
                    if (includes(searchValue, toLower(i.name)) || includes(i.id, filteredOptions)) {
                        filteredOptions = append(i.id, filteredOptions);

                        if (i.parent) {
                            filteredOptions = append(i.parent, filteredOptions);
                        }
                    }
                }, levelOptions);
            }, reverse(range(0, maxLevel + 1)));

            filteredOptions = uniq(filteredOptions);
            expandedKeys = append(filteredOptions, expandedKeys);

            return {
                ...item,
                _relations: {
                    options: filteredOptions.map(id => find(propEq('id', id), options))
                }
            };
        }));

        expandedKeys = flatten(expandedKeys);

        forEach(({ id }) => {
            expandedKeys = append(id, expandedKeys);
        }, tree);

        setTree(parseItems(tree));
        setExpandedKeys(expandedKeys);
    };

    const search = debounce(searchTree, 200);

    const onChangeSearch = e => {
        const { value } = e.target;

        setSearchValue(value);
        search(value);
    }

    const save = () => {
        props.params.onChange(checkedKeys);
        props.close();
    }

    const { modal } = props;

    return <Modal
        {...modal}
        title={t('form.classifiers')}
        footer={<Button type='primary' onClick={save}>{ t('form.save') }</Button>}>
        <Input.Search
            style={{ marginBottom: 8 }}
            placeholder={t('form.search')}
            onChange={onChangeSearch}
            value={searchValue} />
        { tree.length ?
            <Tree
                treeData={tree}
                onExpand={onExpand}
                onCheck={onCheck}
                expandedKeys={expandedKeys}
                selectable={false}
                checkedKeys={checkedKeys}
                checkable /> :
            <div style={{ textAlign: 'center' }}>{ t('form.noMatchesFound') }</div>
        }
    </Modal>;
}

export default SelectClassifiersModal;
