import React, { useContext } from 'react';
import { Button, Tooltip, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { contains, filter, not } from 'ramda';
import { PictureOutlined, SolutionOutlined, LinkOutlined, QrcodeOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import TableList from '../../table/TableList';
import EnabledSwitch from '../../table/EnabledSwitch';
import Address from '../../table/Address';
import Metro from '../../table/Metro';
import { DEEP_LINK_COPY_SUCCESS, DEEP_LINK_COPY_ERROR } from '../../../constants/messages';
import { USER, ADMIN, ADMIN_COMPANY, COMPANY_MANAGER, userHiddenColumns } from '../../../constants/roles';
import UnitFilters from '../../filters/UnitFilters';
import Vacancies from '../../views/vacancy/Vacancies';
import UserContext from '../../../contexts/UserContext';
import { putVacancyUnitHandler } from '../../../actions/handlers';
import { COMPANY_SETTINGS, VACANCY_UNIT } from '../../../constants/urls';
import ModalsContext from '../../../contexts/ModalsContext';
import { OPEN_PUBLIC_VACANCIES_PRINT_MODAL, OPEN_UNIT_BANNER_MODAL, OPEN_UNIT_MODAL } from '../../../constants/actionTypes';

const Units = props => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const modals = useContext(ModalsContext);

    const isManager = () => contains(COMPANY_MANAGER, user.roles) || contains(ADMIN, user.roles) || contains(ADMIN_COMPANY, user.roles);

    const userFilterColumn = (column) => not(contains(column.key, userHiddenColumns));

    const onCopy = (text, result) => {
        if (result) {
            message.success(t(DEEP_LINK_COPY_SUCCESS));
        } else {
            message.error(t(DEEP_LINK_COPY_ERROR));
        }
    }

    const getPublicVacanciesLink = vacancyUnit => {
        return `${window.location.origin}/t/unit/${vacancyUnit}`;
    }

    const openModalUrl = () => {
        props.history.push(`${props.location.pathname.replace('/slider', '')}/slider${props.location.search}`);
    }

    const getColumns = () => {
        const defaultColumn = [
            {
                title: t('unit.name'),
                key: 'title',
                render: item => (
                    isManager() ? (
                        <span
                            className='span-link'
                            onClick={() => modals.open(OPEN_UNIT_MODAL, item)}>
                            { item.title }
                        </span>
                    ) : (
                        <span>
                            { item.title }
                        </span>
                    )
                )
            },
            {
                title: t('unit.address'),
                dataIndex: 'address',
                key: 'address',
                render: (address, item)  => (
                    <div>
                        <Address address={address} location={item.location} />
                        <Metro stations={item.metro} showFull={false} />
                    </div>
                )
            },
            {
                title: t('unit.terminal'),
                key: 'enabled',
                render: item => <EnabledSwitch  path='enabled' item={item} action={putVacancyUnitHandler} revalidateAction={[VACANCY_UNIT.stringify(), COMPANY_SETTINGS.stringify()]} />
            },
            {
                title: t('unit.slider'),
                key: 'banner',
                width: 80,
                align: 'center',
                render: item =>
                    <Button
                        className='unit-slider-btn'
                        icon={<PictureOutlined />}
                        onClick={() => {
                            openModalUrl();
                            setTimeout(() => modals.open(OPEN_UNIT_BANNER_MODAL, item));
                        }} />
            },
            {
                key: 'publicVacancies',
                dataIndex: 'id',
                width: 150,
                render: (id, item) => {
                    const link = getPublicVacanciesLink(id);

                    return <Button.Group>
                        <Tooltip title={t('unit.open')}>
                            <Button icon={<SolutionOutlined />} onClick={() => window.open(link)} />
                        </Tooltip>
                        <Tooltip title={t('unit.copyLink')}>
                            <CopyToClipboard
                                text={link}
                                onCopy={onCopy}>
                                <Button icon={<LinkOutlined />} />
                            </CopyToClipboard>
                        </Tooltip>
                        <Button
                            onClick={() => modals.open(OPEN_PUBLIC_VACANCIES_PRINT_MODAL, item)}
                            icon={<QrcodeOutlined />}
                            className='dropdown-qrcode-button'
                        />
                    </Button.Group>;
                }
            }
        ];

        return isManager() ? defaultColumn : filter(userFilterColumn, defaultColumn);
    }

    const renderButtons = () => {
        const showEditButton = !contains(USER, user.roles);

        return showEditButton ? (
            <Button
                className='toolbar-button'
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => modals.open(OPEN_UNIT_MODAL)}>
                { t('unit.add') }
            </Button>
        ) : null;
    }

    const expandedRowRender = ({ id }) => {
        return <Vacancies
            param={{ vacancyUnit: id }}
            staticFilter={{ vacancyUnit: id }}
            urlPrefix={`expanded${id}`}
            readonly
        />;
    }

    return <TableList
        className='units-table'
        actionUrl={VACANCY_UNIT.stringify()}
        param='table'
        columns={getColumns()}
        buttons={renderButtons()}
        filterForm={UnitFilters}
        expandedRowRender={expandedRowRender}
    />;
}

export default Units;
