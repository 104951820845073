import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import cx from 'classnames';

const DraggableList = props => {
    const { items, droppableId, renderItem, isDragDisabled, addItem } = props;

    return <Droppable droppableId={droppableId}>
        { (provided) =>
            <div ref={provided.innerRef} className={cx(`draggable-list ${droppableId}`, { empty: !items.length })}>
                { !items.length && (
                    <div
                        className={cx('empty-placeholder', { clickable: addItem })}
                        onClick={() => addItem && addItem()}
                    />
                )}
                { items.length ? items.map((name, index) =>
                    <Draggable
                        key={name}
                        draggableId={name}
                        index={index}
                        isDragDisabled={isDragDisabled}
                    >
                        { (provided, snapshot) =>
                            <div ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={cx(`draggable-item ${droppableId}`, {
                                    draggable: snapshot.isDragging,
                                })}
                                style={{ ...provided.draggableProps.style }}
                            >
                                <div className='border-wrapper'>
                                    { renderItem(index, provided.dragHandleProps) }
                                </div>
                            </div>
                        }
                    </Draggable>
                ) : null}
                { provided.placeholder }
            </div>
        }
    </Droppable>;
}

DraggableList.defaultProps = {
        items: [],
    };

export default DraggableList;
