export const SUBMIT_FORM_DELAY = 500;

export const COMPANY_DICTIONARY_PARENT = [
    { value: 'without', label: 'company.companyDictionaryParent.without' },
    { value: 'parent', label: 'company.companyDictionaryParent.parent' },
    { value: 'parentField', label: 'company.companyDictionaryParent.parentField' },
];

export const geoQuestionsArray = ['region', 'city', 'country'];

export const MIN_FILE_SIZE = 2097152;

export const PDA_LABELS = [
    { id: 'opd', value: 'settings.pdaOpd' },
    { id: 'oferta', value: 'settings.pdaOferta' }
];
