import UrlPattern from 'url-pattern';

const construct = url => new UrlPattern('(*)' + url);

export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL || '/api';

export const LANDING_BUILDER = '/landing-builder';
export const LANDING_BUILDER_ANCOR = '/landing-builder-ancor';

export const LOGIN = construct('/login');
export const USER = construct('/user/profile');
export const PASSWORD_RECOVERY = construct('/password-recovery');
export const PASSWORD_RECOVERY_CONFIRM = construct('/password-recovery/:restoreToken');
export const PASSWORD_REFRESH_CONFIRM = construct('/password-refresh');
export const REGISTRATION_CONFIRM = construct('/registration/:id');

export const VACANCY = construct('/vacancy(/:id)');
export const VACANCY_SYNC = construct('/vacancy/:id/sync');
export const EXPERIUM_VACANCY = construct('/vacancy/import/experium/');
export const PUBLIC_VACANCY = construct('/public/vacancy');

export const APPLICANT = construct('/applicant(/:id)');
export const APPLICANT_APPROVE = construct('/applicant/approve');
export const APPLICANT_REJECT = construct('/applicant/reject');

export const VACANCY_APPLICANTS_REPORT = construct('/vacancy/applicants/report');

export const BOT = construct('/bot(/:id)');
export const BOT_SUBSCRIBE = construct('/bot/:id/subscribe');
export const BOT_UNSUBSCRIBE = construct('/bot/:id/unsubscribe');
export const BOT_TOKEN = construct('bot/web/:username');
export const BOT_PUBLIC = construct('bot/public');

export const CHAT_SETTINGS = construct('/settings/chat');

export const DIALOG = construct('dialog/:bot/dialog/:dialog');
export const DIALOGS = construct('/dialog');
export const DIALOG_MESSAGES = construct('dialog/:bot/messages/:dialog');

export const CHAT = construct('/chat');
export const CHAT_MESSAGES = construct('/chat/messages');

export const CITY_DICTIONARY = construct('/city(/:id)');
export const COUNTRY_DICTIONARY = construct('/country(/:id)');
export const REGION_DICTIONARY = construct('/region(/:id)');
export const DICTIONARY = construct('/dictionary(/:type)');

export const FILE = construct('/file(/:id)');

export const MESSAGE_NODE = construct('/message-node(/:id)');

export const USERS_ADMIN = construct('/user/admin(/:id)');
export const USERS_INVITE = construct('/user/admin/invite/:id');

export const COMPANY = construct('/company(/:id)');

export const JURISTIC_ENTITY = construct('/juristic-entity(/:id)');

export const LANDING = construct('/landing(/:id)');

export const PDA = construct('/public/vacancy/:id/pda');

export const POST_FILE = construct('/file?access_token=(:token)');
export const POST_FILE_WITHOUT_AUTH = construct('/chat/:userId/file');
export const GET_FILE_WITHOUT_AUTH = construct('/chat/:userId/file/:fileId');

export const QUESTIONS_PRESET = construct('/question-preset(/:id)');
export const QUESTION = construct('/question(/:type)');

export const SPECIALIZATIONS = construct('/dictionary/specialization');
export const VACANCY_CATEGORIES = construct('/dictionary/vacancy-category');
export const VACANCY_CHARTS = construct('/dictionary/PrjChartOfWork');

export const VACANCY_UNIT = construct('/vacancy-unit(/:id)');

export const COMPANY_SETTINGS = construct('/settings/company');
export const LETTERS_SETTINGS = construct('/settings/email');
export const PDA_SETTINGS = construct('/settings/pda');
export const COMPANY_PASSWORD_RESET = construct('/user/admin/password-reset(/:company)');

export const BANNER = construct('/banner(/:id)');

export const ADMIN_DIALOG_ENABLE = construct('/dialog/:bot/manual/:dialog/enable');
export const ADMIN_DIALOG_DISABLE = construct('/dialog/:bot/manual/:dialog/disable');
export const ADMIN_DIALOG = construct('/dialog/:bot/message/:dialog');

export const CALL_REQUEST = construct('/call-request');

export const SMS = construct('/sms');

export const VACANCY_FORM_PRESET = construct('/vacancy-form-preset(/:id)');

export const CLASSIFIERS = construct('/classifier(/:id)');
export const CLASSIFIERS_OPTIONS = construct('/classifier/:classifier/option(/:optionId)');

export const DOMAIN = construct('/domain(/:id)');

export const IMPORT_USER = construct('/user/admin/import(/:company)');
export const IMPORT_COMPANY_DICTIONARY = construct('/company-dictionary/import');

export const COMPANY_DICTIONARY = construct('/company-dictionary(/:id)');
export const COMPANY_DICTIONARY_ITEM = construct('/company-dictionary-item(/:id)');

export const USER_ADMIN_STATUS = construct('/user/admin/status');
export const USER_ADMIN_SETTINGS = construct('/user/admin/settings');

export const LOGIN_ATTEMPT = construct('/user/admin/login-attempt');

export const IMPORT_DICTIONARIES = construct('/user/admin/import-dictionaries');

export const CHANGE_LOG = construct('/changelog');

export const COMPANY_LICENSE = construct('/company-license');
export const COMPANY_LICENSE_ITEM = construct('/company/:id/license');

export const QUEUE_ENTRY = construct('/queue/entry');
