import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import { prop, filter, pathOr, toPairs, fromPairs } from 'ramda';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { FieldArray } from 'react-final-form-arrays';

import Input from './formComponents/Input';
import Select from './formComponents/Select';
import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import withUserCompany from '../hocs/withUserCompany';
import { COMPANY_DICTIONARY } from '../../constants/urls';
import { TranslationFields } from './ClassifiersForm';
import useCompanyCodeSwr from '../../utils/useCompanyCodeSwr';

const CompanyDictionaryForm = props => {
    const { t } = useTranslation();
    const { item, companyId } = props;
    const companyDictionary = useCompanyCodeSwr([COMPANY_DICTIONARY.stringify(), companyId], (url, company) => axios.get(url, {
        params: {
            filter: {
                company,
            },
            pagination: {
                limit: 0
            },
            relations: ['parent']
        }
    }))
    const currentDictionaryId = prop('id', item);
    const dictionaries = currentDictionaryId ? (
        filter(({ id }) => id !== currentDictionaryId, pathOr([], ['data', 'items'], companyDictionary))
    ) : pathOr([], ['data', 'items'], companyDictionary);

    return <Fragment>
        <Field
            name='name'
            component={Input}
            label={t('company.dictionaryName')}
            placeholder={t('company.typeDictionaryName')}
            required
            disableClear
        />
        <div style={{ marginBottom: 15 }}>
            <FieldArray name='translations'>
                { ({ fields }) => <TranslationFields fields={fields} form={props.form} /> }
            </FieldArray>
        </div>
        <Field
            name='parent'
            component={Select}
            label={t('company.parentDictionary')}
            placeholder={t('company.selectParentDictionary')}
            options={dictionaries}
            namePath='name'
            allowClear={true}
        />
        <SubmitButton>
            { t('form.save') }
        </SubmitButton>
    </Fragment>;
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
});

export default withUserCompany(
    withFormWrapper(CompanyDictionaryForm, {
        validationSchema,
        mapPropsToValues: ({ item = {} }) => ({
            ...item,
            translations: filter(({ lang }) => lang !== 'ru', toPairs(pathOr({}, ['translations', 'name'], item)).map(([lang, name]) => ({ name, lang }))),
        }),
        mapBeforeSubmit: values => ({
            ...values,
            parent: values.parent || null,
            translations: {
                name: fromPairs((values.translations || []).map(({ name, lang }) => [lang, name]))
            }
        })
    })
);
