import { find, prop, propEq } from 'ramda';

export const boolToString = {
    true: 'app.yes',
    false: 'app.no'
};

export const isWeb = propEq('type', 'web');
export const isFacebook = propEq('type', 'facebook');

export const getOptionsValue = (id, options) => prop('value', find(propEq('id', id), options || [])) || '-';
