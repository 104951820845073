import useSwr from 'swr';
import useSWRInfinite from 'swr/infinite';
import { is } from 'ramda';
import { useContext } from 'react';
import axios from 'axios';

import CompanyCodeContext from '../contexts/CompanyCodeContext';

const defaultFetcher = url => axios(url);

function useCompanyCodeSwr(data, fetcher, options = { revalidateOnMount: true }) {
    const { companyCode } = useContext(CompanyCodeContext);

    return useSwr(is(Array, data) ? [...data, companyCode] : [data, companyCode], fetcher || defaultFetcher, options);
}

export function useCompanyCodeSwrInfinite(getKey, fetcher, options = { revalidateOnMount: true }) {
    const { companyCode } = useContext(CompanyCodeContext);

    return useSWRInfinite((pageIndex, data) => getKey(pageIndex, data, companyCode), fetcher || defaultFetcher, options);
}

export default useCompanyCodeSwr;
