import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, message, Popconfirm, Select } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { path } from 'ramda';

import ModalsContext from '../../../../contexts/ModalsContext';
import { OPEN_DICTIONARY_ITEM_MODAL } from '../../../../constants/actionTypes';
import withUserCompany from '../../../hocs/withUserCompany';
import DictionariesFilter from '../../../filters/DictionariesFilter';
import TableList from '../../../table/TableList';
import ActionsContext from '../../../../contexts/ActionsContext';

const DictionaryTable = (props) => {
    const { t } = useTranslation();
    const modals = useContext(ModalsContext);
    const [language, setLanguage] = useState('en');
    const { revalidateAction } = useContext(ActionsContext);
    const modalParams = {
        postAction: props.postAction,
        putAction: props.putAction,
        actionUrl: props.actionUrl,
        countryField: props.countryField,
        regionField: props.regionField,
        priorityField: props.priorityField
    };

    const getColumns = () => {
        return [
            {
                title: t('settings.name'),
                dataIndex: 'name',
                key: 'title'
            },
            {
                title: <div>
                    { t('settings.chooseLanguage') }
                    <Select
                        value={language}
                        onChange={setLanguage}
                        style={{ width: 90, marginLeft: 10 }}>
                        <Select.Option value='en'>EN <span className='flag flag-en' /></Select.Option>
                        <Select.Option value='ua'>UA <span className='flag flag-ua' /></Select.Option>
                        <Select.Option value='et'>ET <span className='flag flag-et' /></Select.Option>
                        <Select.Option value='lt'>LT <span className='flag flag-lt' /></Select.Option>
                        <Select.Option value='lv'>LV <span className='flag flag-lv' /></Select.Option>
                        <Select.Option value='kz'>KZ <span className='flag flag-kz' /></Select.Option>
                        <Select.Option value='th'>TH <span className='flag flag-th' /></Select.Option>
                        <Select.Option value='uz'>UZ <span className='flag flag-uz' /></Select.Option>
                    </Select>
                </div>,
                key: 'translation',
                render: path(['translations', language])
            },
            ...(props.relations ? props.relations.map(dict => ({
                title: t(`settings.${dict}`),
                dataIndex: ['_relations', dict, 'name'],
                key: 'title'
            })) : []),
            {
                key: 'controls',
                width: 100,
                render: item => (item.company && props.isCompanyAdmin || props.isAdminOnly) && (
                    <Button.Group>
                        <Button
                            className={`edit-item-btn`}
                            icon={<EditOutlined />}
                            onClick={() => modals.open(OPEN_DICTIONARY_ITEM_MODAL, {
                                item,
                                ...modalParams
                            })} />
                        <Popconfirm
                            title={t('settings.dictionaryItemDeleteConfirm')}
                            okText={t('settings.yes')}
                            cancelText={t('settings.no')}
                            onConfirm={() => props.deleteAction(item.id).then(() => {
                                message.success(t('settings.deleteDictionaryItemSuccess'));
                                revalidateAction(props.actionUrl);
                            }).catch(() => message.error('settings.deleteDictionaryItemError'))}
                            placement='left'
                        >
                            <Button
                                className={`delete-item-btn`}
                                icon={<DeleteOutlined />}
                                danger />
                        </Popconfirm>
                    </Button.Group>
                )
            },
        ];
    };

    const getButtons = () => {
        return (props.isCompanyAdmin || props.isAdminOnly) ?
            <Button
                id='add-item-btn'
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => modals.open(OPEN_DICTIONARY_ITEM_MODAL, modalParams)}>
                { t('settings.add') }
            </Button> : null;
    }

    return <TableList
        actionUrl={props.actionUrl}
        initFilters={{ company: props.companyId }}
        headers={{
            'Accept-Language': 'ru',
        }}
        filterForm={DictionariesFilter}
        columns={getColumns()}
        relations={props.relations}
        buttons={getButtons()} />;
}

export default withUserCompany(DictionaryTable);
