import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import ClassifierLanguageForm from '../../../forms/ClassifierLanguageForm';
import { putClassifierHandler, putClassifierOptionHandler } from '../../../../actions/handlers';
import { CLASSIFIERS } from '../../../../constants/urls';
import ActionsContext from '../../../../contexts/ActionsContext';

const ClassifierLanguageModal = props => {
    const { t } = useTranslation();
    const { modal, params, close } = props;
    const item = prop('item', params);
    const isOption = prop('isOption', params);
    const { revalidateAction } = useContext(ActionsContext);

    return (
        <Modal
            {...modal}
            title={t('settings.addTranslation')}
            footer={null}
        >
            <ClassifierLanguageForm
                formAction={ isOption ? putClassifierOptionHandler : putClassifierHandler }
                item={item}
                onSuccess={() => {
                    revalidateAction(CLASSIFIERS.stringify());
                    message.success(t(isOption ? 'settings.translationSaveSuccess' : 'settings.translationSaveSuccess'));
                    close();
                }}
                onSubmitFail={() => message.error(t(isOption ? 'settings.translationSaveError' : 'settings.translationSaveError'))}
            />
        </Modal>
    );
}

export default ClassifierLanguageModal;
