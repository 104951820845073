import React from 'react';
import { propOr } from 'ramda';

const CustomEmoji = props => {
    const onChange = (emoji) => {
        const { onChange } = props;
        onChange(emoji);
    }

    const renderModal = () => {
        const { config } = props;
        const emojis = propOr([], 'emojis', config);

        return (
            <div className='dropdown-content'>
                <i className='dropdown-arrow'/>
                <div className='dropdown-content-inner'>
                    <div className='braft-emojis-wrap'>
                        <ul className='braft-emojis'>
                            { emojis.map((emoji, index) => (
                                <li
                                    key={index}
                                    onClick={() => onChange(emoji)}
                                >
                                    {emoji}
                                </li>
                           ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    const { icon } = props.config;
    const { expanded, onExpandEvent } = props;
    const className = expanded ? 'rdw-option-wrapper rdw-option-active' : 'rdw-option-wrapper';

    return (
        <div
            aria-haspopup='true'
            aria-expanded={expanded}
            aria-label='rdw-emoji-control'
            className='rdw-emoji-wrapper'
        >
            <div
                className={className}
                onClick={onExpandEvent}
            >
                <img
                    src={icon}
                    alt='Emoji button'
                />
            </div>
            {expanded ? renderModal() : undefined}
        </div>
    );
}

export default CustomEmoji;
