import React from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import withFormWrapper from '../hocs/withFormWrapper';
import Editor from '../forms/formComponents/Editor';
import Checkbox from '../forms/formComponents/Checkbox';
import { defaultChatMesssages } from '../../constants/defaultPlaceholders';
import SubmitButton from './formComponents/SubmitButton';

const AboutCompany = () => {
    const { t } = useTranslation();

    return (
        <div className='about-company-wrapper'>
            <Field
                name='welcome'
                component={Editor}
                label={t('company.welcomeDefaultText')}
                placeholder={t(defaultChatMesssages.welcome)}
            />
            <Field
                name='welcomeVacancy'
                component={Editor}
                label={t('company.welcomeTextWithVacancy')}
                placeholder={t(defaultChatMesssages.welcomeVacancy)}
            />
            <Field
                name='searchEnabled'
                component={Checkbox}
                placeholder={t('company.searchEnabled')}
                className='search-checkbox'
            />
            <div className='modal-form-toolbar'>
                <SubmitButton className='about-company-save'>{ t('form.save') }</SubmitButton>
            </div>
        </div>
    );
}

const validationSchema = yup.object().shape({
    welcome: yup.string().nullable(),
    welcomeVacancy: yup.string().nullable(),
});

export default withFormWrapper(AboutCompany, {
    mapPropsToValues: ({ chatSettings }) => chatSettings,
    validationSchema,
});
