import React from 'react';
import { withTranslation } from 'react-i18next';

import { VACANCY_UNIT } from '../../constants/urls';
import BaseFilter from './base/BaseFilter';
import withFilterForm from './base/withFilterForm';

export default withTranslation()(withFilterForm(({ t }) => (
    <BaseFilter
        actionUrl={VACANCY_UNIT.stringify()}
        searchFieldName='title'
        namePath='title'
        smallPath='address'
        searchFieldPlaceholder={t('unit.name')}
        span={12}
    />
)));
