import React from 'react';
import moment from 'moment';

const ChatDate = props => {
    const getDate = () => {
        const {
            dateValue,
            prevDateValue,
        } = props;

        const isSameDate = prevDateValue && (
            new Date(dateValue).toDateString() === new Date(prevDateValue).toDateString()
        );

        return isSameDate ? null : dateValue;
    }

    const { className } = props;
    const date = getDate();

    return (
        <div className={className}>
            {date && moment(date).format('DD MMMM YYYY')}
        </div>
    );
}

export default ChatDate;
