import React from 'react';
import { useTranslation } from 'react-i18next';

import { DICTIONARY } from '../../../../constants/urls';
import withUserCompany from '../../../hocs/withUserCompany';
import DictionariesFilter from '../../../filters/DictionariesFilter';
import TableList from '../../../table/TableList';

const CurrencyDictionary = () => {
    const { t } = useTranslation();

    const getColumns = () => {
        return [
            {
                title: t('settings.name'),
                dataIndex: 'code',
                key: 'title'
            },
        ];
    };

    return <TableList
        actionUrl={DICTIONARY.stringify({ type: 'currency' })}
        filterForm={DictionariesFilter}
        columns={getColumns()} />;
}

export default withUserCompany(CurrencyDictionary);
