import { contains } from 'ramda';

import { geoQuestionsArray } from '../constants/form';

export const mapQuestions = (questions = []) => {
    let geoQuestions = {
        'region': false,
        'city': false,
        'country': false,
    }

    questions.forEach(question => {
        if (contains(question.field, geoQuestionsArray)) {
            geoQuestions[question.field] = true;
        }
    });

    const newQuestions = questions.map(question => {
        switch (question.field) {
            case 'city':
                return geoQuestions.region || geoQuestions.country ? ({
                    ...question,
                    settings: {
                        ...question.settings,
                        regionField: geoQuestions.region ? 'region' : undefined,
                        countryField: geoQuestions.country ? 'country' : undefined,
                    },
                }) : question;
            case 'region':
                return geoQuestions.country ? ({
                    ...question,
                    settings: {
                        ...question.settings,
                        countryField: 'country',
                    },
                }) : question;
            default:
                return question;
        }
    });

    return newQuestions;
};
