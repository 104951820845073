import React, { useRef } from 'react';
import cx from 'classnames';
import { isMobile, isIOS } from 'react-device-detect';
import { Field } from 'react-final-form';
import { Button } from 'antd';
import * as yup from 'yup';
import { ArrowUpOutlined } from '@ant-design/icons';

import withFormWrapper from '../hocs/withFormWrapper';
import withAutoFocusForm from '../hocs/withAutoFocusForm';
import Input from './formComponents/Input';
import { useTranslation } from 'react-i18next';

const TEXT_PATH = 'text';

const ChatSendMessageForm = props => {
    const { t } = useTranslation();
    const container = useRef();

    const focusInput = force => {
        if (force || !('ontouchstart' in document.documentElement)) {
            container.current.querySelector('input').focus();
        }
    }

    const onSubmit = () => {
        focusInput(true);
    }

    const { textPath } = props;

    return (
        <div ref={container} className='form-wrapper'>
            <div className={cx('chat-form', { 'isMobile': isMobile, 'isIOS': isIOS })}>
                <Field
                    name={textPath}
                    component={Input}
                    placeholder={t('chat.typeMessage')}
                    displayError={false}
                />
                <Button
                    className='chat-edit-send'
                    icon={<ArrowUpOutlined />}
                    shape='circle'
                    type='primary'
                    htmlType='submit'
                    onClick={onSubmit}
                />
            </div>
        </div>
    );
}

ChatSendMessageForm.defaultProps = {
    textPath: TEXT_PATH
};

const validationSchema = ({ textPath = TEXT_PATH }) => yup.object().shape({
    [textPath]: yup.string().required()
});

export default withFormWrapper(
    withAutoFocusForm(ChatSendMessageForm), {
        mapPropsToValues: ({ initialValues }) => initialValues,
        validationSchema,
        afterSubmit: ({ form }) => {
            form.reset();
        }
    }
);
