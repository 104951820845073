import React, { Fragment, useState, useContext } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Field, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { head, filter, find, toPairs, forEach, forEachObjIndexed, assocPath, isEmpty, addIndex, path, toUpper, findIndex, propEq, pathOr } from 'ramda';
import { ArrowsAltOutlined, CloseOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Form, Button, Space, Divider, Tabs, Popconfirm, Dropdown, Menu } from 'antd';
import styled from 'styled-components';
import axios from 'axios';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import ModalsContext from '../../contexts/ModalsContext';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import Select from './formComponents/Select';
import Checkbox from './formComponents/Checkbox';
import SubmitButton from './formComponents/SubmitButton';
import ListenerField from './ListenerField';
import RadioGroup from './formComponents/RadioGroup';
import withUserCompany from '../hocs/withUserCompany';
import Editor from './formComponents/Editor';
import { AVAILABLE_LANGUAGES, DEFAULT_LANG } from '../../constants/dictionary';
import { QUESTIONS_PRESET } from '../../constants/urls';
import { OPEN_OPD_LANGUAGE_MODAL } from '../../constants/actionTypes';
import { validationLocale } from '../../utils/validation';
import { landingFromHTML, landingToHTML } from '../../utils/editor';
import nanoid from '../../utils/nanoid';
import useCompanyCodeSwr from '../../utils/useCompanyCodeSwr';

const { TabPane } = Tabs;

const HeadGroup = styled.div`
    margin: -1px 0 24px;
    background: #f5f4f4;
    color: #444c63;
    font-size: 15px;
    height: 54px;
    line-height: 54px;
    padding: 0 16px;
    font-weight: 500;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
`;

const RadioWrap = styled.div`
    margin: 15px 0;
`;

const PreviewWrap = styled.div`
    padding: 15px;
    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
    margin-bottom: 15px;
    input[type=number], input[type=password], input[type=text], input[type=email], textarea {
        border: none;
        border-bottom: 1px solid #000;
        font-size: 16px;
        pointer-events: auto;
        outline: none;
        min-width: 220px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 5px;
        background: transparent;
    }
    input[type='radio'], input[type='checkbox'] {
        box-sizing: border-box;
        padding: 0;
        margin-right: 5px;
        position: relative;
        top: 2px;
    }
    a {
        color: #0e65ac;
    }
    button {
        cursor: pointer;
        margin-top: 10px;
    }
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
    & > div {
        & > p {
            display: inline-block;
            & + p {
                display: block;
            }
        }
    }
`;

const SpaceWrap = styled(Space)`
    display: flex;
    .ant-space-item {
        flex: 1 1 auto;
        &:first-child {
            flex: 0 0 auto;
        }
        &:nth-child(2) {
            flex: 5 1 auto;
            &:last-child {
                flex: 1;
            }
        }
        &:nth-child(3), &:nth-child(4) {
            &:not(:last-child) {
                flex: 0 0 auto;
            }
        }
        &:last-child {
            flex: 0 0 auto;
        }
    }
    .ant-form-item {
        margin-bottom: 0;
    }
    .ant-input-group-wrapper {
        vertical-align: middle;
        top: -1px;
        position: relative;
    }
    .ant-checkbox-wrapper {
        top: -3px;
        position: relative;
    }
`;

const SpaceWrapControls = styled(Space)`
    display: flex;
    flex-wrap: wrap;
    .ant-space-item {
        margin-top: 10px;
    }
`;

const TabsWrap = styled(Tabs)`
    margin: 0 -15px;
    .ant-tabs-nav {
        margin: 0;
    }
    .ant-tabs-nav-add {
        padding: 0 !important;
        min-width: auto !important;
        .anticon-plus {
            width: 40px;
            height: 34px;
            padding-top: 11px;
        }
    }
    .ant-tabs-tab-remove {
        margin: 0;
        padding: 0;
        .anticon-close {
            padding: 0 4px 0 12px;
        }
    }
`;

const ConstructorWrap = styled.div`
    padding-right: 15px;
    .ant-divider-plain.ant-divider-with-text {
        margin: 30px 0 15px;
    }
`;

const DndIcon = styled.div`
    transform: rotate(-45deg);
    font-size: 19px;
`;

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const NullComponent = () => null;

const CloseTabIcon = props => {
    const { t } = useTranslation();

    return <Popconfirm
        title={t('banner.continueConfirm')}
        okText={t('settings.yes')}
        cancelText={t('settings.no')}
        onConfirm={props.onConfirm}
        placement='bottom'>
        <CloseOutlined />
    </Popconfirm>;
}

const AddTabIcon = props => {
    const menu = (
        <Menu>
            { props.languages.map(lang =>
                <Menu.Item key={lang} onClick={() => props.addTab(lang)}>
                    <span>{ toUpper(lang) } <span className={`flag flag-${lang}`} /></span>
                </Menu.Item>
            )}
            <Menu.Item key='new' onClick={() => props.openOpdModal()}>
                <span>{ props.t('settings.add') }</span>
            </Menu.Item>
        </Menu>
    );

    return <Dropdown overlay={menu} trigger={['click']}>
        <PlusOutlined />
    </Dropdown>;
}

const TabContent = props => {
    const { t } = useTranslation();
    const { lang, values, fieldName = '', onChangePdaLinkText, onChangePdaLinkType, questions, questionsLoading } = props;
    // const pdaLabelLink = !!values.labelLink || any(item => item.labelLink, values.translations || []);
    const pdaLinkType = values.linkType;
    const required = value => !value ? t('form.requiredField') : undefined;

    const renderPdaContentField = () => {
        switch (pdaLinkType) {
            case 'modal':
                return <Field
                    name={`${fieldName}text`}
                    component={Editor}
                    disableClear />;
            case 'link':
                return <Field
                    name={`${fieldName}link`}
                    component={Input}
                    addonBefore='http(s)://'
                    placeholder={t('settings.pdaLinkAddressPlaceholder')}
                    validate={value => !value ? validationLocale(t).mixed.required : undefined}
                    disableClear />;
            default:
                return null;
        }
    }

    const renderConstructorField = (item, name, lang) => {
        const useTranslations = lang !== DEFAULT_LANG;
        switch (item.type) {
            case 'question':
                return <Fragment>
                    <Field
                        name={`${name}.question`}
                        component={Select}
                        placeholder={t('settings.question')}
                        onChange={(_, opt) => {
                            props.form.change(`${name}.placeholder`, path(['label'], opt));
                            props.form.change(`${name}.translations`, path(['translations', 'label'], opt));
                        }}
                        validate={required}
                        options={questions}
                        loading={questionsLoading}
                        valuePath='field'
                        namePath='label'
                        getNamePath={
                            (item) => useTranslations
                                ? path(['translations', 'label', head(lang.split('-'))], item) || path(['label'], item)
                                : path(['label'], item)
                        }
                        searchable
                        disableClear />
                    <Field name={`${name}.placeholder`} component={() => null} />
                    <Field
                        name={`${name}.required`}
                        component={Checkbox}
                        placeholder={t('settings.required')}
                        disableClear />
                </Fragment>;
            case 'formated':
                return <Field
                    name={useTranslations ? `${name}.translations.${lang}` : `${name}.text`}
                    component={Editor}
                    validate={required}
                    toolbar={undefined}
                    fromHTML={landingFromHTML}
                    toHTML={landingToHTML}
                    disableClear />;
            case 'checkbox':
                return <Fragment>
                    <Field
                        name={useTranslations ? `${name}.translations.${lang}` : `${name}.label`}
                        component={Input}
                        placeholder={t('settings.pdaText')}
                        validate={required}
                        disableClear />
                    <Field
                        name={`${name}.required`}
                        component={Checkbox}
                        placeholder={t('settings.required')}
                        disableClear />
                </Fragment>;
            case 'opdCheckbox':
                return <Fragment>
                    <Field
                        name={useTranslations ? `${name}.translations.${lang}` : `${item.opdType}.label`}
                        component={Input}
                        placeholder={t('settings.pdaText')}
                        extra={t(`settings.${item.opdType}`)}
                        validate={required}
                        disableClear />
                    <Field
                        name={`${item.opdType}.required`}
                        component={Checkbox}
                        placeholder={t('settings.required')}
                        disableClear />
                </Fragment>;
            default:
                return null;
        }
    }

    const generateOpdText = (opdConstructor, values, lang) => {
        const text = (opdConstructor || []).reduce((res, cur) => {
            switch (cur.type) {
                case 'question':
                    return `${res}<input name="${cur.question}" placeholder="${path(['translations', head(lang.split('-'))], cur) || cur.placeholder || ''}" type="text" ${cur.required ? 'required' : ''} /> `;
                case 'formated':
                    return res + (path(['translations', lang], cur) || cur.text || '');
                case 'checkbox':
                    return res + `<div>
                        <input type="checkbox" id="${`checkbox-${cur.id}`}" />
                        <label htmlFor="${`checkbox-${cur.id}`}">${
                            cur.required ? '<span style="color: red;">* </span>' : ''
                        }${
                            path(['translations', lang], cur) || cur.label || ''
                        }</label>
                    </div>`;
                case 'opdCheckbox':
                    return res + `<div>
                        <input type="checkbox" id="${cur.opdType}" />
                        <label htmlFor="${cur.opdType}">${
                            path([cur.opdType, 'required'], values) ? '<span style="color: red;">* </span>' : ''
                        }${
                            path(['translations', lang], cur) || pathOr('', [cur.opdType, 'label'], values)
                        }</label>
                    </div>`;
                default:
                    return res;
            }
        }, '');

        return <div dangerouslySetInnerHTML={{ __html: text }} />;
    }

    const onChangeConstructorFieldOrder = (fieldName, result, fields) => {
        if (!result.destination) {
            return;
        }

        props.form.change(fieldName, reorder(fields.value, result.source.index, result.destination.index));
    }

    const checkBoxMenu = fields => {
        return <ListenerField listenFieldName='data.opdConstructor'>
            { ({ data }) => {
                const showCheckbox = type => !find(propEq('opdType', type), pathOr([], ['opdConstructor'], data));

                return <Menu>
                    <Menu.ItemGroup title={<div style={{ maxWidth: 400 }}>{t('settings.opdCheckboxes')}</div>}>
                        { showCheckbox('acceptCheckbox') && <Menu.Item onClick={() => fields.push({ type: 'opdCheckbox', opdType: 'acceptCheckbox', id: nanoid() })}>
                            { t('settings.pdaShort') }
                        </Menu.Item>}
                        { showCheckbox('purposeCheckbox') && <Menu.Item onClick={() => fields.push({ type: 'opdCheckbox', opdType: 'purposeCheckbox', id: nanoid() })}>
                            { t('settings.purposeCheckboxLabel') }
                        </Menu.Item>}
                        { showCheckbox('mailingCheckbox') && <Menu.Item onClick={() => fields.push({ type: 'opdCheckbox', opdType: 'mailingCheckbox', id: nanoid() })}>
                            { t('settings.mailingCheckboxLabel') }
                        </Menu.Item>}
                        { showCheckbox('transmissionCheckbox') && <Menu.Item onClick={() => fields.push({ type: 'opdCheckbox', opdType: 'transmissionCheckbox', id: nanoid() })}>
                            { t('settings.transmissionCheckboxLabel') }
                        </Menu.Item>}
                    </Menu.ItemGroup>
                </Menu>;
            }}
        </ListenerField>;
    };

    return <Fragment>
        <HeadGroup>{t('settings.opdFrase')}</HeadGroup>
        <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Row gutter={8}>
                <Col span={8}>
                    <Field
                        name={`${fieldName}labelStart`}
                        component={Input}
                        label={t('settings.pdaText')}
                        placeholder={t('settings.pdaStartText')}
                        disableClear />
                </Col>
                <Col span={8}>
                    <Field
                        name={`${fieldName}labelLink`}
                        component={Input}
                        label={t('settings.pdaLinkText')}
                        placeholder={t('settings.pdaLinkDefaultText')}
                        onChange={onChangePdaLinkText}
                        disableClear />
                </Col>
                <Col span={8}>
                    <Field
                        name={`${fieldName}labelEnd`}
                        component={Input}
                        label={t('settings.pdaText')}
                        placeholder='.'
                        disableClear />
                </Col>
            </Row>
            <Fragment>
                <div>{ t('settings.pdaLabelLinkDirecton') }</div>
                <Field
                    name='linkType'
                    component={RadioGroup}
                    onChange={() => onChangePdaLinkType(values)}
                    options={[
                        { label: t('settings.pda', { companyName: props.companyName }), value: 'modal' },
                        { label: t('settings.pdaLinkAddress'), value: 'link' },
                        { label: t('settings.pdaLinkNone'), value: '' }
                    ]}
                    solid
                    disableClear />
                <Field name={`${fieldName}text`} component={() => null} />
                <Field name={`${fieldName}link`} component={() => null} />
                { renderPdaContentField() }
            </Fragment>
        </div>
        <HeadGroup>{ t('settings.whatDoCheckbox') }</HeadGroup>
        <RadioWrap style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Field
                name='useConstructor'
                component={RadioGroup}
                solid
                disableClear
                options={[
                    { value: false, label: t('settings.activateButtonSend') },
                    { value: true, label: t('settings.openNewWindow') }
                ]} />
        </RadioWrap>
        <FormSpy subscription={{ values: true }}>
            { ({ values }) => {
                const opdConstructor = pathOr([], ['data', 'opdConstructor'], values);

                const acceptButtonLabel = fieldName ? path([...filter(i => !!i, fieldName.replace('[', '.').replace(']', '.').split('.')), 'acceptButtonLabel'], values) : values.acceptButtonLabel;

                return values.useConstructor ?
                    <Row gutter={8} style={{ paddingLeft: 15, paddingRight: 15 }}>
                        <Col span={24}>
                            <Divider orientation="left">{ t('settings.constructor') }</Divider>
                            <ConstructorWrap>
                                <div name="dynamic_form_nest_item" autoComplete="off">
                                    <FieldArray name={`data.opdConstructor`}>
                                        { ({ fields }) =>
                                            <div>
                                                <DragDropContext onDragEnd={result => onChangeConstructorFieldOrder(`data.opdConstructor`, result, fields)}>
                                                    <Droppable droppableId="droppable-fields" ignoreContainerClipping={true}>
                                                        {(provided) => (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                { fields.map((name, index) =>
                                                                    <div key={fields.value[index].id}>
                                                                        <Draggable
                                                                            key={fields.value[index].id}
                                                                            draggableId={fields.value[index].id}
                                                                            index={index}
                                                                        >
                                                                            {(provided) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    style={{ marginTop: 10, ...provided.draggableProps.style }}
                                                                                    key={index}
                                                                                >
                                                                                    <SpaceWrap align="baseline">
                                                                                        <DndIcon {...provided.dragHandleProps}>
                                                                                            <ArrowsAltOutlined />
                                                                                        </DndIcon>
                                                                                        { renderConstructorField(fields.value[index], name, lang) }
                                                                                        <Popconfirm
                                                                                            title={t('banner.continueConfirm')}
                                                                                            okText={t('settings.yes')}
                                                                                            cancelText={t('settings.no')}
                                                                                            placement='left'
                                                                                            onConfirm={() => fields.remove(index)}>
                                                                                            <MinusCircleOutlined style={{ cursor: 'pointer' }} />
                                                                                        </Popconfirm>
                                                                                    </SpaceWrap>
                                                                                </div>

                                                                            )}
                                                                        </Draggable>
                                                                    </div>
                                                                )}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                                <Form.Item>
                                                    <SpaceWrapControls id='opd-buttons' style={{ marginTop: 8 }}>
                                                        <Button type="dashed" onClick={() => fields.push({ type: 'formated', id: nanoid() })} block icon={<PlusOutlined />}>
                                                            {t('settings.pdaText')}
                                                        </Button>
                                                        <Button type="dashed" onClick={() => fields.push({ type: 'question', id: nanoid() })} block icon={<PlusOutlined />}>
                                                            {t('settings.question')}
                                                        </Button>
                                                        <Dropdown.Button
                                                            type="dashed"
                                                            block
                                                            overlay={() => checkBoxMenu(fields)}
                                                            trigger={['click']}
                                                            onClick={() => fields.push({ type: 'checkbox', id: nanoid() })}
                                                            getPopupContainer={() => document.getElementById('opd-buttons')}
                                                        >
                                                            <PlusOutlined /> {t('settings.checkbox')}
                                                        </Dropdown.Button>
                                                    </SpaceWrapControls>
                                                </Form.Item>
                                            </div>
                                        }
                                    </FieldArray>
                                    <Divider orientation="left" plain>{t('settings.acceptButtonText')}</Divider>
                                    <Row>
                                        <Col sm={12} xs={24}>
                                            <Field
                                                name={`${fieldName}.acceptButtonLabel`}
                                                component={Input}
                                                placeholder={t('settings.pdaText')}
                                                disableClear />
                                        </Col>
                                    </Row>
                                </div>
                            </ConstructorWrap>
                        </Col>
                        <Col span={24}>
                            <Divider orientation="left">{t('domain.preview')}</Divider>
                            <PreviewWrap>
                                { generateOpdText(opdConstructor, values, lang) }
                                <p>
                                    <button type="button">{ acceptButtonLabel || t('settings.accept') }</button>
                                </p>
                            </PreviewWrap>
                        </Col>
                    </Row> : null;
            }}
        </FormSpy>
    </Fragment>;
}

const PdaSettingsForm = props => {
    const { t, i18n } = useTranslation();
    const modals = useContext(ModalsContext);
    const [tab, setTab] = useState('ru');
    const questionsPreset = useCompanyCodeSwr([QUESTIONS_PRESET.stringify(), tab], (url, lang) => axios.get(url, {
        headers: {
            'Accept-Language': i18n.language || lang,
        },
        params: {
            filter: {
                company: props.companyId
            },
            pagination: {
                limit: 0
            }
        }
    }));
    const questions = pathOr([], ['data', 'items'], questionsPreset);

    const onChangePdaLinkText = (value) => {
        const values = props.form.getState().values;

        if (!values.linkType && value) {
            props.form.change('linkType', 'modal');
        }
    }

    const onChangePdaLinkType = values => {
        props.form.batch(() => {
            props.form.change('text', null);
            props.form.change('link', null);

            addIndex(forEach)((_, index) => {
                props.form.change(`translations[${index}].text`, null);
                props.form.change(`translations[${index}].link`, null);
            }, values.translations || []);
        });
    }

    const addTab = (lang, fields) => {
        fields.push({ lang });
        setTab(lang);
    }

    const removeTab = (lang, fields) => {
        const { data } = props.form.getState().values || [];
        const index = findIndex(propEq('lang', lang), fields.value);

        props.form.change(`data`, assocPath(['locale', lang], null, data));

        fields.remove(index);
        lang === tab && setTab('ru');
    }

    return <ListenerField listenFieldName={['labelLink', 'translations', 'linkType']}>
        { values =>
            <div className='about-company-wrapper'>
                <FieldArray name='translations'>
                    { ({ fields }) =>
                        <TabsWrap
                            type="editable-card"
                            hideAdd={false}
                            activeKey={tab}
                            onChange={setTab}
                            addIcon={<AddTabIcon
                                t={t}
                                addTab={lang => addTab(lang, fields)}
                                languages={filter(lang => !find(propEq('lang', lang), fields.value), AVAILABLE_LANGUAGES)}
                                openOpdModal={() => modals.open(OPEN_OPD_LANGUAGE_MODAL, { addTab: lang => addTab(lang, fields) })}
                            />}
                        >
                            <TabPane closeIcon={<NullComponent />} tab={<span>RU <span className="flag flag-ru" /></span>} key={DEFAULT_LANG}>
                                <TabContent
                                    values={values}
                                    lang={DEFAULT_LANG}
                                    questions={questions}
                                    questionsLoading={questionsPreset.isValidating}
                                    onChangePdaLinkText={onChangePdaLinkText}
                                    onChangePdaLinkType={onChangePdaLinkType}
                                    form={props.form} />
                            </TabPane>
                            { fields.map((name, index) => {
                                const lang = fields.value[index].lang;
                                const langFlag = head(lang.split('-'));

                                return <TabPane
                                    closeIcon={<CloseTabIcon onConfirm={() => removeTab(lang, fields)} />}
                                    tab={<span>{ toUpper(lang) }<span className={`flag flag-${langFlag}`} /></span>}
                                    key={lang}>
                                    <TabContent
                                        values={values}
                                        fieldName={`${name}.`}
                                        lang={lang}
                                        questions={questions}
                                        questionsLoading={questionsPreset.isValidating}
                                        onChangePdaLinkText={onChangePdaLinkText}
                                        onChangePdaLinkType={onChangePdaLinkType}
                                        form={props.form} />
                                </TabPane>
                            })}
                        </TabsWrap>
                    }
                </FieldArray>
                <div className="modal-form-toolbar">
                    { props.submitFailed && props.invalid ? t('app.errors.validationFailed') : '' }
                    <SubmitButton danger={props.submitFailed && props.invalid}>
                        { t('form.save') }
                    </SubmitButton>
                </div>
            </div>
        }
    </ListenerField>;
}

const mapBeforeSubmitCheckbox = (type, values) => {
    const obj = values[type];

    return obj && !isEmpty(obj) ? {
        active: !!find(propEq('opdType', type), pathOr([], ['data', 'opdConstructor'], values)),
        label: obj.label || '',
        required: !!obj.required
    } : null;
}

export default withUserCompany(withTranslation()(withFormWrapper(PdaSettingsForm, {
    mapPropsToValues: ({ item }) => {
        let translations = {};

        if (item.data && item.data.translations) {
            forEachObjIndexed((langs, key) => {
                forEachObjIndexed((val, lang) => {
                    translations = assocPath([lang, key], val, translations);
                }, langs || {});
            }, item.data.translations || {});
        } else {
            forEachObjIndexed((langs, key) => {
                forEachObjIndexed((val, lang) => {
                    translations = assocPath([lang, key], val, translations);
                }, langs || {});
            }, item.translations || {});

            translations = toPairs(translations).map(([lang, values]) => ({ lang, ...values }));
        }

        translations = toPairs(translations).map(([lang, values]) => ({ lang, ...values }));

        return {
            ...item,
            useConstructor: !!item.useConstructor,
            translations
        };
    },
    mapBeforeSubmit: values => {
        let translationsDefault = {};
        let translations = {};
        let locales = [];

        forEach(({ lang, ...item }) => {
            const defaultLang = head(lang.split('-'));

            forEachObjIndexed((val, key) => {
                translations = assocPath([key, lang], val, translations);

                if (defaultLang === lang) {
                    translationsDefault = assocPath([key, lang], val, translationsDefault);
                }
            }, item);

            if (defaultLang !== lang) {
                locales.push(lang);
            }
        }, values.translations);

        return {
            ...values,
            data: {
                ...values.data,
                translations: translations,
                locales,
            },
            translations: isEmpty(translationsDefault) ? null : translationsDefault,
            acceptCheckbox: mapBeforeSubmitCheckbox('acceptCheckbox', values),
            purposeCheckbox: mapBeforeSubmitCheckbox('purposeCheckbox', values),
            mailingCheckbox: mapBeforeSubmitCheckbox('mailingCheckbox', values),
            transmissionCheckbox: mapBeforeSubmitCheckbox('transmissionCheckbox', values)
        };
    }
})));
