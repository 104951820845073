import React from 'react';
import { Modal, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import WidgetLink from '../../table/WidgetLink';

const BotWidgetModal = props => {
    const { t } = useTranslation();
    const { modal, params }  = props;
    const isAll = params.code;

    return (
        <Modal {...modal} title={t('bot.widgetWeb')} footer={null}>
            <Row>
                <Col xs={11}>
                    <div>{ t('bot.widgetWebInfo') }</div>
                    <br />
                    <WidgetLink {...params} />
                </Col>
                <Col xs={10} className='text-right'>
                    <img width='100%' alt={t('bot.chatExample')} src='/assets/widget/preview.png' />
                </Col>
                <Col xs={3} className='text-right'>
                    { isAll ? (
                        <img alt={t('bot.widgetBotExample')} src='/assets/widget/full.png' />
                    ) : (
                        <img alt={t('bot.widgetExample')} style={{ marginTop: '130px' }} src='/assets/widget/one.png' />
                    )}
                </Col>
            </Row>
        </Modal>
    );
}

export default BotWidgetModal;
