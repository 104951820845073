import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { useTranslation, withTranslation } from 'react-i18next';

import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import withUserCompany from '../hocs/withUserCompany';
import Questions from './vacancyForm/Questions';
import { PRESET_QUESTIONS } from '../../constants/questions';
import { getFormQuestions } from '../../utils/vacancy';
import { path } from 'ramda';

const QuestionsWrapper = styled.div`
    margin: 0 -24px 15px;
    .switch-inline {
        padding-left: 24px;
    }
`;

const VacancyFormPresetForm = props => {
    const { t } = useTranslation();
    const { hideQuestions, formAction, item, isCompanyAdmin, userId } = props;
    const user = path(['user'], item);
    const formDisabled = !(isCompanyAdmin || !user || (userId === user));

    return <Fragment>
        { !formDisabled &&
            <Field
                name='title'
                component={Input}
                label={t('vacancy.form.name')}
                disableClear />
        }
        { !hideQuestions &&
            <FieldArray
                name='questions'>
                { fieldProps =>
                    <QuestionsWrapper>
                        <Questions
                            questionsType={PRESET_QUESTIONS}
                            {...fieldProps}
                            form={props.form}
                            formAction={formAction}
                            creator={user}
                            formDisabled={formDisabled}
                            hideFormPreset
                        />
                    </QuestionsWrapper>
                }
            </FieldArray>
        }
        { !formDisabled &&
            <SubmitButton>
                { t('form.save') }
            </SubmitButton>
        }
    </Fragment>;
}

const validationSchema = () => yup.object().shape({
    title: yup.string().required(),
    questions: yup.array().of(
        yup.object().shape({
            question: yup.string().required(),
            label: yup.string().required()
        })
    )
});

export default withTranslation()(withFormWrapper(withUserCompany(VacancyFormPresetForm), {
    validationSchema,
    mapPropsToValues: ({ item = {}, t }) => ({
        ...item,
        questions: getFormQuestions(item.questions, t),
    })
}));
