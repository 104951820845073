import React, { Fragment } from 'react';
import { isEmpty } from 'ramda';
import { DatePicker } from 'antd';
import moment from 'moment';
import { FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const RangeComponent = props => {
    const { t } = useTranslation();

    const onChange = (value, change) => {
        const { onChange, toPath } = props;
        const hasValue = !isEmpty(value);
        const picker = props.picker || 'day';

        if (!value || !hasValue) {
            onChange(undefined);
            change(toPath, undefined);
        } else {
            if (value[0]) {
                onChange(moment(value[0]).utc().startOf(picker).toISOString());
                change(toPath, moment(value[1]).utc().endOf(picker).toISOString());
            } else {
                onChange(moment(value).utc().startOf(picker).toISOString());
                change(toPath, null);
            }
        }
    }

    const { form, values, placeholder, input: { value, name }, className, toPath, picker } = props;
    const pickerValue = value ? [moment(value).utc(), values[toPath] ? moment(values[toPath]).utc() : null] : undefined;

    return (
        <Fragment>
            <DatePicker.RangePicker
                id={name}
                value={pickerValue}
                className={className}
                onChange={e => onChange(e, form.change)}
                placeholder={placeholder.map(t)}
                picker={picker || 'day'}
                format={picker === 'month' ? 'DD-MM-YYYY' : 'DD-MM-YYYY'}
            />
        </Fragment>
    );
}

RangeComponent.defaultProps = {
    placeholder: ['form.from', 'form.to'],
};

export default withFieldWrapper(props => (
    <FormSpy subscription={{ values: true }}>
        { formProps =>
            <RangeComponent {...formProps} {...props} />
        }
    </FormSpy>
));
