import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import { pushRollbarError } from '../utils/rollbarInit';
import { withTranslation } from 'react-i18next';

const clearState = {
    error: false,
    path: '',
};

class RouteComponent extends Component {
    state = clearState;

    componentDidCatch(error) {
        this.setState({
            error: true,
            path: this.props.path,
        });

        pushRollbarError(error);
    }

    componentDidUpdate() {
        if (this.state.error && this.props.path !== this.state.path) {
            this.setState(clearState);
        }
    }

    resetError = () => {
        window.history.back();
    }

    render() {
        const { t } = this.props;

        return this.state.error ? (
            <div className='catch-error'>
                <p>{ t('app.dataError') }</p>
                <Button
                    className='error-component-button'
                    onClick={this.resetError}
                    type='primary'
                    icon={<LeftOutlined />}
                >
                    { t('app.back') }
                </Button>
            </div>
        ) : (
            <Route {...this.props} />
        );
    }
}

export default withTranslation()(RouteComponent);
