import React, { useState } from 'react';
import { message } from 'antd';
import { find, dropLastWhile, append, eqProps } from 'ramda';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { VACANCIES, VACANCY, MENU, URL, MESSAGE_NODE } from '../../../../constants/messageTransitions';
import Message from './Message';
import Menu from './Menu';
import StateNameMenu from './StateNameMenu';
import VacancyBlock from './VacancyBlock';
import UrlBlock from './UrlBlock';
import DeleteButton from './DeleteButton';
import { putChatSettingsHandler } from '../../../../actions/handlers';
import { CHAT_SETTINGS } from '../../../../constants/urls';
import useCompanyCodeSwr from '../../../../utils/useCompanyCodeSwr';

const MessageNodes = () => {
    const { t } = useTranslation();
    const [items, setItems] = useState([]);
    const chatSettings = useCompanyCodeSwr(CHAT_SETTINGS.stringify());

    const toggleItem = item => {
        let updatedItems = item.parent ? dropLastWhile(i => i.id !== item.parent, items) : [];

        if (!find(i => eqProps('id', item, i) && eqProps('buttonIndex', item, i), items)) {
            updatedItems = append(item, updatedItems);
        }

        setItems(updatedItems);
    }

    const renderTextBlock = (text, item) => {
        return <div className='message-nodes-block'>
            <DeleteButton
                {...item}
                items={items}
                setItems={setItems} />
            <span>{ text }</span>
        </div>;
    }

    const renderVacancyBlock = item => {
        return <VacancyBlock
            {...item}
            items={items}
            setItems={setItems}
            name={item.parent ?
                `botMessages.0.data.items.${item.buttonIndex}.payload.vacancy` :
                `mainMenu.items.${item.buttonIndex}.payload.vacancy`
            } />;
    }

    const renderMessage = item => {
        return <Message
            {...item}
            onToggleButton={toggleItem}
            items={items}
            setItems={setItems} />;
    }

    const renderUrlBlock = item => {
        return <UrlBlock
            {...item}
            items={items}
            setItems={setItems}
            name={item.parent ?
                `botMessages.0.data.items.${item.buttonIndex}.payload.url` :
                `mainMenu.items.${item.buttonIndex}.payload.url`
            } />;
    }

    const getItemContentWithoutId = item => {
        switch(item.stateName) {
            case VACANCIES:
                return renderTextBlock(t('settings.showVacanciesList'), item);
            case VACANCY:
                return renderVacancyBlock(item);
            case MENU:
                return renderTextBlock(t('settings.showMenu'), item);
            case MESSAGE_NODE:
                return renderMessage(item);
            case URL:
                return renderUrlBlock(item);
            default:
                return;
        }
    }

    const renderItem = item => {
        if (!item.stateName) {
            return <StateNameMenu
                item={item}
                chatSettings={chatSettings.data}
                items={items}
                setItems={setItems}
                pending={chatSettings.isValidating}
                revalidateChatSettings={() => chatSettings.mutate()}
            />;
        }

        return getItemContentWithoutId(item);
    }

    return <div className='message-nodes-container'>
        <div className='message-nodes'>
            <Menu
                item={chatSettings.data}
                menuLoading={!chatSettings.data && chatSettings.isValidating}
                formAction={putChatSettingsHandler}
                onSuccess={() => chatSettings.mutate()}
                onSubmitFail={() => message.error(t('settings.contentSaveError'))}
                onToggleButton={toggleItem}
                showPreview={true}
            />
            { items.map((item, index) =>
                <div
                    key={`message-${item.id}-${item.buttonIndex}-${index}`}
                    className={cx({ 'last-node': items.length - 1 === index })}>
                    { renderItem(item) }
                </div>
            )}
        </div>
    </div>;
}

export default MessageNodes;
