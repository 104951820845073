import React, { useContext, useState } from 'react';
import { Modal, Button, message } from 'antd';
import styled from 'styled-components';
import { head } from 'ramda';
import { useTranslation } from 'react-i18next';

import TableList from '../../table/TableList';
import { putBannerHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { BANNER, VACANCY } from '../../../constants/urls';

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
    }
`;

const QrVacancyLinkModal = props => {
    const { t } = useTranslation();
    const { modal } = props;
    const [selected, setSelected] = useState(null);
    const { revalidateAction } = useContext(ActionsContext);

    const getColumns = () => {
        return [
            {
                title: t('banner.name'),
                key: 'title',
                dataIndex: 'title'
            }
        ];
    }

    const link = () => {
        putBannerHandler({
            ...props.params,
            vacancy: head(selected)
        }).then(() => {
            revalidateAction(BANNER.stringify());
            message.success(t('banner.linkVacancySuccess'));
            props.close();
        }).catch(() => message.error(t('banner.linkVacancyError')));
    }

    const renderFooter = () => {
        return <Button
            type='primary'
            disabled={!selected}
            onClick={link}>
            { t('banner.link') }
        </Button>;
    }

    return <StyledModal
        {...modal}
        title={t('banner.linkVacancy')}
        footer={renderFooter()}>
        <TableList
            actionUrl={VACANCY.stringify()}
            columns={getColumns()}
            rowSelection={{
                selected: props.select,
                onChange: setSelected,
                type: 'radio'
            }}
            relations={['cities', 'specialization', 'vacancyUnit', 'formPreset', 'landings']} />
    </StyledModal>;
}

export default QrVacancyLinkModal;
