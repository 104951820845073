import React, { useContext } from 'react';
import { message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { putVacancyHandler } from '../../../actions/handlers';
import { VACANCY } from '../../../constants/urls';
import ActionsContext from '../../../contexts/ActionsContext';
import ResponsibleForm from '../../forms/ResponsibleForm';
import { PUT_VACANCY } from '../../../constants/actionTypes';

const ResponsibleModal = props => {
    const { t } = useTranslation();
    const { revalidateAction, onSuccessFn } = useContext(ActionsContext);
    const { modal, params, close } = props;

    return (
        <Modal
            {...modal}
            title={t('vacancy.detail.responsible')}
            footer={null}
        >
            <ResponsibleForm
                formAction={putVacancyHandler}
                onSuccess={() => {
                    message.success(t('vacancy.detail.responsibleEditSuccess'));
                    onSuccessFn(PUT_VACANCY);
                    revalidateAction(VACANCY.stringify());
                    close();
                }}
                onSubmitFail={() => message.error(t('vacancy.detail.responsibleEditError'))}
                item={params.vacancy}
                close={close}
            />
        </Modal>
    );
}

export default ResponsibleModal;
