import form from './ru/form.json';
import vacancy from './ru/vacancy.json';
import applicant from './ru/applicant.json';
import auth from './ru/auth.json';
import banner from './ru/banner.json';
import call from './ru/call.json';
import chat from './ru/chat.json';
import company from './ru/company.json';
import domain from './ru/domain.json';
import guest from './ru/guest.json';
import landing from './ru/landing.json';
import profile from './ru/profile.json';
import settings from './ru/settings.json';
import dialog from './ru/dialog.json';
import userAdmin from './ru/userAdmin.json';
import app from './ru/app.json';
import bot from './ru/bot.json';
import unit from './ru/unit.json';
import reports from './ru/reports.json';

export default {
    app,
    form,
    vacancy,
    applicant,
    auth,
    banner,
    call,
    chat,
    company,
    domain,
    guest,
    landing,
    profile,
    settings,
    dialog,
    userAdmin,
    bot,
    unit,
    reports,
};
