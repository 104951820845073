import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import UnitForm from '../../forms/UnitForm';
import { postVacancyUnitHandler, putVacancyUnitHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { COMPANY_SETTINGS, VACANCY_UNIT } from '../../../constants/urls';

const UnitModal = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const { modal, params, close } = props;

    return <Modal
        {...modal}
        footer={null}
        title={params ? t('unit.editUnit') : t('unit.addUnit')}>
        <UnitForm
            item={params}
            formAction={params ? putVacancyUnitHandler : postVacancyUnitHandler}
            onSuccess={() => {
                revalidateAction(COMPANY_SETTINGS.stringify());
                revalidateAction(VACANCY_UNIT.stringify());
                message.success(t(params ? 'unit.unitEditSuccess' : 'unit.unitAddSuccess'));
                close();
            }} />
    </Modal>;
}

export default UnitModal;
