export const MESSAGE_NODE = 'messageNode';
export const VACANCY = 'vacancy';
export const MENU = 'menu';
export const VACANCIES = 'vacancies';
export const URL = 'url';

export default [
    { id: MENU, value: 'chat.messageTransitions.menu' },
    { id: VACANCIES, value: 'chat.messageTransitions.vacancies' },
    { id: VACANCY, value: 'chat.messageTransitions.vacancy' },
    { id: URL, value: 'chat.messageTransitions.url' }
];
