import React from 'react';
import { Field } from 'react-final-form';
import { Col } from 'antd';
import { withTranslation } from 'react-i18next';

import withFilterForm from './base/withFilterForm';
import BaseFilter from './base/BaseFilter';
import withUserCompany from '../hocs/withUserCompany';
import Select from '../forms/formComponents/Select';

export const userState = userId => [
    { value: 'vacancy.form.myForms', id: userId },
    { value: 'vacancy.form.all', id: null }
];

export default withUserCompany(withTranslation()(withFilterForm(({ userId, t }) => (
    <BaseFilter
        searchFieldName="title"
        searchFieldPlaceholder={t('settings.name')}
        span={6}
    >
        <Col className='text-left' span={8}>
            <Field
                name='user'
                component={Select}
                options={userState(userId)}
                placeholder={t('vacancy.form.all')}
            />
        </Col>
    </BaseFilter>
))));
