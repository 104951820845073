import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import TerminalForm from './TerminalForm';
import { putVacancyHandler } from '../../../actions/handlers';
import { COMPANY_SETTINGS, VACANCY } from '../../../constants/urls';
import ActionsContext from '../../../contexts/ActionsContext';
import { PUT_VACANCY } from '../../../constants/actionTypes';

const TerminalModal = props => {
    const { t } = useTranslation();
    const { modal, params, close } = props;
    const { revalidateAction, onSuccessFn } = useContext(ActionsContext);

    return <Modal
        {...modal}
        title={t('vacancy.terminal.terminalSettings')}
        footer={null}>
        <TerminalForm
            formAction={putVacancyHandler}
            vacancy={{
                ...params.vacancy,
                companySettings: params.companySettings
            }}
            companySettings={params.companySettings}
            edit={params.edit}
            onSuccess={(_, data) => {
                onSuccessFn(PUT_VACANCY);
                revalidateAction(VACANCY.stringify());
                revalidateAction(COMPANY_SETTINGS.stringify());
                message.success(t('vacancy.terminal.terminalSettingsSuccess'));
                params.setFormData(data[0]);
                close();
            }}
            onSubmitFail={() => message.error(t('vacancy.terminal.terminalSettingsError'))} />
    </Modal>;
}

export default TerminalModal;
