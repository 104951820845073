export const ICON_URLS = {
    viber: '/viber.svg',
    facebook: '/facebook.svg',
    telegram: '/telegram.svg',
    web: '/logo-bot.svg',
};

export const TYPE_OPTIONS = [
    { value: 'viber', iconSrc: ICON_URLS.viber },
    { value: 'telegram', iconSrc: ICON_URLS.telegram },
    { value: 'web', iconSrc: ICON_URLS.web },
    { value: 'facebook', iconSrc: ICON_URLS.facebook }
];

export const VACANCY_ICONS = [
    'cook',
    'cook-2',
    'waiter',
    'waiter-2',
    'barman',
    'admin',
    'manager',
    'manager-2',
    'manager-3',
    'office-manager',
    'office-manager-2',
    'hr-manager',
    'account-manager',
    'call-operator',
    'call-operator-2',
    'call-operator-3',
    'call-operator-4',
    'seller',
    'seller-2',
    'seller-3',
    'cashier',
    'cashier-2',
    'merchandiser',
    'merchandiser-2',
    'driver',
    'loader',
    'courier',
    'courier-2',
    'loader-2',
    'loader-3',
    'handyman',
    'handyman-2',
    'handyman-3',
    'handyman-4',
    'installer',
    'installer-2',
    'installer-3',
    'welder',
    'builder',
    'builder-2',
    'builder-3',
    'builder-4',
    'painter',
    'cleaner',
    'cleaner-2',
    'cleaner-3',
    'barber',
    'barber-2',
    'maid',
    'gardener',
    'car',
    'auto-mechanic',
    'auto-mechanic-2',
    'auto-mechanic-3',
    'carwash'
];
