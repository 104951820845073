import React, { useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import MessageButton from './MessageButton';

const ButtonsField = props => {
    const { t } = useTranslation();
    const [active, setActive] = useState(false);

    const toggleButton = (button, buttonIndex) => {
        const { onToggleButton } = props;

        setActive(active !== buttonIndex ? buttonIndex : null);

        onToggleButton(button, buttonIndex);
    }

    const addButton = () => {
        props.fields.push({});
        setTimeout(() => props.submitForm());
    }

   const onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        props.fields.move(result.source.index, result.destination.index);
        setActive(active === result.source.index ? result.destination.index : active);
    }

    const { fields, edit, id } = props;

    return <div className='message-btns'>
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppdable'>
                { provided =>
                    <div ref={provided.innerRef}>
                        { fields && fields.map((name, btnIndex) =>
                            <MessageButton
                                {...props}
                                key={`${id}-btn-${btnIndex}`}
                                name={`${name}.text`}
                                button={fields.value[btnIndex]}
                                btnIndex={btnIndex}
                                active={active === btnIndex}
                                onToggleButton={toggleButton}
                                remove={fields.remove}
                                edit={edit}
                                onEnter={addButton} />
                        )}
                    </div>
                }
            </Droppable>
        </DragDropContext>
        { edit &&
            <Button
                className='message-btns-add'
                onClick={addButton}
            >
                <PlusOutlined /> { t('settings.addButton') }
            </Button>
        }
    </div>;
}

export default ButtonsField;
