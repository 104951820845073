import React, { Fragment, useState } from 'react';
import { Field } from 'react-final-form';
import { assocPath, path } from 'ramda';
import { Radio, Button, Spin, Popconfirm } from 'antd';
import styled from 'styled-components';
import { WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { SCROLL_DIRECTION } from '../../constants/banner';
import SubmitButton from './formComponents/SubmitButton';
import { Select } from './formComponents/Select';
import withFormWrapper from '../hocs/withFormWrapper';
import { Container } from './BannerForm';
import InputNumber from './formComponents/InputNumber';
import RadioGroup from './formComponents/RadioGroup';
import { VACANCY } from '../../constants/urls';

const TogglerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 1;
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 16px;

    .ant-form-item-control .search-input {
        margin-top: 0;
    }

    .ant-form-item {
        margin-bottom: 0;
    }
`;

const HeadColumn = styled.div`
    flex: .5;
`;

const SettingsContainer = styled(Container)`
    display: flex;
    flex-direction: column;
`;

const EmptyContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const SLiderVacancyToggleForm = props => {
    const [visible, setVisible] = useState(null);
    const { t } = useTranslation();

    const getPopConfirmProps = (value, type) => {
        return {
            title: <div>
                <div>{ t('banner.changesWillCancel') }</div>
                <div>{ t('banner.continueConfirm') }</div>
            </div>,
            okText: t('banner.yes'),
            cancelText: t('banner.no'),
            onConfirm: () => props.setShowVacancySlider(value),
            visible: type === visible,
            onVisibleChange: visible => {
                if (value !== props.showVacancySlider && (props.hasTemplate || (!props.selectedVacancy && props.showVacancySlider))) {
                    setVisible(visible ? type : null);
                }
            },
            placement: 'bottomLeft'
        };
    }

    const toggle = e => {
        if (!props.hasTemplate && (props.selectedVacancy || !props.showVacancySlider)) {
            props.setShowVacancySlider(e.target.value);
        }
    }

    const onChangeVacancySelector = selectedVacancy => {
        props.onChange(selectedVacancy);
    }

    const { showVacancySlider, data, close, pending, selectedVacancy, putVacancyPending } = props;

    return <Fragment>
        <TogglerWrapper>
            <HeadColumn>
                <Radio.Group value={showVacancySlider} onChange={toggle} id='vacancy-slider-switcher'>
                    <Popconfirm {...getPopConfirmProps(false, 'static')} >
                        <Radio.Button value={false}>{ t('banner.static') }</Radio.Button>
                    </Popconfirm>
                    <Popconfirm {...getPopConfirmProps(true, 'vacancies')}>
                        <Radio.Button value={true}>{ t('banner.vacanciesList') }</Radio.Button>
                    </Popconfirm>
                </Radio.Group>
            </HeadColumn>
            { showVacancySlider && (!putVacancyPending) &&
                <HeadColumn>
                    <Select
                        actionUrl={VACANCY.stringify()}
                        placeholder={t('banner.chooseVacancy')}
                        onChange={onChangeVacancySelector}
                        input={{ value: selectedVacancy, name: 'vacancySelector' }}
                        renderLabel={({ label, data }) => (
                            <div>
                                { !path(['option', 'banner'], data) && <WarningOutlined />} {label}
                            </div>
                        )}
                        namePath='title'
                        filter={{
                            vacancyUnit: data.id,
                            published: true,
                            active: true,
                            sliderPublished: true,
                        }}
                        allowClear />
                </HeadColumn>
            }
        </TogglerWrapper>
        { showVacancySlider && (!selectedVacancy || pending) &&
            <Fragment>
                <SettingsContainer isUnit>
                    { !selectedVacancy &&
                        <div>
                            <Field
                                name='banner.sliderVerticalScroll'
                                component={RadioGroup}
                                options={SCROLL_DIRECTION}
                                disableClear />
                            <Field
                                name='banner.sliderSpeed'
                                component={InputNumber}
                                type='number'
                                label={t('banner.speed')}
                                disableClear />
                        </div>
                    }
                    <EmptyContainer>
                        { pending ? <Spin className='central-spin' /> : t('banner.chooseVacancy') }
                    </EmptyContainer>
                </SettingsContainer>
                <div className='ant-modal-footer'>
                    <div></div>
                    <Button onClick={close}>
                        { t('banner.close') }
                    </Button>
                    <SubmitButton>
                        { t('form.save') }
                    </SubmitButton>
                </div>
            </Fragment>
        }
    </Fragment>;
}

export default withFormWrapper(SLiderVacancyToggleForm, {
    mapPropsToValues: ({ data }) => ({
        ...data,
        banner: {
            sliderVerticalScroll: false,
            sliderSpeed: 10,
            ...(data.banner || {})
        }
    }),
    mapBeforeSubmit: values => assocPath(['banner', 'showVacancySlider'], true, values)
});
