import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import DialogVacancyForm from './DialogVacancyForm';
import { putVacancyHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { COMPANY_SETTINGS, VACANCY } from '../../../constants/urls';
import { PUT_VACANCY } from '../../../constants/actionTypes';

const DialogVacancyModal = props => {
    const { t } = useTranslation();
    const { revalidateAction, onSuccessFn } = useContext(ActionsContext);
    const { modal, params: { vacancy, setFormData }, close } = props;

    return <Modal
        {...modal}
        title={t('vacancy.questions.chatBotSettings')}
        footer={null}>
        <DialogVacancyForm
            formAction={putVacancyHandler}
            vacancy={vacancy}
            onSuccess={(_, data) => {
                onSuccessFn(PUT_VACANCY);
                revalidateAction(VACANCY.stringify());
                revalidateAction(COMPANY_SETTINGS.stringify());
                message.success(t('vacancy.questions.chatBotSettingsSuccess'));
                setFormData(data[0]);
                close();
            }}
            onSubmitFail={() => message.error(t('vacancy.questions.chatBotSettingsError'))} />
    </Modal>;
}

export default DialogVacancyModal;
