import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/jsdom';

import 'core-js/modules/es6.array.map';
import 'core-js/modules/es6.array.from';
import 'core-js/modules/es6.string.includes';
import 'core-js/modules/es7.array.includes';
import 'core-js/es7/set';

import unorm from 'unorm';
import { shim } from 'document.contains';

if (!String.prototype.normalize) {
    String.prototype.normalize = unorm.nfkd;
}

if (!document.contains) {
    shim();
}

if (!window.crypto) {
    window.crypto = window.msCrypto;
}

if (!document.getElementsByClassName) {
    document.getElementsByClassName = function (search) {
        var d = document, elements, pattern, i, results = [];
        if (d.querySelectorAll) {
            return d.querySelectorAll("." + search);
        }
        if (d.evaluate) {
            pattern = ".//*[contains(concat(' ', @class, ' '), ' " + search + " ')]";
            elements = d.evaluate(pattern, d, null, 0, null);
            while ((i = elements.iterateNext())) {
                results.push(i);
            }
        } else {
            elements = d.getElementsByTagName("*");
            pattern = new RegExp("(^|\\s)" + search + "(\\s|$)");
            for (i = 0; i < elements.length; i++) {
                if (pattern.test(elements[i].className)) {
                    results.push(elements[i]);
                }
            }
        }
        return results;
    }
}

if (SVGElement.prototype.getElementsByClassName === undefined) {
    SVGElement.prototype.getElementsByClassName = function (className) {
        return this.querySelectorAll('.' + className);
    };
}

(function (window) {
    try {
      new MouseEvent('test');
      return false;
    } catch (e) {
        // error
    }

    var MouseEventPolyfill = function (eventType, params) {
        params = params || { bubbles: false, cancelable: false };
        var mouseEvent = document.createEvent('MouseEvent');
        mouseEvent.initMouseEvent(eventType,
            params.bubbles,
            params.cancelable,
            window,
            0,
            params.screenX || 0,
            params.screenY || 0,
            params.clientX || 0,
            params.clientY || 0,
            params.ctrlKey || false,
            params.altKey || false,
            params.shiftKey || false,
            params.metaKey || false,
            params.button || 0,
            params.relatedTarget || null
        );

        return mouseEvent;
    }

    MouseEventPolyfill.prototype = Event.prototype;

    window.MouseEvent = MouseEventPolyfill;
})(window);
