import React from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

import PasswordConfirmForm from '../../forms/PasswordConfirmForm';
import GuestLink from './GuestLink';
import { postPasswordRecoveryConfirmHandler } from '../../../actions/handlers';

const PasswordRecoveryConfirm = props => {
    const { t } = useTranslation();
    const restoreToken = props.match.params.restoreToken;

    return (
        <div>
            <h3>{ t('guest.passwordRecoveryTitle') }</h3>
            <PasswordConfirmForm
                formAction={postPasswordRecoveryConfirmHandler}
                restoreToken={restoreToken}
                onSuccess={() => {
                    notification.success({
                        message: t('guest.passwordRecoveryTitle'),
                        description: t('guest.recoverySuccess'),
                    });
                    props.history.push('/');
                }}
                onSubmitFail={() => notification.error({
                    message: t('guest.passwordRecoveryTitle'),
                    description: t('guest.recoveryLinkError'),
                })}
            />
            <div className='link-block'>
                <GuestLink to='/'>{ t('guest.login') }</GuestLink>
            </div>
        </div>
    );
}

export default PasswordRecoveryConfirm;
