import { propOr, path } from 'ramda';
import qs from 'qs';

import { LINES_PER_PAGE } from '../constants/lists';

export const getUrlParams = location => {
    return qs.parse(propOr('', 'search', location), { ignoreQueryPrefix: true, strictNullHandling: true });
};

export const getFilters = (location, prefix) => {
    const params = getUrlParams(location);

    try {
        return JSON.parse(prefix ? path([prefix, 'filter'], params) : params.filter);
    } catch {
        return null;
    }
};

export const getUrlPagination = (location, prefix) => {
    const params = getUrlParams(location);
    const { offset = 0, limit = LINES_PER_PAGE } = prefix ? propOr({}, prefix, params) : params;

    return ({
        offset: Number(offset),
        limit: Number(limit),
    });
};

export const extendSearchPath = (location, searchPath = {}) => {
    const current = qs.parse(location.search, { ignoreQueryPrefix: true });

    return `${location.pathname}${qs.stringify({ ...current, ...searchPath }, { addQueryPrefix: true, strictNullHandling: true })}`;
}

export const getUrlWithFilters = (url, values) => {
    const search = qs.stringify({ filter: JSON.stringify(values) }, { addQueryPrefix: true, strictNullHandling: true });

    return `${url}${search}`;
};
