import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import { prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import BotStepsForm, { withBotSteps, onSubmitFailed, onSuccess } from './BotStepsForm';
import Input from './formComponents/Input';
import { validationSchema } from './BotForm';
import withFormWrapper from '../hocs/withFormWrapper';

import telegram1 from '../../images/telegram1.png';

const TelegramBotForm = props => {
    const { t } = useTranslation();

    const step1 = () => {
        return <Fragment>
            <p>{ t('bot.telegram.step1_1') }</p>
            <div>{ t('bot.telegram.step1_2') } <a href='https://telegram.me/botfather'>BotFather</a> { t('bot.telegram.step1_3') }
                <div className='wrap-img'>
                    <img alt='telegram screen BotFather dialog' src={telegram1} />
                </div>
            </div>
        </Fragment>;
    }

    const step2 = () => {
        return <p>{ t('bot.telegram.step2_1') } <br/><code>(/newbot - create a new bot)</code></p>;
    }

    const step3 = () => {
        return <Fragment>
            <p>{ t('bot.telegram.step3_1') } <br/><code>{ t('bot.telegram.step3_2') }</code></p>
            <Field
                name='username'
                component={Input}
                label={t('bot.botName')}
                required
                disableClear
            />
        </Fragment>;
    }

    const step4 = () => {
        return <Fragment>
            <p>{ t('bot.telegram.step4_1') } <br/><code>Use this token to access the HTTP API: ***</code></p>
            <Field
                name='token'
                component={Input}
                label={t('bot.token')}
                required
            />
        </Fragment>;
    }

    const renderForm = () => {
        const { formAction, close, step, setStep } = props;
        const steps = [
            { render: step1 },
            { render: step2 },
            { render: step3, field: 'username' },
            { render: step4, submit: true, field: 'token' }
        ];

    return <BotStepsForm
        steps={steps}
        formAction={formAction}
        close={close}
        step={step}
        setStep={setStep} />;
    }

    return renderForm();
}

export default withBotSteps(withFormWrapper(TelegramBotForm, {
    mapPropsToValues: prop('bot'),
    validationSchema,
    onSuccess,
    onSubmitFailed
}));
