import React, { useContext, useEffect, useRef, useState } from 'react';
import { Badge, Button, message, Spin, Dropdown, Menu } from 'antd';
import { path, omit } from 'ramda';
import { Icon } from '@experium/findy-ui';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import ActionsContext from '../../../contexts/ActionsContext';
import ModalsContext from '../../../contexts/ModalsContext';
import { BOT, COMPANY_SETTINGS, VACANCY } from '../../../constants/urls';
import { postVacancyHandler, putVacancyHandler } from '../../../actions/handlers';
import { OPEN_RESPONSIBLE_MODAL, OPEN_VACANCY_WIDGET_MODAL } from '../../../constants/actionTypes';
import withUserCompany from '../../hocs/withUserCompany';
import ErrorHandler from '../../hocs/withErrorHandler';
import { getUrlWithFilters } from '../../../utils/urlParams';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';
import DeepLinksDropdown from '../../table/DeepLinksDropdown';
import VacancyForm from '../../forms/vacancyForm/VacancyForm';
import VacancyMasterForm from '../../forms/vacancyForm/VacancyMasterForm';

const getId = path(['match', 'params', 'id']);

const Vacancy = props => {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    const companySettings = useCompanyCodeSwr(COMPANY_SETTINGS.stringify());
    const bots = useCompanyCodeSwr(BOT.stringify());
    const actionUrl = VACANCY.stringify({ id: props.match.params.id });
    const vacancy = useCompanyCodeSwr([actionUrl, props.match.params.id], url => axios.get(url, {
        params: {
            relations: ['company', 'createdBy', 'cities', 'specialization', 'specialization.parent', 'vacancyUnit', 'category', 'chartOfWork', 'classifiers', 'employeeType', 'educationLevel', 'salaryCurrency', 'salaryType', 'domains', 'formPreset', 'responsibleBy'],
        },
    }), { revalidateOnMount: getId(props) });
    const vacancyEdit = useCompanyCodeSwr([actionUrl, props.match.params.id, 'edit'], url => axios.get(url, {
        params: {
            relations: ['company', 'createdBy', 'cities', 'specialization', 'specialization.parent', 'vacancyUnit', 'category', 'chartOfWork', 'classifiers', 'employeeType', 'educationLevel', 'salaryCurrency', 'salaryType', 'domains', 'formPreset', 'responsibleBy'],
        },
        headers: {
            'Accept-Language': 'ru',
        }
    }), { revalidateOnMount: getId(props) });
    const didMount = useRef(null);
    const { addAction, removeAction } = useContext(ActionsContext);
    const modals = useContext(ModalsContext);

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            addAction(actionUrl, vacancy);
            addAction(`/edit${actionUrl}`, vacancyEdit);
        }
    });

    useEffect(() => {
        return () => {
            removeAction(actionUrl);
            removeAction(`/edit${actionUrl}`);
        };
    }, []);

    const createSimilarVacancy = () => {
        postVacancyHandler(omit(['id', 'code'], vacancy.data)).then(data => {
            message.success(t('vacancy.detail.createSuccess'));
            props.history.replace(`/vacancy/${data[0].id}?type=similar`);
        }).catch(() => message.error(t('vacancy.detail.createSameError')));
    }

    const getMoreMenu = () => {
        const { isFullLicense, isAdmin, companyId, isUser } = props;

        return <Menu>
            { isFullLicense && (
                <Menu.Item onClick={() => modals.open(OPEN_VACANCY_WIDGET_MODAL, { vacancy: vacancy.data })}>
                    { t('vacancy.detail.widget') }
                </Menu.Item>
            )}
            { (isAdmin ? path(['data', 'company'], vacancy) === companyId : true) &&
                <Menu.Item onClick={createSimilarVacancy}>
                    { t('vacancy.detail.createSameVacancy') }
                </Menu.Item>
            }
            { !isUser &&
                <Menu.Item onClick={() => modals.open(OPEN_RESPONSIBLE_MODAL, { vacancy: vacancy.data })}>
                    { t('vacancy.detail.editResponsible') }
                </Menu.Item>
            }
        </Menu>
    }

    const renderEditContent = () => {
        const { companyCode } = props;

        return <VacancyMasterForm
            {...props}
            vacancy={vacancyEdit.data}
            companySettings={companySettings.data || {}}
            keepDirtyOnReinitialize={true}
            companyCode={companyCode}
            onComplete={() => setEdit(false)}
            edit />
    }

    const { isFullLicense, history, match, companyId, isAdmin } = props;
    const data = vacancy.data || {};

    return <ErrorHandler action={vacancy} key={match.params.id}>
        <div className='toolbar vacancy-toolbar'>
            <Button shape='circle' icon={edit ? <CloseOutlined /> : <ArrowLeftOutlined />} onClick={edit ? () => setEdit(false) : history.goBack} />
            <h1 className='vacancy-block-title'>
                { data.icon && <Icon size={23} icon={data.icon} /> }
                <span className='vacancy-title'>{ data.title }</span>
            </h1>
            { !!data && !edit &&
                <div className='toolbar-actions'>
                    { isFullLicense && (
                        <DeepLinksDropdown
                            bots={bots}
                            data={data}
                        />
                    )}
                    <Button.Group>
                        { (isAdmin ? data.company === companyId : true) && data.id && (
                            <Button type='primary' onClick={() => setEdit(true)}>{ t('vacancy.detail.edit') }</Button>
                        )}
                        <Button className='applicants-btn' onClick={() => history.push(getUrlWithFilters('/applicants', { vacancy: data.id, rejectedState: null }))}>
                            { t('vacancy.detail.applicants') } <Badge count={data.applicantCount || 0} className='primary' showZero overflowCount={10000} />
                        </Button>
                        <Dropdown overlay={getMoreMenu()} trigger={['click']}>
                            <Button className='vacancy-more-btn'>{ t('vacancy.detail.more') }</Button>
                        </Dropdown>
                    </Button.Group>
                </div>
            }
        </div>
        { vacancy.isValidating && !vacancy.data ?
            <Spin className='central-spin' /> :
            <div className='vacancy-edit-block'>
                { edit ?
                    renderEditContent() :
                    <VacancyForm
                        formAction={putVacancyHandler}
                        onSuccess={() => {
                            vacancy.mutate();
                            vacancyEdit.mutate();
                            companySettings.mutate();
                        }}
                        onSubmitFail={() => message.error(t('vacancy.detail.saveError'))}
                        vacancy={data}
                        onPutVacancySyncSuccess={() => {
                            vacancy.mutate();
                            vacancyEdit.mutate();
                            companySettings.mutate();
                            message.success(t('vacancy.detail.fieldsAddSuccess'));
                        }}
                        companySettings={companySettings.data || {}}
                        match={match}
                        history={history}
                        companyCode={props.companyCode}
                    />
                }
            </div>
        }
    </ErrorHandler>;
}

export default withUserCompany(Vacancy);
