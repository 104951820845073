import React, { Fragment, useContext, useState, useEffect } from 'react';
import { is, dissocPath, all, values, equals, propOr, prop, pathOr, path, propEq, split } from 'ramda';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Field, FormSpy } from 'react-final-form';
import { Button, Dropdown, Menu, Tooltip } from 'antd';
import { FieldArray } from 'react-final-form-arrays';
import cx from 'classnames';
import { EditOutlined, GlobalOutlined, DeleteOutlined, PlusOutlined, ArrowsAltOutlined, CheckOutlined, InfoCircleTwoTone, LockOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { OPEN_CONFIRM_MODAL, OPEN_FORM_SELECT_MODAL, OPEN_VACANCY_FORM_PRESET_MODAL, OPEN_VACANCY_QUESTIONS_MODAL, OPEN_VACANCY_QUESTION_LANGUAGE_MODAL, OPEN_VACANCY_QUESTION_MODAL, PUT_VACANCY } from '../../../constants/actionTypes';
import { required } from '../../../utils/validation';
import checkTranslation from '../../../utils/translation';
import { getFormQuestions } from '../../../utils/vacancy';
import { DEFAULT_QUESTIONS, QUESTIONS_KEY, OPD_TYPE } from '../../../constants/questions';
import { renderCompanyDictionaryOrderError, renderGeoQuestionOrderError } from '../../../utils/errors';
import { isBlockQuestion, hasBlockQuestions } from '../../../utils/vacancy';
import DraggableList from '../formComponents/DraggableList';
import Input from '../formComponents/Input';
import Switch from '../formComponents/Switch';
import IconSwitch from '../formComponents/IconSwitch';
import VacancyTranslationButton from './VacancyTranslationButton';
import ListenerField from '../ListenerField';
import { VACANCY_FORM_PRESET } from '../../../constants/urls';
import ActionsContext from '../../../contexts/ActionsContext';
import ModalsContext from '../../../contexts/ModalsContext';
import nanoid from '../../../utils/nanoid';
import withUserCompany from '../../hocs/withUserCompany';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';
import { OPD_FIELD } from '../../../constants/vacancy';

const LockQuestion = ({ item }) => {
    const { t } = useTranslation();
    const correctValues = isBlockQuestion(item);

    const tooltipTitle = !!correctValues && (
        <div>
            <b>{t('applicant.barierQuestion')}</b>
        </div>
    );

    return (
        <div className='lock-view'>
            { tooltipTitle ? (
                <Tooltip placement='bottom' title={tooltipTitle}>
                    <LockOutlined />
                </Tooltip>
            ) : null}
        </div>
    );
}

const Questions = props => {
    const { t } = useTranslation();
    const { addAction, removeAction, addOnSuccessFn, removeOnSuccessFn } = useContext(ActionsContext);
    const [selectedPreset, updateSelectedPreset] = useState(pathOr({}, ['vacancy', '_relations', 'formPreset'], props));
    const [idState, updateIdState] = useState({
        isEditingId: false,
        editableFields: [],
    });
    const vacancyFormPresets = useCompanyCodeSwr(VACANCY_FORM_PRESET.stringify(), url => axios.get(url, {
        params: {
            relations: ['domains']
        }
    }));
    const modals = useContext(ModalsContext);

    useEffect(() => {
        addAction(VACANCY_FORM_PRESET.stringify(), vacancyFormPresets);
        addOnSuccessFn(PUT_VACANCY, () => updateIdState({
            isEditingId: false,
            editableFields: [],
        }));

        return () => {
            removeAction(VACANCY_FORM_PRESET.stringify());
            removeOnSuccessFn(PUT_VACANCY);
        };
    }, []);

    const onDragEnd = result => {
        const { fields } = props;
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        fields.move(
            source.index,
            destination.index
        );
        const draggableList = document.querySelector('.draggable-list');
        draggableList.style.marginBottom = '';
    }

    const onSubQuestionDragEnd = (result, move) => {
        const { source, destination } = result;

        if (prop('droppableId', source) === prop('droppableId', destination)) {
            move(
                source.index,
                destination.index
            );
        }
    }

    const getQuestionValue = (type, questionValue) => {
        if (type === 'composite' &&  questionValue.type !== 'composite') {
            return dissocPath(['settings', 'questions'], questionValue);
        } else {
            return questionValue;
        }
    }

    const onChangeQuestion = (index, type) => questionValue => {
        const { fields: { update }, onChangeQuestion } = props;
        const value = getQuestionValue(type, questionValue);

        onChangeQuestion ? (
            onChangeQuestion(index)(value)
        ) : update(index, value);
    }

    const onChangeCompositeQuestion = (index, update) => questionValue => {
        update(index, questionValue);
    }

    const getItem = (index) => {
        const { form, questionsType } = props;
        const item = pathOr({}, ['value', index], form.getFieldState(QUESTIONS_KEY[questionsType]));

        return item;
    }

    const toggleFieldEdit = (name) => {
        const prevEditStatus = pathOr(false, ['editableFields', name], idState);

        updateIdState({
            ...idState,
            editableFields: {
                ...idState.editableFields,
                [name]: !prevEditStatus,
            },
        });
    }

    const renderCompositeQuestions = (name, index, label) => {
        const { formDisabled, highlightError } = props;

        return (
            <FieldArray
                name={`${name}.${index}.settings.questions`}
                validate={(value) => !value || value && value.length === 0 ? 'Необходимо добавить вопрос' : undefined}
                render={(fieldArrayProps) => {
                    const { fields: { name, push, map, value, remove, update, move }, meta: { error, submitFailed } } = fieldArrayProps;
                    const { form, hideLanguageButton, questionsType } = props;
                    const { isEditingId } = idState;

                    return (
                        <div className={`composite ${formDisabled ? 'disabled' : '' }`}>
                            <DragDropContext onDragEnd={(result) => onSubQuestionDragEnd(result, move)}>
                                <Droppable droppableId="droppable-111" ignoreContainerClipping={true}>
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            { map((fieldItem, fieldIndex) => {
                                                const item = value[fieldIndex];
                                                const isTransalated = all(equals(true))(values(checkTranslation([item])));
                                                const idIsEditing = path(['editableFields', `${name}.${fieldIndex}`], idState);
                                                const isOpd = propEq('type', OPD_TYPE)(item);
                                                const key = isEditingId ? `${item.label}-${item.question}` : item.field;

                                                return (
                                                    <div key={key}>
                                                        <Draggable
                                                            key={key}
                                                            draggableId={key}
                                                            index={fieldIndex}
                                                            isDragDisabled={isEditingId || formDisabled}
                                                            disableInteractiveElementBlocking={true}
                                                        >
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className='draggable-item-row'
                                                                    key={fieldIndex}
                                                                >
                                                                    <div className='draggable-item-cell'>
                                                                        <div className='dnd-icon' >
                                                                            <ArrowsAltOutlined />
                                                                        </div>
                                                                        <Field
                                                                            name={`${name}.${fieldIndex}.label`}
                                                                            component={Input}
                                                                            highlightError={highlightError}
                                                                            disableClear={true}
                                                                            disabled={formDisabled}
                                                                            validate={required}
                                                                        />
                                                                    </div>
                                                                    <div className='draggable-item-cell'>
                                                                        <Field
                                                                            name={`${name}.${fieldIndex}.question`}
                                                                            component={Input}
                                                                            placeholder={t(`vacancy.placeholders.${item.field}`)}
                                                                            highlightError={highlightError}
                                                                            disableClear={true}
                                                                            disabled={formDisabled}
                                                                            validate={required}
                                                                        />
                                                                    </div>
                                                                    <div className='draggable-item-cell'>
                                                                        <Field
                                                                            key={item.question}
                                                                            name={`${name}.${fieldIndex}.required`}
                                                                            component={Switch}
                                                                            disabled={isOpd || formDisabled}
                                                                            disableClear={true}
                                                                        />
                                                                    </div>
                                                                    <div className='draggable-item-cell'>
                                                                        <LockQuestion item={item} />
                                                                    </div>
                                                                    { !formDisabled && (
                                                                        <div className='draggable-item-cell move-button'>
                                                                            <Button.Group>
                                                                                <Button
                                                                                    icon={<EditOutlined />}
                                                                                    onClick={() => modals.open(OPEN_VACANCY_QUESTION_MODAL, {
                                                                                        item,
                                                                                        onChange: onChangeCompositeQuestion(fieldIndex, update),
                                                                                        isCompositeQuestion: true,
                                                                                        getFormQuestions: getFormQuestions,
                                                                                    })}
                                                                                    disabled={formDisabled}
                                                                                />
                                                                                { idState.isEditingId && (
                                                                                    <Button
                                                                                        className='id-button'
                                                                                        onClick={() => toggleFieldEdit(`${name}.${fieldIndex}`)}
                                                                                        disabled={formDisabled}
                                                                                    >ID</Button>
                                                                                )}
                                                                                { !hideLanguageButton && (
                                                                                    <Button
                                                                                        icon={<GlobalOutlined />}
                                                                                        onClick={() => modals.open(OPEN_VACANCY_QUESTION_LANGUAGE_MODAL, {
                                                                                            item,
                                                                                            onChange: onChangeCompositeQuestion(fieldIndex, update),
                                                                                            form,
                                                                                            questionsType,
                                                                                            formAction: props.formAction,
                                                                                            add: !item.id
                                                                                        })}
                                                                                        className={cx('row-language-button', {
                                                                                            translated: isTransalated && !formDisabled,
                                                                                        })}
                                                                                        disabled={formDisabled}
                                                                                    />
                                                                                )}
                                                                                <Button
                                                                                    type='danger'
                                                                                    onClick={() => remove(fieldIndex)}
                                                                                    icon={<DeleteOutlined />}
                                                                                    disabled={formDisabled}
                                                                                />
                                                                            </Button.Group>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                        { isEditingId ? (
                                                            <div className={cx('id-row', { collapse: !idIsEditing || formDisabled })}>
                                                                <Field
                                                                    label={t('vacancy.questions.questionId')}
                                                                    name={`${name}.${fieldIndex}.field`}
                                                                    component={Input}
                                                                    disabled={formDisabled}
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            { error && submitFailed && <div className='questions-error'>{error}</div> }
                            { !formDisabled && (
                                <div className='composite-buttons-row'>
                                    <Button
                                        className='composite-add-button'
                                        style={{ marginBottom: 7 }}
                                        icon={<PlusOutlined />}
                                        onClick={() => modals.open(OPEN_VACANCY_QUESTION_MODAL, {
                                            onChange: (formItem) => push(formItem),
                                            isCompositeQuestion: true,
                                            item: {
                                                type: 'text',
                                                field: nanoid(),
                                                customizable: true,
                                                required: true
                                            },
                                            getFormQuestions: getVacancyFormQuestions,
                                        })}>
                                        { t('vacancy.questions.createQuestionInBlock', { label }) }
                                    </Button>
                                </div>
                            )}
                        </div>
                    );
                }}
                validateOnChange={false}
            />
        )
    }

    const renderItem = (index, dragHandleProps) => {
        const {
            fields: {
                name,
                remove,
                length
            },
            highlightError,
            formDisabled,
            form,
            hideLanguageButton,
            questionsType,
            isSubQuestions,
            isCompositeQuestion,
            isFunctionalLicense,
            isAdmin
        } = props;
        const item = getItem(index);
        const isComposite = item.type === 'composite';
        const isTransalated = all(equals(true))(values(checkTranslation([item], isComposite)));
        const idIsEditing = path(['editableFields', `${name}.${index}`], idState);
        const subQuestionsLength = pathOr([], ['settings', 'questions'], item).length;
        const isOpd = propEq('type', OPD_TYPE)(item);

        return (
            <div>
                <div className='draggable-item-row selected' {...dragHandleProps}>
                    <div className='draggable-item-cell'>
                        <div className='dnd-icon' >
                            <ArrowsAltOutlined />
                        </div>
                        <Field
                            name={`${name}.${index}.label`}
                            component={Input}
                            highlightError={highlightError}
                            disableClear={true}
                            disabled={formDisabled || isOpd && !item.customizable}
                        />
                        { item.field === 'experience' &&
                            <div className='dnd-info-tooltip'>
                                <Tooltip title='Обращаем ваше внимание, что в Experium в Сводке проекта во вкладке Отклики с сайтов будет выводиться только первое введенное место работы кандидатом. Рекомендуем не стирать отметку (начиная с последнего), чтобы кандидаты указывали в анкете места работы, начиная с последнего'>
                                    <InfoCircleTwoTone />
                                </Tooltip>
                            </div>
                        }
                    </div>
                   { !(isFunctionalLicense && !isAdmin) &&
                        <div className='draggable-item-cell draggable-item-cell-chatbot'>
                            <Field
                                name={`${name}.${index}.question`}
                                component={Input}
                                placeholder={t(`vacancy.placeholders.${item.field}`)}
                                highlightError={highlightError}
                                disableClear={true}
                                disabled={formDisabled}
                            />
                        </div>
                    }
                    <div className='draggable-item-cell'>
                        <Field
                            key={item.question}
                            name={`${name}.${index}.required`}
                            component={Switch}
                            disabled={isOpd || formDisabled}
                            disableClear={true}
                        />
                    </div>
                    <div className='draggable-item-cell'>
                        { isComposite ? (
                            <Field
                                key={item.question}
                                name={`${name}.${index}.settings.showLabel`}
                                component={IconSwitch}
                                undefinedTrue={true}
                                iconTrue={<EyeOutlined />}
                                iconFalse={<EyeInvisibleOutlined />}
                                tooltip={t('vacancy.questions.showLabel')}
                                disabled={isOpd || formDisabled}
                            />
                        ) : (
                            <LockQuestion item={item} />
                        )}
                    </div>
                    { !formDisabled &&
                        <div className='draggable-item-cell move-button'>
                            <Button.Group>
                                <Button
                                    className={cx('edit-question-btn', { 'opd-edit-btn': item.field === 'personalDataAgreement' })}
                                    icon={<EditOutlined />}
                                    onClick={() => modals.open(OPEN_VACANCY_QUESTION_MODAL, {
                                        item,
                                        isSubQuestions: isSubQuestions,
                                        isCompositeQuestion: isCompositeQuestion,
                                        onChange: onChangeQuestion(index, item.type),
                                        getFormQuestions: getVacancyFormQuestions,
                                    })}
                                    disabled={formDisabled}
                                />
                                { idState.isEditingId && (
                                    <Button
                                        className={cx('id-button', { 'id-button-active': idIsEditing })}
                                        onClick={() => toggleFieldEdit(`${name}.${index}`)}
                                        disabled={!item.customizable || item.reserved || formDisabled}
                                    >ID</Button>
                                )}
                                { !hideLanguageButton && (
                                    <Button
                                        icon={<GlobalOutlined />}
                                        onClick={() => modals.open(OPEN_VACANCY_QUESTION_LANGUAGE_MODAL, {
                                            item,
                                            onChange: onChangeQuestion(index, item.type),
                                            form,
                                            questionsType,
                                            formAction: props.formAction,
                                            add: !item.id
                                        })}
                                        disabled={!item.label || !item.question || formDisabled || (isComposite && subQuestionsLength === 0)}
                                        className={cx('row-language-button', {
                                            translated: isTransalated && !formDisabled,
                                        })}
                                    />
                                )}
                                <Button
                                    className='delete-question-btn'
                                    type='danger'
                                    onClick={() => remove(index)}
                                    icon={<DeleteOutlined />}
                                    disabled={isSubQuestions ? formDisabled : formDisabled || length === 1 || item.field === 'personalDataAgreement'}
                                />
                            </Button.Group>
                        </div>
                    }
                </div>
                { idIsEditing ? <div className={cx('id-row', { collapse: !idIsEditing || formDisabled })}>
                    <Field
                        label={t('vacancy.questions.questionId')}
                        name={`${name}.${index}.field`}
                        component={Input}
                        disabled={formDisabled}
                    />
                </div> : null}
                { isComposite && renderCompositeQuestions(name, index, item.label) }
            </div>
        );
    }

    const addPresets = presets => {
        const { form } = props;

        if (is(Array, presets)) {
            form.change(props.questionsFormKey, presets);
        }
    }

    const onChangeFormPreset = (questions, change, form, item) => {
        change(props.fields.name, questions);
        updateSelectedPreset(item);
        change('formPreset', form);
    }

    const onClearFormPreset = () => {
        props.form.change('questions', [OPD_FIELD]);
        props.form.change('formPreset', undefined);
        updateSelectedPreset({});
    }

    const getMenu = (values, form, hideFormPreset) => {
        const { isMaster, isDefaultPreset } = props;
        const questionsAmount = (values.questions || []).length;
        const formPreset = values.formPreset;
        const formPresetTitle = formPreset && path(['_relations', 'formPreset', 'title'], values);

        return <Menu>
            <Menu.Item
                onClick={() => updateIdState({
                    isEditingId: !idState.isEditingId,
                })}
            >
                { idState.isEditingId ? (
                    t('vacancy.questions.offEditId')
                ) : (
                    t('vacancy.questions.editId')
                )}
            </Menu.Item>
            { !hideFormPreset && !isDefaultPreset && (
                <Menu.Item
                    disabled={!questionsAmount}
                    onClick={() => modals.open(OPEN_VACANCY_FORM_PRESET_MODAL, {
                        questions: values.questions,
                        hideQuestions: true
                    })}>{ t('vacancy.questions.saveAsPreset') }</Menu.Item>
            )}
            { !hideFormPreset && !isMaster && !isDefaultPreset && (
                <Menu.Item
                    onClick={() => {
                        modals.open(OPEN_FORM_SELECT_MODAL, {
                            onSelect: (id, item) => onChangeFormPreset(item.questions, form.change, id, item),
                            onClear: () => onClearFormPreset(),
                            value: formPreset,
                            name: 'questions',
                            confirmText: formPreset ? t('vacancy.questions.replaceQuestionnaireSure') : null,
                        })
                    }}
                    disabled={!pathOr([], ['data', 'items'], vacancyFormPresets).length}
                >
                    { t('vacancy.questions.presetQuestionnaires') }
                </Menu.Item>
            )}
            { formPreset && (
                <Menu.Item
                    danger={true}
                    onClick={() => modals.open(OPEN_CONFIRM_MODAL, {
                            text: t('vacancy.questions.unlinkQuestionnaireSure', { title: formPresetTitle ? `"${formPresetTitle}"` : '' }),
                            onConfirm: () => {
                                props.form.change('formPreset', undefined);
                            },
                    })}
                >
                    { t('vacancy.questions.unlinkQuestionnaire') }
                </Menu.Item>
            )}
        </Menu>
    }

    const getVacancyFormQuestions = () => {
        const { form: { getFieldState }, questionsFormKey } = props;

        return questionsFormKey ? (
            prop('value', getFieldState(questionsFormKey))
        ) : propOr([], 'value', getFieldState('questions'));
    }

    const renderContent = () => {
        const {
            fields,
            fields: { insert, push },
            formDisabled,
            hideFormPreset,
            hideLanguageButton,
            questionsType,
            isMaster,
            isSubQuestions,
            isCompositeQuestion,
            isFunctionalLicense,
            isAdmin
        } = props;
        const items = pathOr([], ['data', 'items'], vacancyFormPresets);

        return (
            <Fragment>
                <DragDropContext
                    onDragEnd={onDragEnd}
                    onBeforeCapture={({ draggableId }) => {
                        const antLayoutContent = document.querySelector('.ant-layout-content');
                        const draggableItem = document.querySelector(`[data-rbd-draggable-id="${draggableId}"]`);
                        const compositeBlock = draggableItem.querySelector('.composite');
                        const draggableHeight = 57;

                        const scrollTop = antLayoutContent.scrollTop;

                        const draggableList = document.querySelector('.draggable-list');

                        if (compositeBlock) {
                            if (scrollTop > 0 ) {
                                draggableList.style.marginBottom = compositeBlock.offsetHeight + 'px';
                            }
                            draggableItem.setAttribute('style', `height: ${draggableHeight}px;`);
                        }
                    }}
                >
                    { !formDisabled && (
                        <div className='toolbar toolbar-questions'>
                            <h3>{t('applicant.form')}</h3>
                            { !hideLanguageButton && (
                                <FormSpy subscription={{ values: true }}>
                                    { ({ values }) => (
                                        <VacancyTranslationButton
                                            vacancyData={values}
                                            questionsType={questionsType}
                                        />
                                    )}
                                </FormSpy>
                            )}
                            <FormSpy subscription={{ values: true }}>
                                { ({ values, form }) => (
                                    <Fragment>
                                        <Button.Group>
                                            { isMaster && (
                                                items.length ?
                                                    <Tooltip title={selectedPreset.title}>
                                                        <Button
                                                            onClick={() => {
                                                                modals.open(OPEN_FORM_SELECT_MODAL, {
                                                                    onSelect: (id, item) => onChangeFormPreset(item.questions, form.change, id, item),
                                                                    onClear: () => onClearFormPreset(),
                                                                    value: values.formPreset,
                                                                    name: 'questions',
                                                                    confirmText:  values.formPreset ? t('vacancy.questions.replaceQuestionnaireSure') : null,
                                                                })
                                                            }}
                                                            disabled={!items.length}
                                                        >
                                                            { t('vacancy.questions.presetQuestionnaires') }
                                                        </Button>
                                                    </Tooltip> :
                                                    <Tooltip title={t('vacancy.questions.noPresetQuestionnaires')}>
                                                        <Button disabled>{ t('vacancy.questions.presetQuestionnaires') }</Button>
                                                    </Tooltip>
                                            )}
                                            <Button
                                                onClick={() => isSubQuestions ? (
                                                    modals.open(OPEN_VACANCY_QUESTION_MODAL, {
                                                        onChange: (question) => push(question),
                                                        item: {
                                                            type: 'text',
                                                            field: nanoid(),
                                                            customizable: true,
                                                            required: true
                                                        },
                                                        isSubQuestion: isSubQuestions,
                                                        isCompositeQuestion: isCompositeQuestion,
                                                        getFormQuestions: getVacancyFormQuestions,
                                                    })
                                                ) : (
                                                    modals.open(OPEN_VACANCY_QUESTIONS_MODAL, {
                                                        questions: pathOr([], [...split('.', props.questionsFormKey)], values),
                                                        company: values.company,
                                                        onChange: addPresets,
                                                        isSubQuestion: isSubQuestions,
                                                        isCompositeQuestion: isCompositeQuestion,
                                                        openQuestionModal: () => modals.open(OPEN_VACANCY_QUESTION_MODAL, {
                                                            onChange: (question) => push(question),
                                                            item: {
                                                                type: 'text',
                                                                field: nanoid(),
                                                                customizable: true,
                                                                required: true
                                                            },
                                                            getFormQuestions: getVacancyFormQuestions,
                                                            isCompositeQuestion: isSubQuestions,
                                                            new: true,
                                                        }),
                                                    })
                                                ) }
                                            >
                                                <CheckOutlined /> { isSubQuestions ? t('vacancy.questions.createQuestion') : t('vacancy.questions.chooseQuestion')}
                                            </Button>
                                            {!isSubQuestions && (
                                                <Dropdown.Button
                                                    onClick={() => modals.open(OPEN_VACANCY_QUESTION_MODAL, {
                                                        onChange: (question) => push(question),
                                                        item: {
                                                            type: 'text',
                                                            field: nanoid(),
                                                            customizable: true,
                                                            required: true
                                                        },
                                                        getFormQuestions: getVacancyFormQuestions,
                                                        isCompositeQuestion: isSubQuestions,
                                                        new: true,
                                                    })}
                                                    overlay={getMenu(values, form, hideFormPreset)}
                                                    trigger={['click']}
                                                >
                                                    <EditOutlined /> { t('vacancy.questions.createCustom') }
                                                </Dropdown.Button>
                                            )}
                                        </Button.Group>
                                    </Fragment>
                                )}
                            </FormSpy>
                        </div>
                    )}
                    <div
                        className={cx('draggable-content editable', {
                            'form-is-disabled': formDisabled,
                            'drag-is-disabled': formDisabled || idState.isEditingId,
                            'editing-question-id': idState.isEditingId,
                        })}
                    >
                        <div className='draggable-titles'>
                            <div>{ t('vacancy.questions.name') }</div>
                            { !(isFunctionalLicense && !isAdmin) && <div className="draggable-titles-chatbot">{ t('vacancy.questions.question') }</div> }
                            <div>{ t('vacancy.questions.required') }</div>
                        </div>
                        <DraggableList
                            droppableId='selected'
                            items={fields}
                            renderItem={renderItem}
                            allowDifferentItemHeight={(itemValue) => itemValue.type === 'composite'}
                            isDragDisabled={idState.isEditingId || formDisabled}
                            addItem={() => modals.open(OPEN_VACANCY_QUESTION_MODAL, {
                                onChange: (question) => insert(0, question),
                                item: {
                                    type: 'text',
                                    field: nanoid(),
                                    customizable: true,
                                    required: true
                                },
                                isCompositeQuestion: isSubQuestions,
                                getFormQuestions: getVacancyFormQuestions
                            })}
                        />
                    </div>
                </DragDropContext>
                { renderCompanyDictionaryOrderError(props.formAction, props.form, t) }
                { renderGeoQuestionOrderError(props.formAction, props.form, t) }
            </Fragment>
        );
    }

    const { isMaster, newVacancy, edit, formDisabled } = props;

    return <Fragment>
        { renderContent() }
        <ListenerField listenFieldName='questions'>
            { ({ questions }) => (isMaster || edit || !formDisabled) && !newVacancy && hasBlockQuestions(questions) && (
                <Field
                    label={t('vacancy.questions.recalculateApplicants')}
                    name='recalculateApplicants'
                    component={Switch}
                    wrapperClassName='switch-inline'
                />
            )}
        </ListenerField>
    </Fragment>;
}

Questions.defaultProps = {
    hideLanguageButton: false,
    questionsFormKey: 'questions',
    questionsType: DEFAULT_QUESTIONS,
};

export default withUserCompany(Questions);
