import React, { useEffect } from 'react';
import { Form } from 'antd';
import { contains } from 'ramda';

import RequiredFieldsContext from '../../contexts/RequiredFieldsContext';
import FormServerErrorsContext from '../../contexts/FormServerErrorsContext';
import FormSubmittingContext from '../../contexts/FormSubmittingContext';

export default WrappedComponent => {
    const FieldWrapper = props => {
        const onChange = (value, additional) => {
            const { input, onChange, onBeforeChange } = props;

            if (value !== input.value) {
                onBeforeChange && onBeforeChange(value, additional);
                input.onChange(value);
                onChange && onChange(value, additional);
            }
        }

        useEffect(() => {
            return () => {
                if (!props.disableClear) {
                    onChange();
                }
            }
        }, []);

        const renderField = serverError => {
            const { input: { name }, label, displayError, required, wrapperClassName, extra, getRef, meta, highlightError, wrapperCol, labelCol, hideField } = props;
            const error = serverError && !meta.dirtySinceLastSubmit ? serverError : meta.invalid && (meta.submitFailed || highlightError) ? meta.error : null;
            const hide =  hideField ? hideField(props) : false;

            return !hide && <RequiredFieldsContext.Consumer>
                { fields =>
                    <Form.Item
                        className={wrapperClassName}
                        label={label}
                        required={contains(name, fields) || required}
                        extra={extra}
                        validateStatus={error && displayError ? 'error' : undefined}
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                    >
                        <FormSubmittingContext.Consumer>
                            { isSubmitting =>
                                <WrappedComponent
                                    {...props}
                                    disable={props.disabled || isSubmitting}
                                    onChange={onChange}
                                    getRef={getRef}
                                />
                            }
                        </FormSubmittingContext.Consumer>
                        { error && displayError && (
                            <div className="error-message">
                                {error}
                            </div>
                        )}
                    </Form.Item>
                }
            </RequiredFieldsContext.Consumer>;
        }

        const { input: { name }} = props;

        return (
            <FormServerErrorsContext.Consumer>
                { serverErrors => renderField(serverErrors[name]) }
            </FormServerErrorsContext.Consumer>
        );
    }

    FieldWrapper.defaultProps = {
        displayError: true,
    }

    return FieldWrapper;
};
