import React from 'react';
import { Button } from 'antd';
import { Field } from 'react-final-form';
import cx from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { BarsOutlined, RightOutlined, DeleteOutlined } from '@ant-design/icons';

import InlineEditor from './InlineEditor';
import { isEmpty } from '../../../../utils/ramdaAdditions';
import { required } from '../../../../utils/validation';

const MessageButton = props => {
    const toggleButton = () => {
        const { edit, onToggleButton, button, btnIndex } = props;

        if (!edit) {
            onToggleButton(button, btnIndex);
        }
    }

    const remove = () => {
        const { remove, btnIndex, submitForm, active, edit, button, onToggleButton } = props;

        remove(btnIndex);
        active && onToggleButton(button, btnIndex);
        !edit && setTimeout(() => submitForm());
    }

    const { active, btnIndex, name, edit, onEnter, button } = props;
    const withoutHandler = isEmpty(button.payload);

    return <Draggable
        key={`btn-${btnIndex}`}
        draggableId={`btn-${btnIndex}`}
        index={btnIndex}
        isDragDisabled={!edit}>
        { provided =>
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                className={cx('message-button-container', { edit })}>
                { edit &&
                    <div className='message-button-drag-handle' {...provided.dragHandleProps}>
                        <BarsOutlined />
                    </div>
                }
                <Button
                    className={cx('message-button', { active, withoutHandler })}
                    type='primary'
                    onClick={() => toggleButton()}>
                    <Field
                        name={name}
                        component={InlineEditor}
                        readOnly={!edit}
                        textAlignment='center'
                        disableSplitBlock
                        validate={required}
                        onEnter={onEnter}
                    />
                    { active && <RightOutlined /> }
                </Button>
                <div className='message-button-toolbar'>
                    <Button className="delete-btn" shape='circle' onClick={remove}>
                        <DeleteOutlined />
                    </Button>
                </div>
            </div>
        }
    </Draggable>;
}

export default MessageButton;
