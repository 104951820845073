import React from 'react';
import { Modal, Button } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledModal = styled(Modal)`
    .ant-modal-footer {
        border-top: none;
        padding-top: 0;
    }
`;

const ConfirmModal = props => {
    const { t } = useTranslation();

    const confirm = () => {
        props.params.onConfirm();
        props.close();
    }

    const renderFooter = () => {
        return [
            <Button key='yes' type='primary' onClick={confirm}>{ t('app.yes') }</Button>,
            <Button key='no' type='primary' onClick={props.close}>{ t('app.no') }</Button>
        ];
    }

    const { modal, params } = props;

    return <StyledModal
        {...modal}
        closable={false}
        maskClosable={false}
        width={400}
        footer={renderFooter()}>
        { params.text }
    </StyledModal>;
}

export default ConfirmModal;
