import React from 'react';
import { Upload, message } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import withUserCompany from '../../hocs/withUserCompany';
import { getUrl } from '../../../utils/http';
import { FILE } from '../../../constants/urls';

const AvatarLoader = props => {
    const { t } = useTranslation();

    const onChangeUpload = info => {
        switch (info.file.status) {
            case 'done':
                props.onChange(info.file.response.id);
                break;
            case 'error':
                message.error(t('form.avatarLoadError'));
                break;
            default:
                break;
        }
    }

    const removeFile = () => {
        props.onChange(null);
    }

    const { input: { value } } = props;

    return <Upload
        name='file'
        listType='picture-card'
        className='logo-uploader'
        accept='image/*'
        headers={{
            'company-code': props.companyCode,
        }}
        showUploadList={false}
        action={getUrl(FILE)}
        onChange={onChangeUpload}
        disabled={!!value}>
        { value ?
            <div className='logo-uploader-image' style={{ backgroundImage: `url(${getUrl(FILE, { id: value })})` }}>
                <div className='logo-uploader-hover'>
                    <button type='button' className='delete-btn' onClick={removeFile}>
                        <DeleteOutlined />
                    </button>
                </div>
            </div> :
            <div>
                <PlusOutlined />
                <div className='ant-upload-text'>{ t('form.add') }</div>
            </div>
        }
    </Upload>;
}

export default withFieldWrapper(withUserCompany(AvatarLoader));
