import React, { useContext } from 'react';
import { Dropdown, Menu, message, Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { filter, pathOr, propEq } from 'ramda';
import { QrcodeOutlined, LinkOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import DeepLink from './DeepLink';
import { DEEP_LINK_COPY_ERROR, DEEP_LINK_COPY_SUCCESS } from '../../constants/messages';
import { getBotsPageLink } from '../../utils/botLink';
import ModalsContext from '../../contexts/ModalsContext';
import { OPEN_BOT_PRINT_MODAL } from '../../constants/actionTypes';

const DeepLinksDropdown = props => {
    const { t } = useTranslation();
    const modals = useContext(ModalsContext);

    const onCopy = (text, result) => {
        if (result) {
            message.success(t(DEEP_LINK_COPY_SUCCESS));
        } else {
            message.error(t(DEEP_LINK_COPY_ERROR));
        }
    }

    const openBotPrintModal = () => {
        const { data } = props;

        modals.open(OPEN_BOT_PRINT_MODAL, {vacancy: data});
    }

    const menu = (subscribedBots) => {
        const { id } = props.data;

        return (
            <Menu className='deeplink-menu'>
                { subscribedBots.map((bot, index) => (
                    <Menu.Item key={index}>
                        <DeepLink
                            vacancyId={id}
                            bot={bot}
                            key={bot.id}
                            placement='left'
                        />
                    </Menu.Item>
                ))}
            </Menu>
        );
    }

    const { data, bots } = props;
    const botList = pathOr([], ['items'], bots.data);
    const subscribedBots = botList && filter(propEq('subscribed', true), botList);

    return bots.data && subscribedBots.length ? (
        <div className='dropdown-component'>
            <Button
                onClick={openBotPrintModal}
                icon={<QrcodeOutlined/>}
                className='dropdown-qrcode-button'
            />
            <CopyToClipboard
                text={getBotsPageLink(data.id, data.user)}
                onCopy={onCopy} key='CopyToClipboard'
            >
                <Button
                    icon={<LinkOutlined />}
                    className='dropdown-link-button'
                />
            </CopyToClipboard>
            <Dropdown
                overlay={menu(subscribedBots)}
                placement='bottom'
            >
                <Button
                    icon={<EllipsisOutlined />}
                    className='dropdown-button'
                />
            </Dropdown>
        </div>
    ) : t('bot.noActiveBots');
}

export default DeepLinksDropdown;
