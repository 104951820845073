import React, { useContext } from 'react';
import { EnvironmentTwoTone } from '@ant-design/icons';

import ModalsContext from '../../contexts/ModalsContext';
import { OPEN_MAP_MODAL } from '../../constants/actionTypes';

const Address = ({ address, location }) => {
    const modals = useContext(ModalsContext);

    return (
        <span>
            { address }
            { location && (
                <span className='map-modal-link' onClick={() => modals.open(OPEN_MAP_MODAL, { address, location })}>
                    <EnvironmentTwoTone />
                </span>
            )}
        </span>
    );
};

export default Address;
