import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Spin, Form, Row, Col, Tooltip } from 'antd';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { compose, toPairs, find, propEq, pathOr, path, filter, assocPath, contains, startsWith, includes, any, equals, is, uniq } from 'ramda';
import * as yup from 'yup';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation, withTranslation } from 'react-i18next';
import cx from 'classnames';

import withFormWrapper from '../hocs/withFormWrapper';
import withUserCompany from '../hocs/withUserCompany';
import Select from './formComponents/Select';
import Input from './formComponents/Input';
import InputNumber, { InputNumberString } from './formComponents/InputNumber';
import Checkbox, { CheckboxComponent } from './formComponents/Checkbox';
import RadioGroup from './formComponents/RadioGroup';
import BlockDatePicker from './formComponents/BlockDatePicker';
import { COMPANY_DICTIONARY_PARENT } from '../../constants/form';
import Switch from './formComponents/Switch';
import SubmitButton from './formComponents/SubmitButton';
import ListenerField from './ListenerField';
import { parentField, dateMoreThem, validationLocale } from '../../utils/validation';
import { OPD_TYPE, RESERVED_ALLOW_QUESTIONS_TYPES } from '../../constants/questions';
import BlockSubQuestions from '../views/forms/question/BlockSubQuestions';
import { CITY_DICTIONARY, COMPANY_DICTIONARY, COMPANY_DICTIONARY_ITEM, COUNTRY_DICTIONARY, DICTIONARY, QUESTION, REGION_DICTIONARY } from '../../constants/urls';
import { getCompanyDictionaryParentItemsHandler } from '../../actions/handlers';
import nanoid from '../../utils/nanoid';
import useCompanyCodeSwr from '../../utils/useCompanyCodeSwr';

const fieldComponents = {
    string: Input,
    number: InputNumber,
    money: InputNumberString,
    date: BlockDatePicker,
    boolean: Checkbox,
    select: Select,
    entity: Select,
};

const requiredValidate = (t, value) => !value ? validationLocale(t).mixed.required : undefined;
const noSameValidate = (t, values) => values && values.length ? (
    values.length !== uniq(values).length ? validationLocale(t).array.nosame : undefined
) : undefined;
const fieldComponentsValidate = {
    money: (t, value, minValue, isMax, isMin) => !(value || value === 0)
        ? validationLocale(t).mixed.required
        : (minValue && isMax && isMin && (Number(value) < Number(minValue)) && validationLocale(t).number.moreThan)
            ? validationLocale(t).number.moreThan
            : undefined,
    date: (t, value, minValue, isMax, isMin) => !value
        ? validationLocale(t).mixed.required
        : (minValue && isMax && isMin && dateMoreThem(minValue, value) && validationLocale(t).date.moreThan)
            ? validationLocale(t).number.moreThan
            : undefined,
};

const isMin = values => contains(path(['settings', 'correctValuesType'], values), ['min', 'range']);
const isMax = values => contains(path(['settings', 'correctValuesType'], values), ['max', 'range']);

const notDisabledOptions = {
    international: true,
    minLength: true,
    minLengthErrorMessage: true,
    maxLength: true,
    maxLengthErrorMessage: true,
    checkboxes: true,
    selection: true,
    notFoundMessage: true,
    countryField: true,
    countries: true,
    regionField: true,
    regions: true,
    multiple: true,
    showLabel: true,
};

const presetDisabledOptions = {
    countryField: true,
    regionField: true,
};

const correctValuesOptions = {
    correctValues: true,
    rangeErrorMessage: true,
    minValue: true,
    maxValue: true,
    stopInterviewOnRangeError: true,
};

const correctValuesSubOptions = {
    rangeErrorMessage: true,
    stopInterviewOnRangeError: true,
};

const fieldTypeStringOptions = {
    minValue: true,
    maxValue: true,
};

const dictionaryTypes = {
    currency: 'currency',
    availableCurrencies: 'currency',
};

const dictionaryRegionsTypes = {
    city: CITY_DICTIONARY.stringify(),
    country: COUNTRY_DICTIONARY.stringify(),
    region: REGION_DICTIONARY.stringify(),
};

const dictionaryRegionsOptions = (type) => ({
    'regions': 'region',
    'countries': 'country',
    'selection': type,
});

const geoParentFields = {
    'city': ['country', 'region'],
    'region': ['country'],
};

const namePaths = {
    currency: 'code',
    availableCurrencies: 'code',
};

const labelCol = {
    xs: { span: 24 },
    sm: { span: 8 },
};

const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 16 },
};

const notTextSelectNames = ['availableCurrencies'];
const notTextSelectTypes = ['choice'];

const isCompositeType = type => type === 'composite';
const isDictionaryType = type => type === 'dictionary';
const isCompanyDict = type => type === 'company_dictionary';
const isPDAType = type => type === 'personalDataAgreement';

const isChoiceType = type => type === 'choice';
const isBooleanType = type => type === 'boolean';
const isDateType = type => type === 'date';
const isFileType = type => type === 'file';
const isMoneyType = type => type === 'money';
const isEmailType = type => type === 'email';
const isPhoneType = type => type === 'phone';

const hasCorrectValues = type => (
    !isCompositeType(type) && !isFileType(type) && !isPDAType(type) && !isBooleanType(type)  && !isPhoneType(type)  && !isEmailType(type)
);

const getWrapperClassName = (type) => {
    switch (type) {
        case 'boolean':
            return 'question-checkbox';
        default:
            return '';
    }
};

const functionalLicenseHideFields = {
    text: ['maxLengthErrorMessage', 'minLengthErrorMessage', 'rangeErrorMessage'],
    email: ['maxLengthErrorMessage', 'minLengthErrorMessage', 'rangeErrorMessage'],
    phone: ['maxLengthErrorMessage', 'minLengthErrorMessage', 'rangeErrorMessage'],
    file: ['uploadMoreQuestion'],
    dictionary: ['multipleQuestion', 'rangeErrorMessage'],
    city: ['multipleQuestion', 'notFoundMessage', 'rangeErrorMessage'],
    region: ['multipleQuestion', 'notFoundMessage', 'rangeErrorMessage'],
    country: ['multipleQuestion', 'notFoundMessage', 'rangeErrorMessage'],
    date: ['errorMessage', 'rangeErrorMessage', 'stopInterviewOnRangeError'],
    choice: ['multipleQuestion', 'rangeErrorMessage'],
    composite: ['addBlockQuestion'],
    boolean: ['rangeErrorMessage'],
    company_dictionary: ['multipleQuestion', 'rangeErrorMessage'],
    money: ['currencyQuestion', 'rangeErrorMessage']
};

const VacancyQuestionForm = props => {
    const { t } = useTranslation();
    const [companyDictionaryParentItemsData, setCompanyDictionaryParentItemsData] = useState(null);
    const questions = useCompanyCodeSwr(QUESTION.stringify());
    const didMount = useRef(null);
    const [disableSync, setDisableSync] = useState(false);

    const fetchCompanyDictionaryParentItems = (dictionaryId, companyId) => {
        getCompanyDictionaryParentItemsHandler({
            dictionaryId,
            companyId
        }).then(data => setCompanyDictionaryParentItemsData(data));
    }

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;

            const dictionaryId = path(['settings', 'dictionary'], props.item);
            const type = path(['settings', 'type'], props.item);

            if (isCompanyDict(type) && !!dictionaryId) {
                fetchCompanyDictionaryParentItems(dictionaryId, props.companyId);
            }
        }
    });

    const onChangeType = type => {
        props.form.change('settings', {
            type: isFileType(type) ? undefined : type,
            choices: isChoiceType(type) ? [{ id: nanoid() }] : null
        });
    }

    const onChangeDictionary = (dictionaryId) => {
        const { form, companyId } = props;
        dictionaryId && fetchCompanyDictionaryParentItems(dictionaryId, companyId);

        form.batch(() => {
            form.change('settings.parent', undefined);
            form.change('settings.parentField', undefined);
        });
    }

    const onChangeQuestion = (value, field) => {
        const { form } = props;

        if (isDictionaryType(field)) {
            form.batch(() => {
                form.change('settings.correctValues', null);
            });
        }
    }

    const onChangeCorrectQuestions = (value, type) => {
        props.form.change('settings.hasCorrectValues', !!value);

        if (isDateType(type) || isMoneyType(type)) {
            if (!value) {
                props.form.change('settings.correctValues', null);
                props.form.change('settings.minValue', null);
                props.form.change('settings.maxValue', null);
                props.form.change('settings.stopInterviewOnRangeError', null);
            }

            return;
        }

        if (value) {
            props.form.change('settings.correctValues', [null]);
        } else {
            props.form.change('settings.correctValues', null);
        }
    }

    const onChangeCorrectValuesType = value => {
        switch(value) {
            case 'min':
                return props.form.change('settings.maxValue', null);
            case 'max':
                return props.form.change('settings.minValue', null);
        }
    }

    const syncQuestionChange = (syncField, values) => (value, prevValue) => {
        if (!disableSync && (!values[syncField] || values[syncField] === prevValue)) {
            props.form.change(syncField, value);
        }
    }

    const setDisableSyncTrue = () => {
        setDisableSync(true);
    }

    const getParentFields = () => {
        const { getFormQuestions } = props;
        const formQuestions = getFormQuestions ? getFormQuestions(undefined, t) : [];

        if (companyDictionaryParentItemsData) {
            const parentItems =  pathOr([], ['items'], companyDictionaryParentItemsData).map(({ dictionary }) => dictionary);

            return filter(formQuestion => {
                const dictionaryId = path(['settings', 'dictionary'], formQuestion);

                return contains(dictionaryId, parentItems);
            }, formQuestions);
        } else {
            return [];
        }
    }

    const getGeoFields = (fieldType) => {
        if (!geoParentFields[fieldType]) {
            return null;
        }
        const { getFormQuestions } = props;
        const formQuestions = getFormQuestions ? getFormQuestions(undefined, t) : [];

        if (formQuestions) {
            return filter(formQuestion => {
                return contains(formQuestion.type, geoParentFields[fieldType]);
            }, formQuestions);
        } else {
            return [];
        }
    }

    const renderToolbar = () => {
        const { formAction, isPreset } = props;

        return isPreset ? (
            <div className='modal-form-toolbar'>
                <ListenerField listenFieldName={['settings', 'type']}>
                    { values => {
                        const isEmptyQuestions = pathOr([], ['settings', 'questions'], values).length === 0;
                        const buttonsIsDisabled = values.type === 'composite' && isEmptyQuestions;

                        return (
                            <Fragment>
                                <Tooltip
                                    title={buttonsIsDisabled ? t('vacancy.questions.needAddQuestions') : null}
                                >
                                    <span>
                                        { formAction.dispatch ? (
                                            <SubmitButton
                                                disabled={buttonsIsDisabled}
                                            >
                                                { t('form.save') }
                                            </SubmitButton>
                                        ) : (
                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                                disabled={buttonsIsDisabled}
                                            >
                                                { t('form.save') }
                                            </Button>
                                        )}
                                    </span>
                                </Tooltip>
                            </Fragment>
                        );
                    }}
                </ListenerField>
            </div>
        ) : (
            <div>
                { formAction.dispatch ? (
                    <SubmitButton>{ t('form.save') }</SubmitButton>
                ) : (
                    <Button
                        type='primary'
                        htmlType='submit'>
                        { t('form.ok') }
                    </Button>
                )}
            </div>
        );
    }

    const renderEntityComponent = (name, options, parentType) => {
        const isDictionary = isDictionaryType(name);
        const action = isDictionary ? COMPANY_DICTIONARY.stringify() : undefined;
        const { companyId } = props;
        const parentItems = pathOr([], ['items'], companyDictionaryParentItemsData);

        if (!isDictionary && parentType !== name) {
            return null;
        }

        return  (
            <Fragment key={name + options.type}>
                <Field
                    wrapperClassName={getWrapperClassName(options.type)}
                    name={`settings.${name}`}
                    component={fieldComponents[options.type]}
                    label={options.label}
                    extra={options.description}
                    validate={value => !value && options.required ? validationLocale(t).mixed.required : undefined}
                    required={options.required}
                    options={isDictionary ? undefined : parentItems}
                    valuePath='id'
                    namePath={isDictionary ? 'name' : 'value'}
                    placeholder={options.default}
                    actionUrl={action}
                    onChange={isDictionary && onChangeDictionary}
                    filter={isDictionary ? { 'company': companyId } : {}}
                />
                {isDictionary && (
                    <Field
                        wrapperClassName='parent-radio'
                        name={`settings.parentType`}
                        component={RadioGroup}
                        options={COMPANY_DICTIONARY_PARENT}
                        label={ t('vacancy.questions.filterForDictionary') }
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                    />
                )}
            </Fragment>
        );
    }

    const getDictionaryType = (dictionary, fieldName) => {
        if (dictionary) {
            return dictionary;
        } else {
            return dictionaryTypes[fieldName];
        }
    }

    const renderCollection = ({ fields, type, meta, dictionary, choices, noText, textProp = 'value', minLength = 1, fieldName, disabled }) => {
        return (
            <Fragment>
                { fields.map((name, index) => {
                    const companyDict = isCompanyDict(type);
                    const isDate = isDateType(type);
                    const dictionaryType = getDictionaryType(dictionary, fieldName);
                    const regionsDict = dictionaryRegionsTypes[dictionary];
                    const dictUrl = companyDict ? COMPANY_DICTIONARY_ITEM.stringify() : (dictionaryType ? DICTIONARY.stringify({ type: dictionaryType }) : null);

                    return (
                        <div key={name} className={cx('vacancy-questions-collection', { 'has-array-error': meta.error })}>
                            {dictionaryType || choices ? (
                                <Field
                                    key={dictionaryType}
                                    name={noText ? name : `${name}.${textProp}`}
                                    component={Select}
                                    validate={value => !value ? validationLocale(t).mixed.required : undefined}
                                    required
                                    valuePath={regionsDict || companyDict || choices || namePaths[fieldName] ? 'id' : 'id'}
                                    namePath={choices || companyDict ? 'value' : namePaths[fieldName] || 'name'}
                                    options={choices}
                                    actionUrl={regionsDict || (dictionaryType && dictUrl)}
                                    searchKey={regionsDict ? 'name' : 'value'}
                                    filter={{ [companyDict ? 'dictionary' : 'type']: dictionaryType }}
                                    allowClear
                                    searchable={!!dictionary}
                                    noText={noText}
                                    disabled={disabled}
                                    sorting={!companyDict && !regionsDict ? {
                                        field: 'title',
                                        order: 'asc',
                                    } : null}
                                />
                            ) : (
                                <Field
                                    name={noText ? name : `${name}.${textProp}`}
                                    component={isDate ? BlockDatePicker : Input}
                                    validate={value => !value ? validationLocale(t).mixed.required : undefined}
                                    required
                                />
                            )}
                            {fields.length > minLength && (
                                <Button icon={<MinusOutlined />} type='danger' onClick={() => fields.remove(index)} />
                            )}
                        </div>
                    );
                })}
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => fields.push(noText ? '' : { id: nanoid() })}
                    disabled={disabled}
                >
                    { t('vacancy.questions.add') }
                </Button>
                { meta.error && meta.submitFailed && !is(Array, meta.error)  && (
                    <div className="error-message">
                        {meta.error}
                    </div>
                )}
            </Fragment>
        );
    };

    const renderAdditionFields = (type, parentType, isCustomizeble, dictionarySettings, fields, disabled) => {
        const { isAdmin, isFunctionalLicense } = props;
        return !!fields.length && fields.map(([name, options]) => {
            const isDisabled = (props.isPreset ? presetDisabledOptions[name] : !isCustomizeble && !notDisabledOptions[name]) || disabled;
            const dictionary = dictionaryRegionsOptions(type)[name];

            if (isFunctionalLicense && !isAdmin && any(equals(name), functionalLicenseHideFields[type])) {
                return null;
            }

            switch (options.type) {
                case 'collection':
                    return name === 'currency' ? (
                        <Field
                            key={name}
                            name={`settings.${name}`}
                            component={Select}
                            label={options.label}
                            valuePath='id'
                            namePath='code'
                            actionUrl={params => DICTIONARY.stringify({ type: params.filter.type })}
                            filter={{ type: 'currency' }}
                            disabled={isDisabled}
                            allowClear
                        />
                    ) : (
                        <Form.Item
                            key={name}
                            label={options.label}
                            extra={options.description}
                            required={options.required}
                            disabled={isDisabled}

                        >
                            <FieldArray
                                name={`settings.${name}`}
                                fieldName={name}
                                questionType={type}
                                render={renderCollection}
                                type={type}
                                dictionary={dictionaryRegionsTypes[type] ? dictionary : dictionarySettings}
                                validateOnChange={false}
                                minLength={name === 'selection' && 0}
                                noText={!contains(type, notTextSelectTypes) || contains(name, notTextSelectNames)}
                                disabled={isDisabled}
                                validate={values => noSameValidate(t, values)}
                            />
                        </Form.Item>
                    );
                case 'entity':
                    return renderEntityComponent(name, options, parentType);
                default:
                    switch (name) {
                        case 'regionField':
                        case 'countryField': {
                            const fields = getGeoFields(type);

                            if (!fields) {
                                return null;
                            } else {
                                return (
                                    <div key={name}>
                                        <Field
                                            key={name + options.type}
                                            name={`settings.${name}`}
                                            component={Select}
                                            label={options.label}
                                            extra={options.description}
                                            required={options.required}
                                            options={fields}
                                            valuePath='field'
                                            namePath='label'
                                            disabled={isDisabled || !fields.length}
                                            allowClear
                                        />
                                    </div>
                                );
                            }
                        }
                        case 'parentField':
                            if (parentType !== name) {
                                return null;
                            } else {
                                return (
                                    <div key={name}>
                                        <Field
                                            key={name + options.type}
                                            name={`settings.${name}`}
                                            component={Select}
                                            label={options.label}
                                            extra={options.description}
                                            required={options.required}
                                            options={getParentFields()}
                                            validate={(parentFieldName, { field }) => (
                                                parentFieldName && parentField(parentFieldName, field, props.getFormQuestions(undefined, t), t)
                                            )}
                                            valuePath='field'
                                            namePath='label'
                                            disabled={isDisabled}
                                            allowClear
                                        />
                                    </div>
                                );
                            }
                        case 'multiple':
                            return (
                                <div key={name}>
                                    <Field
                                        wrapperClassName='question-checkbox'
                                        key={name + options.type}
                                        name={`settings.${name}`}
                                        component={Checkbox}
                                        label={options.label}
                                        extra={options.description}
                                        disabled={isDisabled}
                                    />
                                </div>
                            );
                        default:
                            return (
                                <div key={name}>
                                    <Field
                                        wrapperClassName={getWrapperClassName(options.type)}
                                        key={name}
                                        name={`settings.${name}`}
                                        component={fieldComponents[fieldTypeStringOptions[name] ? type : options.type] || fieldComponents.string}
                                        label={options.label}
                                        extra={options.description}
                                        validate={value => !value && options.required ? validationLocale(t).mixed.required : undefined}
                                        required={options.required}
                                        options={options.values}
                                        onChange={value => onChangeQuestion(value, name)}
                                        valuePath='value'
                                        namePath='label'
                                        allowClear={name === 'type'}
                                        placeholder={options.default || ''}
                                        disabled={isDisabled}
                                    />
                                </div>
                            );
                    }
            }
        });
    }

    const getQuestionsTypes = () => {
        const { isCompositeQuestion, item: { field } } = props;
        const isReserved = startsWith('field_', field);

        return filter(({ type }) => type === OPD_TYPE ? false : isCompositeQuestion ? !isCompositeType(type) :
            isReserved ? includes(type, RESERVED_ALLOW_QUESTIONS_TYPES) : true, questions.data || []);
    }

    const { item = {}, isFunctionalLicense, isAdmin } = props;
    const isOpd = propEq('type', OPD_TYPE)(item);

    return <Fragment>
        <Row gutter={10}>
            <Col span={!(isFunctionalLicense && !isAdmin) ? 6 : 9}>
                <Field
                    name='type'
                    component={Select}
                    label={t('vacancy.questions.typeOfQuestion')}
                    options={getQuestionsTypes()}
                    valuePath='type'
                    namePath='name'
                    disabled={!item.customizable && !!item.type}
                    placeholder={isOpd && t('applicant.opd')}
                    onChange={onChangeType} />
            </Col>
            <ListenerField listenFieldName={['question', 'label']}>
                {values => (
                    <Fragment>
                        <Col span={!(isFunctionalLicense && !isAdmin) ? 7 : 11}>
                            <Field
                                name='label'
                                component={Input}
                                label={t('vacancy.questions.name')}
                                onChange={syncQuestionChange('question', values)}
                                disabled={isOpd && !item.customizable} />
                        </Col>
                        { !(isFunctionalLicense && !isAdmin) &&
                            <Col span={7}>
                                <Field
                                    name='question'
                                    component={Input}
                                    onChange={setDisableSyncTrue}
                                    label={t('vacancy.questions.question')} />
                            </Col>
                        }
                    </Fragment>
                )}
            </ListenerField>
            <Col span={4} className='text-center'>
                <Field
                    name='required'
                    component={Switch}
                    disabled={isOpd}
                    label={t('vacancy.questions.required')}
                />
            </Col>
        </Row>
        { questions.isValidating && !questions.data ? <Spin className='center-spin' /> : (
            <ListenerField listenFieldName={['type', 'settings']}>
                {({ type, settings = {} }) => renderAdditionFields(
                    type,
                    settings.parentType,
                    item.customizable,
                    settings.dictionary,
                    filter(
                        item => !correctValuesOptions[item[0]],
                        toPairs(pathOr({}, ['settings'], find(propEq('type', type), questions.data || [])))
                    )
                )}
            </ListenerField>
        )}
        <ListenerField listenFieldName={['type', 'settings']}>
            {values => hasCorrectValues(values.type) && (
                <Fragment>
                    <Form.Item label={t('vacancy.questions.barierQuestion')} className='question-checkbox'>
                        <CheckboxComponent
                            disabled={isDictionaryType(values.type) && !path(['settings', 'dictionary'], values)}
                            input={{ value: !!path(['settings', 'correctValues'], values) || path(['settings', 'hasCorrectValues'], values), name: 'settings.hasCorrectValues' }}
                            onChange={value => onChangeCorrectQuestions(value, values.type)}
                        />
                    </Form.Item>
                    <ListenerField listenFieldName='settings'>
                        {(settings = {}) => {
                            const hasCorrectValues = !!path(['settings', 'hasCorrectValues'], settings);
                            const correctValuesType = path(['settings', 'correctValuesType'], settings);
                            const disabled = !path(['settings', 'correctValues'], settings) && !hasCorrectValues;
                            const isValuesMin = isMin(values);
                            const isValuesMax = isMax(values);

                            return (
                                <Fragment key={disabled}>
                                    { isDateType(values.type) || isMoneyType(values.type) ? (
                                        <Fragment>
                                            <Field
                                                name={`settings.correctValuesType`}
                                                component={Select}
                                                options={[
                                                    { id: 'range', value: t('vacancy.questions.correctValuesTypeRange') },
                                                    { id: 'min', value: t('vacancy.questions.correctValuesTypeMin') },
                                                    { id: 'max', value: t('vacancy.questions.correctValuesTypeMax') },
                                                ]}
                                                validate={value => hasCorrectValues && !value ? validationLocale(t).mixed.required : undefined}
                                                label={t('vacancy.questions.correctValuesType')}
                                                disabled={disabled}
                                                onChange={onChangeCorrectValuesType}
                                            />
                                            <Field
                                                name='settings.minValue'
                                                component={fieldComponents[values.type] || fieldComponents.string}
                                                label={t('vacancy.questions.correctMin')}
                                                validate={(value, form) => isMin(form) && (fieldComponentsValidate[values.type] || requiredValidate)(t, value) || undefined}
                                                required={isValuesMin}
                                                min={0}
                                                disabled={disabled || !isValuesMin || correctValuesType === 'max'}
                                            />
                                            <Field
                                                name='settings.maxValue'
                                                component={fieldComponents[values.type] || fieldComponents.string}
                                                label={t('vacancy.questions.correctMax')}
                                                validate={(value, form) => isMax(form) && (fieldComponentsValidate[values.type] || requiredValidate)(t, value, form.settings.minValue, isMax, isMin) || undefined }
                                                required={isValuesMax}
                                                min={0}
                                                disabled={disabled || !isValuesMax || correctValuesType === 'min'}
                                            />
                                            {renderAdditionFields(
                                                values.type,
                                                settings.parentType,
                                                true,
                                                null,
                                                filter(
                                                    item => correctValuesSubOptions[item[0]],
                                                    toPairs(pathOr({}, ['settings'], find(propEq('type', values.type), questions.data || [])))
                                                ),
                                                disabled
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Form.Item>
                                            <FieldArray
                                                key={path(['settings', 'dictionary'], settings)}
                                                name={`settings.correctValues`}
                                                render={renderCollection}
                                                type={values.type}
                                                validateOnChange={false}
                                                dictionary={(
                                                    path(['settings', 'dictionary'], settings)
                                                    || (dictionaryRegionsTypes[values.type] ? values.type : undefined)
                                                )}
                                                choices={path(['settings', 'choices'], settings)}
                                                textProp='text'
                                                minLength={1}
                                                noText
                                                disabled={disabled}
                                            />
                                        </Form.Item>
                                    )}
                                    { !(isFunctionalLicense && !isAdmin) &&
                                        <Field
                                            name='settings.rangeErrorMessage'
                                            component={Input}
                                            placeholder={t('vacancy.questions.rangeErrorMessage')}
                                            label={t('vacancy.questions.rangeText')}
                                            disabled={disabled}
                                        />
                                    }
                                </Fragment>
                            );
                        }}
                    </ListenerField>
                </Fragment>
            )}
        </ListenerField>
        <ListenerField listenFieldName={['type', 'settings']}>
            {({ type, settings }) => {
                return isCompositeType(type) && (
                    <BlockSubQuestions
                        settings={settings}
                        form={props.form}
                        formAction={props.formAction}
                    />
                );
            }}
        </ListenerField>
        { renderToolbar() }
    </Fragment>;
}

const validationSchema = props => yup.lazy(value => {
    return yup.object().shape({
        type: yup.string().required(),
        label: yup.string().required(),
        question: yup.string().required(),
        settings: yup.object().shape({
            userValueAllowed: yup.bool().nullable(),
            userValueQuestion: yup.string()
                .when('userValueAllowed', {
                    is: true,
                    then: yup.string().required(),
                    otherwise: yup.string().nullable(),
                }),
            currency: yup.mixed().test(
                'oneOfRequired',
                props.t('vacancy.questions.currencyRequired'),
                function (item, { parent }) {
                    if (value.type !== 'money') {
                        return true;
                    } else {
                        const { availableCurrencies = [] } = parent;
                        const filteredAvailableCurrencies = filter(Boolean, availableCurrencies);

                        return item || filteredAvailableCurrencies.length > 0;
                    }
                }
            ),
        }),
    })
});

const mapPropsToValues = ({ item }) => {
    const hasCorrectValues = path(['settings', 'minValue'], item) || path(['settings', 'maxValue'], item);
    const parentField = path(['settings', 'parentField'], item);
    const parent = path(['settings', 'parent'], item);
    const showLabel = path(['settings', 'showLabel'], item);
    const parentTypeValue = (parentField || parent) ? (
        parent ? 'parent' : 'parentField'
    ) : parentField ? 'without' : undefined;

    return compose(
        assocPath(['settings', 'parentType'], parentTypeValue),
        isCompositeType(item.type) && (showLabel === undefined)
            ? assocPath(['settings', 'showLabel'], true)
            : assocPath(['settings', 'hasCorrectValues'], !!hasCorrectValues),
    )(item);
}

export default withTranslation()(withUserCompany(withFormWrapper(VacancyQuestionForm, {
    mapPropsToValues,
    validationSchema
})));
