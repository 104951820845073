import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, Select } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { FieldArray } from 'react-final-form-arrays';
import { filter, find, fromPairs, pathOr, toPairs } from 'ramda';
import styled from 'styled-components';

import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import { AVAILABLE_LANGUAGES } from '../../constants/dictionary';

export const TranlsationItem = styled.div`
    display: flex;
    .ant-row {
        width: 100%;
    }
    .ant-btn {
        min-width: 32px
    }
`;

export const LanguageSelector = props => {
    return <Select
        style={{ width: props.width || 70 }}
        value={props.value}
        disabled={props.disabled}
        onChange={props.onChange}>
        { props.languages.map(lang =>
            <Select.Option value={lang} key={`${props.name}-${lang}`}>
                { lang.toUpperCase() }
            </Select.Option>
        )}
    </Select>;
}

export const TranslationFields = props => {
    const { form, fields } = props;
    const { t } = useTranslation();

    return <div>
        { fields.map((name, index) =>
            <TranlsationItem key={name} >
                <Field
                    name={`${name}.name`}
                    component={Input}
                    addonAfter={
                        <LanguageSelector
                            name={name}
                            value={fields.value[index].lang}
                            onChange={lang => form.change(`${name}.lang`, lang)}
                            languages={filter(lng => !find(v => v.lang === lng, fields.name || []) || lng === fields.value[index].lang, AVAILABLE_LANGUAGES)}
                        />
                    }
                />
                <Button
                    danger
                    style={{ marginLeft: 10 }}
                    icon={<DeleteOutlined />}
                    onClick={() => fields.remove(index)} />
            </TranlsationItem>
        )}
        { (fields.value || []).length < AVAILABLE_LANGUAGES.length &&
            <Button
                id='add-translation-btn'
                type='link'
                icon={<PlusOutlined />}
                style={{ paddingLeft: 0, paddingRight: 0 }}
                onClick={() => {
                    const lang = filter(lng => !find(v => v.lang === lng, fields.value || []), AVAILABLE_LANGUAGES)[0];
                    fields.push({ lang });
                }}>
                { t('settings.addTranslation') }
            </Button>
        }
    </div>;
}

const ClassifierForm = props => {
    const { t } = useTranslation();
    const { form } = props;

    return (
        <Fragment>
            <Field
                name='name'
                component={Input}
                label={t('settings.classifierName')}
                addonAfter={<div style={{ width: 48 }}>RU</div>}
            />
            <FieldArray name='translations'>
                { ({ fields }) => <TranslationFields fields={fields} form={form} /> }
            </FieldArray>
            <SubmitButton>
                { t('form.save') }
            </SubmitButton>
        </Fragment>
    );
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    translations: yup.array().of(
        yup.object().shape({
            name: yup.string().required()
        })
    )
});

export default withFormWrapper(ClassifierForm, {
    validationSchema,
    mapPropsToValues: ({ item }) => ({
        ...(item || {}),
        translations: toPairs(pathOr({}, ['translations'], item)).map(([lang, name]) => ({ name, lang }))
    }),
    mapBeforeSubmit: values => ({
        ...values,
        translations: fromPairs((values.translations || []).map(({ name, lang }) => [lang, name]))
    })
});
