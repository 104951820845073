import React from 'react';
import { Popover } from 'antd';
import { __, compose, head, filter, includes, map, prop, tail, path } from 'ramda';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { LINES, METRO_TYPES, METRO_OPTIONS } from '../../constants/metro';

const Metro = ({ stations, showFull = true, city, cities }) => {
    const type = city ? METRO_TYPES[(city || '').toLowerCase()] :
        path([0], filter(name => METRO_TYPES[(name || '').toLowerCase()], cities || []).map(name => METRO_TYPES[(name || '').toLowerCase()]));

    if (!stations || !stations.length || !type) {
        return null;
    }

    const options = METRO_OPTIONS[type];
    const data = filter(compose(includes(__, stations), prop('id')))(options);

    const firstStation = head(data);
    const otherStations = tail(data);
    const stationsContent = otherStations.length || showFull ? (
        map(station => (
            <div className='metro-line' key={station.id}>
                { map(line => (
                    <span key={line} className={`line-badge line-${line} ${type}`}>{line}</span>
                ), station.lines)}
                { station.title }
            </div>
        ), data)
    ) : null;

    return !showFull ? (
        <div className='metro-with-popover'>
            <div className='metro-line'>
                { map(line => (
                    <span key={line} className={`line-badge line-${line} ${type}`}>{line}</span>
                ), firstStation.lines)}
                { firstStation.title }
            </div>
            { stationsContent && (
                <Popover placement='bottom' title='Станции метрополитена' content={stationsContent} trigger='click'>
                    <span className='metro-other-icon'>
                        <PlusCircleOutlined />
                    </span>
                </Popover>
            )}
        </div>
    ) : stationsContent;
};

export default Metro;

export const MetroLines = ({ lines }) => {
    const { t } = useTranslation();

    return lines && lines.length ? compose(
        map(line => {
            let code = line.code || line.id;
            return (
                <div key={code} className='metro-line'>
                    <span key={code} className={`line-badge line-${code}`}>
                        {code}
                    </span>
                    { t(line.title) }
                </div>
            );
        }),
        filter(compose(includes(__, lines), prop('id')))
    )(LINES) : null;
};
