import React, { useContext } from 'react';
import { Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import withUserCompany from '../../hocs/withUserCompany';
import TableList from '../../table/TableList';
import BotSwitch from '../../table/BotSwitch';
import ButtonWithConfirm from '../../forms/ButtonWithConfirm';
import DeepLink from '../../table/DeepLink';
import FacebookWebhookUrl from '../../table/FacebookWebhookUrl';
import { useTranslation } from 'react-i18next';
import { BOT } from '../../../constants/urls';
import { deleteBotHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import ModalsContext from '../../../contexts/ModalsContext';
import { OPEN_BOT_CREATE_MODAL, OPEN_BOT_MODAL, OPEN_BOT_WIDGET_MODAL } from '../../../constants/actionTypes';

const BotsSettings = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const { companyCode } = props;
    const modals = useContext(ModalsContext);

    const getTableColumns = () => {
        return [
            {
                title: t('bot.type'),
                key: 'type',
                render: bot => <DeepLink bot={bot} enableLink />,
                width: 50,
            },
            {
                title: t('bot.botName'),
                key: 'username',
                render: ({ username, token, type, id, avatar }) => (
                    <span
                        className='span-link'
                        onClick={() => modals.open(OPEN_BOT_MODAL, { username, token, type, id, avatar })}>
                        { username }
                    </span>
                ),
            },
            {
                key: 'url',
                width: 125,
                align: 'center',
                render: bot => {
                    switch(bot.type) {
                        case 'web':
                            return (
                                <Button onClick={() => modals.open(OPEN_BOT_WIDGET_MODAL, bot)}>
                                    { t('bot.widget') }
                                </Button>
                            );
                        case 'facebook':
                            return <FacebookWebhookUrl bot={bot} />;
                        default:
                            return null;
                    }
                }
            },
            {
                title: t('bot.subscribed'),
                key: 'subscribed',
                render: ({ id, subscribed }) => <BotSwitch id={id} checked={subscribed} />,
                width: 80,
                align: 'center'
            },
            {
                key: 'delete',
                render: ({ id, username }) => (
                    <ButtonWithConfirm
                        modalDescription={t('bot.deleteConfirm', { username })}
                        onConfirm={() => deleteBotHandler(id).then(() => {
                            message.success(t('bot.deleteSuccess'));
                            revalidateAction(BOT.stringify());
                        })}
                    />
                ),
                width: 108,
                align: 'right',
            }
        ];
    }

    return (
        <div>
            <div className='toolbar'>
                <Button className='toolbar-button' onClick={() => modals.open(OPEN_BOT_WIDGET_MODAL, { code: companyCode })}>
                    { t('bot.widgetWeb') }
                </Button>
                <Button type='primary' icon={<PlusOutlined />} onClick={() => modals.open(OPEN_BOT_CREATE_MODAL)}>
                    { t('bot.addBot') }
                </Button>
            </div>
            <TableList
                tableLayout={'auto'}
                actionUrl={BOT.stringify()}
                columns={getTableColumns()}
            />
        </div>
    );
}

export default withUserCompany(BotsSettings);
