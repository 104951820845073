import React from 'react';
import { Tabs } from 'antd';
import { contains, path } from 'ramda';
import { useTranslation } from 'react-i18next';

import NoMatch from '../../NoMatch';
import Route from '../../Route';
import Dictionaries from './dictionaries/Dictionaries';
import Classifiers from './classifieers/Classifiers';
import CompanyDictionaries from './companyDictionaries/CompanyDictionaries';
import withUserCompany from '../../hocs/withUserCompany';

const Settings = props => {
    const { t } = useTranslation();

    const onChange = key => {
        props.history.push(`/settings/dictionaries/${key}`);
    }

    const { isCompanyAdmin, location } = props;
    const activeKey = path([0], location.pathname.replace(/\/settings\/dictionaries\/?/, '').split('/'));

    return contains(activeKey, ['', 'list', 'classifier']) ?
        <Tabs
            className='settings-tabs'
            animated={{ tabPane: false }}
            activeKey={activeKey}
            onChange={onChange}
        >
            <Tabs.TabPane tab={t('settings.companyDictionaries')} key=''>
                <Route path='/settings/dictionaries' component={CompanyDictionaries} />
            </Tabs.TabPane>
            { isCompanyAdmin &&
                <Tabs.TabPane tab={t('settings.systemDictionaries')} key='list'>
                    <Route path='/settings/dictionaries/list/:type?' component={Dictionaries} />
                </Tabs.TabPane>
            }
            <Tabs.TabPane tab={t('settings.classifiers')} key='classifier'>
                <Route path='/settings/dictionaries/classifier' component={Classifiers} />
            </Tabs.TabPane>
        </Tabs> :
        <Route component={NoMatch} />;
}

export default withUserCompany(Settings);
