import React, { useContext } from 'react';
import { Button, Tooltip } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { HistoryOutlined, PlusOutlined, UserOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { find, propEq, path } from 'ramda';
import { useTranslation } from 'react-i18next';

import { getUrlWithFilters } from '../../../utils/urlParams';
import TableList from '../../table/TableList';
import EnabledSwitch from '../../table/EnabledSwitch';
import CompanyFilter from '../../filters/CompanyFilter';
import { LICENSES } from '../../../constants/company';
import { putCompanyHandler } from '../../../actions/handlers';
import { COMPANY } from '../../../constants/urls';
import ModalsContext from '../../../contexts/ModalsContext';
import { OPEN_COMPANY_LICENSE_LOG_MODAL, OPEN_COMPANY_LICENSE_MODAL, OPEN_COMPANY_MODAL } from '../../../constants/actionTypes';

const initFilters = {
    enabled: true
};

const Companies = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const modals = useContext(ModalsContext);

    const getColumns = () => {
        return [
            {
                title: t('company.name'),
                key: 'name',
                dataIndex: 'name',
                render: (name, item) =>
                    <span className='span-link company-edit-btn' onClick={() => modals.open(OPEN_COMPANY_MODAL, item)}>
                        { name }
                    </span>
            },
            {
                title: t('company.license'),
                key: 'license',
                dataIndex: 'license',
                render: (license, item) => <div>
                    <span className='span-link company-edit-btn' onClick={() => modals.open(OPEN_COMPANY_LICENSE_MODAL, { id: item.id, license: item.license })}>
                        { t(path(['label'], find(propEq('value', license), LICENSES))) }
                    </span>
                    <Tooltip title={t('company.activationHistory')}>
                        <Button style={{ marginLeft: 5, padding: '0 5px' }} type='link' onClick={() => modals.open(OPEN_COMPANY_LICENSE_LOG_MODAL, item.id)}>
                            <HistoryOutlined />
                        </Button>
                    </Tooltip>
                </div>
            },
            {
                title: t('company.enabled'),
                key: 'enabled',
                width: 100,
                render: item => <EnabledSwitch path='enabled' item={item} action={putCompanyHandler} revalidateAction={COMPANY.stringify()} />
            },
            {
                key: 'users',
                dataIndex: 'id',
                title: t('company.users'),
                width: 140,
                className: 'company-users',
                render: (id) =>
                    <Link to={getUrlWithFilters('/users', { company: id })}>
                        <UserOutlined className='company-users-link' />
                    </Link>
            },
            {
                key: 'users',
                dataIndex: 'id',
                title: t('company.juristicEntities'),
                width: 140,
                className: 'company-users',
                render: (id) =>
                    <Link to={getUrlWithFilters(`/companies/entities/`, { company: id })}>
                        <UnorderedListOutlined className='company-users-link' />
                    </Link>
            }
        ];
    }

    const renderButtons = () => {
        return (
            <Button
                className='toolbar-button'
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => history.push('/companies/create')}>
                { t('company.add') }
            </Button>
        )
    }

    return <TableList
            actionUrl={COMPANY.stringify()}
            columns={getColumns()}
            buttons={renderButtons()}
            filterForm={CompanyFilter}
            initFilters={initFilters}
            param='table'
        />;
}

export default Companies;
