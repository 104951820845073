import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import ClassifiersForm from '../../../forms/ClassifiersForm';
import { extendSearchPath } from '../../../../utils/urlParams';
import { postClassifierHandler, putClassifierHandler } from '../../../../actions/handlers';
import ActionsContext from '../../../../contexts/ActionsContext';
import { CLASSIFIERS } from '../../../../constants/urls';

const ClassifiersModal = props => {
    const { t } = useTranslation();
    const { modal, params, close } = props;
    const item = prop('item', params);
    const { revalidateAction } = useContext(ActionsContext);
    const history = useHistory();
    const location = useLocation();

    return (
        <Modal
            {...modal}
            title={item ? t('settings.editClassifier1') : t('settings.addClassifier1')}
            footer={null}
        >
            <ClassifiersForm
                formAction={item ? putClassifierHandler : postClassifierHandler}
                item={item}
                onSuccess={(_, data) => {
                    revalidateAction(CLASSIFIERS.stringify());
                    message.success(t(item ? 'settings.classifierSaveSuccess1' : 'settings.classifierAddSuccess1'));
                    close();
                    if (!item) {
                        history.replace(extendSearchPath(location, { expanded: [data.id] }));
                    }
                }}
                onSubmitFail={() => message.error(t(item ? 'settings.classifierSaveError1' : 'settings.classifierAddError1'))}
            />
        </Modal>
    );
}

export default ClassifiersModal;
