import React, { Fragment } from 'react';
import { prop } from 'ramda';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import BotStepsForm, { withBotSteps, onSuccess, onSubmitFailed } from './BotStepsForm';
import Input from './formComponents/Input';
import { validationSchema } from './BotForm';
import withFormWrapper from '../hocs/withFormWrapper';

import viber1 from '../../images/viber1.png';
import viber2 from '../../images/viber2.png';
import viber3 from '../../images/viber3.png';

const ViberBotForm = props => {
    const { t } = useTranslation();

    const step1 = () => {
        return <Fragment>
            <p>{ t('bot.viber.step1_1') }&nbsp;&mdash; <a href='https://partners.viber.com/'>https://partners.viber.com/</a></p>
            <div>{ t('bot.viber.step1_2') }&nbsp;<a href='https://partners.viber.com/'>https://partners.viber.com/</a> { t('bot.viber.step1_3') }
                <div className='wrap-img'>
                    <img alt='viber screen auth' src={viber1} />
                </div>
            </div>
        </Fragment>;
    }

    const step2 = () => {
        return <div>
            { t('bot.viber.step2_1') }
            <div className='wrap-img'>
                <img alt='viber screen menu' src={viber2} width='283' />
            </div>
        </div>;
    }

    const step3 = () => {
        return <Fragment>
            <div>
                { t('bot.viber.step3_1') }
                <div className='wrap-img'>
                    <img alt='viber screen create bot' src={viber3} />
                </div>
            </div>
            <Field
                name='username'
                component={Input}
                label={t('bot.botName')}
                required
                disableClear
            />
        </Fragment>;
    }

    const step4 = () => {
        return <Fragment>
            <p>{ t('bot.viber.step4_1') }</p>
            <Field
                name='token'
                component={Input}
                label={t('bot.token')}
                required
            />
            <p>{ t('bot.viber.step4_2') }<a href='https://partners.viber.com/'>https://partners.viber.com/</a>{ t('bot.viber.step4_3') }</p>
        </Fragment>;
    }

    const renderForm = () => {
        const { formAction, close, step, setStep } = props;
        const steps = [
            { render: step1 },
            { render: step2 },
            { render: step3, field: 'username' },
            { render: step4, submit: true, field: 'token' }
        ];

        return <BotStepsForm
            steps={steps}
            formAction={formAction}
            close={close}
            step={step}
            setStep={setStep} />;
    }

    return renderForm();
}

export default withBotSteps(withFormWrapper(ViberBotForm, {
    mapPropsToValues: prop('bot'),
    validationSchema,
    onSuccess,
    onSubmitFailed
}));
