import React, { Fragment, useState } from 'react';
import { Field } from 'react-final-form';
import { Row, Col, Modal, Button, message, Input as AntInput } from 'antd';
import { pathOr, find, propEq, forEach, path, filter } from 'ramda';
import { QuestionCircleTwoTone} from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import qs from 'qs';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import Select from './formComponents/Select';
import { UTM_TRAFFIC_SOURCES, UTM_FIELDS, UTM_FIELDS_INFO } from '../../constants/utm';
import ListenerField from './ListenerField';
import SocialShare from '../views/vacancy/SocialShare';

const ResultField = styled.div`
    .ant-input-group-addon {
        border: none;
        padding: 0;
        .ant-btn {
            margin-top: 0 !important;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
    }
    input.ant-input {
        color: rgba(0,0,0,0.85);
        cursor: text;
        border-left: 0;
    }
`;

const UtmParamsForm = props => {
    const { t } = useTranslation();
    const [infoModal, setInfoModal] = useState(null);

    const onCopy = (_, result) => {
        result ?
            message.success(t('landing.linkCopySuccess')) :
            message.error(t('landing.linkCopyError'));
    }

    const onChangeTrafficSource = value => {
        const { form } = props;
        const source = find(propEq('id', value), UTM_TRAFFIC_SOURCES);

        form.batch(() => {
            forEach(field => {
                form.change(`utm.${field}`, pathOr('', ['defaultValues', field], source));
            }, UTM_FIELDS);
        });
    }

    const closeInfoModal = () => setInfoModal(null);

    const renderLabel = (type, utmTrafficSource) => {
        const source = find(propEq('id', utmTrafficSource), UTM_TRAFFIC_SOURCES);
        const smallText = path(['smallText', type], source);

        return <div>
            <div>
                { t(UTM_FIELDS_INFO[type].label) }
                <QuestionCircleTwoTone
                    style={{ cursor: 'pointer', marginLeft: 10 }}
                    onClick={() => setInfoModal(type)} />
            </div>
            { smallText && <small style={{ color: '#868e96' }}>{ t(smallText) }</small> }
            <Modal
                visible={type === infoModal}
                onCancel={closeInfoModal}
                footer={null}
                destroyOnClose>
                { UTM_FIELDS_INFO[type].info(t) }
            </Modal>
        </div>
    }

    const getUrl = (url, utm) => {
        const params = qs.stringify(filter(v => !!v, utm || {}), { addQueryPrefix: true });

        return `${url || props.url}${params}`;
    }

    const { vacancyTitle } = props;

    return <Fragment>
        <Field
            name='utmUrl'
            component={Input}
            label={t('landing.address')} />
        <h2>{ t('landing.trafficSource') }</h2>
        <Field
            name='utmTrafficSource'
            component={Select}
            options={UTM_TRAFFIC_SOURCES}
            onChange={onChangeTrafficSource} />
        <ListenerField listenFieldName='utmTrafficSource'>
            { ({ utmTrafficSource }) =>
                <Row gutter={16} style={{ marginTop: 30 }}>
                    <Col sm={12} xs={24}>
                        <h2>{ t('landing.requiredParams') }</h2>
                            <Field
                                disabled={utmTrafficSource !== 'own'}
                                name='utm.utm_source'
                                component={Input}
                                label={renderLabel('utm_source', utmTrafficSource)}
                                placeholder='google, yandex, vk, facebook'
                                disableClear
                            />
                        <Field
                            name='utm.utm_medium'
                            component={Input}
                            label={renderLabel('utm_medium', utmTrafficSource)}
                            placeholder='cpc, email, banner, article' />
                        <Field
                            name='utm.utm_campaign'
                            component={Input}
                            label={renderLabel('utm_campaign', utmTrafficSource)}
                            placeholder='promo, discount, sale' />
                    </Col>
                    <Col sm={12} xs={24}>
                        <h2>{ t('landing.noRequiredParams') }</h2>
                        <Field
                            name='utm.utm_content'
                            component={Input}
                            label={renderLabel('utm_content', utmTrafficSource)}
                            placeholder='link, landing page' />
                        <Field
                            name='utm.utm_term'
                            component={Input}
                            label={renderLabel('utm_term', utmTrafficSource)}
                            placeholder='free, -30%, registration' />
                        <Field
                            name='utm.utm_tool'
                            component={Input}
                            label={t('landing.utmToolLabel')} />
                    </Col>
                </Row>
            }
        </ListenerField>
        <Field name='utm.utm_source' component={() => null} />
        <h2 style={{ marginTop: 20 }}>{ t('landing.result') }</h2>
        <ListenerField listenFieldName={['utm', 'utmUrl']}>
            { ({ utm, utmUrl }) =>
                <ResultField>
                    <AntInput
                        value={getUrl(utmUrl, utm)}
                        addonBefore={
                            <CopyToClipboard onCopy={onCopy} text={getUrl(utmUrl, utm)}>
                                <Button type='primary' style={{ marginTop: 15 }}>
                                    { t('landing.copy') }
                                </Button>
                            </CopyToClipboard>
                        }
                        addonAfter={
                            <SocialShare
                                url={getUrl(utmUrl, utm)}
                                vacancyTitle={vacancyTitle}
                                utmReady
                                style={{ padding: '4px 10px', border: '1px solid #d9d9d9', borderLeft: 0, borderRadius: '0 4px 4px 0' }} />
                        }
                        disabled />
                </ResultField>
            }
        </ListenerField>
    </Fragment>;
}

export default withFormWrapper(UtmParamsForm, {
    mapPropsToValues: ({ url }) => ({
        utmTrafficSource: 'own',
        utmUrl: url
    })
});
