import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import { prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import BotStepsForm, { onSubmitFailed, onSuccess, withBotSteps } from './BotStepsForm';
import Input from './formComponents/Input';
import withFormWrapper from '../hocs/withFormWrapper';
import { validationSchema } from './BotForm';

import fb1 from '../../images/fb1.png';
import fb2 from '../../images/fb2.png';
import fb3 from '../../images/fb3.png';
import fb4 from '../../images/fb4.png';
import fb5 from '../../images/fb5.png';
import fb6 from '../../images/fb6.png';
import fb7 from '../../images/fb7.png';
import fb10 from '../../images/fb10.png';
import fb11 from '../../images/fb11.png';
import fb13 from '../../images/fb13.png';
import fb14 from '../../images/fb14.png';
import fb15 from '../../images/fb15.png';
import FacebookWebhookUrl from '../table/FacebookWebhookUrl';

const FacebookBotForm = props => {
    const { t } = useTranslation();

    const step1 = () => {
        return <Fragment>
            <p>{ t('bot.facebook.step1_1') } <a href='https://developers.facebook.com/'>{ t('bot.facebook.step1_2') }</a>.</p>
            <div>{ t('bot.facebook.step1_3') } <a href='https://www.facebook.com/pages/creation/'>facebook</a>
                <div className='wrap-img'>
                    <img alt='facebook screen company' src={fb1} />
                </div>
            </div>
        </Fragment>;
    }

    const step2 = () => {
        return <div>{ t('bot.facebook.step2_1') }
            <p>{ t('bot.facebook.step2_2') }</p>
            <div className='wrap-img'>
                <img alt='facebook screen token' src={fb11} />
            </div>
            <p>{ t('bot.facebook.step2_3') }</p>
            <div className='wrap-img'>
                <img alt='facebook screen token' src={fb13} />
            </div>
            <Field
                name='username'
                component={Input}
                label={t('bot.userName')}
                disableClear
            />
        </div>;
    }

    const step3 = () => {
        return <div>
            { t('bot.facebook.step3_1') } <a href='https://developers.facebook.com/'>facebook for developers</a>. { t('bot.facebook.step3_2') }
            <div className='wrap-img'>
                <img alt='facebook screen add app' src={fb2} />
            </div>
        </div>;
    }

    const step4 = () => {
        return <Fragment>
            <div>{ t('bot.facebook.step4_1') }
                <div className='wrap-img'>
                    <img alt='facebook screen add id app' src={fb3} />
                </div>
            </div>
        </Fragment>
    }

    const step5 = () => {
        return <div>{ t('bot.facebook.step5_1') }
            <div className='wrap-img'>
                <img alt='facebook screen add id app' src={fb14} />
            </div>
        </div>;
    }

    const step6 = () => {
        return <div>{ t('bot.facebook.step6_1') }
            <div className='wrap-img'>
                <img alt='facebook screen messenger' src={fb4} />
            </div>
        </div>;
    }

    const step7 = () => {
        return <Fragment>
            <div>{ t('bot.facebook.step7_1') }
                <div className='wrap-img'>
                    <img alt='facebook screen token' src={fb7} />
                </div>
            </div>
            <Field
                name='token'
                component={Input}
                label='Токен'
                required
                disableClear
            />
        </Fragment>;
    }

    const step8 = () => {
        return <div>{ t('bot.facebook.step8_1') }
            <div className='wrap-img'>
                <img alt='facebook screen webhooks' src={fb5} />
            </div>
        </div>;
    }

    const step9 = () => {
        return <div>
            <p>{ t('bot.facebook.step9_1') }</p>
            <p>{ t('bot.facebook.step9_2') } - <FacebookWebhookUrl bot={props.actionData} /></p>
            <p>{ t('bot.facebook.step9_3') } - Findy</p>
            <p>{ t('bot.facebook.step9_4') } - messages, messaging_postbacks, messaging_optins, message_deliveries, messaging_referrals</p>
            <div className='wrap-img'>
                <img alt='facebook screen webhooks fields' src={fb6} />
            </div>
        </div>
    }

    const step10 = () => {
        return <Fragment>
            <p>{ t('bot.facebook.step10_1') }</p>
            <div className='wrap-img'>
                <img alt='facebook screen webhooks fields' src={fb15} />
            </div>
            { t('bot.facebook.step10_2') }
            <div className='wrap-img'>
                <img alt='facebook screen publish' src={fb10} />
            </div>
        </Fragment>
    }

    const renderForm = () => {
        const { formAction, close } = props;
        const steps = [
            { render: step1 },
            { render: step2, field: 'username' },
            { render: step3 },
            { render: step4 },
            { render: step5 },
            { render: step6 },
            { render: step7, submit: true, field: 'token' },
            { render: step8 },
            { render: step9 },
            { render: step10 }
        ];

        return <BotStepsForm
            className='facebook-create-bot'
            steps={steps}
            formAction={formAction}
            close={close}
            step={props.step}
            setStep={props.setStep} />;
    }

    return renderForm();
}

export default withBotSteps(withFormWrapper(FacebookBotForm, {
    mapPropsToValues: prop('bot'),
    validationSchema,
    onSuccess,
    onSubmitFailed
}));
