import React, { Fragment, useState } from 'react';
import { Modal, Radio, Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { path, pathOr, toUpper } from 'ramda';

import WidgetLink from '../../table/WidgetLink';
import { getWidgetFormLink } from '../../../utils/vacancy';
import { VACANCY_FORM_PRESET, VACANCY, PDA_SETTINGS } from '../../../constants/urls';
import { AVAILABLE_TRANSLATIONS } from '../../../constants/dictionary';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const DomainWidgetModal = props => {
    const { t } = useTranslation();
    const [type, setType] = useState('vacancies');
    const [selectFormType, setSelectFormType] = useState('default');
    const [form, setForm] = useState(undefined);
    const [language, setLanguage] = useState(null);
    const [pdaLanguage, setPdaLanguage] = useState(null);
    const [vacancy, setVacancy] = useState(null);

    const pdaSettings = useCompanyCodeSwr(PDA_SETTINGS.stringify());

    const vacancyFormPreset = useCompanyCodeSwr(VACANCY_FORM_PRESET.stringify(), url => axios.get(url, {
        params: {
            pagination: {
                limit: 0
            },
            relations: ['domains']
        }
    }));
    const { modal, params } = props;

    const vacancies = useCompanyCodeSwr([VACANCY.stringify(), 'widgetVacancies'], url => axios.get(url, {
        params: {
            filter: {
                active: true,
                domain: params.id,
            },
            pagination: {
                limit: 0
            },
        }
    }));

    const getWidgetLink = () => {
        const url = `${window.location.origin}/vacanciesWidget.js?domain=${params.name}&lang=${language}${pdaLanguage ? `&pdaLang=${pdaLanguage}` : ''}`;

        return `<script src="${url}"></script>`;
    }

    const onChangeType = e => {
        setType(e.target.value)
        setSelectFormType(null)
        setForm(null);
        setVacancy(null);
        setLanguage(null);
        setPdaLanguage(null);
    };

    const onChangeForm = form => setForm(form || undefined);
    const onChangeVacancy = vacancy => setVacancy(vacancy);

    const onChangeSelectFormType = e => {
        setSelectFormType(e.target.value)
        setForm(null);
    };

    const renderLanguageSelector = () => {
        return <Form.Item label={t('domain.language')}>
            <Select
                value={language}
                onChange={setLanguage}
            >
                { AVAILABLE_TRANSLATIONS.map(lang =>
                    <Select.Option value={lang} key={`widget-language-${lang}`}>
                        { t(`domain.${lang}`) }
                    </Select.Option>
                )}
            </Select>
        </Form.Item>;
    }

    const renderPdaLanguageSelector = () => {
        return <Form.Item label={t('settings.pdaShort')}>
            <Select
                value={pdaLanguage}
                onChange={setPdaLanguage}
                loading={pdaSettings.isValidating}
            >
                { (path(['data', 'data', 'locales'], pdaSettings) || []).map(lang =>
                    <Select.Option value={lang} key={`widget-pda-language-${lang}`}>
                        { toUpper(lang) }
                    </Select.Option>
                )}
            </Select>
        </Form.Item>;
    }

    return <Modal
        {...modal}
        title={t('domain.widget')}
        footer={null}>
        <div style={{ marginBottom: 10 }}>
            <Radio.Group onChange={onChangeType} value={type}>
                <Radio.Button value='vacancies'>{ t('domain.vacancies') }</Radio.Button>
                <Radio.Button value='formWithVacancy'>{ t('domain.applicantWithVacancy') }</Radio.Button>
                <Radio.Button value='formWithoutVacancy'>{ t('domain.applicantWithoutVacancy') }</Radio.Button>
            </Radio.Group>
        </div>
        { type === 'vacancies' ?
            <Fragment>
                { renderLanguageSelector() }
                { language && renderPdaLanguageSelector() }
                { language && <WidgetLink link={getWidgetLink()} /> }
            </Fragment> :
            <Fragment>
                <Radio.Group style={{ marginBottom: 10 }} onChange={onChangeSelectFormType} value={selectFormType}>
                    <Radio.Button value='default'>{ t('domain.defaultForm') }</Radio.Button>
                    <Radio.Button value='form'>{ t('domain.selectForm') }</Radio.Button>
                </Radio.Group>
                { selectFormType === 'form' && (
                    <Form.Item label={t('domain.form')}>
                        <Select
                            value={form}
                            onChange={onChangeForm}>
                            { pathOr([], ['data', 'items'], vacancyFormPreset).map(item =>
                                <Select.Option value={item.id} key={item.id}>
                                    { item.title }
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                )}
                { selectFormType && renderLanguageSelector() }
                { selectFormType && language && renderPdaLanguageSelector() }
                { selectFormType && (type === 'formWithVacancy') && (
                    <Form.Item label={t('vacancy.list.vacancy')}>
                        <Select
                            value={vacancy}
                            onChange={onChangeVacancy}>
                            { pathOr([], ['data', 'items'], vacancies).map(item =>
                                <Select.Option value={item.id} key={item.id}>
                                    { item.title }
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                )}
                { !!(selectFormType === 'form' ? form : true) && language &&
                    <WidgetLink link={getWidgetFormLink(
                        params.company,
                        params.id,
                        type === 'formWithVacancy' ? vacancy || 'VACANCY_ID' : undefined,
                        selectFormType === 'default' ? undefined : form,
                        language,
                        pdaLanguage
                    )} />
                }
            </Fragment>
        }
    </Modal>;
}

export default DomainWidgetModal;
