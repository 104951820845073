import React, { useState} from 'react';
import { Modal, Input, Select, Form, Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { AVAILABLE_TRANSLATIONS } from '../../../constants/dictionary';
import { transformCompanyCodeValue } from '../../../utils/companyCode';

const PdaLanguageModal = props => {
    const { t } = useTranslation();
    const [language, setLanguage] = useState(null);
    const [code, setCode] = useState(null);
    const { modal, params, close } = props;

    const addTab = () => {
        params.addTab(`${language}-${code}`);
        close();
    };

    return <Modal
        {...modal}
        title={t('userAdmin.editUser')}
        footer={null}>
        <div style={{ marginBottom: 10 }}>
            <Row gutter={10}>
                <Col xs={12}>
                    <Form.Item label={t('domain.language')}>
                        <Select
                            value={language}
                            onChange={setLanguage}>
                            { AVAILABLE_TRANSLATIONS.map(lang =>
                                <Select.Option value={lang} key={`widget-language-${lang}`}>
                                    { t(`domain.${lang}`) } ({ lang })
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={12}>
                    <Form.Item label={t('settings.code')}>
                        <Input
                            value={code || ''}
                            onChange={e => setCode(transformCompanyCodeValue(e.target.value))}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Button disabled={!code || !language} onClick={addTab}>{t('settings.add')}</Button>
        </div>
    </Modal>;
}

export default PdaLanguageModal;
