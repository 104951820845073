import {
    SolutionOutlined,
    HomeOutlined,
    UsergroupAddOutlined,
    TeamOutlined,
    ToolOutlined,
    PhoneOutlined,
    MessageOutlined,
    ShopOutlined,
    FileTextOutlined,
    QrcodeOutlined,
    SettingOutlined,
    ContainerOutlined,
    FileDoneOutlined,
    LoginOutlined,
    BookOutlined,
    SnippetsOutlined
} from '@ant-design/icons';

import { IS_ADMIN, NOT_ADMIN, ONLY_MANAGER, ONLY_ADMIN } from './roles';

export default [
    { key: '/', route: '/vacancy', icon: SolutionOutlined, title: 'app.sidebar.vacancies', roles: NOT_ADMIN },
    { key: '/applicants', icon: FileTextOutlined, title: 'app.sidebar.applicants', roles: NOT_ADMIN },
    { key: '/landings', route: '/landings', icon: ContainerOutlined, title: 'app.sidebar.landings', roles: NOT_ADMIN, fullLicense: true },
    { key: '/forms', icon: FileDoneOutlined, title: 'app.sidebar.forms', roles: NOT_ADMIN },
    { key: '/domains', icon: FileDoneOutlined, title: 'app.sidebar.domains', roles: NOT_ADMIN },
    { key: '/companies', route: '/companies', icon: HomeOutlined, title: 'app.sidebar.companies', roles: ONLY_ADMIN, withoutCompany: true },
    { key: '/admins', route: '/admins', icon: UsergroupAddOutlined, title: 'app.sidebar.admins', roles: ONLY_ADMIN, withoutCompany: true },
    { key: '/users', route: '/users', icon: TeamOutlined, title: 'app.sidebar.users', roles: ONLY_ADMIN, withoutCompany: true },
    { key: '/bots', route: '/bots', icon: ToolOutlined, title: 'app.sidebar.bots', roles: ONLY_ADMIN, withoutCompany: true, fullLicense: true },
    { key: '/dictionaries', icon: BookOutlined, title: 'app.sidebar.dictionaries', roles: ONLY_ADMIN, withoutCompany: true },
    { key: '/questions', icon: FileDoneOutlined, title: 'app.sidebar.questions', roles: ONLY_ADMIN, withoutCompany: true },
    { key: '/sms', icon: MessageOutlined, title: 'app.sidebar.sms', roles: ONLY_ADMIN, withoutCompany: true },
    { key: '/auth', icon: LoginOutlined, title: 'app.sidebar.auth', roles: ONLY_ADMIN, withoutCompany: true },
    { key: '/units', route: '/units', icon: ShopOutlined, title: 'app.sidebar.units', roles: NOT_ADMIN, hideFunctionalLicense: true },
    { key: '/banners', icon: QrcodeOutlined, title: 'app.sidebar.banners', roles: NOT_ADMIN, fullLicense: true, hideFunctionalLicense: true },
    {
        key: 'settingsGroup',
        title: 'app.sidebar.settings',
        icon: SettingOutlined,
        roles: ONLY_MANAGER,
        subItems: [
            { key: '/settings/bots', title: 'app.sidebar.bots', roles: ONLY_MANAGER, fullLicense: true, hideFunctionalLicense: true  },
            { key: '/settings/dictionaries', title: 'app.sidebar.dictionaries', roles: ONLY_MANAGER },
            { key: '/settings', title: 'app.sidebar.company', roles: ONLY_MANAGER },
        ],
    },
    { key: '/reports', icon: SnippetsOutlined, title: 'app.sidebar.reports', roles: IS_ADMIN },
    { key: '/dialogs', icon: MessageOutlined, title: 'app.sidebar.dialogs', roles: NOT_ADMIN, fullLicense: true, hideFunctionalLicense: true },
    { key: '/callrequests', icon: PhoneOutlined, title: 'app.sidebar.callrequests', roles: NOT_ADMIN, fullLicense: true, hideFunctionalLicense: true },
];

export const DEFAULT_OPEN_KEYS = ['settingsGroup'];
