import React from 'react';
import { pathOr, find, propEq, match, prop, replace } from 'ramda';
import { PARENT_FIELD_VALIDATOR, GEO_PARENT_FIELD_VALIDATOR } from '../constants/errors';

export const renderCompanyDictionaryOrderError = (formAction, form, t) => {
    const questionsErrors = pathOr([], ['meta', 'error', 'data', 'errors', 'questions'], formAction);
    const parentFieldError = find(propEq('validator', PARENT_FIELD_VALIDATOR), questionsErrors);
    if (parentFieldError) {
        const [parent, child] = match(/".*?"/g, parentFieldError.message);
        const questions = prop('value', form.getFieldState('questions'));
        const parentLabel = prop('label', find(propEq('field', replace(/"/g, '', parent)), questions));
        const childLabel = prop('label', find(propEq('field', replace(/"/g, '', child)), questions));

        return (
            <div className='error-message footer-error'>
                { t('vacancy.questions.questionShouldAfterQuestion', { question: childLabel, afterQuestion: parentLabel }) }
            </div>
        );
    } else {
        return null;
    }
};

export const renderGeoQuestionOrderError = (formAction, form, t) => {
    const questionsErrors = pathOr([], ['meta', 'error', 'data', 'errors', 'questions'], formAction);
    const parentFieldError = find(propEq('validator', GEO_PARENT_FIELD_VALIDATOR), questionsErrors);

    if (parentFieldError) {
        const [parent, child] = match(/".*?"/g, parentFieldError.message);
        const questions = prop('value', form.getFieldState('questions'));
        const parentLabel = prop('label', find(propEq('field', replace(/"/g, '', parent)), questions));
        const childLabel = prop('label', find(propEq('field', replace(/"/g, '', child)), questions));

        return (
            <div className='error-message footer-error'>
                { t('vacancy.questions.questionShouldAfterQuestion', { question: childLabel, afterQuestion: parentLabel }) }
            </div>
        );
    } else {
        return null;
    }
};

