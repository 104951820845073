import React from 'react';
import { Field } from 'react-final-form';
import { Col } from 'antd';
import { withTranslation } from 'react-i18next';

import BaseFilter from './base/BaseFilter';
import withFilterForm from './base/withFilterForm';
import Select from '../forms/formComponents/Select';
import { companyState } from '../../constants/vacancy';
import { COMPANY } from '../../constants/urls';

export default withTranslation()(withFilterForm(({ t }) => (
    <BaseFilter
        actionUrl={COMPANY.stringify()}
        searchFieldName='name'
        searchFieldPlaceholder={t('company.company')}
        span={9}>
        <Col className='text-left' span={6}>
            <Field
                name='enabled'
                placeholder={t('company.all')}
                component={Select}
                options={companyState}
                namePath='value'
            />
        </Col>
    </BaseFilter>
)));
