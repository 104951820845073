import React, { useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { filter, any, contains } from 'ramda';
import { useTranslation } from 'react-i18next';

import SIDEBAR_ITEMS, { DEFAULT_OPEN_KEYS } from '../../../constants/sidebarItems';
import { COMPANY_MANAGER } from '../../../constants/roles';

const { SubMenu } = Menu;

const filterItems = (items, { stateCompanyCode, roles, isAdmin, isFullLicense, isFunctionalLicense}) => (
    filter(
        item => (
            (!item.roles ||
            any(role => contains(role, roles), item.roles) ||
            stateCompanyCode && contains(COMPANY_MANAGER, item.roles)) &&
            (stateCompanyCode ? !item.withoutCompany : !item.withCompany) &&
            (item.fullLicense && !isAdmin ? (item.hideFunctionalLicense ? isFullLicense : (isFullLicense || isFunctionalLicense)) : true) &&
            !(item.hideFunctionalLicense && !isAdmin && isFunctionalLicense)
        ),
        items
    )
);

const Sidebar = props => {
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const { t } = useTranslation();

    const onCollapse = () => setCollapsed(prev => !prev);

    const findSelectedKey = (sidebarsItems) => {
        let selected = null;

        sidebarsItems.forEach(sidebarItem => {
            if (!selected) {
                if (sidebarItem.subItems) {
                    selected = findSelectedKey(sidebarItem.subItems);
                } else {
                    if (
                        sidebarItem.key === '/' && sidebarItem.key === location.pathname
                        || matchPath(location.pathname, { path: sidebarItem.route || sidebarItem.key, })
                    ) {
                        selected = sidebarItem;
                    }
                }
            }
        });

        return selected;
    }

    const getSelectedKey = () => {
        const selected = findSelectedKey(SIDEBAR_ITEMS);

        return selected ? [selected.key] : null;
    }

    const getItems = () => {
        return filterItems(SIDEBAR_ITEMS, props);
    }

    const items = getItems();

    return (
        <Layout.Sider
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
            width={230}
        >
            <Menu
                theme='dark'
                selectable={false}
                selectedKeys={getSelectedKey()}
                mode='inline'
                defaultOpenKeys={DEFAULT_OPEN_KEYS}
            >
                { items.map(({ icon: Icon, ...item }) => item.subItems ? (
                    <SubMenu key={item.key} icon={<Icon />} title={t(item.title)}>
                        { filterItems(item.subItems, props).map(({ icon: SubIcon, ...subItem }) =>
                            <Menu.Item key={subItem.key} icon={ SubIcon ? <SubIcon /> : undefined }>
                                <Link to={subItem.key}>{ t(subItem.title) }</Link>
                            </Menu.Item>
                        )}
                    </SubMenu>
                ) : (
                    <Menu.Item key={item.key} icon={ Icon ? <Icon /> : undefined }>
                        <Link to={item.key}>{ t(item.title) }</Link>
                    </Menu.Item>
                ))}
            </Menu>
        </Layout.Sider>
    );
}

export default Sidebar;
