import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import UserContext from '../../../contexts/UserContext';
import CompanyCodeContext from '../../../contexts/CompanyCodeContext';
import { putJuristicEntityHandler, postJuristicEntityHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { USERS_ADMIN, JURISTIC_ENTITY } from '../../../constants/urls';
import withUserCompany from '../../hocs/withUserCompany';
import JuristicEntityForm from '../../forms/JuristicEntityForm';

const JuristicEntityModal = props => {
    const { user } = useContext(UserContext);
    const { revalidateAction } = useContext(ActionsContext);
    const { companyCode } = useContext(CompanyCodeContext);
    const { t } = useTranslation();
    const { modal, params, close, isAdmin, isManager } = props;

    const onPostSuccess = () => {
        revalidateAction(USERS_ADMIN.stringify());
        revalidateAction(JURISTIC_ENTITY.stringify());
        message.success(t('company.entitySuccess'));
        close();
    };

    const onPutSuccess = () => {
        revalidateAction(USERS_ADMIN.stringify());
        revalidateAction(JURISTIC_ENTITY.stringify());
        message.success(t('company.entityEditSuccess'));
        close();
    };

    return <Modal
        {...modal}
        title={params.user ? t('userAdmin.editUser') : t('userAdmin.addUser')}
        footer={null}>
        <JuristicEntityForm
            formAction={params.item ? putJuristicEntityHandler : postJuristicEntityHandler}
            item={params.item}
            isAdmin={isAdmin || companyCode}
            isManager={isManager}
            company={user.company}
            onSuccess={params.item ? onPutSuccess : onPostSuccess}
        />
    </Modal>;
}

export default withUserCompany(JuristicEntityModal);
