import {
    ACTIVE_ID, ARCHIVE_ID, EXPERIED_ID, ALL_ID,
    VACANCY_STATE, DOMAIN_STATE,
    PASSED_STATE, NOPASSED_STATE, NOBARIER_STATE
} from '../constants/vacancy';

export const vacanciesFilter = (filter) => {
    switch (filter.published) {
        case ACTIVE_ID:
            return ({
                active: true,
                published: true,
            });
        case ARCHIVE_ID:
            return ({
                active: false,
                published: false,
            });
        case EXPERIED_ID:
            return ({
                published: true,
                active: false,
            });
        case ALL_ID:
        default:
            return ({
                active: undefined,
                published: undefined,
            });
    }
};

export const applicantsFilter = (filter) => {
    switch (filter.state) {
        case VACANCY_STATE:
            return ({
                ...filter,
                withDomain: undefined,
                withVacancy: true,
                state: undefined,
            });
        case DOMAIN_STATE:
            return ({
                ...filter,
                withDomain: true,
                withVacancy: false,
                state: undefined,
            });
        default:
            return ({
                ...filter,
                withDomain: undefined,
                withVacancy: undefined,
                state: undefined,
            });
    }
};

export const applicantsRejectFilter = (filter) => {
    switch (filter.rejectedState) {
        case PASSED_STATE:
            return ({
                ...filter,
                rejected: false,
                hasCorrectValues: undefined,
                rejectedState: undefined,
            });
        case NOPASSED_STATE:
            return ({
                ...filter,
                rejected: true,
                hasCorrectValues: undefined,
                rejectedState: undefined,
            });
        case NOBARIER_STATE:
            return ({
                ...filter,
                hasCorrectValues: false,
                rejected: false,
                rejectedState: undefined,
            });
        default:
            return ({
                ...filter,
                rejected: null,
                hasCorrectValues: undefined,
                rejectedState: undefined,
            });
    }
};

