import React, { Fragment, useState } from 'react';
import { isEmpty } from 'ramda';
import { DatePicker, Checkbox } from 'antd';
import moment from 'moment';
import { FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const getStartDay = date => {
    return moment().isSame(date, 'day') ? moment() : date.startOf('day');
};

const PublishDatePicker = props => {
    const { t } = useTranslation();
    const [noEnd, setNoEnd] = useState(props.values[props.toPath] === null);

    const onChange = (value, change) => {
        const { onChange, toPath } = props;
        const hasValue = !isEmpty(value);

        if (!value || !hasValue) {
            onChange(undefined);
            change(toPath, undefined);
        } else {
            if (value[0]) {
                onChange(getStartDay(value[0]).toISOString());
                change(toPath, value[1].endOf('day').toISOString());
            } else {
                onChange(getStartDay(value).toISOString());
                change(toPath, null);
            }
        }
    }

    const { form, values, placeholder, input: { name, value }, className, toPath } = props;
    const pickerValue = value ? [moment(value), values[toPath] ? moment(values[toPath]) : null] : undefined;

    return (
        <Fragment>
            { !noEnd ? (
                <DatePicker.RangePicker
                    id={name}
                    value={pickerValue}
                    className={className}
                    onChange={e => onChange(e, form.change)}
                    placeholder={placeholder.map(t)}
                    format='DD-MM-YYYY'
                />
            ) : (
                <DatePicker
                    id={name}
                    value={value ? moment(value) : null}
                    className={className}
                    onChange={e => onChange(e, form.change)}
                    placeholder={t(placeholder[0])}
                    format='DD-MM-YYYY'
                />
            )}
            <Checkbox
                id={`${name}-checkbox`}
                checked={!!noEnd}
                onChange={e => {
                    const noEndVal = e.target.checked;
                    setNoEnd(noEndVal);
                    form.change(toPath, noEndVal ? null : undefined);
                }}
            >
                { t('form.withoutRestrictions') }
            </Checkbox>
        </Fragment>
    );
}

PublishDatePicker.defaultProps = {
    placeholder: ['form.from', 'form.to'],
};

export default withFieldWrapper(props => (
    <FormSpy subscription={{ values: true }}>
        { formProps =>
            <PublishDatePicker {...formProps} {...props} />
        }
    </FormSpy>
));
