import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import { path, pathOr, isEmpty, propOr, contains, reverse } from 'ramda';
import { Button, Spin, Switch, message } from 'antd';
import cx from 'classnames';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import io from 'socket.io-client';
import axios from 'axios';

import { getDialogMessagesHandler } from '../../../actions/handlers';
import { chatFilterLimit, chatScrollContainer } from '../../../constants/chat';
import ChatDate from '../chat/ChatDate';
import UserRequest from '../chat/UserRequest';
import BotResponse from '../chat/BotResponse';
import { getToken } from '../../../utils/token';
import DeepLink from '../../table/DeepLink';
import ChatSendMessageForm from '../../forms/ChatSendMessageForm';
import Avatar from './Avatar';
import { ADMIN } from '../../../constants/roles';
import CustomScroll from '../../CustomScroll';
import UserContext from '../../../contexts/UserContext';
import { patchAdminDialogDisableHandler, patchAdminDialogEnableHandler } from '../../../actions/handlers';
import { DIALOG } from '../../../constants/urls';
import ErrorHandler from '../../hocs/withErrorHandler';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const appender = (payload, current) => {
    const payloadItems = reverse(payload.items);

    const items = current.items ? [...payloadItems, ...current.items] : [...payloadItems];
    const payloadItemsLength = payload.items.length;
    const currentPreviousCursor = path(['_meta', 'previousCursor'], current);
    const currentNextCursor = path(['_meta', 'nextCursor'], current);

    return ({
        ...current,
        ...payload,
        ['items']: items,
        _meta: {
            ...payload._meta,
            previousCursor: payload._meta.previousCursor || currentPreviousCursor,
            nextCursor: currentNextCursor || payload._meta.nextCursor
        },
        payloadItemsLength
    });
};

let socket = null;

const Dialog = props => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const isAdmin = contains(ADMIN, propOr([], 'roles', user));
    const [patchPending, setPatchPending] = useState(false);
    const [dialogMessages, _setDialogMessages] = useState({});
    const [dialogMessagesPending, setDialogMessagesPending] = useState(false);
    const dialogData = useCompanyCodeSwr(DIALOG.stringify({ bot: props.match.params.bot, dialog: props.match.params.dialog }), url => axios.get(url, {
        params: {
            relations: ['bot'],
        }
    }));
    const dialog = dialogData.data || {};
    const didMount = useRef(null);
    const dialogMessagesRef = useRef(dialogMessages);

    const setDialogMessages = data => {
        dialogMessagesRef.current = data;
        _setDialogMessages(data);
    };

    const fetchDialogMessages = payload => {
        setDialogMessagesPending(true);
        getDialogMessagesHandler(payload).then(data => {
            const prevScrollHeight = payload.prevScrollHeight;
            const target = document.querySelector(chatScrollContainer);
            const messages = appender({ ...data, prevScrollHeight }, dialogMessages);

            setDialogMessagesPending(false);
            setDialogMessages(messages);

            if (target) {
                const currentScrollHeight = target.scrollHeight;

                if (messages.prevScrollHeight) {
                    target.scrollTop = currentScrollHeight - messages.prevScrollHeight;
                }
            }
        }).catch(() => {
            setDialogMessagesPending(false);
        });
    }

    const sendChatMessage = (message) => {
        socket && socket.emit('inputMessage', message);
    }

    const receiveChatMessage = data => {
        var items = [...dialogMessagesRef.current.items, data];

        setDialogMessages({
            ...dialogMessagesRef.current,
            items,
            payloadItemsLength: items.length
        });
    }

    const startAdminChatConnection = ({ query }) => {
        socket = io(`/chat/admin`, {
            forceNew: true,
            query
        });

        socket.on('outputMessage', receiveChatMessage);
    }

    const endAdminChatConnection = () => {
        socket && socket.off('outputMessage', receiveChatMessage);
        socket && socket.close();
        socket = null;
    }

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;

            fetchDialogMessages({
                bot: props.match.params.bot,
                dialog: props.match.params.dialog,
                filter: {
                    limit: chatFilterLimit,
                    direction: 'previous',
                }
            });
            startAdminChatConnection({
                query: {
                    token: getToken()
                }
            });
        }
    });

    useEffect(() => {
        return () => {
            endAdminChatConnection();
        }
    }, []);

    const onScrollTop = ({ scrollTop, scrollHeight }) => {
        const { match } = props;
        const { items = [], payloadItemsLength, _meta = {} } = dialogMessages;
        const { count } = _meta;
        const notLastItems = payloadItemsLength ? payloadItemsLength !== count : items.length !== count;
        const { bot, dialog } = pathOr({}, ['params'], match);

        if (scrollTop === 0 && notLastItems && dialogMessages._meta.previousCursor) {
            fetchDialogMessages({
                bot,
                dialog,
                filter: {
                    cursor: dialogMessages._meta.previousCursor,
                    limit: chatFilterLimit,
                    direction: 'previous',
                },
                prevScrollHeight: scrollHeight
            });
        }
    }

    const getAvatarUrl = () => {
        const avatar = dialog.avatar;
        const token = getToken();

        return avatar && token ? (
            `${window.location.origin}/api/file/${avatar}?access_token=${token}`
        ) : null;
    }

    const toggleAdminDialog = checked => {
        const { match: { params }} = props;
        const dispatch = checked ? patchAdminDialogEnableHandler : patchAdminDialogDisableHandler;

        setPatchPending(true);
        dispatch({
            bot: params.bot,
            dialog: params.dialog
        }).then(() => {
            dialogData.mutate();
            setPatchPending(false);
            message.success(t(checked ? 'dialog.operatorModeSuccessOn' : 'dialog.operatorModeSuccessOff'));
        })
        .catch(() => {
            setPatchPending(false);
            message.error(t(checked ? 'dialog.operatorModeErrorOn' : 'dialog.operatorModeErrorOff'))
        });
    }

    const renderAdminDialogToggler = () => {
        return <Fragment>
            <Switch
                checked={dialog.manual}
                onChange={toggleAdminDialog}
                loading={patchPending} /> { t('dialog.operatorMode') }
        </Fragment>;
    }

    const { botToken, userId } = props.location.state;
    const { history } = props;
    const avatar = getAvatarUrl();
    const items = pathOr([], ['items'], dialogMessages);
    const userName = dialog.name || t('dialog.withoutName');
    const bot = path(['_relations', 'bot'], dialog);
    const botName = path(['_relations', 'bot', 'username'], dialog);
    const botAvatar = path(['_relations', 'bot', 'avatar'], dialog);

    return (
        <ErrorHandler action={dialogData}>
            <div className='dialog-page'>
                <div className='dialog-toolbar'>
                    <div className='dialog-toolbar-group'>
                        <Button shape='circle' icon={<ArrowLeftOutlined />} onClick={history.goBack} />
                        <Avatar avatar={dialog.avatar} name={dialog.name} />
                        <h1>{userName}</h1>
                    </div>
                    { bot && <DeepLink bot={bot} enableLink /> }
                </div>
                <div className={cx('dialog-background', { 'admin-dialog': dialog.manual, 'hide-operator': isAdmin })}>
                    <div className='dialog-wrapper'>
                        <CustomScroll onScroll={onScrollTop}>
                            <div className='dialog-cards'>
                                <div className='prev-messages-row'>
                                    <Spin wrapperClassName='prev-messages-row' spinning={dialogMessagesPending}/>
                                </div>
                                { !isEmpty(items) && items.map((dialogItem, index) => {
                                    const { state, createdAt } = dialogItem;
                                    const MessageComponent = !state ? UserRequest : BotResponse;
                                    const prevDateValue = path([index - 1, 'createdAt'], items);

                                    return (
                                        <div key={index}>
                                            <ChatDate
                                                dateValue={createdAt}
                                                prevDateValue={prevDateValue}
                                                className='date-row'
                                            />
                                            <MessageComponent
                                                data={dialogItem}
                                                avatar={avatar}
                                                userName={userName}
                                                botName={botName}
                                                botAvatar={botAvatar}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </CustomScroll>
                        { !isAdmin && (
                            <div className='dialog-controls'>
                                { renderAdminDialogToggler() }
                                { dialog.manual &&
                                    <ChatSendMessageForm
                                        formAction={sendChatMessage}
                                        textPath='text'
                                        initialValues={{
                                            botToken,
                                            userId
                                        }} />
                                }
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </ErrorHandler>
    );
}

export default Dialog;
