import React, { useState } from 'react';
import { find, path, pathOr, propEq } from 'ramda';
import { withYMaps } from 'react-yandex-maps';

import { parseGeoObject } from '../../utils/geo';
import { CITY_DICTIONARY } from '../../constants/urls';
import { getCoordinatesHandler } from '../../actions/handlers';
import useCompanyCodeSwr from '../../utils/useCompanyCodeSwr';

const findCity = (dataCities, value, cities) => {
    const items = pathOr([], ['items'], dataCities);
    const city = find(propEq('id', value), (items || []).concat(cities || [])) || {};
    return city.value || city.name;
};

export default itemName => Component => withYMaps(props => {
    const [cityName, setCity] = useState();
    const [coordinates, setCoordinates] = useState(null);
    const [coordinatesPending, setCoordinatesPending] = useState(false);
    const cityDictionary = useCompanyCodeSwr(CITY_DICTIONARY.stringify(), null, {
        onSuccess: data => {
            const city = path([itemName, 'city'], props);
            setCity(findCity(data, city));
        }
    })

    return <Component
        {...props}
        cityName={cityName}
        coordinatesPending={coordinatesPending}
        onCitySelect={(value, addressField, cities) => {
            const geoObject = coordinates && coordinates.geoObjects.get(0);
            const lastAddress = geoObject && geoObject.properties.get('text');
            const fieldAddress = path(['address'], addressField);

            if (fieldAddress && (lastAddress ? fieldAddress !== lastAddress : fieldAddress)) {
                return;
            }

            const city = findCity(cityDictionary.data, value, cities);
            if (props.ymaps && city) {
                setCoordinatesPending(true);
                getCoordinatesHandler({
                    city,
                    geocode: props.ymaps.geocode,
                }).then(data => {
                    const { location, address } = parseGeoObject(data);

                    setCoordinates(data);
                    setCoordinatesPending(false);
                    props.form.change('address', {
                        address,
                        location,
                    });
                }).then(() => setCoordinatesPending(false));
            }

            return setCity(city);
        }}
    />;
}, false, 'geocode');
