import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import DocumentTypeForm from './DocumentTypeForm';

const DocumentTypeModal = props => {
    const { t } = useTranslation();
    const { modal, params: { addAdditionalType, values }, close } = props;

    return (
        <Modal
            {...modal}
            className='domain-modal'
            title={t('settings.addFileType')}
            footer={null}
        >
            <DocumentTypeForm
                values={values}
                formAction={(data) => {
                    addAdditionalType(data);
                    close();
                }}
            />
        </Modal>
    );
}

export default DocumentTypeModal;
