import React from 'react';
import { Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';

import { buildYupLocale } from '../../../utils/validation';
import { addDefaultHeader } from '../../../utils/http';

const LanguageDropdown = props => {
    const { t, i18n } = useTranslation();

    const languageMenu = (
        <Menu onClick={({ key }) => {
            addDefaultHeader('Accept-Language', key);
            localStorage.setItem('i18nextLng', key);
            i18n.changeLanguage(key);
            buildYupLocale(null, t);

            if (!props.disableLanguageReload) {
                window.location.reload();
            }
        }}>
            <Menu.Item key="ru">RU <span className={`flag flag-ru`} /></Menu.Item>
            <Menu.Item key="en">EN <span className={`flag flag-en`} /></Menu.Item>
            <Menu.Item key="ua">UA <span className={`flag flag-ua`} /></Menu.Item>
            <Menu.Item key="et">ET <span className={`flag flag-et`} /></Menu.Item>
            <Menu.Item key="lt">LT <span className={`flag flag-lt`} /></Menu.Item>
            <Menu.Item key="lv">LV <span className={`flag flag-lv`} /></Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={languageMenu} placement="bottom" trigger='click'>
            <span>{`${i18n.language}`.toUpperCase()}</span>
        </Dropdown>
    );
};

export default LanguageDropdown;
