import React from 'react';
import { Modal } from 'antd';

import UtmParamsForm from '../../forms/UtmParamsForm';
import { useTranslation } from 'react-i18next';

const UtmParamsModal = props => {
    const { t } = useTranslation();
    const { modal, params, putLanding } = props;

    return <Modal
        {...modal}
        title={t('landing.utmGenerator')}
        width={800}
        footer={null}>
        <UtmParamsForm
            url={params.url}
            vacancyTitle={params.vacancyTitle}
            formAction={putLanding} />
    </Modal>;
}

export default UtmParamsModal;
