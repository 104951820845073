import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { Button, message, Tag } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined } from '@ant-design/icons';
import { contains, prop } from 'ramda';

import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';
import Select from './formComponents/Select';
import { rolesSelectOptions, rolesAdminSelectOptions, USER, COMPANY_MANAGER, ADMIN, ADMIN_COMPANY } from '../../constants/roles';
import { PhoneInput } from './formComponents/MaskedInput';
import Checkbox from './formComponents/Checkbox';
import Switch from './formComponents/Switch';
import ListenerField from './ListenerField';
import withUserCompany from '../hocs/withUserCompany';
import { getUserInviteHandler } from '../../actions/handlers';
import { COMPANY, JURISTIC_ENTITY } from '../../constants/urls';

const PublishBlock = styled.div`
    .ant-row.ant-form-item {
        margin-bottom: 0;
        .ant-form-item-control-input {
            min-height: auto;
        }
    }
`;

const UserAdminForm = props => {
    const { t } = useTranslation();
    const { user, isAdmin, isManager, isCurrentUser, isFullLicense, hasCompany, companyCode, isFunctionalLicense } = props;

    return <Fragment>
        <Field
            name='lastName'
            component={Input}
            label={t('userAdmin.lastName')} />
        <Field
            name='firstName'
            component={Input}
            label={t('userAdmin.firstName')} />
        <Field
            name='middleName'
            component={Input}
            label={t('userAdmin.middleName')} />
        { isAdmin && !hasCompany && !companyCode ?
            <Field
                name='roles'
                component={Select}
                label={t('userAdmin.role')}
                options={rolesAdminSelectOptions}
                namePath='value' />
            : <Field
                name='roles'
                component={Select}
                label={t('userAdmin.role')}
                options={rolesSelectOptions}
                namePath='value' />
        }
        <ListenerField listenFieldName={['company', 'roles']}>
            { values => contains(ADMIN_COMPANY, values.roles || []) ?
                <Field
                    name='companies'
                    component={Select}
                    label={t('app.sidebar.companies')}
                    actionUrl={COMPANY.stringify()}
                    namePath='name'
                    filter={{ 'enabled': true }}
                    isMulti
                />
                : (prop('length', values.roles) && !contains(ADMIN, values.roles)) && <Fragment>
                    { (isAdmin && hasCompany && !companyCode) && <Field
                        name='company'
                        component={Select}
                        label={t('userAdmin.company')}
                        actionUrl={COMPANY.stringify()}
                        namePath='name'
                        filter={{ 'enabled': true }}
                        onBeforeChange={() => props.form.change('juristicEntity', undefined)}
                    />}
                    <Field
                        key={values.company}
                        name='juristicEntity'
                        component={Select}
                        label={t('company.juristicEntity')}
                        actionUrl={JURISTIC_ENTITY.stringify()}
                        namePath='title'
                        allowDefaultOption={user && user.id ? false : 1}
                        disabled={!values.company && !companyCode}
                        filter={{ company: props.values.company }}
                    />
                </Fragment>
            }
        </ListenerField>
        <Field
            name='username'
            component={Input}
            disabled={isCurrentUser}
            label='Email'
            htmlType='email' />
        <Field
            name='phone'
            component={PhoneInput}
            label={t('userAdmin.phone')} />
        <Field
            name='enabled'
            component={Switch}
            label={t('bot.subscribed')} />
        <ListenerField listenFieldName='roles'>
            { values => !contains(ADMIN, values.roles || []) &&
                <Fragment>
                    <div>{t('userAdmin.publicationEnabled')}</div>
                    <PublishBlock>
                        <Field
                            name='publicationSettings.domain'
                            component={Checkbox}
                            placeholder={t('userAdmin.domains')} />
                        { (isFullLicense || isFunctionalLicense || isAdmin) && (
                            <Fragment>
                                { !(isFunctionalLicense && !isAdmin) &&
                                    <Field
                                        name='publicationSettings.bot'
                                        component={Checkbox}
                                        placeholder={t('userAdmin.chatBots')} />
                                }
                                { !(isFunctionalLicense && !isAdmin) &&
                                    <Field
                                        name='publicationSettings.terminal'
                                        component={Checkbox}
                                        placeholder={t('userAdmin.terminal')} />
                                }
                                <Field
                                    name='publicationSettings.landing'
                                    component={Checkbox}
                                    placeholder={t('userAdmin.landings')} />
                            </Fragment>
                        )}
                    </PublishBlock>
                </Fragment>
            }
        </ListenerField>
        <div className='modal-form-toolbar'>
            { (isAdmin || isManager) && user && user.id && (
                user.activated ? (
                        <Tag icon={<CheckCircleOutlined />} color="success">
                            { t('userAdmin.active') }
                        </Tag>
                ) : (
                    <Button style={{ marginTop: 20 }}
                        onClick={() => getUserInviteHandler({ id: user.id })
                            .then(() => message.success(t('userAdmin.inviteSendSuccess')))
                            .catch(() => message.error(t('userAdmin.inviteSendError')))
                        }
                        className='invite-button'
                    >
                        { t('userAdmin.resendInvite') }
                    </Button>
                )
            )}
            <SubmitButton type='primary'>
                { user ? t('form.save') : t('userAdmin.add') }
            </SubmitButton>
        </div>
    </Fragment>;
}

const validationSchema = ({ isAdmin, hasCompany, companyCode }) => yup.object().shape({
    id: yup.string(),
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    username: yup.string().email().required(),
    phone: yup.string().nullable().phone(),
    companies: yup.array()
        .when('roles', {
            is: (value) => contains(ADMIN_COMPANY, value || []),
            then: yup.array().nullable().required(),
            otherwise: yup.array().nullable(),
        }),
    company: isAdmin && hasCompany && !companyCode ? yup.string()
        .when('roles', {
            is: (value) => contains(COMPANY_MANAGER, value || []) || contains(USER, value || []),
            then: yup.string().nullable().required(),
            otherwise: yup.string().nullable(),
        }) : yup.string().nullable(),
    juristicEntity: yup.string()
        .when('roles', {
            is: (value) => contains(COMPANY_MANAGER, value || []) || contains(USER, value || []),
            then: yup.string().nullable().required(),
            otherwise: yup.string().nullable(),
        }),
    roles: yup.array().of(yup.string().nullable()).nullable().required(),
});

export default withUserCompany(
    withFormWrapper(UserAdminForm, {
        mapBeforeSubmit: values => ({
            ...values,
            ...(contains(ADMIN_COMPANY, values.roles) ? { company: null, juristicEntity: null } : {}),
            ...(contains(ADMIN, values.roles) ? { companies: null, company: null, juristicEntity: null } : {}),
            ...(contains(USER, values.roles) ? { companies: null } : {}),
            ...(contains(COMPANY_MANAGER, values.roles) ? { companies: null } : {}),
        }),
        mapPropsToValues: ({ user, company, isAdmin, isManager, hasCompany }) => {
            const data = isAdmin ? {
                ...user,
                roles: user ? user.roles : !hasCompany ? [ADMIN] : [USER],
            } : {
                roles: !hasCompany ? [ADMIN] : isManager ? [USER] : null,
                ...user,
                company,
            };

            return user ? data : {
                ...data,
                enabled: true,
                publicationSettings: {
                    domain: true
                }
            };
        },
        validationSchema
    })
);
