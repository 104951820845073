import { Validator } from 'class-validator';

const validator = new Validator();

export default function phone(ref, msg) {
    return this.test({
        name: 'phone',
        exclusive: false,
        message: msg || 'Введите телефон в формате: +79123456789',
        test: (value) => value ? validator.isPhoneNumber(value, 'ru-RU') : true
    });
}
