import React, { Fragment, useState } from 'react';
import { Button, Row, Col, Radio } from 'antd';
import { pathOr, path, isEmpty, prop, propOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { LANGUAGES } from '../../constants/dictionary';

const ClassifiersLanguageForm = props => {
    const [language, setLanguage] = useState('en');
    const { t } = useTranslation();

    const changeLanguage = (event) => {
        const value = event.target.value;

        setLanguage(value);
    }

    const getTranslation = (item) => {
        if (language === 'ru') {
            return prop('name', item);
        } else {
            return path(['translations', language], item);
        }
    }

    const renderLanguageRadio = () => {
        return (
            <Radio.Group value={language} onChange={changeLanguage}>
                { LANGUAGES.map((language, index) =>
                    <Radio.Button key={`${name}-${index}`} value={language.value} disabled={language.disabled}>
                        { language.label }
                    </Radio.Button>
                )}
            </Radio.Group>
        );
    }

    const renderClassifierOptions = (options) => {
        return options.map((option, index) => (
            <Row gutter={10} className='classifier-translate-item option' key={propOr(index, 'option', name)}>
                <Col span={24} className='classifier-translate-col'>
                    <div className='translate-value'>{getTranslation(option)}</div>
                </Col>
            </Row>
        ));
    }

    const renderClassifierItem = (classifier) => {
        const classifierName = prop('name', classifier);
        const options = pathOr([], ['_relations', 'options'], classifier);

        return (
            <Fragment key={classifierName}>
                <Row gutter={10} className='classifier-translate-item'>
                    <Col span={24} className='classifier-translate-col'>
                        <div className='translate-value'>{ getTranslation(classifier) }</div>
                    </Col>
                </Row>
                { !isEmpty(options) && renderClassifierOptions(options) }
            </Fragment>
        );
    }

    const { items } = props;

    return (
        <div>
            <div className='language-select'>
                { renderLanguageRadio() }
            </div>
            <Row className='question-translate-header'>
                <Col span={12} className='question-translate-col'>{t('settings.name')}:</Col>
            </Row>
            <div className='classifier-translate'>
                { items && items.map(item => renderClassifierItem(item)) }
            </div>
            <Button
                style={{ marginTop: '15px' }}
                type='primary'
                onClick={() => props.close()}
            >
                Ок
            </Button>
        </div>
    );
}

export default ClassifiersLanguageForm;
