import React from 'react';
import { withTranslation } from 'react-i18next';

import { ROLE_TITLES } from '../../constants/roles';

export default withTranslation()(({ roles = [], t }) =>
    roles.map(role =>
        <div key={role}>
            { t(ROLE_TITLES[role]) }
        </div>
    ));
