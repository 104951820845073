import React from 'react';
import Masked from 'react-text-mask';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { PHONE_MASK } from '../../../constants/masks';

const MaskedInputComponent = ({ input: { value, onChange, name }, mask, pipe, placeholder, disabled }) => (
    <Masked
        id={name}
        name={name}
        value={value}
        mask={mask}
        onChange={onChange}
        className='ant-input'
        placeholderChar={'\u2000'}
        keepCharPositions={false}
        guide={true}
        pipe={pipe}
        placeholder={placeholder}
        disabled={disabled}
    />
);

const MaskedInput = withFieldWrapper(MaskedInputComponent);

export const PhoneInput = props => <MaskedInput {...props} mask={PHONE_MASK} />;

export default MaskedInput;
