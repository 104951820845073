import form from './lt/form.json';
import vacancy from './lt/vacancy.json';
import applicant from './lt/applicant.json';
import auth from './lt/auth.json';
import banner from './lt/banner.json';
import call from './lt/call.json';
import chat from './lt/chat.json';
import company from './lt/company.json';
import domain from './lt/domain.json';
import guest from './lt/guest.json';
import landing from './lt/landing.json';
import profile from './lt/profile.json';
import settings from './lt/settings.json';
import dialog from './lt/dialog.json';
import userAdmin from './lt/userAdmin.json';
import app from './lt/app.json';
import bot from './lt/bot.json';
import unit from './lt/unit.json';
import reports from './lt/reports.json';

export default {
    app,
    form,
    vacancy,
    applicant,
    auth,
    banner,
    call,
    chat,
    company,
    domain,
    guest,
    landing,
    profile,
    settings,
    dialog,
    userAdmin,
    bot,
    unit,
    reports,
};
