import form from './en/form.json';
import vacancy from './en/vacancy.json';
import applicant from './en/applicant.json';
import auth from './en/auth.json';
import banner from './en/banner.json';
import call from './en/call.json';
import chat from './en/chat.json';
import company from './en/company.json';
import domain from './en/domain.json';
import guest from './en/guest.json';
import landing from './en/landing.json';
import profile from './en/profile.json';
import settings from './en/settings.json';
import dialog from './en/dialog.json';
import userAdmin from './en/userAdmin.json';
import app from './en/app.json';
import bot from './en/bot.json';
import unit from './en/unit.json';
import reports from './en/reports.json';

export default {
    app,
    form,
    vacancy,
    applicant,
    auth,
    banner,
    call,
    chat,
    company,
    domain,
    guest,
    landing,
    profile,
    settings,
    dialog,
    userAdmin,
    bot,
    unit,
    reports,
};
