import React from 'react';
import { Button, Row, Col } from 'antd';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';

const ClassifierLanguageForm = () => {
    const { t } = useTranslation();

    return (
        <div className='question-translations'>
            <Row gutter={20} className='question-translations-content'>
                <Col span={3} className='labels-col'>
                    <div className='labels-wrapper'>
                        <div className='label'>{ t('vacancy.form.title') }:</div>
                    </div>
                </Col>
                <Col span={7}>
                    <div className='language'>{ t('vacancy.form.russian') }</div>
                    <Field
                        name={'name'}
                        component={Input}
                        disabled
                    />
                </Col>
                <Col span={7}>
                    <div className='language'>{ t('vacancy.form.ukranian') }</div>
                    <Field
                        name={'translations.ua'}
                        component={Input}
                    />
                </Col>
                <Col span={7}>
                    <div className='language'>{ t('vacancy.form.english') }</div>
                    <Field
                        name={'translations.en'}
                        component={Input}
                    />
                </Col>
            </Row>
            <Row>
                <div className='modal-form-toolbar'>
                    <Button
                        type='primary'
                        htmlType='submit'
                    >
                        { t('form.save') }
                    </Button>
                </div>
            </Row>
        </div>
    );
}

export default withFormWrapper(ClassifierLanguageForm, {
    mapPropsToValues: ({ item }) => item,
});
