import React, { Fragment, useContext, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { contains, without, append, head, drop, prop } from 'ramda';
import { MinusOutlined, CheckOutlined, PlusOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import TableStateList from '../../table/TableStateList';
import DateCell from '../../table/tableCells/DateCell';
import { getFullName } from '../../../utils/fieldsHelpers';
import LandingLinkFilter from '../../filters/LandingLinkFilter';
import { putLandingHandler } from '../../../actions/handlers';
import { LANDING } from '../../../constants/urls';
import ActionsContext from '../../../contexts/ActionsContext';
import { CHANGE_LANDING } from '../../../constants/actionTypes';
import withUserCompany from '../../hocs/withUserCompany';

const LinkButton = styled(Button)`
    ${({ linked }) => linked && css`
        background: #46c44c;
        border-color: #46c44c;
        color: #fff;
        .anticon-minus {
            display: none;
            margin-top: 2px;
            margin-left: 0 !important;
        }
        &:hover {
            background: #fff;
            color: #0c304d;
            color: #0c304d;
            .anticon-minus {
                display: block;
            }
            .anticon-check {
                display: none;
            }
        }
    `}
`;

const VacanciesList = props => {
    const { t } = useTranslation();
    const [collapsed, setCollapsed] = useState(true);

    const toggleCollapsed = () => setCollapsed(!collapsed);

    const renderVacancies = vacancies => {
        const first = head(vacancies);
        const rest = drop(1, vacancies);

        return <Fragment>
            <div>{ prop('title', first)}</div>
            { Array.isArray(rest) &&
                <Fragment>
                    { !collapsed && rest.map(vacancy =>
                        <div key={vacancy.id}>{ vacancy.title }</div>
                    )}
                    <div className='span-link' onClick={toggleCollapsed}>{ collapsed ? '...' : t('landing.hide') }</div>
                </Fragment>
            }
        </Fragment>
    }

    const vacancies = props.vacancies || [];

    return vacancies.length ? renderVacancies(vacancies) : '-';
}

const LandingLink = props => {
    const { t } = useTranslation();
    const { revalidateAction, onSuccessFn } = useContext(ActionsContext);

    const getColumns = () => {
        const { vacancy } = props;

        return [
            {
                title: t('landing.code'),
                dataIndex: 'code',
                key: 'code'
            },
            {
                title: t('landing.landing'),
                key: 'url',
                dataIndex: 'url',
                render: url => <a href={url} target='_blank' rel='noopener noreferrer'>{ t('landing.open') }</a>
            },
            {
                title: t('landing.author'),
                key: 'createdBy',
                dataIndex: ['_relations', 'createdBy'],
                render: getFullName
            },
            {
                title: t('landing.createdAt'),
                key: 'createdAt',
                dataIndex: 'createdAt',
                render: date => <DateCell date={date} />
            },
            {
                title: t('landing.linkedVacancies'),
                key: 'vacancies',
                dataIndex: ['_relations', 'vacancies'],
                render: vacancies => <VacanciesList vacancies={vacancies} />
            },
            {
                key: 'control',
                width: 60,
                render: item => {
                    const linked = contains(vacancy, item.vacancies);

                    return <Tooltip title={linked ? t('landing.unlink') : t('landing.link')}>
                        <LinkButton
                            linked={linked}
                            icon={linked ? <Fragment><CheckOutlined /><MinusOutlined /></Fragment> : <PlusOutlined/>}
                            shape='circle'
                            onClick={() => toggle(item)} />
                    </Tooltip>;
                }
            }
        ];
    };

    const toggle = landing => {
        const { vacancy } = props;
        const vacancies = contains(vacancy, landing.vacancies) ?
            without([vacancy], landing.vacancies) :
            append(vacancy, landing.vacancies);

        putLandingHandler({ ...landing, vacancies }).then(() => {
            revalidateAction(LANDING.stringify());
            onSuccessFn(CHANGE_LANDING);
        });
    }

    return <TableStateList
        param={{ type: 'link' }}
        actionUrl={LANDING.stringify()}
        columns={getColumns()}
        filterForm={LandingLinkFilter}
        initFilters={{ createdBy: props.isAdmin ? null : props.userId }}
        relations={['createdBy', 'vacancies']}
    />;
}

export default withUserCompany(LandingLink);
