import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';

const PasswordConfirmForm = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Field
                name='password'
                component={Input}
                icon={<LockOutlined />}
                htmlType='password'
                placeholder={t('guest.newPassword')} />
            <Field
                name='repeatePassword'
                component={Input}
                icon={<LockOutlined />}
                htmlType='password'
                placeholder={t('guest.repeatNewPassword')} />
            <SubmitButton>
                { t('guest.passwordRecovery') }
            </SubmitButton>
        </Fragment>
    );
}

const validationSchema = () => yup.object().shape({
    password: yup.string().match('repeatePassword').required(),
    repeatePassword: yup.string().match('password').required(),
});

export default withFormWrapper(PasswordConfirmForm, {
    mapPropsToValues: ({restoreToken}) => ({
        restoreToken,
    }),
    validationSchema
});
