import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { pathOr, prop } from 'ramda';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import DomainForm from '../../forms/DomainForm';
import { DOMAIN, VACANCY_FORM_PRESET } from '../../../constants/urls';
import { postDomainHandler, putDomainHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const DomainModal = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const vacancyFormPresets = useCompanyCodeSwr(VACANCY_FORM_PRESET.stringify(), url => axios.get(url, {
        params: {
            pagination: {
                limit: 0,
            },
            relations: ['domains']
        }
    }));

    const { modal, params, close } = props;
    const item = prop('item', params);
    const forms = pathOr([], ['data', 'items'], vacancyFormPresets);

    return (
        <Modal
            {...modal}
            className='domain-modal'
            title={item ? t('domain.editDomain') : t('domain.addDomain')}
            footer={null}
        >
            <DomainForm
                formAction={item ? putDomainHandler : postDomainHandler}
                onSuccess={() => {
                    revalidateAction(DOMAIN.stringify({ id: undefined }));
                    message.success(t(item ? 'domain.saveSuccess' : 'domain.addSuccess'));
                    close();
                }}
                onSubmitFail={() => message.error(t(item ? 'domain.saveError' : 'domain.addError'))}
                item={item}
                forms={forms}
            />
        </Modal>
    );
}

export default DomainModal;
