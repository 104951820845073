import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { LockOutlined } from '@ant-design/icons';
import { useTranslation, withTranslation } from 'react-i18next';

import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';

const RegistrationConfirmForm = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Field
                name='password'
                component={Input}
                icon={<LockOutlined />}
                htmlType='password'
                placeholder={t('guest.setPassword')} />
            <Field
                name='repeatPassword'
                component={Input}
                icon={<LockOutlined />}
                htmlType='password'
                placeholder={t('guest.repeatPassword')} />
            <SubmitButton>
                { t('guest.register') }
            </SubmitButton>
        </Fragment>
    );
}

const validationSchema = () => yup.object().shape({
    password: yup.string().match('repeatPassword').required(),
    repeatPassword: yup.string().match('password').required(),
});

export default withTranslation()(withFormWrapper(RegistrationConfirmForm, {
    mapPropsToValues: ({ id }) => ({
        id,
        user: {
            id
        }
    }),
    validationSchema
}));
