import React, { Fragment, useContext } from 'react';
import { message, Spin, Alert } from 'antd';
import { startsWith } from 'ramda';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import VacancyMasterForm from '../../forms/vacancyForm/VacancyMasterForm';
import withUserCompany from '../../hocs/withUserCompany';
import { COMPANY_SETTINGS, VACANCY } from '../../../constants/urls';
import { postVacancyHandler, putVacancyHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { PUT_VACANCY } from '../../../constants/actionTypes';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const VacancyCreate = props => {
    const { t } = useTranslation();
    const { revalidateAction, onSuccessFn } = useContext(ActionsContext);
    const companySettings = useCompanyCodeSwr(COMPANY_SETTINGS.stringify());
    const { companyCode, companyId, location, vacancy, edit } = props;

    return <VacancyMasterForm
        {...props}
        formAction={vacancy ? putVacancyHandler : postVacancyHandler}
        isExperiumForm={startsWith('/experium', location.pathname)}
        companySettings={companySettings.data || {}}
        keepDirtyOnReinitialize={true}
        companyCode={companyCode}
        companyId={companyId}
        creating={true}
        onSuccess={() => {
            onSuccessFn(PUT_VACANCY);
            revalidateAction(COMPANY_SETTINGS.stringify());
            revalidateAction(VACANCY.stringify());
            companySettings.mutate();
        }}
        onSubmitFail={() => {
            message.error(t('vacancy.detail.saveError'))
        }}
        edit={edit}
        newVacancy
    />;
}

const VacancyCreateComponent = withUserCompany(VacancyCreate);

export default VacancyCreateComponent;

const ErrorWrapper = styled.div`
    padding: 40px 10px 0px;
`;

export const VacancyEdit = props => {
    const { t } = useTranslation();

    const vacancy = useCompanyCodeSwr(VACANCY.stringify({ id: props.match.params.id }), url => axios.get(url, {
        params: {
            relations: ['company', 'createdBy', 'cities', 'specialization', 'specialization.parent', 'vacancyUnit', 'category', 'chartOfWork', 'classifiers', 'employeeType', 'educationLevel', 'salaryCurrency', 'domains', 'formPreset'],
        }
    }));

    return vacancy.data ? (
        <VacancyCreateComponent
            {...props}
            vacancy={vacancy.data}
            edit
        />
    ) : vacancy.error ? (
        <ErrorWrapper>
            <Alert message={t('vacancy.detail.loadError')} type='error' className='form-alert' />
        </ErrorWrapper>
    ) : (
        <Spin className='central-spin' />
    );
}

export const ExperiumVacancyEdit = withUserCompany(props => {
    return <Fragment>
        {(props.companyCode) && (
            <VacancyEdit {...props} onComplete={() => props.history.push(props.location.pathname.replace('/edit', ''))} />
        )}
    </Fragment>;
});

export const ExperiumVacancySubmitted = () => (
    <Helmet>
        <title>Submitted</title>
    </Helmet>
);
