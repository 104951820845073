import React, { useContext, useState } from 'react';
import { Switch, message } from 'antd';
import { useTranslation } from 'react-i18next';

import { putBotSubscribeHandler, putBotUnsubscribeHandler } from '../../actions/handlers';
import ActionsContext from '../../contexts/ActionsContext';
import { BOT } from '../../constants/urls';

const BotSwitch = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const [loading, setLoading] = useState(false);

    const revalidateBots = () => revalidateAction(BOT.stringify());

    const onChange = value => {
        const { id } = props;

        setLoading(true);

        return value ?
            putBotSubscribeHandler(id)
                .then(() => {
                    revalidateBots();
                    setLoading(false);
                    message.success(t('bot.successOn'));
                })
                .catch(() => {
                    setLoading(false);
                    message.error(t('bot.errorOn'));
                }) :
            putBotUnsubscribeHandler(id)
                .then(() => {
                    revalidateBots();
                    setLoading(false);
                    message.success(t('bot.successOff'));
                })
                .catch(() => {
                    setLoading(false);
                    message.error(t('bot.errorOff'));
                });
    };

    return (
        <Switch
            onChange={onChange}
            checked={!!props.checked}
            loading={loading}
        />
    );
}

export default BotSwitch;
