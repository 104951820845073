import React from 'react';

export const UTM_FIELDS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];

export const UTM_FIELDS_INFO = {
    utm_source: {
        label: 'landing.utm.utmSource.label',
        info: function UtmSourceInfo(t) {
            return <div>
                <p><strong>utm_source</strong> — {t('landing.utm.utmSource.info1')}</p>
                <p><strong>{t('landing.utm.forWhat')}:</strong><br />{t('landing.utm.utmSource.info2')}</p>
                <div>
                    <strong>{t('landing.utm.examples')}:</strong>
                    <ul>
                        <li>utm_source=google – {t('landing.utm.utmSource.info3')}</li>
                        <li>utm_source=yandex — {t('landing.utm.utmSource.info4')}</li>
                        <li>utm_source=facebook — {t('landing.utm.utmSource.info5')}</li>
                        <li>utm_source=vk — {t('landing.utm.utmSource.info6')}</li>
                    </ul>
                </div>
            </div>
        }
    },
    utm_medium: {
        label: 'landing.utm.utmMedium.label',
        info: function UtmMediumInfo(t) {
            return <div>
                <p><strong>utm_medium</strong> — {t('landing.utm.utmMedium.info1')}</p>
                <p>{t('landing.utm.utmMedium.info2')}</p>
                <p><strong>{t('landing.utm.forWhat')}:</strong><br />{t('landing.utm.utmMedium.info3')}</p>
                <div>
                    <strong>{t('landing.utm.examples')}:</strong>
                    <ul>
                        <li>utm_medium=organic – {t('landing.utm.utmMedium.info4')}</li>
                        <li>utm_medium=cpc – {t('landing.utm.utmMedium.info5')}</li>
                        <li>utm_medium=email — {t('landing.utm.utmMedium.info6')}</li>
                        <li>utm_medium=social — {t('landing.utm.utmMedium.info7')}</li>
                        <li>utm_medium=banner — {t('landing.utm.utmMedium.info8')}</li>
                        <li>utm_medium=cpa — {t('landing.utm.utmMedium.info9')}</li>
                    </ul>
                </div>
            </div>;
        }
    },
    utm_campaign: {
        label: 'landing.utm.utmCampaign.label',
        info: function UtmCampaignInfo(t) {
            return <div>
                <p><strong>utm_campaign</strong> — {t('landing.utm.utmCampaign.info1')}</p>
                <p>{t('landing.utm.utmCampaign.info2')}</p>
                <p><strong>{t('landing.utm.forWhat')}:</strong><br />{t('landing.utm.utmCampaign.info3')}</p>
                <div>
                    <strong>{t('landing.utm.examples')}:</strong>
                    <ul>
                        <li>utm_campaign=mebel_dlya_doma – {t('landing.utm.utmCampaign.info4')}</li>
                    </ul>
                </div>
            </div>;
        }
    },
    utm_content: {
        label: 'landing.utm.utmContent.label',
        info: function UtmContentInfo(t) {
            return <div>
                <p><strong>utm_content</strong> — {t('landing.utm.utmContent.info1')}</p>
                <p><strong>{t('landing.utm.forWhat')}:</strong><br />{t('landing.utm.utmContent.info2')}</p>
                <div>
                    <strong>{t('landing.utm.examples')}:</strong>
                    <ul>
                        <li>utm_content=zero_block240×60 — {t('landing.utm.utmContent.info3')}</li>
                        <li>utm_content=zero_block_text — {t('landing.utm.utmContent.info4')}</li>
                    </ul>
                </div>
            </div>;
        }
    },
    utm_term: {
        label: 'landing.utm.utmTerm.label',
        info: function UtmTermInfo(t) {
            return <div>
                <p><strong>utm_term</strong> — {t('landing.utm.utmTerm.info1')}</p>
                <p>{t('landing.utm.utmTerm.info2')}</p>
                <p><strong>{t('landing.utm.forWhat')}:</strong><br />{t('landing.utm.utmTerm.info3')}</p>
            </div>;
        }
    }
};

export const UTM_TRAFFIC_SOURCES = [{
        id: 'google',
        value: 'Google Adwords',
        defaultValues: {
            utm_source: 'google',
            utm_medium: 'cpc',
            utm_campaign: '{network}',
            utm_content: '{creative}',
            utm_term: '{keyword}'
        },
        smallText: {
            utm_campaign: 'landing.utm.smallText.google.utm_campaign',
            utm_content: 'landing.utm.smallText.google.utm_content',
            utm_term: 'landing.utm.smallText.google.utm_term'
        }
    },
    {
        id: 'yandex',
        value: 'Яндекс.Директ',
        defaultValues: {
            utm_source: 'yandex',
            utm_medium: 'cpc',
            utm_campaign: '{campaign_id}',
            utm_content: '{ad_id}',
            utm_term: '{keyword}'
        },
        smallText: {
            utm_campaign: 'landing.utm.smallText.yandex.utm_campaign',
            utm_content: 'landing.utm.smallText.yandex.utm_content',
            utm_term: 'landing.utm.smallText.yandex.utm_term'
        }
    },
    {
        id: 'vk',
        value: 'Вконтакте',
        defaultValues: {
            utm_source: 'vkontakte',
            utm_medium: 'cpc',
            utm_campaign: '{campaign_id}',
            utm_content: '{ad_id}'
        },
        smallText: {
            utm_campaign: 'landing.utm.smallText.vk.utm_campaign',
            utm_content: 'landing.utm.smallText.vk.utm_content'
        }
    },
    {
        id: 'facebook',
        value: 'Facebook',
        defaultValues: {
            utm_source: 'facebook',
            utm_medium: 'cpc',
            utm_campaign: 'promo'
        }
    },
    {
        id: 'instagram',
        value: 'Instagram',
        defaultValues: {
            utm_source: 'instagram',
        }
    },
    {
        id: 'odnoklassniki',
        value: 'Odnoklassniki',
        defaultValues: {
            utm_source: 'odnoklassniki',
        }
    },
    {
        id: 'telegram',
        value: 'Telegram',
        defaultValues: {
            utm_source: 'telegram',
        }
    },
    {
        id: 'viber',
        value: 'Viber',
        defaultValues: {
            utm_source: 'viber',
        }
    },
    {
        id: 'whatsapp',
        value: 'WhatsApp',
        defaultValues: {
            utm_source: 'whatsapp',
        }
    },
    {
        id: 'hh',
        value: 'Hh.ru',
        defaultValues: {
            utm_source: 'hh',
        }
    },
    {
        id: 'rabotaru',
        value: 'Rabota.ru',
        defaultValues: {
            utm_source: 'rabotaru',
        }
    },
    {
        id: 'own',
        value: 'Другое'
    }
];
