import React from 'react';
import { useTranslation } from 'react-i18next';

import { COMPANY_LICENSE } from '../../../constants/urls';
import { getFullName } from '../../../utils/fieldsHelpers';
import DateTime from '../../table/DateTime';
import TableStateList from '../../table/TableStateList';

const CompanyLicense = props => {
    const { t } = useTranslation();

    const getColumns = () => {
        return [
            {
                title: t('company.license'),
                key: 'license',
                dataIndex: 'license',
                render: license => t(`company.licenseTypes.${license}`)
            },
            {
                title: t('vacancy.form.created'),
                key: 'createdBy',
                dataIndex: ['_relations', 'createdBy'],
                render: user => getFullName(user)
            },
            {
                title: t('company.activatedAt'),
                key: 'createdAt',
                dataIndex: 'createdAt',
                render: date => <DateTime date={date} />
            },
            {
                title: t('company.expiredAt'),
                key: 'expiredAt',
                dataIndex: 'expiredAt',
                render: date => <DateTime date={date} />
            }
        ];
    }

    return <TableStateList
        actionUrl={COMPANY_LICENSE.stringify()}
        columns={getColumns()}
        relations={['createdBy']}
        staticFilter={{ company: props.company }}
        sorting={{
            field: 'createdAt',
            order: 'desc',
        }}
    />;
}

export default CompanyLicense;
