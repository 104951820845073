import React, { Fragment, useContext, useEffect } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Tabs, Card, message } from 'antd';
import { path, pathOr, find, propEq, isNil, filter, isEmpty, flatten, any } from 'ramda';
import * as yup from 'yup';
import { withStateHandlers } from 'recompose';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'qs';
import { FileTextOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useTranslation, withTranslation } from 'react-i18next';

import { URL_CODE } from '../../../constants/regexps';
import { tabName, salaryPeriod } from '../../../constants/vacancy';
import withFormWrapper from '../../hocs/withFormWrapper';
import withUserCompany from '../../hocs/withUserCompany';
import { preWrap } from '../../../utils/editor';
import { getUrlWithFilters } from '../../../utils/urlParams';
import Banner from '../../views/banners/Banner';
import Landings from '../../views/landing/Landings';
import Metro from '../../table/Metro';
import Money from '../../table/Money';
import Address from '../../table/Address';
import { getBotsPageLink } from '../../../utils/botLink';
import ListenerField from '../ListenerField';
import DateRange from '../../table/DateRange';
import Questions from './Questions';
import ClassifiersView from '../../views/vacancy/ClassifiersView';

import { mapQuestions } from '../../../utils/formMapper';
import { getFormQuestions, addCompanyCode, getVacancyCode } from '../../../utils/vacancy';
import CubeVacancyPreview from './CubeVacancyPreview';
import { LANDING } from '../../../constants/urls';
import ActionsContext from '../../../contexts/ActionsContext';
import { withClassifiers } from '../formComponents/Classifiers';

import logoSVG from '../../../images/logo-bot.svg';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

export const mapClassifiers = (selectedItems = [], items = []) => {
    const allChildren = flatten([...items, ...items.map(item => pathOr([], ['_relations', 'options'], item))]);

    return filter(id => !any(propEq('parent', id), allChildren), selectedItems);
}

const mapPropsToValues = ({ vacancy, companySettings, t, classifiers }) => ({
    ...vacancy,
    code: getVacancyCode(vacancy.code),
    questions: getFormQuestions(vacancy.questions, t),
    companySettings,
    classifiers: mapClassifiers(vacancy.classifiers, pathOr([], ['data', 'items'], classifiers)),
    address: {
        address: path(['address'], vacancy),
        location: path(['location'], vacancy),
    },
});

const DialogFieldView = styled.div`
    margin-top: 10px;
`;

const Description = styled.div`
    display: inline-grid;
`;

const VacancyForm = props => {
    const { t } = useTranslation();
    const { addAction, removeAction } = useContext(ActionsContext);
    const landingsUrl = LANDING.stringify();
    const landings = useCompanyCodeSwr([landingsUrl, props.vacancy.id], (url, vacancyId) => axios.get(url, {
        params: {
            pagination: { limit: 1 },
            filter: { vacancy: vacancyId, published: true },
            relations: ['vacancies', 'createdBy'],
            sorting: {
                field: 'createdAt',
                order: 'desc',
            }
        }
    }));

    useEffect(() => {
        addAction(landingsUrl, landings);
        return () => removeAction(landingsUrl);
    }, []);

    useEffect(() => {
        addAction(landingsUrl, landings);
    }, [props.vacancy.id]);

    const getTabName = tabKey => {
        return t(tabName[tabKey]);
    }

    const getSalary = vacancy => {
        const period = path(['value'], find(propEq('id', vacancy.salaryPeriod), salaryPeriod));
        const currency = path(['_relations', 'salaryCurrency', 'code'], vacancy);
        const type = path(['_relations', 'salaryType', 'name'], vacancy);

        return (vacancy.minSalary || vacancy.maxSalary) && (
            <span>
                <Money min={vacancy.minSalary} max={vacancy.maxSalary} viewType={vacancy.salaryViewType} /> {t(period)} {currency || ''} {type || ''}
            </span>
        );
    }

    const renderProp = (title, prop, string) => {
        return prop &&
            <div>
                <strong>{ title }:</strong> { string || prop }
            </div>;
    }

    const renderDomain = (domains) => {
        if (Array.isArray(domains) && domains.length > 0) {
            return (
                <div className='vacancy-domains-wrapper'>
                    <strong>{ t('vacancy.form.domains') }: </strong>
                    <div className='vacancy-domains'>
                        { domains.map(domain => domain && (
                            <span key={domain.id}>
                                <a
                                    className='vacancy-domain-link'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href={`http://www.${domain.name}`}
                                >
                                    { domain.title }
                                </a> <Link to={getUrlWithFilters('/applicants', { domain: domain.id })}>
                                    <FileTextOutlined />
                                </Link>
                            </span>
                        )) }
                    </div>
                </div>

            );
        } else {
            return null;
        }
    }

    const renderClassifiers = (classifiers = []) => {
        const filteredClassifiers = classifiers && filter((value) => !isNil(value), classifiers);
        const classifiersIds = classifiers.map(({ id }) => id);

        return !isEmpty(filteredClassifiers) && (
            <div>
                <strong>{ t('vacancy.form.classifiers') }:</strong>
                <ClassifiersView selectedClassifiers={mapClassifiers(classifiersIds, pathOr([], ['data', 'items'], props.classifiers))} />
            </div>
        );
    }

    const onTabClick = tab => {
        const { history, match, edit, setTab, setTabSubmit, form } = props;
        const formState = form.getState();

        if (edit && !formState.pristine) {
            if (!formState.invalid) {
                setTabSubmit(true, tab);
            }

            form.submit();
        } else {
            setTab(tab);
            history.replace(`/vacancy/${match.params.id}/${tab}`);
        }
    }

    const renderDialogField = html => {
        return <DialogFieldView className='dialog-row-user'>
            <div className='dialog-message dialog-message-user'>
                <Card className='dialog-card dialog-card-user'>
                    <div className='dialog-card-header'>
                        <div className='dialog-card-user'>{ t('vacancy.form.chatBot') }</div>
                    </div>
                    <div className='pre-wrap' dangerouslySetInnerHTML={{
                        __html: html
                    }} />
                    <div className='user-avatar'>
                        <div className='avatar-logo-wrapper' style={{ backgroundImage: `url(${logoSVG})`}}>
                        </div>
                    </div>
                </Card>
            </div>
        </DialogFieldView>;
    }

    const { vacancy, tab, match, companySettings, isCompanyAdmin } = props;
    const vacancyDescription = { __html: preWrap(vacancy.description) };
    const cities = pathOr([], ['_relations', 'cities'], vacancy).map(city => city ? city.name : '');
    const vacancyUnit = path(['_relations', 'vacancyUnit', 'title'], vacancy);
    const vacancyMetro = <Metro stations={vacancy.metro} cities={cities} />;
    const salary = getSalary(vacancy);
    const address = <Address address={vacancy.address} location={vacancy.location} />;
    const chartOfWork = path(['_relations', 'chartOfWork', 'name'], vacancy);
    const classifiers = path(['_relations', 'classifiers'], vacancy);
    const employeeType = path(['_relations', 'employeeType', 'name'], vacancy);
    const domains = path(['_relations', 'domains'], vacancy);

    return <Fragment>
        <Tabs
            className='vacancy-edit-tabs'
            activeKey={tab}
            onChange={onTabClick}>
            <Tabs.TabPane key="general" tab={getTabName('general')} >
                <div className='vacancy-block_description' style={{ paddingBottom: 20 }}>
                    <div className='vacancy-block_description-info'>
                        <div>
                            <strong>{t('vacancy.form.name')}:</strong> { vacancy.title }
                        </div>
                        { !!vacancy.description && (
                            <div>
                                <strong>{t('vacancy.form.description')}: </strong><Description dangerouslySetInnerHTML={vacancyDescription} />
                            </div>
                        )}
                        { renderProp(t('vacancy.form.code'), getVacancyCode(vacancy.code)) }
                        { isCompanyAdmin && renderProp(t('vacancy.form.created'), (
                            <Link to={getUrlWithFilters(`/settings/users/${vacancy.createdBy}`, { name: path(['_relations', 'createdBy', 'username'], vacancy) })}>
                                {path(['_relations', 'createdBy', 'username'], vacancy)}
                            </Link>
                        )) }
                        { renderProp(t('vacancy.detail.responsible'), (
                            <Link to={getUrlWithFilters(`/settings/users/${vacancy.responsibleBy}`, { name: path(['_relations', 'responsibleBy', 'username'], vacancy) })}>
                                {path(['_relations', 'responsibleBy', 'username'], vacancy)}
                            </Link>
                        ))
                        }
                        { renderClassifiers(classifiers) }
                        { renderProp(t('vacancy.form.unit'), vacancyUnit) }
                        { renderProp(t('vacancy.form.city'), cities.reduce((res, cur, index) => res + (index ? `, ${cur}` : cur), '')) }
                        { renderProp(t('vacancy.form.address'), vacancy.address || vacancy.location, address) }
                        { renderProp(t('vacancy.form.metro'), vacancy.metro && vacancy.metro.length, vacancyMetro) }
                        { renderProp(t('vacancy.form.chartOfWork'), chartOfWork) }
                        { renderProp(t('vacancy.form.employeeType'), employeeType) }
                        { renderProp(t('vacancy.form.salary'), salary) }
                        { renderProp(t('vacancy.form.experienceRequirements'), vacancy.experienceRequirements) }
                        { renderProp(t('vacancy.form.bonuses'), vacancy.bonuses) }
                        { renderProp(t('vacancy.form.industry'), vacancy.industry) }
                        { renderProp(t('vacancy.form.function'), vacancy.function) }
                        { renderProp(t('vacancy.form.level'), vacancy.level) }
                        { renderProp(t('vacancy.form.specialization'), vacancy.specialization) }
                        { (vacancy.datePublishedStart || vacancy.datePublishedEnd) &&
                            renderProp(t('vacancy.form.datePublishedStart'), <DateRange from={vacancy.datePublishedStart} to={vacancy.datePublishedEnd} />)
                        }
                        { renderDomain(domains) }
                    </div>
                </div>
            </Tabs.TabPane>
            { (vacancy.terminalPublished || vacancy.sliderPublished || vacancy.botPublished || !!pathOr([], ['items'], landings.data).length) &&
                <Fragment>
                    <Tabs.TabPane key="form" tab={getTabName('form')}>
                        <Fragment>
                            <FieldArray
                                subscription={{
                                    initial: true,
                                    modified: true,
                                }}
                                component={Questions}
                                name='questions'
                                label={t('vacancy.form.questionnaire')}
                                formDisabled
                                form={props.form}
                                formAction={props.formAction}
                            />
                            { vacancy.formPreset && (
                                <div className='vacancy-block_description'>
                                    <div className='vacancy-block_description-info'>
                                        <div>
                                            <strong>{t('applicant.form')}: </strong>
                                            <Link to={`/forms/formPresets/${vacancy.formPreset}`}>{ path(['_relations', 'formPreset', 'title'], vacancy) }</Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    </Tabs.TabPane>
                    { vacancy.terminalPublished &&
                        <Tabs.TabPane key="terminal" tab={getTabName('terminal')}>
                            <Fragment>
                                <div className='vacancy-block_description'>
                                    <div className='vacancy-block_description-info'>
                                        <div><strong>{t('vacancy.form.published')}:</strong> { vacancy.terminalPublished ? t('vacancy.form.yes') : t('vacancy.form.no') }</div>
                                        { vacancy.terminalPublished &&
                                            <Fragment>
                                                { vacancy.category && <div><strong>{t('vacancy.terminal.category')}:</strong> { path(['_relations', 'category', 'name'], vacancy) }</div> }
                                                <div><strong>{t('vacancy.terminal.callRequestAllowed')}:</strong> { path(['terminalSettings', 'callRequestAllowed'], vacancy) ? t('vacancy.form.yes') : t('vacancy.form.no') }</div>
                                                { path(['terminalSettings', 'callRequestAllowed'], vacancy) &&
                                                    <Fragment>
                                                        <div><strong>{t('vacancy.terminal.showCompanyPhone')}:</strong> { path(['terminalSettings', 'showPhoneAllowed'], vacancy) ? t('vacancy.form.yes') : t('vacancy.form.no') }</div>
                                                        { path(['terminalSettings', 'showPhoneAllowed'], vacancy) && path(['theme', 'phone'], companySettings) &&
                                                            <div><strong>{t('vacancy.terminal.companyPhone')}</strong> { path(['theme', 'phone'], companySettings) }</div>
                                                        }
                                                    </Fragment>
                                                }
                                                <CubeVacancyPreview vacancy={{ ...vacancy, companySettings }} />
                                            </Fragment>
                                        }
                                    </div>
                                </div>
                            </Fragment>
                        </Tabs.TabPane>
                    }
                    { vacancy.botPublished &&
                        <Tabs.TabPane key="dialog" tab={getTabName('dialog')}>
                            <div className='vacancy-block_description'>
                                <div className='vacancy-block_description-info'>
                                    <div><strong>{t('vacancy.form.published')}:</strong> { vacancy.botPublished ? t('vacancy.form.yes') : t('vacancy.form.no') }</div>
                                    { vacancy.botPublished &&
                                        <Fragment>
                                            <div>
                                                <strong>{t('vacancy.form.directOpenMessage')}:</strong>
                                                <ListenerField
                                                    listenFieldName='title'
                                                >
                                                    { ({ title }) => renderDialogField(pathOr(t('vacancy.placeholders.directOpenMessage', { vacancy: pathOr(title, ['title'], vacancy) }), ['chatSettings', 'directOpenMessage'], vacancy)) }
                                                </ListenerField>
                                            </div>
                                            <div>
                                                <strong>{t('vacancy.form.greetingMessage')}:</strong>
                                                { renderDialogField(pathOr(t('vacancy.placeholders.greetingMessage'), ['chatSettings', 'greetingMessage'], vacancy)) }
                                            </div>
                                            <div>
                                                <strong>{t('vacancy.form.farewellMessage')}:</strong>
                                                { renderDialogField(pathOr(t('vacancy.placeholders.farewellMessage'), ['chatSettings', 'farewellMessage'], vacancy)) }
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        </Tabs.TabPane>
                    }
                    { vacancy.sliderPublished &&
                        <Tabs.TabPane key="slider" tab={getTabName('slider')}>
                            <div className='vacancy-block_description'>
                                <div className='vacancy-block_description-info'>
                                    <div><strong>{t('vacancy.form.published')}:</strong> { vacancy.sliderPublished ? t('vacancy.form.yes') : t('vacancy.form.no') }</div>
                                </div>
                            </div>
                            { vacancy.sliderPublished && vacancy.banner &&
                                <Banner data={vacancy} qr={getBotsPageLink(vacancy.id, vacancy.user)} />
                            }
                        </Tabs.TabPane>
                    }
                    { !!pathOr([], ['items'], landings.data).length &&
                        <Tabs.TabPane key='landings' tab={getTabName('landings')}>
                            <Landings
                                vacancy={match.params.id}
                                vacancyTitle={vacancy.title}
                                pda={companySettings.pda}
                                view
                            />
                        </Tabs.TabPane>
                    }
                </Fragment>
            }
        </Tabs>
    </Fragment>;
}

const validationSchema = props => {
    let shape = {};

    switch (props.tab) {
        case 'general':
            shape = {
                title: yup.string().nullable().required(),
                code: yup.string().code(URL_CODE).nullable(),
                description: yup.string().nullable().min(6),
                salaryViewType: yup.string().nullable().required(),
                minSalary: yup.number().positive().nullable(),
                maxSalary: yup.number().positive().nullable()
                    .when('minSalary', {
                        is: val => !isNil(val),
                        then: yup.number().nullable().moreThan(yup.ref('minSalary'), props.t('vacancy.form.minSalaryValidation'))
                    }),
                salaryType: yup.string().nullable()
                    .when(
                        ['minSalary', 'maxSalary'],
                        (min, max) => !isNil(min) || !isNil(max)
                            ? yup.string().nullable().required()
                            : yup.string().nullable()
                    ),
            };

            break;
        case 'form':
            shape = {
                questions: yup.array()
                .of(
                    yup.object().shape({
                        question: yup.string().required(),
                        label: yup.string().required(),
                    })
                )
            };

            break;
        case 'terminal':
            shape = {
                terminalSettings: yup.object().shape({
                    showPhoneAllowed: yup.boolean().nullable()
                }),
                companySettings: yup.object().when('terminalSettings.showPhoneAllowed', (show, schema) =>
                    show ? schema.shape({
                        theme: yup.object().shape({
                            phone: yup.string().phone().nullable().required()
                        })
                    }) : schema
                )
            };

            break;
        default:
            break;
    }
    return yup.object().shape(shape);
};

const withRouter = WrappedComponent => props => {
    const location = useLocation();
    const history = useHistory();
    const params = useParams();

    return <WrappedComponent
        {...props}
        location={location}
        history={history}
        match={{
            params
        }} />;
}

export default withTranslation()(withRouter(
    withStateHandlers(
        props => ({
            tab: pathOr('general', ['match', 'params', 'tab'], props),
            edit: qs.parse(props.location.search, { ignoreQueryPrefix: true }).type === 'similar',
            tabSubmit: false
        }),
        {
            setEdit: () => (edit = true) => ({ edit }),
            setTab: () => tab => ({ tab }),
            setTabSubmit: ({ tab }) => (tabSubmit, nextTab) => ({ tabSubmit, tab: nextTab || tab })
        }
    )(
        withClassifiers(withFormWrapper(
            withUserCompany(VacancyForm),
            {
                mapPropsToValues,
                validationSchema,
                mapBeforeSubmit: (values, props) => ({
                    ...values,
                    salaryType: values.minSalary || values.maxSalary ? values.salaryType : null,
                    publishedAt: values.datePublishedStart ? values.datePublishedStart : values.createdAt,
                    code: addCompanyCode(values.code, props.companyCode),
                    address: path(['address', 'address'], values),
                    location: path(['address', 'location'], values),
                    questions: mapQuestions(values.questions),
                }),
                onSuccess: ({ setEdit, tabSubmit, t }) => {
                    if (!tabSubmit) {
                        setEdit(false);
                        message.success(t('vacancy.form.vacancySuccess'));
                    }
                }
            }
        ))
    )
));
