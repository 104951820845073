import form from './et/form.json';
import vacancy from './et/vacancy.json';
import applicant from './et/applicant.json';
import auth from './et/auth.json';
import banner from './et/banner.json';
import call from './et/call.json';
import chat from './et/chat.json';
import company from './et/company.json';
import domain from './et/domain.json';
import guest from './et/guest.json';
import landing from './et/landing.json';
import profile from './et/profile.json';
import settings from './et/settings.json';
import dialog from './et/dialog.json';
import userAdmin from './et/userAdmin.json';
import app from './et/app.json';
import bot from './et/bot.json';
import unit from './et/unit.json';
import reports from './et/reports.json';

export default {
    app,
    form,
    vacancy,
    applicant,
    auth,
    banner,
    call,
    chat,
    company,
    domain,
    guest,
    landing,
    profile,
    settings,
    dialog,
    userAdmin,
    bot,
    unit,
    reports,
};
