import React, { Fragment } from 'react';
import { prop } from 'ramda';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';

const QrBannerForm = () => {
    const { t } = useTranslation();

    return <Fragment>
        <Field
            name='name'
            component={Input}
            label={t('banner.name')} />
        <SubmitButton>{ t('form.save') }</SubmitButton>
    </Fragment>;
}

const validationSchema = yup.object().shape({
    name: yup.string().required()
});

export default withFormWrapper(QrBannerForm, {
    mapPropsToValues: prop('item'),
    validationSchema
});
