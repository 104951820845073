import React from 'react';
import { Checkbox } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

export const CheckboxComponent = props => {
    const onChange = e => {
        props.onChange(e.target.checked);
    };

    const { placeholder, input: { name, value }, disabled } = props;

    return <Checkbox id={name} checked={!!value} onChange={onChange} disabled={disabled}>{ placeholder }</Checkbox>;
}

export default withFieldWrapper(CheckboxComponent);
