import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation, withTranslation } from 'react-i18next';
import axios from 'axios';

import Table from './Table';
import Toolbar from './Toolbar';
import { LINES_PER_PAGE } from '../../constants/lists';
import ActionsContext from '../../contexts/ActionsContext';
import nanoid from '../../utils/nanoid';
import useCompanyCodeSwr from '../../utils/useCompanyCodeSwr';

const TableList = props => {
    const [idx] = useState(nanoid());
    const { addAction, removeAction } = useContext(ActionsContext);
    const { limit, offset, filters, sorting, initFilters, staticFilter = {}, relations, headers, actionUrl } = props;
    const urlParams = {
        pagination: {
            limit: limit || LINES_PER_PAGE,
            offset: offset || 0,
        },
        filter: {
            ...staticFilter,
            ...(filters || initFilters || {})
        },
        sorting,
        relations
    };
    const action = useCompanyCodeSwr([actionUrl, JSON.stringify(urlParams)], (url, params) => axios.get(url, { params: JSON.parse(params), headers }));

    useEffect(() => {
        addAction(`${actionUrl}_${idx}`, action);
        return () => removeAction(`${actionUrl}_${idx}`);
    }, []);

    useEffect(() => {
        addAction(`${actionUrl}_${idx}`, action);
    }, [JSON.stringify(urlParams)]);

    return (
        <ListTableStateless {...props} action={action} />
    );
}

TableList.defaultProps = {
    toolbarButtonSize: 8,
    toolbarFilterSize: 16,
};

const ListTableStateless = props => {
    const { t } = useTranslation();
    const location = useLocation();

    const renderError = () => {
        const { action } = props;

        return (
            <div className='table-error'>
                <span className='table-error-message'>
                    { t('app.tableError') }
                </span>
                <Button
                    className='table-error-reload-button'
                    shape='circle'
                    icon={<ReloadOutlined />}
                    size='small'
                    onClick={() => action.mutate()}
                />
            </div>
        );
    }

    const {
        action,
        setOffset,
        setLimit,
        setFilters,
        columns,
        className,
        buttons,
        title,
        filterForm : FilterForm,
        scroll,
        initFilters,
        onRow,
        rowSelection,
        getRowSelection,
        expandedRowRender,
        staticFilter,
        urlPrefix,
        parseItems,
        tableLayout,
        toolbarButtonSize,
        toolbarFilterSize,
    } = props;

    const filterForm = FilterForm && (
        <FilterForm
            initFilters={initFilters}
            staticFilter={staticFilter}
            urlPrefix={urlPrefix}
            setFilters={setFilters}
        />
    );

    const toolbar = (filterForm || buttons) && (
        <Toolbar
            title={title}
            filterForm={filterForm}
            buttons={buttons}
            toolbarButtonSize={toolbarButtonSize}
            toolbarFilterSize={toolbarFilterSize}
        />
    );

    return (
        <div className={className}>
            {toolbar}
            <Table
                tableLayout={tableLayout}
                action={action}
                setOffset={setOffset}
                setLimit={setLimit}
                columns={columns}
                scroll={scroll}
                onRow={onRow}
                location={location}
                rowSelection={getRowSelection ? getRowSelection(props) : rowSelection}
                expandedRowRender={expandedRowRender}
                urlPrefix={urlPrefix}
                parseItems={parseItems}
                footer={action.error && renderError}
                pagination={{
                    limit: props.limit,
                    offset: props.offset,
                }}
            />
        </div>
    );
}

const TableStateList = props => {
    const [offset, setStateOffset] = useState(0);
    const [limit, setStateLimit] = useState(props.limit || LINES_PER_PAGE);
    const [filters, setStateFilters] = useState(null);

    const setOffset = (offset, limit) => {
        setStateOffset(offset);
        setStateLimit(limit);
    }

    const setLimit = (limit = LINES_PER_PAGE) => {
        setStateLimit(limit);
    }

    const setFilters = (filters) => {
        setStateOffset(0);
        setStateFilters(filters);
    }

    return (
        <TableList
            {...props}
            offset={offset}
            limit={limit}
            filters={filters}
            setOffset={setOffset}
            setLimit={setLimit}
            setFilters={setFilters}
        />
    );
}

export default withTranslation()(TableStateList);
