import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const DomainLicenseInfoModal = props => {
    const { t } = useTranslation();
    const { modal } = props;

    return <Modal
        {...modal}
        footer={null}>
        <p>{ t('domain.domainLicenseInfo1') }</p>
        <p>{ t('domain.domainLicenseInfo2') }</p>
    </Modal>;
}

export default DomainLicenseInfoModal;
