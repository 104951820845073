import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import { prop } from 'ramda';
import * as yup from 'yup';
import { useTranslation, withTranslation } from 'react-i18next';

import Checkbox from './formComponents/Checkbox';
import Input from './formComponents/Input';
import FormSelect from './formComponents/FormSelect';
import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import ListenerField from './ListenerField';

const DomainForm = props => {
    const { t } = useTranslation();
    const { forms } = props;

    return (
        <Fragment>
            <Field
                name='title'
                component={Input}
                label={t('domain.name')}
                placeholder={t('domain.typeName')}
                disableClear
            />
            <Field
                name='name'
                component={Input}
                label={t('domain.domainAddress')}
                addonBefore='https://'
                placeholder={t('domain.typeDomainAddress')}
                disableClear
            />
            <ListenerField listenFieldName='name'>
                { ({ name }) =>
                    <Field
                        name='vacancyLink'
                        component={Input}
                        label={t('settings.link')}
                        placeholder={`https://${name || 'domain'}/\${id}`} />
                }
            </ListenerField>
            <Field
                wrapperClassName='question-checkbox'
                name='defaultActive'
                component={Checkbox}
                label={t('domain.defaultActive')}
            />
            <Field
                name='vacancyFormPreset'
                component={FormSelect}
                label={t('domain.formPresetWithVacancy')}
                options={forms}
                namePath='title'
                placeholder={t('domain.selectForm1')}
                allowClear
            />
            <Field
                name='domainFormPreset'
                component={FormSelect}
                label={t('domain.formPresetWithoutVacancy')}
                options={forms}
                namePath='title'
                placeholder={t('domain.selectForm1')}
                allowClear
            />
            <SubmitButton>
                { t('form.save') }
            </SubmitButton>
        </Fragment>
    );
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    title: yup.string().min(2).required(),
    domainFormPreset: yup.string().nullable(),
    vacancyFormPreset: yup.string().nullable(),
    vacancyLink: yup.string().nullable().matches(/\${id}/gi),
});

export default withTranslation()(withFormWrapper(DomainForm, {
    validationSchema,
    mapPropsToValues: prop('item'),
}));
