import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import BotForm from '../../forms/BotForm';
import { putBotHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { BOT } from '../../../constants/urls';

const BotModal = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const { modal, params: bot, close }  = props;

    return <Modal
        {...modal}
        title={t('bot.editBot')}
        footer={null}>
        <BotForm
            formAction={putBotHandler}
            isEdit
            bot={bot}
            onSuccess={() => {
                revalidateAction(BOT.stringify());
                message.success(t('bot.successSave'));
                close();
            }} />
    </Modal>;
}

export default BotModal;
