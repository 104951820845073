
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { __, compose, concat, includes, findIndex, flatten, pathEq, find, length, map, prop, propEq, path, pathOr, filter, take, toLower, trim, startsWith, fromPairs, forEach, any, is } from 'ramda';
import RSelect, { components } from 'react-select';
import { VariableSizeList as List } from 'react-window';
import styled from 'styled-components';
import cx from 'classnames';
import { useTranslation, withTranslation } from 'react-i18next';
import axios from 'axios';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import withUserCompany from '../../hocs/withUserCompany';
import { HEIGHT, HEIGHT_WITH_SMALL } from '../../../constants/select';
import withFormValues from '../../hocs/withFormValues';
import useCompanyCodeSwr, { useCompanyCodeSwrInfinite } from '../../../utils/useCompanyCodeSwr';
import usePrevious from '../../../utils/usePrevious';
import ActionsContext from '../../../contexts/ActionsContext';

const Option = styled.div`
    .search-input__option {
        height: ${({ small }) => small ? HEIGHT_WITH_SMALL : HEIGHT}px;
    }
`;

const SelectElement = props => {
    const { renderLabel, renderMultiLabel } = props;
    const { t } = useTranslation();
    const [allowDefaultOption, setAllowDefaultOption] = useState(props.allowDefaultOption);
    const [selectedMultiOptions, setSelectedMultiOptions] = useState([]);
    const select = useRef();
    const prevLoadInitialOptions = usePrevious(props.loadInitialOptions);

    const getOptionsHeight = childrens => {
        if (Array.isArray(childrens)) {
            const smallLength = length(filter(path(['props', 'data', 'small']), childrens));

            return smallLength * HEIGHT_WITH_SMALL + (childrens.length - smallLength) * HEIGHT;
        }

        return HEIGHT;
    }

    const getOffset = (childrens, option = {}) => {
        const index = findIndex(pathEq(['props', 'value'], option.value), childrens);

        return index < 0 ? 0 : getOptionsHeight(take(index, childrens));
    }

    const getMenuList = ({ children, maxHeight, getValue }) => {
        const [ value ] = getValue();
        const optionsHeight = getOptionsHeight(children);

        const listHeight = children ? optionsHeight > maxHeight ? maxHeight : optionsHeight : 0;
        const initialOffset = optionsHeight > maxHeight ? getOffset(children, value) : 0;

        return <List
            height={listHeight}
            itemCount={children.length || 1}
            itemSize={index => children[index] && path(['props', 'data', 'small'], children[index]) ? HEIGHT_WITH_SMALL : HEIGHT}
            initialScrollOffset={initialOffset}
        >
            { ({ index, style }) => <div style={style}>{ Array.isArray(children) ? children[index] : children }</div> }
        </List>;
    }

    const getOptions = () => {
        const { asyncAction, valueAction, options, isMulti } = props;
        const { valuePath, namePath, getNamePath, smallPath } = props;
        const { searchable, isOptionDisabled } = props;
        const itemCount = asyncAction && path(['asyncAction', 'data', '_meta', 'count'], props);
        const serverOptions = asyncAction && (path(['asyncAction', 'data', 'items'], props) || path(['asyncAction', 'data'], props));
        const resultOptions = serverOptions || options || [];
        const valueOptions = valueAction ? flatten(pathOr([], ['data'], valueAction)
                .map(page => page.items))
                .filter(item => !find(option => prop(valuePath, option) === prop(valuePath, item), resultOptions))
            : [];

        const listOptions = concat(valueOptions, resultOptions).map(item => ({
            value: prop(valuePath, item),
            label: asyncAction ? (getNamePath ? getNamePath(item) : prop(namePath, item)) : t(getNamePath ? getNamePath(item) : prop(namePath, item)),
            small: prop(smallPath, item),
            option: item,
            isDisabled: isOptionDisabled && isOptionDisabled(item),
        }))

        if (searchable && isMulti) {
            forEach(item => {
                if (!any(({ value }) => item.value === value, listOptions)) {
                    listOptions.push(item);
                }
            }, selectedMultiOptions);
        }

        if (searchable && serverOptions && (itemCount > listOptions.length)) {
            listOptions.push({
                label: '...',
                isDisabled: true,
                value: null,
            });
        }

        return listOptions;
    }

    const onChange = (event, { action }) => {
        const { isMulti, onChange, searchable, setSearch } = props;

        if (action !== 'clear') {
            if (isMulti) {
                if (searchable) {
                    setSelectedMultiOptions(event || []);
                    setSearch(null);
                }
                const value = map(prop('value'), event || []);
                onChange(value && value.length ? value : undefined, (event || []).map(({ option }) => option));
            } else {
                onChange(event.value, event.option);
            }
        } else {
            if (searchable) {
                setSelectedMultiOptions([]);
            }
            onChange(undefined);
        }
    }

    const getOption = props => {
        const { label, data: { small } } = props;

        return <Option small={small}>
            <components.Option {...props}>
                <div>
                    { renderLabel ? renderLabel(props) : label }
                </div>
                { small && <small>{ small }</small> }
            </components.Option>
        </Option>;
    }

    const getMultiValueLabel = props => {
        return <components.MultiValueLabel {...props}>
            { renderMultiLabel ? renderMultiLabel(props) : props.children }
        </components.MultiValueLabel>;
    }

    const onSearchChange = (value, { action }) => {
        const { setSearch, input, isMulti, autocomplete } = props;

        setSearch(value);

        if (autocomplete && action === 'input-change') {
            const options = filter(({ label }) => startsWith(toLower(trim(value)), toLower(label)), getOptions());

            if (options.length === 1 && (isMulti ? !includes(options[0].value, input.value || []) : options[0].value === input.value)) {
                select.current.select.selectOption(options[0]);
            }
        }
    }

    const getMenuPlacement = () => {
        const { smallPath } = props;
        const height = (smallPath ? HEIGHT_WITH_SMALL : HEIGHT) * 6;

        if (select && select.current) {
            const box = select.current.select.controlRef.getBoundingClientRect();
            const container = document.body.querySelector('.mainContainer');
            const coords = container.scrollHeight - (container.scrollTop + box.top);

            return coords < height ? 'top' : 'bottom';
        } else {
            return 'bottom';
        }
    }

    const { placeholder, input, disabled, searchable, search, allowClear, smallPath, isMulti, asyncAction } = props;
    const options = getOptions();

    const value = isMulti ?
        filter(compose(includes(__, input.value || []), prop('value')), options)
        : find(propEq('value', input.value), options);
    const pending = path(['asyncAction', 'isValidating'], props) || path(['valueAction', 'isValidating'], props) || props.loading;
    const lastSucceedAt = asyncAction ? !!path(['asyncAction', 'data'], props) : true;

    useEffect(() => {
        const { isOptionDisabled, valuePath, smallPath, getNamePath, namePath, initialOptions, asyncAction, loadInitialOptions } = props;

        setSelectedMultiOptions((loadInitialOptions || initialOptions || []).map(item => ({
            value: prop(valuePath, item),
            label: asyncAction ? (getNamePath ? getNamePath(item) : prop(namePath, item)) : t(getNamePath ? getNamePath(item) : prop(namePath, item)),
            small: prop(smallPath, item),
            option: item,
            isDisabled: isOptionDisabled && isOptionDisabled(item),
        })));
    }, []);

    useEffect(() => {
        const { isOptionDisabled, valuePath, smallPath, getNamePath, namePath, asyncAction, loadInitialOptions } = props;

        if (!prevLoadInitialOptions && !!loadInitialOptions) {
            setSelectedMultiOptions(loadInitialOptions.map(item => ({
                value: prop(valuePath, item),
                label: asyncAction ? (getNamePath ? getNamePath(item) : prop(namePath, item)) : t(getNamePath ? getNamePath(item) : prop(namePath, item)),
                small: prop(smallPath, item),
                option: item,
                isDisabled: isOptionDisabled && isOptionDisabled(item),
            })));
        }
    }, [props.loadInitialOptions]);

    useEffect(() => {
        if (allowDefaultOption && (is(Number, props.allowDefaultOption) ? options.length === props.allowDefaultOption : options.length)) {
            const option = options[0];
            const value = prop('value', option);

            setTimeout(() => props.onChange(props.isMulti ? [value] : value, option));
            setAllowDefaultOption(false);
        }
    }, [allowDefaultOption, options]);

    return (
        <RSelect
            id={input.name}
            ref={select}
            classNamePrefix='search-input'
            className={cx('search-input', { 'search-multi': isMulti })}
            value={value || null}
            components={{
                MenuList: getMenuList,
                Option: getOption,
                MultiValueLabel: getMultiValueLabel,
                IndicatorSeparator: () => null,
                LoadingIndicator: () => <Spin size='small' />,
                NoOptionsMessage: () => <div className='no-options'>{ t('form.notFound') }</div>
            }}
            options={options}
            onChange={onChange}
            isLoading={searchable ? pending : !lastSucceedAt && pending}
            placeholder={placeholder}
            isClearable={allowClear}
            isSearchable={searchable}
            isMulti={isMulti}
            isDisabled={disabled}
            maxMenuHeight={(smallPath ? HEIGHT_WITH_SMALL : HEIGHT) * 6}
            inputValue={search || ''}
            onInputChange={onSearchChange}
            loadingMessage={() => t('form.loading')}
            menuPlacement={getMenuPlacement()}
        />
    );
}

SelectElement.defaultProps = {
    options: [],
    placeholder: '',
    searchable: false,
    valuePath: 'id',
    namePath: 'value'
};

export const SelectComponent = withTranslation()(SelectElement);

export const Select = withUserCompany(withFormValues(props => {
    const { filter, searchKey, filterBy, values, searchable, input: { value }, actionUrl, actionUrlParams, sorting, relations, dataParse, swr } = props;
    const [search, setSearch] = useState('');
    const { i18n } = useTranslation();
    const { addAction, removeAction } = useContext(ActionsContext);

    const urlParams = {
        filter: {
            company: props.companyId,
            ...filter,
            [searchKey || 'value']: searchable ? search || undefined : undefined,
            ...(filterBy ? fromPairs(filterBy.map(key => ([ key, values[key] ]))) : {})
        },
        pagination: {
            offset: 0,
            limit: 50,
        },
        sorting,
        relations,
        locale: i18n.language,
    };
    const selectUrl = is(Function, actionUrl) ? actionUrl(urlParams) : actionUrl;
    const asyncAction = actionUrl ? (swr || useCompanyCodeSwr)([selectUrl, JSON.stringify(actionUrlParams || urlParams)], (url, params) =>
        axios.get(url, { params: JSON.parse(params) }).then(data => dataParse ? dataParse(data) : data)
    ) : null;

    const valueArray = value ? is(Array, value) ? value : [value] : [];
    const valueAction = actionUrl ? useCompanyCodeSwrInfinite((pageIndex, data, companyCode) => {
        const val = valueArray[pageIndex] || null;
        const valueParams = {
            filter: {
                company: props.companyId,
                [props.valuePath || 'id']: val,
            },
            pagination: {
                offset: 0,
                limit: 1,
            },
            relations,
            locale: i18n.language,
        };

        return val && [selectUrl, JSON.stringify(valueParams), companyCode];
    }, (url, params) =>
        axios.get(url, { params: JSON.parse(params) }).then(data => dataParse ? dataParse(data) : data),
        { initialSize: value ? is(Array, value) ? value.length : 1 : 0, revalidateOnMount: true }
    ) : null;

    useEffect(() => {
        if (valueAction && (valueAction.size !== valueArray.length)) {
            valueAction.setSize(valueArray.length);
        }
    }, [value]);

    useEffect(() => {
        !!asyncAction && addAction(selectUrl, asyncAction);
        return () => !!asyncAction && removeAction(selectUrl);
    }, [pathOr(0, ['data', 'items', 'length'], asyncAction)]);

    return <SelectComponent
        {...props}
        search={search}
        setSearch={setSearch}
        asyncAction={asyncAction}
        valueAction={valueAction} />;
}));

export default withFieldWrapper(Select);
