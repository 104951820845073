import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { LeftOutlined, ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import ERRORS, { UNABLE_TO_SHOW } from '../constants/errors';

const Error = props => {
    const { t } = useTranslation();
    const history = useHistory();
    const { error, statusCode } = props.error;
    const { refresh } = props;

    return (
        <div className='error-component'>
            { statusCode && <h1 className='error-component-code'>{statusCode}</h1> }
            { error && <h2 className='error-component-title'>{t(ERRORS[error] || UNABLE_TO_SHOW)}</h2> }
            <div className='error-buttons'>
                <Button
                    className='error-component-button'
                    onClick={history.goBack}
                    type='primary'
                    icon={<LeftOutlined />}
                >
                    { t('app.back') }
                </Button>
                <Button
                    className='error-component-button'
                    onClick={() => refresh()}
                    type='primary'
                    icon={<ReloadOutlined/>}
                >
                    { t('app.refresh') }
                </Button>
            </div>
        </div>
    );
}

export default Error;
