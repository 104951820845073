import React from 'react';
import { Badge, Tooltip } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { PUBLISHED_TOOLTIPS } from '../../constants/vacancy';

const Container = styled.div`
    margin-left: 7px;
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
    height: 20px;
    .ant-badge-not-a-wrapper {
        vertical-align: baseline;
    }
`;

const VacancyPublishedSwitch = props => {
    const { t } = useTranslation();

    const onChange = () => {
        const { checked, onChange } = props;

        onChange(!checked);
    }

    const { checked, path, disabled, getTitle } = props;

    return <Tooltip title={getTitle ? getTitle(path) : `${checked ? t('vacancy.list.noShow') : t('vacancy.list.show')} ${t(PUBLISHED_TOOLTIPS[path])}`}>
        <Container className='vacancy-published-switch' onClick={onChange} disabled={disabled}>
            <Badge status={checked ? 'success' : 'default'} />
        </Container>
    </Tooltip>;
}

export default VacancyPublishedSwitch;
