import React, { useEffect } from 'react';
import { equals } from 'ramda';
import { useHistory, useLocation } from 'react-router-dom';
import { Form } from 'react-final-form';

import { extendSearchPath } from '../../../utils/urlParams';
import InitialValuesContext from '../../../contexts/InitialValuesContext';
import usePrevious from '../../../utils/usePrevious';

function withFilters(WrappedComponent) {
    const WithFilter = props => {
        const prevValues = usePrevious(props.values);

        useEffect(() => {
            if (!props.isSubmitOnly && prevValues && !equals(props.values, prevValues)) {
                if (props.setFilters) {
                    props.setFilters(props.values);
                } else {
                    setLocation();
                }
            }
        }, [props.values, prevValues, props.isSubmitOnly]);

        const setLocation = () => {
            const { location, history: { replace }, urlPrefix } = props;
            const params = {
                filter: JSON.stringify(props.values),
                offset: 0
            };

            replace(extendSearchPath(location, urlPrefix ? { [urlPrefix]: params } : params));
        }

        return (
            <div className='filter-form'>
                <WrappedComponent {...props} setLocation={setLocation} />
            </div>
        );
    }

    const WithForm = props => {
        const { initFilters, staticFilter } = props;
        const location = useLocation();
        const history = useHistory();

        return (
            <InitialValuesContext.Provider value={initFilters}>
                <Form
                    initialValues={{
                        ...staticFilter,
                        ...initFilters
                    }}
                    onSubmit={() => {}}
                    render={formProps =>
                        <WithFilter {...formProps} {...props} location={location} history={history} />
                    }
                />
            </InitialValuesContext.Provider>
        );
    }

    return WithForm;
}

export default WrappedComponent => withFilters(WrappedComponent);
