import React, { useContext, useState } from 'react';
import { Modal, Row, Col, message } from 'antd';
import { useTranslation } from 'react-i18next';

import BotForm from '../../forms/BotForm';
import ViberBotForm from '../../forms/ViberBotForm';
import TelegramBotForm from '../../forms/TelegramBotForm';
import FacebookBotForm from '../../forms/FacebookBotForm';
import { postBotHandler, putBotSubscribeHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { BOT } from '../../../constants/urls';

const forms = {
    facebook: FacebookBotForm,
    viber: ViberBotForm,
    telegram: TelegramBotForm,
    web: BotForm
};

const TYPES = ['facebook', 'viber', 'telegram', 'web'];

const BotCreateModal = props => {
    const [type, setType] = useState();
    const { revalidateAction } = useContext(ActionsContext);
    const { t } = useTranslation();
    const { modal } = props;

    const renderBotSelector = () => {
        return <div className='bot-modal-selector'>
            <h3>{ t('bot.chooseBot') }</h3>
            <Row type='flex' gutter={16} justify='center' align='middle'>
                { TYPES.map(type =>
                    <Col key={type} xs={24} md={12}>
                        <div
                            className={`box-messenger ${type}-box-messenger`}
                            onClick={() => setType(type)}>
                            <i className={`icon ${type}`} />
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    }

    const renderForm = () => {
        const { close } = props;
        const Form = forms[type];

        return <Form
            formAction={postBotHandler}
            bot={{ type }}
            close={close}
            onSuccess={(_, data) => {
                revalidateAction(BOT.stringify());
                putBotSubscribeHandler(data.id)
                    .then(() => {
                        revalidateAction(BOT.stringify());
                        message.success(t('bot.successOn'));
                    })
                    .catch(() => message.error(t('bot.errorOn')));

                message.success(t('bot.successAdd'));

                if (data.type !== 'facebook') {
                    close();
                }
            }}
        />;
    }

    return <Modal
        {...modal}
        title={type ? t('bot.addBotType', { type }) : t('bot.addBot')}
        footer={null}>
        { type ? renderForm() : renderBotSelector() }
    </Modal>;
}

export default BotCreateModal;
