import React, { useContext, useState } from 'react';
import { Modal, List, Button, Popconfirm, message } from 'antd';
import { without, pathOr } from 'ramda';
import { Link } from 'react-router-dom';
import { MinusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ActionsContext from '../../../contexts/ActionsContext';
import { putLandingHandler } from '../../../actions/handlers';
import { LANDING } from '../../../constants/urls';
import { CHANGE_LANDING, OPEN_LANDING_LINK_VACANCIES_MODAL } from '../../../constants/actionTypes';
import ModalsContext from '../../../contexts/ModalsContext';

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 5px 24px;
    }
`;

const LinkButton = styled(Button)`
    position: absolute;
    top: 12px;
    right: 56px;
`;

const LandingLinkedVacanciesModal = props => {
    const { t } = useTranslation();
    const [vacancies, setVacancies] = useState(props.params.vacancies || []);
    const [pending, setPending] = useState(false);
    const { revalidateAction, onSuccessFn } = useContext(ActionsContext);
    const modals = useContext(ModalsContext);

    const unlink = vacancy => {
        const { params: { landing } } = props;
        const items = without([vacancy], vacancies.map(({ id }) => id));

        setPending(true);
        putLandingHandler({
            ...landing,
            vacancies: items.length ? items : null
        }).then(data => {
            setPending(false);
            onSuccessFn(CHANGE_LANDING);
            revalidateAction(LANDING.stringify());
            setVacancies(pathOr([], ['_relations', 'vacancies'], data));
            message.success(t('landing.unlinkSuccess'));
        }).catch(() => setPending(false));
    }

    const { modal, params: { landing }} = props;

    return <StyledModal
        {...modal}
        className='landing-linked-vacancies-modal'
        title={t('landing.linkedVacancies')}
        footer={null}
        destroyOnClose>
        <LinkButton className='link-vacancy-btn' onClick={() => modals.open(OPEN_LANDING_LINK_VACANCIES_MODAL, { vacancies, landing, setVacancies })}>
            { t('landing.link') }
        </LinkButton>
        <List
            loading={pending}
            dataSource={vacancies}
            locale={{
                emptyText: t('landing.noLinkedVacancies')
            }}
            renderItem={item => (
                <List.Item actions={[
                    <Popconfirm
                        key='unlink-btn'
                        title={t('landing.unlinkConfirm')}
                        placement='topRight'
                        onConfirm={() => unlink(item.id)}
                        okText={t('landing.yes')}
                        cancelText={t('landing.no')}>
                        <Button icon={<MinusOutlined />} disabled={pending} shape='circle' />
                    </Popconfirm>
                ]}>
                    <Link to={`/vacancy/${item.id}`}>{ item.title }</Link>
                </List.Item>
            )}
        />
    </StyledModal>;
}

export default LandingLinkedVacanciesModal;
