import React from 'react';
import { postCityDictionaryHandler, putCityDictionaryHandler, deleteCityDictionaryHandler } from '../../../../actions/handlers';

import { CITY_DICTIONARY } from '../../../../constants/urls';
import DictionaryTable from './DictionaryTable';

const CitiesDictionary = () => {
    return <DictionaryTable
        actionUrl={CITY_DICTIONARY.stringify()}
        relations={['region', 'country']}
        putAction={putCityDictionaryHandler}
        postAction={postCityDictionaryHandler}
        deleteAction={deleteCityDictionaryHandler}
        priorityField
        countryField
        regionField />;
}

export default CitiesDictionary;
