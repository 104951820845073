import React, { Fragment } from 'react';
import { prop, path } from 'ramda';

import withFormWrapper from '../../hocs/withFormWrapper';
import DialogVacancyFields from './DialogVacancyFields';
import SubmitButton from '../formComponents/SubmitButton';
import { useTranslation } from 'react-i18next';

const DialogVacancyForm = props => {
    const { t } = useTranslation();

    return <Fragment>
    <DialogVacancyFields {...props} />
        <div style={{ marginTop: 15 }}>
            <SubmitButton>{ t('form.save') }</SubmitButton>
        </div>
    </Fragment>;
}

export default withFormWrapper(DialogVacancyForm, {
    mapPropsToValues: prop('vacancy'),
    mapBeforeSubmit: values => ({
        ...values,
        address: path(['address', 'address'], values),
        location: path(['address', 'location'], values),
    })
});
