export const document = {
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    html: 'text/html',
    odt: 'application/vnd.oasis.opendocument.text',
    pdf: 'application/pdf',
    rtf: 'application/rtf, text/rtf, .rtf',
    txt: 'text/plain',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const image = {
    png: 'image/png',
    jpg: 'image/jpeg',
    tif: 'image/tiff',
};
