import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Checkbox, message, Tooltip } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { append, contains, includes, pathOr, without, isNil, mapObjIndexed, filter } from 'ramda';
import { PlusOutlined, DownloadOutlined, CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined, EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { ADMIN, ADMIN_COMPANY } from '../../../constants/roles';
import { getFullName } from '../../../utils/fieldsHelpers';
import { getUrlParams, getUrlWithFilters } from '../../../utils/urlParams';
import withUserCompany from '../../hocs/withUserCompany';
import UserAdminFilter from '../../filters/UserAdminFilter';
import AdminFilter from '../../filters/AdminFilter';
import UserRolesList from '../../table/UserRolesList';
import TableList from '../../table/TableList';
import Mail from '../../table/Mail';
import Phone from '../../table/Phone';
import EnabledSwitch from '../../table/EnabledSwitch';
import VacancyPublishedSwitch from '../../table/VacancyPublishedSwitch';
import { USER_PUBLISHED_TOOLTIPS } from '../../../constants/vacancy';
import { USER_PUBLICATIONS } from '../../../constants/company';
import { putUserAdminHandler, putUserAdminSettingsHandler } from '../../../actions/handlers';
import { USERS_ADMIN, USER_ADMIN_STATUS } from '../../../constants/urls';
import ActionsContext from '../../../contexts/ActionsContext';
import ModalsContext from '../../../contexts/ModalsContext';
import { OPEN_USER_ADMIN_MODAL, OPEN_USER_IMPORT_MODAL } from '../../../constants/actionTypes';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const PublishedSwitchers = styled.div`
    display: flex;
    justify-content: center;
    button {
        margin-right: 8px;
    }
`;

const UserPublicationsBlock = styled.div`
    padding: 15px;
    text-align: right;
    border-bottom: 1px solid #e8e8e8;
    button {
        margin-left: 15px;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner:after {
        background-color: #aaa;
    }
`;

const PublishedSwitch = props => {
    const { t } = useTranslation();

    return <VacancyPublishedSwitch {...props} getTitle={path => t(USER_PUBLISHED_TOOLTIPS[path])} />;
};

const UserPublications = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const [statuses, setStatuses] = useState({});
    const [savePending, setSavePending] = useState(false);
    const usersAdminStatus = useCompanyCodeSwr([USER_ADMIN_STATUS.stringify(), props.selected, props.all, props.excluded], (url, selected, all, excluded) => axios.get(url, {
        params: {
            filter: {
                all,
                users: all ? excluded : selected
            }
        }
    }), {
        onSuccess: data => setStatuses(pathOr({}, ['publicationSettings'], data))
    });

    const isIndeterminate = key => {
        return statuses[key] === null;
    }

    const onChange = (e, key) => {
        setStatuses({
            ...statuses,
            [key]: isIndeterminate(key) ? false : e.target.checked
        });
    }

    const save = () => {
        const { all, excluded, selected } = props;

        setSavePending(true);
        putUserAdminSettingsHandler({
            all,
            users: all ? excluded : selected,
            publicationSettings: mapObjIndexed(status => isNil(status) ? undefined : !!status, statuses)
        }).then(() => {
            revalidateAction(USERS_ADMIN.stringify())
            message.success(t('userAdmin.userRightsSaveSuccess'));
            setStatuses({});
            setSavePending(false);
            props.onCancel();
        }).catch(() => {
            setSavePending(false);
            message.error(t('userAdmin.userRightsSaveError'));
        });
    }

    const { onCancel, all, selected, isAdmin, isFunctionalLicense } = props;
    const disabled = !all && !selected.length || usersAdminStatus.isValidating;

    return <UserPublicationsBlock>
        { filter(({ key }) => includes(key, ['bot', 'terminal']) ? !(isFunctionalLicense && !isAdmin) : true, USER_PUBLICATIONS).map(item =>
            <Checkbox
                key={`user-publication-${item.key}`}
                indeterminate={isIndeterminate(item.key)}
                checked={!!statuses[item.key]}
                disabled={disabled}
                onChange={e => onChange(e, item.key)}>
                { t(item.label) }
            </Checkbox>
        )}
        <Button type='primary' disabled={disabled} loading={savePending} onClick={save}>
            { t('form.save') }
        </Button>
        <Button
            onClick={onCancel} disabled={disabled}>
            { t('userAdmin.cancel') }
        </Button>
    </UserPublicationsBlock>;
}

const UsersAdmin = props => {
    const { t } = useTranslation();
    const [all, setAll] = useState(false);
    const [selected, setSelected] = useState([]);
    const [excluded, setExcluded] = useState([]);
    const location = useLocation();
    const modals = useContext(ModalsContext);

    const onSelectAll = e => {
        setAll(e.target.checked);
        setSelected([]);
        setExcluded([]);
    };

    const onSelect = e => {
        if (all) {
            setExcluded(e.target.checked ? without([e.target.value], excluded) : append(e.target.value, excluded));
        } else {
            setSelected(e.target.checked ? append(e.target.value, selected) : without([e.target.value], selected))
        }
    };

    const clearSelected = () => {
        setAll(false);
        setSelected([]);
        setExcluded([]);
    };

    useEffect(() => {
        clearSelected();
    }, [getUrlParams(location).filter]);

    const getColumns = (isAdmin, isFullLicense) => {
        const { isFunctionalLicense } = props;

        return [
            {
                title: '',
                key: 'active',
                align: 'center',
                render: item => !item.enabled ? (
                    <Tooltip title={t('userAdmin.blocked')}>
                        <CloseCircleOutlined />
                    </Tooltip>
                ) : item.activated ? (
                    <Tooltip title={t('userAdmin.active')}>
                        <CheckCircleOutlined />
                    </Tooltip>
                ) : (
                    <Tooltip title={t('userAdmin.inactive')}>
                        <QuestionCircleOutlined />
                    </Tooltip>
                )
            },
            {
                title: t('userAdmin.user'),
                key: 'fullName',
                render: user =>
                    <Link className='user-link' to={`/user/${user.id}`}>
                        { getFullName(user) }
                    </Link>
            },
            (isAdmin && props.staticFilter.hasCompany) ? {
                title: t('userAdmin.company'),
                key: 'company',
                dataIndex: ['_relations', 'company'],
                render: (company) => company ? <Fragment>
                    <Link className='company-link' to={getUrlWithFilters(props.allowUserPublications ? '/settings/users' : '/users', { company: company.id })}>
                        { company.name }
                    </Link>
                </Fragment> : null,
            } : null,
            (props.staticFilter.hasCompany) ? {
                title: t('company.juristicEntity'),
                key: 'juristicEntity',
                dataIndex: ['_relations', 'juristicEntity'],
                render: (juristicEntity) => juristicEntity ? <Fragment>
                    <Link className='company-link' to={getUrlWithFilters(props.allowUserPublications ? '/settings/users' : '/users', { juristicEntity: juristicEntity.id })}>
                        { juristicEntity.title }
                    </Link>
                </Fragment> : null,
            } : null,
            {
                title: 'Email',
                key: 'username',
                dataIndex: 'username',
                render: value => <Mail value={value} />,
            },
            {
                title: t('userAdmin.phone'),
                key: 'phone',
                dataIndex: 'phone',
                render: value => <Phone value={value} />,
            },
            {
                title: t('userAdmin.role'),
                key: 'roles',
                dataIndex: 'roles',
                render: roles => <UserRolesList roles={roles} />
            },
            {
                title: t('bot.subscribed'),
                key: 'enabled',
                render: item => {
                    return (
                        <EnabledSwitch item={item} action={putUserAdminHandler} path='enabled' revalidateAction={USERS_ADMIN.stringify()} />
                    );
                }
            },
            (isFullLicense || isFunctionalLicense || isAdmin) && ({
                title: t('userAdmin.publicationEnabled'),
                key: 'publicationSettings',
                align: 'center',
                render: item => <PublishedSwitchers>
                    <EnabledSwitch item={item} action={putUserAdminHandler} path='publicationSettings.domain' SwitchComponent={PublishedSwitch} revalidateAction={USERS_ADMIN.stringify()} />
                    { !(isFunctionalLicense && !isAdmin) && <EnabledSwitch item={item} action={putUserAdminHandler} path='publicationSettings.bot' SwitchComponent={PublishedSwitch} revalidateAction={USERS_ADMIN.stringify()} /> }
                    { !(isFunctionalLicense && !isAdmin) && <EnabledSwitch item={item} action={putUserAdminHandler} path='publicationSettings.terminal' SwitchComponent={PublishedSwitch} revalidateAction={USERS_ADMIN.stringify()} />}
                    <EnabledSwitch item={item} action={putUserAdminHandler} path='publicationSettings.landing' SwitchComponent={PublishedSwitch} revalidateAction={USERS_ADMIN.stringify()} />
                </PublishedSwitchers>
            }),
            {
                key: 'actions',
                render: user =>
                    <Button onClick={() => modals.open(OPEN_USER_ADMIN_MODAL, {
                        user,
                        isFullLicense: isFullLicense || isAdmin,
                        hasCompany: !contains(ADMIN, user.roles) && !contains(ADMIN_COMPANY, user.roles)
                    })}>
                        <EditOutlined />
                    </Button>
            }
        ].filter(Boolean);
    }

    const renderButtons = () => {
        const { isManager, isAdmin, companyCode } = props;

        return (
            <div className='company-dictionaries-buttons'>
                { (isManager || isAdmin && companyCode) && (
                    <Button
                        icon={<DownloadOutlined />}
                        onClick={() => modals.open(OPEN_USER_IMPORT_MODAL)}
                    >
                        { t('userAdmin.import') }
                    </Button>
                )}
                { (isManager || isAdmin) && (
                    <Button
                        className='toolbar-button'
                        type='primary'
                        icon={<PlusOutlined />}
                        onClick={() => modals.open(OPEN_USER_ADMIN_MODAL, {
                            ...(props.staticFilter || {}),
                            isFullLicense: props.isFullLicense || isAdmin,
                        })}>
                        { t('userAdmin.add') }
                    </Button>
                )}
            </div>
        );
    }

    const renderAfterToolbar = () => {
        const { allowUserPublications, isAdmin, isFunctionalLicense } = props;

        return allowUserPublications ?
            <UserPublications
                onCancel={clearSelected}
                selected={selected}
                all={all}
                excluded={excluded}
                isAdmin={isAdmin}
                isFunctionalLicense={isFunctionalLicense} /> :
            null;
    }

    const { isAdmin, isFullLicense, staticFilter, initFilters, allowUserPublications } = props;

    return <TableList
        actionUrl={USERS_ADMIN.stringify()}
        columns={getColumns(isAdmin, isFullLicense)}
        buttons={renderButtons()}
        afterToolbar={renderAfterToolbar()}
        filterForm={staticFilter.hasCompany ? UserAdminFilter : AdminFilter}
        initFilters={initFilters}
        staticFilter={staticFilter}
        getRowSelection={(props, filters) => allowUserPublications ? {
            selected,
            onSelectAll: onSelectAll,
            type: 'checkbox',
            columnTitle: () => <Checkbox checked={all} disabled={!!filters.name} onChange={onSelectAll} />,
            renderCell: (checked, record) => {
                return <Checkbox
                    onChange={onSelect}
                    value={record.id}
                    checked={all ? !includes(record.id, excluded) : includes(record.id, selected)} />;
            }
        } : null}
        relations={['company', 'juristicEntity']}
        sorting={{
            field: 'createdAt',
            order: 'desc',
        }}
    />;
}

UsersAdmin.defaultProps = {
    staticFilter: {
        hasCompany: true,
    },
    initFilters: {
        enabled: true,
    }
};

export default withUserCompany(UsersAdmin);
