import { pathOr, split, head, contains } from 'ramda';
import { chatFilterLimit } from '../constants/chat';
import { fileTypes } from '../constants/webchatMedia';
import nanoid from './nanoid';

export const getUserId = (botName) => localStorage.getItem(`webChatUserId_${botName}`);
export const createUserId = (botName) => {
    const userId = nanoid();
    localStorage.setItem(`webChatUserId_${botName}`, userId);
    return userId;
};
export const removeUserId = (botName) => localStorage.removeItem(`webChatUserId_${botName}`);

export const downloadMessages = (action, options, prevScrollHeight) => {
    const { dispatch, data: { items = [], payloadItemsLength, _meta = {} } } = action;
    const { count } = _meta;
    const { bot, dialog } = pathOr({}, ['params'], options.match);
    const { botToken, userId, previousCursor } = options;
    const notLastItems = payloadItemsLength ? payloadItemsLength !== count : items.length !== count;

    if (notLastItems && previousCursor) {
        dispatch({
            bot,
            dialog,
            filter: {
                botToken,
                userId,
                cursor: previousCursor,
                limit: chatFilterLimit,
                direction: 'previous',
            },
            prevScrollHeight
        });
    }
}

export const getFileType = (mimeType) => {
    const fileType = head(split('/', mimeType));

    return contains(fileType, fileTypes) ? fileType : 'file';
}

export const getMenuText = menuText => `${menuText || ''}`.replace(/&nbsp;/g, '');
