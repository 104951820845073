import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import resources from '../translations';
import { addDefaultHeader } from './http';
import { buildYupLocale } from './validation';

const whitelist = ['ru', 'ua', 'en', 'et', 'lt', 'lv'];

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['cookie', 'localStorage', 'sessionStorage', 'querystring', 'navigator', 'htmlTag'],
        },
        resources,
        fallbackLng: 'ru',
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        },
        whitelist,
    }, buildYupLocale);

export const changeLanguage = (lang, t) => {
    if (whitelist.indexOf(lang) !== -1) {
        addDefaultHeader('Accept-Language', lang);
        localStorage.setItem('i18nextLng', lang);
        i18n.changeLanguage(lang);
        buildYupLocale(null, t);
        window.location.reload();
    }
}

export default i18n;
