import React from 'react';
import { contains, find, path, prop, propEq } from 'ramda';
import { useTranslation } from 'react-i18next';

import { getOptionsValue } from '../../../../utils/applicant';

export const getTranslateOption = (item, language = 'en') => (
    path(['translations', language], item)
    || path(['translations', 'name', language], item) || prop('name', item)
    || path(['translations', 'value', language], item) || prop('value', item)
);

const CorrectValuesView = ({ type, field, correctValues, correctValuesItems, settings, language }) => {
    const { t } = useTranslation();

    return correctValues && correctValues.length ? correctValues.map((value, index) => {
        let displayValue = value;
        let item;
        switch(type) {
            case 'company_dictionary':
            case 'dictionary':
            case 'country':
            case 'region':
            case 'city':
                item = find(propEq('id', value), correctValuesItems || []);
                displayValue = getTranslateOption(item, language);
                break;
            case 'choice':
                displayValue = getOptionsValue(value, settings.choices);
                break;
            case 'money':
            case 'date': {
                let formatRangeValueOption = value => type === 'date' ?
                    value === 'now' ? t('applicant.rangeNow') : value && contains('T', value) ? new Date(value).toLocaleDateString('ru') : value
                    : value;
                let valuesRange = `${value.minValue ? `${t('applicant.rangeFrom')} ${formatRangeValueOption(value.minValue)}` : ''}`
                    + `${value.maxValue ? ` ${t('applicant.rangeTo')} ${formatRangeValueOption(value.maxValue)}` : ''}`;
                displayValue = valuesRange;
                break;
            }
        }

        return (
            <span key={`${field}-${value}`}>
                {displayValue}{index + 1 === correctValues.length ? '' : ', '}
            </span>
        );
    }) : null;
};

export default CorrectValuesView;
