import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { Row, Col, Button } from 'antd';
import styled from 'styled-components';

import ImageLoader from './formComponents/ImageLoader';
import Input from './formComponents/Input';
import Banner from '../views/banners/Banner';
import ColorPicker from './formComponents/ColorPicker';
import RadioGroup from './formComponents/RadioGroup';
import { QR_OR_BUTTON } from '../../constants/banner';
import LogoLoader from './formComponents/LogoLoader';
import { useTranslation } from 'react-i18next';

import { COMPANY_SETTINGS } from '../../constants/urls';
import useCompanyCodeSwr from '../../utils/useCompanyCodeSwr';

const ChangeButton = styled(Button)`
    margin-bottom: 20px;
`;

const labelCol = {
    xs: { span: 24 },
    sm: { span: 8 },
};

const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 16 },
};

const BannerFields = props => {
    const { t } = useTranslation();
    const companySettings = useCompanyCodeSwr(COMPANY_SETTINGS.stringify());
    const { vacancies, qr, isUnit, resetTemplate } = props;

    return <Row type='flex' gutter={16}>
        <Col span={12}>
            <ChangeButton id='change-template-btn' type='primary' onClick={resetTemplate}>{ t('banner.changeTemplate') }</ChangeButton>
            <Field
                name='banner.bg'
                component={ImageLoader}
                label={t('banner.background')}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                hidePreview />
            { !!companySettings.data &&
                <Field
                    name='companySettings.logo'
                    component={LogoLoader}
                    label={t('banner.logo')}
                    labelCol={labelCol}
                    wrapperCol={wrapperCol}
                    hidePreview />
            }
            <Field
                name='banner.title'
                component={Input}
                label={t('banner.title')}
                labelCol={labelCol}
                wrapperCol={wrapperCol} />
            <Field
                name='banner.bgColor'
                component={ColorPicker}
                label={t('banner.backgroundColor')}
                labelCol={labelCol}
                wrapperCol={wrapperCol} />
            <Field
                name='banner.titleColor'
                component={ColorPicker}
                label={t('banner.titleColor')}
                labelCol={labelCol}
                wrapperCol={wrapperCol} />
            <Field
                name='banner.descriptionColor'
                component={ColorPicker}
                label={t('banner.descriptionColor')}
                labelCol={labelCol}
                wrapperCol={wrapperCol} />
            <Field
                name='banner.showQr'
                component={RadioGroup}
                options={QR_OR_BUTTON}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                label={t('banner.show')} />
            <FormSpy subscription={{ values: true }}>
                { ({ values }) =>
                    values.banner.showQr ?
                        <Field
                            name='banner.qrBg'
                            component={ColorPicker}
                            label={t('banner.qrBackground')}
                            labelCol={labelCol}
                            wrapperCol={wrapperCol}
                            disableClear /> :
                        <Field
                            name='banner.buttonText'
                            component={Input}
                            label={t('banner.buttonText')}
                            labelCol={labelCol}
                            wrapperCol={wrapperCol}
                            disableClear />
                }
            </FormSpy>
        </Col>
        <Col span={12}>
            <FormSpy subscription={{ values: true }}>
                { ({ values }) =>
                    <Banner data={values} vacancies={vacancies} qr={qr} isUnit={isUnit} isEdit />
                }
            </FormSpy>
        </Col>
    </Row>;
}

export default BannerFields;
