import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import CompanyDictionaryImportForm from '../../../forms/CompanyDictionaryImportForm';
import { postCompanyDictionaryFileHandler } from '../../../../actions/handlers';
import ActionsContext from '../../../../contexts/ActionsContext';
import { COMPANY_DICTIONARY } from '../../../../constants/urls';

const CompanyDictionaryModal = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const { modal, params, close } = props;
    const item = prop('item', params);

    return (
        <Modal
            {...modal}
            title={t('settings.importDictionary')}
            footer={null}
        >
            <CompanyDictionaryImportForm
                layout='horisontal'
                formAction={postCompanyDictionaryFileHandler}
                item={item}
                onSuccess={() => {
                    revalidateAction(COMPANY_DICTIONARY.stringify());
                    message.success(t('settings.dictionaryImportSuccess'));
                    close();
                }}
                onSubmitFail={() => message.error(t('settings.dictionaryImportError'))}
            />
        </Modal>
    );
}

export default CompanyDictionaryModal;
