import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { pathOr, prop, path, isEmpty } from 'ramda';
import { Spin, Button, Tooltip } from 'antd';
import cx from 'classnames';
import { withState } from 'recompose';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import withFormWrapper from '../../../hocs/withFormWrapper';
import ListenerField from '../../../forms/ListenerField';
import ButtonsField from './ButtonsField';

const Menu = props => {
    const { t } = useTranslation();

    const toggleButton = (button, buttonIndex) => {
        props.onToggleButton({
            id: path(['payload', 'messageNode'], button),
            stateName: path(['payload', 'stateName'], button),
            buttonIndex
        });
    }

    const toggleEdit = () => {
        const { setEdit, form, edit } = props;

        if (edit) {
            form.submit();
        } else {
            setEdit(true);
        }
    }

    const renderInfo = () => {
        return (
            <div className='text-center message-nodes-preview'>
                <p>
                    { t('settings.youCanCustomizeBotMenu') }
                </p>
                <Button type='primary' onClick={toggleEdit}>
                    { t('settings.createCustomizedMenu') }
                </Button>
            </div>
        );
    }

    const renderMenu = values => {
        const { menuLoading, form, showPreview, edit, errors } = props;
        const menuItems = pathOr([], ['mainMenu', 'items'], values);
        const showInfo = !edit && showPreview && !menuLoading && !menuItems.length;
        const invalid = edit && !isEmpty(errors);

        return showInfo ? renderInfo() : (
            <div>
                <div className='menu-settings message-nodes-block'>
                    <div className='menu-settings-header'>
                        <h3>{ t('settings.menu') }</h3>
                        <Tooltip title={invalid ? t('settings.typeButtonText') : null} placement="top">
                            <Button shape='circle' onClick={toggleEdit} className={cx({ invalid, 'save-btn': edit, 'edit-btn': !edit })}>
                                { edit ? <CheckOutlined /> : <EditOutlined /> }
                            </Button>
                        </Tooltip>
                    </div>
                    { menuLoading ? <Spin /> :
                        <div className='menu-settings-btns'>
                            <FieldArray
                                name='mainMenu.items'
                                render={({ fields }) =>
                                    <ButtonsField
                                        fields={fields}
                                        edit={edit}
                                        onToggleButton={toggleButton}
                                        submitForm={form.submit} />
                                } />
                        </div>
                    }
                </div>
            </div>
        );
    }

    return (
        <ListenerField listenFieldName='mainMenu.items'>
            {(values) => renderMenu(values)}
        </ListenerField>
    );
}

export default withState('edit', 'setEdit')(
    withFormWrapper(Menu, {
        mapPropsToValues: prop('item'),
        enableReinitialize: true,
        onSuccess: props => props.setEdit(false)
    })
);
