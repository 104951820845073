import React from 'react';
import { Modal, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import DateTime from '../../table/DateTime';
import { StatusIcon } from './HookSettings';

const Wrapper = styled.div`
    .date-time-string {
        display: inline-block;
    }
    pre {
        background: #fafafa;
        padding: 10px;
    }
    h3 {
        margin-top: 15px;
    }
`;

const QueueEntryModal = props => {
    const { t } = useTranslation();
    const { modal, params } = props;

    return (
        <Modal
            {...modal}
            title={t(`settings.${params.type}`)}
            style={{ top: 50 }}
            footer={null}
        >
            <Wrapper>
                <div><strong>ID: </strong><StatusIcon status={params.status} /> { params.id }</div>
                <div><strong>{t('settings.hook')}: </strong>{t(`settings.${params.type}`)}</div>
                <div><strong>{t('settings.createdAt')}:</strong> <DateTime time={params.createdAt} /></div>
                { params.startedAt && <div><strong>{t('settings.startedAt')}:</strong> <DateTime time={params.startedAt} /></div> }
                { params.finishedAt && <div><strong>{t('settings.finishedAt')}:</strong> <DateTime time={params.finishedAt} /></div> }
                <Tabs defaultActiveKey='payload'>
                    { params.payload &&
                        <Tabs.TabPane tab={t('settings.payload')} key='payload'>
                            <pre style={{ fontSize: 12, maxHeight: 'calc(100vh - 350px)' }}>
                                { JSON.stringify(params.payload, null, 2) }
                            </pre>
                        </Tabs.TabPane>
                    }
                    { params.result &&
                        <Tabs.TabPane tab={t('settings.result')} key='result'>
                            <pre style={{ fontSize: 12, maxHeight: 'calc(100vh - 350px)' }}>
                                { JSON.stringify(params.result, null, 2) }
                            </pre>
                        </Tabs.TabPane>
                    }
                </Tabs>
            </Wrapper>
        </Modal>
    );
}

export default QueueEntryModal;
