import { forEachObjIndexed, has } from 'ramda';

export const getRequiredFields = schema => {
    const requiredFields = [];

    if (!schema) {
        return requiredFields;
    }

    const fn = (fields, path) => forEachObjIndexed((field, key) => {
        const fieldPath = path ? `${path}.${key}` : key;
        field.fields ?
            fn(field.fields, fieldPath) :
            has('required', field._exclusive) && requiredFields.push(fieldPath);
    }, fields);

    fn(schema.fields);

    return requiredFields;
};
