import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CalendarOutlined } from '@ant-design/icons';

import { DATE_FORMAT } from '../../constants/date';

const DateRange = ({ from, to }) => {
    const { t } = useTranslation();

    return (
        <span>
            <CalendarOutlined />
            { from && to ? (
                ` ${moment(from).format(DATE_FORMAT)} - ${moment(to).format(DATE_FORMAT)}`
            ) : (
                ` ${t('vacancy.detail.publishFrom')} ${moment(from).format(DATE_FORMAT)}`
            ) }
        </span>
    );
};

export default DateRange;
