import React from 'react';
import { Field } from 'react-final-form';
import { Col } from 'antd';
import { withTranslation } from 'react-i18next';

import BaseFilter from './base/BaseFilter';
import Select from '../forms/formComponents/Select';
import withFilterForm from './base/withFilterForm';
import { USERS_ADMIN } from '../../constants/urls';
import { enabledState } from '../../constants/roles';

export default withTranslation()(withFilterForm(({ t }) => (
    <BaseFilter
        actionUrl={USERS_ADMIN.stringify()}
        searchFieldName='name'
        searchFieldPlaceholder={t('userAdmin.user')}
        span={12}
        sorting={{
            field: 'createdAt',
            order: 'desc',
        }}
    >
        <Col className='text-left' span={8}>
            <Field
                name='enabled'
                component={Select}
                options={enabledState}
                placeholder={t('bot.subscribed')}
                namePath='value'
            />
        </Col>
    </BaseFilter>
)));
