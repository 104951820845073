import { assoc, compose, includes, map, keys, pickBy, prop, sortBy, values } from 'ramda';
import stations from '@experium/moscow_metro/src/map/stations.json';
import { SPB_STATIONS } from '@experium/spb-metro';

const newStations = [
    { title: 'Беломорская', id: 256 },
    { title: 'Коммунарка', id: 257 },
    { title: 'Прокшино', id: 258 },
    { title: 'Ольховая', id: 259 },
    { title: 'Филатов Луг', id: 260 },
    { title: 'Косино', id: 261 },
    { title: 'Лухмановская', id: 262 },
    { title: 'Улица Дмитриевского', id: 263 },
    { title: 'Некрасовка', id: 264 },
    { title: 'Нижегородская', id: 265 },
    { title: 'Стахановская»', id: 266 },
    { title: 'Окская', id: 267 },
    { title: 'Юго-Восточная', id: 268 },
    { title: 'Лефортово', id: 269 },
    { title: 'Электрозаводская', id: 271 },
    { title: 'Мнёвники', id: 272 },
    { title: 'Народное Ополчение', id: 273 },
    { title: 'Одинцово', id: 274 },
    { title: 'Баковка', id: 275 },
    { title: 'Сколково', id: 276 },
    { title: 'Немчиновка', id: 277 },
    { title: 'Сетунь', id: 278 },
    { title: 'Рабочий Посёлок', id: 279 },
    { title: 'Кунцево', id: 280 },
    { title: 'Тестовская', id: 281 },
    { title: 'Дегунино', id: 282 },
    { title: 'Бескудниково', id: 283 },
    { title: 'Лианозово', id: 284 },
    { title: 'Марк', id: 285 },
    { title: 'Новодачная', id: 286 },
    { title: 'Долгопрудная', id: 287 },
    { title: 'Водники', id: 288 },
    { title: 'Хлебниково', id: 289 },
    { title: 'Шереметьевская', id: 290 },
    { title: 'Лобня', id: 291 },
    { title: 'Нахабино', id: 292 },
    { title: 'Аникеевка', id: 293 },
    { title: 'Опалиха', id: 294 },
    { title: 'Красногорская', id: 295 },
    { title: 'Павшино', id: 296 },
    { title: 'Пенягино', id: 297 },
    { title: 'Трикотажная', id: 298 },
    { title: 'Тушино', id: 299 },
    { title: 'Красный Балтиец', id: 300 },
    { title: 'Гражданская', id: 301 },
    { title: 'Рижская', id: 302 },
    { title: 'Каланчёвская', id: 303 },
    { title: 'Москва Товарная', id: 304 },
    { title: 'Калитники', id: 305 },
    { title: 'Кубанская', id: 306 },
    { title: 'Депо', id: 307 },
    { title: 'Перерва', id: 308 },
    { title: 'Курьяново', id: 309 },
    { title: 'Москворечье', id: 310 },
    { title: 'Покровская', id: 311 },
    { title: 'Красный Строитель', id: 312 },
    { title: 'Битца', id: 313 },
    { title: 'Бутово', id: 314 },
    { title: 'Щербинка', id: 315 },
    { title: 'Остафьево', id: 316 },
    { title: 'Силикатная', id: 317 },
    { title: 'Подольск', id: 318 }
];

export const LINE_CONFIG = {
    '1': {
        id: 1,
        color: '#E42313',
        stations: [216, 1, 3, 2, 4, 84, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 257, 258, 259, 260],
    },
    '2': {
        id: 2,
        color: '#4AAF4F',
        stations: [246, 43, 42, 41, 40, 39, 38, 87, 36, 35, 34, 33, 81, 237, 30, 29, 195, 27, 26, 25, 24, 23, 22, 255, 256],
    },
    '3': {
        id: 3,
        color: '#0072BA',
        stations: [65, 64, 63, 62, 61, 60, 59, 66, 57, 145, 89, 54, 53, 52, 83, 50, 49, 48, 46, 47, 45, 44],
    },
    '4': {
        id: 4,
        color: '#1EBCEF',
        stations: [66, 67, 68, 70, 69, 231, 72, 89, 76, 78, 77, 74, 75],
    },
    '5': {
        id: 5,
        color: '#924F30',
        stations: [87, 86, 97, 84, 83, 127, 81, 80, 79, 12, 89, 88],
    },
    '6': {
        id: 6,
        color: '#F07E23',
        stations: [90, 91, 92, 219, 94, 95, 96, 97, 98, 99, 126, 144, 103, 79, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113],
    },
    '7': {
        id: 7,
        color: '#943E90',
        stations: [114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136],
    },
    '8': {
        id: 8,
        color: '#FFCD1C',
        stations: [137, 139, 212, 141, 142, 143, 144, 138],
    },
    '8A': {
        id: 81,
        code: '8A',
        color: '#FFCD1C',
        stations: [242, 243, 247, 229, 145, 147, 148, 149, 248, 249, 250, 251, 252, 253, 254, 157],
    },
    '9': {
        id: 9,
        color: '#ADACAC',
        stations: [150, 151, 152, 220, 194, 205, 156, 157, 158, 159, 160, 161, 162, 163, 164, 167, 166, 165, 168, 169, 170, 172, 171, 173, 174],
    },
    '10': {
        id: 10,
        color: '#BED031',
        stations: [244, 245, 221, 194, 193, 192, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191],
    },
    '11': {
        id: 11,
        color: '#88CDCF',
        stations: [146, 229, 247, 243, 38],
    },
    '11A': {
        id: 111,
        color: '#88CDCF',
        stations: [196, 197, 195, 269, 141, 271, 272, 273],
    },
    '12': {
        id: 12,
        color: '#BAC8E8',
        stations: [204, 203, 198, 199, 200, 201, 202],
    },
    '13': {
        id: 13,
        color: '#0072B9',
        stations: [208, 207, 206, 205, 210, 209],
    },
    '14': {
        id: 14,
        borderColor: '#E42325',
        stations: [237, 236, 235, 234, 233, 232, 231, 230, 229, 228, 227, 226, 225, 224, 223, 222, 221, 220, 219, 218, 217, 216, 215, 214, 213, 212, 211, 241, 240, 239, 238],
    },
    '15': {
        id: 15,
        color: '#D68AB2',
        stations: [261, 262, 263, 264, 265, 266, 267, 268]
    },
    'D1': {
        id: 21,
        borderColor: '#EE9E2C',
        stations: [274, 275, 276, 277, 278, 279, 280, 57, 70, 281, 121, 87, 157, 205, 221, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291]
    },
    'D2': {
        id: 22,
        borderColor: '#DE477C',
        stations: [292, 293, 294, 295, 296, 297, 63, 298, 118, 299, 225, 300, 301, 156, 302, 83, 303, 304, 305, 240, 130, 306, 307, 308, 309, 310, 26, 311, 312, 313, 314, 315, 316, 317, 318]
    }
};

const getLines = stationId => {
    const lines = pickBy(compose(includes(stationId), prop('stations')), LINE_CONFIG);
    return keys(lines);
};

export const STATIONS = compose(
    sortBy(prop('title')),
    map(item => assoc('lines', getLines(item.id), item))
)(stations.concat(newStations));

export const LINES = values(LINE_CONFIG);

export const METRO_TYPES = {
    'москва': 'msc',
    'moscow': 'msc',
    'санкт-петербург': 'spb',
    'saint petersburg': 'spb'
};

export const METRO_OPTIONS = {
    msc: STATIONS,
    spb: SPB_STATIONS
};
