import React, { useContext, useState } from 'react';
import { path } from 'ramda';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

import ERRORS, { USER_UNAVAIBLE } from '../../../constants/errors';
import LoginForm from '../../forms/LoginForm';
import GuestLink from './GuestLink';
import { postLoginHandler } from '../../../actions/handlers';
import { handleTokenChange } from '../../../utils/token';
import UserContext from '../../../contexts/UserContext';
import logo from '../../../images/findy-color.svg';

const LoginComponent = props => {
    const { t } = useTranslation();
    const user = useContext(UserContext);
    const [loginError, setLoginError] = useState(null);
    const { hideRecovery, experiumRecovery } = props;
    const onSuccess = (_, data) => {
        handleTokenChange(data.accessToken, data.isUrl);
        user.load();
    };

    return <div>
        <div className='logo-wrap'>
            <img alt='logo' src={logo} />
        </div>
        <div className='tagline'>{ t('guest.chatBotsForCandidates') }</div>
        <h3>{ t('guest.loginTitle') }</h3>
        { !loginError && user && user.error && (
            <Alert message={t(ERRORS[path(['message'], user.error) || user.error] || USER_UNAVAIBLE)} type='error' className='form-alert' />
        )}
        <LoginForm
            formAction={values => {
                user && user.reset();
                return postLoginHandler(values);
            }}
            onSuccess={props.onSuccess || onSuccess}
            onSubmitFail={(props, error) => setLoginError(error)} />
        { !hideRecovery && (
            <div className='link-block'>
                <GuestLink to={experiumRecovery ? '/oauth/password-recovery' : '/password-recovery'}>{ t('guest.passwordRecovery') }</GuestLink>
            </div>
        )}
    </div>;
}

export default LoginComponent;
