export const DICTIONARIES = [
    'Sex',
    'YesNoOtherCombo',
    'PplEduLevel',
    'VocEduType',
    'PplEduForm',
    'LangLevelNew',
    'PplMilitaryDolg',
    'PplMaritalStatus',
    'PCProficiency',
    'OldDriverLicCategories',
    'HasHasnotCombo',
    'ReadyNotready',
    'PrjChartOfWork',
    'PrjEmployType',
    'TimeToGetToWork',
    'PplMoving',
    'PplBusinessTrips',
    'CallbackType',
    'Languages',
    'SalaryType'
];

export const DICTIONARIES_NAMES = {
    'Sex': 'app.dictionariesNames.Sex',
    'YesNoOtherCombo': 'app.dictionariesNames.YesNoOtherCombo',
    'PplEduLevel': 'app.dictionariesNames.PplEduLevel',
    'VocEduType': 'app.dictionariesNames.VocEduType',
    'PplEduForm': 'app.dictionariesNames.PplEduForm',
    'LangLevelNew': 'app.dictionariesNames.LangLevelNew',
    'PplMilitaryDolg': 'app.dictionariesNames.PplMilitaryDolg',
    'PplMaritalStatus': 'app.dictionariesNames.PplMaritalStatus',
    'PCProficiency': 'app.dictionariesNames.PCProficiency',
    'OldDriverLicCategories': 'app.dictionariesNames.OldDriverLicCategories',
    'HasHasnotCombo': 'app.dictionariesNames.HasHasnotCombo',
    'ReadyNotready': 'app.dictionariesNames.ReadyNotready',
    'PrjChartOfWork': 'app.dictionariesNames.PrjChartOfWork',
    'PrjEmployType': 'app.dictionariesNames.PrjEmployType',
    'TimeToGetToWork': 'app.dictionariesNames.TimeToGetToWork',
    'PplMoving': 'app.dictionariesNames.PplMoving',
    'PplBusinessTrips': 'app.dictionariesNames.PplBusinessTrips',
    'CallbackType': 'app.dictionariesNames.CallbackType',
    'Languages': 'app.dictionariesNames.Languages',
    'SalaryType': 'app.dictionariesNames.SalaryType'
};

export const DEFAULT_LANG = 'ru';

export const AVAILABLE_LANGUAGES = [
    'en', 'ua',
    'et', 'lt', 'lv',
    'kz', 'th', 'uz'
];

export const AVAILABLE_TRANSLATIONS = [
    'ru', 'en', 'ua',
    'et', 'lt', 'lv',
    'kz', 'th', 'uz'
];

export const LANGUAGES = [
    { value: 'ru', label: 'RU' },
    { value: 'en', label: 'EN' },
    { value: 'ua', label: 'UA' },
    { value: 'et', label: 'ET' },
    { value: 'lt', label: 'LT' },
    { value: 'lv', label: 'LV' },
    { value: 'kz', label: 'KZ' },
    { value: 'th', label: 'TH' },
    { value: 'uz', label: 'UZ' }
];
