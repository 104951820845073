import React from 'react';
import { LoginOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import DateCell from '../../table/tableCells/DateCell';
import TableList from '../../table/TableList';
import AuthLogFilter from '../../filters/AuthLogFilter';
import { LOGIN_ATTEMPT } from '../../../constants/urls';

const UserIcon = styled.div`
    color: ${({ green }) => green ? '#52c41a' : '#f5222d'}
`;

const AuthLog = () => {
    const { t } = useTranslation();

    const renderStatusIcon = status => {
        return isNil(status) ? null : <UserIcon green={status}>
            {status ? <CheckOutlined /> : <CloseOutlined />}
      </UserIcon>;
    }

    const getColumns = () => {
        return [
            {
                title: t('auth.user'),
                dataIndex: 'login',
                key: 'login'
            },
            {
                title: 'IP',
                dataIndex: 'ip',
                key: 'ip'
            },
            {
                title: t('auth.date'),
                dataIndex: 'date',
                key: 'date',
                render: date => <DateCell date={date} />
            },
            {
                title: t('auth.attemptBlocked'),
                dataIndex: 'attemptBlocked',
                key: 'attemptBlocked',
                render: renderStatusIcon,
                align: 'center'
            },
            {
                title: t('auth.userActive'),
                dataIndex: 'userDisabled',
                key: 'userDisabled',
                render: userDisabled => renderStatusIcon(!userDisabled),
                align: 'center'
            },
            {
                title: t('auth.goodPassword'),
                dataIndex: 'badPassword',
                key: 'badPassword',
                render: badPassword => renderStatusIcon(!badPassword),
                align: 'center'
            },
            {
                title: t('auth.successLogin'),
                dataIndex: 'loginFailure',
                key: 'loginFailure',
                render: loginFailure => <UserIcon green={!loginFailure}><LoginOutlined /></UserIcon>,
                align: 'center'
            }
        ];
    }

    return <TableList
        actionUrl={LOGIN_ATTEMPT.stringify()}
        columns={getColumns()}
        filterForm={AuthLogFilter}
        rowKey='date'
        sorting={{
            field: 'date',
            order: 'desc',
        }} />;
}

export default AuthLog;
