import React from 'react';
import { Tooltip } from 'antd';

const DateCell = (props) => {
    const date = new Date(props.date);

    return props.date ? (
        <Tooltip title={date.toLocaleTimeString('ru')}>
            <span>{date.toLocaleDateString('ru')}</span>
        </Tooltip>
    ) : null;
}

export default DateCell;
