import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { ThemeProvider, theme, TerminalSliderTemplates } from '@experium/findy-ui';
import styled from 'styled-components';
import { prop, take, pathOr, path } from 'ramda';
import { Checkbox, Radio } from 'antd';
import { pluralize } from 'numeralize-ru';
import { useTranslation } from 'react-i18next';

import { FILE, BASE_URL, COMPANY_SETTINGS } from '../../../constants/urls';
import ActionsContext from '../../../contexts/ActionsContext';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const sizes = {
    default: {
        width: 2048,
        height: 1536,
    },
    ipad: {
        width: 1366,
        height: 1024,
    }
};

const Wrapper = styled.div`
    width: ${({ horizontal, device = 'default' }) => (
        horizontal ? sizes[device].width : sizes[device].height
    )}px;
    height: ${({ horizontal, device = 'default' }) => (
        (horizontal ? sizes[device].height : sizes[device].width)
    )}px;
    zoom: ${prop('zoom')};
    margin: 0 auto;
`;

const ViewSwitcher = styled.div`
    margin-bottom: 15px;
    text-align: center;

    .ant-checkbox-wrapper {
        margin-left: 20px;
    }
`;

const Banner = props => {
    const { t } = useTranslation();
    const [zoom, setZoom] = useState(1);
    const [ipad, setIpad] = useState(false);
    const [horizontal, setHorizontal] = useState(true);
    const container = useRef();
    const { addAction, removeAction } = useContext(ActionsContext);
    const companySettings = useCompanyCodeSwr(COMPANY_SETTINGS.stringify());

    const getZoom = (orientation, device) => {
        const gridWidth = sizes[device].width;
        const gridHeight = sizes[device].height;
        const width = orientation ? gridWidth : gridHeight;

        if (width <= container.clientWidth) {
            return 1;
        }

        return container.clientWidth / width;
    }

    const getDevice = (ipad) => {
        return ipad ? 'ipad' : 'default';
    }

    const setDeviceZoom = () => {
        const device = getDevice(ipad);

        setZoom(getZoom(horizontal, device));
    };

    useEffect(() => {
        addAction(COMPANY_SETTINGS.stringify(), companySettings);
        setDeviceZoom();
        window.addEventListener('resize', setDeviceZoom);

        return () => {
            removeAction(COMPANY_SETTINGS.stringify());
            window.removeEventListener('resize', setDeviceZoom);
        }
    }, []);

    const getLogo = () => {
        const { data, isEdit } = props;
        const logo = pathOr(isEdit ? null : path(['data', 'logo'], companySettings), ['companySettings', 'logo'], data);

        return logo ? `${BASE_URL}${FILE.stringify({ id: logo })}` : null;
    }

    const getTerminalSliderListContent = () => {
        const { data: { title }, vacancies: { items = [], _meta }, isUnit } = props;
        const count = pathOr(0, ['count'], _meta);

        if (isUnit) {
            const amount = count - 4 < 1 ? 4 : 3;
            const vacancies = take(amount, items);
            const restLength = count - amount;

            return <Fragment>
                { vacancies.map(item =>
                    <Fragment key={item.id}>
                        <span>{ item.title }</span><br/>
                    </Fragment>
                )}
                { restLength > 0 && (
                    <Fragment>
                        <span>{ t('banner.more') } {restLength} {pluralize(restLength, t('banner.vacancy1'), t('banner.vacancy2'), t('banner.vacancy3'))}</span><br/>
                    </Fragment>
                )}
            </Fragment>;
        } else {
            return <Fragment>
                <span>{ title }</span><br/>
            </Fragment>;
        }
    }

    const renderBanner = () => {
        const { data: { banner }, qr } = props;
        const Template = TerminalSliderTemplates[banner.template]

        return (
            <div style={{ overflow: 'hidden' }} ref={container}>
                { !!companySettings.data &&
                    <ThemeProvider theme={theme}>
                        <Wrapper
                            zoom={zoom}
                            horizontal={horizontal}
                            device={getDevice(ipad)}
                        >
                            { Template && (
                                <Template
                                    data={banner}
                                    qr={qr}
                                    logo={getLogo()}
                                    terminalSliderListContent={getTerminalSliderListContent()} />
                            )}
                        </Wrapper>
                    </ThemeProvider>
                }
            </div>
        );
    }

    const togglePreview = e => {
        const { value } = e.target;
        const device = getDevice(ipad);

        setHorizontal(value);
        setZoom(getZoom(value, device));
    }

    const toggleDevice = e => {
        const { checked } = e.target;
        const device = getDevice(checked);

        setIpad(checked);
        setZoom(getZoom(horizontal, device));
    }

    return <Fragment>
        <ViewSwitcher>
            <Radio.Group value={horizontal} onChange={togglePreview}>
                <Radio.Button value={true}>{ t('banner.horizontal') }</Radio.Button>
                <Radio.Button value={false}>{ t('banner.vertical') }</Radio.Button>
            </Radio.Group>
            <Checkbox checked={ipad} onChange={toggleDevice}>
                iPad
            </Checkbox>
        </ViewSwitcher>
        { renderBanner() }
    </Fragment>
}

Banner.defaultProps = {
    vacancies: []
};

export default Banner;
