import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation, withTranslation } from 'react-i18next';
import { Collapse, Alert } from 'antd';
import * as yup from 'yup';

import withFormWrapper from '../hocs/withFormWrapper';
import withUserCompany from '../hocs/withUserCompany';
import { MailEditor } from './formComponents/Editor';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';

const { Panel } = Collapse;

const commonPlaceholders = ['fullName', 'username', 'siteUrl'];
const inviteTemplatePlaceholders = ['fullName', 'username', 'siteUrl', 'registrationUrl', 'registrationUrlBtn'];
const passwordRecoveryPlaceholders = ['fullName', 'username', 'siteUrl', 'url', 'urlBtn'];
const passwordResetPlaceholders = ['fullName', 'username', 'siteUrl', 'url', 'urlBtn', 'minPasswordLength'];
const applicantNotificationPlaceholders = ['fullName', 'username', 'siteUrl', 'applicantTable'];

const LettersSettingsForm = () => {
    const { t } = useTranslation();

    return  <div className='about-company-wrapper'>
        <Collapse className='collapse-custom' defaultActiveKey={[]} accordion>
            <Panel header={t('settings.inviteTemplate')} key="1">
                <Alert
                    style={{ marginBottom: 15 }}
                    description={t('settings.inviteTemplateInfo')}
                    type="info"
                    showIcon
                />
                <Field
                    name='invite.subject'
                    component={Input}
                    required
                    label={t('settings.letterSubject')} />
                <Field
                    name='invite.header'
                    component={MailEditor}
                    mailEditor
                    placeholder={t('settings.letterHeaderPlaceholder')}
                    label={t('settings.letterHeader')}
                    placeholders={commonPlaceholders} />
                <Field
                    name='invite.body'
                    component={MailEditor}
                    mailEditor
                    rows={10}
                    required
                    label={t('settings.letterText')}
                    placeholders={inviteTemplatePlaceholders} />
                <Field
                    name='invite.footer'
                    component={MailEditor}
                    mailEditor
                    placeholder={t('settings.letterFooterPlaceholder')}
                    label={t('settings.letterFooter')}
                    placeholders={commonPlaceholders} />
            </Panel>
            <Panel header={t('settings.passwordRecoveryTemplate')} key="2">
                <Alert
                    style={{ marginBottom: 15 }}
                    description={t('settings.passwordRecoveryTemplateInfo')}
                    type="info"
                    showIcon
                />
                <Field
                    name='passwordRecovery.subject'
                    component={Input}
                    required
                    label={t('settings.letterSubject')} />
                <Field
                    name='passwordRecovery.header'
                    component={MailEditor}
                    mailEditor
                    placeholder={t('settings.letterHeaderPlaceholder')}
                    label={t('settings.letterHeader')}
                    placeholders={commonPlaceholders} />
                <Field
                    name='passwordRecovery.body'
                    component={MailEditor}
                    mailEditor
                    rows={10}
                    required
                    label={t('settings.letterText')}
                    placeholders={passwordRecoveryPlaceholders} />
                <Field
                    name='passwordRecovery.footer'
                    component={MailEditor}
                    mailEditor
                    placeholder={t('settings.letterFooterPlaceholder')}
                    label={t('settings.letterFooter')}
                    placeholders={commonPlaceholders} />
            </Panel>
            <Panel header={t('settings.applicantNotificationTemplate')} key="3">
                <Alert
                    style={{ marginBottom: 15 }}
                    description={t('settings.applicantNotificationTemplateInfo')}
                    type="info"
                    showIcon
                />
                <Field
                    name='applicantNotification.subject'
                    component={Input}
                    required
                    label={t('settings.letterSubject')} />
                <Field
                    name='applicantNotification.header'
                    component={MailEditor}
                    mailEditor
                    placeholder={t('settings.letterHeaderPlaceholder')}
                    label={t('settings.letterHeader')}
                    placeholders={commonPlaceholders} />
                <Field
                    name='applicantNotification.body'
                    component={MailEditor}
                    mailEditor
                    rows={10}
                    required
                    label={t('settings.letterText')}
                    placeholders={applicantNotificationPlaceholders} />
                <Field
                    name='applicantNotification.footer'
                    component={MailEditor}
                    mailEditor
                    placeholder={t('settings.letterFooterPlaceholder')}
                    label={t('settings.letterFooter')}
                    placeholders={commonPlaceholders} />
            </Panel>
            <Panel header={t('settings.companyPasswordResetTemplate')} key="4">
                <Alert
                    style={{ marginBottom: 15 }}
                    description={t('settings.companyPasswordResetTemplateInfo')}
                    type="info"
                    showIcon
                />
                <Field
                    name='passwordReset.subject'
                    component={Input}
                    required
                    label={t('settings.letterSubject')} />
                <Field
                    name='passwordReset.header'
                    component={MailEditor}
                    mailEditor
                    placeholder={t('settings.letterHeaderPlaceholder')}
                    label={t('settings.letterHeader')}
                    placeholders={commonPlaceholders} />
                <Field
                    name='passwordReset.body'
                    component={MailEditor}
                    mailEditor
                    rows={10}
                    required
                    label={t('settings.letterText')}
                    placeholders={passwordResetPlaceholders} />
                <Field
                    name='passwordReset.footer'
                    component={MailEditor}
                    mailEditor
                    placeholder={t('settings.letterFooterPlaceholder')}
                    label={t('settings.letterFooter')}
                    placeholders={commonPlaceholders} />
            </Panel>
        </Collapse>
        <div className='modal-form-toolbar'>
            <SubmitButton>
                { t('form.save') }
            </SubmitButton>
        </div>
    </div>;
}

const validationSchema = ({ t }) => yup.object().shape({
    invite: yup.object().shape({
        subject: yup.string().nullable().required(),
        body: yup.string().nullable().containString(['{registrationUrl}', '{registrationUrlButton}'], t('settings.registrationUrlRequired')).required()
    }),
    passwordRecovery: yup.object().shape({
        subject: yup.string().nullable().required(),
        body: yup.string().nullable().containString(['{url}', '{buttonUrl}'], t('settings.urlRequired')).required()
    }),
    applicantNotification: yup.object().shape({
        subject: yup.string().nullable().required(),
        body: yup.string().nullable().required()
    }),
    passwordReset: yup.object().shape({
        subject: yup.string().nullable().required(),
        body: yup.string().nullable().required()
    }),
});

const parseBody = (value, placeholder) => {
    return '<style>.placeholder-btn {display: inline-block; border-radius: 4px; color: #fff; font-size: 16px; background-color: #002140; text-decoration: none !important; padding: 16px 40px;text-transform: uppercase; margin: 20px;}</style>' + value.replace(new RegExp(`<a href="{${placeholder}}"`, 'g'), `<a class="placeholder-btn" href="{${placeholder}}"`);
};

export default withTranslation()(withUserCompany(withFormWrapper(LettersSettingsForm, {
    mapPropsToValues: ({ item }) => item,
    validationSchema,
    mapBeforeSubmit: value => ({
        ...value,
        invite: {
            ...value.invite,
            body: parseBody(value.invite.body, 'registrationUrlButton')
        },
        passwordRecovery: {
            ...value.passwordRecovery,
            body: parseBody(value.passwordRecovery.body, 'buttonUrl')
        },
        passwordReset: {
            ...value.passwordRecovery,
            body: parseBody(value.passwordRecovery.body, 'buttonUrl')
        }
    })
})));
