import axios from 'axios';
import { omit } from 'ramda';

import { BASE_URL } from '../constants/urls';
import { getToken } from './token';
import { POST_FILE_WITHOUT_AUTH, GET_FILE_WITHOUT_AUTH } from '../constants/urls';

axios.defaults.timeout = 5 * 60 * 1000;

export function setBaseUrl(base = BASE_URL) {
    axios.defaults.baseURL = base;
}

export function addDefaultHeader(header, value) {
    axios.defaults.headers.common[header] = value;
}

export function removeDefaultHeader(header) {
    axios.defaults.headers.common = omit([header], axios.defaults.headers.common);
}

export const getUrl = (url, params) => axios.defaults.baseURL + url.stringify(params) + `?access_token=${getToken()}`;
export const getPostFileUrl = (params) => axios.defaults.baseURL + POST_FILE_WITHOUT_AUTH.stringify(params);
export const getUploadedFileLink = (params) => window.location.origin + axios.defaults.baseURL + GET_FILE_WITHOUT_AUTH.stringify(params);
