import React from 'react';

import { ICON_URLS as botIcons } from '../../constants/icons';

const ApplicantSource = props => {
    const { type } = props;

    return (
        <img src={botIcons[type]} alt="Bot icon" className='deep-link_icon' />
    );
}

export default ApplicantSource;
