import React from 'react';

import DeepLink from '../../table/DeepLink';

const BotSelect = props => {
    const { text, option } = props;

    return (
        <span className='bot-select'>
            <DeepLink
                bot={option}
                enableLink={true}
            />
            {text}
        </span>
    );
}

export default BotSelect;
