import React, { Fragment, useEffect, useRef } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useTranslation, withTranslation } from 'react-i18next';

import withFormWrapper from '../hocs/withFormWrapper';
import BannerFields from './BannerFields';
import { COMPANY_SETTINGS } from '../../constants/urls';
import useCompanyCodeSwr from '../../utils/useCompanyCodeSwr';

export const Container = styled.div`
    padding: 24px;
    height: calc(100vh - ${({ isUnit }) => isUnit || 106}px);
    overflow: auto;

    .ant-row.ant-form-item {
        display: flex;
        .ant-form-item-control-wrapper {
            flex: 1;
        }
    }
`;

const BannerForm = props => {
    const { t } = useTranslation();
    const didMount = useRef(null);
    const { handleSubmit, isSubmitting, isUnit, close } = props;

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;

            props.formRef && props.formRef({ props });
        }
    });

    return <Fragment>
        <Container isUnit={isUnit}>
            <BannerFields {...props} />
        </Container>
        <div className='ant-modal-footer'>
            <Button onClick={close}>
                { t('banner.close') }
            </Button>
            <Button
                type='primary'
                onClick={handleSubmit}
                disabled={isSubmitting}>
                { t('form.save') }
            </Button>
        </div>
    </Fragment>;
}

const withCompanySettings = WrappedComponent => props => {
    const companySettings = useCompanyCodeSwr(COMPANY_SETTINGS.stringify());

    return <WrappedComponent {...props} companySettings={companySettings.data || {}} />;
}

export default withCompanySettings(withTranslation()(
    withFormWrapper(BannerForm, {
        mapPropsToValues: ({ item, companySettings }) => ({
            ...item,
            companySettings
        })
    })
));
