import React from 'react';
import { Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { pathOr } from 'ramda';

import SecuritySettingsForm from '../../forms/SecuritySettingsForm';
import { putCompanySettingsHandler } from '../../../actions/handlers';
import { COMPANY_SETTINGS } from '../../../constants/urls';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';
import withUserCompany from '../../hocs/withUserCompany';

const SecuritySettings = ({ companyId }) => {
    const { t } = useTranslation();
    const companySettings = useCompanyCodeSwr(COMPANY_SETTINGS.stringify());

    return companySettings.data ?
        <SecuritySettingsForm
            formAction={putCompanySettingsHandler}
            item={pathOr({}, ['data'], companySettings)}
            companyId={companyId}
            onSuccess={() => message.success(t('settings.settingsSaveSuccess'))}
            onSubmitFail={() => message.error(t('settings.settingsSaveError'))} />
        : <Spin className='central-spin' spinning={companySettings.isValidating} />;
}

export default withUserCompany(SecuritySettings);
