import React, { useEffect, useRef } from 'react';
import qs from 'qs';
import { path, prop } from 'ramda';
import { Spin } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { PDA } from '../../../constants/urls';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const getVacancy = location => prop('vacancy', {
    vacancy: prop('vacancy', qs.parse(prop('search', location), { ignoreQueryPrefix: true })),
});

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    background: #ececec;
    padding: 30px;
    @media (max-width: 576px) {
        padding: 0;
    }
`;

const Container = styled.div`
    margin: 0 auto;
    padding: 30px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    width: 100%;
    max-width: 1200px;
    background: #fff;
    word-wrap: break-word;

    @media (max-width: 576px) {
        padding: 20px;
        min-height: 100vh;
    }
`;

const Title = styled.h1`
    text-align: center;
    margin-bottom: 30px;
    @media (max-width: 576px) {
        font-size: 1.2rem;
    }
`;

const Pda = props => {
    const { t } = useTranslation();
    const didMount = useRef(null);
    const pda = useCompanyCodeSwr(PDA.stringify({ id: getVacancy(props.location) }));

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;

            if (!getVacancy(props.location)) {
                props.history.push('/');
            }
        }
    });

    return <Wrapper>
        { pda.isValidating ? <Spin className='central-spin' /> :
            <Container>
                <Title>{ t('vacancy.detail.opd') }</Title>
                <div dangerouslySetInnerHTML={{ __html: path(['data', 'pda'], pda) }} />
            </Container>
        }
    </Wrapper>;
}

export default Pda;
