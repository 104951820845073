import './utils/iepolyfill';

import React from 'react';
import { render } from 'react-dom';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/uk';
import 'moment/locale/et';
import 'moment/locale/lt';
import 'moment/locale/lv';
import 'yup';

import './utils/validation';
import App from './components/App';
import { setBaseUrl } from './utils/http';
import rollbarInit from './utils/rollbarInit';
import { provideRequestInterceptors, provideResponseInterceptors } from './utils/httpInterceptors';
import './utils/i18n';

import './styles/index.sass';

setBaseUrl();
provideResponseInterceptors();
provideRequestInterceptors();
moment.locale('ru');

const MOUNT_NODE = document.getElementById('root');

if (process.env.NODE_ENV === 'production') {
    rollbarInit({
        token: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
        version: process.env.REACT_APP_VERSION,
        environment: window.location.host
    });

    window._version = {
        version: process.env.REACT_APP_VERSION,
    };
}

render(<App />, MOUNT_NODE);
