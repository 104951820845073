import React, { Fragment, useContext } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { prop, propOr } from 'ramda';
import { Row, Col, Button } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ListenerField from '../ListenerField';
import Input from '../formComponents/Input';
import CheckboxField from '../formComponents/Checkbox';
import PublishDatePicker from '../formComponents/PublishDatePicker';
import Domains from '../formComponents/Domains';
import UserContext from '../../../contexts/UserContext';
import ModalsContext from '../../../contexts/ModalsContext';
import { OPEN_VACANCY_TRANSLATION_MODAL } from '../../../constants/actionTypes';

const CheckboxBlockWrapper = styled.div`
    margin-bottom: 15px;
`;

const VacancyFields = props => {
    const { user } = useContext(UserContext);
    const { t } = useTranslation();
    const modals = useContext(ModalsContext);

    const {
        highlightError,
        validateOnChange,
        fullLicense,
        isFunctionalLicense
    } = props;

    return <Fragment>
        <Field
            name='title'
            component={Input}
            label={t('vacancy.form.name')}
            highlightError={highlightError}
            placeholder={t('vacancy.placeholders.vacancyTitle')}
            validateOnChange={validateOnChange}
        />
        <Row>
            <Col md={22}>
                <Field
                    name='datePublishedStart'
                    component={PublishDatePicker}
                    label={t('vacancy.form.datePublishedStart')}
                    toPath='datePublishedEnd'
                    highlightError={highlightError}
                    validateOnChange={validateOnChange}
                />
            </Col>
            <Col md={2} className='vacancy-translation-col hide'>
                <FormSpy>
                    {({ values }) => !(
                        <Button
                            className='vacancy-translation-button hide'
                            disabled={propOr('', 'title', values).length < 6}
                            icon={<GlobalOutlined />}
                            onClick={() => modals.open(OPEN_VACANCY_TRANSLATION_MODAL, { values })}
                        />
                    )}
                </FormSpy>
            </Col>
        </Row>
        <ListenerField listenFieldName='company'>
            { () => {
                const publicationSettings = user.publicationSettings || {};

                return (
                    <CheckboxBlockWrapper>
                        <Domains
                            creating={props.creating}
                            fullLicense={fullLicense}
                            isFunctionalLicense={isFunctionalLicense}
                            publicationSettings={publicationSettings}
                        />
                        { (fullLicense) &&
                            <Field
                                name='botPublished'
                                wrapperClassName={'ant-form-item-blocked vacancy-bot-publish'}
                                component={CheckboxField}
                                placeholder={t('form.publicationChat')}
                                disabled={!prop('bot', publicationSettings)} />
                        }
                        { (fullLicense || isFunctionalLicense) &&
                            <Field
                                name='terminalPublished'
                                wrapperClassName={'ant-form-item-blocked vacancy-terminal-publish'}
                                component={CheckboxField}
                                placeholder={t('form.publicationTerminal')}
                                disabled={!prop('bot', publicationSettings) && !prop('landing', publicationSettings) && !prop('terminal', publicationSettings)} />
                        }
                        { (fullLicense || isFunctionalLicense) &&
                            <Field
                                name='landingPublished'
                                wrapperClassName={'ant-form-item-blocked vacancy-landing-publish'}
                                component={CheckboxField}
                                placeholder={t('form.publicationLanding')}
                                disabled={!prop('bot', publicationSettings) && !prop('landing', publicationSettings) && !prop('terminal', publicationSettings)} />
                        }
                    </CheckboxBlockWrapper>
                );
            }}
        </ListenerField>
    </Fragment>;
}

export default VacancyFields;
