import React, { Fragment, useState } from 'react';
import { Field } from 'react-final-form';
import { prop, filter, pathOr, toPairs, fromPairs } from 'ramda';
import * as yup from 'yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';

import Input from './formComponents/Input';
import Select from './formComponents/Select';
import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import withUserCompany from '../hocs/withUserCompany';
import ListenerField from './ListenerField';
import { COMPANY_DICTIONARY, COMPANY_DICTIONARY_ITEM } from '../../constants/urls';
import { TranslationFields } from './ClassifiersForm';
import useCompanyCodeSwr from '../../utils/useCompanyCodeSwr';

const CompanyDictionaryItemForm = props => {
    const { t } = useTranslation();
    const [stateParentDictionaryId, setStateParentDictionaryId] = useState(null);
    const companyDictionaryItem = useCompanyCodeSwr([COMPANY_DICTIONARY_ITEM.stringify(), props.parentDictionaryId, stateParentDictionaryId], (url, parentDictionaryId, updatedParentDictionaryId) => axios.get(url, {
        params: {
            filter: {
                dictionary: updatedParentDictionaryId || parentDictionaryId,
            },
            relations: ['parent']
        }
    }), { revalidateOnMount: stateParentDictionaryId || !!props.parentDictionaryId });
    const companyDictionary = useCompanyCodeSwr([COMPANY_DICTIONARY.stringify(), props.companyId], (url, companyId) => axios.get(url, {
        params: {
            relations: ['parent'],
            filter: {
                company: companyId
            }
        }
    }));

    const { item } = props;
    const dictionaryItems =  pathOr([], ['data', 'items'], companyDictionaryItem);
    const dictionaryId = prop('dictionary', item);
    const dictionaries =  filter(({ id }) => id !== dictionaryId, pathOr([], ['data', 'items'], companyDictionary));

    const onChangeParentDictionary = (dictionaryId) => {
        const { form: { change } } = props;

        if (dictionaryId) {
            companyDictionaryItem.mutate();
            setStateParentDictionaryId(dictionaryId);
        } else {
            companyDictionaryItem.mutate(null);
            change('parent', undefined);
        }
    };

    return (
        <Fragment>
            <Field
                name='value'
                component={Input}
                label={t('company.valueOfDictionaryElement')}
                placeholder={t('company.selectValueOfDictionaryElement')}
                required
                disableClear
            />
            <div style={{ marginBottom: 15 }}>
                <FieldArray name='translations'>
                    { ({ fields }) => <TranslationFields fields={fields} form={props.form} /> }
                </FieldArray>
            </div>
            <Field
                name='parentDictionaryId'
                component={Select}
                label={t('company.linkedDictionary')}
                placeholder={t('company.selectLinkedDictionary')}
                options={dictionaries}
                namePath='name'
                allowClear={true}
                onChange={onChangeParentDictionary}
                disabled
            />
            <ListenerField listenFieldName='parentDictionaryId'>
                { ({ parentDictionaryId }) => (
                    <Field
                        disabled={!parentDictionaryId}
                        name='parent'
                        component={Select}
                        label={t('company.linkedElement')}
                        placeholder={t('company.selectLinkedElement')}
                        options={dictionaryItems}
                        namePath='value'
                        allowClear={true}
                    />
                )}
            </ListenerField>
            <SubmitButton>
                { t('form.save') }
            </SubmitButton>
        </Fragment>
    );
}

const validationSchema = yup.object().shape({
    value: yup.string().nullable().required(),
    parentDictionaryId: yup.string().nullable(),
    parent: yup.string().when('parentDictionaryId', (parentDictionaryId, schema) => {
        return parentDictionaryId ? schema.required() : schema;
    })
});

export default withUserCompany(withFormWrapper(CompanyDictionaryItemForm, {
    validationSchema,
    mapPropsToValues: ({ item = {}, parentDictionaryId }) => ({
        ...item,
        parentDictionaryId,
        translations: filter(({ lang }) => lang !== 'ru', toPairs(pathOr({}, ['translations', 'value'], item)).map(([lang, name]) => ({ name, lang }))),
    }),
    mapBeforeSubmit: values => ({
        ...values,
        translations: {
            value: fromPairs((values.translations || []).map(({ name, lang }) => [lang, name]))
        }
    })
}));
