import React, { Fragment, useContext } from 'react';
import { Checkbox, Button, Divider } from 'antd';
import { keys, isEmpty, dissocPath, path, split, filter } from 'ramda';
import { FormSpy } from 'react-final-form';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import ModalsContext from '../../../contexts/ModalsContext';
import { OPEN_DOCUMENT_TYPE_MODAL } from '../../../constants/actionTypes';

const CheckboxGroup = Checkbox.Group;

const FileTypes = props => {
    const { t } = useTranslation();
    const modals = useContext(ModalsContext);

    const onChange = ( values = [] ) => {
        const { input: { onChange } } = props;
        let newValue = {};

        if (isEmpty(values)) {
            onChange(undefined);
        }

        const { defaultTypes } = props;

        values.forEach(type => {
            if (defaultTypes[type]) {
                newValue[type] = defaultTypes[type];
            }
        });

        onChange(newValue);
    }

    const addAdditionalType = ({ name, type }) => {
        const { input: { onChange, value } } = props;

        onChange({ ...value,  [name]: type});
    }

    const removeAdditionalType = (type) => {
        const { input: { value, onChange } } = props;

        onChange(dissocPath([type], value));
    }

    const renderDefaultTypes = () => {
        const { defaultTypes, input: { value = {} } } = props;
        const options = keys(defaultTypes);

        return (
            <div className='default-types'>
                <Divider plain orientation='left'>{ t('form.standartTypes') }</Divider>
                <CheckboxGroup
                    defaultValue={keys(value)}
                    options={options}
                    onChange={onChange}
                />
            </div>
        );
    }

    const renderAdditionalTypes = () => {
        const { defaultTypes, input: { value = {} } } = props;
        const values = keys(value);
        let additionalTypes = filter((type) =>!defaultTypes[type], values) || [];

        return isEmpty(additionalTypes) ? null : (
            <Fragment>
                <Divider plain orientation='left'>{ t('form.companyTypes') }</Divider>
                <div className='additional-types'>
                    { additionalTypes.map(type => {
                        return (
                            <div
                                key={type}
                                className='additional-type'
                            >
                                <Checkbox
                                    disabled
                                    checked
                                >
                                    <div className='additional-type-text'>
                                        { type }
                                    </div>
                                </Checkbox>
                                <Button
                                    type='danger'
                                    className='additional-type-remove'
                                    onClick={() => removeAdditionalType(type)}
                                    icon={<DeleteOutlined />}
                                    size='small'
                                />
                            </div>
                        );
                    })}
                </div>
            </Fragment>
        );
    }

    const { input: { name } } = props;

    return (
        <div className='file-types-wrapper'>
            { renderDefaultTypes() }
            { renderAdditionalTypes() }
            <div className='additional-type-input'>
                <Divider className='additional-type-input-divider' />
                <FormSpy>
                    {({ values }) => (
                        <Button
                            onClick={() => modals.open(OPEN_DOCUMENT_TYPE_MODAL, {
                                addAdditionalType,
                                values: path(split('.', name), values),
                            })}
                        >
                            { t('form.add') }
                        </Button>
                    )}
                </FormSpy>
            </div>
        </div>
    );
}

export default withFieldWrapper(FileTypes);
