import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

import Questions from '../../../forms/vacancyForm/Questions';
import { SUB_QUESTION } from '../../../../constants/questions';

const BlockSubQuestions = props => {
    const { t } = useTranslation();

    return (
        <div className="sub-questions">
            <FieldArray
                subscription={{
                    initial: true,
                    modified: true,
                }}
                component={Questions}
                name='settings.questions'
                questionsFormKey='settings.questions'
                label={t('vacancy.form.questionnaire')}
                form={props.form}
                hideFormPreset={true}
                isCompositeQuestion={true}
                isSubQuestions={true}
                questionsType={SUB_QUESTION}
                questionsSubType={props.questionsSubType}
                formAction={props.formAction}
            />
        </div>
    );
}

export default BlockSubQuestions;
