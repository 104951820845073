import React from 'react';
import cx from 'classnames';
import { Tooltip } from 'antd';
import { is } from 'ramda';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const IconSwitchField = props => {
    const { onChange, undefinedTrue, input: { value, name }, iconTrue, iconFalse, disabled, tooltip } = props;
    const isTrue = undefinedTrue && !is(Boolean, value) ? true  : value;

    const icon = (
        <div
            id={name}
            className={cx('icon-switch', `icon-switch-${value ? 'true' : 'false'}`, { 'icon-switch-disabled': disabled })}
            onClick={() => !disabled && onChange(!isTrue)}
        >
            { isTrue ? iconTrue : iconFalse }
        </div>
    );

    return tooltip ? (
        <Tooltip title={tooltip}>
            {icon}
        </Tooltip>
    ) : icon;
}

export default withFieldWrapper(IconSwitchField);
