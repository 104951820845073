import React, { useContext, useState } from 'react';
import { Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import VacancyQuestionForm from '../../../forms/VacancyQuestionForm';
import { postQuestionPresetHandler, putQuestionPresetHandler } from '../../../../actions/handlers';
import ActionsContext from '../../../../contexts/ActionsContext';
import { QUESTIONS_PRESET } from '../../../../constants/urls';
import nanoid from '../../../../utils/nanoid';

const QuestionPresetModal = props => {
    const [ field ] = useState(nanoid());
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const { modal, params, close } = props;
    const isNew = !params || params.new;
    const isEdit = !isNew;

    const initial = {
        field,
        reserved: true,
        required: true
    };

    const initialNew = {
        field,
        reserved: null,
        customizable: false,
        required: true
    };

    return <Modal
        {...modal}
        title={isEdit ? t('vacancy.questions.editQuestion') : t('vacancy.questions.addQuestion')}
        footer={null}>
        <VacancyQuestionForm
            formAction={isEdit ? putQuestionPresetHandler : postQuestionPresetHandler}
            item={isNew ? params ? initialNew : initial : params}
            isPreset={true}
            onSuccess={() => {
                revalidateAction(QUESTIONS_PRESET.stringify());
                message.success(t(isEdit ? 'vacancy.questions.questionSuccessEdit' : 'vacancy.questions.questionSuccessAdd'));
                close();
            }}
            onSubmitFail={() => message.error(t(isEdit ? 'vacancy.questions.questionErrorEdit' : 'vacancy.questions.questionErrorAdd'))}
        />
    </Modal>;
}

export default QuestionPresetModal;
