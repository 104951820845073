import { pick, path, pathOr, propEq, last, split, filter, map, any, flatten } from 'ramda';
import qs from 'qs';
import axios from 'axios';

import { OPD_FIELD } from '../constants/vacancy';

export const getWidgetFormLink = (company, domain, vacancy, form, lang, pdaLang) => {
    const url = `${window.location.origin}/formWidget.js`;
    const searchPath = qs.stringify({
        vacancy,
        domain,
        company,
        form,
        lang,
        pdaLang,
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        utm_content: null,
        utm_term: null,
        utm_tool: null
    }, { addQueryPrefix: true });

    return `<script src="${url}${searchPath}"></script>`;
}

export const getFormQuestions = (questions) => {
    return any(propEq('type', 'personalDataAgreement'), questions || []) ?
        questions.map(item => item.type === 'personalDataAgreement' ? { ...item, field: 'personalDataAgreement', required: true } : item) :
        [...(questions || []), OPD_FIELD];
}

export const addCompanyCode = (code, company) => code ? `${company}-${code}` : null;

export const getVacancyCode = code => (
    code ? last(filter(Boolean, split(/-([^]+)/, code))) : null
);

export const isCompositeQuestion = question => (
    path(['type'], question) === 'composite'
);

export const isBlockQuestion = question => {
    const settings = pathOr({}, ['settings'], question);
    return settings.correctValues || (settings.minValue || settings.maxValue ? [pick(['minValue', 'maxValue'], settings)] : null);
};

export const getBlockQuestions = (data = []) => {
    const blockQuestions = {};

    function checkQuestions(questions) {
        return map(question => {
            if (isCompositeQuestion(question)) {
                return checkQuestions(pathOr([], ['settings', 'questions'], question));
            } else {
                const correctValues = pathOr(false, ['settings', 'correctValues'], question);
                const minValue = pathOr(false, ['settings', 'minValue'], question);
                const maxValue = pathOr(false, ['settings', 'maxValue'], question);
                if (correctValues) {
                    blockQuestions[question.field] = correctValues;
                    return true;
                } else if (maxValue || minValue) {
                    blockQuestions[question.field] = [{ minValue, maxValue }];
                    return true;
                } else {
                    return false;
                }
            }
        }, questions);
    }

    return any(checkQuestions(data)) ? blockQuestions : null;
};

export const hasBlockQuestions = (questions) => {
    return any(
        question => isCompositeQuestion(question)
            ? any(subquestion => !!isBlockQuestion(subquestion), pathOr([], ['settings', 'questions'], question))
            : !!isBlockQuestion(question),
        questions || []
    );
};

export const mapClassifiers = (selectedItems = [], items = []) => {
    const allChildren = flatten([...items, ...items.map(item => pathOr([], ['_relations', 'options'], item))]);

    return filter(id => !any(propEq('parent', id), allChildren), selectedItems);
};

// csv reports
const countries = {
    '5c9de82be066cb0055ea3744': 'Россия',
    '5c9de82be066cb0055ea3767': 'Украина',
    '5c9de82be066cb0055ea36cf': 'Беларусь',
    '5c9de82be066cb0055ea3705': 'Казахстан',
    '5c9de82be066cb0055ea3766': 'Узбекистан',
    '5c9de82be066cb0055ea377a': 'Эстония',
    '5c9de82be066cb0055ea370c': 'Киргизия',
    '5c9de82be066cb0055ea372e': 'Мьянма',
    '5c9de82be066cb0055ea3764': 'Турция',
    '5c9de82be066cb0055ea36ef': 'Грузия',
};

export const getVacanciesCSV = async request => {
    const data = await request;

    let csv = 'Название вакансии;Ответственный за вакансию (ФИО);Город;Страна;Дата размещения;Кол-во откликов;Кол-во откликов (март);Кол-во откликов (апрель);Кол-во откликов (май);Кол-во откликов (июнь);Кол-во откликов (июль)\n';

    for(let item of data.items) {
        let applicants = {
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
        };

        let vapplicants = (await axios.get('/applicant', {
            params: {
                pagination: { limit: 0 },
                filter: {
                    vacancy: item.id,
                    rejected: null,
                    createdAtFrom: `2021-03-01T00:00:00.000Z`, createdAtTo: `2021-08-01T00:00:00.000Z`,
                }
            }
        })).items;

        for (let applicant of vapplicants) {
            for (let month of [3, 4, 5, 6, 7]) {
                if (`${applicant.createdAt}`.indexOf(`2021-0${month}`) >= 0) {
                    applicants[month] = applicants[month] + 1;
                }
            }
        }

        csv += `${item.title};${(
            `${path(['createdBy', 'lastName'], item._relations) || ''} ${path(['createdBy', 'firstName'], item._relations) || ''} ${path(['createdBy', 'middleName'], item._relations) || ''}`
        )};${
            (path(['cities'], item._relations) || []).map(city => city ? city.name || '-' : '').join(' ')
        };${
            (path(['cities'], item._relations) || []).map(city => city ? countries[city.country] || '-' : '').join(' ')
        };${new Date(item.publishedAt || item.createdAt).toLocaleDateString('ru')};${
            `${item.applicantCount || 0 }`
        };${
            `${applicants['3'] || 0 }` // март
        };${
            `${applicants['4'] || 0 }` // апрель
        };${
            `${applicants['5'] || 0 }` // май
        };${
            `${applicants['6'] || 0 }` // июнь
        };${
            `${applicants['7'] || 0 }` // июль
        }\n`
    }

    // console.log(csv)
    return { items: [], csv };
}

export const getVacanciesUsersCSV = request => request.then(data => {
    let csv = 'Ответственный;Кол-во вакансий;Кол-во откликов\n';
    const users = {};

    data.items.map(item => {
        if (!users[item.createdBy]) {
            users[item.createdBy] = {
                _relations: item._relations,
                applicantCount: item.applicantCount || 0,
                vacancyCount: 1,
            }
        } else {
            users[item.createdBy].vacancyCount += 1;
            users[item.createdBy].applicantCount += (item.applicantCount || 0);
        }
    });

    for (let item in users) {
        csv += `${(
            `${path(['createdBy', 'lastName'], users[item]._relations) || ''} ${path(['createdBy', 'firstName'], users[item]._relations) || ''} ${path(['createdBy', 'middleName'], users[item]._relations) || ''}`
        )};${users[item].vacancyCount || 0};${users[item].applicantCount || 0}\n`
    }
    // console.log(csv)
    return { items: [], csv };
})

