import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import Input from './formComponents/Input';
import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import { Button } from 'antd';
import { COMPANY_MANAGER } from '../../constants/roles';
import { PhoneInput } from './formComponents/MaskedInput';

const CompanyManagerForm = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return <Fragment>
        <Field
            name='lastName'
            component={Input}
            label={t('company.lastName')}
            required
        />
        <Field
            name='firstName'
            component={Input}
            label={t('company.firstName')}
            required
        />
        <Field
            name='middleName'
            component={Input}
            label={t('company.middleName')}
        />
        <Field
            name='username'
            component={Input}
            label='Email'
            htmlType='email'
            required
        />
        <Field
            name='phone'
            component={PhoneInput}
            label={t('company.phone')}
        />
        <div className='modal-form-toolbar'>
            <Button
                onClick={() => history.push('/companies')}>
                {t('company.pass')}
            </Button>
            <SubmitButton>
                {t('company.add')}
            </SubmitButton>
        </div>
    </Fragment>;
}

const validationSchema = () => yup.object().shape({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    username: yup.string().email().required(),
    phone: yup.string().phone(),
});

export default withFormWrapper(CompanyManagerForm, {
    mapPropsToValues: ({ company }) => ({
        company,
        roles: [COMPANY_MANAGER]
    }),
    validationSchema
});
