import React from 'react';
import { Col } from 'antd';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import Select from '../forms/formComponents/Select';
import { ATTEMPT_BLOCKED, USER_DISABLED, BAD_PASSWORD, LOGIN_FAILURE } from '../../constants/authLog';
import withFilterForm from './base/withFilterForm';
import BaseFilter from './base/BaseFilter';

const AuthLogFilter = () => {
    const { t } = useTranslation();

    return <BaseFilter
        searchFieldName="login"
        searchFieldPlaceholder={t('auth.user')}
        span={6}
    >
        <Col span={5}>
            <Field
                name='attemptBlocked'
                component={Select}
                options={ATTEMPT_BLOCKED}
                placeholder={t('auth.attemptBlocked')}
                allowClear />
        </Col>
        <Col span={5}>
            <Field
                name='userDisabled'
                component={Select}
                options={USER_DISABLED}
                placeholder={t('auth.userActive')}
                allowClear />
        </Col>
        <Col span={4}>
            <Field
                name='badPassword'
                component={Select}
                options={BAD_PASSWORD}
                placeholder={t('auth.goodPassword')}
                allowClear />
        </Col>
        <Col span={4}>
            <Field
                name='loginFailure'
                component={Select}
                options={LOGIN_FAILURE}
                placeholder={t('auth.successLogin')}
                allowClear />
        </Col>
    </BaseFilter>;
}

export default withFilterForm(AuthLogFilter);
