import React from 'react';
import { Tooltip, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { DEEP_LINK_COPY_ERROR, DEEP_LINK_COPY_SUCCESS } from '../../constants/messages';
import { botLink } from '../../utils/botLink';
import BotImage from './BotImage';

const DeepLink = props => {
    const { t } = useTranslation();

    const onCopy = (text, result) => {
        result ?
            message.success(t(DEEP_LINK_COPY_SUCCESS)) :
            message.error(t(DEEP_LINK_COPY_ERROR));
    }

    const renderLink = (botName, link) => (
        <a className='deep-link' href={link} target='_blank' rel='noopener noreferrer'>
            {botName}
        </a>
    );

    const { bot, vacancyId, enableLink, placement = 'top' } = props;
    const link = botLink(bot, vacancyId);

    const deepLink = (
        <CopyToClipboard onCopy={(vacancyId || enableLink) && onCopy} text={link} className='deep-link'>
            <span>
                <BotImage type={bot.type} />
            </span>
        </CopyToClipboard>
    );

    return (vacancyId || enableLink) ? (
        <Tooltip
            title={renderLink(bot.username, link)}
            key={bot.id}
            placement={placement}
        >
            { deepLink }
        </Tooltip>
    ) : deepLink;
}

export default DeepLink;
