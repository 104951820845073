import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { path } from 'ramda';
import { useTranslation } from 'react-i18next';

import withFormWrapper from '../hocs/withFormWrapper';
import withCityField from '../hocs/withCityField';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';
import AddressSelect from './formComponents/AddressSelect';
import Select from './formComponents/Select';
import MetroInput from './formComponents/MetroInput';
import { getStationsLines } from '../../utils/fieldsHelpers';
import ListenerField from './ListenerField';
import { CITY_DICTIONARY } from '../../constants/urls';

const UnitForm = props => {
    const { t } = useTranslation();

    const setMetroLines = values => {
        if (values && values.length) {
            const lines = getStationsLines(values);

            props.form.change('metroLines', lines);
        } else {
            props.form.change('metroLines', null);
        }
    }

    const { item, cityName, onCitySelect, coordinatesPending } = props;

    return <Fragment>
        <Field
            name='title'
            component={Input}
            label={t('unit.name')} />
        <ListenerField listenFieldName='address'>
            { ({ address }) => (
                <Field
                    name='city'
                    component={Select}
                    label={t('unit.city')}
                    placeholder={t('unit.selectCity')}
                    actionUrl={CITY_DICTIONARY.stringify()}
                    searchable={true}
                    onChange={value => {
                        onCitySelect(value, address);
                    }}
                    allowClear={true}
                    valuePath='id'
                    namePath='name'
                    searchKey='name'
                />
            )}
        </ListenerField>
        <Field
            name='address'
            component={AddressSelect}
            label={t('unit.address')}
            city={cityName}
            mask={false}
            disabled={coordinatesPending}
        />
        <ListenerField listenFieldName='city'>
            { ({ city }) =>
                city && <Field
                    name='metro'
                    component={MetroInput}
                    onChange={setMetroLines}
                    city={city}
                    mask={false}
                />
            }
        </ListenerField>
        <SubmitButton>
            { item ? t('form.save') : t('unit.add') }
        </SubmitButton>
    </Fragment>;
}

const validationSchema = yup.object().shape({
    title: yup.string().required().min(3)
});

export default withFormWrapper(withCityField('item')(UnitForm), {
    mapPropsToValues: ({ item }) => item ? ({
        ...item,
        address: {
            address: path(['address'], item),
            location: path(['location'], item),
        },
    }) : ({}),
    validationSchema,
    mapBeforeSubmit: values => ({
        ...values,
        address: path(['address', 'address'], values),
        location: path(['address', 'location'], values),
    }),
});
