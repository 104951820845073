import React, { Fragment } from 'react';
import { Button, Badge } from 'antd';
import { Link } from 'react-router-dom';
import { filter, path, prop } from 'ramda';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { getUrlWithFilters } from '../../../utils/urlParams';
import VacancyFilter from '../../filters/VacancyFilter';
import TableList from '../../table/TableList';
import EnabledSwitch from '../../table/EnabledSwitch';
import DeepLinksDropdown from '../../table/DeepLinksDropdown';
import VacancyPublishedSwitch from '../../table/VacancyPublishedSwitch';
import PublicationDate from '../../table/PublicationDate';
import Metro from '../../table/Metro';
import withUserCompany from '../../hocs/withUserCompany';
import { getPublicationStatus } from '../../../utils/date';
import { ACTIVE_ID } from '../../../constants/vacancy';
import { putVacancyHandler } from '../../../actions/handlers';
import { BOT, VACANCY, COMPANY_SETTINGS } from '../../../constants/urls';
import { vacanciesFilter } from '../../../utils/handlers';
import { getFullName } from '../../../utils/fieldsHelpers';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const initFilters = { published: ACTIVE_ID };

const PublishedSwitchers = styled.div`
    display: flex;
    button {
        margin-right: 8px;
    }
`;

const Vacancies = props => {
    const { t } = useTranslation();
    const bots = useCompanyCodeSwr(BOT.stringify());

    const getColumns = () => {
        const { readonly, isFullLicense, isAdmin, isFunctionalLicense } = props;

        const columns = [
            {
                title: t('vacancy.list.vacancy'),
                dataIndex: 'title',
                key: 'title',
                render: (title, { id }) => (
                    <Fragment>
                        <Link to={`/vacancy/${id}`}>
                            {title}
                        </Link>
                    </Fragment>
                ),
                className: 'main-column',
            },
            {
                title: t('vacancy.list.city'),
                dataIndex: ['_relations', 'cities'],
                key: 'cities',
                render: (cities, item) => (
                    <div>
                        {(cities || []).reduce((res, cur, index) => res + (index ? `, ${prop('name', cur)}` : prop('name', cur)), '')}
                        <Metro stations={item.metro} showFull={false} />
                    </div>
                ),
            },
            {
                title: t('vacancy.list.unit'),
                dataIndex: ['_relations', 'vacancyUnit', 'title'],
                key: 'vacancyUnit',
                hide: (isFunctionalLicense && !isAdmin) || props.staticFilter.vacancyUnit
            },
            {
                title: t('vacancy.detail.responsible'),
                key: 'responsibleBy',
                render: vacancy => getFullName(path(['_relations', 'responsibleBy'], vacancy))
            },
            {
                title: t('vacancy.list.published'),
                key: 'published',
                render: item => {
                    const publicationStatus = getPublicationStatus(item.datePublishedStart, item.datePublishedEnd);
                    const { isFullLicense, publicationSettings, isFunctionalLicense, isAdmin } = props;
                    const revalidateActions = [VACANCY.stringify(), COMPANY_SETTINGS.stringify()];

                    return (
                        <PublishedSwitchers key={item.id}>
                            { !readonly && (
                                <Fragment>
                                    <EnabledSwitch item={item} action={putVacancyHandler} revalidateAction={revalidateActions} />
                                    { isFullLicense && !publicationStatus.expired && item.active &&
                                        <Fragment>
                                            { publicationSettings.bot && (
                                                <EnabledSwitch item={item} action={putVacancyHandler} path='botPublished' SwitchComponent={VacancyPublishedSwitch} revalidateAction={revalidateActions} />
                                            )}
                                            { publicationSettings.terminal && (
                                                <EnabledSwitch item={item} action={putVacancyHandler} path='terminalPublished' SwitchComponent={VacancyPublishedSwitch} revalidateAction={revalidateActions} />
                                            )}
                                            { item.vacancyUnit && (
                                                <EnabledSwitch item={item} action={putVacancyHandler} path='sliderPublished' SwitchComponent={VacancyPublishedSwitch} revalidateAction={revalidateActions} />
                                            )}
                                        </Fragment>
                                    }
                                    { isFunctionalLicense && !isAdmin && !publicationStatus.expired && item.active && item.vacancyUnit && (
                                        <EnabledSwitch item={item} action={putVacancyHandler} path='sliderPublished' SwitchComponent={VacancyPublishedSwitch} revalidateAction={revalidateActions} />
                                    )}
                                </Fragment>
                            )}
                            <PublicationDate publicationStatus={publicationStatus} />
                        </PublishedSwitchers>
                    );
                }
            },
            {
                key: 'apply',
                render: item => (
                    <Link to={getUrlWithFilters('/applicants', { vacancy: item.id, rejectedState: null })}>
                        {t('vacancy.detail.applicants')} <Badge count={item.applicantCount || 0} className='primary' showZero overflowCount={10000} />
                    </Link>
                )
            },
            {
                title: t('vacancy.list.links'),
                key: 'deepLinks',
                render: ({ id, user, description, title }) => (
                    <DeepLinksDropdown
                        bots={bots}
                        data={{ title, description, id, user }}
                    />
                ),
                hide: !isFullLicense && !isAdmin
            },
        ];

        return filter(column => !column.hide, columns);
    }

    const renderButtons = () => {
        const { readonly } = props;

        return readonly ? null : (
            <Fragment>
                <Button
                    className='toolbar-button'
                    type='primary' icon={<PlusOutlined />}
                    onClick={() => props.history.push('/vacancy/create')}
                >
                    { t('vacancy.list.add') }
                </Button>
            </Fragment>
        )
    }

    const { staticFilter, param, urlPrefix } = props;

    return (
        <TableList
            tableLayout={'auto'}
            actionUrl={VACANCY.stringify()}
            filterForm={VacancyFilter}
            initFilters={initFilters}
            staticFilter={staticFilter}
            buttons={renderButtons()}
            columns={getColumns()}
            param={param}
            urlPrefix={urlPrefix}
            toolbarButtonSize={4}
            toolbarFilterSize={20}
            sorting={{
                field: 'createdAt',
                order: 'desc',
            }}
            relations={['cities', 'specialization', 'vacancyUnit', 'formPreset', 'createdBy', 'responsibleBy']}
            getAdditionalFilter={vacanciesFilter}
            bots={bots.data}
        />
    );
}

Vacancies.defaultProps = {
    staticFilter: {},
    param: 'list'
};

export default withUserCompany(Vacancies);
