import Rollbar from 'rollbar';
import { any, merge, always } from 'ramda';
import jwt from 'jwt-decode';

import { getToken } from './token';

const defaults = {
    getPayload: always(null),
    domains: ['.*?']
};

const ignoredMessages = [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    'Script error',
];

export default function (options) {
    options = merge(defaults, options);

    window._rollbarConfig = {
        enabled: true,
        accessToken: options.token,
        captureUncaught: true,
        payload: {
            environment: options.environment,
            client: {
                javascript: {
                    source_map_enabled: true,
                    code_version: options.version,
                    guest_uncaught_frames: true
                }
            }
        },
        ignoredMessages,
        checkIgnore: function(isUncaught, args) {
            if (isUncaught && any(message => args[0] && args[0].indexOf(message) === 0, ignoredMessages)) {
                return true;
            }

            return false;
        },
        transform: payload => {
            const token = getToken();

            try {
                payload.person = token ? jwt(token) : null;
            } catch(e) {
                payload.person = null;
            }

            const trace = payload.body.trace;
            const host = window.location.host;

            if (trace && trace.frames) {
                for (var i = 0; i < trace.frames.length; i++) {
                    const filename = trace.frames[i].filename;

                    if (filename) {
                        const fileNameWithDynamichost = filename.replace(host, process.env.REACT_APP_ROLLBAR_HOST);
                        trace.frames[i].filename = fileNameWithDynamichost;
                    }
                }
            }
        }
    };

    Rollbar.init(window._rollbarConfig);
}

export const pushRollbarError = (error, info) => {
    if (window._rollbarConfig) {
        Rollbar.configure({
            payload: {
                info
            }
        });

        Rollbar.error(error);
    }
}
