import form from './lv/form.json';
import vacancy from './lv/vacancy.json';
import applicant from './lv/applicant.json';
import auth from './lv/auth.json';
import banner from './lv/banner.json';
import call from './lv/call.json';
import chat from './lv/chat.json';
import company from './lv/company.json';
import domain from './lv/domain.json';
import guest from './lv/guest.json';
import landing from './lv/landing.json';
import profile from './lv/profile.json';
import settings from './lv/settings.json';
import dialog from './lv/dialog.json';
import userAdmin from './lv/userAdmin.json';
import app from './lv/app.json';
import bot from './lv/bot.json';
import unit from './lv/unit.json';
import reports from './lv/reports.json';

export default {
    app,
    form,
    vacancy,
    applicant,
    auth,
    banner,
    call,
    chat,
    company,
    domain,
    guest,
    landing,
    profile,
    settings,
    dialog,
    userAdmin,
    bot,
    unit,
    reports,
};
