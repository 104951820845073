import React from 'react';
import { withTranslation } from 'react-i18next';

import BaseFilter from './base/BaseFilter';
import withFilterForm from './base/withFilterForm';

export default withTranslation()(withFilterForm(({ t }) => (
    <BaseFilter
        searchFieldName='name'
        namePath='name'
        searchFieldPlaceholder={t('settings.name')}
    />
)));
