import React, { Fragment, useState } from 'react';
import { Modal, Row, Col, Select } from 'antd';
import { allPass, filter, path, propEq, prop, isEmpty, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import DeepLink from '../../table/DeepLink';
import WidgetLink from '../../table/WidgetLink';

import { getWidgetFormLink } from '../../../utils/vacancy';
import { BOT } from '../../../constants/urls';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const VacancyWidgetModal = props => {
    const { t } = useTranslation();
    const [domain, setDomain] = useState();
    const { data } = useCompanyCodeSwr(BOT.stringify());
    const items = pathOr([], ['items'], data);

    const { modal, params } = props;
    const bots = !isEmpty(items) ? filter(allPass([prop('subscribed'), propEq('type', 'web')]), items) : [];
    const isSubscribed = data && !isEmpty(bots);
    const code = path(['vacancy', '_relations', 'company', 'code'], params);
    const domains = pathOr([], ['vacancy', '_relations', 'domains'], params);

    return (
        <Modal {...modal} title={t('vacancy.detail.widget')} footer={null}>
            <Row>
                <Col xs={20}>
                    <div className='bot-title'>
                        { t('vacancy.detail.formWidget') }:
                    </div>
                    { !!domains.length &&
                        <Select
                            style={{ width: '100%', marginTop: 10, marginBottom: 10 }}
                            value={domain}
                            onChange={setDomain}
                            placeholder={t('vacancy.detail.domain')}
                            allowClear>
                            { domains.map(domain =>
                                <Select.Option value={domain.id} key={domain.id}>{ domain.name }</Select.Option>
                            )}
                        </Select>
                    }
                    <WidgetLink link={getWidgetFormLink(params.vacancy.company, domain, params.vacancy.id)} />
                    <br />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col xs={20}>
                    { isSubscribed ? (
                        <div>
                            <div className='bot-title'>
                                { t('vacancy.detail.widgetForAllBots') }:
                            </div>
                            <WidgetLink code={code} vacancy={params.vacancy.id} />
                            <br />
                            { bots.map((bot) => (
                                <Fragment key={bot.id}>
                                    <div className='bot-title'>
                                        { t('vacancy.detail.webBotWidget') } <DeepLink vacancyId={params.vacancy.id} bot={bot} />
                                        <span className='bot-title-text'>
                                            {bot.username}
                                        </span>:
                                    </div>
                                    <WidgetLink {...bot} vacancy={params.vacancy.id} />
                                    <br />
                                </Fragment>
                            ))}
                        </div>
                    ) : (
                        <div className='empty-bots'>{ t('vacancy.detail.noActiveWebBots') }</div>
                    )}
                </Col>
                <Col xs={4} className='text-center'>
                    <img alt={t('vacancy.detail.exampleOfChatWidget')} src='/assets/widget/full.png' />
                </Col>
            </Row>
        </Modal>
    );
}

export default VacancyWidgetModal;
