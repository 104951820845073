import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, Popover, Badge } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import DateTime from '../../table/DateTime';
import { getFullName } from '../../../utils/fieldsHelpers';
import { last, path, pathOr } from 'ramda';
import { Link } from 'react-router-dom';
import moment from 'moment';

const RadioGroupWrap = styled.div`
    margin: 0 -10px;
    .ant-radio-group {
        display: flex;
    }
    .ant-radio-button-wrapper {
        flex: 1;
        margin: 10px;
        height: auto;
        line-height: normal;
        padding: 12px 20px;
        display: inline-flex;
        align-items: center;
        border-radius: 4px;
        border-width: 1px;
        font-weight: 500;
        &-checked {
            background: rgba(128, 193, 255, 0.24);
        }
        &:not(:first-child)::before {
            content: none;
        }
        .radio-title {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            .anticon {
                color: #1990ff;
            }
        }
        & > span {
            display: block;
            width: 100%;
        }
    }
`;

const LicenseRadio = props => {
    const { t } = useTranslation();
    const { input: { value, name }, isDemo } = props;

    const onChange = e => {
        props.onChange(e.target.value);
    }

    const demoActivated = isDemo ? moment().isBefore(path(['expiredAt'], last(props.companyLicense || []))) : false;

    return <RadioGroupWrap>
        <Radio.Group value={value} onChange={onChange} id={name}>
            <Radio.Button value="full" disabled={isDemo && demoActivated}>{ t('company.licenseTypes.full') }</Radio.Button>
            <Radio.Button value="minimal" disabled={isDemo && demoActivated}>{ t('company.licenseTypes.minimal') }</Radio.Button>
            <Radio.Button value="functional" disabled={isDemo && demoActivated}>{ t('company.licenseTypes.functional') }</Radio.Button>
            <Radio.Button value="demo">
                { props.create ?
                    <span>{ t('company.licenseTypes.demo') }</span> :
                    <div className="radio-title">
                        <span>{ demoActivated && <Badge status="processing" />} { t('company.licenseTypes.demo') }</span>
                        { !!(props.companyLicense || []).length &&
                            <Popover
                                title={t('company.activationHistory')}
                                content={<div>
                                    { (props.companyLicense || []).map((license, index) =>
                                        <div key={`license-${index}`} className="company-history-line">
                                            <span className="date"><DateTime date={license.createdAt} /></span> &ndash; <Link to={`/user/${license.createdBy}`} className="author">{ getFullName(pathOr({}, ['_relations', 'createdBy'], license)) }</Link>
                                        </div>
                                    )}
                                </div>}
                                trigger="click"
                            >
                                <HistoryOutlined />
                            </Popover>
                        }
                    </div>
                }
            </Radio.Button>
        </Radio.Group>
    </RadioGroupWrap>;
};

export default withFieldWrapper(LicenseRadio);
