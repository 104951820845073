import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const GuestLink = ({ to, children }) => {
    const location = useLocation();

    return <Link to={location.search ? to + location.search : to}>{ children }</Link>;
}

export default GuestLink;
