import form from './ua/form.json';
import vacancy from './ua/vacancy.json';
import applicant from './ua/applicant.json';
import auth from './ua/auth.json';
import banner from './ua/banner.json';
import call from './ua/call.json';
import chat from './ua/chat.json';
import company from './ua/company.json';
import domain from './ua/domain.json';
import guest from './ua/guest.json';
import landing from './ua/landing.json';
import profile from './ua/profile.json';
import settings from './ua/settings.json';
import dialog from './ua/dialog.json';
import userAdmin from './ua/userAdmin.json';
import app from './ua/app.json';
import bot from './ua/bot.json';
import unit from './ua/unit.json';
import reports from './ua/reports.json';

export default {
    app,
    form,
    vacancy,
    applicant,
    auth,
    banner,
    call,
    chat,
    company,
    domain,
    guest,
    landing,
    profile,
    settings,
    dialog,
    userAdmin,
    bot,
    unit,
    reports,
};
