import React from 'react';
import { Tabs } from 'antd';
import { contains, path } from 'ramda';
import { useTranslation } from 'react-i18next';

import NoMatch from '../../NoMatch';
import Route from '../../Route';
import ThemeSettings from './ThemeSettings';
import FileSettings from './FileSettings';
import SecuritySettings from './SecuritySettings';
import UsersAdmin from '../usersAdmin/UsersAdmin';
import JuristicEntities from '../companies/JuristicEntities';
import CaptchaSettings from './CaptchaSettings';
import withUserCompany from '../../hocs/withUserCompany';
import PdaSettings from './PdaSettings';
import HookSettings from './HookSettings';
import LettersSettings from "./LettersSettings";

const Settings = props => {
    const { t } = useTranslation();
    const { location, isCompanyAdmin } = props;
    const activeKey = path([0], location.pathname.replace(/\/settings\/?/, '').split('/'));

    const onChange = key => {
        props.history.push(`/settings/${key}`);
    };

    return contains(activeKey, ['', 'users', 'entities', 'files', 'captcha', 'pda', 'security', 'hook', 'letters']) ?
        <Tabs
            className='settings-tabs'
            animated={{ tabPane: false }}
            activeKey={activeKey}
            onChange={onChange}
        >
            <Tabs.TabPane tab={t('settings.companyTheme')} key=''>
                <Route path='/settings' component={ThemeSettings} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('settings.letterTemplates')} key='letters'>
                <Route path='/settings/letters' component={LettersSettings} />
            </Tabs.TabPane>
            { isCompanyAdmin &&
                <Tabs.TabPane tab={t('settings.users')} key='users'>
                    <Route path='/settings/users/:id?' render={props => <UsersAdmin {...props} allowUserPublications />} />
                </Tabs.TabPane>
            }
            { isCompanyAdmin &&
                <Tabs.TabPane tab={t('company.juristicEntities')} key='entities'>
                    <Route path='/settings/entities/:id?' render={props => <JuristicEntities {...props} isCompanyRoute />} />
                </Tabs.TabPane>
            }
            { isCompanyAdmin &&
                <Tabs.TabPane tab={t('settings.files')} key='files'>
                    <Route path='/settings/files' component={FileSettings} />
                </Tabs.TabPane>
            }
            { isCompanyAdmin &&
                <Tabs.TabPane tab={t('settings.captcha')} key='captcha'>
                    <Route path='/settings/captcha' component={CaptchaSettings} />
                </Tabs.TabPane>
            }
            <Tabs.TabPane tab={t('settings.pdaShort')} key='pda'>
                <Route path='/settings/pda' component={PdaSettings} />
            </Tabs.TabPane>
            { isCompanyAdmin &&
                <Tabs.TabPane tab={t('settings.security')} key='security'>
                    <Route path='/settings/security' component={SecuritySettings} />
                </Tabs.TabPane>
            }
            <Tabs.TabPane tab={t('settings.hooks')} key='hook'>
                <Route path='/settings/hook' component={HookSettings} />
            </Tabs.TabPane>
        </Tabs> :
        <Route component={NoMatch} />;
}

export default withUserCompany(Settings);
