export const ADMIN = 'admin';
export const ADMIN_COMPANY = 'companyAdmin';
export const USER = 'user';
export const COMPANY_MANAGER = 'companyManager';

export const IS_ADMIN = [ADMIN, ADMIN_COMPANY, COMPANY_MANAGER];
export const ONLY_ADMIN = [ADMIN];
export const NOT_ADMIN = [USER, COMPANY_MANAGER, ADMIN_COMPANY];
export const ONLY_MANAGER = [COMPANY_MANAGER, ADMIN_COMPANY];

export const ROLE_TITLES = {
    [ADMIN]: 'app.roles.admin',
    [ADMIN_COMPANY]: 'app.roles.companyAdmin',
    [COMPANY_MANAGER]: 'app.roles.companyManager',
    [USER]: 'app.roles.user'
};

export const rolesSelectOptions = [
    {
        id: [USER],
        value: ROLE_TITLES[USER],
    }, {
        id: [COMPANY_MANAGER],
        value: ROLE_TITLES[COMPANY_MANAGER],
    }
];

export const rolesAdminSelectOptions = [
    {
        id: [ADMIN],
        value: ROLE_TITLES[ADMIN],
    }, {
        id: [ADMIN_COMPANY],
        value: ROLE_TITLES[ADMIN_COMPANY],
    }
];

export const rolesFullSelectOptions = [
    ...rolesSelectOptions,
    {
        id: [ADMIN_COMPANY],
        value: ROLE_TITLES[ADMIN_COMPANY],
    }
];

export const userHiddenColumns = ['enabled'];

export const enabledState = [
    { value: 'bot.subscribed', id: true },
    { value: 'userAdmin.blocked', id: false },
];
