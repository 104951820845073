import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, message, Popconfirm, Select } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { assoc, includes, path, filter } from 'ramda';

import { DICTIONARY } from '../../../../constants/urls';
import ModalsContext from '../../../../contexts/ModalsContext';
import { OPEN_DICTIONARY_ITEM_MODAL } from '../../../../constants/actionTypes';
import withUserCompany from '../../../hocs/withUserCompany';
import TableList from '../../../table/TableList';
import { AVAILABLE_LANGUAGES } from '../../../../constants/dictionary';
import { deleteDictionaryHandler } from '../../../../actions/handlers';
import ActionsContext from '../../../../contexts/ActionsContext';

const DictionaryTab = (props) => {
    const { t } = useTranslation();
    const modals = useContext(ModalsContext);
    const [language, setLanguage] = useState(['en', 'ua']);
    const { revalidateAction } = useContext(ActionsContext);

    const { match: { params: { dictionaryType } } } = props;
    const actionUrl = DICTIONARY.stringify({ type: dictionaryType });

    const getLanguageColumn = index => {
        return {
            title: <div>
                { t('settings.chooseLanguage') }
                <Select
                    value={language[index]}
                    onChange={lang => {
                        const value = assoc(index, lang, language);
                        setLanguage(value);
                    }}
                    style={{ width: 90, marginLeft: 10 }}>
                    { filter(lang => lang === language[index] || !includes(lang, language), AVAILABLE_LANGUAGES).map(lang =>
                        <Select.Option key={`language-${lang}-${index}`} value={lang}>
                            { lang.toUpperCase() } <span className={`flag flag-${lang}`} />
                        </Select.Option>
                    )}
                </Select>
            </div>,
            key: 'translation',
            render: path(['translations', 'name', language[index]])
        };
    }

    const getColumns = () => {
        return [
            {
                title: t('settings.name'),
                dataIndex: 'name',
                key: 'title'
            },
            getLanguageColumn(0),
            getLanguageColumn(1),
            {
                key: 'controls',
                width: 100,
                render: item => (item.company && props.isCompanyAdmin || props.isAdminOnly) && (
                    <Button.Group>
                        <Button
                            className={`edit-item-btn`}
                            icon={<EditOutlined />}
                            onClick={() => modals.open(OPEN_DICTIONARY_ITEM_MODAL, {
                                dictionaryType,
                                item: {...item, value: item.name},
                                isDictionary: true
                            })}
                        />
                        <Popconfirm
                            title={t('settings.dictionaryItemDeleteConfirm')}
                            okText={t('settings.yes')}
                            cancelText={t('settings.no')}
                            onConfirm={() =>
                                deleteDictionaryHandler(item.id).then(() => {
                                    message.success(t('settings.deleteDictionaryItemSuccess'));
                                    revalidateAction(actionUrl);
                                })
                                .catch(() => message.error('settings.deleteDictionaryItemError'))
                            }
                            placement='left'
                        >
                            <Button
                                className={`delete-item-btn`}
                                icon={<DeleteOutlined />}
                                danger />
                        </Popconfirm>
                    </Button.Group>
                )
            },
        ];
    };

    const getButtons = () => {
        return (props.isCompanyAdmin || props.isAdminOnly) ?
            <Button
                id='add-item-btn'
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => modals.open(OPEN_DICTIONARY_ITEM_MODAL, { dictionaryType, isDictionary: true })}>
                { t('settings.add') }
            </Button> : null;
    }

    return <TableList
        actionUrl={actionUrl}
        param={dictionaryType}
        initFilters={{ type: dictionaryType, company: props.companyId }}
        headers={{
            'Accept-Language': 'ru',
        }}
        columns={getColumns()}
        pagination={{
            offset: 0,
            limit: 0,
        }}
        sorting={{
            field: 'title',
            order: 'asc',
        }}
        buttons={getButtons()}
        rowKey='id' />;
}

export default withUserCompany(DictionaryTab);

