import { useTranslation } from 'react-i18next';

import { getMoneyRange } from '../../utils/fieldsHelpers';

export default ({ min, max, viewType = 'minMax', value }) => {
    const { t } = useTranslation();

    return value ? value : getMoneyRange(min, max, viewType, t);
};

export const ApplicantMoney = ({ field }) => {
    const { value, currencyCode } = field;

    return value && currencyCode ? `${value} ${currencyCode}` : null;
};
