import React from 'react';
import { prop, isEmpty, head, pathOr } from 'ramda';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { getBotsPageLink } from '../../../utils/botLink';
import QrModal from '../qr/QrModal';
import { BANNER, BOT } from '../../../constants/urls';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const BotModal = props => {
    const { t } = useTranslation();
    const bots = useCompanyCodeSwr(BOT.stringify());
    const items = pathOr([], ['data', 'items'], bots);
    const { params: { vacancy } } = props;
    const banners = useCompanyCodeSwr([BANNER.stringify(), vacancy.id], (url, id) => axios.get(url, {
        params: {
            filter: {
                vacancy: id
            },
            relations: ['vacancy', 'vacancyUnit']
        }
    }));
    const botItems = items && items.filter(botItem => prop('subscribed', botItem));
    const isSubscribed = bots.data && !isEmpty(botItems);

    const getData = () => {
        const data = head(pathOr([], ['data', 'items'], banners));

        return data || {
            vacancy: vacancy.id,
            name: vacancy.title
        };
    }

    return <QrModal
        {...props}
        data={getData()}
        title={t('bot.printBot')}
        qr={getBotsPageLink(vacancy.id, vacancy.user)}
        renderContent={node =>
            banners.isValidating || bots.isValidating ?
                <Spin className='central-spin' /> :
            !!banners.data && bots.data && isSubscribed ?
                node :
                <div className='empty-bots'>{ t('bot.noActiveBots') }</div>
        } />;
}

export default BotModal;
