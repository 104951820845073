import React from 'react';
import { Field } from 'react-final-form';
import { prop, assocPath, compose } from 'ramda';
import { useTranslation } from 'react-i18next';

import Select from '../../../forms/formComponents/Select';
import withFormWrapper from '../../../hocs/withFormWrapper';
import DeleteButton from './DeleteButton';
import { CHAT_SETTINGS, MESSAGE_NODE, VACANCY } from '../../../../constants/urls';
import { putChatSettingsHandler, putMessageNodeHandler } from '../../../../actions/handlers';
import useCompanyCodeSwr from '../../../../utils/useCompanyCodeSwr';

const addFromMenuParam = assocPath(['mainMenu', 'items', 0, 'payload', 'fromMenu'], true);

const VacancyBlockComponent = props => {
    const { t } = useTranslation();

    const onChange = () => {
        setTimeout(() => props.form.submit());
    }

    const { name, parent, buttonIndex, items, setItems } = props;

    return <div className='message-nodes-block'>
        <DeleteButton
            parent={parent}
            buttonIndex={buttonIndex}
            items={items}
            setItems={setItems} />
        <Field
            name={name}
            component={Select}
            label={t('settings.vacancy')}
            actionUrl={VACANCY.stringify()}
            onChange={onChange}
            namePath='title'
            filter={{ published: true }}
            disableClear />
    </div>;
}

const VacancyBlock = withFormWrapper(VacancyBlockComponent, {
    mapPropsToValues: compose(addFromMenuParam, prop('item')),
    enableReinitialize: true
});

const VacancyBlockForm = props => {
    const item = useCompanyCodeSwr(props.parent ? MESSAGE_NODE.stringify({ id: props.parent }) : CHAT_SETTINGS.stringify());

    return <VacancyBlock
        {...props}
        formAction={props.parent ? putMessageNodeHandler : putChatSettingsHandler}
        onSuccess={() => item.mutate()}
        item={item.data} />;
}

export default VacancyBlockForm;

