export const ACTIVE_ID = 'active'
export const ARCHIVE_ID = 'archive'
export const EXPERIED_ID = 'expired'
export const ALL_ID = 'all'

export const DOMAIN_STATE = 'withDomainOnly';
export const VACANCY_STATE = 'withVacancyOnly';

export const PASSED_STATE = 'passed';
export const NOPASSED_STATE = 'nopassed';
export const NOBARIER_STATE = 'nobarier';

export const tabName = {
    general: 'vacancy.detail.tabs.general',
    form: 'vacancy.detail.tabs.form',
    dialog: 'vacancy.detail.tabs.dialog',
    terminal: 'vacancy.detail.tabs.terminal',
    opd: 'vacancy.detail.tabs.opd',
    slider: 'vacancy.detail.tabs.slider',
    landings: 'vacancy.detail.tabs.landings'
};

export const validateFields = {
    general: ['title', 'code', 'description', 'salaryViewType', 'maxSalary', 'minSalary', 'datePublishedStart', 'datePublishedEnd'],
    form: ['questions'],
    landing: ['title', 'datePublishedEnd'],
};

export const serverValidateFields = [
    ['code']
];

export const enabledState = [
    { value: 'vacancy.detail.active', id: ACTIVE_ID },
    { value: 'vacancy.detail.archive', id: ARCHIVE_ID },
    { value: 'vacancy.detail.experied', id: EXPERIED_ID },
    { value: 'vacancy.detail.all', id: ALL_ID }
];

export const companyState = [
    { value: 'vacancy.detail.active', id: true },
    { value: 'vacancy.detail.inactive', id: false },
    { value: 'vacancy.detail.all', id: null }
];

export const applicantState = [
    { value: 'vacancy.detail.allApplicants', id: null },
    { value: 'vacancy.detail.withoutVacancy', id: DOMAIN_STATE },
    { value: 'vacancy.detail.withVacancy', id: VACANCY_STATE }
];

export const applicantTypes = [
    { value: 'vacancy.detail.allPublicationTypes', id: null },
    { value: 'vacancy.detail.domain', id: 'domain', permission: 'domain' },
    { value: 'vacancy.detail.landing', id: 'landing', permission: 'landing' },
    { value: 'vacancy.detail.chatBot', id: 'chat', permission: 'bot' },
    { value: 'vacancy.detail.terminal', id: 'terminal', permission: 'terminal' },
];

export const rejectedState = [
    { value: 'vacancy.detail.allBarierStatuses', id: null },
    { value: 'vacancy.detail.passedBarierQuestions', id: PASSED_STATE },
    { value: 'vacancy.detail.noPassedBarierQuestions', id: NOPASSED_STATE },
    { value: 'vacancy.detail.noBarierQuestions', id: NOBARIER_STATE },
];

export const OPD_FIELD = ({
    customizable: false,
    field: 'personalDataAgreement',
    label: 'Согласие на ОПД', // t('vacancy.detail.opd1'),
    question: 'Для продолжения мне нужно получить от вас согласие на обработку ваших персональных данных', //t('vacancy.detail.opdQuestion'),
    required: true,
    settings: {},
    translations: {
        label: {
            en: 'Personal data processing',
            ua: 'Згода на ОПД',
            et: 'Asmens duomenų tvarkymas',
            lv: 'Personas datu apstrāde',
            lt: 'Isikuandmete töötlemine',
        },
        question: {
            en: 'I need to get consent to personal data processing from you to continue',
            ua: 'Для продовження мені необхідно отримати вашу згоду на обробку персональних даних',
            et: 'Norėdamas tęsti, turiu gauti jūsų sutikimą tvarkyti jūsų asmens duomenis',
            lv: 'Lai turpinātu, man jāsaņem jūsu piekrišana jūsu personas datu apstrādei',
            lt: 'Jätkamiseks pean saama teie nõusoleku teie isikuandmete töötlemiseks',
        },
    },
    type: 'personalDataAgreement'
});

export const salaryViewType = [
    { value: 'vacancy.detail.salaryViewType.minMax', id: 'minMax' },
    { value: 'vacancy.detail.salaryViewType.fixed', id: 'fixed' },
];

export const salaryPeriod = [
    { value: 'vacancy.detail.salaryPeriod.hour', id: 'hour' },
    { value: 'vacancy.detail.salaryPeriod.shift', id: 'shift' },
    { value: 'vacancy.detail.salaryPeriod.month', id: 'month' }
];

export const PUBLISHED_TOOLTIPS = {
    botPublished: 'vacancy.publishedTooltips.botPublished',
    terminalPublished: 'vacancy.publishedTooltips.terminalPublished',
    sliderPublished: 'vacancy.publishedTooltips.sliderPublished',
    'publicationSettings.domain': 'vacancy.publishedTooltips.domain',
    'publicationSettings.bot': 'vacancy.publishedTooltips.bot',
    'publicationSettings.terminal': 'vacancy.publishedTooltips.terminal',
    'publicationSettings.landing': 'vacancy.publishedTooltips.landing'
};

export const USER_PUBLISHED_TOOLTIPS = {
    'publicationSettings.domain': 'userAdmin.domains',
    'publicationSettings.bot': 'userAdmin.chatBots',
    'publicationSettings.terminal': 'userAdmin.terminal',
    'publicationSettings.landing': 'userAdmin.landings'
};

export const LANDINGS_CREATED = user => ([
    { id: user, value: 'landing.myLandings' },
    { id: null, value: 'landing.allLandings' }
]);
