import React, { useCallback, useState } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { useTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, message } from 'antd';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import InputNumber from './formComponents/InputNumber';
import Checkbox from './formComponents/Checkbox';
import ListenerField from './ListenerField';
import { putCompanyPasswordResetHandler } from '../../actions/handlers';

const HeadGroup = styled.div`
    margin: -1px 0 24px;
    background: #f5f4f4;
    color: #444c63;
    font-size: 15px;
    height: 54px;
    line-height: 54px;
    padding: 0 16px;
    font-weight: 500;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
`;

const Label = styled.span`
    font-weight: 500;
`;

const SecuritySettingsForm = (props) => {
    const [resetPending, setResetPending] = useState(false);
    const { t } = useTranslation();
    const passwordResetCallback = useCallback(() => {
        setResetPending(true);
        putCompanyPasswordResetHandler({ company: props.companyId })
            .then(() => message.success(t('guest.resetLinkSent')))
            .catch(() => message.success(t('vacancy.list.error')))
            .finally(() => setResetPending(false));
    });

    return <div>
        <HeadGroup>{ t('guest.password') }</HeadGroup>
        <div className='about-company-wrapper'>
            <Field
                className='file-size-input'
                name='minPasswordLength'
                component={InputNumber}
                label={t('settings.minPasswordLength')}
                min={1}
                max={50}
                disableClear
                disabled={resetPending}
            />
            <Button onClick={passwordResetCallback} loading={resetPending}>
                { t('settings.resetCompanyUsersPassword') }
            </Button>
            <br />
            <br />
            <Field
                name='passwordResetPeriodEnabled'
                component={Checkbox}
                placeholder={<Label>{t('settings.passwordResetPeriodEnabled')}</Label>}
                onChange={value => props.form.change(`passwordResetPeriodDays`, value ? 0 : null)}
            />
            <ListenerField listenFieldName='passwordResetPeriodEnabled'>
                { ({ passwordResetPeriodEnabled }) =>
                    <Field
                        className='file-size-input'
                        name='passwordResetPeriodDays'
                        component={InputNumber}
                        addonAfter={t('settings.days')}
                        min={0}
                        max={999}
                        disableClear
                        disabled={!passwordResetPeriodEnabled}
                    />
                }
            </ListenerField>
            <div className='modal-form-toolbar'>
                <SubmitButton disabled={resetPending}>
                    { t('form.save') }
                </SubmitButton>
            </div>
        </div>
    </div>;
}

const validationSchema = props => yup.object().shape({
    minPasswordLength: yup.number().nullable().min(1, props.t('settings.minimum1')).max(50, props.t('settings.max50')),
    passwordResetPeriodEnabled: yup.bool().nullable(),
    passwordResetPeriodDays: yup.number().when('passwordResetPeriodEnabled', {
        is: true,
        then: yup.number().nullable().min(1, props.t('settings.minimum1')).max(999).required(),
        otherwise: yup.number().nullable(),
    }),
});

export default withTranslation()(withFormWrapper(SecuritySettingsForm, {
    mapPropsToValues: ({ item }) => ({
        ...item,
    }),
    validationSchema,
}));
