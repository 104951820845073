import React from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

import RegistrationConfirmForm from '../../forms/RegistrationConfirmForm';
import { postRegistrationConfirmHandler } from '../../../actions/handlers';
import { REGISTRATION_CONFIRM } from '../../../constants/urls';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const RegistrationConfirm = props => {
    const { t } = useTranslation();
    const id = props.match.params.id;
    const registrationConfirm = useCompanyCodeSwr(REGISTRATION_CONFIRM.stringify({ id: props.match.params.id }));

    return registrationConfirm.error ? (
        <div>
            { t('guest.linkInactive') }
        </div>
    ) : (
        <div>
            <h3>{ t('guest.pickPassword') }</h3>
            <RegistrationConfirmForm
                formAction={postRegistrationConfirmHandler}
                id={id}
                onSuccess={() => {
                    notification.success({
                        message: t('guest.registration'),
                        description: t('guest.registrationSuccess'),
                    });
                    props.history.push('/');
                }}
                onSubmitFail={() => notification.error({
                    message: t('guest.registration'),
                    description: t('guest.registrationError'),
                })}
            />
        </div>
    );
}

export default RegistrationConfirm;
