import React from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { useTranslation, withTranslation } from 'react-i18next';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import InputNumber from './formComponents/InputNumber';
import { MIN_FILE_SIZE } from '../../constants/form';
import FileTypes from './formComponents/FileTypes';
import { document, image } from '../../constants/files';

const FileSettingsForm = () => {
    const { t } = useTranslation();

    return <div className='about-company-wrapper'>
        <Field
            className='file-size-input'
            name='maxFileUploadSize'
            component={InputNumber}
            label={t('settings.maxFileSize')}
            min={2}
            disableClear
        />
        <Field
            name='allowFileExtensions.image'
            component={FileTypes}
            label={t('settings.imageTypes')}
            defaultTypes={ image }
        />
        <Field
            name='allowFileExtensions.document'
            component={FileTypes}
            label={t('settings.docTypes')}
            defaultTypes={ document }
        />
        <div className='modal-form-toolbar'>
            <SubmitButton>
                { t('form.save') }
            </SubmitButton>
        </div>
    </div>;
}

const validationSchema = props => yup.object().shape({
    maxFileUploadSize: yup.number().nullable().min(2, props.t('settings.minimum2')).required()
});

export default withTranslation()(withFormWrapper(FileSettingsForm, {
    mapPropsToValues: ({ item }) => ({
        ...item,
        maxFileUploadSize: (item.maxFileUploadSize || MIN_FILE_SIZE) / 1024 / 1024,
    }),
    mapBeforeSubmit: (values) => ({
        ...values,
        maxFileUploadSize: values.maxFileUploadSize ? values.maxFileUploadSize * 1024 * 1024 : undefined,
    }),
    validationSchema
}));
