import React, { useState } from 'react';
import { Dropdown, Menu, message } from 'antd';
import { assocPath, findIndex } from 'ramda';
import { useTranslation } from 'react-i18next';

import MESSAGE_TRANSITIONS, { MESSAGE_NODE } from '../../../../constants/messageTransitions';
import * as urls from '../../../../constants/urls';
import { postMessageNodeHandler, putChatSettingsHandler, putMessageNodeHandler } from '../../../../actions/handlers';
import useCompanyCodeSwr from '../../../../utils/useCompanyCodeSwr';

const StateNameMenu = props => {
    const { t } = useTranslation();
    const parentItem = props.item.parent ? useCompanyCodeSwr(urls.MESSAGE_NODE.stringify({ id: props.item.parent })) : null;
    const [putPending, setPutPending] = useState(false);
    const [postPending, setPostPending] = useState(false);
    const [putChatPending, setPutChatPending] = useState(false);

    const getMenu = () => {
        return (
            <Menu>
                { MESSAGE_TRANSITIONS.map(transition =>
                    <Menu.Item
                        key={transition.id}
                        onClick={() => setStateName(transition.id)}
                    >
                        { t(transition.value) }
                    </Menu.Item>
                )}
            </Menu>
        );
    }

    const setStateName = stateName => {
        const { items, setItems, chatSettings, item, revalidateChatSettings } = props;

        if (stateName === MESSAGE_NODE) {
            setPostPending(true);
            postMessageNodeHandler({
                botMessages: [
                    { type: 'text', data: { text: '' } }
                ]
            }).then(data => {
                const { item: { buttonIndex, parent }, chatSettings, items, setItems } = props;
                const payload = {
                    messageNode: data.id,
                    stateName: MESSAGE_NODE
                };

                setPostPending(false);

                if (parent) {
                    setPutPending(true);
                    putMessageNodeHandler({
                        id: parent,
                        data: assocPath(['botMessages', 0, 'data', 'items', buttonIndex, 'payload'], {
                            ...parentItem.data.botMessages[0].data.items[buttonIndex].payload,
                            ...payload
                        }, parentItem.data)
                    }).then(() => setPutPending(false)).catch(() => setPutPending(false));
                } else {
                    setPutChatPending(true);
                    putChatSettingsHandler(assocPath(['mainMenu', 'items', buttonIndex, 'payload'], {
                        ...chatSettings.mainMenu.items[buttonIndex].payload,
                        ...payload
                    }, chatSettings)).then(() => {
                        setPutChatPending(false);
                        revalidateChatSettings();
                    }).catch(() => {
                        setPutChatPending(false);
                        message.error(t('settings.contentSaveError'))
                    });
                }

                const index = findIndex(i => !i.id, items);
                setItems(assocPath([index], {
                    ...items[index], id: data.id, stateName: MESSAGE_NODE, created: true
                }, items));
            }).catch(() => setPostPending(false));
        } else {
            if (parentItem) {
                setPutPending(true);
                putMessageNodeHandler({
                    id: parent,
                    data: assocPath(['botMessages', 0, 'data', 'items', item.buttonIndex, 'payload', 'stateName'], stateName, parentItem.data)
                })
                    .then(() => setPutPending(false)).catch(() => setPutPending(false));
            } else {
                putChatSettingsHandler(assocPath(['mainMenu', 'items', item.buttonIndex, 'payload', 'stateName'], stateName, chatSettings))
                    .then(() => revalidateChatSettings()).catch(() => message.error(t('settings.contentSaveError')));
            }

            const index = findIndex(i => !i.id, items);
            const updatedItems = assocPath([index], {
                ...items[index], stateName, created: true,
            }, items);

            setItems(updatedItems);
        }
    }

    const { pending } = props;
    const disabled = pending || postPending || putChatPending || putPending;

    return <div className='message-nodes-transitions'>
        <p>{ t('settings.chooseActionForButton') }</p>
        <Dropdown.Button
            onClick={() => setStateName(MESSAGE_NODE)}
            overlay={getMenu()}
            trigger={['click']}
            disabled={disabled}
        >
            { t('settings.showMessage') }
        </Dropdown.Button>
    </div>;
}

export default StateNameMenu;
