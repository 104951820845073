import React from 'react';
import { all, equals, prop } from 'ramda';
import { Card } from 'antd';

import logo from '../../../images/logo-bot.svg';
import { getUrl } from '../../../utils/http';
import { getMenuText } from '../../../utils/chat';
import { FILE } from '../../../constants/urls';
import ChatTime from './ChatTime';

const BotResponse = props => {
    const renderActions = (actions = []) => actions.filter(Boolean).map((action, index) => (
        <div
            key={index}
            className='dialog-vacancy-button'
            onClick={() => sendMessage(action.text, action.payload)}
        >
            {action.text}
        </div>
    ))

    const sendMessage = (text, payload) => {
        const { dispatchMessage, focusInput } = props;
        const url = prop('url', payload);

        if (url) {
            return window.open(url, '_blank');
        }

        if (dispatchMessage) {
            focusInput();
            return dispatchMessage({ text, payload })
        } else {
            return null;
        }
    }

    const renderText = (outputItem, index) => {
        const { text, actions, suggestions } = outputItem.data;
        const innerHtml = { __html: text };

        return (
            <div className='dialog-item' key={index}>
                <div
                    className='dialog-text'
                    dangerouslySetInnerHTML={innerHtml}
                />
                { Array.isArray(suggestions) && (
                    <ul className='dialog-list'>
                        { suggestions.map((listItem, index) => (
                            <li
                                className='dialog-list-item'
                                key={index}
                                onClick={() => sendMessage(getMenuText(listItem.text), listItem.payload)}
                            >
                                <span dangerouslySetInnerHTML={{__html: listItem.text}}></span>
                            </li>
                        ))}
                    </ul>
                )}
                { renderActions(actions)}
            </div>
        );
    }

    const renderList = (outputItem, index) => {
        const { items = [], actions, text } = outputItem.data;

        return (
            <div className='dialog-item' key={index}>
                <span className='dialog-list-text'>
                    {text}
                </span>
                <ul className='dialog-list'>
                    { items.map((listItem, index) => (
                        <li
                            className='dialog-list-item'
                            key={index}
                            onClick={() => sendMessage(getMenuText(listItem.text), listItem.payload)}
                        >
                            <span dangerouslySetInnerHTML={{__html: listItem.text}}></span>
                        </li>
                    ))}
                </ul>
                {renderActions(actions)}
            </div>
        );
    }

    const renderMenu = (outputItem, index, array) => {
        const { items = [], text } = outputItem.data;
        const { withoutMenu } = props;

        if (index === 0 && array.length === 1) {
            return (
                <div className='dialog-item menu' key={index}>
                    { text && (
                        <div
                            className='dialog-menu-text'
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    )}
                    { withoutMenu ? null : (
                        <ul className='dialog-menu'>
                            { items.map((listItem, index) => (
                                <li className='dialog-menu-item' key={index}>
                                    <span dangerouslySetInnerHTML={{__html: listItem.text}}></span>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            );
        }
    }

    const renderVacancy = (outputItem, index) => {
        const { title, description, actions } = outputItem.data;
        const [ action ] = actions;
        const text = { __html: description };

        return (
            <div className='dialog-item' key={index}>
                <div className='dialog-vacancy'>
                    <div className='dialog-vacancy-title'>{title}</div>
                    <div className='dialog-vacancy-description' dangerouslySetInnerHTML={text} />
                    { action && (
                        <div className='dialog-vacancy-button' onClick={() => sendMessage(action.text)}>
                            {action.text}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    const renderError = (outputItem, index) => {
        const { text, suggestions } = outputItem.data;

        return (
            <div className='dialog-item' key={index}>
                <div className='dialog-text'>
                    {text}
                </div>
                { Array.isArray(suggestions) && (
                    suggestions.map((suggestionsItem, index) => {
                        const { text } = suggestionsItem;

                        return (
                            <div
                                key={index}
                                className='dialog-vacancy-button'
                                onClick={() => sendMessage(text)}
                            >
                                {text}
                            </div>
                        );
                    })
                )}
            </div>
        );
    }

    const getItem = (item, index, array) => {
        switch (item.type) {
            case 'text':
                return renderText(item, index, array);
            case 'list':
                return renderList(item, index, array);
            case 'menu':
                return renderMenu(item, index, array);
            case 'vacancy':
                return renderVacancy(item, index, array);
            case 'error':
                return renderError(item, index, array);
            default:
                return null;
        }
    }

    const { createdAt, output = [] } = props.data;
    const { botName, botAvatar } = props;
    const dialogItems = output.map((item, index, array) => (
        getItem(item, index, array)
    ));

    const emptyCard = all(equals(null))(dialogItems);

    return !emptyCard && (
        <div className='dialog-row-bot'>
            <div className='dialog-message dialog-message-bot'>
                <Card className='dialog-card dialog-card-bot'>
                    <div className='dialog-card-header'>
                        <div className='dialog-card-user'>{botName}</div>
                    </div>
                    <div className='dialog-items'>
                        {dialogItems}
                    </div>
                    <div className='bot-avatar'>
                        <div className='avatar-logo-wrapper' style={{ backgroundImage: `url(${
                            botAvatar ?
                                getUrl(FILE, { id: botAvatar }) :
                                logo
                            })`
                        }}>
                        </div>
                        <ChatTime timeValue={createdAt} />
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default BotResponse;
