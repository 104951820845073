import React from 'react';
import { Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { pathOr } from 'ramda';

import FileSettingsForm from '../../forms/FileSettingsForm';
import { putCompanySettingsHandler } from '../../../actions/handlers';
import { COMPANY_SETTINGS } from '../../../constants/urls';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const FileSettings = () => {
    const { t } = useTranslation();
    const companySettings = useCompanyCodeSwr(COMPANY_SETTINGS.stringify());

    return companySettings.data ?
        <FileSettingsForm
            formAction={putCompanySettingsHandler}
            item={pathOr({}, ['data'], companySettings)}
            onSuccess={() => message.success(t('settings.fileSettingsEditSuccess'))}
            onSubmitFail={() => message.error(t('settings.fileSettingsEditError'))} />
        : <Spin className='central-spin' spinning={companySettings.isValidating} />;
}

export default FileSettings;
