export const parseGeoObject = geoData => {
    const geoObject = geoData.geoObjects.get(0);
    const location = geoObject && geoObject.geometry.getCoordinates();
    const address = geoObject && geoObject.properties.get('text');

    return {
        location,
        address,
    };
};
