import React from 'react';
import { Menu, Dropdown } from 'antd';
import {
    TelegramShareButton,
    WhatsappShareButton,
    ViberShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    VKShareButton,
    TelegramIcon,
    WhatsappIcon,
    ViberIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    VKIcon,
} from 'react-share';
import { ShareAltOutlined } from '@ant-design/icons';
import qs from 'qs';

const SocialShare = props => {
    const getUrl = source => {
        const params = props.utmReady ? '' : qs.stringify({
            utm_source: source,
            utm_medium: 'free',
            utm_campaign: 'jobot-share',
        }, { addQueryPrefix: true });

        return `${props.url}${params}`;
    }

    const renderSocials = () => {
        const { vacancyTitle } = props;

        return (
            <Menu>
                <Menu.Item>
                    <FacebookShareButton url={getUrl('facebook')}>
                        <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                </Menu.Item>
                <Menu.Item>
                    <LinkedinShareButton url={getUrl('linkedin')} title={vacancyTitle}>
                        <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                </Menu.Item>
                <Menu.Item>
                    <TwitterShareButton url={getUrl('twitter')} title={vacancyTitle}>
                        <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                </Menu.Item>
                <Menu.Item>
                    <VKShareButton url={getUrl('vk')} title={vacancyTitle}>
                        <VKIcon size={32} round={true} />
                    </VKShareButton>
                </Menu.Item>
                <Menu.Item>
                    <TelegramShareButton url={getUrl('telegram')} title={vacancyTitle}>
                        <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                </Menu.Item>
                <Menu.Item>
                    <WhatsappShareButton url={getUrl('whatsapp')} title={vacancyTitle}>
                        <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                </Menu.Item>
                <Menu.Item>
                    <ViberShareButton url={getUrl('viber')} title={vacancyTitle}>
                        <ViberIcon size={32} round={true} />
                    </ViberShareButton>
                </Menu.Item>
            </Menu>
        );
    }

    return (
        <Dropdown
            overlay={renderSocials()}
            placement='bottom'
        >
            <div style={props.style}>
                <ShareAltOutlined />
            </div>
        </Dropdown>
    );
}

export default SocialShare;
