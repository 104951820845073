import React from 'react';
import { Table } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import { LINES_PER_PAGE } from '../../constants/lists';
import { TABLE_LOADING_DELAY } from '../../constants/tableColumns';
import { getUrlPagination, extendSearchPath, getUrlParams } from '../../utils/urlParams';
import { append, isEmpty, path, pathOr, without } from 'ramda';

const TableComponent = props => {
    const location = useLocation();
    const history = useHistory();

    const getPaginationConfig = () => {
        const { action, setOffset, setLimit, urlPrefix, pagination } = props;
        const _meta = path(['data', '_meta'], action);

        const { offset = 0, limit = LINES_PER_PAGE } = pagination || getUrlPagination(location, urlPrefix);

        if (!_meta || _meta.count <= limit) {
            return false;
        }

        return {
            total: _meta.count,
            pageSize: Number(limit),
            current: (Number(offset) / Number(limit)) + 1,
            defaultCurrent: 0,
            onChange: (page, pageSize) => setOffset((page - 1) * pageSize, pageSize),
            onShowSizeChange: (_, pageSize) => setLimit(pageSize)
        };
    }

    const onExpand = (expanded, row) => {
        let expandedItems = null;

        if (props.multipleExpanded) {
            const currentExpanded = getUrlParams(location).expanded || [];
            expandedItems = expanded ? append(row.id, currentExpanded) : without([row.id], currentExpanded);
            expandedItems = isEmpty(expandedItems) ? null : expandedItems;
        } else {
            expandedItems = expanded ? row.id : null
        }

        history.replace(extendSearchPath(location, { expanded: expandedItems }));
    }

    const pagination = getPaginationConfig();
    const {
        action: { data, isValidating },
        columns,
        scroll,
        rowKey = 'id',
        onRow,
        rowSelection,
        expandedRowRender,
        parseItems,
        tableLayout,
        footer,
        multipleExpanded,
        noAutoFetch,
        noFetchMessage,
    } = props;
    const expanded = getUrlParams(location).expanded;
    const dataSource = parseItems ? parseItems(pathOr(data || [], ['items'], data)) : pathOr(data || [], ['items'], data);

    const loading = {
        spinning: isValidating,
        delay: TABLE_LOADING_DELAY,
    };

    const conditionProps = expandedRowRender ? {
        expandedRowRender
    } : {
        scroll: scroll ? { x: scroll } : { x: false }
    };

    return (
        <Table
            tableLayout={tableLayout}
            columns={columns}
            dataSource={dataSource}
            pagination={pagination}
            loading={loading}
            rowKey={rowKey}
            onRow={onRow}
            rowSelection={rowSelection}
            expandedRowKeys={multipleExpanded ? (expanded || []) : (expanded ? [expanded] : [])}
            onExpand={onExpand}
            footer={footer}
            {...conditionProps}
            locale={noAutoFetch ? {
                emptyText: noFetchMessage,
            } : undefined}
        />
    );
}

export default TableComponent;
