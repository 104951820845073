import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { useTranslation, withTranslation } from 'react-i18next';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import Select from './formComponents/Select';
import { USERS_ADMIN } from '../../constants/urls';
import { getFullName } from '../../utils/fieldsHelpers';

const ResponsibleForm = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Field
                name='responsibleBy'
                component={Select}
                label={t('vacancy.detail.responsible')}
                actionUrl={USERS_ADMIN.stringify()}
                getNamePath={getFullName}
                searchKey='name'
                sorting={{
                    field: 'lastName',
                    order: 'asc',
                }}
                searchable />
            <SubmitButton>
                { t('form.save') }
            </SubmitButton>
        </Fragment>
    );
}

const validationSchema = yup.object().shape({
    responsibleBy: yup.string().nullable().required()
});

export default withTranslation()(withFormWrapper(ResponsibleForm, {
    mapPropsToValues: ({ item }) => item,
    validationSchema
}));
