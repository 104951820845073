import { filter, keys, pathOr } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getFullName } from '../../../utils/fieldsHelpers';
import UserRolesList from '../../table/UserRolesList';

const publicationTypes = {
    'domain': 'domains',
    'bot': 'chatBots',
    'terminal': 'terminal',
    'landing': 'landings'
};

const HeadGroup = styled.div`
    margin: 0 -15px 10px;
    background: #f5f4f4;
    color: #444c63;
    font-size: 15px;
    height: 54px;
    line-height: 54px;
    padding: 0 16px;
    font-weight: 500;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
`;

const Line = styled.div`
    margin: 3px 0 10px;
`;

const UserInfo = props => {
    const { t } = useTranslation();
    const { user } = props;
    const company = pathOr(null, ['_relations', 'company'], user);
    const companies = pathOr(null, ['_relations', 'companies'], user);
    const juristicEntity = pathOr(null, ['_relations', 'juristicEntity'], user);
    const publicationSettings = keys(filter(item => !!item, user.publicationSettings || {}));

    return <div style={{ padding: 15, paddingTop: 0 }}>
        <HeadGroup>{ t('settings.main') }</HeadGroup>
        <Line><strong>{ t('userAdmin.user') }:</strong> { getFullName(user) }</Line>
        { !!companies && <Line>
            <strong>{ t('userAdmin.company') }:</strong> { companies.map(
                company => company.name
            ).join(', ') }
        </Line>}
        { !!company && <Line>
            <strong>{ t('userAdmin.company') }:</strong> { company.name }
        </Line>}
        { !!juristicEntity && <Line>
            <strong>{ t('company.juristicEntity') }:</strong> { juristicEntity.title }
        </Line>}
        <Line><strong>Email:</strong> { user.username }</Line>
        { !!user.phone && <Line><strong>{ t('userAdmin.phone') }:</strong> { user.phone }</Line>}
        <HeadGroup>{ t('applicant.additionalInfo') }</HeadGroup>
        <Line style={{ display: 'flex' }}><strong>{ t('userAdmin.role') }: </strong>&nbsp;{ <UserRolesList roles={user.roles} /> }</Line>
        { !!publicationSettings.length && <Line><strong>{ t('userAdmin.publicationEnabled') }:</strong> { publicationSettings.map((type, index) =>
            <span key={type}>
                { index ? `, ` : '' }{ t(`userAdmin.${publicationTypes[type]}`) }
            </span>
        )}</Line>}
    </div>
}

export default UserInfo;
