import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'antd';
import { useTranslation } from 'react-i18next';

import { getUrlWithFilters } from '../../../utils/urlParams';
import DialogFilter from '../../filters/DialogFilter';
import TableList from '../../table/TableList';
import DeepLink from '../../table/DeepLink';
import DateTime from '../../table/DateTime';
import Avatar from './Avatar';
import { DIALOGS } from '../../../constants/urls';

const initFilters = { manual: null };

const DialogList = () => {
    const { t } = useTranslation();

    const getColumns = () => {
        return [
            {
                dataIndex: 'avatar',
                key: 'avatar',
                width: 64,
                render: (avatar, { name }) =>
                    <div className='dialog-list-avatar'>
                        <Avatar avatar={avatar} name={name} />
                    </div>
            },
            {
                title: t('dialog.user'),
                dataIndex: 'name',
                key: 'name',
                render: (name, { bot, id, manual, userId, _relations: { bot: { token }}}) =>
                    <Link className='dialog-link' to={{
                        pathname: `dialogs/${bot}/messages/${id}`,
                        state: {
                            userId,
                            botToken: token
                        }
                    }}>
                        <Badge
                            status={manual ? 'processing' : 'default'}
                            text={name || 'Без имени'}
                        />
                    </Link>
            },
            {
                title: t('dialog.bot'),
                dataIndex: ['_relations', 'bot'],
                key: 'bot',
                render: bot => (
                    <span>
                        <DeepLink bot={bot} enableLink />
                        <Link to={getUrlWithFilters('/dialogs', { bot: bot.id })}>{bot.username}</Link>
                    </span>
                ),
            },
            {
                title: t('dialog.lastUpdate'),
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                render: updatedAt => <DateTime time={updatedAt} />,
            },
            {
                title: t('dialog.lastMessage'),
                dataIndex: 'lastMessage',
                key: 'lastMessage',
                width: 300,
                render: lastMessage => (
                    <div className='last-message'>
                        <span>{lastMessage}</span>
                    </div>
                ),
            },
        ];
    }

    return (
        <TableList
            actionUrl={DIALOGS.stringify()}
            columns={getColumns()}
            initFilters={initFilters}
            filterForm={DialogFilter}
            relations={['bot']}
            sorting={{
                field: 'updatedAt',
                order: 'desc',
            }}
        />
    );
}

export default DialogList;
