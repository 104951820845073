import React, { useContext, useEffect, useRef } from 'react';
import { Dropdown, Menu, Layout, Alert } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import UserRoutes from '../../UserRoutes';
import Sidebar from './Sidebar';
import withUserCompany from '../../hocs/withUserCompany';
import CompanyCodeForm from '../../forms/CompanyCodeForm';
import UserContext from '../../../contexts/UserContext';
import { changeLanguage } from '../../../utils/i18n';
import { handleTokenChange } from '../../../utils/token';
import CompanyCodeContext from '../../../contexts/CompanyCodeContext';
import ModalsContext from '../../../contexts/ModalsContext';
import withModals from '../../hocs/withModals';

const CompanyCodeContainer = styled.div`
    display: inline-block;
    width: 200px;
`;

export const UserLayoutContent = withModals(({ children }) => (
    <div className='ant-layout experium-layout'>
        <div className='ant-layout-content mainContainer'>
            {children}
        </div>
    </div>
));

const UserLayout = ({ isAdmin, isAdminCompany, isMultiCompany, stateCompanyCode, userRoles, userCompanies, companyName, isFullLicense, isFunctionalLicense, license }) => {
    const { t, i18n } = useTranslation();
    const disableRoutes = isMultiCompany && (!isAdmin || isAdminCompany) && !stateCompanyCode;
    const user = useContext(UserContext);
    const modals = useContext(ModalsContext);
    const { changeCompanyCode } = useContext(CompanyCodeContext);
    const didMount = useRef(null);
    const logout = () => {
        user.reset();
        modals.clean();
        changeCompanyCode(null);
        handleTokenChange(null);
    };

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            axios.interceptors.response.use(undefined, function (error) {
                const statusCode = error.response ? error.response.status : null;

                if (statusCode === 401) {
                    logout();
                }

                return Promise.reject(error);
            });
        }
    });

    const languageMenu = (
        <Menu onClick={({ key }) => changeLanguage(key, t)}>
            <Menu.Item key="ru">RU <span className={`flag flag-ru`} /></Menu.Item>
            <Menu.Item key="en">EN <span className={`flag flag-en`} /></Menu.Item>
            <Menu.Item key="ua">UA <span className={`flag flag-ua`} /></Menu.Item>
            <Menu.Item key="et">ET <span className={`flag flag-et`} /></Menu.Item>
            <Menu.Item key="lt">LT <span className={`flag flag-lt`} /></Menu.Item>
            <Menu.Item key="lv">LV <span className={`flag flag-lv`} /></Menu.Item>
        </Menu>
    );

    const licenseExpired = !license && !isAdmin;

    return (
        <Layout>
            <Layout.Header>
                { isMultiCompany ? (
                    <CompanyCodeContainer>
                        <CompanyCodeForm userCompanies={userCompanies} />
                    </CompanyCodeContainer>
                ) : (
                    <div className='companyName'>
                        { companyName }
                    </div>
                )}
                <div className='header-buttons-group'>
                    <Dropdown overlay={languageMenu} placement="bottom" trigger='click'>
                        <span className='lang-button'>{`${i18n.language}`.toUpperCase()}</span>
                    </Dropdown>
                    { !licenseExpired && <Link className='profile-button' to='/profile'>
                        <UserOutlined /><span>{ t('app.profile') }</span>
                    </Link>}
                    <span className='logout-button' onClick={logout}>
                        <LogoutOutlined />{ t('app.logout') }
                    </span>
                </div>
            </Layout.Header>
            { licenseExpired ?
                <div className='page-content' style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                    <Alert
                        message={<div dangerouslySetInnerHTML={{ __html: t('company.licenseExpired') }} />}
                        type='warning'
                        className='form-alert'
                        style={{ maxWidth: 500 }}
                    />
                </div> :
                <Layout>
                    <Sidebar
                        stateCompanyCode={stateCompanyCode}
                        isAdmin={isAdmin}
                        roles={disableRoutes ? [] : userRoles}
                        isFullLicense={isFullLicense}
                        isFunctionalLicense={isFunctionalLicense}
                    />
                    <Layout.Content className='mainContainer user-layout-content'>
                        <div className='user-content'>
                            <div>
                                { !disableRoutes && <UserRoutes /> }
                            </div>
                        </div>
                    </Layout.Content>
                </Layout>
            }
        </Layout>
    );
};

export default withModals(withUserCompany(UserLayout));
