import React, { useContext } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Upload, Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { forEach } from 'ramda';

import { CITY_DICTIONARY, COUNTRY_DICTIONARY, DICTIONARY, IMPORT_DICTIONARIES, REGION_DICTIONARY } from '../../../../constants/urls';
import { getUrl } from '../../../../utils/http';
import ActionsContext from '../../../../contexts/ActionsContext';

const DictionariesImportModal = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const dictionaries = [
        CITY_DICTIONARY,
        REGION_DICTIONARY,
        COUNTRY_DICTIONARY,
        DICTIONARY
    ];

    const onChangeUpload = info => {
        switch (info.file.status) {
            case 'done':
                forEach(url => revalidateAction(url.stringify()), dictionaries);
                message.success(t('settings.dictionariesUploadSuccess'));
                props.close();
                break;
            case 'error':
                message.error(t('settings.dictionariesUploadError'));
                break;
            default:
                break;
        }
    }

    return <Modal
        {...props.modal}
        title={t('settings.importAllDictionaries')}
        footer={null}
    >
        <Upload.Dragger
            accept='.xlsx'
            action={getUrl(IMPORT_DICTIONARIES)}
            onChange={onChangeUpload}
            multiple={false}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">{ t('settings.uploadFile') }</p>
            <p className="ant-upload-hint">
                .xlsx
            </p>
        </Upload.Dragger>
    </Modal>;
}

export default DictionariesImportModal;
