import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import { postCompanyLicenseHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { COMPANY } from '../../../constants/urls';
import CompanyLicenseForm from '../../forms/CompanyLicenseForm';

const CompanyLicenseModal = props => {
    const { t } = useTranslation();
    const { modal, params, close } = props;
    const { revalidateAction } = useContext(ActionsContext);

    return (
        <Modal
            {...modal}
            title={t('company.license')}
            footer={null}
        >
            <CompanyLicenseForm
                item={params}
                formAction={postCompanyLicenseHandler}
                onSuccess={() => {
                    revalidateAction(COMPANY.stringify());

                    if (params) {
                        message.success(t('company.editSuccess'));
                        close();
                    }
                }}
            />
        </Modal>
    );
}

export default CompanyLicenseModal;
