import React, { Fragment } from 'react';
import { prop, path } from 'ramda';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import withFormWrapper from '../../hocs/withFormWrapper';
import TerminalFields from './TerminalFields';
import SubmitButton from '../formComponents/SubmitButton';

const TerminalForm = props => {
    const { t } = useTranslation();

    return <Fragment>
        <TerminalFields {...props} validateOnChange />
        <div style={{ marginTop: 15 }}>
            <SubmitButton>{ t('form.save') }</SubmitButton>
        </div>
    </Fragment>;
}

const validationSchema = yup.object().shape({
    terminalSettings: yup.object().shape({
        showPhoneAllowed: yup.boolean().nullable()
    }),
    companySettings: yup.object().when('terminalSettings.showPhoneAllowed', (show, schema) =>
        show ? schema.shape({
            theme: yup.object().shape({
                phone: yup.string().phone().nullable().required()
            })
        }) : schema
    )
});

export default withFormWrapper(TerminalForm, {
    mapPropsToValues: prop('vacancy'),
    validationSchema,
    mapBeforeSubmit: values => ({
        ...values,
        address: path(['address', 'address'], values),
        location: path(['address', 'location'], values),
    }),
});
