import React, { Fragment, useState } from 'react';
import { Button, Spin, Tooltip } from 'antd';
import { prop, pathOr, path, assocPath } from 'ramda';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import cx from 'classnames';
import { LoadingOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { required } from '../../../../utils/validation';
import withFormWrapper from '../../../hocs/withFormWrapper';
import InlineEditor from './InlineEditor';
import DeleteButton from './DeleteButton';
import ButtonsField from './ButtonsField';
import { putMessageNodeHandler } from '../../../../actions/handlers';
import { MESSAGE_NODE } from '../../../../constants/urls';
import useCompanyCodeSwr from '../../../../utils/useCompanyCodeSwr';

const MessageFormComponent = props => {
    const { t } = useTranslation();

    const toggleButton = (button, buttonIndex) => {
        const { onToggleButton, id } = props;

        onToggleButton({
            parent: id,
            id: path(['payload', 'messageNode'], button),
            buttonIndex,
            stateName: path(['payload', 'stateName'], button)
        });
    }

    const toggleEdit = () => {
        const { edit, setEdit, form } = props;

        if (edit) {
            form.submit();
        } else {
            setEdit(true);
        }
    }

    const { edit, form, isValid, submitCount, pending } = props;
    const { messageNode, parent, buttonIndex, items, setItems, id, isValidating } = props;
    const invalid = edit && submitCount && !isValid;

    return <div className='message-nodes-block'>
        { isValidating && !messageNode ?
            <Spin /> :
            <Fragment>
                <DeleteButton
                    id={id}
                    parent={parent}
                    buttonIndex={buttonIndex}
                    items={items}
                    setItems={setItems} />
                <div className='message-text-container'>
                    <div className='message-text'>
                        <Field
                            name='botMessages.0.data.text'
                            component={InlineEditor}
                            readOnly={!edit}
                            validate={required}
                            placeholder={edit ? t('settings.yourText') : null} />
                    </div>
                    <Tooltip title={invalid ? t('settings.typeTextMessage') : null} placement="top">
                        <Button shape='circle' onClick={toggleEdit} className={cx({ invalid, 'save-btn': edit, 'edit-btn': !edit })}>
                            { pending ? <LoadingOutlined /> : (
                                edit ? <CheckOutlined /> : <EditOutlined />
                            )}
                        </Button>
                    </Tooltip>
                </div>
                <FieldArray
                    name='botMessages.0.data.items'
                    render={({ fields }) =>
                        <ButtonsField
                            fields={fields}
                            edit={edit}
                            onToggleButton={toggleButton}
                            submitForm={form.submit} />
                    } />
            </Fragment>
        }
    </div>;
}

const MessageForm = withFormWrapper(MessageFormComponent, {
    mapPropsToValues: ({ messageNode }) => messageNode,
    enableReinitialize: true,
    mapBeforeSubmit: (values, props) => {
        const buttons = pathOr([], ['botMessages', 0, 'data', 'items'], values);
        return {
            id: props.id,
            data: assocPath(['botMessages', 0, 'type'], buttons.length ? 'list' : 'text', values)
        };
    }
});

const Message = props => {
    const [edit, setEdit] = useState(prop('created', props));
    const messageNode = useCompanyCodeSwr(MESSAGE_NODE.stringify({ id: props.id }));

    return <MessageForm
        {...props}
        formAction={putMessageNodeHandler}
        onSuccess={() => {
            messageNode.mutate();
            setEdit(false);
        }}
        messageNode={messageNode.data}
        isValidating={messageNode.isValidating}
        setEdit={setEdit}
        edit={edit} />;
}

export default Message;
