import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { prop, assocPath, compose } from 'ramda';
import { Button, Tooltip } from 'antd';
import cx from 'classnames';
import { LoadingOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { required } from '../../../../utils/validation';
import MaskedInput from '../../../forms/formComponents/MaskedInput';
import withFormWrapper from '../../../hocs/withFormWrapper';
import withAutoFocusForm from '../../../hocs/withAutoFocusForm';
import DeleteButton from './DeleteButton';
import { CHAT_SETTINGS, MESSAGE_NODE } from '../../../../constants/urls';
import { putChatSettingsHandler, putMessageNodeHandler } from '../../../../actions/handlers';
import useCompanyCodeSwr from '../../../../utils/useCompanyCodeSwr';

const addFromMenuParam = assocPath(['mainMenu', 'items', 0, 'payload', 'fromMenu'], true);

const getUrlMask = (raw = 'https://') => {
    let maskArray;
    const textLength = raw.length;
    const maskLength = textLength - 8;
    const placeholder = ['h', 't', 't', 'p', 's', ':', '/', '/'];

    if (maskLength <= 0) {
        maskArray = [/./];
    } else {
        maskArray = new Array(maskLength).fill(/./);
    }

    return [...placeholder, ...maskArray];
};

const VacancyBlock = props => {
    const { t } = useTranslation();

    const toggleEdit = () => {
        const { edit, setEdit, form } = props;

        if (edit) {
            form.submit();
        } else {
            setEdit(true);
        }
    }

    const { edit, isValid, submitCount, name, parent, buttonIndex, items, setItems, pending } = props;
    const invalid = edit && submitCount && !isValid;

    return <div className='message-nodes-block'>
        <DeleteButton
            parent={parent}
            buttonIndex={buttonIndex}
            items={items}
            setItems={setItems} />

        <div className='message-text-container'>
            <div className='message-text'>
                <Field
                    name={name}
                    component={MaskedInput}
                    label={t('settings.link')}
                    disabled={!edit}
                    validate={required}
                    mask={getUrlMask}
                    placeholder='https://'
                />
            </div>
            <Tooltip title={invalid ? t('settings.typeTextMessage') : null} placement="top">
                <Button shape='circle' onClick={toggleEdit} className={cx({ invalid, 'save-btn': edit, 'edit-btn': !edit })}>
                    { pending ? <LoadingOutlined /> : (
                        edit ? <CheckOutlined /> : <EditOutlined />
                    )}
                </Button>
            </Tooltip>
        </div>
    </div>;
}

const VacancyBlockForm = withFormWrapper(withAutoFocusForm(VacancyBlock), {
    mapPropsToValues: compose(addFromMenuParam, prop('item')),
    enableReinitialize: true
});

const UrlBlockForm = props => {
    const [edit, setEdit] = useState(props.created);
    const messageNode = useCompanyCodeSwr(MESSAGE_NODE.stringify({ id: props.parent }));
    const chatSettings = useCompanyCodeSwr(CHAT_SETTINGS.stringify());
    const item = props.parent ? messageNode.data : chatSettings.data;

    return <VacancyBlockForm
        {...props}
        formAction={props.parent ? putMessageNodeHandler : putChatSettingsHandler}
        onSuccess={() => {
            messageNode.mutate();
            setEdit(false);
        }}
        item={item}
        edit={edit}
        setEdit={setEdit} />;
}

export default UrlBlockForm;
