import React, { useContext } from 'react';
import { GlobalOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import cx from 'classnames';
import { all, equals, values, propOr } from 'ramda';

import checkTranslation from '../../../utils/translation';
import { DEFAULT_QUESTIONS, QUESTIONS_KEY } from '../../../constants/questions';
import ModalsContext from '../../../contexts/ModalsContext';
import { OPEN_VACANCY_LANGUAGE_MODAL } from '../../../constants/actionTypes';

const VacancyTranslationButton = props => {
    const modals = useContext(ModalsContext);

    const getTooltip = (translations) => {
        return (
            <div className='transalation-status'>
                <div className='transalation-status-item'>
                    <div className='language'>RU:</div>
                    <GlobalOutlined className={cx('status-icon', { translated: translations.ru })} />
                </div>
                <div className='transalation-status-item'>
                    <div className='language'>UA:</div>
                    <GlobalOutlined className={cx('status-icon', { translated: translations.ua })} />
                </div>
                <div className='transalation-status-item'>
                    <div className='language'>EN:</div>
                    <GlobalOutlined className={cx('status-icon', { translated: translations.en })} />
                </div>
            </div>
        );
    }

    const { disabled, vacancyData, className, questionsType = DEFAULT_QUESTIONS } = props;
    const questions = propOr([], QUESTIONS_KEY[questionsType], vacancyData);
    const translations = checkTranslation(questions);
    const isTransalated = all(equals(true))(values(translations));

    return (
        <Tooltip
            title={getTooltip(translations)}
            align={{ offset: [0, 3] }}
        >
            <GlobalOutlined
                className={cx('language-button', className, {
                    translated: isTransalated && !disabled,
                })}
                onClick={() => modals.open(OPEN_VACANCY_LANGUAGE_MODAL, { questions })}
            />
        </Tooltip>
    );
}

export default VacancyTranslationButton;
