import React, { useEffect, useRef } from 'react';

const focusabledElements = 'input, textarea, .ant-select-selection, .ant-radio-button-wrapper';

export default function withAutoFocusForm(WrappedComponent) {
    return props => {
        const container = useRef();

        useEffect(() => {
            setTimeout(() => {
                if (!container.current) return null;
                const focusable = container.current.querySelector(focusabledElements);
                return focusable ? focusable.focus() : null;
            });
        }, []);

        return (
            <div ref={container}>
                <WrappedComponent {...props} />
            </div>
        );
    }
}
