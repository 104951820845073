import React from 'react';
import { Button, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation, withTranslation } from 'react-i18next';

import { DEEP_LINK_COPY_ERROR, DEEP_LINK_COPY_SUCCESS } from '../../constants/messages';

const FacebookWebhookUrl = props => {
    const { t } = useTranslation();

    const onCopy = (text, result) => {
        result ?
            message.success(t(DEEP_LINK_COPY_SUCCESS)) :
            message.error(t(DEEP_LINK_COPY_ERROR));
    }

    const getLink = () => {
        const { bot } = props;
        const base = process.env.REACT_APP_WEBHOOK_BASE_URL || `https://${window.location.hostname}`;

        return `${base}/api/bot/facebook/${bot.token}/${bot.username}`;
    }

    return <CopyToClipboard onCopy={onCopy} text={getLink()}>
        <Button>
            Webhook URL
        </Button>
    </CopyToClipboard>;
}

export default withTranslation()(FacebookWebhookUrl);
