import React from 'react';
import { Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import VacancyQuestionForm from '../../forms/VacancyQuestionForm';

const VacancyQuestionModal = props => {
    const { t } = useTranslation();
    const { modal, params, formAction, close } = props;

    const onSubmit = values => {
        props.params.onChange(values);
        props.close();
    }

    return <Modal
        {...modal}
        width='calc(100% - 30px)'
        style={{ maxWidth: '1440px' }}
        footer={null}
        title={params.new ? t('vacancy.questions.createQuestion') : t('vacancy.questions.editQuestion')}>
        { params.new && (
            <div className='modal-help'>
                <WarningOutlined style={{ color: 'red' }} />
                { t('vacancy.questions.editQuestionHelp1') }
                <br />
                <br />
                { t('vacancy.questions.editQuestionHelp2') }
                <br />
                <br />
                { t('vacancy.questions.editQuestionHelp3') }
            </div>
        )}
        <VacancyQuestionForm
            formAction={formAction || onSubmit}
            item={params.item}
            isCompositeQuestion={params.isCompositeQuestion}
            getFormQuestions={params.getFormQuestions}
            onSuccess={() => close()}
        />
    </Modal>;
}

export default VacancyQuestionModal;
