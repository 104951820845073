import { contains } from 'ramda';
import translit from 'translit-passport';

import { ADMIN, ADMIN_COMPANY } from '../constants/roles';
import { addDefaultHeader, removeDefaultHeader } from './http';

export function handleCompanyCodeChange(code) {
    if (code) {
        localStorage.setItem('companyCode', code);
        addDefaultHeader('company-code', code);
    } else {
        localStorage.removeItem('companyCode');
        removeDefaultHeader('company-code');
    }
}

export function getCompanyCode() {
    return localStorage.getItem('companyCode') || null;
}

export function checkMultiCompany(userData) {
    const isAdmin = contains(ADMIN, userData.roles || []) || contains(ADMIN_COMPANY, userData.roles || []);

    return isAdmin;
}

export const transformCompanyCodeValue = value => translit.cyrillicToLatin(`${value || ''}`).toLocaleLowerCase().replace(/[^-_0-9a-z]/gim, '');
export const transformCodeValue = value => translit.cyrillicToLatin(`${value || ''}`).replace(/[^-_0-9a-zA-Z]/gim, '');
