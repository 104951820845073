export const botLink = (botObj = {}, vacancyId) => {
    switch (botObj.type) {
        case 'telegram':
            return `https://t.me/${botObj.username}${vacancyId ? `?start=${vacancyId}` : ''}`;
        case 'viber':
            return `viber://pa?chatURI=${botObj.username}${vacancyId ? `&context=vacancy/${vacancyId}` : ''}`;
        case 'facebook':
            return `https://m.me/${botObj.username}${vacancyId ? `?ref=${vacancyId}` : ''}`;
        case 'web':
            return `${window.location.origin}/chat/${botObj.username}${vacancyId ? `?vacancy=${vacancyId}` : ''}`;
        default:
            return null;
    }
};

export const getBotsPageLink = vacancyId => `${window.location.origin}/messenger-select?vacancy=${vacancyId || ''}`;

export const getWidgetLink = (botname = '', vacancy = '', company = '') => {
    const params = `botname=${encodeURI(botname)}&vacancy=${vacancy}&company=${encodeURI(company)}`;
    const url = `${window.location.origin}/${company ? 'widgetSelect' : 'widget'}.js`;

    return `<script src="${url}?${params}"></script>`;
};
