import React from 'react';
import { Checkbox, Spin } from 'antd';
import { prop, pathOr } from 'ramda';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { DOMAIN } from '../../../constants/urls';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const Wrapper = styled.div`
    .domains-checkboxes-wrapper {
        margin-bottom: 0 !important;
    }
    .ant-form-item-control-input {
        min-height: auto;
    }
`;

const AntdCheckboxGroup = Checkbox.Group;

const Domains = props => {
    const { input: { name, value }, onChange, className, options, disabled } = props;

    return (
        <AntdCheckboxGroup
            name={name}
            options={options}
            defaultValue={value}
            onChange={onChange}
            className={className}
            disabled={disabled}
        />
    );
}

const DomainComponent = withFieldWrapper(Domains);

const DomainField = props => {
    const { t } = useTranslation();
    const domain = useCompanyCodeSwr(DOMAIN.stringify({ id: undefined }), url => axios.get(url, {
        params: {
            pagination: { offset: 0, limit: 0 },
            relations: ['vacancyFormPreset', 'domainFormPreset']
        }
    }));

    const getDefaultValue = (options) => {
        const { creating } = props;
        let defaultValues = [];

        if (!creating) {
            return undefined;
        }

        options.forEach(option => {
            option.defaultActive && defaultValues.push(option.value);
        });
        return defaultValues;
    }

    const getDomainsOptons = () => {
        const domains = pathOr([], ['data', 'items'], domain);

        return domains.map(domain => domain && ({
            label: domain.title,
            value: domain.id,
            defaultActive: domain.defaultActive,
        }));
    }

    const { fullLicense, publicationSettings = {} } = props;
    const options = getDomainsOptons();
    const disabledDomain = !prop('domain', publicationSettings);

    return <Wrapper>
        { !domain.data ? <Spin /> : (fullLicense || !fullLicense && options.length) ?
            <Field
                wrapperClassName='domains-checkboxes-wrapper'
                className='domains-checkboxes'
                label={t('form.publicationPlace')}
                name='domains'
                component={DomainComponent}
                options={options}
                disabled={disabledDomain}
                initialValue={getDefaultValue(options)}
                required
            />
            : null
        }
    </Wrapper>;
}

export default DomainField;
