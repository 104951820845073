import React from 'react';

import { messageTimeOptions } from '../../../utils/date';

const ChatTime = props => {
    const {locale, className, timeOptions, timeValue} = props;

    return (
        <div className={className}>
            {new Date(timeValue).toLocaleTimeString(locale, timeOptions)}
        </div>
    );
}

ChatTime.defaultProps = {
    locale: 'ru',
    className: 'dialog-card-time',
    timeOptions: messageTimeOptions,
};

export default ChatTime;
