import React from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

import PasswordRecoveryForm from '../../forms/PasswordRecoveryForm';
import GuestLink from './GuestLink';
import { postPasswordRecoveryHandler } from '../../../actions/handlers';

const PasswordRecovery = ({ experiumRecovery }) => {
    const { t } = useTranslation();

    return (
        <div>
            <h3>{ t('guest.passwordRecoveryTitle') }</h3>
            <PasswordRecoveryForm
                formAction={postPasswordRecoveryHandler}
                onSuccess={({ form }) => {
                    form.reset();
                    notification.success({
                        message: t('guest.passwordRecoveryTitle'),
                        description: t('guest.recoveryLinkSent')
                    });
                }}
                onSubmitFail={() => notification.error({
                    message: t('guest.passwordRecoveryTitle'),
                    description: t('guest.recoveryLinkError')
                })}
            />
            <div className='link-block'>
                <GuestLink to={experiumRecovery ? '/oauth/authorize' : '/'}>{ t('guest.login') }</GuestLink>
            </div>
        </div>
    );
}

export default PasswordRecovery;
