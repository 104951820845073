import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { all, compose, propEq, concat, uniq, without, path, pathOr, head, is } from 'ramda';
import { Button, Popconfirm, Tooltip, message } from 'antd';
import { DeleteOutlined, FileOutlined, FileExcelOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import { PASSED_STATE } from '../../../constants/vacancy';

import withUserCompany from '../../hocs/withUserCompany';
import { renderFieldValue } from './Applicant';
import DateCell from '../../table/tableCells/DateCell';
import TableList from '../../table/TableList';
import ApplicantFilter from '../../filters/ApplicantFilter';
import BotImage from '../../table/BotImage';
import Mail from '../../table/Mail';
import Phone from '../../table/Phone';
import { getFullName } from '../../../utils/fieldsHelpers';
import { deleteApplicantsHandler, approveApplicantsHandler, rejectApplicantsHandler } from '../../../actions/handlers';
import { APPLICANT, VACANCY_FORM_PRESET } from '../../../constants/urls';
import ActionsContext from '../../../contexts/ActionsContext';
import { getFilters } from '../../../utils/urlParams';
import { getToken } from '../../../utils/token';
import { applicantsFilter, applicantsRejectFilter } from '../../../utils/handlers';

const ButtonsToolbar = styled.div`
    padding: 15px;
    text-align: right;

    & .ant-btn {
        margin-left: 5px;
    }
`;

const getApplicantName = ({ id, vacancyForm }, t) => (
    <div className='last-message'>
        <span>
            <Link to={`/applicants/${id}`}>
                {getFullName(vacancyForm) || t('applicant.withoutName')}
            </Link>
        </span>
    </div>
);

const getFieldFiles = (value, question) => !value ? null : Array.isArray(value) ? (
    value.map((item, index) => (
        <div key={`${question.field}-${index}`}>
            {getFileName(item, question)}
        </div>
    ))
) : (
    <div key={`${question.field}`}>
        {getFileName(value, question)}
    </div>
);

const getFileName = (value, question) => (
    <a href={value.url} download className='file-link-wrapper'>
        <FileOutlined /> {question.label}
    </a>
);

const getDefaultSelected = () => ({ status: {}, ids: [] });

const getAdditionalFilter = compose(applicantsFilter, applicantsRejectFilter);
const initFilters = {
    rejectedState: PASSED_STATE
};
const relations = ['vacancy', 'vacancy.formPreset'];
const sorting = {
    field: 'createdAt',
    order: 'desc',
};

const Applicants = (props) => {
    const { t, i18n } = useTranslation();
    const [selected, setSelected] = useState(getDefaultSelected());
    const { actions, revalidateAction } = useContext(ActionsContext);
    const location = useLocation();
    const filters = getFilters(location) || {};
    const formPresets = pathOr([], [VACANCY_FORM_PRESET.stringify(), 'data', 'items'], actions)

    useEffect(() => {
        setSelected(getDefaultSelected());
    }, [JSON.stringify(getFilters(location))]);

    const getFilteredColumns = () => {
        const formPreset = head(formPresets.filter(propEq('id', filters.formPreset)));

        return [
            {
                title: t('applicant.fullName'),
                key: 'fullName',
                render: applicant => getApplicantName(applicant, t),
                className: 'main-column',
                fixed: 'left'
            },
            {
                title: t('applicant.vacancy'),
                dataIndex: '_relations',
                key: 'vacancy',
                render: ({ vacancy }) => vacancy && (
                    <Link to={`/vacancy/${vacancy.id }`}>
                        {vacancy.title}
                    </Link>
                ),
                fixed: filters.formPreset ? false : 'left',
            },
            !filters.formPreset && {
                title: t('applicant.contacts'),
                dataIndex:'vacancyForm',
                key: 'contacts',
                render: vacancyForm => (
                    <Fragment>
                        <div className='table-item'>
                            <Mail value={vacancyForm.email} />
                        </div>
                        <div className='table-item'>
                            <Phone value={vacancyForm.phone} />
                        </div>
                    </Fragment>
                ),
            },
            !filters.formPreset && {
                title: '',
                width: 40,
                align: 'center',
                key: 'source',
                dataIndex: 'source',
                render: source => source && <BotImage type={source.type} />,
            },
            {
                title: t('applicant.createdAt'),
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: date => <DateCell date={date} />,
            },
            {
                title: t('applicant.barierQuestion'),
                key: 'rejected',
                dataIndex: 'rejected',
                className: 'barier-col',
                render: (rejected, { hasCorrectValues }) => rejected ?
                    t('applicant.rejected')
                    : !hasCorrectValues  ? t('applicant.noBarier') : t('applicant.noRejected')
            },
            ...(formPreset ? formPreset.questions.map(question => ({
                title: path(['translations', 'label', i18n.language], question) || question.label,
                key: question.field,
                width: 160,
                render: question.type === 'composite' ? undefined : applicant => (
                    renderFieldValue(question.type, path(['vacancyForm', question.field], applicant), question.settings, {}, t, i18n.language)
                ),
                children: question.type === 'composite' ? question.settings.questions.map(subQuestion => ({
                    title: path(['translations', 'label', i18n.language], subQuestion) || subQuestion.label,
                    key: subQuestion.id,
                    width: 160,
                    render: applicant => {
                        const questionAnswer = path(['vacancyForm', question.field], applicant);
                        return (is(Array, questionAnswer) ? questionAnswer : [questionAnswer]).map((answer, index) => (
                            <div key={`${question.field}.${index}.${subQuestion.field}`}>
                                {renderFieldValue(subQuestion.type, pathOr('', [subQuestion.field], answer), subQuestion.settings, {}, t, i18n.language)}
                            </div>
                        ));
                    },
                })) : undefined,
            })) : []),
            !filters.formPreset && {
                title: '',
                key: 'attachments',
                render: ({ vacancyQuestions, vacancyForm }) => (vacancyQuestions || []).filter(propEq('type', 'file')).map(
                    question => getFieldFiles(vacancyForm[question.field], question)
                ),
            }
        ].filter(Boolean);
    }

    const deleteApplicant = () => {
        deleteApplicantsHandler(selected.ids).then(() => {
            revalidateAction(APPLICANT.stringify());
            setSelected(getDefaultSelected());
            message.success(t('applicant.deleteSuccess'));
        }).catch(() => message.error(t('applicant.deleteError')));
    }

    const approve = () => {
        approveApplicantsHandler(selected.ids).then(() => {
            revalidateAction(APPLICANT.stringify());
            setSelected(getDefaultSelected());
            message.success(t('applicant.updateSuccess'));
        }).catch(() => message.error(t('applicant.updateError')));
    }

    const reject = () => {
        rejectApplicantsHandler(selected.ids).then(() => {
            revalidateAction(APPLICANT.stringify());
            setSelected(getDefaultSelected());
            message.success(t('applicant.updateSuccess'));
        }).catch(() => message.error(t('applicant.updateError')));
    }

    const renderButtons = () => {
        const disabled = !selected.ids.length;
        const disabledChange = disabled || !all(id => selected.status[id] === selected.status[selected.ids[0]], selected.ids);
        const isToReject = !selected.status[selected.ids[0]];
        const exportPath = qs.stringify({
            access_token: getToken(),
            sorting: {
                field: 'createdAt',
                order: 'desc',
            },
            filter: JSON.stringify({
                company: props.companyId,
                ...initFilters,
                ...getAdditionalFilter(filters),
                ids: disabled ? undefined : selected.ids,
            }),
        });

        return (
            <ButtonsToolbar>
                <Popconfirm
                    title={t(isToReject ? 'applicant.rejectConfirm' : 'applicant.approveConfirm', { length: selected.ids.length })}
                    onConfirm={isToReject ? reject : approve}
                    disabled={disabledChange}
                    arrowPointAtCenter
                    placement='topRight'
                >
                    <Button disabled={disabledChange}>{ t('applicant.replace') }</Button>
                </Popconfirm>
                <Popconfirm
                    title={t('applicant.deleteApplicantsConfirm')}
                    onConfirm={deleteApplicant}
                    disabled={disabled}
                    arrowPointAtCenter
                    placement='topRight'
                >
                    <Button icon={<DeleteOutlined />} disabled={disabled} type='danger'>{ t('applicant.delete') }</Button>
                </Popconfirm>
                <Tooltip
                    title={!filters.formPreset ? t('applicant.needForm') : null}
                >
                    <Button
                        type='primary'
                        target='_blank'
                        rel='noopener noreferrer'
                        href={`/api/applicant/export?${exportPath}`}
                        icon={<FileExcelOutlined />}
                        download
                        disabled={!filters.formPreset}
                    >
                        { t('chat.download') }
                    </Button>
                </Tooltip>
            </ButtonsToolbar>
        );
    }

    const onChangeSelected = (value, items = []) => {
        const status = { ...selected.status };

        items.forEach(item => {
            if (item) {
                if (value) {
                    status[item.id] = item.rejected;
                } else {
                    delete status[item.id];
                }
            }
        });

        setSelected({
            status,
            ids: value ?
                uniq(concat(items.filter(Boolean).map(item => item.id), selected.ids))
                : without(items.filter(Boolean).map(item => item.id), selected.ids)
        });
    }

    return (
        <Fragment>
            <TableList
                actionUrl={APPLICANT.stringify()}
                getAdditionalFilter={getAdditionalFilter}
                filterForm={ApplicantFilter}
                afterToolbar={renderButtons()}
                columns={getFilteredColumns()}
                initFilters={initFilters}
                relations={relations}
                sorting={sorting}
                rowSelection={{
                    selectedRowKeys: selected.ids,
                    onSelect: (item, selected) => onChangeSelected(selected, [item]),
                    onSelectAll: (selected, selectedRows, changeRows) => onChangeSelected(selected, selected ? selectedRows : changeRows),
                    type: 'checkbox'
                }}
                toolbarButtonSize={4}
                toolbarFilterSize={20}
                scroll={filters.formPreset ? 'max-content' : 1000}
            />
        </Fragment>
    );
}

export default withUserCompany(Applicants);
