import React from 'react';
import { Button, Row, Col } from 'antd';
import { Field } from 'react-final-form';
import { path } from 'ramda';
import * as yup from 'yup';

import { useTranslation, withTranslation } from 'react-i18next';
import withFormWrapper from '../../hocs/withFormWrapper';
import Input from '../formComponents/Input';
import { DescriptionEditor } from '../formComponents/Editor';

const VacancyTranslationForm = () => {
    const { t } = useTranslation();

    return (
        <div className='question-translations'>
            <Row gutter={20} className='question-translations-content'>
                <Col span={3} className='labels-col'>
                    <div className='labels-wrapper'>
                        <div className='label'>{ t('vacancy.form.name') }:</div>
                    </div>
                </Col>
                <Col span={7}>
                    <div className='language'>{ t('vacancy.form.russian') }</div>
                    <Field
                        name={'title'}
                        component={Input}
                        disabled
                    />
                </Col>
                <Col span={7}>
                    <div className='language'>{ t('vacancy.form.ukranian') }</div>
                    <Field
                        name={'translations.title.ua'}
                        component={Input}
                    />
                </Col>
                <Col span={7}>
                    <div className='language'>{ t('vacancy.form.english') }</div>
                    <Field
                        name={'translations.title.en'}
                        component={Input}
                    />
                </Col>
            </Row>
            <Row gutter={20} className='question-translations-content description-row'>
                <Col span={3} className='labels-col'>
                    <div className='label'>{ t('vacancy.form.description') }:</div>
                </Col>
                <Col span={7}>
                    <Field
                        name={'description'}
                        component={DescriptionEditor}
                        disabled
                    />
                </Col>
                <Col span={7}>
                    <Field
                        name={'translations.description.ua'}
                        component={DescriptionEditor}
                    />
                </Col>
                <Col span={7}>
                    <Field
                        name={'translations.description.en'}
                        component={DescriptionEditor}
                    />
                </Col>
            </Row>
            <Row gutter={20} className='question-translations-content'>
                <Col span={3} className='labels-col'>
                    <div className='label'>{ t('vacancy.form.address') }:</div>
                </Col>
                <Col span={7}>
                    <Field
                        name={'address.address'}
                        component={Input}
                        disabled
                    />
                </Col>
                <Col span={7}>
                    <Field
                        name={'translations.address.ua'}
                        component={Input}
                    />
                </Col>
                <Col span={7}>
                    <Field

                        name={'translations.address.en'}
                        component={Input}
                    />
                </Col>
            </Row>
            <Row>
                <div className='modal-form-toolbar'>
                    <Button
                        type='primary'
                        htmlType='submit'
                    >
                        { t('form.save') }
                    </Button>
                </div>
            </Row>
        </div>
    );
}

const validationSchema = () => yup.object().shape({
    translations: yup.object().shape({
        description: yup.object().shape({
            en: yup.string().required(),
            ua: yup.string().required(),
        }),
        title: yup.object().shape({
            en: yup.string().required(),
            ua: yup.string().required(),
        }),
        address: yup.object().shape({
            en: yup.string().required(),
            ua: yup.string().required(),
        })
    }),
});

export default withTranslation()(withFormWrapper(VacancyTranslationForm, {
    validationSchema,
    mapPropsToValues: ({ values }) => values,
    mapBeforeSubmit: values => ({
        ...values,
        address: path(['address', 'address'], values),
        location: path(['address', 'location'], values),
    }),
}));
