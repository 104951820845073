import React from 'react';
import { InputNumber } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const InputNumberComponent = props => {
    const { placeholder, disabled, input: { value, name }, className, addonAfter, getRef, onChange, formatter, min, max } = props;

    return <InputNumber
        name={name}
        id={name}
        value={value}
        disabled={disabled}
        className={className}
        addonAfter={addonAfter}
        onChange={onChange}
        placeholder={placeholder}
        ref={getRef}
        formatter={value => formatter ? formatter(value) : value}
        min={min}
        max={max}
    />;
}

export default withFieldWrapper(InputNumberComponent);

export const InputNumberString = withFieldWrapper(props => (
    <InputNumberComponent {...props} onChange={value => props.onChange(value && `${value}`)} />
));
