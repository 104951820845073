import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Editor as DraftEditor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, Modifier } from 'draft-js';
import enhanceWithClickOutside from 'react-click-outside';
import { handleDraftEditorPastedText } from 'draftjs-conductor';
import { useTranslation, withTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import { includes, path } from 'ramda';
import styled from 'styled-components';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { defaultToolbar, descriptionToolbar, mailToolbar, etTranslations, ltTranslations, lvTranslations, uaTranslations } from '../../../constants/editor';
import { mailFromHTML, mailToHTML, chatFromHTML, chatToHTML } from '../../../utils/editor';

const localizationOptions = {
    ru: { locale: 'ru' },
    en: { locale: 'en' },
    ua: { translations: uaTranslations },
    et: { translations: etTranslations },
    lv: { translations: lvTranslations },
    lt: { translations: ltTranslations }
};

function blockStyleFn(contentBlock, contentState) {
    const type = contentBlock.getType();

    if (type === 'unstyled') {
        let placeholderUrl = false;

        contentBlock.findEntityRanges(
            character => {
                const entityKey = character.getEntity();

                if (!entityKey) {
                    return false;
                }

                const hasUrlInData = includes(contentState.getEntity(entityKey).getData().url, ['{buttonUrl}', '{registrationUrlButton}']);

                if (hasUrlInData) {
                    placeholderUrl = true;
                }

                return hasUrlInData;
            },
            () => {}
        )

        if (placeholderUrl) {
            return 'placeholder-url';
        }
    }
}

const EditorComponent = props => {
    const { t, i18n } = useTranslation();
    const { input: { value }, fromHTML } = props;
    const [editorState, setEditorState] = useState(value ? fromHTML(value) : EditorState.createEmpty());
    const editor = useRef();
    const editorContainer = useRef();

    const onEditorStateChange = (editorStateValue) => {
        const { toHTML, onChange } = props;
        const contentState = editorState.getCurrentContent();
        setEditorState(editorStateValue);
        const value = toHTML(contentState);

        onChange(contentState.hasText() ? value : '');
    }

    const setContainerRef = (wrapper) => {
        if (wrapper) {
            props.forwardRef(wrapper);
            editorContainer.current = wrapper.querySelector('.rdw-editor-wrapper');
        }
    }

    const onFocusEditor = () => {
        editorContainer.current.classList.add('focused');
    }

    const handlePastedText = (text, html, editorState) => {
        if (html) {
            let newState = handleDraftEditorPastedText(html, editorState);
            if (newState) {
                onEditorStateChange(newState);
                return true;
            }
        }

        return false;
    }

    useEffect(() => {
        const { input: { value }, fromHTML } = props;

        setEditorState(fromHTML(value));
    }, [path(['form', 'initialValues', ...props.input.name.split('.')], props)]);

    useEffect(() => {
        const { input: { value }, fromHTML } = props;
        const editorHasText = !!editorState.getCurrentContent().getPlainText();

        if (value && !editorHasText) {
            setEditorState(fromHTML(value));
        }
    }, [props.input.value]);

    const { input, placeholder, disabled, toolbar, mailEditor, toolbarCustomButtons } = props;

    return (
        <div id={input.name} className='editorWrapper' ref={setContainerRef}>
            <DraftEditor
                editorRef={ref => editor.current = ref}
                wrapperClassName={disabled ? 'wysiwyg-wrapper wysiwyg-disabled' : 'wysiwyg-wrapper'}
                editorClassName='wysiwyg-editor'
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                placeholder={t(placeholder)}
                readOnly={disabled}
                toolbarHidden={disabled}
                mailEditor={mailEditor}
                onFocus={onFocusEditor}
                toolbar={toolbar}
                handlePastedText={handlePastedText}
                localization={localizationOptions[i18n.language]}
                toolbarCustomButtons={toolbarCustomButtons}
                blockStyleFn={b => blockStyleFn(b, editorState.getCurrentContent())}
            />
        </div>
    );
}

const Editor = withTranslation()(
    withFieldWrapper(
        enhanceWithClickOutside(
            forwardRef((props, ref) => <EditorComponent {...props} forwardRef={ref} />)
        )
    )
);

const WrappedEditor = props => {
    const form = useFormState();

    return <Editor {...props} form={form} />;
}

const ChatEditor = props => (
    <WrappedEditor
        toolbar={defaultToolbar}
        fromHTML={chatFromHTML}
        toHTML={chatToHTML}
        {...props}
    />
);

const MailPlaceholderOption = styled.div`
    cursor: pointer;
    padding: 10px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
`;

const MailPlaceholderBtn = props => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const [registred, setRegistered] = useState(false);

    const expandedRef = useRef();
    useEffect(() => {
        expandedRef.current = expanded;
    });

    const expandCollapse = useCallback(() => {
        if (expandedRef.current) {
            doCollapse();
        }
    });

    const doExpand = () => {
        setExpanded(true);
    };

    const doCollapse = () => {
        setExpanded(false);
    };

    if (!registred) {
        props.modalHandler.registerCallBack(expandCollapse);
        setRegistered(true);
    }

    useEffect(() => {
        const { modalHandler } = props;
        return modalHandler.deregisterCallBack(expandCollapse);
    }, []);

    const placeholdersHtml = {
        'siteUrl': `<a href="{siteUrl}" style="font-family:Arial; font-size:14px; color:#2E91D1; text-decoration:none;" target="_blank">${t('settings.link')}</a>`,
        'registrationUrl': `<a href="{registrationUrl}" style="font-family:Arial; font-size:14px; color:#2E91D1; text-decoration:none;" target="_blank">${t('guest.login')}</a>`,
        'url': `<a href="{url}" style="font-family:Arial; font-size:14px; color:#2E91D1; text-decoration:none;" target="_blank">${t('guest.passwordRecovery')}</a>`,
        'urlBtn': `<p style="text-align: center; padding: 20px;"><a href="{buttonUrl}" style="display: inline-block;border-radius: 4px;color:#fff;font-size: 16px;background-color: #002140;text-decoration:none!important;padding:16px 40px;text-transform: uppercase;" target="_blank">${t('guest.passwordRecovery')}</a></p>`,
        'registrationUrlBtn': `<p style="text-align: center; padding: 20px;"><a href="{registrationUrlButton}" style="display: inline-block;border-radius: 4px;color:#fff;font-size: 16px;background-color: #002140;text-decoration:none!important;padding:16px 40px;text-transform: uppercase;" target="_blank">${t('guest.login')}</a></p>`
    };
    const addPlaceholder = text => {
        const { editorState, onChange } = props;
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            `{${text}}`,
            editorState.getCurrentInlineStyle(),
        );
        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
        doCollapse();
    }

    const addHtmlPlaceholder = html => {
        const { editorState, onChange } = props;
        let { contentBlocks, entityMap } = htmlToDraft(html);
        let contentState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
        );

        onChange(EditorState.push(editorState, contentState, 'insert-fragment'));
        doCollapse();
    }

    return <div
        className='rdw-embedded-wrapper'
        aria-haspopup="true"
        aria-expanded={expanded}>
        <div className='rdw-option-wrapper' style={{ paddingTop: 3 }} title='Плейсхолдер' onClick={doExpand}>{`{}`}</div>
        { expanded &&
            <div
                className='rdw-embedded-modal'
                onClick={e => e.stopPropagation()}
                style={{ width: 280, padding: 0, height: 'auto' }}>
                { props.placeholders.map(placeholder =>
                    <MailPlaceholderOption key={placeholder} onClick={() => placeholdersHtml[placeholder] ? addHtmlPlaceholder(placeholdersHtml[placeholder]) : addPlaceholder(placeholder)}>
                        { t(`form.editor.mailPlaceholder.${placeholder}`) }
                    </MailPlaceholderOption>
                )}
            </div>}
    </div>;
}

export const MailEditor = props => (
    <WrappedEditor
        toolbar={mailToolbar}
        fromHTML={mailFromHTML}
        toHTML={mailToHTML}
        toolbarCustomButtons={props.placeholders && props.placeholders.length ? [<MailPlaceholderBtn key='placeholder' placeholders={props.placeholders} />] : []}
        {...props}
    />
);

export const DescriptionEditor = props => (
    <WrappedEditor
        toolbar={descriptionToolbar}
        fromHTML={chatFromHTML}
        toHTML={chatToHTML}
        {...props}
    />
);

export default ChatEditor;
