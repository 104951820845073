import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { pathOr } from 'ramda';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import Input from './formComponents/Input';
import Select from './formComponents/Select';
import { CAPTCHA_TYPES } from '../../constants/captcha';

const CaptchaSettingsForm = () => {
    const { t } = useTranslation();

    return <div className='about-company-wrapper'>
        <Field
            name='captcha.landings'
            component={Select}
            label={t('settings.captchaTypeForLandings')}
            options={CAPTCHA_TYPES} />
        <Field
            name='captcha.domains'
            component={Select}
            label={t('settings.captchaTypeFormDomains')}
            options={CAPTCHA_TYPES} />
        <Field
            name='captcha.frontendKey'
            component={Input}
            label={t('settings.frontendKey')} />
        <Field
            name='captcha.backendKey'
            component={Input}
            label={t('settings.backendKey')} />
        <div className='modal-form-toolbar'>
            <SubmitButton>
                { t('form.save') }
            </SubmitButton>
        </div>
    </div>;
}

const validationSchema = () => yup.object().shape({
    captcha: yup.object().shape({
        landings: yup.string().required(),
        domains: yup.string().required(),
    }),
});

export default withFormWrapper(CaptchaSettingsForm, {
    validationSchema,
    mapPropsToValues: ({ item }) => ({
        ...item,
        captcha: {
            ...(item.captcha || {}),
            landings: pathOr('none', ['captcha', 'landings'], item),
            domains: pathOr('none', ['captcha', 'domains'], item),
        },
    })
});
