import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import experium1 from '../../../images/experium1.png';
import experium2 from '../../../images/experium2.png';
import experium3 from '../../../images/experium3.png';
import experium4 from '../../../images/experium4.png';
import experium5 from '../../../images/experium5.png';
import experium6 from '../../../images/experium6.png';
import experium7 from '../../../images/experium7.png';

const ManualModal = props => {
    const { t } = useTranslation();
    const { modal }  = props;

    return (
        <Modal
            {...modal}
            className="manual-modal"
            title={t('vacancy.experiumManual.instructionOfImportTitle')}
            footer={null}
        >
            <p>{ t('vacancy.experiumManual.importStepsTitle') }</p>
            <ol>
                <li>{ t('vacancy.experiumManual.importStep1') }
                    <div className="wrap-img">
                        <img alt={t('vacancy.experiumManual.importStep1Alt')} src={experium1} />
                    </div>
                </li>
                <li>{ t('vacancy.experiumManual.importStep2') }
                    <div className="wrap-img">
                        <img alt={t('vacancy.experiumManual.importStep2Alt')} src={experium2} />
                    </div>
                </li>
                <li>{ t('vacancy.experiumManual.importStep3') }
                    <div className="wrap-img">
                        <img alt={t('vacancy.experiumManual.importStep3Alt')} src={experium3} />
                    </div>
                </li>
                <li>{ t('vacancy.experiumManual.importStep4') }
                    <div className="wrap-img">
                        <img alt={t('vacancy.experiumManual.importStep4Alt')} src={experium4} />
                    </div>
                </li>
            </ol>
            <p>{ t('vacancy.experiumManual.importStep5') }</p>
            <div className="wrap-img">
                <img alt={t('vacancy.experiumManual.importStep5Alt')} src={experium5} />
            </div>
            <p>{ t('vacancy.experiumManual.importStep6') }</p>
            <div className="wrap-img">
                <img alt={t('vacancy.experiumManual.importStep6Alt')} src={experium6} />
            </div>
            <p>{ t('vacancy.experiumManual.importStep7') }</p>
            <div className="wrap-img">
                <img alt={t('vacancy.experiumManual.importStep7Alt')} src={experium7} />
            </div>
        </Modal>
    );
}

export default ManualModal;
