import React, { Fragment, useEffect, useState } from 'react';
import { contains, isEmpty } from 'ramda';
import { DatePicker, Checkbox, Input } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const DATE_FORMAT = 'DD.MM.YYYY';

const BlockDatePicker = props => {
    const { t } = useTranslation();

    const [now, setNow] = useState(props.input.value === 'now');

    useEffect(() => {
        if (now && props.input.value !== 'now') {
            setNow(false);
        }
    }, [now, props.input.value]);

    const onChangeDate = (value) => {
        const { onChange } = props;
        const hasValue = !isEmpty(value);

        if (!value || !hasValue) {
            onChange(undefined);
        } else {
            const parsedValue = value.startOf('day').toISOString(DATE_FORMAT);
            onChange(parsedValue);
        }
    }

    const { onChange, placeholder, input: { value }, disabled } = props;

    return (
        <Fragment>
            { !now && (value !== 'now') ? (
                <DatePicker
                    value={value ? contains('T', value) ? moment(value) : moment(value, DATE_FORMAT) : undefined}
                    className='date-select'
                    onChange={onChangeDate}
                    placeholder={placeholder}
                    format={DATE_FORMAT}
                    disabled={disabled}
                />
            ) : (
                <Input
                    value={value}
                    className='date-select'
                    disabled
                />
            )}
            <Checkbox
                checked={!!now && value}
                onChange={e => {
                    const now = e.target.checked;
                    setNow(now);
                    onChange(now ? 'now' : undefined);
                }}
                disabled={disabled}
            >
                {t('applicant.now')}
            </Checkbox>
        </Fragment>
    );
}

export default withFieldWrapper(BlockDatePicker);
