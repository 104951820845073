import React from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { path, prop } from 'ramda';

import TableList from '../../table/TableList';
import DateCell from '../../table/tableCells/DateCell';
import ReportFilter from '../../filters/ReportFilter';
import withUserCompany from '../../hocs/withUserCompany';
import { VACANCY_APPLICANTS_REPORT } from '../../../constants/urls';
import { getToken } from '../../../utils/token';
import { getFullName } from '../../../utils/fieldsHelpers';
import { getFilters } from '../../../utils/urlParams';
import { getMonths } from '../../../utils/date';

const VacancyApplicantsReport = props => {
    const { t } = useTranslation();
    const location = useLocation();
    const actionUrl = VACANCY_APPLICANTS_REPORT.stringify();
    const filters = getFilters(location) || {};
    const months = filters.applicantsPeriodFrom && filters.applicantsPeriodTo ?
        getMonths(filters.applicantsPeriodFrom, filters.applicantsPeriodTo)
        : ['all'];

    const getColumns = () => {
        return [
            {
                title: t('vacancy.list.vacancy'),
                dataIndex: 'title',
                key: 'title',
                render: (title, item) => (
                    <Link to={`/vacancy/${item.id}`}>
                        <span className="span-link">{ title }</span>
                    </Link>
                ),
            },
            {
                title: t('reports.list.id'),
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: t('vacancy.detail.responsible'),
                dataIndex: 'responsibleBy',
                key: 'responsibleBy',
                render: (responsibleBy, vacancy) => getFullName(path(['_relations', 'responsibleBy'], vacancy)) || responsibleBy,
            },
            {
                title: t('vacancy.form.city'),
                dataIndex: ['_relations', 'cities'],
                key: 'cities',
                render: (cities) => (
                    cities || []).map(city => (
                        <div key={prop('id', city)}>{prop('name', city)}</div>
                    )
                ),
            },
            {
                title: t('settings.country'),
                dataIndex: ['_relations', 'cities'],
                key: 'countries',
                render: (cities) => (
                    <div>
                        {(cities || [])
                            .map(city => path(['_relations', 'country', 'name'], city))
                            .reduce((countries, country) => countries.indexOf(country) >= 0 ? countries : [...countries, country], [])
                            .map(country => <div key={country}>{country}</div>)
                        }
                    </div>
                ),
            },
            {
                title: t('reports.list.createdAt'),
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: date => <DateCell date={date} />,
            },
            {
                title: t('reports.list.datePublishedStart'),
                dataIndex: 'datePublishedStart',
                key: 'datePublishedStart',
                render: date => <DateCell date={date} />,
            },
            ...(months.map(current => (
                {
                    title: `${t('reports.list.applicantStat')} ${current === 'all' ? '' : current }`,
                    dataIndex: 'applicantsStat',
                    key: 'applicantsStat',
                    render: (applicantsStat) =>  current === 'all' ? (
                        <div>
                            {(applicantsStat || []).reduce((count, stat) => count + (prop('count', stat) || 0), 0)}
                        </div>
                    ) : (
                        <div>
                            {prop('count', (applicantsStat || []).find(({ month }) => current === month)) || 0}
                        </div>
                    ),
                }
            ))),
        ];
    }

    const getButtons = () => ({ action }) => {
        return <div className='company-dictionaries-buttons'>
            <Button
                target='_blank'
                rel='noopener noreferrer'
                href={`/api/vacancy/applicants/report/export?access_token=${getToken()}&filter=${JSON.stringify({ ...filters, company: prop('companyId', props) })}`}
                icon={<DownloadOutlined />}
                download
                disabled={!prop('data', action)}
            >
                { t('reports.save') }
            </Button>
        </div>;
    }

    return (
        <TableList
            filterForm={props => <ReportFilter
                {...props}
                isSubmitOnly
            />}
            headers={{
                'Accept-Language': 'ru',
            }}
            relations={['responsibleBy', 'cities', 'cities.country']}
            className='company-dictionaries-table'
            actionUrl={actionUrl}
            columns={getColumns()}
            buttonComponent={getButtons()}
            sorting={{
                field: 'createdAt',
                order: 'desc',
            }}
            noAutoFetch
            noFetchMessage={t('reports.noFetch')}
        />
    );
}

export default withUserCompany(VacancyApplicantsReport);
