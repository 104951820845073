export const FULL_LICENSE = 'full';
export const MINIMAL_LICENSE = 'minimal';
export const FUNCTIONAL_LICENSE = 'functional';
export const DEMO_LICENSE = 'demo';

export const LICENSES = [
    { label: 'company.licenseTypes.full', value: FULL_LICENSE },
    { label: 'company.licenseTypes.minimal', value: MINIMAL_LICENSE },
    { label: 'company.licenseTypes.functional', value: FUNCTIONAL_LICENSE },
    { label: 'company.licenseTypes.demo', value: DEMO_LICENSE }
];

export const FULL_SETTINGS = {
    bot: true,
    domain: true,
    landing: true,
    terminal: true,
};

export const USER_PUBLICATIONS = [
    { label: 'company.userPublications.domains', key: 'domain' },
    { label: 'company.userPublications.bots', key: 'bot' },
    { label: 'company.userPublications.terminal', key: 'terminal' },
    { label: 'company.userPublications.landings', key: 'landing' },
];
