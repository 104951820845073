import { contains, concat, compose, filter, map, props, prop, join, reduce, uniq } from 'ramda';

import { STATIONS, LINE_CONFIG } from '../constants/metro';

const defaultFullNameOrder = ['lastName', 'firstName', 'middleName'];

export const getFullNameInOrder = (order = defaultFullNameOrder) => (
    compose(join(' '), filter(Boolean), props(order))
);

export const getFullName = getFullNameInOrder();

export const intervalTemplate = (from, to, preFrom = '', preTo = '', empty = '', delimiter = '-') => {
    let result = '';
    if (from && to) {
        result += delimiter ? `${from} ${delimiter} ${to}` : `${preFrom} ${from} ${preTo} ${to}`;
    } else {
        result += from ? `${preFrom} ${from} ` : '';
        result += to ? `${preTo} ${to} ` : '';
    }
    return result || empty;
};

export const isSalaryFixed = value => value === 'fixed';
export const SALARY_FORMAT = (value) => value ? String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '';
export const getMoneyRange = (from, to, viewType, t) => (
    from === to && from ?
        from :
        intervalTemplate(
            SALARY_FORMAT(from),
            SALARY_FORMAT(to),
            isSalaryFixed(viewType) ? '' : t('vacancy.detail.from'),
            isSalaryFixed(viewType) ? '' : t('vacancy.detail.to'),
            '-',
            ''
        )
);

export const getStationsLines = (values = []) => {
    const stations = filter(item => contains(item.id, values), STATIONS);
    const lines = uniq(reduce((lines, station) => concat(station.lines, lines), [], stations));

    return map(line => prop('id', LINE_CONFIG[line]), lines);
}
