import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import Table from './Table';
import Toolbar from './Toolbar';
import { getFilters, getUrlParams, getUrlPagination } from '../../utils/urlParams';
import { LINES_PER_PAGE } from '../../constants/lists';
import ActionsContext from '../../contexts/ActionsContext';
import nanoid from '../../utils/nanoid';
import useCompanyCodeSwr from '../../utils/useCompanyCodeSwr';

const TableList = props => {
    const [idx] = useState(nanoid());
    const [autoFetch, setAutoFetch] = useState(!props.noAutoFetch);
    const { addAction, removeAction } = useContext(ActionsContext);
    const location = useLocation();
    const history = useHistory();
    const { initFilters, staticFilter = {}, sorting, urlPrefix, relations, pagination, headers, actionUrl, getAdditionalFilter, noAutoFetch } = props;
    const filter = {
        ...staticFilter,
        ...(getFilters(location, urlPrefix) || initFilters || {})
    };

    const urlParams = {
        pagination: pagination || getUrlPagination(location, urlPrefix),
        filter: {
            ...filter,
            ...(getAdditionalFilter ? getAdditionalFilter(filter) : {})
        },
        sorting,
        relations
    };

    const action = useCompanyCodeSwr(
        [actionUrl, JSON.stringify(urlParams)],
        (url, params) => axios.get(url, { params: JSON.parse(params), headers }),
        {
            revalidateOnMount: autoFetch
        }
    );

    useEffect(() => {
        if (noAutoFetch) {
            action.mutate(null, false);
            setAutoFetch(true);
        }
        addAction(`${actionUrl}_${idx}`, action);
        return () => {
            if (noAutoFetch) {
                action.mutate(null, false);
            }
            removeAction(`${actionUrl}_${idx}`);
        };
    }, []);

    useEffect(() => {
        addAction(`${actionUrl}_${idx}`, action);
    }, [JSON.stringify(urlParams), action.isValidating]);

    const setOffset = (offset, limit) => {
        const { urlPrefix } = props;
        const params = getUrlParams(location);
        const search = { ...params, offset, limit };

        history.push({
            pathname: history.location.pathname,
            search: qs.stringify(urlPrefix ? {
                [urlPrefix]: search
            } : search),
        });
    }

    const setLimit = (limit = LINES_PER_PAGE) => {
        const { urlPrefix } = props;
        const params = getUrlParams(location);
        const search = { ...params, offset: 0, limit };

        history.push({
            pathname: history.location.pathname,
            search: qs.stringify(urlPrefix ? {
                [urlPrefix]: search
            } : search),
        });
    }

    return (
        <ListTableStateless
            {...props}
            action={action}
            setOffset={setOffset}
            setLimit={setLimit}
            location={location}
        />
    );
}

TableList.defaultProps = {
    toolbarButtonSize: 8,
    toolbarFilterSize: 16,
};

const ListTableStateless = props => {
    const { t } = useTranslation();

    const renderError = () => {
        const { action } = props;

        return (
            <div className='table-error'>
                <span className='table-error-message'>
                    { t('app.tableError') }
                </span>
                <Button
                    className='table-error-reload-button'
                    shape='circle'
                    icon={<ReloadOutlined />}
                    size='small'
                    onClick={() => action.mutate()}
                />
            </div>
        );
    }

    const {
        action,
        setOffset,
        setLimit,
        columns,
        className,
        buttons,
        buttonComponent,
        title,
        filterForm : FilterForm,
        scroll,
        location,
        initFilters,
        rowKey,
        onRow,
        rowSelection,
        getRowSelection,
        expandedRowRender,
        staticFilter,
        urlPrefix,
        parseItems,
        tableLayout,
        toolbarButtonSize,
        toolbarFilterSize,
        afterToolbar,
        multipleExpanded,
        noAutoFetch,
        noFetchMessage,
    } = props;

    const filters = {
        ...initFilters,
        ...getFilters(location, urlPrefix)
    };

    const filterForm = FilterForm && (
        <FilterForm
            action={action}
            initFilters={filters}
            staticFilter={staticFilter}
            urlPrefix={urlPrefix}
        />
    );
    const toolbar = (filterForm || buttons || buttonComponent) && (
        <Toolbar
            action={action}
            title={title}
            filterForm={filterForm}
            buttons={buttons}
            buttonComponent={buttonComponent}
            toolbarButtonSize={toolbarButtonSize}
            toolbarFilterSize={toolbarFilterSize}
        />
    );

    return (
        <div className={className}>
            {toolbar}
            {afterToolbar}
            <Table
                tableLayout={tableLayout}
                action={action}
                setOffset={setOffset}
                setLimit={setLimit}
                columns={columns}
                scroll={scroll}
                rowKey={rowKey}
                onRow={onRow}
                location={location}
                rowSelection={getRowSelection ? getRowSelection(props, filters) : rowSelection}
                expandedRowRender={expandedRowRender}
                urlPrefix={urlPrefix}
                parseItems={parseItems}
                footer={action.error && renderError}
                multipleExpanded={multipleExpanded}
                noAutoFetch={noAutoFetch}
                noFetchMessage={noFetchMessage}
            />
        </div>
    );
}

export default TableList;
