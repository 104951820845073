import preview1 from '../images/slider/1.png';
import preview2 from '../images/slider/2.png';
import preview3 from '../images/slider/3.png';
import preview4 from '../images/slider/4.png';
import preview5 from '../images/slider/5.png';
import preview6 from '../images/slider/6.png';
import preview7 from '../images/slider/7.png';
import preview8 from '../images/slider/8.png';
import preview9 from '../images/slider/9.png';
import preview10 from '../images/slider/10.png';
import preview11 from '../images/slider/11.png';

export const SLIDER_TEMPLATES = t => ([
    { type: 'template1', image: preview1, options: {
        bg: '/assets/slider/1.png',
        bgPosition: 'center top',
        bgColor: 'rgba(189,16,44,0.8)',
        titleColor: '#fff',
        descriptionColor: '#fff',
        title: t('banner.templates.title1'),
        showQr: true,
        buttonText: t('banner.templates.buttonText'),
        qrBg: '#fff'
    }},
    { type: 'template2', image: preview2, options: {
        bg: '/assets/slider/2.png',
        bgColor: '#fff',
        titleColor: '#93b011',
        descriptionColor: '#93b011',
        title: t('banner.templates.title1'),
        showQr: true,
        buttonText: t('banner.templates.buttonText'),
        qrBg: '#eef1f7'
    }},
    { type: 'template3', image: preview3, options: {
        bg: '/assets/slider/3.png',
        bgColor: '#2b9c4a',
        titleColor: '#fff',
        descriptionColor: '#fff',
        title: t('banner.templates.title1'),
        showQr: true,
        buttonText: t('banner.templates.buttonText'),
        qrBg: '#fff'
    }},
    { type: 'template4', image: preview4, options: {
        bg: '/assets/slider/4.png',
        bgColor: '#EB0E15',
        titleColor: '#fff',
        descriptionColor: '#fff',
        title: t('banner.templates.title1'),
        showQr: true,
        buttonText: t('banner.templates.buttonText'),
        qrBg: '#eef1f7'
    }},
    { type: 'template5', image: preview5, options: {
        bg: '/assets/slider/5.png',
        bgColor: 'rgba(255,255,255,0.6)',
        titleColor: '#000',
        descriptionColor: '#b23249',
        title: t('banner.templates.title1'),
        showQr: true,
        buttonText: t('banner.templates.buttonText'),
        qrBg: '#fff'
    }},
    { type: 'template6', image: preview6, options: {
        bg: '/assets/slider/6.png',
        bgColor: '#fff',
        titleColor: '#1a5286',
        descriptionColor: '#1a5286',
        title: t('banner.templates.title1'),
        showQr: true,
        buttonText: t('banner.templates.buttonText'),
        qrBg: '#fff'
    }},
    { type: 'template7', image: preview7, options: {
        bg: '/assets/slider/7.png',
        bgColor: '#b0b41f',
        titleColor: '#fff',
        descriptionColor: '#1a5286',
        title: t('banner.templates.title2'),
        showQr: true,
        buttonText: t('banner.templates.buttonText'),
        qrBg: '#fafafa'
    }},
    { type: 'template8', image: preview8, options: {
        bg: '/assets/slider/8.png',
        bgColor: '#3C9628',
        titleColor: '#fff',
        descriptionColor: '#fff',
        title: t('banner.templates.title1'),
        showQr: true,
        buttonText: t('banner.templates.buttonText'),
        qrBg: '#fff'
    }},
    { type: 'template9', image: preview9, options: {
        bg: '/assets/slider/9.png',
        bgColor: '#0083c5',
        titleColor: '#fff',
        descriptionColor: '#fff',
        title: t('banner.templates.title1'),
        showQr: true,
        buttonText: t('banner.templates.buttonText'),
        qrBg: '#fff'
    }},
    { type: 'template10', image: preview10, options: {
        bg: '/assets/slider/10.png',
        bgColor: '#e78f00',
        titleColor: '#fff',
        descriptionColor: '#000',
        title: t('banner.templates.title3'),
        showQr: true,
        buttonText: t('banner.templates.buttonText'),
        qrBg: '#fff'
    }},
    { type: 'template11', image: preview11, options: {
        bg: '/assets/slider/11.png',
        bgColor: 'rgb(140, 151, 178)',
        titleColor: '#fff',
        descriptionColor: '#fff',
        title: t('banner.templates.title4'),
        showQr: true,
        buttonText: t('banner.templates.buttonText'),
        qrBg: '#eef1f7'
    }},
]);

export const QR_OR_BUTTON = [
    { value: true, label: 'QR' },
    { value: false, label: 'banner.button' }
];

export const SCROLL_DIRECTION = [
    { value: false, label: 'banner.horizontalScroll' },
    { value: true, label: 'banner.verticalScroll' }
];
