import React from 'react';
import { CubeVacancy, CubeVacancyLogo, CubeVacancySalary, CubeVacancyName, CubeVacancyText, Box } from '@experium/findy-ui';
import styled from 'styled-components';
import { path, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { FILE, BASE_URL } from '../../../constants/urls';
import { isSalaryFixed } from '../../../utils/fieldsHelpers';

const currency = {
    ru: 'RUB',
    ua: 'UAH',
    en: 'USD',
    et: 'EUR',
    lt: 'EUR',
    lv: 'EUR'
};

const Wrapper = styled.div`
    width: 300px;
    height: 230px;
`;

const CubeVacancyPreview = props => {
    const { t, i18n } = useTranslation();
    const { vacancy } = props;
    const logo = path(['companySettings', 'logo'], vacancy);

    return <Wrapper>
        <CubeVacancy
            backgroundColor='#f5f5f5'
            triangleColor={pathOr(path(['companySettings', 'theme', 'vacancyColor'], vacancy), ['terminalSettings', 'backgroundColor'], vacancy)}
            columnCount={1}
            rowCount={1}>
            <Box display='flex' height='50%'>
                { logo && <CubeVacancyLogo image={`${BASE_URL}${FILE.stringify({ id: logo })}`} alt='' /> }
            </Box>
            <CubeVacancyText height='50%'>
                <CubeVacancyName>{ vacancy.title }</CubeVacancyName>
                { vacancy.minSalary && vacancy.maxSalary ?
                    <CubeVacancySalary>{ vacancy.minSalary }-{vacancy.maxSalary} { currency[i18n.language] }</CubeVacancySalary> :
                    vacancy.minSalary ? <CubeVacancySalary>{ t('vacancy.detail.from') } { vacancy.minSalary } { currency[i18n.language] }</CubeVacancySalary> :
                    vacancy.maxSalary ? <CubeVacancySalary>{ !isSalaryFixed(vacancy.salaryViewType) && t('vacancy.detail.to') } { vacancy.maxSalary } { currency[i18n.language] }</CubeVacancySalary> : null
                }
            </CubeVacancyText>
        </CubeVacancy>
    </Wrapper>;
}

export default CubeVacancyPreview;
