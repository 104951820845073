import React from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import AboutCompany from '../../forms/AboutCompany' ;
import { putChatSettingsHandler } from '../../../actions/handlers';
import { CHAT_SETTINGS } from '../../../constants/urls';
import { pathOr } from 'ramda';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const TextSettings = () => {
    const { t } = useTranslation();
    const chatSettings = useCompanyCodeSwr(CHAT_SETTINGS.stringify());

    return (
        !!chatSettings.data && <AboutCompany
            formAction={putChatSettingsHandler}
            chatSettings={pathOr({}, ['data'], chatSettings)}
            onSuccess={() => {
                chatSettings.mutate();
                message.success(t('settings.profileSaveSuccess'));
            }}
            onSubmitFail={() => message.error(t('settings.profileSaveError'))} />
    );
}

export default TextSettings;
