import React from 'react';
import { Modal } from 'antd';
import { prop } from 'ramda';

import ClassifiersLanguageForm from '../../../forms/ClassifiersLanguageForm';
import { useTranslation } from 'react-i18next';

const ClassifiersLanguageModal = props => {
    const { t } = useTranslation();
    const { modal, params, close } = props;
    const items = prop('items', params);

    return (
        <Modal
            {...modal}
            title={t('settings.translations')}
            footer={null}
            closable={false}
        >
            <ClassifiersLanguageForm items={items} close={close} />
        </Modal>
    );
}

export default ClassifiersLanguageModal;
