import React, { useContext, useState } from 'react';
import { Button, Popconfirm, Select, message } from 'antd';
import { EditOutlined, DeleteOutlined, GlobalOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { path } from 'ramda';
import { useTranslation } from 'react-i18next';

import { QUESTION_PRESET } from '../../../../constants/questions';
import TableList from '../../../table/TableList';
import { deleteQuestionPresetHandler } from '../../../../actions/handlers';
import ActionsContext from '../../../../contexts/ActionsContext';
import { QUESTIONS_PRESET } from '../../../../constants/urls';
import ModalsContext from '../../../../contexts/ModalsContext';
import { AVAILABLE_LANGUAGES } from '../../../../constants/dictionary';
import { OPEN_QUESTION_PRESET_MODAL, OPEN_VACANCY_QUESTION_LANGUAGE_MODAL } from '../../../../constants/actionTypes';

const QuestionsPresets = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const [language, setLanguage] = useState('en');
    const actionUrl = QUESTIONS_PRESET.stringify();
    const modals = useContext(ModalsContext);

    const deletePreset = id => {
        deleteQuestionPresetHandler(id).then(() => {
            message.success(t('vacancy.questions.questionDeleteConfirmSuccess'));
            revalidateAction(actionUrl);
        }).catch(() => message.error(t('vacancy.questions.questionDeleteConfirmError')));
    }

    const getColumns = () => {
        return [
            {
                title: t('vacancy.questions.name'),
                dataIndex: 'label',
                key: 'label'
            },
            {
                title: t('vacancy.questions.question'),
                dataIndex: 'question',
                key: 'question'
            },
            {
                title: `${t('vacancy.questions.name')}, ${language.toUpperCase()}`,
                key: 'translationLabel',
                render: path(['translations', 'label', language]),
            },
            {
                title: `${t('vacancy.questions.question')}, ${language.toUpperCase()}`,
                key: 'translationQuestion',
                render: path(['translations', 'question', language]),
            },
            {
                key: 'controls',
                width: 126,
                render: item =>
                    <Button.Group>
                        <Button
                            className='edit-btn'
                            icon={<EditOutlined />}
                            onClick={() => modals.open(OPEN_QUESTION_PRESET_MODAL, item)} />
                        <Button
                            className='translations-btn'
                            icon={<GlobalOutlined />}
                            onClick={() => modals.open(OPEN_VACANCY_QUESTION_LANGUAGE_MODAL, {
                                item,
                                questionsType: QUESTION_PRESET,
                            })}
                        />
                        <Popconfirm
                            title={t('vacancy.questions.questionDeleteConfirm')}
                            placement='topRight'
                            onConfirm={() => deletePreset(item.id)}
                            okText={t('vacancy.form.yes')}
                            cancelText={t('vacancy.form.no')}>
                            <Button className='delete-btn' type='danger' icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Button.Group>
            }
        ]
    };

    const filterForm = () => (
        <div className='text-left'>
            <Select
                value={language}
                onChange={lang => {
                    setLanguage(lang);
                }}
                style={{ width: 180, marginLeft: 10 }}
            >
                { AVAILABLE_LANGUAGES.map(lang =>
                    <Select.Option key={`language-${lang}`} value={lang}>
                        {t('settings.translation')}, { lang.toUpperCase() } <span className={`flag flag-${lang}`} />
                    </Select.Option>
                )}
            </Select>
        </div>
    )

    const renderAddButton = () => {
        return (
            <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => modals.open(OPEN_QUESTION_PRESET_MODAL, { new: true })}
            >
                { t('vacancy.questions.add') }
            </Button>
        );
    }

    return <TableList
        actionUrl={actionUrl}
        headers={{
            'Accept-Language': 'ru',
        }}
        location={props.location}
        columns={getColumns()}
        buttons={renderAddButton()}
        filterForm={filterForm}
        staticFilter={{ customizable: false }}
    />;
}

export default QuestionsPresets;
