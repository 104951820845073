import React from 'react';
import { Upload, Button } from 'antd';
import styled from 'styled-components';
import { EditOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import withUserCompany from '../../hocs/withUserCompany';

export const PreviewWrapper = styled.div`
    .ant-btn {
        margin-left: 10px
    }
`;

export const ImageWrapper = styled.div`
    border-radius: 4px;
    padding: 5px;
    display: inline-block;
    border: 1px solid #e8e8e8;
    img {
        border-radius: 4px;
    }
`;

const ImageLoader = props => {
    const { t } = useTranslation();
    const reader = new FileReader();

    const beforeUpload = file => {
        reader.readAsDataURL(file);
        reader.onload = () => props.onChange(reader.result);

        return false;
    }

    const { input: { value, name }, onChange, hidePreview, companyCode } = props;

    return value ?
        <PreviewWrapper>
            { !hidePreview &&
                <ImageWrapper>
                    <img src={value} width={200} alt={`${name}-preview`} />
                </ImageWrapper>
            }
            <Upload
                beforeUpload={beforeUpload}
                showUploadList={false}
                headers={{
                    'company-code': companyCode,
                }}
                accept='image/*'>
                <Button
                    icon={<EditOutlined />}
                    shape='circle' />
            </Upload>
            <Button
                icon={<DeleteOutlined />}
                type='danger'
                shape='circle'
                onClick={() => onChange(null)} />
        </PreviewWrapper> :
        <Upload
            beforeUpload={beforeUpload}
            showUploadList={false}
            headers={{
                'company-code': companyCode,
            }}
            accept='image/*'>
            <Button>
                <UploadOutlined /> { t('form.loadImage') }
            </Button>
        </Upload>;
}

export default withFieldWrapper(withUserCompany(ImageLoader));
