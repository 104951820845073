import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import VacancyFormPresetForm from '../../../forms/VacancyFormPresetForm';
import { postVacancyFormPresetHandler, putVacancyFormPresetHandler } from '../../../../actions/handlers';
import { VACANCY_FORM_PRESET } from '../../../../constants/urls';
import ActionsContext from '../../../../contexts/ActionsContext';

const FormPresetModal = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const { modal, params, close } = props;
    const { hideQuestions, ...item } = params;

    return <Modal
        {...modal}
        title={item.id ? t('vacancy.questions.editFormPreset') : t('vacancy.questions.addFormPreset')}
        footer={null}>
        <VacancyFormPresetForm
            formAction={item.id ? putVacancyFormPresetHandler : postVacancyFormPresetHandler}
            item={item}
            hideQuestions={hideQuestions}
            onSuccess={() => {
                revalidateAction(VACANCY_FORM_PRESET.stringify());
                message.success(t(item.id ? 'vacancy.questions.formPresetSuccessSave' : 'vacancy.questions.formPresetSuccessAdd'));
                close();
            }}
            onSubmitFail={() => message.error(t(item.id ? 'vacancy.questions.formPresetErrorSave' : 'vacancy.questions.formPresetErrorAdd'))} />
    </Modal>;
}

export default FormPresetModal;
