import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';

import { stateToHTMLOptions } from '../constants/editor';

export const preWrap = value => (value || '').replace(/(?:\r\n|\r|\n)/g, '');

export const mailFromHTML = (value) => {
    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
}

export const mailToHTML = (contentState) => {
    const rawContentState = convertToRaw(contentState);
    const value = draftToHtml(rawContentState);

    return value;
}

export const landingFromHTML = (value) => {
    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    return EditorState.createWithContent(contentState);
}

export const landingToHTML = (contentState) => {
    const rawContentState = convertToRaw(contentState);
    const value = draftToHtml(rawContentState);

    return value;
}

export const chatFromHTML = (value) => {
    const contentBlock = stateFromHTML(value);

    return EditorState.createWithContent(contentBlock)
}

export const chatToHTML = (contentState) => stateToHTML(contentState, stateToHTMLOptions);
