import { CheckOutlined, ClockCircleOutlined, CloseOutlined, InfoCircleOutlined, RocketOutlined } from '@ant-design/icons';
import { Button, message, Tooltip } from 'antd';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { putCompanySettingsHandler } from '../../../actions/handlers';
import { COMPANY_SETTINGS, QUEUE_ENTRY } from '../../../constants/urls';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';
import HookSettingsForm from '../../forms/HookSettingsForm';
import TableList from '../../table/TableList';
import DateTime from '../../table/DateTime';
import ModalsContext from '../../../contexts/ModalsContext';
import { OPEN_QUEUE_ENTRY_MODAL } from '../../../constants/actionTypes';

const Separator = styled.div`
    height: 1px;
    background: #ccc;
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-bottom: 15px;
`;

export const StatusIcon = ({ status }) => {
    const { t } = useTranslation();
    const icons = {
        'scheduled': ClockCircleOutlined,
        'running': RocketOutlined,
        'success': CheckOutlined,
        'fail': CloseOutlined
    };

    const colors = {
        'scheduled': '#ccc',
        'running': 'blue',
        'success': 'green',
        'fail': 'red'
    };

    const IconComponent = icons[status];

    return status ? <Tooltip title={t(`settings.hookStatuses.${status}`)}><IconComponent style={{ color: colors[status] }} /></Tooltip> : null;
}

const HookSettings = () => {
    const { t } = useTranslation();
    const modals = useContext(ModalsContext);
    const companySettings = useCompanyCodeSwr(COMPANY_SETTINGS.stringify());

    const getColumns = () => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                render: (id, { status }) => <span><StatusIcon status={status} /> { id }</span>
            },
            {
                title: t('settings.startedAt'),
                dataIndex: 'startedAt',
                key: 'startedAt',
                render: date => <DateTime date={date} />
            },
            {
                key: 'info',
                align: 'right',
                render: item => <Button onClick={() => modals.open(OPEN_QUEUE_ENTRY_MODAL, item)}>
                    <InfoCircleOutlined />
                </Button>
            }
        ];
    }

    return <Fragment>
        { !!companySettings.data &&
            <Fragment>
                <HookSettingsForm
                    formAction={putCompanySettingsHandler}
                    item={companySettings.data}
                    onSuccess={() => message.success(t('settings.settingsSaveSuccess'))}
                    onSubmitFail={() => message.error(t('settings.settingsSaveError'))} />
                <Separator />
            </Fragment>
        }
        <TableList
            actionUrl={QUEUE_ENTRY.stringify()}
            columns={getColumns()}
            sorting={{
                field: 'createdAt',
                order: 'desc',
            }} />
    </Fragment>;
}

export default HookSettings;
