import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import { Alert, Col, Row } from 'antd';
import * as yup from 'yup';
import { prop } from 'ramda';

import { defaultChatMesssages } from '../../constants/defaultPlaceholders';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import AvatarLoader from './formComponents/AvatarLoader';
import SubmitButton from './formComponents/SubmitButton';
import { isWeb, isFacebook } from '../../utils/applicant';
import BotResponse from '../views/chat/BotResponse';
import ListenerField from './ListenerField';
import { useTranslation } from 'react-i18next';

const BotForm = () => {
    const { t } = useTranslation();

    return <Fragment>
        <ListenerField listenFieldName='type'>
            { values => (
                isFacebook(values) && (
                    <Alert
                        message={t('bot.attention')}
                        description={(
                            <div>
                                { t('bot.afterAdd') }
                                <a href='https://developer.facebook.com' target='_blank' rel='noopener noreferrer'> developer.facebook.com</a>
                                <br />
                                { t('bot.chatBotWillWork') }
                            </div>
                        )}
                        type='warning'
                    />
                )
            )}
        </ListenerField>
        <Field
            name='username'
            component={Input}
            label={t('bot.botName')} />
        <ListenerField listenFieldName='type'>
            { (values) => isWeb(values) ? (
                <Row>
                    <Col xs={5}>
                        <Field
                            name='avatar'
                            label={t('bot.avatar')}
                            component={AvatarLoader}
                        />
                    </Col>
                    <Col xs={19}>
                        <div className='dialog-preview'>
                            <BotResponse
                                botName={values.username}
                                botAvatar={values.avatar}
                                data={{
                                    createdAt: (new Date).toISOString(),
                                    output: [
                                        {
                                            type: 'text',
                                            data: {
                                                text: t(defaultChatMesssages.welcome),
                                            }
                                        }
                                    ]
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            ) : (
                <Field
                    name='token'
                    component={Input}
                    label={t('bot.token')}
                />
            )}
        </ListenerField>
        <div className='modal-form-toolbar'>
            <SubmitButton type='primary'>
                { t('form.save') }
            </SubmitButton>
        </div>
    </Fragment>;
}

export const validationSchema = () => yup.object().shape({
    username: yup.string().required(),
    token: yup.string().when('type', {
        is: 'web',
        then: yup.string().notRequired(),
        otherwise: yup.string().required(),
    }),
    type: yup.string().required()
});

export default withFormWrapper(BotForm, {
    mapPropsToValues: prop('bot'),
    validationSchema
});
