import React, { useState } from 'react';
import { Button, Row, Col, Collapse, Radio } from 'antd';
import { pathOr, dissoc, path, propOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { LANGUAGES } from '../../constants/dictionary';
import withUserCompany from '../hocs/withUserCompany';

const { Panel } = Collapse;

const VacancyQuestionForm = props => {
    const { t } = useTranslation();
    const [language, setLanguage] = useState('en');

    const changeLanguage = (event) => {
        const value = event.target.value;

        setLanguage(value);
    }

    const getTranslation = (item, key, russianKey) => {
        if (language === 'ru') {
            return russianKey ? item[russianKey] : item[key];
        } else {
            return path(['translations', key, language], item);
        }
    }

    const renderLanguageRadio = () => {
        return (
            <Radio.Group value={language} onChange={changeLanguage}>
                { LANGUAGES.map((language, index) =>
                    <Radio.Button key={`${name}-${index}`} value={language.value} disabled={language.disabled}>
                        { language.label }
                    </Radio.Button>
                )}
            </Radio.Group>
        );
    }

    const renderCompositeQuestion = (questionItem ) => {
        const subQuestions = pathOr([], ['settings', 'questions'], questionItem);

        return (
            <div className='composite-question' key={questionItem.field}>
                <div className='main-question'>
                    { renderQuestion(dissoc('type', questionItem)) }
                </div>
                <div className='sub-questions'>
                    { subQuestions.map(question => renderQuestion(question)) }
                </div>
            </div>
        );
    }

    const renderChoiceQuestion = (questionItem) => {
        const choices = pathOr([], ['settings', 'choices'], questionItem);

        return (
            <div className='choices-question' key={questionItem.field}>
                <Collapse expandIconPosition='right'>
                    <Panel header={renderQuestion(dissoc('type', questionItem))} key="1">
                        <div className='sub-questions'>
                            <div className='sub-questions-title'>{ t('vacancy.form.listOfAnswers') }:</div>
                            { choices.map(choice => (
                                <div
                                    key={choice.id}
                                    className='question-translate-value'
                                >
                                    { getTranslation(choice, 'choices', 'value') }
                                </div>
                            ))}
                        </div>
                    </Panel>
                </Collapse>
            </div>
        );
    }

    const renderQuestion = (questionItem) => {
        const { isFunctionalLicense, isAdmin } = props;

        switch (questionItem.type) {
            case 'choice':
                return renderChoiceQuestion(questionItem);
            case 'composite':
                return renderCompositeQuestion(questionItem);
            default:
                return (
                    <Row gutter={10} className='question-translate-item' key={`${questionItem.field}-${language}`}>
                        <Col span={12} className='question-translate-col'>
                            <div className='question-translate-value'>{getTranslation(questionItem, 'label')}</div>
                        </Col>
                        { !(isFunctionalLicense && !isAdmin) && <Col span={12} className='question-translate-col'>
                            <div className='question-translate-value'>{getTranslation(questionItem, 'question')}</div>
                        </Col>}
                    </Row>
                );
        }
    }

    const { isFunctionalLicense, isAdmin } = props;
    const questions = propOr([], 'questions', props);

    return (
        <div>
            <div className='language-select'>
                { renderLanguageRadio() }
            </div>
            <Row className='question-translate-header'>
                <Col span={12} className='question-translate-col'>{ t('vacancy.form.title') }:</Col>
                { !(isFunctionalLicense && !isAdmin) && <Col span={12} className='question-translate-col'>{ t('vacancy.form.question') }:</Col>}
            </Row>
            { questions.map(question => renderQuestion(question)) }
            <Button
                style={{ marginTop: '15px' }}
                type='primary'
                onClick={() => props.close()}
            >
                Ок
            </Button>
        </div>
    );
}

export default withUserCompany(VacancyQuestionForm);
