export const DEFAULT_QUESTIONS = 'DEFAULT_QUESTIONS';
export const PRESET_QUESTIONS = 'PRESET_QUESTIONS';
export const QUESTION_PRESET = 'QUESTION_PRESET';
export const SUB_QUESTION = 'SUB_QUESTION';

export const QUESTIONS_KEY = {
    [DEFAULT_QUESTIONS]: 'questions',
    [PRESET_QUESTIONS]: 'questions',
    [SUB_QUESTION]: 'settings.questions',
};

export const OPD_TYPE = 'personalDataAgreement';

export const RESERVED_ALLOW_QUESTIONS_TYPES = ['text', 'phone', 'dictionary', 'city', 'region', 'country', 'email', 'date', 'choice', 'company_dictionary', 'money'];

export const RESERVED_QUESTIONS_LIMIT = 40;
