import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import QrBannerForm from '../../forms/QrBannerForm';
import { postBannerHandler, putBannerHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { BANNER } from '../../../constants/urls';

const QrBannerModal = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const { modal, params, close } = props;

    return <Modal
        {...modal}
        title={params.id ? t('banner.editBanner') : t('banner.addBanner')}
        footer={null}
        maskClosable={false}>
        <QrBannerForm
            item={params}
            formAction={params.id ? putBannerHandler : postBannerHandler}
            onSuccess={() => {
                revalidateAction(BANNER.stringify());
                message.success(t(params.id ? 'banner.bannerEditSuccess' : 'banner.bannerAddSuccess'));
                close();
            }}
            onSubmitFail={() => message.error(t(params.id ? 'banner.bannerEditError' : 'banner.bannerAddError'))} />
    </Modal>;
}

export default QrBannerModal;
