import React from 'react';
import { Row, Col } from 'antd';

const Toolbar = ({ filterForm, title, buttonComponent : ButtonComponent, buttons, toolbarFilterSize, toolbarButtonSize, action }) => (
    <Row className='toolbar'>
        <Col sm={24} lg={buttons || ButtonComponent ? toolbarFilterSize : 24}>
            { filterForm || title }
        </Col>
        { !!(buttons || ButtonComponent) && (
            <Col sm={24} lg={toolbarButtonSize}>
                { ButtonComponent ? <ButtonComponent action={action} /> : buttons }
            </Col>
        )}
    </Row>
);

export default Toolbar;
