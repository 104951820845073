import React from 'react';
import Designer from '@experium/react-designer';

import { VIEW_WIDTH, VIEW_HEIGHT } from '../../../constants/print';
import { getToken } from '../../../utils/token';
import { POST_FILE } from '../../../constants/urls';

const QrBuidler = props => {
    return <Designer
        width={VIEW_WIDTH}
        height={VIEW_HEIGHT}
        data={props.data}
        onChange={props.updateData}
        getStageRef={props.getStageRef}
        getFileUrl={id => `/api/file/${id}`}
        postFileUrl={`/api${POST_FILE.stringify({ token: getToken() })}`} />;
}

export default QrBuidler;
