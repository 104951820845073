import React from 'react';
import { Row, Col } from 'antd';
import { Field } from 'react-final-form';

import InputSearch from '../../forms/formComponents/InputSearch';

const BaseFilter = ({ searchFieldName, children, searchFieldPlaceholder, actionUrl, namePath, span = 8, smallPath, component, allowClear, searchLimit, searchable, searchKey, sorting }) => (
    <Row key='header' gutter={5}>
        <Col span={span}>
            <Field
                actionUrl={actionUrl}
                name={searchFieldName}
                namePath={namePath}
                smallPath={smallPath}
                placeholder={searchFieldPlaceholder}
                component={component || InputSearch}
                allowClear={allowClear}
                searchLimit={searchLimit}
                searchable={searchable}
                searchKey={searchKey}
                sorting={sorting}
            />
        </Col>
        { children || null }
    </Row>
);

export default BaseFilter
