import React, { useContext } from 'react';
import { contains, includes, find, path, propEq, pathOr } from 'ramda';
import axios from 'axios';

import { ADMIN, USER, COMPANY_MANAGER, ADMIN_COMPANY } from '../../constants/roles';
import { DEMO_LICENSE, FULL_LICENSE, FULL_SETTINGS, FUNCTIONAL_LICENSE } from '../../constants/company';
import { checkMultiCompany } from '../../utils/companyCode';
import UserContext from '../../contexts/UserContext';
import CompanyCodeContext from '../../contexts/CompanyCodeContext';
import { COMPANY } from '../../constants/urls';
import useCompanyCodeSwr from '../../utils/useCompanyCodeSwr';

const withUserCompany = Component => props => {
    const { user } = useContext(UserContext);
    const { changeCompanyCode, companyCode } = useContext(CompanyCodeContext);
    let company = path(['_relations', 'company'], user);
    let companies = path(['_relations', 'companies'], user);
    const isMultiCompany = checkMultiCompany(user);
    const isAdminCompany = includes(ADMIN_COMPANY, user.roles || []);
    const isAdmin = includes(ADMIN, user.roles || []) || isAdminCompany;
    const isManager = includes(COMPANY_MANAGER, user.roles || []) || isAdminCompany;

    if (!company && !isAdminCompany) {
        const companiesQuery = useCompanyCodeSwr([COMPANY.stringify(), JSON.stringify({ pagination: { offset: 0, limit: 0 } })], (url, params) => axios.get(url, {
            params: JSON.parse(params)
        }));
        company = find(propEq('code', companyCode), pathOr([], ['data', 'items'], companiesQuery)) || {};
    }

    if (companies) {
        company = find(propEq('code', companyCode), companies) || {};
    }

    const userProps = {
        userId: user.id,
        userRoles: user.roles,
        userCompanies: companies,
        license: company.license,
        stateCompanyCode: companyCode,

        isMultiCompany,
        isUser: contains(USER, user.roles) && (user.roles.length === 1),
        isFullLicense: (company.license || FULL_LICENSE) === FULL_LICENSE,
        isFunctionalLicense: company.license === FUNCTIONAL_LICENSE || company.license === DEMO_LICENSE,
        isAdmin,
        isAdminCompany,
        isManager,
        isAdminOnly: isAdmin && !companyCode,
        isCompanyAdmin: isManager || (isAdmin && companyCode),

        companyId: company.id,
        companyCode: company.code,
        companyName: company.name || '',

        publicationSettings: isAdmin ? FULL_SETTINGS : user.publicationSettings || {},

        changeCompanyCode
    };

    return <Component {...props} {...userProps} />;
}

export default withUserCompany;
