import React from 'react';
import { Button } from 'antd';
import { assocPath, dropLastWhile, omit, propOr } from 'ramda';
import { DeleteOutlined } from '@ant-design/icons';

import { deleteMessageNodeHandler, putChatSettingsHandler, putMessageNodeHandler } from '../../../../actions/handlers';
import { CHAT_SETTINGS } from '../../../../constants/urls';
import { MESSAGE_NODE } from '../../../../constants/urls';
import useCompanyCodeSwr from '../../../../utils/useCompanyCodeSwr';

const DeleteButton = props => {
    const chatSettings = useCompanyCodeSwr(CHAT_SETTINGS.stringify());
    const parentItem = props.parent ? useCompanyCodeSwr(MESSAGE_NODE.stringify({ id: props.parent })) : null;

    const removeMessage = () => {
        const { id, buttonIndex } = props;
        const items = propOr([], 'items', props);

        parentItem ?
            putMessageNodeHandler({
                id: props.parent,
                data: assocPath(['botMessages', 0, 'data', 'items', buttonIndex, 'payload'], {}, parentItem.data)
            }).then(() => chatSettings.mutate()) :
            putChatSettingsHandler(assocPath(['mainMenu', 'items', buttonIndex, 'payload'], {}, chatSettings.data));

        const updatedItems = dropLastWhile(i => i.id !== id, items)
            .map(i => i.id === id ? omit(['id', 'stateName'], i) : i);

        props.setItems(updatedItems);
        id && deleteMessageNodeHandler(id).then(() => chatSettings.mutate());
    }

    return <Button
        shape='circle'
        className='message-nodes-block-remove'
        onClick={removeMessage}>
        <DeleteOutlined />
    </Button>;
}

export default DeleteButton;
