import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { prop } from 'ramda';

import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';
import Select from './formComponents/Select';
import withUserCompany from '../hocs/withUserCompany';
import { COMPANY } from '../../constants/urls';

const JuristicEntityForm = props => {
    const { t } = useTranslation();
    const { item, isAdmin, companyCode, company } = props;

    return <Fragment>
        <Field
            name='title'
            component={Input}
            label={t('company.juristicEntity')} />
        { (isAdmin && !companyCode && !company) &&
            <Field
                name='company'
                component={Select}
                label={t('userAdmin.company')}
                actionUrl={COMPANY.stringify()}
                namePath='name'
                filter={{ 'enabled': true }}
            />
        }
        <div className='modal-form-toolbar'>
            <SubmitButton type='primary'>
                { item ? t('form.save') : t('userAdmin.add') }
            </SubmitButton>
        </div>
    </Fragment>;
}

const validationSchema = ({ stateCompanyCode, isAdmin }) => yup.object().shape({
    title: yup.string().required(),
    company: isAdmin && !stateCompanyCode
        ? yup.string().required()
        : yup.string().nullable(),
});

export default withUserCompany(
    withFormWrapper(JuristicEntityForm, {
        mapPropsToValues: props => ({
            ...(prop('item', props) || ({ company: prop('company', props)}))
        }),
        validationSchema
    })
);
