import React from 'react';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const RadioGroup = props => {
    const { t } = useTranslation();

    const onChange = e => {
        props.onChange(e.target.value);
    }

    const { input: { value, name }, options, solid } = props;

    return solid ?
        <Radio.Group value={value} onChange={onChange} id={name}>
            { options.map((option, index) =>
                <Radio key={`${name}-${index}`} value={option.value} disabled={option.disabled}>
                    { t(option.label) }
                </Radio>
            )}
        </Radio.Group> :
        <Radio.Group value={value} onChange={onChange} id={name}>
            { options.map((option, index) =>
                <Radio.Button key={`${name}-${index}`} value={option.value} disabled={option.disabled}>
                    { t(option.label) }
                </Radio.Button>
            )}
        </Radio.Group>;
}

RadioGroup.defaultProps = {
    options: []
};

export default withFieldWrapper(RadioGroup);
