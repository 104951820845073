import React from 'react';
import { Modal, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ButtonWithConfirm = props => {
    const { t } = useTranslation();

    const confirmModal = () => {
        const {
            modalDescription,
            modalOkText,
            modalCancelText,
            modalOkType,
            onConfirm: onOk,
            onCancel
        } = props;

        return (
            Modal.confirm({
                title: t('form.confirmDelete'),
                content: t(modalDescription),
                onOk,
                onCancel,
                okText: t(modalOkText),
                okType: modalOkType,
                cancelText: t(modalCancelText)
            })
        );
    };

    const {
        buttonText,
        buttonType,
        buttonIcon,
        shape,
        buttonSize,
        className
    } = props;

    return (
        <Button
            type={buttonType}
            icon={buttonIcon}
            onClick={confirmModal}
            shape={shape}
            size={buttonSize}
            className={className}
        >
            {t(buttonText)}
        </Button>
    );
}

ButtonWithConfirm.defaultProps = {
    buttonText: 'form.delete',
    buttonType: 'danger',
    buttonIcon: <DeleteOutlined />,
    modalTitle: 'form.title',
    modalDescription: 'form.description',
    modalOkText: 'form.yes',
    modalOkType: 'danger',
    modalCancelText: 'form.no',
};

export default ButtonWithConfirm;
