import React from 'react';
import { withTranslation } from 'react-i18next';

import BaseFilter from './base/BaseFilter';
import withFilterForm from './base/withFilterForm';
import Select from '../forms/formComponents/Select';
import { VACANCY } from '../../constants/urls';

export default withTranslation()(withFilterForm(({ t }) => (
    <BaseFilter
        searchFieldName="vacancy"
        searchFieldPlaceholder={t('call.vacancy')}
        actionUrl={VACANCY.stringify()}
        component={Select}
        searchLimit
        namePath="title"
        searchKey='term'
        searchable
        allowClear
        sorting={{
            field: 'createdAt',
            order: 'desc',
        }}
    />
)));
