import React from 'react';
import { Row, Col, Spin } from 'antd';
import qs from 'qs';
import { prop, pathOr, isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { botLink } from '../../../utils/botLink';
import { BOT_PUBLIC } from '../../../constants/urls';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const getQuery = location => qs.parse(prop('search', location), { ignoreQueryPrefix: true });

const MessengerSelect = props => {
    const { t } = useTranslation();
    const { location } = props;
    const botPublic = useCompanyCodeSwr(BOT_PUBLIC.stringify(), url => axios.get(url, {
        params: {
            filter: getQuery(location)
        }
    }));
    const bots = pathOr([], ['data', 'items'], botPublic);
    const { vacancy } = getQuery(location);

    return (
        <div className='wrap-messenger'>
            { botPublic.isValidating ?
                <Spin className='central-spin' /> :
                <div className='wrap-messenger-container'>
                    { isEmpty(bots) ? <h1>{ t('chat.noActiveBots') }</h1> : <h1>{ t('chat.continueIn') }</h1> }
                    <Row type='flex' gutter={16} justify='center' align='middle'>
                        { bots.map((bot, index) => (
                            <Col key={`${bot.username}-${index}`} xs={24} md={12} lg={6}>
                                <a href={botLink(bot, vacancy)}>
                                    <div className='box-messenger'>
                                        <i className={`icon ${bot.type}`} />
                                        <h2>{bot.username}</h2>
                                    </div>
                                </a>
                            </Col>
                        ))}
                    </Row>
                </div>
            }
        </div>
        );
}

export default MessengerSelect;
