import { path, pathOr, propOr, prop, mapObjIndexed } from 'ramda';

const translationsLocales = ['ua', 'en'];

const checkLocaleTranslation = (locale, translations) => {
    const labelText = path(['label', locale ], translations);
    const guestionText = path(['question', locale ], translations);

    if (!labelText || !guestionText) {
        return false;
    } else {
        return true;
    }
};

const checkChoiceTranslate = (locale, translations) => {
    return !!prop(locale, translations);
}

const checkSubQuestionsTranslation = (subQuestions, translation) => {
    const subquestionTranslationStatus = checkTranslation(subQuestions);

    return mapObjIndexed((value, key) => {
        if (!value) {
            return false;
        } else {
            return subquestionTranslationStatus[key];
        }
    }, translation);
};

const checkTranslation = (questionsValues, isMainCompositeQuestion) => {
    let translation = {
        ru: true,
        en: true,
        ua: true,
    };

    questionsValues.forEach(question => {
        if (translation.en || translation.ua) {
            const { translations, type, settings } = question;

            const questionType = isMainCompositeQuestion ? 'text' : type;

            switch (questionType) {
                case 'choice':
                    translationsLocales.forEach(locale => {
                        const localeIsTranslate = checkLocaleTranslation(locale, translations);

                        if (!localeIsTranslate) {
                            translation[locale] = localeIsTranslate;
                        }

                        if (localeIsTranslate) {
                            const choices = propOr([], 'choices', settings);

                            choices.forEach(choice => {
                                const translations = pathOr({}, ['translations', 'choices'], choice);
                                const choiceIsTranslate = checkChoiceTranslate(locale, translations);

                                if (!choiceIsTranslate) {
                                    translation[locale] = choiceIsTranslate;
                                }
                            });
                        }
                    });
                    break;
                case 'composite':
                    translationsLocales.forEach(locale => {
                        const localeIsTranslate = checkLocaleTranslation(locale, translations);
                        if (!localeIsTranslate) {
                            translation[locale] = localeIsTranslate;
                        }

                        if (localeIsTranslate) {
                            const subQuestions = propOr([], 'questions', settings);

                            translation = checkSubQuestionsTranslation(subQuestions, translation);
                        }
                    });

                    break;
                default:
                    translationsLocales.forEach(locale => {
                        const localeIsTranslate = checkLocaleTranslation(locale, translations);
                        if (!localeIsTranslate) {
                            translation[locale] = localeIsTranslate;
                        }
                    });
                    break;
            }
        }
    });

    return translation;
};

export const getClassifierOptionsTranslationStatus = (optionItem) => {
    const en = path(['translations', 'en'], optionItem);
    const ua = path(['translations', 'ua'], optionItem);

    return en && ua;
}

export const getClassifierTranslationStatus = (classifier) => {
    const options = pathOr([], ['_relations', 'options'], classifier)
    const classifierEn = path(['translations', 'en'], classifier);
    const classifierUa = path(['translations', 'ua'], classifier);
    const classifierIsTranslated = classifierEn && classifierUa;

    if (classifierIsTranslated) {
        let optionsIsTranslated = true;
        options.forEach(option => {
            const optionIsTranslated = getClassifierOptionsTranslationStatus(option);

            if (!optionIsTranslated) {
                return false;
            }
        });

        return optionsIsTranslated;
    } else {
        return false;
    }
}

export const getClassifiersTranslationStatus = (classifiers) => {
    let translation = {
        ru: true,
        en: true,
        ua: true,
    };

    classifiers.forEach(classifier => {
        if (translation.en || translation.ua) {
            translationsLocales.forEach(locale => {
                const classifierLocaleIsTranslate = path(['translations', locale], classifier);

                if (!classifierLocaleIsTranslate) {
                    translation[locale] = false;
                }

                if (translation[locale]) {
                    const classifierOptions = pathOr([], ['_relations', 'options'], classifier);

                    classifierOptions.forEach(classifierOption => {
                        const classifierOptionIsTranslated = path(['translations', locale], classifierOption);

                        if (!classifierOptionIsTranslated) {
                            translation[locale] = false;
                        }
                    });
                }
            });
        }
    });

    return translation;
}

export default checkTranslation;
