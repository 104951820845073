import axios from 'axios';
import { path, pathOr } from 'ramda';

import * as urls from '../../constants/urls';
import { vacanciesFilter, applicantsFilter, applicantsRejectFilter } from '../../utils/handlers';

const withCompanyThemeSettings = (payload, request) =>
    axios.all(payload ? [request, putCompanySettingsHandler(payload)] : [request]);

export const postLoginHandler = payload => axios.post(urls.LOGIN.stringify(), payload);
export const getProfileHandler = (payload) => axios.get(urls.USER.stringify(), {
    headers: {
        'Authorization': `Bearer ${payload}`,
    }
});
export const getUserHandler = () => axios.get(urls.USER.stringify(), {
    params: {
        relations: ['company']
    }
});
export const putUserHandler = payload => axios.put(urls.USER.stringify(), payload);
export const postPasswordRecoveryHandler = payload => axios.post(urls.PASSWORD_RECOVERY.stringify(), payload);
export const postPasswordRecoveryConfirmHandler = payload => axios.post(urls.PASSWORD_RECOVERY_CONFIRM.stringify({ restoreToken: payload.restoreToken }), payload);
export const postPasswordRefreshConfirmHandler = payload => axios.post(urls.PASSWORD_REFRESH_CONFIRM.stringify(), payload, {
    headers: {
        'Authorization': `Bearer ${payload.restoreToken}`,
    }
});
export const postRegistrationConfirmHandler = payload => axios.post(urls.REGISTRATION_CONFIRM.stringify({ id: payload.id }), payload);
export const getRegistrationConfirmHandler = ({ payload }) => axios.get(urls.REGISTRATION_CONFIRM.stringify({ id: payload.id }));

export const getChatSettingsHandler = () => axios.get(urls.CHAT_SETTINGS.stringify());
export const putChatSettingsHandler = payload => axios.put(urls.CHAT_SETTINGS.stringify(), payload);

const getVacanciesParams = (payload = {}) => {
    const filter = pathOr({}, ['filter'], payload);

    return ({
        ...payload,
        sorting: {
            field: 'createdAt',
            order: 'desc',
        },
        filter: {
            ...filter,
            ...vacanciesFilter(filter),
        }
    });
}
export const getVacanciesHandler = payload => axios.get(urls.VACANCY.stringify(), {
    params: {
        ...getVacanciesParams(payload),
        relations: ['cities', 'specialization', 'vacancyUnit', 'formPreset', 'landings'],
    }
});
export const getVacanciesListHandler = ({ payload }) => axios.get(urls.VACANCY.stringify(), {
    params: {
        ...getVacanciesParams(payload),
    }
});
export const postExperiumVacancyHandler = payload => axios.post(urls.EXPERIUM_VACANCY.stringify(), payload);
export const postVacancyHandler = ({ companySettings, ...data }) =>
    withCompanyThemeSettings(companySettings, axios.post(urls.VACANCY.stringify(), data));
export const putVacancyLandingsHandler = ({ id, companySettings, ...data }, landingsData) =>
    withCompanyThemeSettings(companySettings,
        axios.all([
            axios.put(urls.VACANCY.stringify({ id }), data),
            ...(!data.landingPublished && landingsData ? (
                landingsData.map(landing => putLandingHandler({
                    ...landing,
                    vacancies: (landing.vacancies || []).filter(vacancy => vacancy !== id),
                }))
            ) : [])
        ])
    );
export const putVacancyHandler = ({ id, companySettings, landings, ...data }) =>
    withCompanyThemeSettings(companySettings, axios.put(urls.VACANCY.stringify({ id }), data, landings));
export const putVacancySyncHandler = ({ id, ...data }) => axios.put(urls.VACANCY_SYNC.stringify({ id }), data);
export const getVacancyHandler = payload => axios.get(urls.VACANCY.stringify({ id: payload }), {
    params: {
        relations: ['company', 'createdBy', 'cities', 'specialization', 'specialization.parent', 'vacancyUnit', 'category', 'chartOfWork', 'classifiers', 'employeeType', 'educationLevel', 'salaryCurrency', 'domains', 'formPreset'],
    }
});
export const deleteVacancyHandler = ({ payload }) => axios.delete(urls.VACANCY.stringify({ id: payload }));
export const getPublicVacanciesHandler = ({ payload }) => axios.get(urls.PUBLIC_VACANCY.stringify(), { params: payload });

export const getApplicantsHandler = ({ payload }) => axios.get(urls.APPLICANT.stringify(), {
    params: {
        ...payload,
        relations: ['vacancy', 'vacancy.formPreset'],
        sorting: {
            field: 'createdAt',
            order: 'desc',
        },
        filter: {
            ...payload.filter,
            ...applicantsFilter(payload.filter),
            ...applicantsRejectFilter(payload.filter)
        }
    }
});
export const getApplicantHandler = ({ payload }) => axios.get(urls.APPLICANT.stringify({ id: payload }), {
    params: {
        relations: ['vacancy', 'dialog', 'dialog.bot', 'domain', 'formPreset'],
    }
});
export const deleteApplicantsHandler = payload => axios.all(payload.map(id => axios.delete(urls.APPLICANT.stringify({ id }))));
export const approveApplicantsHandler = payload => axios.put(urls.APPLICANT_APPROVE.stringify(), { ids: payload });
export const rejectApplicantsHandler = payload => axios.put(urls.APPLICANT_REJECT.stringify(), { ids: payload });

export const getBotsHandler = ({ payload }) => axios.get(urls.BOT.stringify(), { params: payload });
export const postBotHandler = payload => axios.post(urls.BOT.stringify(), payload);
export const deleteBotHandler = payload => axios.delete(urls.BOT.stringify({ id: payload }));
export const putBotHandler = payload => axios.put(urls.BOT.stringify({ id: payload.id }), payload);
export const putBotSubscribeHandler = payload => axios.put(urls.BOT_SUBSCRIBE.stringify({ id: payload }));
export const putBotUnsubscribeHandler = payload => axios.put(urls.BOT_UNSUBSCRIBE.stringify({ id: payload }));
export const getBotTokenHandler = ({ payload }) => axios.get(urls.BOT_TOKEN.stringify({ username: payload.botName }), {
    params: {
        relations: ['company'],
    }
});
export const getBotPublicHandler = ({ payload }) => axios.get(urls.BOT_PUBLIC.stringify(), { params: payload });

export const getDialogHandler = ({ payload: { bot, dialog } }) => axios.get(urls.DIALOG.stringify({ bot, dialog }), {
    params: {
        relations: ['bot'],
    }
});
export const getDialogsHandler = ({ payload }) => axios.get(urls.DIALOGS.stringify(), {
    params: {
        ...payload,
        relations: ['bot'],
        sorting: {
            field: 'updatedAt',
            order: 'desc',
        },
    }
});
export const getDialogMessagesHandler = ({ bot, dialog, filter, prevScrollHeight }) => axios.get(urls.DIALOG_MESSAGES.stringify({ bot, dialog }), {
    params: {
        relations: ['bot'],
        filter,
    },
    prevScrollHeight,
});
export const postChatMessageHandler = ({ payload }) => axios.post(urls.CHAT.stringify(), payload);
export const getChatMessageHandler = ({ payload }) => axios.get(urls.CHAT_MESSAGES.stringify(), { params: payload });

export const getDictionaryHandler = ({ payload }) => axios.get(urls.DICTIONARY.stringify({ type: path(['filter', 'type'], payload) }), {
    params: {
        ...payload,
        sorting: {
            field: 'title',
            order: 'asc',
        },
    },
    headers: payload.headers,
})
    .then(data => {
        if (data.items) {
            return ({ items: data.items });
        } else {
            return ({
                items: data
            });
        }
    })
;
export const getCountryDictionaryHandler = ({ payload }) => axios.get(urls.COUNTRY_DICTIONARY.stringify(), {
    params: {
        ...payload,
        sorting: {
            field: 'title',
            order: 'asc',
        },
    },
});
export const postCountryDictionaryHandler = payload => axios.post(urls.COUNTRY_DICTIONARY.stringify(), payload);
export const putCountryDictionaryHandler = payload => axios.put(urls.COUNTRY_DICTIONARY.stringify({ id: payload.id }), payload);
export const deleteCountryDictionaryHandler = payload => axios.delete(urls.COUNTRY_DICTIONARY.stringify({ id: payload }));

export const getRegionDictionaryHandler = ({ payload }) => axios.get(urls.REGION_DICTIONARY.stringify(), {
    params: {
        ...payload,
        sorting: {
            field: 'title',
            order: 'asc',
        },
    },
});
export const postRegionDictionaryHandler = payload => axios.post(urls.REGION_DICTIONARY.stringify(), payload);
export const putRegionDictionaryHandler = payload => axios.put(urls.REGION_DICTIONARY.stringify({ id: payload.id }), payload);
export const deleteRegionDictionaryHandler = payload => axios.delete(urls.REGION_DICTIONARY.stringify({ id: payload }));

export const getCityDictionaryHandler = ({ payload }) => axios.get(urls.CITY_DICTIONARY.stringify(), {
    params: {
        ...payload,
        relations: ['country'],
    },
    headers: payload.headers,
});
export const postCityDictionaryHandler = payload => axios.post(urls.CITY_DICTIONARY.stringify(), payload);
export const putCityDictionaryHandler = payload => axios.put(urls.CITY_DICTIONARY.stringify({ id: payload.id }), payload);
export const deleteCityDictionaryHandler = payload => axios.delete(urls.CITY_DICTIONARY.stringify({ id: payload }));

export const getCurrencyDictionaryHandler = ({ payload }) => axios.get(urls.DICTIONARY.stringify({ type: 'currency' }), {
    params: payload,
})

export const getMessageNodesHandler = () => axios.get(urls.MESSAGE_NODE.stringify());
export const getMessageNodeHandler = ({ params }) => axios.get(urls.MESSAGE_NODE.stringify({ id: params.id }));
export const postMessageNodeHandler = payload => axios.post(urls.MESSAGE_NODE.stringify(), payload);
export const putMessageNodeHandler = ({ id, data }) => axios.put(urls.MESSAGE_NODE.stringify({ id }), data);
export const deleteMessageNodeHandler = payload => axios.delete(urls.MESSAGE_NODE.stringify({ id: payload }));

export const getUsersAdminHandler = ({ payload }) => axios.get(urls.USERS_ADMIN.stringify(), {
    params: {
        ...payload,
        relations: ['company'],
        sorting: {
            field: 'createdAt',
            order: 'desc',
        },
    }
});
export const getUserAdminHandler = ({ payload }) => axios.get(urls.USERS_ADMIN.stringify({ id: payload }));
export const postUserAdminHandler = payload => axios.post(urls.USERS_ADMIN.stringify(), payload);
export const putUserAdminHandler = ({ id, ...data }) => axios.put(urls.USERS_ADMIN.stringify({ id }), data);
export const getUserInviteHandler = ({ id }) => axios.get(urls.USERS_INVITE.stringify({ id }));

export const getCompaniesHandler = ({ payload }) => axios.get(urls.COMPANY.stringify(), { params: payload });
export const getCompanyHandler = ({ payload }) => axios.get(urls.COMPANY.stringify({ id: payload }),  {
    params: {
        filter: payload.filter,
    }
});
export const putCompanyHandler = ({ id, ...data }) => axios.put(urls.COMPANY.stringify({ id }), data);
export const postCompanyHandler = payload => axios.post(urls.COMPANY.stringify(), payload);

export const putJuristicEntityHandler = ({ id, ...data }) => axios.put(urls.JURISTIC_ENTITY.stringify({ id }), data);
export const postJuristicEntityHandler = payload => axios.post(urls.JURISTIC_ENTITY.stringify(), payload);
export const deleteJuristicEntityHandler = (id) => axios.delete(urls.JURISTIC_ENTITY.stringify({ id }));

export const getLandingsHandler = ({ payload }) => axios.get(urls.LANDING.stringify(), {
    params: {
        ...payload,
        relations: ['vacancies', 'createdBy'],
        sorting: {
            field: 'createdAt',
            order: 'desc',
        },
    }
});
export const postLandingHandler = payload => axios.post(urls.LANDING.stringify(), payload);
export const putLandingHandler = ({ id, ...data }) => axios.put(urls.LANDING.stringify({ id }), data, {
    params: {
        relations: ['vacancies']
    }
});
export const deleteLandingHandler = payload => axios.delete(urls.LANDING.stringify({ id: payload }));
export const getPdaHandler = ({ payload }) => axios.get(urls.PDA.stringify({ id: payload }));

export const postFileHandler = ({ payload, params: { token } }) => axios.post(urls.POST_FILE.stringify({ token }), payload);
export const postFileWithoutAuthHandler = (payload, userId) => axios.post(urls.POST_FILE_WITHOUT_AUTH.stringify({ userId }), payload);

export const getQuestionsPresetHandler = ({ payload }) => axios.get(urls.QUESTIONS_PRESET.stringify(), {
    params: payload
});
export const postQuestionPresetHandler = payload => axios.post(urls.QUESTIONS_PRESET.stringify(), payload);
export const putQuestionPresetHandler = ({ id, ...data }) => axios.put(urls.QUESTIONS_PRESET.stringify({ id }), data);
export const deleteQuestionPresetHandler = payload => axios.delete(urls.QUESTIONS_PRESET.stringify({ id: payload }));

export const getQuestionsHandler = () => axios.get(urls.QUESTION.stringify());
export const getQuestionHandler = ({ payload }) => axios.get(urls.QUESTION.stringify({ type: payload }));

export const getSpecializationsHandler = () => axios.get(urls.SPECIALIZATIONS.stringify());

export const getVacancyUnitsHandler = ({ payload }) => axios.get(urls.VACANCY_UNIT.stringify(), { params: payload });
export const postVacancyUnitHandler = payload => axios.post(urls.VACANCY_UNIT.stringify(), payload);
export const putVacancyUnitHandler = ({ id, companySettings, ...data }) =>
    withCompanyThemeSettings(companySettings, axios.put(urls.VACANCY_UNIT.stringify({ id }), data));

export const getCompanySettingsHandler = () => axios.get(urls.COMPANY_SETTINGS.stringify());
export const putCompanySettingsHandler = payload => axios.put(urls.COMPANY_SETTINGS.stringify(), payload);
export const putCompanyPasswordResetHandler = payload => axios.put(urls.COMPANY_PASSWORD_RESET.stringify(payload), {});
export const putLettersSettingsHandler = payload => axios.put(urls.LETTERS_SETTINGS.stringify(), payload);

export const getBannersHandler = ({ payload }) => axios.get(urls.BANNER.stringify(), {
    params: {
        ...payload,
        relations: ['vacancy', 'vacancyUnit']
    }
});
export const getBannerHandler = ({ payload }) => axios.get(urls.BANNER.stringify({ id: payload }));
export const postBannerHandler = payload => axios.post(urls.BANNER.stringify(), payload);
export const putBannerHandler = ({ id, ...data }) => axios.put(urls.BANNER.stringify({ id }), data);

export const patchAdminDialogEnableHandler = ({ bot, dialog }) => axios.patch(urls.ADMIN_DIALOG_ENABLE.stringify({ bot, dialog }));
export const patchAdminDialogDisableHandler = ({ bot, dialog }) => axios.patch(urls.ADMIN_DIALOG_DISABLE.stringify({ bot, dialog }));
export const postAdminDialogHandler = ({ bot, dialog, ...data }) => axios.post(urls.ADMIN_DIALOG.stringify({ bot, dialog }), data);

export const getVacancyCategoriesHandler = ({ payload }) => axios.get(urls.VACANCY_CATEGORIES.stringify(), { params: payload });
export const getVacancyChartsHandler = ({ payload }) => axios.get(urls.VACANCY_CHARTS.stringify(), { params: payload }).then(data => ({ items: data }));

export const getCallRequestsHandler = ({ payload }) => axios.get(urls.CALL_REQUEST.stringify(), {
    params: {
        ...payload,
        relations: ['vacancy', 'company']
    },
    headers: payload.headers,
});

export const getSmsLogsHandler = ({ payload }) => axios.get(urls.SMS.stringify(), {
    params: {
        ...payload,
        relations: ['phone', 'vacancy'],
        sorting: {
            field: 'createdAt',
            order: 'desc',
        }
    }
});

export const getCoordinatesHandler = ({ geocode, city }) => geocode(city, { results: 1 });

export const getVacancyFormPresetsHandler = ({ payload }) => axios.get(urls.VACANCY_FORM_PRESET.stringify(), {
    params: {
        ...payload,
        relations: ['domains'],
    }
});
export const getVacancyFormPresetHandler = ({ payload }) => axios.get(urls.VACANCY_FORM_PRESET.stringify({ id: payload }));
export const postVacancyFormPresetHandler = payload => axios.post(urls.VACANCY_FORM_PRESET.stringify(), payload);
export const putVacancyFormPresetHandler = payload => axios.put(urls.VACANCY_FORM_PRESET.stringify({ id: payload.id }), payload);
export const deleteVacancyFormPresetHandler = payload => axios.delete(urls.VACANCY_FORM_PRESET.stringify({ id: payload }));

export const getClassifiersHandler = ({ payload }) => axios.get(urls.CLASSIFIERS.stringify({ id: payload ? payload.id : undefined }), {
    params: {
        relations: ['options'],
    }
});
export const postClassifierHandler = payload => axios.post(urls.CLASSIFIERS.stringify(), payload);
export const putClassifierHandler = payload => axios.put(urls.CLASSIFIERS.stringify({ id: payload.id}), payload);
export const deleteClassifierHandler = payload => axios.delete(urls.CLASSIFIERS.stringify({ id: payload}));

export const getClassifierOptionsHandler = ({ payload }) => axios.get(urls.CLASSIFIERS_OPTIONS.stringify({ classifier: payload.classifier }));

export const postClassifierOptionHandler = payload => axios.post(urls.CLASSIFIERS_OPTIONS.stringify({ classifier: payload.classifier }), payload);

export const putClassifierOptionHandler = payload => axios.put(urls.CLASSIFIERS_OPTIONS.stringify({
    classifier: payload.classifier,
    optionId: payload.id,
}), payload);

export const deleteClassifierOptionHandler = payload => axios.delete(urls.CLASSIFIERS_OPTIONS.stringify({
    classifier: payload.classifier,
    optionId: payload.optionId,
}));

export const getDomainHandler = ({ payload }) => axios.get(urls.DOMAIN.stringify({ id: undefined }), {
    params: {
        ...payload,
        relations: ['vacancyFormPreset', 'domainFormPreset']
    }
});
export const postDomainHandler = payload => axios.post(urls.DOMAIN.stringify(), payload);
export const putDomainHandler = payload => axios.put(urls.DOMAIN.stringify({ id: payload.id}), payload);
export const deleteDomainHandler = payload => axios.delete(urls.DOMAIN.stringify({ id: payload}));

export const postCompanyDictionaryFileHandler = ({ file }) => axios.post(urls.IMPORT_COMPANY_DICTIONARY.stringify(), file);
export const postUserImportFileHandler = ({ file, company }) => axios.post(urls.IMPORT_USER.stringify({ company }), file);

export const getCompanyDictionaryHandler = ({ payload }) => axios.get(urls.COMPANY_DICTIONARY.stringify({ id: payload ? payload.id : undefined }), {
    params: {
        ...payload,
        relations: ['parent']
    }
});
export const postCompanyDictionaryHandler = payload => axios.post(urls.COMPANY_DICTIONARY.stringify(), payload);
export const putCompanyDictionaryHandler = payload => axios.put(urls.COMPANY_DICTIONARY.stringify({ id: payload.id}), payload);
export const deleteCompanyDictionaryHandler = payload => axios.delete(urls.COMPANY_DICTIONARY.stringify({ id: payload}));

export const getCompanyDictionaryItemHandler = ({ payload }) => axios.get(urls.COMPANY_DICTIONARY_ITEM.stringify(), {
    params: {
        ...payload,
        relations: ['parent']
    }
});
export const postCompanyDictionaryItemHandler = payload => axios.post(urls.COMPANY_DICTIONARY_ITEM.stringify(), payload);
export const putCompanyDictionaryItemHandler = payload => axios.put(urls.COMPANY_DICTIONARY_ITEM.stringify({ id: payload.id}), payload);
export const deleteCompanyDictionaryItemHandler = payload => axios.delete(urls.COMPANY_DICTIONARY_ITEM.stringify({ id: payload}));

export const getCompanyDictionaryParentItemsHandler = payload => axios.get(urls.COMPANY_DICTIONARY.stringify({ id: payload.dictionaryId }), {
    params: {
        filter: {
            company: payload.companyId,
        },
        relations: ['parent']
    }
}).then(data => {
    const parentDictionaryId = path(['_relations', 'parent', 'id'], data);

    if (parentDictionaryId) {
        return getCompanyDictionaryItemHandler({ payload: { filter: { dictionary: parentDictionaryId } } });
    }
});

export const getUserAdminStatusHandler = ({ payload }) => axios.get(urls.USER_ADMIN_STATUS.stringify(), { params: payload });
export const putUserAdminSettingsHandler = payload => axios.put(urls.USER_ADMIN_SETTINGS.stringify(), payload);

export const getLoginAttemptHandler = ({ payload }) => axios.get(urls.LOGIN_ATTEMPT.stringify(), { params: payload });

export const postDictionaryHandler = payload => axios.post(urls.DICTIONARY.stringify(), payload);
export const putDictionaryHandler = payload => axios.put(urls.DICTIONARY.stringify({ type: payload.id }), payload);
export const deleteDictionaryHandler = payload => axios.delete(urls.DICTIONARY.stringify({ type: payload }));

export const putPdaSettingsHandler = payload => axios.put(urls.PDA_SETTINGS.stringify(), payload);

export const postCompanyLicenseHandler = payload => axios.post(urls.COMPANY_LICENSE_ITEM.stringify({ id: payload.id }), payload);
