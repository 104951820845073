import React from 'react';
import { deleteCountryDictionaryHandler, postCountryDictionaryHandler, putCountryDictionaryHandler } from '../../../../actions/handlers';

import { COUNTRY_DICTIONARY } from '../../../../constants/urls';
import DictionaryTable from './DictionaryTable';

const RegionDictionary = () => {
    return <DictionaryTable
        actionUrl={COUNTRY_DICTIONARY.stringify()}
        putAction={putCountryDictionaryHandler}
        postAction={postCountryDictionaryHandler}
        deleteAction={deleteCountryDictionaryHandler}
        priorityField />;
}

export default RegionDictionary;
