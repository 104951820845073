import React from 'react';
import { Field } from 'react-final-form';
import { Col } from 'antd';
import { withTranslation } from 'react-i18next';

import BaseFilter from './base/BaseFilter';
import withFilterForm from './base/withFilterForm';
import { enabledState } from '../../constants/vacancy';
import Select from '../forms/formComponents/Select';
import withUserCompany from '../hocs/withUserCompany';
import { CITY_DICTIONARY, COUNTRY_DICTIONARY, DICTIONARY, DOMAIN, USERS_ADMIN, VACANCY, VACANCY_FORM_PRESET, VACANCY_UNIT } from '../../constants/urls';
import { getFullName } from '../../utils/fieldsHelpers';

export default withTranslation()(withUserCompany(withFilterForm(({ staticFilter, form, t, isFunctionalLicense, isAdmin }) => (
    <BaseFilter
        actionUrl={VACANCY.stringify()}
        searchFieldName='title'
        namePath='title'
        searchFieldPlaceholder={t('vacancy.form.name')}
        span={8}
        sorting={{
            field: 'createdAt',
            order: 'desc',
        }}
    >
        <Col className='text-left' span={8}>
            <Field
                name='published'
                component={Select}
                options={enabledState}
                namePath='value'
            />
        </Col>
        <Col span={8}>
            <Field
                name='chartOfWork'
                placeholder={t('vacancy.form.chartOfWork')}
                component={Select}
                actionUrl={DICTIONARY.stringify({ type: 'PrjChartOfWork' })}
                searchable={true}
                allowClear
                valuePath='id'
                namePath='name'
                searchKey='name'
            />
        </Col>
        <Col span={12}>
            <Field
                name='country'
                placeholder={t('settings.country')}
                component={Select}
                actionUrl={COUNTRY_DICTIONARY.stringify()}
                searchable={true}
                allowClear
                valuePath='id'
                namePath='name'
                searchKey='name'
            />
        </Col>
        <Col span={12}>
            <Field
                name='cities'
                placeholder={t('vacancy.form.city')}
                component={Select}
                actionUrl={CITY_DICTIONARY.stringify()}
                searchable={true}
                isMulti
                allowClear
                valuePath='id'
                namePath='name'
                searchKey='name'
            />
        </Col>
        { !staticFilter.vacancyUnit && !(isFunctionalLicense && !isAdmin) &&
            <Col span={12}>
                <Field
                    name='vacancyUnit'
                    placeholder={t('vacancy.form.unit')}
                    component={Select}
                    actionUrl={VACANCY_UNIT.stringify()}
                    namePath='title'
                    smallPath='address'
                    allowClear
                />
            </Col>
        }
        <Col span={isFunctionalLicense && !isAdmin ? 8 : 12}>
            <Field
                name='domain'
                placeholder={t('vacancy.form.domains')}
                component={Select}
                actionUrl={DOMAIN.stringify({ id: undefined })}
                namePath='title'
                onChange={() => form.batch(() => {
                    form.change('formPreset', undefined);
                })}
                searchable
                allowClear
            />
        </Col>
        <Col span={isFunctionalLicense && !isAdmin ? 8 : 12}>
            <Field
                name='formPreset'
                placeholder={t('vacancy.form.questionnaire')}
                component={Select}
                actionUrl={VACANCY_FORM_PRESET.stringify()}
                namePath='title'
                searchKey='title'
                filterBy={['domain']}
                searchable
                allowClear
            />
        </Col>
        <Col span={isFunctionalLicense && !isAdmin ? 8 : 12}>
            <Field
                name='responsibleBy'
                placeholder={t('vacancy.detail.responsible')}
                component={Select}
                actionUrl={USERS_ADMIN.stringify()}
                searchable
                isMulti
                allowClear
                getNamePath={getFullName}
                searchKey='name'
                sorting={{
                    field: 'lastName',
                    order: 'asc',
                }} />
        </Col>
    </BaseFilter>
))));
