import React, { useEffect, useRef, useState } from 'react';
import { Editor, EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

const getText = html => html.replace(/&nbsp;/g, ' ').replace(/<[^]*?>/g, '');

const InlineEditor = props => {
    const editor = useRef(null);
    const [editorState, setEditorState] = useState(
        props.input.value ?
                EditorState.createWithContent(stateFromHTML(props.input.value)) :
                EditorState.createEmpty()
    );
    const value = props.input.value || '';
    const isHtml = /<\/?[a-z][\s\S]*>/i.test(value);
    const editorValue = isHtml ? stateFromHTML(value) : getText(stateToHTML(editorState.getCurrentContent()));

    useEffect(() => editor.current.focus(), []);

    useEffect(() => {
        if (value !== editorValue) {
            const state = ContentState.createFromText(value);

            setEditorState(
                value ?
                    EditorState.createWithContent(state) :
                    EditorState.createEmpty()
            );
        }
    }, [value, editorValue]);

    const onChange = editorStateValue => {
        const value = getText(stateToHTML(editorStateValue.getCurrentContent()));

        setEditorState(editorStateValue);
        props.input.onChange(value);
    }

    const handleKeyCommand = command => {
        const { disableSplitBlock, onEnter } = props;
        if (command === 'split-block' && disableSplitBlock) {
            onEnter && onEnter();
            return 'handled';
        }

        return 'not-handled';
    }

    const { readOnly, textAlignment, placeholder } = props;

    return <Editor
        ref={editor}
        editorState={editorState}
        onChange={onChange}
        handleKeyCommand={handleKeyCommand}
        readOnly={readOnly}
        textAlignment={textAlignment}
        placeholder={placeholder} />;
}

export default InlineEditor;
