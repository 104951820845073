import React, { useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { contains, path } from 'ramda';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Spin, Tabs } from 'antd';
import { Route } from 'react-router';

import { USERS_ADMIN } from '../../../constants/urls';
import ActionsContext from '../../../contexts/ActionsContext';
import ModalsContext from '../../../contexts/ModalsContext';
import ErrorHandler from '../../hocs/withErrorHandler';
import { OPEN_USER_ADMIN_MODAL } from '../../../constants/actionTypes';
import { ADMIN_COMPANY, ADMIN } from '../../../constants/roles';
import withUserCompany from '../../hocs/withUserCompany';
import UserInfo from './UserInfo';
import { getFullName } from '../../../utils/fieldsHelpers';
import UserLog from './UserLog';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const getId = path(['match', 'params', 'id']);

const UserAdmin = props => {
    const { t } = useTranslation();
    const actionUrl = USERS_ADMIN.stringify({ id: props.match.params.id });
    const user = useCompanyCodeSwr([actionUrl, getId(props)], url => axios.get(url, {
        params: {
            relations: ['companies', 'company', 'juristicEntity']
        }
    }), { revalidateOnMount: getId(props) });
    const didMount = useRef(null);
    const { addAction, removeAction } = useContext(ActionsContext);
    const modals = useContext(ModalsContext);

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            addAction(actionUrl, user);
        }
    });

    useEffect(() => {
        return () => removeAction(actionUrl);
    }, []);

    const onChange = key => {
        props.history.replace(`/user/${getId(props)}${key ? `/${key}` : ''}`);
    };

    const data = user.data || {};

    return <ErrorHandler action={user} key={getId(props)}>
        <div className='toolbar vacancy-toolbar'>
            <Button shape='circle' icon={<ArrowLeftOutlined />} onClick={props.history.goBack} />
            <h1 className='vacancy-block-title'>
                <span className='vacancy-title'>{ getFullName(data) }</span>
                <Spin spinning={user.isValidating} />
            </h1>
            { !!user.data &&
                <div className='toolbar-actions'>
                    <Button.Group>
                        <Button type='primary' className='edit-btn' onClick={() => modals.open(OPEN_USER_ADMIN_MODAL, {
                            user: data,
                            isFullLicense: props.isFullLicense || props.isAdmin,
                            hasCompany: !contains(ADMIN, data.roles || []) && !contains(ADMIN_COMPANY, data.roles || [])
                        })}>
                            <EditOutlined /> { t('settings.edit') }
                        </Button>
                    </Button.Group>
                </div>
            }
        </div>
        { user.isValidating && !user.data ?
            <Spin className='central-spin' /> :
            <div className='vacancy-edit-block'>
                <Tabs
                    className='user-tabs'
                    animated={{ tabPane: false }}
                    activeKey={props.match.params.tab || ''}
                    onChange={onChange}
                >
                    <Tabs.TabPane tab={t('vacancy.form.info')} key=''>
                        <Route path={`/user/${getId(props)}`} render={props => <UserInfo {...props} user={data} />} />
                    </Tabs.TabPane>
                    { (props.companyCode || props.isManager) && <Tabs.TabPane tab={t('userAdmin.lastChanges')} key='log'>
                        <Route path={`/user/${getId(props)}/log`} render={props => <UserLog {...props} user={data} />} />
                    </Tabs.TabPane>}
                </Tabs>
            </div>
        }
    </ErrorHandler>;
}

export default withUserCompany(UserAdmin);
