import React, { Fragment, useContext, useState } from 'react';
import { Button, Steps, message } from 'antd';
import styled from 'styled-components';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import CompanyForm from '../../forms/CompanyForm';
import CompanyManagerForm from '../../forms/CompanyManagerForm';
import JuristicEntityForm from '../../forms/JuristicEntityForm';
import { postCompanyHandler, postUserAdminHandler, postJuristicEntityHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { COMPANY, USERS_ADMIN, JURISTIC_ENTITY } from '../../../constants/urls';

const FormWrapper = styled.div`
    margin-top: 15px;
`;

const CompanyCreate = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const [step, setStep] = useState(0);
    const [postCompanyData, setPostCompanyData] = useState({});
    const { history } = props;

    const getSteps = () => {
        return [
            { title: t('company.company'), Form: CompanyForm, action: postCompanyHandler, onSuccess: (_, data) => {
                revalidateAction(COMPANY.stringify());
                message.success(t('company.addSuccess'));
                setStep(1);
                setPostCompanyData(data);
            }, onError: () => message.error(t('company.addError')) },
            { title: t('company.juristicEntity'), Form: JuristicEntityForm, action: postJuristicEntityHandler, onSuccess: () => {
                revalidateAction(JURISTIC_ENTITY.stringify());
                message.success(t('company.entitySuccess'));
                setStep(2);
            }, onError: () => message.error(t('company.entityError')) },
            { title: t('company.manager'), Form: CompanyManagerForm, action: postUserAdminHandler, onSuccess: () => {
                revalidateAction(USERS_ADMIN.stringify());
                message.success(t('company.managerAddSuccess'));
                props.history.push('/companies');
            }, onError: () => message.error(t('company.managerAddError')) },
        ];
    }

    const steps = getSteps();
    const current = steps[step];

    const renderForm = ({ Form, action, onSuccess, onError }) => {
        return <FormWrapper>
            <Form
                formAction={action}
                company={postCompanyData.id}
                onSuccess={onSuccess}
                onSubmitFail={onError} />
        </FormWrapper>;
    }

    return <Fragment>
        <div className='toolbar company-add-toolbar'>
            <Button shape='circle' icon={<ArrowLeftOutlined />} onClick={history.goBack} />
            <h1>{ t('company.createMaster') }</h1>
        </div>
        <div className='company-add-block'>
            <Steps current={step}>
                { steps.map((step, index) =>
                    <Steps.Step key={`step-${index}`} title={step.title} />
                )}
            </Steps>
            { renderForm(current) }
        </div>
    </Fragment>;
}

export default CompanyCreate;
