import React, { Fragment, useContext } from 'react';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import { path, without, filter } from 'ramda';
import { EditOutlined, MinusOutlined, DeleteOutlined, LinkOutlined, PlusOutlined, SnippetsOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import TableList from '../../table/TableList';
import EnabledSwitch from '../../table/EnabledSwitch';
import DateCell from '../../table/tableCells/DateCell';
import SocialShare from '../vacancy/SocialShare';
import LandingFilter from '../../filters/LandingFilter';
import TableStateList from '../../table/TableStateList';
import { getFullName } from '../../../utils/fieldsHelpers';
import withUserCompany from '../../hocs/withUserCompany';
import { deleteLandingHandler, putLandingHandler } from '../../../actions/handlers';
import { LANDING } from '../../../constants/urls';
import ActionsContext from '../../../contexts/ActionsContext';
import { CHANGE_LANDING, OPEN_LANDING_BUILDER_MODAL, OPEN_LANDING_CODE_MODAL, OPEN_LANDING_LINKED_VACANCIES_MODAL, OPEN_LANDING_LINK_MODAL, OPEN_UTM_PARAMS_MODAL } from '../../../constants/actionTypes';
import ModalsContext from '../../../contexts/ModalsContext';

const Landings = props => {
    const { t } = useTranslation();
    const { revalidateAction, onSuccessFn } = useContext(ActionsContext);
    const actionUrl = LANDING.stringify();
    const modals = useContext(ModalsContext);

    const openLandingModal = landing => {
        const { vacancy, pda } = props;

        modals.open(OPEN_LANDING_BUILDER_MODAL, { item: landing || { vacancy }, pda });
    }

    const remove = id => {
        deleteLandingHandler(id)
            .then(() => {
                revalidateAction(actionUrl);
                onSuccessFn(CHANGE_LANDING);
                message.success(t('landing.landingDeleteSuccess'));
            }).catch(() => t('landing.landingDeleteError'));
    }

    const unlink = landing => {
        const { vacancy } = props;

        putLandingHandler({
            ...landing,
            vacancies: without([vacancy], landing.vacancies)
        }).then(() => {
            revalidateAction(actionUrl);
            onSuccessFn(CHANGE_LANDING);
        });
    }

    const getColumns = () => {
        const { vacancyTitle, pda, vacancy, staticList } = props;

        return filter(({ hide }) => !hide, [
            {
                title: t('landing.code'),
                dataIndex: 'code',
                key: 'code',
                render: (code, item) =>
                    item.experium ?
                        code :
                        <span className='span-link' onClick={() => modals.open(OPEN_LANDING_CODE_MODAL, { item, pda })}>
                            { code }
                        </span>
            },
            {
                title: t('landing.landing'),
                key: 'url',
                dataIndex: 'url',
                render: (url, { vacancies }) => !(vacancies || []).length ?
                    <Tooltip title={t('landing.noLinkedVacancies')}>
                        <span style={{ color: 'rgba(0, 0, 0, 0.25)', cursor: 'not-allowed' }}>{ t('landing.open') }</span>
                    </Tooltip> :
                    <a href={url} target='_blank' rel='noopener noreferrer'>{ t('landing.open') }</a>
            },
            {
                title: t('landing.linkedVacancies'),
                key: 'vacancies',
                dataIndex: ['_relations', 'vacancies'],
                align: 'center',
                render: (vacancies, landing) => (
                    <span className='span-link linked-vacancies' onClick={() => modals.open(OPEN_LANDING_LINKED_VACANCIES_MODAL, { vacancies, landing })}>{ t('landing.open') }</span>
                ),
                hide: !!vacancy
            },
            {
                title: t('landing.published'),
                key: 'published',
                render: item => <EnabledSwitch item={item} action={putLandingHandler} disabled={item.experium || props.view} revalidateAction={actionUrl} />
            },
            {
                title: t('landing.author'),
                key: 'createdBy',
                dataIndex: ['_relations', 'createdBy'],
                render: getFullName
            },
            {
                title: t('landing.createdAt'),
                key: 'createdAt',
                dataIndex: 'createdAt',
                render: date => <DateCell date={date} />
            },
            {
                key: 'controls',
                width: 150,
                align: 'center',
                render: item =>
                    item.experium ?
                        null :
                        <Button.Group>
                            <Tooltip title={t('landing.utmGenerator')}>
                                <Button
                                    className='utm-btn'
                                    icon={<SnippetsOutlined />}
                                    onClick={() => modals.open(OPEN_UTM_PARAMS_MODAL, { url: item.url, vacancyTitle })} />
                            </Tooltip>
                            <Button className='edit-btn' onClick={() => openLandingModal(item)} icon={<EditOutlined />} />
                            { !props.view &&
                                <Fragment>
                                    { props.vacancy &&
                                        <Popconfirm
                                            title={t('landing.unlinkConfirm')}
                                            placement='topRight'
                                            onConfirm={() => unlink(item)}
                                            okText={t('landing.yes')}
                                            cancelText={t('landing.no')}>
                                            <Button icon={<MinusOutlined />} />
                                        </Popconfirm>
                                    }
                                    { (!staticList || path(['vacancies', 'length'], item) > 1) && (
                                        <Popconfirm
                                            onConfirm={() => remove(item.id)}
                                            placement='topRight'
                                            title={t('landing.deleteUnlink')}
                                            okText={t('landing.yes')}
                                            cancelText={t('landing.no')}>
                                            <Button type='danger' icon={<DeleteOutlined />} />
                                        </Popconfirm>
                                    )}
                                </Fragment>
                            }
                        </Button.Group>
            },
            {
                title: t('landing.share'),
                dataIndex: 'url',
                key: 'social',
                className: 'social',
                width: 110,
                render: url => <SocialShare url={url} vacancyTitle={vacancyTitle} />
            },
        ]);
    };

    const getButtons = () => {
        const { vacancy } = props;

        return <Fragment>
            { vacancy &&
                <Button
                    id="landing-link"
                    className='toolbar-button'
                    icon={<LinkOutlined />}
                    onClick={() => modals.open(OPEN_LANDING_LINK_MODAL, vacancy)}>
                    { t('landing.link') }
                </Button>
            }
            <Button
                className='toolbar-button'
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => openLandingModal({ vacancies: vacancy ? [vacancy] : null })}>
                { t('landing.add') }
            </Button>
        </Fragment>;
    }

    const getTitle = () => {
        const { title } = props;

        return title;
    }

    const { vacancy, view, staticList, isAdmin, userId, hideToolbar } = props;
    const Table = staticList ? TableStateList : TableList;

    return <Table
        param={{ type: 'edit' }}
        actionUrl={actionUrl}
        initFilters={{ vacancy, createdBy: !isAdmin ? userId : null }}
        relations={['createdBy', !vacancy && 'vacancies']}
        sorting={{
            field: 'createdAt',
            order: 'desc',
        }}
        filterForm={hideToolbar ? null : LandingFilter}
        columns={getColumns()}
        buttons={!view && !hideToolbar ? getButtons() : null}
        title={getTitle()}
    />;
}

export default withUserCompany(Landings);
