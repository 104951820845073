import React, { Fragment } from 'react';
import { prop } from 'ramda';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { useTranslation, withTranslation } from 'react-i18next';

import { URL_COMPANY_CODE } from '../../constants/regexps';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';
import { transformCompanyCodeValue } from '../../utils/companyCode';
import LicenseRadio from './formComponents/LicenseRadio';

const CompanyForm = props => {
    const { t } = useTranslation();
    const { item } = props;

    return <Fragment>
        <Field
            name='name'
            component={Input}
            label={t('company.name')}
            required
        />
        <Field
            name='code'
            component={Input}
            label={t('company.code')}
            parse={transformCompanyCodeValue}
            required
        />
        { !item &&
            <Fragment>
                <div className="ant-col ant-form-item-label"><label title={t('company.license')}>{ t('company.license') }</label></div>
                <Field
                    name='license'
                    component={LicenseRadio}
                    create />
            </Fragment>
        }
        <div className='modal-form-toolbar'>
            <SubmitButton>
                { item ? t('form.save') : t('company.add') }
            </SubmitButton>
        </div>
    </Fragment>;
}

const validationSchema = props => yup.object().shape({
    name: yup.string().required(),
    code: yup.string().code(URL_COMPANY_CODE).required(),
    license: !props.item ? yup.string().required() : yup.string().nullable()
});

export default withTranslation()(withFormWrapper(CompanyForm, {
    mapPropsToValues: prop('item'),
    validationSchema
}));
