import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { filter, fromPairs, pathOr, toPairs } from 'ramda';

import withFormWrapper from '../hocs/withFormWrapper';
import { TranslationFields } from './ClassifiersForm';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';
import Select from './formComponents/Select';
import ListenerField from './ListenerField';
import InputNumber from './formComponents/InputNumber';
import { COUNTRY_DICTIONARY, REGION_DICTIONARY } from '../../constants/urls';
import withUserCompany from '../hocs/withUserCompany';

const DictionaryItemForm = props => {
    const { t } = useTranslation();

    return <Fragment>
        { props.countryField &&
            <Field
                name='country'
                label={t('settings.country')}
                component={Select}
                actionUrl={COUNTRY_DICTIONARY.stringify()}
                searchable={true}
                valuePath='id'
                namePath='name'
                searchKey='name'
            />
        }
        <ListenerField listenFieldName='country'>
            { ({ country }) =>
                props.regionField && country &&
                    <Field
                        name='region'
                        label={t('settings.region')}
                        component={Select}
                        filter={{ country }}
                        actionUrl={REGION_DICTIONARY.stringify()}
                        searchable={true}
                        valuePath='id'
                        namePath='name'
                        searchKey='name'
                    />
            }
        </ListenerField>
        { props.priorityField &&
            <Field
                name='priority'
                component={InputNumber}
                label={t('settings.priority')} />
        }
        <Field
            name={'name'}
            component={Input}
            label={t('settings.name')}
            addonAfter={<div style={{ width: 48 }}>RU</div>}
        />
        <FieldArray name='translations'>
            { ({ fields }) => <TranslationFields fields={fields} form={props.form} /> }
        </FieldArray>
        <SubmitButton>
            { t('form.save') }
        </SubmitButton>
    </Fragment>
}

const validationSchema = props => yup.object().shape({
    name: yup.string().required(),
    country: props.countryField ? yup.string().required() : yup.string().nullable(),
    region: yup.string().nullable(),
    priority: props.priorityField ? yup.number().required() : yup.number().nullable()
});

export default withUserCompany(withFormWrapper(DictionaryItemForm, {
    validationSchema,
    mapPropsToValues: ({ item, dictionary, isDictionary, regionField, companyId }) => ({
        company: companyId,
        region: regionField ? '' : undefined,
        ...(item || {}),
        translations: filter(
            ({ lang }) => lang !== 'ru',
            toPairs(
                pathOr({}, isDictionary ? ['translations', 'name'] : ['translations'], item))
                    .map(([lang, name]) => ({ name, lang })
            )
        ),
        dictionary
    }),
    mapBeforeSubmit: (values, props) => ({
        ...values,
        translations: props.isDictionary ? {
            name: fromPairs((values.translations || []).map(({ name, lang }) => [lang, name]))
        } : fromPairs((values.translations || []).map(({ name, lang }) => [lang, name]))
    })
}));
