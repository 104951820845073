import React, { Fragment, useState } from 'react';
import { Field } from 'react-final-form';
import { assoc, find, filter, path, pathOr, toPairs, fromPairs } from 'ramda';
import { Button, Row, Col } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';

import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';
import { AVAILABLE_LANGUAGES } from '../../constants/dictionary';
import withFormWrapper from '../hocs/withFormWrapper';
import withUserCompany from '../hocs/withUserCompany';
import { TranlsationItem, LanguageSelector } from './ClassifiersForm';

const TranslationQuestionFieldsComponent = props => {
    const [disableSync, setStateDisableSync] = useState({});
    const { form, fields } = props;
    const { t } = useTranslation();

    const syncQuestionChange = (field, syncField, values) => (value, prevValue) => {
        if (!path([field], disableSync) && (!values[syncField] || values[syncField] === prevValue)) {
            form.change(field, value || undefined);
        }
    };
    const disableSyncChange = field => () => {
        setStateDisableSync({ disableSync: assoc(field, true, disableSync) });
    };

    return <div>
        { fields.map((name, index) =>
            <Row gutter={5} key={name}>
                <Col span={11}>
                    <Field
                        name={`${name}.label`}
                        component={Input}
                        onChange={syncQuestionChange(`${name}.question`, 'question', fields.value[index])}
                    />
                </Col>
                { !(props.isFunctionalLicense && !props.isAdmin) && <Col span={10}>
                    <Field
                        name={`${name}.question`}
                        component={Input}
                        onChange={disableSyncChange(`${name}.question`)}
                    />
                </Col>}
                <Col span={3}>
                    <TranlsationItem>
                        <LanguageSelector
                            width='100%'
                            name={name}
                            value={fields.value[index].lang}
                            onChange={lang => form.change(`${name}.lang`, lang)}
                            languages={filter(lng => !find(v => v.lang === lng, fields.name || []) || lng === fields.value[index].lang, AVAILABLE_LANGUAGES)}
                        />
                        <Button
                            danger
                            style={{ marginLeft: 10 }}
                            icon={<DeleteOutlined />}
                            onClick={() => fields.remove(index)} />
                    </TranlsationItem>
                </Col>
            </Row>
        )}
        { (fields.value || []).length < AVAILABLE_LANGUAGES.length &&
            <Button
                id='add-translation-btn'
                type='link'
                icon={<PlusOutlined />}
                style={{ paddingLeft: 0, paddingRight: 0 }}
                onClick={() => {
                    const lang = filter(lng => !find(v => v.lang === lng, fields.value || []), AVAILABLE_LANGUAGES)[0];
                    fields.push({ lang });
                }}>
                { t('settings.addTranslation') }
            </Button>
        }
    </div>;
}

export const TranslationQuestionFields = withUserCompany(TranslationQuestionFieldsComponent);

const VacancyQuestionLanguageForm = props => {
    const { t } = useTranslation();

    return <Fragment>
        <Row className='question-translate-header' style={{ marginBottom: 15 }}>
            <Col span={11} className='question-translate-col'>{ t('vacancy.form.title') }:</Col>
            { !(props.isFunctionalLicense && !props.isAdmin) && <Col span={10} className='question-translate-col'>{ t('vacancy.form.question') }:</Col>}
            <Col span={3} className='question-translate-col'>{ t('settings.chooseLanguage') }:</Col>
        </Row>
        <Row gutter={5}>
            <Col span={11}>
                <Field
                    name={'label'}
                    component={Input}
                    disabled
                />
            </Col>
            { !(props.isFunctionalLicense && !props.isAdmin) && <Col span={10}>
                <Field
                    name={'question'}
                    component={Input}
                    disabled
                />
            </Col>}
            <Col span={3}>
                <TranlsationItem>
                    <LanguageSelector
                        width='100%'
                        value={'RU'}
                        languages={AVAILABLE_LANGUAGES}
                        disabled
                    />
                    <div style={{ width: 56 }} />
                </TranlsationItem>
            </Col>
        </Row>
        <div style={{ marginBottom: 15 }}>
            <FieldArray name='translations'>
                { ({ fields }) => (
                    <TranslationQuestionFields fields={fields} form={props.form} />
                )}
            </FieldArray>
        </div>
        <SubmitButton>
            { t('form.save') }
        </SubmitButton>
    </Fragment>;
}

export default withUserCompany(
    withFormWrapper(VacancyQuestionLanguageForm, {
        mapPropsToValues: ({ item = {} }) => ({
            ...item,
            translations: filter(
                ({ lang }) => lang !== 'ru',
                toPairs(pathOr({}, ['translations', 'label'], item))
                    .map(([lang, label]) => ({
                        lang,
                        label,
                        question: pathOr('', ['translations', 'question', lang], item)
                    }))
            ),
        }),
        mapBeforeSubmit: values => ({
            ...values,
            translations: {
                label: fromPairs((values.translations || []).map(({ label, lang }) => [lang, label])),
                question: fromPairs((values.translations || []).map(({ question, lang }) => [lang, question]))
            }
        })
    })
);
