import React, { useContext, useState } from 'react';
import { Alert, Modal, message } from 'antd';
import { head, path, prop, values, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import ERRORS from '../../../constants/errors';
import UserImportForm from '../../forms/UserImportForm';
import { postUserImportFileHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { USERS_ADMIN } from '../../../constants/urls';

const UserImportModal = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const [userImportFileData, setUserImportFileData] = useState({});
    const { modal, params, isAdmin, companyId, close } = props;
    const item = prop('item', params);

    return (
        <Modal
            {...modal}
            title={t('userAdmin.importUsers')}
            footer={null}
        >
            { userImportFileData.success ? (
                !!pathOr([], ['data', 'rows'], userImportFileData).length && userImportFileData.data.rows.map(item => item.errors ? (
                    <div key={item.rowNumber}>
                        <Alert
                            message={
                                <div>
                                    { t('userAdmin.userCreateError', { username: path(['data', 'username'], item), rowNumber: item.rowNumber }) }:
                                    { item.errors.map((error, index) => {
                                        const username = path(['data', 'username'], item);
                                        const errors = values(path(['response', 'message', 0, 'constraints'], error) || path(['constraints'], error)) || [];
                                        const constraint = head(errors);
                                        const message = constraint.replace(username, '$value');

                                        return (
                                            <div key={index}>
                                                - {ERRORS[message] ? t(ERRORS[message]).replace('\'$value\'', username) : message}
                                                <br />
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                            type='error'
                            className='form-alert'
                        />
                    </div>
                ) : null)
            ) : (
                <UserImportForm
                    layout='horisontal'
                    formAction={values => {
                        setUserImportFileData({});
                        return postUserImportFileHandler(values);
                    }}
                    item={item}
                    isAdmin={isAdmin}
                    companyId={companyId}
                    onSuccess={(_, data) => {
                        revalidateAction(USERS_ADMIN.stringify());
                        let fullSuccess = true;

                        data.rows && data.rows.forEach(item => {
                            if (!item.success) {
                                fullSuccess = false;
                            }
                        });

                        setUserImportFileData({
                            success: true,
                            data
                        });

                        if (fullSuccess) {
                            message.success(t('userAdmin.usersImportSuccess'));
                            close();
                        }
                    }}
                    onSubmitFail={() => message.error(t('userAdmin.usersImportError'))}
                />
            )}
        </Modal>
    );
}

export default UserImportModal;
