
import React, { useEffect, useState } from 'react';
import { AutoComplete, Modal, Button, Row, Col } from 'antd';
import { withYMaps, Map, SearchControl, Placemark } from 'react-yandex-maps';
import { path, trim } from 'ramda';
import { useTranslation } from 'react-i18next';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { parseGeoObject } from '../../../utils/geo';

const defaultLocation = [37.57, 55.75];

const MapSelect = props => {
    const { t } = useTranslation();
    const [center, setStateCenter] = useState(null);
    const [value, setValue] = useState({});

    const setCenter = location => {
        setStateCenter(location || defaultLocation);
    }

    const addMark = (event) => {
        const location = event.get('coords');

        props.ymaps.geocode(location).then(data => {
            const { address } = parseGeoObject(data);

            setValue({
                address,
                location,
            });
        }).catch(() => {
            const address = path(['address'], props.value);
            setValue({
                address,
                location,
            });
        });
    }

    const selectCurrent = () => {
        const { address, location } = value;

        props.onChange({
            address: `${address}`,
            location: location,
        });

        props.close();
    }

    useEffect(() => {
        const address = path(['address'], props.value);
        const location = path(['location'], props.value);

        setValue({
            address,
            location,
        });

        props.ymaps.geocode(location || address || props.city).then(data => {
            const geoObject = parseGeoObject(data);
            setCenter(geoObject.location);
        }).catch(() => {
            setCenter(location);
        });
    }, []);

    const { address, location } = value;

    return center ? (
        <div className='ymap'>
            <Map
                defaultState={{
                    center,
                    zoom: center === defaultLocation ? 10 : 15,
                }}
                width='100%'
                height='100%'
                onClick={addMark}
            >
                <SearchControl
                    options={{
                        noPlacemark: true,
                    }}
                    instanceRef={instance => {
                        if (instance) {
                            instance.events.add('resultselect', () => {
                                instance.getResult(instance.getSelectedIndex()).then(e => {
                                    setValue({
                                        address: `${instance.getRequestString() || address}`,
                                        location:  e.geometry._coordinates,
                                    });
                                });
                            });
                        }
                    }}
                />
                { location && <Placemark geometry={location} /> }
            </Map>
            <div className='map-footer'>
                <div>
                    { address || '-' }
                </div>
                <Button onClick={() => props.close()}>
                    { t('userAdmin.cancel') }
                </Button>
                <Button disabled={!location} type='primary' onClick={selectCurrent}>
                    { t('form.choose') }
                </Button>
            </div>
        </div>
    ) : null;
}

const AddressSelect = props => {
    const { t } = useTranslation();
    const [ymaps, setYmaps] = useState(null);
    const [mapOpen, setMapOpen] = useState(false);
    const [options, setOptions] = useState([]);

    if (props.ymaps && !ymaps) {
        setYmaps(props.ymaps);
    }

    const handleSearch = value => {
        const { city = '' } = props;

        if (ymaps) {
            ymaps.suggest(`${city} ${value}`).then(optionsValue => {
                setOptions(optionsValue);
            });
        }

        if (value) {
            const location = path(['location'], props.input.value);
            props.onChange({
                address: `${value}`,
                location,
            });
        } else {
            props.onChange(null);
        }
    }

    const onSelect = value => {
        if (value) {
            props.onChange({ address: trim(`${value}`) });
            ymaps.geocode(value, { results: 1 })
                .then(response => {
                    const geoObject = response.geoObjects.get(0);
                    const location = geoObject.geometry.getCoordinates();

                    props.onChange({
                        address: trim(`${value}`),
                        location,
                    });
                }).catch(() => {
                    const location = path(['location'], props.input.value);

                    props.onChange({
                        address: trim(`${value}`),
                        location,
                    });
                });
        } else {
            props.onChange(undefined);
        }
    }

    const close = () => {
        setMapOpen(false);
    }

    const renderOptions = options => {
        return options && options.length && (
            options.map(item => (
                <AutoComplete.Option key={item.value} value={item.value}>
                    <span>{item.value}</span>
                </AutoComplete.Option>
            ))
        );
    }

    const { input, placeholder, city, disabled } = props;
    const location = path(['location'], input.value);
    const address = path(['address'], input.value);

    return (
        <Row className='button-select' gutter={10}>
            <Col xs={18}>
                <AutoComplete
                    id={input.name}
                    key={JSON.stringify(location)}
                    value={address}
                    onSelect={onSelect}
                    onSearch={handleSearch}
                    placeholder={placeholder}
                    allowClear={true}
                    disabled={disabled}
                >
                    {renderOptions(options)}
                </AutoComplete>
            </Col>
            <Col xs={6}>
                <Button disabled={!ymaps || disabled} onClick={() => setMapOpen(true)}>
                    { t('form.selectOnMap') }
                </Button>
            </Col>
            <Modal
                visible={mapOpen}
                onCancel={close}
                width='80%'
                footer={null}
                className='map-modal'
                destroyOnClose={true}
                mask={props.mask}
            >
                <MapSelect
                    onChange={props.onChange}
                    value={props.input.value || null}
                    close={close}
                    ymaps={ymaps}
                    city={city}
                    t={t}
                />
            </Modal>
        </Row>
    );
}

AddressSelect.defaultProps = {
    mask: true,
};

export default withFieldWrapper(
    withYMaps(AddressSelect, false, ['suggest', 'geocode'])
);
