import React, { useContext } from 'react';
import { message, Modal } from 'antd';
import { path } from 'ramda';
import { useTranslation } from 'react-i18next';

import VacancyQuestionLanguageForm from '../../forms/VacancyQuestionLanguageForm';
import { DEFAULT_QUESTIONS, PRESET_QUESTIONS, QUESTION_PRESET, SUB_QUESTION } from '../../../constants/questions';
import { putQuestionPresetHandler, putVacancyFormPresetHandler, putVacancyHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { COMPANY_SETTINGS, QUESTIONS_PRESET, VACANCY, VACANCY_FORM_PRESET } from '../../../constants/urls';
import { PUT_VACANCY } from '../../../constants/actionTypes';

const VacancyQuestionLanguageModal = props => {
    const { t } = useTranslation();
    const { revalidateAction, onSuccessFn } = useContext(ActionsContext);

    const getAction = (questionsType = DEFAULT_QUESTIONS) => {
        const actions = {
            [DEFAULT_QUESTIONS]: putVacancyHandler,
            [PRESET_QUESTIONS]: putVacancyFormPresetHandler,
            [QUESTION_PRESET]: putQuestionPresetHandler,
        };

        return questionsType === SUB_QUESTION ? props.params.formAction : actions[questionsType];
    }

    const onSubmit = fieldValue => {
        const { params: { form, onChange, questionsType }, close } = props;
        const action = getAction(questionsType);
        onChange && onChange(fieldValue);

        if (params.add) {
            close();
        } else {
            if (questionsType === QUESTION_PRESET) {
                action({
                    ...fieldValue,
                }).then(() => {
                    message.success(t('settings.questionPresetTranslationSaveSuccess'));
                    revalidateAction(QUESTIONS_PRESET.stringify());
                    close();
                });
            } else {
                const { values } = form.getState();

                return action({
                    ...values,
                    address: path(['address', 'address'], values),
                    location: path(['address', 'location'], values),
                }).then(() => {
                    onSuccessFn(PUT_VACANCY);
                    revalidateAction(COMPANY_SETTINGS.stringify());
                    revalidateAction(VACANCY.stringify());
                    revalidateAction(VACANCY_FORM_PRESET.stringify());
                    revalidateAction(QUESTIONS_PRESET.stringify());
                    message.success(t('settings.questionPresetTranslationSaveSuccess'));
                    close();
                });
            }
        }
    }

    const { modal, params } = props;

    return (
        <Modal
            {...modal}
            width='calc(100% - 30px)'
            style={{ maxWidth: '1440px' }}
            title={t('vacancy.detail.questionsTranslations')}
            footer={null}
        >
            <VacancyQuestionLanguageForm
                formAction={onSubmit}
                item={params.item}
                layout='horizontal'
            />
        </Modal>
    );
}

export default VacancyQuestionLanguageModal;
