import React from 'react';
import { Card, Button } from 'antd';
import cx from 'classnames';
import { contains } from 'ramda';
import { ReloadOutlined } from '@ant-design/icons';

import avatarPlaceholder from '../../../images/male.png';
import ChatTime from './ChatTime';
import Media from './Media';
import { useTranslation } from 'react-i18next';

const hideMessages = [
    '/vacancy',
    '/start'
];

const UserRequest = props => {
    const { t } = useTranslation();
    const { withoutMenu, data: { createdAt, text, payload = {}, error }, avatar, userName, dispatchMessage } = props;
    const avatarSrc = avatar ? avatar : avatarPlaceholder;
    const hideMessage = withoutMenu && contains(text, hideMessages);

    return hideMessage ? null : (
        <div className='dialog-row-user'>
            <div className='dialog-message dialog-message-user'>
                <Card className={cx('dialog-card dialog-card-user', { error })}>
                    <div className='dialog-card-header'>
                        <div className='dialog-card-user-placeholder'>{userName || t('chat.you')}</div>
                    </div>
                    <div className='dialog-items'>
                        { payload.url ? (
                            <Media payload={payload} />
                        ) : (
                            <div className='dialog-text'>
                                {text}
                            </div>
                        )}
                    </div>
                    <div className='user-avatar'>
                        <div className='avatar-logo-wrapper'>
                            <img src={avatarSrc} alt='User avatar' />
                        </div>
                        <ChatTime timeValue={createdAt} />
                        { error && (
                            <div className='message-error'>
                                <Button
                                    className='message-error-button'
                                    icon={<ReloadOutlined />}
                                    onClick={() => dispatchMessage({ text, payload })}
                                />
                            </div>
                        )}
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default UserRequest;
