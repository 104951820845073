import React, { Fragment } from 'react';
import { pathOr, prop } from 'ramda';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { useTranslation, withTranslation } from 'react-i18next';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import LicenseRadio from './formComponents/LicenseRadio';
import useCompanyCodeSwr from '../../utils/useCompanyCodeSwr';
import { COMPANY_LICENSE } from '../../constants/urls';
import axios from 'axios';
import { Spin } from 'antd';

const CompanyLicenseForm = props => {
    const { t } = useTranslation();
    const companyLicense = props.item.license === 'demo' ? useCompanyCodeSwr(COMPANY_LICENSE.stringify(), url =>
        axios.get(url, {
            params: {
                filter: {
                    license: 'demo',
                    company: props.item.id
                },
                sorting: {
                    field: 'createdAt',
                    order: 'desc',
                },
                relations: ['createdBy']
            }
        })
    ) : null;

    return (props.item.license === 'demo' ? !companyLicense.data : false) ?
        <Spin /> :
        <Fragment>
            <div className="ant-col ant-form-item-label"><label title={t('company.license')}>{ t('company.license') }</label></div>
            <Field
                name='license'
                component={LicenseRadio}
                isDemo={props.item.license === 'demo'}
                companyLicense={pathOr([], ['data', 'items'], companyLicense)} />
            <div className='modal-form-toolbar'>
                <SubmitButton>
                    { t('form.save') }
                </SubmitButton>
            </div>
        </Fragment>;
}

const validationSchema = () => yup.object().shape({
    license: yup.string().required()
});

export default withTranslation()(withFormWrapper(CompanyLicenseForm, {
    mapPropsToValues: prop('item'),
    validationSchema
}));
