import React, { useContext, useState } from 'react';
import { message, Modal } from 'antd';
import styled from 'styled-components';
import { pathOr, assocPath, equals, path } from 'ramda';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SliderVacancyToggleForm from '../../forms/SliderVacancyToggleForm';
import BannerModalContent from './BannerModalContent';
import { getVacancyHandler, putVacancyHandler, putVacancyUnitHandler } from '../../../actions/handlers';
import { COMPANY_SETTINGS, VACANCY, VACANCY_UNIT } from '../../../constants/urls';
import ActionsContext from '../../../contexts/ActionsContext';
import axios from 'axios';
import { PUT_VACANCY } from '../../../constants/actionTypes';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

export const StyledBannerModal = styled(Modal)`
    width: 100% !important;
    max-width: 100vw !important;
    top: 0 !important;
    padding-bottom: 0 !important;

    .ant-modal-content {
        border-radius: 0;
    }

    .ant-modal-body {
        padding: 0;
    }
`;

const UnitBannerModal = props => {
    const { t } = useTranslation();
    const { params } = props;
    const [hasTemplate, setHasTemplate] = useState(false);
    const [selectedVacancy, setSelectedVacancy] = useState(null);
    const { revalidateAction, onSuccessFn } = useContext(ActionsContext);
    const [showVacancySlider, setShowVacancySlider] = useState(pathOr(false, ['banner', 'showVacancySlider'], params));
    const companySettings = useCompanyCodeSwr(COMPANY_SETTINGS.stringify());
    const [vacancy, setVacancy] = useState(null);
    const [vacancyPending, setVacancyPending] = useState(false);
    const [putVacancyPending, setPutVacancyPending] = useState(false);
    const [unit, setUnit] = useState(params);
    const vacancies = useCompanyCodeSwr([VACANCY.stringify(), params.id], (url, vacancyUnit) => axios.get(url, {
        params: {
            filter: {
                vacancyUnit,
            },
            pagination: {
                limit: 4
            },
            sorting: {
                field: 'createdAt',
                order: 'desc',
            }
        }
    }));
    let bannerForm = null;
    const history = useHistory();

    const changeHasTemplate = template => {
        if (template !== hasTemplate) {
            setHasTemplate(template);

            if (!template) {
                bannerForm = null;
            }
        }
    }

    const onChangeSelectedVacancy = vacancy => {
        if (bannerForm) {
            const values = bannerForm.props.form.getState().values;

            if (!equals(values.banner, pathOr(['banner'], vacancy)) || values.companySettings.logo !== path(['data', 'logo'], companySettings)) {
                bannerForm.props.form.submit();
            }
        }

        bannerForm = null;
        setSelectedVacancy(vacancy);

        if (vacancy) {
            setVacancyPending(true);
            getVacancyHandler(vacancy).then(data => {
                setVacancy(data);
                setVacancyPending(false);
            }).catch(() => setVacancyPending(false));
        } else {
            setVacancy(null);
        }
    }

    const toggleShowVacancySlider = show => {
        setShowVacancySlider(show);

        if (show) {
            changeHasTemplate(true);
        }
    }

    const onPutVacancyUnitSuccess = (_, data) => {
        setUnit(pathOr(params, [0], data));
        revalidateAction(VACANCY_UNIT.stringify());
        revalidateAction(VACANCY.stringify());
        message.success(t('banner.sliderSaveSuccess'));

        if (!props.showVacancySlider) {
            props.close();
        }
    }

    const { modal, close } = props;

    return <StyledBannerModal
        {...modal}
        afterClose={(...args) => {
            history.goBack();
            modal.afterClose(...args);
        }}
        title={null}
        footer={null}
        closable={false}>
        <SliderVacancyToggleForm
            onChange={onChangeSelectedVacancy}
            selectedVacancy={selectedVacancy}
            putPending={putVacancyPending}
            formAction={putVacancyUnitHandler}
            data={unit}
            showVacancySlider={showVacancySlider}
            setShowVacancySlider={toggleShowVacancySlider}
            close={close}
            pending={vacancyPending}
            hasTemplate={hasTemplate}
            changeHasTemplate={changeHasTemplate}
            onSuccess={onPutVacancyUnitSuccess}
            onError={() => message.error(t('banner.sliderSaveError'))} />
        { showVacancySlider ?
            ( vacancy && !vacancyPending ?
                <BannerModalContent
                    {...props}
                    key={selectedVacancy}
                    params={vacancy || {}}
                    formAction={putVacancyHandler}
                    onSuccess={() => {
                        onSuccessFn(PUT_VACANCY);
                        revalidateAction(VACANCY.stringify());
                        revalidateAction(COMPANY_SETTINGS.stringify());
                        if (showVacancySlider) {
                            setPutVacancyPending(true);
                            putVacancyUnitHandler(assocPath(['banner', 'showVacancySlider'], true, params))
                                .then(() => {
                                    revalidateAction(COMPANY_SETTINGS.stringify());
                                    setPutVacancyPending(false);
                                    message.success(t('banner.sliderSaveSuccess'));

                                    if (!showVacancySlider) {
                                        close();
                                    }
                                }).catch(() => {
                                    message.error(t('banner.sliderSaveError'));
                                    setPutVacancyPending(false);
                                });
                        }
                    }}
                    onSubmitFail={() => message.error(t('banner.sliderSaveError'))}
                    changeHasTemplate={changeHasTemplate}
                    formRef={node => bannerForm = node}
                    lowHeight /> : null
            ) :
            <BannerModalContent
                {...props}
                params={assocPath(['banner', 'showVacancySlider'], false, params)}
                formAction={putVacancyUnitHandler}
                onSuccess={onPutVacancyUnitSuccess}
                onError={() => message.error(t('banner.sliderSaveError'))}
                vacancies={vacancies.data}
                changeHasTemplate={changeHasTemplate}
                isUnit />
        }
    </StyledBannerModal>;
}

export default UnitBannerModal;
