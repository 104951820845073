import React from 'react';
import { Alert } from 'antd';
import { withTranslation } from 'react-i18next';

const AlreadyLogin = ({ t }) => (
    <div className='page-content'>
        <Alert
            message={t('guest.alreadyAuth')}
            type='warning'
            className='form-alert'
        />
    </div>
);

export default withTranslation()(AlreadyLogin);
