import React, { Fragment } from 'react';
import { Steps, Button } from 'antd';
import { findIndex, propEq, pathOr, keys, path } from 'ramda';
import { withState } from 'recompose';
import { useTranslation, withTranslation } from 'react-i18next';

import SubmitButton from './formComponents/SubmitButton';
import ListenerField from './ListenerField';

const BotStepsForm = props => {
    const { t } = useTranslation();

    const next = () => props.setStep(step => step + 1);

    const prev = () => props.setStep(step => step - 1);

    const getSubmitIndex = () => {
        const { steps } = props;
        const index = findIndex(propEq('submit', true), steps);

        return index > -1 ? index : steps.length - 1;
    }

    const { steps, className, close, step } = props;
    const submitIndex = getSubmitIndex();
    const stepComponent = steps[step];

    return <Fragment>
        <Steps progressDot current={step} className={className}>
            { steps.map((step, index) =>
                <Steps.Step key={`step-${index}`} />
            )}
        </Steps>
        <div className='steps-content'>
            { stepComponent && stepComponent.render() }
        </div>
        <div className='steps-action'>
            { step > 0 && step !== submitIndex + 1 &&
                <Button className='steps-action-back' onClick={() => prev()}>{ t('bot.back') }</Button>
            }
            <ListenerField listenFieldName='username'>
                { (values) => step < steps.length - 1 && step !== submitIndex && (
                    <Button
                        type='primary'
                        disabled={stepComponent.field && !values[stepComponent.field]}
                        onClick={() => next()}>
                        { t('bot.next') }
                    </Button>
                )}
            </ListenerField>
            <ListenerField listenFieldName='token'>
                { (values) => step === submitIndex && (
                    <SubmitButton
                        type='primary'
                        disabled={stepComponent.field && !values[stepComponent.field]}>
                        { t('form.save') }
                    </SubmitButton>
                )}
            </ListenerField>
            { step !== submitIndex && step === steps.length - 1 &&
                <Button onClick={close} type='primary'>{ t('bot.ready') }</Button>
            }
        </div>
    </Fragment>;
}

BotStepsForm.defaultProps = {
    steps: []
};

export const withBotSteps = FormComponent => withState('step', 'setStep', 0)(FormComponent);

export const onSuccess = ({ setStep }) => {
    setStep(step => step + 1);
};

export const onSubmitFailed = ({ steps, setStep }, error) => {
    const errorField = path([0], keys(pathOr({}, ['data', 'errors'], error)));

    if (errorField) {
        const step = findIndex(propEq('field', errorField), steps);
        step > -1 && setStep(step);
    }
}

export default withTranslation()(BotStepsForm);
