import React from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const emptyList = [];

const UploadButton = props => {
    const { t } = useTranslation();

    const beforeUpload = file => {
        const { input: { value = [], onChange }, multiple } = props;

        onChange(multiple ? [ ...value, file ] : [ file ]);

        return false;
    };

    const onRemove = file => {
        const { input: { value, onChange }, multiple } = props;
        if(multiple) {
            const index = value.indexOf(file);
            const newFileList = value.slice();
            newFileList.splice(index, 1);
            onChange(newFileList.length === 0 ? undefined : newFileList );
        } else {
            onChange(undefined);
        }
    }

    const { input: { name, value }, text, uploadHelper, accept } = props;

    return (
        <div>
            {uploadHelper}
            <Upload
                name={name}
                accept={accept}
                beforeUpload={beforeUpload}
                onRemove={onRemove}
                fileList={value || emptyList}
            >
                <Button>
                    <UploadOutlined />
                    { t(text) }
                </Button>
            </Upload>
        </div>
    );
}

UploadButton.defaultProps = {
    multiple: false,
    text: 'form.chooseFile',
};

export default withFieldWrapper(UploadButton);
