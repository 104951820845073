export const POST_LOGIN = 'POST_LOGIN';
export const POST_OAUTH_LOGIN = 'POST_OAUTH_LOGIN';
export const GET_USER = 'GET_USER';
export const LOGOUT = 'LOGOUT';
export const PUT_USER = 'PUT_USER';
export const POST_PASSWORD_RECOVERY = 'POST_PASSWORD_RECOVERY';
export const POST_PASSWORD_RECOVERY_CONFIRM = 'POST_PASSWORD_RECOVERY_CONFIRM';
export const POST_REGISTRATION_CONFIRM = 'POST_REGISTRATION_CONFIRM';
export const GET_REGISTRATION_CONFIRM = 'GET_REGISTRATION_CONFIRM';

export const GET_VACANCIES = 'GET_VACANCIES';
export const GET_VACANCIES_LIST = 'GET_VACANCIES_LIST';
export const POST_EXPERIUM_VACANCY = 'POST_EXPERIUM_VACANCIES';
export const POST_VACANCY = 'POST_VACANCY';
export const PUT_VACANCY = 'PUT_VACANCY';
export const PUT_VACANCY_SYNC = 'PUT_VACANCY_SYNC';
export const GET_VACANCY = 'GET_VACANCY';
export const DELETE_VACANCY = 'DELETE_VACANCY';
export const GET_PUBLIC_VACANCIES = 'GET_PUBLIC_VACANCIES';

export const GET_APPLICANTS = 'GET_APPLICANTS';
export const GET_APPLICANT = 'GET_APPLICANT';
export const POST_APPLICANT = 'POST_APPLICANT';
export const DELETE_APPLICANTS = 'DELETE_APPLICANTS';
export const PUT_APPROVE_APPLICANTS = 'PUT_APPROVE_APPLICANTS';
export const PUT_REJECT_APPLICANTS = 'PUT_REJECT_APPLICANTS';

export const GET_BOTS = 'GET_BOTS';
export const POST_BOT = 'POST_BOT';
export const PUT_BOT = 'PUT_BOT';
export const DELETE_BOT = 'DELETE_BOT';
export const SUBSCRIBE_BOT = 'SUBSCRIBE_BOT';
export const UNSUBSCRIBE_BOT = 'UNSUBSCRIBE_BOT';
export const GET_BOT_TOKEN = 'GET_BOT_TOKEN';
export const GET_BOT_PUBLIC = 'GET_BOT_PUBLIC';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';
export const REMOVE_MODAL = 'REMOVE_MODAL';

export const OPEN_BOT_MODAL = 'OPEN_BOT_MODAL';
export const OPEN_BOT_PRINT_MODAL = 'OPEN_BOT_PRINT_MODAL';
export const OPEN_EXPERIUM_MANUAL_MODAL = 'OPEN_EXPERIUM_MANUAL_MODAL';
export const OPEN_AVATAR_MODAL = 'OPEN_AVATAR_MODAL';
export const OPEN_USER_ADMIN_MODAL = 'OPEN_USER_ADMIN_MODAL';
export const OPEN_COMPANY_MODAL = 'OPEN_COMPANY_MODAL';
export const OPEN_LANDING_BUILDER_MODAL = 'OPEN_LANDING_BUILDER_MODAL';
export const OPEN_LANDING_CODE_MODAL = 'OPEN_LANDING_CODE_MODAL';
export const OPEN_BOT_WIDGET_MODAL = 'OPEN_BOT_WIDGET_MODAL';
export const OPEN_VACANCY_WIDGET_MODAL = 'OPEN_VACANCY_WIDGET_MODAL';
export const OPEN_BOT_CREATE_MODAL = 'OPEN_BOT_CREATE_MODAL';
export const OPEN_VACANCY_QUESTION_MODAL = 'OPEN_VACANCY_QUESTION_MODAL';
export const OPEN_VACANCY_QUESTIONS_MODAL = 'OPEN_VACANCY_QUESTIONS_MODAL';
export const OPEN_VACANCY_LANGUAGE_MODAL = 'OPEN_VACANCY_LANGUAGE_MODAL';
export const OPEN_VACANCY_QUESTION_LANGUAGE_MODAL = 'OPEN_VACANCY_QUESTION_LANGUAGE_MODAL';
export const OPEN_UNIT_MODAL = 'OPEN_UNIT_MODAL';
export const OPEN_PUBLIC_VACANCIES_PRINT_MODAL = 'OPEN_PUBLIC_VACANCIES_PRINT_MODAL';
export const OPEN_VACANCY_BANNER_MODAL = 'OPEN_VACANCY_BANNER_MODAL';
export const OPEN_VACANCY_TRANSLATION_MODAL = 'OPEN_VACANCY_TRANSLATION_MODAL';
export const OPEN_UNIT_BANNER_MODAL = 'OPEN_UNIT_BANNER_MODAL';
export const OPEN_LANDING_LINK_MODAL = 'OPEN_LANDING_LINK_MODAL';
export const OPEN_QUESTION_PRESET_MODAL = 'OPEN_QUESTION_PRESET_MODAL';
export const OPEN_QR_BANNER_MODAL = 'OPEN_QR_BANNER_MODAL';
export const OPEN_QR_BUILDER_MODAL = 'OPEN_QR_BUILDER_MODAL';
export const OPEN_QR_VACANCY_LINK_MODAL = 'OPEN_QR_VACANCY_LINK_MODAL';
export const OPEN_QR_UNIT_LINK_MODAL = 'OPEN_QR_UNIT_LINK_MODAL';
export const OPEN_MAP_MODAL = 'OPEN_MAP_MODAL';
export const OPEN_DIALOG_VACANCY_MODAL = 'OPEN_DIALOG_VACANCY_MODAL';
export const OPEN_TERMINAL_MODAL = 'OPEN_TERMINAL_MODAL';
export const OPEN_VACANCY_LANDING_MODAL = 'OPEN_VACANCY_LANDING_MODAL';
export const OPEN_VACANCY_FORM_PRESET_MODAL = 'OPEN_VACANCY_FORM_PRESET_MODAL';
export const OPEN_CONFIRM_MODAL = 'OPEN_CONFIRM_MODAL';
export const OPEN_CLASSIFIER_MODAL = 'OPEN_CLASSIFIER_MODAL';
export const OPEN_CLASSIFIER_OPTION_MODAL = 'OPEN_CLASSIFIER_OPTION_MODAL';
export const OPEN_DOMAIN_MODAL = 'OPEN_DOMAIN_MODAL';
export const OPEN_COMPANY_DICTIONARY_MODAL = 'OPEN_COMPANY_DICTIONARY_MODAL';
export const OPEN_COMPANY_DICTIONARY_IMPORT_MODAL = 'OPEN_COMPANY_DICTIONARY_IMPORT_MODAL';
export const OPEN_COMPANY_DICTIONARY_ITEM_MODAL = 'OPEN_COMPANY_DICTIONARY_ITEM_MODAL';
export const OPEN_CLASSIFIER_LANGUAGE_MODAL = 'OPEN_CLASSIFIER_LANGUAGE_MODAL';
export const OPEN_CLASSIFIERS_LANGUAGE_MODAL = 'OPEN_CLASSIFIERS_LANGUAGE_MODAL';
export const OPEN_LANDING_LINKED_VACANCIES_MODAL = 'OPEN_LANDING_LINKED_VACANCIES_MODAL';
export const OPEN_LANDING_LINK_VACANCIES_MODAL = 'OPEN_LANDING_LINK_VACANCIES_MODAL';
export const OPEN_SELECT_CLASSIFIERS_MODAL = 'OPEN_SELECT_CLASSIFIERS_MODAL';
export const OPEN_DOMAIN_WIDGET_MODAL = 'OPEN_DOMAIN_WIDGET_MODAL';
export const OPEN_DOCUMENT_TYPE_MODAL = 'OPEN_DOCUMENT_TYPE_MODAL';
export const OPEN_PDA_HTML_MODAL = 'OPEN_PDA_HTML_MODAL';
export const OPEN_UTM_PARAMS_MODAL = 'OPEN_UTM_PARAMS_MODAL';
export const OPEN_DOMAIN_LICENSE_INFO_MODAL = 'OPEN_DOMAIN_LICENSE_INFO_MODAL';
export const OPEN_DOMAIN_WIDGET_PREVIEW_MODAL = 'OPEN_DOMAIN_WIDGET_PREVIEW_MODAL';
export const OPEN_FORM_SELECT_MODAL = 'OPEN_FORM_SELECT_MODAL';
export const OPEN_USER_IMPORT_MODAL = 'OPEN_USER_IMPORT_MODAL';
export const OPEN_DICTIONARY_LANGUAGE_MODAL = 'OPEN_DICTIONARY_LANGUAGE_MODAL';
export const OPEN_DICTIONARIES_IMPORT_MODAL = 'OPEN_DICTIONARIES_IMPORT_MODAL';
export const OPEN_DICTIONARY_ITEM_MODAL = 'OPEN_DICTIONARY_ITEM_MODAL';
export const OPEN_RESPONSIBLE_MODAL = 'OPEN_RESPONSIBLE_MODAL';
export const OPEN_COMPANY_LICENSE_MODAL = 'OPEN_COMPANY_LICENSE_MODAL';
export const OPEN_QUEUE_ENTRY_MODAL = 'OPEN_QUEUE_ENTRY_MODAL';
export const OPEN_COMPANY_LICENSE_LOG_MODAL = 'OPEN_COMPANY_LICENSE_LOG_MODAL';
export const OPEN_JURISTIC_ENTITY_MODAL = 'OPEN_JURISTIC_ENTITY_MODAL';
export const OPEN_OPD_LANGUAGE_MODAL = 'OPEN_OPD_LANGUAGE_MODAL';

export const GET_CHAT_SETTINGS = 'GET_CHAT_SETTINGS';
export const PUT_CHAT_SETTINGS = 'PUT_CHAT_SETTINGS';

export const GET_DIALOG = 'GET_DIALOG';
export const GET_DIALOGS = 'GET_DIALOGS';
export const GET_DIALOG_MESSAGES = 'GET_DIALOG_MESSAGES';

export const POST_CHAT_MESSAGE = 'POST_CHAT_MESSAGE';
export const GET_CHAT_MESSAGES = 'GET_CHAT_MESSAGES';
export const CLEAR_CHAT = 'CLEAR_CHAT';

export const GET_CITY_DICTIONARY = 'GET_CITY_DICTIONARY';
export const GET_COUNTRY_DICTIONARY = 'GET_COUNTRY_DICTIONARY';
export const GET_REGION_DICTIONARY = 'GET_REGION_DICTIONARY';
export const GET_CURRENCY_DICTIONARY = 'GET_CURRENCY_DICTIONARY';

export const GET_DICTIONARY = 'GET_DICTIONARY';
export const GET_DICTIONARY_ONCE = 'GET_DICTIONARY_ONCE';

export const GET_MESSAGE_NODES = 'GET_MESSAGE_NODES';
export const GET_MESSAGE_NODE = 'GET_MESSAGE_NODE';
export const POST_MESSAGE_NODE = 'POST_MESSAGE_NODE';
export const PUT_MESSAGE_NODE = 'PUT_MESSAGE_NODE';
export const DELETE_MESSAGE_NODE = 'DELETE_MESSAGE_NODE';

export const GET_USERS_ADMIN = 'GET_USERS_ADMIN';
export const GET_USER_ADMIN = 'GET_USER_ADMIN';
export const POST_USER_ADMIN = 'POST_USER_ADMIN';
export const PUT_USER_ADMIN = 'PUT_USER_ADMIN';
export const GET_USER_INVITE = 'GET_USER_INVITE';

export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANY = 'GET_COMPANY';
export const PUT_COMPANY = 'PUT_COMPANY';
export const POST_COMPANY = 'POST_COMPANY';

export const GET_LANDINGS = 'GET_LANDINGS';
export const POST_LANDING = 'POST_LANDING';
export const PUT_LANDING = 'PUT_LANDING';
export const DELETE_LANDING = 'DELETE_LANDING';
export const IFRAME_LOAD = 'IFRAME_LOAD';
export const GET_PDA = 'GET_PDA';

export const POST_FILE = 'POST_FILE';
export const POST_FILE_WITHOUT_AUTH = 'POST_FILE_WITHOUT_AUTH';

export const GET_QUESTIONS_PRESET = 'GET_QUESTIONS_PRESET';
export const POST_QUESTION_PRESET = 'POST_QUESTION_PRESET';
export const PUT_QUESTION_PRESET = 'PUT_QUESTION_PRESET';
export const DELETE_QUESTION_PRESET = 'DELETE_QUESTION_PRESET';

export const GET_QUESTIONS = 'GET_QUESTIONS';
export const GET_QUESTION = 'GET_QUESTION';

export const GET_SPECIALIZATIONS = 'GET_SPECIALIZATIONS';

export const GET_VACANCY_UNITS = 'GET_VACANCY_UNITS';
export const POST_VACANCY_UNIT = 'POST_VACANCY_UNIT';
export const PUT_VACANCY_UNIT = 'PUT_VACANCY_UNIT';

export const GET_COMPANY_SETTINGS = 'GET_COMPANY_SETTINGS';
export const PUT_COMPANY_SETTINGS = 'PUT_COMPANY_SETTINGS';

export const GET_BANNERS = 'GET_BANNERS';
export const GET_BANNER = 'GET_BANNER';
export const POST_BANNER = 'POST_BANNER';
export const PUT_BANNER = 'PUT_BANNER';

export const PATCH_ADMIN_DIALOG_ENABLE = 'PATCH_ADMIN_DIALOG_ENABLE';
export const PATCH_ADMIN_DIALOG_DISABLE = 'PATCH_ADMIN_DIALOG_DISABLE';
export const POST_ADMIN_DIALOG = 'POST_ADMIN_DIALOG';

export const CHANGE_COMPANY_CODE = 'CHANGE_COMPANY_CODE';

export const GET_VACANCY_CATEGORIES = 'GET_VACANCY_CATEGORIES';
export const GET_VACANCY_CHARTS = 'GET_VACANCY_CHARTS';

export const GET_CALL_REQUESTS = 'GET_CALL_REQUESTS';

export const GET_SMS_LOGS = 'GET_SMS_LOGS';

export const GET_COORDINATES = 'GET_COORDINATES';

export const START_CLIENT_CHAT_CONNECTION = 'START_CLIENT_CHAT_CONNECTION';
export const END_CLIENT_CHAT_CONNECTION = 'END_CLIENT_CHAT_CONNECTION';
export const START_ADMIN_CHAT_CONNECTION = 'START_ADMIN_CHAT_CONNECTION';
export const END_ADMIN_CHAT_CONNECTION = 'END_ADMIN_CHAT_CONNECTION';
export const SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE';
export const RECEIVE_CHAT_MESSAGE = 'RECEIVE_CHAT_MESSAGE';
export const RECEIVE_DIALOG_MESSAGE = 'RECEIVE_DIALOG_MESSAGE';

export const GET_VACANCY_FORM_PRESETS = 'GET_VACANCY_FORM_PRESETS';
export const GET_VACANCY_FORM_PRESET = 'GET_VACANCY_FORM_PRESET';
export const POST_VACANCY_FORM_PRESET = 'POST_VACANCY_FORM_PRESET';
export const PUT_VACANCY_FORM_PRESET = 'PUT_VACANCY_FORM_PRESET';
export const DELETE_VACANCY_FORM_PRESET = 'DELETE_VACANCY_FORM_PRESET';

export const GET_CLASSIFIERS = 'GET_CLASSIFIERS';
export const GET_CLASSIFIER = 'GET_CLASSIFIER';
export const POST_CLASSIFIER = 'POST_CLASSIFIER';
export const PUT_CLASSIFIER = 'PUT_CLASSIFIER';
export const DELETE_CLASSIFIER = 'DELETE_CLASSIFIER';

export const GET_CLASSIFIER_OPTIONS = 'GET_CLASSIFIER_OPTIONS';
export const POST_CLASSIFIER_OPTION = 'POST_CLASSIFIER_OPTION';
export const PUT_CLASSIFIER_OPTION = 'PUT_CLASSIFIER_OPTION';
export const DELETE_CLASSIFIER_OPTION = 'DELETE_CLASSIFIER_OPTION';

export const GET_DOMAIN = 'GET_DOMAIN';
export const POST_DOMAIN = 'POST_DOMAIN';
export const PUT_DOMAIN = 'PUT_DOMAIN';
export const DELETE_DOMAIN = 'DELETE_DOMAIN';

export const POST_COMPANY_DICTIONARY_FILE = 'POST_COMPANY_DICTIONARY_FILE';
export const POST_USER_IMPORT_FILE = 'POST_USER_IMPORT_FILE';

export const GET_COMPANY_DICTIONARY = 'GET_COMPANY_DICTIONARY';
export const POST_COMPANY_DICTIONARY = 'POST_COMPANY_DICTIONARY';
export const PUT_COMPANY_DICTIONARY = 'PUT_COMPANY_DICTIONARY';
export const DELETE_COMPANY_DICTIONARY = 'DELETE_COMPANY_DICTIONARY';

export const GET_COMPANY_DICTIONARY_ITEM = 'GET_COMPANY_DICTIONARY_ITEM';
export const POST_COMPANY_DICTIONARY_ITEM = 'POST_COMPANY_DICTIONARY_ITEM';
export const PUT_COMPANY_DICTIONARY_ITEM = 'PUT_COMPANY_DICTIONARY_ITEM';
export const DELETE_COMPANY_DICTIONARY_ITEM = 'DELETE_COMPANY_DICTIONARY_ITEM';

export const GET_COMPANY_DICTIONARY_PARENT_ITEM = 'GET_COMPANY_DICTIONARY_PARENT_ITEM';

export const GET_USER_ADMIN_STATUS = 'GET_USER_ADMIN_STATUS';
export const PUT_USER_ADMIN_SETTINGS = 'PUT_USER_ADMIN_SETTINGS';

export const GET_LOGIN_ATTEMPT = 'GET_LOGIN_ATTEMPT';

export const CHANGE_LANDING = 'CHANGE_LANDING';
