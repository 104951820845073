import React from 'react';
import { deleteRegionDictionaryHandler, postRegionDictionaryHandler, putRegionDictionaryHandler } from '../../../../actions/handlers';

import { REGION_DICTIONARY } from '../../../../constants/urls';
import DictionaryTable from './DictionaryTable';

const RegionDictionary = () => {
    return <DictionaryTable
        actionUrl={REGION_DICTIONARY.stringify()}
        relations={['country']}
        putAction={putRegionDictionaryHandler}
        postAction={postRegionDictionaryHandler}
        deleteAction={deleteRegionDictionaryHandler}
        countryField
        priorityField />;
}

export default RegionDictionary;
