import React, { useContext } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { pathOr, prop, split } from 'ramda';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableList from '../../table/TableList';
import withUserCompany from '../../hocs/withUserCompany';
import { getUrlWithFilters } from '../../../utils/urlParams';
import { DOMAIN } from '../../../constants/urls';
import { deleteDomainHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import ModalsContext from '../../../contexts/ModalsContext';
import { OPEN_DOMAIN_LICENSE_INFO_MODAL, OPEN_DOMAIN_MODAL, OPEN_DOMAIN_WIDGET_MODAL } from '../../../constants/actionTypes';

const Domains = props => {
    const { t, i18n } = useTranslation();
    const { actions, revalidateAction } = useContext(ActionsContext);
    const actionUrl = DOMAIN.stringify({ id: undefined });
    const domainAmounts = pathOr([], [actionUrl, 'data', 'items'], actions).length;
    const modals = useContext(ModalsContext);

    const getDomainAddress = () => {
        const hostname = window.location.hostname;
        let hostType = split('.', hostname)[1];

        if (hostname === 'localhost') {
            hostType = 'dev';
        }

        if (hostname === 'web-test.findy.ru') {
            hostType = 'test';
        }

        switch (hostType) {
            case 'test':
                return `http://findy-vacancies-widget.dev.experium.net/test`;
            case 'demo':
                return `http://findy-vacancies-widget.dev.experium.net/demo`;
            case 'dev':
                return `http://findy-vacancies-widget.dev.experium.net`;
            default:
                return null;
        }
    };
    const widgetPreview = getDomainAddress();

    const getColumns = () => {
        return [
            {
                title: t('domain.name'),
                dataIndex: 'title',
                key: 'title',
                render: title => (
                    <span className='domain-name'>
                        { title }
                    </span>
                ),
            },
            {
                title: t('domain.domainAddress'),
                dataIndex: 'name',
                key: 'name',
                render: name => (
                    <a
                        className='domain-link'
                        target='_blank'
                        href={`http://${name}`}
                        rel='noopener noreferrer'
                    >
                        { name }
                    </a>
                ),
            },
            {
                title: t('domain.formPresetWithVacancy'),
                dataIndex: ['_relations', 'vacancyFormPreset'],
                key: 'vacancyFormPreset',
                render: (vacancyFormPreset) => prop('id', vacancyFormPreset) && (
                    <Link to={`/forms/formPresets/${vacancyFormPreset.id}`}>
                        { prop('title', vacancyFormPreset) }
                    </Link>
                ),
            },
            {
                title: t('domain.formPresetWithoutVacancy'),
                dataIndex: ['_relations', 'domainFormPreset'],
                key: 'domainFormPreset',
                render: (domainFormPreset) => prop('id', domainFormPreset) && (
                    <Link to={`/forms/formPresets/${domainFormPreset.id}`}>
                        { prop('title', domainFormPreset) }
                    </Link>
                ),
            },
            {
                key: 'links',
                render: item => (
                    <div>
                        <Link className='applicants-link' to={getUrlWithFilters('/applicants', { domain: item.id })}>{ t('domain.applicants') }</Link>
                        <br />
                        <Link className='vacancies-link' to={getUrlWithFilters('/', { domain: item.id })}>{ t('domain.vacancies') }</Link>
                    </div>
                ),
            },
            {
                key: 'actions',
                width: 200,
                align: 'center',
                render: item => {
                    return (
                        <div>
                            <div className='domain-buttons'>
                                <Button className='widget-btn' onClick={() => modals.open(OPEN_DOMAIN_WIDGET_MODAL, item)}>
                                    {t('domain.widget')}
                                </Button>
                                { !props.publicationSettings.domain ? null : <Button
                                    className='edit-domain-btn'
                                    icon={<EditOutlined />}
                                    onClick={ () => modals.open(OPEN_DOMAIN_MODAL, { item }) }
                                />}
                                { !props.publicationSettings.domain ? null : <Popconfirm
                                    title={t('domain.deleteConfirm')}
                                    okText={t('domain.yes')}
                                    cancelText={t('domain.no')}
                                    onConfirm={() => deleteDomainHandler(item.id).then(() => {
                                        message.success(t('domain.deleteSuccess'));
                                        revalidateAction(actionUrl);
                                    }).catch(() => message.error(t('domain.deleteError')))}
                                    placement='left'
                                >
                                    <Button
                                        className='delete-domain-btn'
                                        type='danger'
                                        icon={<DeleteOutlined />}
                                    />
                                </Popconfirm>}
                            </div>
                            <Button.Group>
                                { widgetPreview && (
                                    <Button className='vacancies-widget-btn' target='_blank' href={`${widgetPreview}/domains/${item.name}?lang=${i18n.language}`}>
                                        {t('domain.vacancies')}
                                    </Button>
                                )}
                                { widgetPreview && (
                                    <Button className='form-widget-btn'  target='_blank' href={`${widgetPreview}/companies/${item.company}/vacancies/${item.name}/${item.id}?lang=${i18n.language}`}>
                                        {t('domain.previewForm')}
                                    </Button>
                                )}
                            </Button.Group>
                        </div>
                    );
                }
            }
        ];
    };

    const getButtons = () => (
        <Button.Group>
            { !props.publicationSettings.domain ? null : <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() =>
                    !props.isFullLicense && !props.isAdmin && domainAmounts >= 2 ? modals.open(OPEN_DOMAIN_LICENSE_INFO_MODAL) : modals.open(OPEN_DOMAIN_MODAL)
                }
            >
                {t('domain.addDomain')}
            </Button>}
        </Button.Group>
    );

    return (
        <TableList
            className='domain-table'
            actionUrl={actionUrl}
            columns={getColumns()}
            buttons={getButtons()}
            relations={['vacancyFormPreset', 'domainFormPreset']}
        />
    );
}

export default withUserCompany(Domains);
