export const ATTEMPT_BLOCKED = [
    { value: 'auth.attemptBlocked', id: true },
    { value: 'auth.attemptSuccess', id: false }
];

export const USER_DISABLED = [
    { value: 'auth.userActive', id: false },
    { value: 'auth.userInactive', id: true }
];

export const BAD_PASSWORD = [
    { value: 'auth.goodPassword', id: false },
    { value: 'auth.badPassword', id: true }
];

export const LOGIN_FAILURE = [
    { value: 'auth.successLogin', id: false },
    { value: 'auth.errorLogin', id: true }
];
