import ruTranslations from './ru';
import enTranslations from './en';
import uaTranslations from './ua';
import etTranslations from './et';
import ltTranslations from './lt';
import lvTranslations from './lv';

export default {
    ru: {
        translation: ruTranslations
    },
    en: {
        translation: enTranslations
    },
    ua: {
        translation: uaTranslations
    },
    et: {
        translation: etTranslations
    },
    lt: {
        translation: ltTranslations
    },
    lv: {
        translation: lvTranslations
    }
};
