import React, { Component } from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { withTranslation } from 'react-i18next';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import ukUA from 'antd/lib/locale-provider/uk_UA';
import enUS from 'antd/lib/locale-provider/en_US';
import ltLT from 'antd/lib/locale-provider/lt_LT';
import lvLV from 'antd/lib/locale-provider/lv_LV';
import etEE from 'antd/lib/locale-provider/et_EE';
import { SWRConfig } from 'swr';
import axios from 'axios';

import SecureLayout from './layout/SecureLayout';
import { pushRollbarError } from '../utils/rollbarInit';
import Route from './Route';
import { PublicRoutes, ExperiumGuestRoutes } from './GuestRoutes';

const antdLanguages = {
    ru: ruRU,
    ua: ukUA,
    en: enUS,
    et: etEE,
    lt: ltLT,
    lv: lvLV
};

class App extends Component {
    state = {
        error: false
    };

    componentDidCatch(error) {
        pushRollbarError(error);
        this.setState({ error: true });
    }

    render() {
        const { t, i18n } = this.props;

        return this.state.error ? (
            <div className='catch-error'>{ t('app.appError') }</div>
        ) : (
            <SWRConfig value={{
                revalidateOnFocus: false,
                revalidateOnReconnect: false,
                fetcher: axios
            }}>
                <ConfigProvider locale={antdLanguages[i18n.language] || ruRU}>
                    <BrowserRouter>
                        <Switch>
                            {PublicRoutes()}
                            {ExperiumGuestRoutes()}
                            <Route>
                                <SecureLayout locale={antdLanguages[i18n.language] || ruRU} />
                            </Route>
                        </Switch>
                    </BrowserRouter>
                </ConfigProvider>
            </SWRConfig>
        );
    }
}

export default withTranslation()(App);
