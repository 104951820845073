import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { path } from 'ramda';
import { Button } from 'antd';

import { SLIDER_TEMPLATES } from '../../../constants/banner';
import BannerForm, { Container } from '../../forms/BannerForm';
import { useTranslation } from 'react-i18next';

const Header = styled.h3`
    text-align: center;
    margin-top: 15px;
`;

const Templates = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 4;
`;

const Template = styled.div`
    padding: 15px;
    box-shadow: 0 9px 23px rgba(0,0,0,.09), 0 5px 5px rgba(0,0,0,.06);
    background: #fff;
    cursor: pointer;
    flex: 0 0 calc(25% - 20px);
    margin: 20px 10px 0;
    img {
        border: 1px solid #eee;
        width: 300px;
    }
`;

const BannerModal = props => {
    const [banner, setBanner] = useState(null);
    const { t } = useTranslation();

    const getPublicVacanciesLink = () => {
        return `${window.location.origin}/t?vacancyUnit=${props.params.id}`;
    }

    const selectTemplate = bannerValue => {
        const { changeHasTemplate } = props;

        setBanner(bannerValue);
        changeHasTemplate && changeHasTemplate(path(['template'], bannerValue));
    }

    const resetTemplate = () => selectTemplate(null);

    const renderTemplateSelector = () => {
        return <Fragment>
            <Container lowHeight={props.lowHeight} isUnit={props.isUnit} id='preset-selector'>
                <Header>{ t('banner.chooseTemplate') }</Header>
                <Templates>
                    { SLIDER_TEMPLATES(t).map((template, index) =>
                        <Template
                            id={`template-${index}`}
                            key={template.type}
                            onClick={() => selectTemplate({ template: template.type, ...template.options })}>
                            <img src={template.image} alt={template.type} />
                        </Template>
                    )}
                </Templates>
            </Container>
            <div className='ant-modal-footer'>
                <Button onClick={props.close}>
                    { t('banner.close') }
                </Button>
            </div>
        </Fragment>;
    }

    useEffect(() => {
        selectTemplate(path(['params', 'banner'], props));
    }, []);

    useEffect(() => {
        selectTemplate(path(['params', 'banner'], props));
    }, [props.params.id]);

    const { params, formAction, vacancies, isUnit, close, formRef, onSuccess, onError } = props;
    const qr = getPublicVacanciesLink();

    return path(['template'], banner) ?
        <BannerForm
            item={{ ...params, banner }}
            formAction={formAction}
            onSuccess={onSuccess}
            onSubmitFail={onError}
            vacancies={vacancies}
            qr={qr}
            resetTemplate={resetTemplate}
            isUnit={isUnit}
            close={close}
            formRef={formRef} /> :
        renderTemplateSelector();
}

export default BannerModal;
