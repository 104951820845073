import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { path } from 'ramda';
import { useTranslation } from 'react-i18next';

import LandingCodeForm from '../../forms/LandingCodeForm';
import UserContext from '../../../contexts/UserContext';
import CompanyCodeContext from '../../../contexts/CompanyCodeContext';
import { postLandingHandler, putLandingHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { LANDING } from '../../../constants/urls';
import { CHANGE_LANDING } from '../../../constants/actionTypes';

const LandingCodeModal = props => {
    const { t } = useTranslation();
    const { revalidateAction, onSuccessFn } = useContext(ActionsContext);
    const { companyCode } = useContext(CompanyCodeContext);
    const { user } = useContext(UserContext);
    const { modal, params: { item, pda }, close } = props;

    return <Modal
        {...modal}
        title={t('landing.code')}
        footer={null}
        maskClosable={false}
        destroyOnClose>
        <LandingCodeForm
            item={{ ...item, pda }}
            formAction={item.code ? putLandingHandler : postLandingHandler}
            companyCode={companyCode || path(['_relations', 'company', 'code'], user)}
            onSuccess={() => {
                revalidateAction(LANDING.stringify());
                onSuccessFn(CHANGE_LANDING);
                message.success(t(item.code ? 'landing.landingEditSuccess' : 'landing.landingCreateSuccess'));
                close();
            }}
            onSubmitFail={() => message.error(t(item.code ? 'landing.landingEditError' : 'landing.landingCreateError'))}
        />
    </Modal>;
}

export default LandingCodeModal;
