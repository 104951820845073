export default {
    'User not found': 'app.errors.userNotFound',
    'User is disabled': 'app.errors.userIsDisabled',
    'User disabled': 'app.errors.userDisabled',
    'Incorrect password': 'app.errors.incorrectPassword',
    'User already exists': 'app.errors.userAlreadyExists',
    'Bot not found': 'app.errors.botNotFound',
    'Unauthorized': 'app.errors.unauthorized',
    'Bruteforce detection': 'app.errors.userBrutBlocked',
    'User has not complete registration': 'app.errors.userNotCompleteRegistration',
    'Access to unit \'domainCreate\' denied': 'app.errors.domainCreateAccess',

    '$property must be longer than or equal to $constraint1 characters': 'app.errors.propertyCharacters',
    'user with username \'$value\' already exists': 'app.errors.userUsername',
    'bot with username \'$value\' already exists': 'app.errors.botUsername',
    'company with $property \'$value\' already exists': 'app.errors.companyProperty',
    'landing with code \'$value\' already exists': 'app.errors.landingCode',
    'vacancy with code \'$value\' already exists': 'app.errors.vacancyCode',
    'dictionary with $property \'$value\' already exists': 'app.errors.dictionaryProperty',
    'dictionary item with $property \'$value\' already exists': 'app.errors.dictionaryItemProperty',
    'User with username \'$value\' already exists': 'app.errors.username',
    'phone must be a valid phone number': 'app.errors.phone must be a valid phone number',
    'name must be a valid domain name': 'app.errors.name must be a valid domain name',
    'username should not be empty': 'app.errors.username should not be empty',
    'username must be an email': 'app.errors.username must be an email',
    'firstName must be a string': 'app.errors.firstName must be a string',
    'lastName must be a string': 'app.errors.lastName must be a string',
    'Password length must be greater or equal than': 'app.errors.minPasswordLength',
    'According to your company\'s password policy, your current password has expired': 'app.errors.passwordExpired',
    'Incorrect old password': 'app.errors.oldPasswordIncorrect',

    'Validation failed': 'app.errors.validationFailed',
    'Not Found': 'app.errors.notFound'
};

export const DEFAULT_ERROR = 'app.errors.default';
export const UNABLE_TO_SHOW = 'app.errors.unableToShow';
export const USER_UNAVAIBLE = 'app.errors.userUnavaible';
export const PASSWORD_EXPIRED = 'app.errors.passwordExpired';

export const ERRORS_LABELS = {
    name: 'app.errors.labels.name',
    value: 'app.errors.labels.value',
    code: 'app.errors.labels.code'
};

export const PARENT_FIELD_VALIDATOR = 'companyDictionaryParentField';
export const GEO_PARENT_FIELD_VALIDATOR = 'geoParentField';
