import { message, Modal } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { postDictionaryHandler, putDictionaryHandler } from '../../../../actions/handlers';
import { DICTIONARY } from '../../../../constants/urls';
import ActionsContext from '../../../../contexts/ActionsContext';
import DictionaryItemForm from '../../../forms/DictionaryItemForm';

const DictionaryItemModal = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const { modal, close, params: { putAction, postAction, actionUrl, dictionaryType, item, regionField, countryField, isDictionary, priorityField }} = props;

    return <Modal
        {...modal}
        title={t(item ? 'settings.editDictionaryItem' : 'settings.addDictionaryItem')}
        footer={null}>
        <DictionaryItemForm
            formAction={item ? (putAction || putDictionaryHandler) : (postAction || postDictionaryHandler)}
            item={item}
            dictionary={dictionaryType}
            regionField={regionField}
            countryField={countryField}
            isDictionary={isDictionary}
            priorityField={priorityField}
            onSuccess={() => {
                message.success(t(item ? 'settings.editDictionaryItemSuccess' : 'settings.addDictionaryItemSuccess'));
                revalidateAction(actionUrl || DICTIONARY.stringify({ type: dictionaryType }))
                close();
            }}
            onError={() => message.error(t(item ? 'settings.editDictionaryItemError' : 'settings.addDictionaryItemError'))} />
    </Modal>;
}

export default DictionaryItemModal;
