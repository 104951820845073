import React from 'react';
import { Field } from 'react-final-form';
import { Col } from 'antd';
import { withTranslation } from 'react-i18next';

import BaseFilter from './base/BaseFilter';
import Select from '../forms/formComponents/Select';
import withFilterForm from './base/withFilterForm';
import { VACANCY } from '../../constants/urls';

export default withTranslation()(withFilterForm(({ t }) => (
    <BaseFilter
        searchFieldName="phone"
        searchFieldPlaceholder={t('call.phone')}
        span={8}
    >
        <Col span={8}>
            <Field
                name="vacancy"
                placeholder={t('call.vacancy')}
                component={Select}
                actionUrl={VACANCY.stringify()}
                namePath="title"
                searchKey='term'
                searchable
                allowClear
                sorting={{
                    field: 'createdAt',
                    order: 'desc',
                }}
            />
        </Col>
    </BaseFilter>
)));
