import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Wrapper = styled.div`
    .pda-modal-close-button {
        display: none;
    }
`;

const PdaModal = props => {
    const { t } = useTranslation();
    const { modal, params: { htmlContent } } = props;

    return (
        <Modal
            {...modal}
            className='pda-modal'
            title={t('applicant.opd')}
            footer={null}
        >
            <Wrapper>
                <div dangerouslySetInnerHTML={{ __html: htmlContent }}/>
            </Wrapper>
        </Modal>
    );
}

export default PdaModal;
