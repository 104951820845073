import React, { Fragment, useState } from 'react';
import { Field } from 'react-final-form';
import { find, path, propEq, pathOr, isEmpty, isNil, filter } from 'ramda';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import ListenerField from '../ListenerField';
import AddressSelect from '../formComponents/AddressSelect';
import Input from '../formComponents/Input';
import MetroInput from '../formComponents/MetroInput';
import Classifiers from '../formComponents/Classifiers';
import Select from '../formComponents/Select';
import InputNumber from '../formComponents/InputNumber';
import { DescriptionEditor } from '../formComponents/Editor';
import { getStationsLines } from '../../../utils/fieldsHelpers';
import withCityField from '../../hocs/withCityField';
import { salaryPeriod, salaryViewType } from '../../../constants/vacancy';
import { SALARY_FORMAT, isSalaryFixed } from '../../../utils/fieldsHelpers';
import { transformCodeValue } from '../../../utils/companyCode';
import { CITY_DICTIONARY, DICTIONARY, VACANCY_CHARTS, VACANCY_UNIT } from '../../../constants/urls';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const hideClassifier = ({ classifiers: { data } }) => {
    const classifiers = pathOr([], ['items'], data);

    if (isEmpty(classifiers)) {
        return true;
    } else {
        let hideClassifier = true;

        classifiers.forEach(classifier => {
            const options = pathOr([], ['_relations', 'options'], classifier);
            const filteredOptions = filter((value) => !isNil(value), options);

            if (hideClassifier && !isEmpty(filteredOptions)) {
                hideClassifier = false;
            }
        });

        return hideClassifier;
    }
}

const GeneralVacancyFields = props => {
    const [cities, setCities] = useState(pathOr([], ['_relations', 'cities'], props.vacancy));
    const { t } = useTranslation();
    const shouldLoadUnits = !(props.isFunctionalLicense && !props.isAdmin);
    const salaryTypes = useCompanyCodeSwr(DICTIONARY.stringify({ type: 'SalaryType' }), url => axios.get(url, {
        params: {
            pagination: { limit: 1 }
        }
    }));
    const units = useCompanyCodeSwr(VACANCY_UNIT.stringify(), url => axios.get(url, {
        params: {
            pagination: {
                offset: 0,
                limit: 0
            }
        }
    }), { revalidateOnMount: shouldLoadUnits, revalidateOnFocus: shouldLoadUnits, revalidateOnReconnect: shouldLoadUnits });

    const setMetroLines = values => {
        if (values && values.length) {
            const lines = getStationsLines(values);

            props.form.change('metroLines', lines);
        } else {
            props.form.change('metroLines', null);
        }
    }

    const setUnitFields = value => {
        if (value) {
            const unit = find(propEq('id', value), path(['data', 'items'], units));

            if (unit) {
                props.form.change('city', unit.city);
                props.form.change('address.address', unit.address);
                props.form.change('address.location', unit.location);
                props.form.change('metro', unit.metro);
                props.form.change('metroLines', unit.metroLines);
            }
        }
    }

    const setSalaryType = salaryType => value => {
        if (!salaryType && value) {
            props.form.change('salaryType', path(['data', 0, 'id'], salaryTypes));
        }
    }

    const setSalaryViewType = value => {
        if (isSalaryFixed(value)) {
            props.form.change('minSalary', undefined);
        }
    };

    const {
        vacancy,
        cityName,
        highlightError,
        validateOnChange,
        coordinatesPending,
    } = props;
    const fieldCities = pathOr([], ['_relations', 'cities'], vacancy);
    const hasUnits = !!pathOr([], ['data', 'items'], units).length;

    return <Fragment>
        <Field
            name='code'
            component={Input}
            label={t('vacancy.form.code')}
            highlightError={highlightError}
            validateOnChange={validateOnChange}
            parse={transformCodeValue}
            placeholder={t('vacancy.placeholders.vacancyCode')} />
        <Field
            name='description'
            component={DescriptionEditor}
            label={t('vacancy.form.description')}
            placeholder={t('vacancy.placeholders.vacancyDescription')}
            highlightError={highlightError}
            validateOnChange={validateOnChange}
        />
        <ListenerField listenFieldName='company'>
            { () => {
                return (
                    <Fragment>
                        <Field
                            name='classifiers'
                            component={Classifiers}
                            label={t('vacancy.form.classifier')}
                            hideField={hideClassifier}
                        />
                        { hasUnits && (
                            <Row gutter={10}>
                                <Col md={vacancy ? 18 : 24}>
                                    <Field
                                        name='vacancyUnit'
                                        component={Select}
                                        label={t('vacancy.form.unit')}
                                        placeholder={t('vacancy.placeholders.chooseUnit')}
                                        options={units.data.items}
                                        namePath='title'
                                        smallPath='address'
                                        onChange={!vacancy ? setUnitFields : null}
                                        allowClear={true}
                                    />
                                </Col>
                                { vacancy && (
                                    <ListenerField listenFieldName='vacancyUnit'>
                                        {({ vacancyUnit }) => (
                                            <Col md={6} className='form-item-sub-col'>
                                                <Button disabled={!vacancyUnit} onClick={() => setUnitFields(vacancyUnit)}>
                                                    { t('vacancy.form.copyFields') }
                                                </Button>
                                            </Col>
                                        )}
                                    </ListenerField>
                                )}
                            </Row>
                        )}
                    </Fragment>
                );
            }}
        </ListenerField>
        <ListenerField listenFieldName='address'>
            { ({ address }) => (
                <Field
                    name='cities'
                    component={Select}
                    label={t('vacancy.form.city')}
                    placeholder={t('vacancy.placeholders.chooseCity')}
                    actionUrl={CITY_DICTIONARY.stringify()}
                    searchable={true}
                    onChange={(value, selected) => {
                        props.onCitySelect(value && value.length === 1 ? value[0] : null, address, selected);
                        setCities(selected);

                        if (value && value.length > 1) {
                            props.form.batch(() => {
                                props.form.change('address', null);
                                props.form.change('location', null);
                            });
                        }
                    }}
                    initialOptions={fieldCities}
                    loadInitialOptions={props.loadCities}
                    allowClear={true}
                    valuePath='id'
                    namePath='name'
                    searchKey='name'
                    isMulti
                />
            )}
        </ListenerField>
        <ListenerField listenFieldName='cities'>
            { ({ cities: fieldCities }) => <Fragment>
                    <Field
                        name='address'
                        component={AddressSelect}
                        label={t('vacancy.form.address')}
                        city={fieldCities && fieldCities.length > 1 ? null : cityName}
                        disabled={coordinatesPending || !!fieldCities && fieldCities.length > 1}
                        placeholder={t('vacancy.placeholders.chooseAddress')}
                    />
                    { fieldCities && fieldCities.length === 1 && (
                        <div className='metro-field'>
                            <Field
                                name='metro'
                                component={MetroInput}
                                onChange={setMetroLines}
                                citiesList={cities}
                                city={fieldCities[0]}
                            />
                        </div>
                    )}
                </Fragment>
            }
        </ListenerField>
        <Row gutter={10}>
            <Col md={12}>
                <Field
                    name='chartOfWork'
                    component={Select}
                    label={t('vacancy.form.chartOfWork')}
                    placeholder={t('vacancy.placeholders.chooseChartOfWork')}
                    actionUrl={VACANCY_CHARTS.stringify()}
                    dataParse={data => ({ items: data })}
                    namePath='name'
                    valuePath='id'
                    allowClear={true}
                    searchable
                />
            </Col>
            <Col md={12}>
                <Field
                    name='employeeType'
                    component={Select}
                    label={t('vacancy.form.employeeType')}
                    actionUrl={DICTIONARY.stringify({ type: 'PrjEmployType' })}
                    dataParse={data => ({ items: data })}
                    filter={{ type: 'PrjEmployType' }}
                    namePath='name'
                    valuePath='id'
                    placeholder={t('vacancy.placeholders.chooseEmployeeType')}
                    allowClear={true}
                    sorting={{
                        field: 'title',
                        order: 'asc',
                    }}
                />
            </Col>
        </Row>
        <Row gutter={10}>
            <Col md={4}>
                <Field
                    name='salaryViewType'
                    component={Select}
                    options={salaryViewType}
                    label={t('vacancy.form.salary')}
                    onChange={setSalaryViewType}
                />
            </Col>
            <Col md={4}>
                <ListenerField listenFieldName={['salaryType', 'salaryViewType']}>
                    { ({ salaryType, salaryViewType }) => (
                        <Field
                            name='minSalary'
                            component={InputNumber}
                            label={t('vacancy.form.minSalary')}
                            formatter={SALARY_FORMAT}
                            highlightError={highlightError}
                            validateOnChange={validateOnChange}
                            disabled={isSalaryFixed(salaryViewType)}
                            placeholder={t('vacancy.placeholders.chooseMinSalary')}
                            onChange={setSalaryType(salaryType)}
                        />
                    )}
                </ListenerField>
            </Col>
            <Col md={4}>
                <ListenerField listenFieldName='salaryType'>
                    { ({ salaryType }) => (
                        <Field
                            name='maxSalary'
                            component={InputNumber}
                            label={t('vacancy.form.maxSalary')}
                            formatter={SALARY_FORMAT}
                            highlightError={highlightError}
                            validateOnChange={validateOnChange}
                            placeholder={t('vacancy.placeholders.chooseMaxSalary')}
                            onChange={setSalaryType(salaryType)}
                        />
                    )}
                </ListenerField>
            </Col>
            <Col md={4}>
                <Field
                    name='salaryPeriod'
                    component={Select}
                    options={salaryPeriod}
                    label={t('vacancy.form.salaryPeriod')}
                    allowClear={true}
                    placeholder={t('vacancy.placeholders.chooseSalaryPeriod')}
                />
            </Col>
            <Col md={4}>
                <Field
                    name='salaryCurrency'
                    component={Select}
                    actionUrl={DICTIONARY.stringify({ type: 'currency' })}
                    filter={{ type: 'currency' }}
                    label={t('vacancy.form.salaryCurrency')}
                    namePath='code'
                    valuePath='id'
                    allowClear={true}
                    placeholder={t('vacancy.placeholders.chooseSalaryCurrency')}
                    searchable
                    sorting={{
                        field: 'title',
                        order: 'asc',
                    }}
                />
            </Col>
            <Col md={4}>
                <Field
                    name='salaryType'
                    component={Select}
                    actionUrl={DICTIONARY.stringify({ type: 'SalaryType' })}
                    filter={{ type: 'SalaryType' }}
                    label={t('vacancy.form.salaryType')}
                    namePath='name'
                    valuePath='id'
                    allowClear={true}
                    placeholder={t('vacancy.placeholders.chooseSalaryType')}
                    searchable
                    highlightError={highlightError}
                    sorting={{
                        field: 'title',
                        order: 'asc',
                    }}
                />
            </Col>
        </Row>
    </Fragment>;
}

export default withCityField('vacancy')(GeneralVacancyFields);
