import React, { useContext, useState } from 'react';
import { path, assocPath, values, pathOr } from 'ramda';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import LandingBuilder from './LandingBuilder';
import { putLandingHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { LANDING } from '../../../constants/urls';
import { CHANGE_LANDING, OPEN_LANDING_CODE_MODAL } from '../../../constants/actionTypes';
import ModalsContext from '../../../contexts/ModalsContext';

const LandingBuilderModalContent = props => {
    const { t } = useTranslation();
    const [pending, setPending] = useState(false);
    const { revalidateAction, onSuccessFn } = useContext(ActionsContext);
    const modals = useContext(ModalsContext);

    const onGetData = data => {
        const { params, remove, close } = props;

        if (!data) {
            message.warning(t('landing.needChooseLanding'));
        } else {
            const item = assocPath(['content'], data, params.item);

            if (item.code) {
                setPending(true);
                putLandingHandler(item)
                    .then(() => {
                        revalidateAction(LANDING.stringify())
                        onSuccessFn(CHANGE_LANDING);
                        message.success(t('landing.landingEditSuccess'));
                        close();
                    }).catch(() => {
                        setPending(false);
                        message.error(t('landing.landingEditError'));
                    });
            } else {
                remove();
                modals.open(OPEN_LANDING_CODE_MODAL, { item, pda: params.pda });
            }
        }
    }

    const { params, close } = props;
    const vacancy = path(['item', 'vacancies', 0], params);
    const vacancies = values(pathOr({}, ['item', '_relations', 'vacancies'], params));

    return <LandingBuilder
        data={JSON.stringify(path(['item', 'content'], params))}
        onGetData={onGetData}
        close={close}
        vacancy={vacancy}
        vacancies={vacancies}
        pending={pending}
    />;
}

export default LandingBuilderModalContent;
