import React, { useContext, Fragment } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { pathOr } from 'ramda';

import ProfileForm from '../../forms/ProfileForm';
import { putUserHandler } from '../../../actions/handlers';
import UserContext from '../../../contexts/UserContext';

const Profile = () => {
    const { user, load } = useContext(UserContext);
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Fragment>
            <div className='toolbar vacancy-toolbar'>
                <Button shape='circle' icon={<ArrowLeftOutlined />} onClick={history.goBack} />
                <h1 className='vacancy-block-title'>
                    <span className='vacancy-title'>{ t('app.profile') }</span>
                </h1>
            </div>
            <div className="profile-block">
                <ProfileForm
                    user={user}
                    minPasswordLength={pathOr(1, ['_relations', 'company', 'companySettings', 'minPasswordLength'], user)}
                    formAction={putUserHandler}
                    onSubmitFail={() => message.error(t('profile.saveError'))}
                    onSuccess={() => {
                        load();
                        message.success(t('profile.saveSuccess'));
                    }} />
            </div>
        </Fragment>
    );
}

export default Profile;
