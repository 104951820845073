import React, { useContext } from 'react';
import { Button, message, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { path } from 'ramda';
import { useTranslation } from 'react-i18next';

import VacancyFormPresetForm from '../../../forms/VacancyFormPresetForm';
import { postVacancyFormPresetHandler, putVacancyFormPresetHandler } from '../../../../actions/handlers';
import { VACANCY_FORM_PRESET } from '../../../../constants/urls';
import ActionsContext from '../../../../contexts/ActionsContext';
import useCompanyCodeSwr from '../../../../utils/useCompanyCodeSwr';
import ErrorHandler from '../../../hocs/withErrorHandler';

const FormPreset = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const { match: { params: { id } }, history } = props;
    const vacancyFormPreset = id ? useCompanyCodeSwr(VACANCY_FORM_PRESET.stringify({ id }), null, { revalidateOnMount: !!id }) : {};

    return <ErrorHandler action={vacancyFormPreset} key={id}>
        <div className='toolbar vacancy-toolbar'>
            <Button shape='circle' icon={<ArrowLeftOutlined />} onClick={history.goBack} />
            <h1>{ id ? path(['data', 'title'], vacancyFormPreset) : t('vacancy.questions.addFormPreset') }</h1>
        </div>
        { id && vacancyFormPreset.isValidating && !vacancyFormPreset.data ?
            <Spin className='central-spin' /> :
            <div style={{ padding: 20 }}>
                <VacancyFormPresetForm
                    formAction={id ? putVacancyFormPresetHandler : postVacancyFormPresetHandler}
                    item={id ? vacancyFormPreset.data : {}}
                    onSuccess={() => {
                        revalidateAction(VACANCY_FORM_PRESET.stringify());
                        message.success(t(id ? 'vacancy.questions.formPresetSuccessSave' : 'vacancy.questions.formPresetSuccessAdd'));
                        history.goBack();
                    }}
                    onSubmitFail={() => t(id ? 'vacancy.questions.formPresetErrorSave' : 'vacancy.questions.formPresetErrorAdd')} />
            </div>
        }
    </ErrorHandler>;
}

export default FormPreset;
