import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { getUrl } from '../../../utils/http';
import { FILE } from '../../../constants/urls';

const AvatarModal = props => {
    const { t } = useTranslation();
    const { modal, params: { avatar, name } } = props;

    return (
        <Modal
            {...modal}
            title={name}
            footer={null}
            className='avatar-modal'
        >
            <img alt={t('dialog.avatar')} src={getUrl(FILE, { id: avatar })} />
        </Modal>
    );
}

export default AvatarModal;
