import React, { useContext } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import VacancyLanguageForm from '../../forms/VacancyLanguageForm';
import { putVacancyHandler } from '../../../actions/handlers';
import { VACANCY } from '../../../constants/urls';
import ActionsContext from '../../../contexts/ActionsContext';
import { PUT_VACANCY } from '../../../constants/actionTypes';

const VacancyLanguageModal = props => {
    const { t } = useTranslation();
    const { revalidateAction, onSuccessFn } = useContext(ActionsContext);
    const { modal, params, close } = props;

    return (
        <Modal
            {...modal}
            width='calc(100% - 30px)'
            style={{ maxWidth: '1440px' }}
            title={t('vacancy.detail.translations')}
            closable={false}
            footer={null}
        >
            <VacancyLanguageForm
                formAction={putVacancyHandler}
                onSuccess={() => {
                    onSuccessFn(PUT_VACANCY);
                    revalidateAction(VACANCY.stringify())
                    close();
                }}
                questions={params.questions}
                close={close}
            />
        </Modal>
    );
}

export default VacancyLanguageModal;
