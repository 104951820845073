import React from 'react';
import { Modal, Button } from 'antd';
import styled from 'styled-components';
import { useTranslation, withTranslation } from 'react-i18next';

import Landings from '../../views/landing/Landings';

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
    }

    .ant-table-placeholder {
        border-bottom: none;
    }
`;

const VacancyLandingModal = props => {
    const { t } = useTranslation();
    const { modal, params: { id, title, pda }} = props;

    const renderFooter = () => (
        <Button onClick={() => props.close()}>
            OK
        </Button>
    );

    return <StyledModal
        {...modal}
        className='vacancy-landing-modal'
        title={t('vacancy.detail.landing')}
        footer={renderFooter()}
        width={900}>
        <Landings vacancy={id} vacancyTitle={title} pda={pda} staticList />
    </StyledModal>;
}

export default withTranslation()(VacancyLandingModal);
