import React from 'react';
import qs from 'qs';
import { has, path } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import LanguageDropdown from './LanguageDropdown';
import GuestRoutes from '../../GuestRoutes';

export const GuestLayout = props => {
    const { t } = useTranslation();
    const location = useLocation();
    const isAncor = has('ancor', qs.parse(location.search, { ignoreQueryPrefix: true }));

    return <div className='guest-content'>
        <div className='guest-layout'>
            <div className='guest-block'>
                <div className='guest-language'>
                    <LanguageDropdown disableLanguageReload={props.disableLanguageReload} />
                </div>
                {props.routes}
            </div>
            { !path(['DISABLE_LOGIN_CONTACTS'], window) && (
                <div className='reg-info'>
                    { isAncor ?
                        <div>{ t('guest.ancorInfo1') } <a href='tel:6771010'>677-1010</a> { t('guest.ancorInfo2') } <a href='mailto:experium@ancor.ru'>experium@ancor.ru</a></div> :
                        <div>{ t('guest.ancorInfo3') } <a href='https://experium.ru/'>Experium</a><br /><a href='tel:+74957836603'>+7 (495) 783-66-03</a><small>{ t('guest.ancorInfo4') }</small></div>
                    }
                </div>
            )}
        </div>
    </div>;
};

const GuestLayoutRoutes = props => <GuestLayout {...props} routes={<GuestRoutes />} />;

export default GuestLayoutRoutes;
