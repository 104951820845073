import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal, message, Button } from 'antd';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';
import { path, pathOr, pathEq, find, propEq } from 'ramda';
import Qr from 'qrcode.react';
import { useTranslation } from 'react-i18next';

import { PRESETS } from '../../../constants/print';
import QrBuidler from './QrBuilder';
import { postBannerHandler, putBannerHandler } from '../../../actions/handlers';
import { BANNER } from '../../../constants/urls';
import ActionsContext from '../../../contexts/ActionsContext';
import ModalsContext from '../../../contexts/ModalsContext';
import { OPEN_QR_BANNER_MODAL } from '../../../constants/actionTypes';

const StyledModal = styled(Modal)`
    top: 30px !important;
    width: 980px !important;
    min-width: 980px;
    .ant-modal-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        & > .ant-btn,
        & > .ant-btn-group {
            margin-left: 15px;
        }
    }
`;

const PresetsHeader = styled.h3`
    text-align: center;
`;

const Presets = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

const Preset = styled.div`
    padding: 15px;
    box-shadow: 0 9px 23px rgba(0,0,0,.09), 0 5px 5px rgba(0,0,0,.06);
    background: #fff;
    cursor: pointer;
    img {
        border: 1px solid #eee;
        width: 300px;
    }
`;

const QrWrapper = styled.div`
    height: 0;
    overflow: hidden;
`;

const QrModal = props => {
    const { t } = useTranslation();
    const { revalidateAction } = useContext(ActionsContext);
    const node = useRef();
    const qrContainer = useRef();
    const print = useRef();
    const modals = useContext(ModalsContext);

    const getData = () => pathOr(path(['content'], props.params), ['content'], props.data);
    const [data, setData] = useState(getData());

    useEffect(() => setData(getData()), [props.data]);

    const save = () => {
        const { remove, params, close } = props;

        if (!params.newItem) {
            const values = {
                ...(props.data || params),
                content: data
            };

            (values.id ? putBannerHandler : postBannerHandler)(values).then(() => {
                revalidateAction(BANNER.stringify());
                message.success(t('banner.bannerSaveSuccess'));
                close();
            }).catch(() => t('banner.bannerSaveSuccess'));
        } else {
            remove();
            modals.open(OPEN_QR_BANNER_MODAL, { content: data });
        }
    }

    const download = () => {
        const href = qrContainer.current.querySelector('canvas').toDataURL('image/png').replace(/^data:image\/[^;]/, 'data:application/octet-stream');
        document.getElementById('qr-download').href = href;
    }

    const printContent = () => {
        setTimeout(() => print.current.handlePrint(), 50);
    }

    const renderFooter = () => {
        const disableDownload = !getQrLink();

        const downloadQrButton = <a
            id='qr-download'
            className='ant-btn'
            key='image'
            onClick={!disableDownload ? download : null}
            disabled={disableDownload}
            download='qr.png'
            href>
            { t('banner.downloadQr') }
        </a>;

        return data ? [
            <Button
                key='ReactToPrint'
                onClick={printContent}>
                { t('banner.print') }
            </Button>,
            downloadQrButton,
            <Button
                key='save'
                type='primary'
                onClick={save}>
                { t('form.save') }
            </Button>
        ] : [downloadQrButton];
    }

    const getPrintContent = () => {
        return node.current.querySelector('.konvajs-content');
    }

    const selectPreset = options => {
        setData(options(props.qr, t));
    }

    const renderPresetSelector = () => {
        return <div id='preset-selector'>
            <PresetsHeader>{ t('banner.chooseTemplate') }</PresetsHeader>
            <Presets>
                { PRESETS.map((preset, index) =>
                    <Preset
                        id={`banner-preset-${index}`}
                        key={`qr-preset-${index}`}
                        onClick={() => selectPreset(preset.options)}>
                        <img src={preset.image} alt={`preset ${index}`} />
                    </Preset>
                )}
            </Presets>
        </div>
    }

    const updateData = data => setData(data);

    const renderBuilder = () => {
        return data ?
            <QrBuidler
                data={data}
                updateData={updateData} /> :
            renderPresetSelector();
    }

    const getTitle = () => {
        const { getBanners, title, params } = props;

        if (getBanners) {
            return getBanners.meta.success && (pathEq(['data', '_meta', 'count'], 0)(getBanners) ? t('banner.createPrintBanner') : title || t('banner.editBanner'));
        } else {
            return params.newItem ? t('banner.createPrintBanner') : t('banner.editBanner');
        }
    }

    const getQrLink = () => {
        return pathOr(props.qr, ['link'], find(propEq('type', 'qr'), pathOr([], ['elements'], data)));
    }

    const { modal, renderContent } = props;
    const builder = renderBuilder();
    const qr = getQrLink();

    return <StyledModal
        {...modal}
        title={getTitle()}
        footer={renderFooter()}
        maskClosable={false}>
        <div ref={node} id='qr-builder'>
            { renderContent ? renderContent(builder) : builder }
        </div>
        { qr &&
            <QrWrapper ref={qrContainer}>
                <Qr level='H' value={qr} renderAs='canvas' bgColor='#fff' size={500} includeMargin />
            </QrWrapper>
        }
        <ReactToPrint
            trigger={() => <span />}
            ref={print}
            content={() => getPrintContent()}
            bodyClass='print-qr' />
    </StyledModal>;
}

export default QrModal;
