import React from 'react';
import { Field } from 'react-final-form';
import { Button } from 'antd';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import withFormWrapper from '../../hocs/withFormWrapper';
import Input from '../../forms/formComponents/Input';

const DocumentTypeForm = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Field
                label={t('settings.typeName')}
                name='name'
                component={Input}
                placeholder={t('settings.selectTypeName')}
            />
            <Field
                label={t('settings.mimeType')}
                name='type'
                component={Input}
                placeholder={t('settings.selectMimeType')}
            />
            <Button htmlType='submit'>
                {t('settings.add')}
            </Button>
        </div>
    );
}

const validationSchema = ({ values }) => yup.object().shape({
    name: yup.string().fileTypeName(values).required(),
    type: yup.string().mime(values).required(),
});

export default withFormWrapper(DocumentTypeForm, {
    validationSchema,
});
