
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Modal, Button, Row, Col, Alert } from 'antd';
import { append, includes, map, without, find, propEq, pathOr } from 'ramda';
import '@experium/moscow_metro';
import SpbMetro from '@experium/spb-metro';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { LINES, METRO_TYPES, METRO_OPTIONS } from '../../../constants/metro';
import withFieldWrapper from '../../hocs/withFieldWrapper';
import Select, { Select as MetroSelect } from './Select';
import { CITY_DICTIONARY } from '../../../constants/urls';
import useCompanyCodeSwr from '../../../utils/useCompanyCodeSwr';

const MetroMapSelect = props => {
    const { t } = useTranslation();
    const container = useRef();
    let map = null;

    useEffect(() => {
        map = new window.MoscowMetro(container.current, {
            selectable: true,
            check_icons: true,
            zoom: {
                k: 1.5,
                min: 1,
                max: 8,
            }
        });

        map.select(props.values);

        map.use((station, next) => {
            props.onSelect(station.id);
            next();
        });

        return () => map && map.destroy();
    }, []);

    return (
        <Fragment>
            <div className='metro' ref={container}></div>
            <Button type='primary' onClick={props.close}>{ t('form.ok') }</Button>
        </Fragment>
    );
}

const MetroSelectComponent = props => {
    const { t } = useTranslation();
    const [mapOpen, setMapOpen] = useState(false);
    const mounted = useRef();

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            props.onChange(null);
        }
    }, [props.mapType]);

    const onSelect = value => {
        const values = props.input.value;

        props.onChange(includes(value, values) ?
            without([value], values)
            : append(value, values)
        );
    }

    const close = () => {
        setMapOpen(false);
    }

    const renderMetroLabel = ({ label, data: { option, label: dataLabel } }) => {
        return (
            <div className='metro-line'>
                { map(line => (
                    <span key={line} className={`line-badge line-${line} ${props.mapType}`}>{line}</span>
                ), option.lines)}1
                { label || dataLabel }
            </div>
        );
    }

    const renderMetroTag = props => {
        return <div className='metro-line-tag'>
            { renderMetroLabel(props) }
        </div>;
    };

    const renderMSCMetroMap = () => {
        return <Fragment>
            <Alert
                style={{ margin: 10, marginBottom: 0 }}
                message={t('form.chooseMetroStation')}
                type='warning' />
            <MetroMapSelect
                onSelect={onSelect}
                values={props.input.value || []}
                close={close}
                t={t}
            />
        </Fragment>;
    }

    const renderSPBMetroMap = () => {
        return <Fragment>
            <div className='metro'>
                <SpbMetro
                    initialScale={document.body.clientWidth > 1500 ? 1200 / 1330 : document.body.clientWidth / 1330}
                    width={1200}
                    value={props.input.value || []}
                    onChange={props.onChange} />
            </div>
            <Button type='primary' onClick={close}>{ t('form.ok') }</Button>
        </Fragment>;
    }

    const renderMap = {
        msc: renderMSCMetroMap,
        spb: renderSPBMetroMap
    };

    return props.mapType ? (
        <Fragment>
            <div className='ant-col ant-form-item-label'>
                <label>{ t('form.metroStation') }</label>
            </div>
            <Row className='button-select metro-select' gutter={10}>
                <Col xs={18}>
                    <MetroSelect
                        options={METRO_OPTIONS[props.mapType]}
                        namePath='title'
                        searchable={true}
                        isMulti={true}
                        {...props}
                        renderLabel={renderMetroLabel}
                        renderMultiLabel={renderMetroTag}
                        autocomplete
                    />
                </Col>
                <Fragment>
                    <Col xs={6} className='text-center'>
                        <Button onClick={() => setMapOpen(true)}>
                            { t('form.chooseOnMap') }
                        </Button>
                    </Col>
                    <Modal
                        mask={props.mask}
                        closable={false}
                        visible={mapOpen}
                        onCancel={close}
                        width='80%'
                        footer={null}
                        className='metro-modal'
                        destroyOnClose={true}
                        maskClosable={false}
                    >
                        { renderMap[props.mapType]() }
                    </Modal>
                </Fragment>
            </Row>
        </Fragment>
    ) : null;
}

MetroSelectComponent.defaultProps = {
    mask: true
};

const MetroSelectComponentMetroType = props => {
    const cityDictionary = useCompanyCodeSwr(CITY_DICTIONARY.stringify(), url => axios.get(url, {
        params: {
            relations: ['country']
        }
    }));

    const getMapType = () => {
        const dict = pathOr([], ['data', 'items'], cityDictionary).concat(props.citiesList || []);
        const cityName = pathOr('', ['name'], find(item => item && propEq('id', props.city, item), dict));

        return METRO_TYPES[cityName.toLowerCase()];
    }

    const mapType = getMapType();

    return <MetroSelectComponent {...props} mapType={mapType} />;
}

export default withFieldWrapper(MetroSelectComponentMetroType);

export const MetroLinesSelect = props => {
    const renderLineLabel = ({ value, label }) => {
        return (
            <div className='metro-line'>
                <span className={`line-badge line-${value}`}>{value}</span>
                { label }
            </div>
        );
    }

    return (
        <Select
            options={LINES}
            namePath='title'
            searchable={true}
            isMulti={true}
            {...props}
            renderLabel={renderLineLabel}
        />
    );
}
