import React from 'react';
import { Upload, message, Button } from 'antd';
import { EditOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import withUserCompany from '../../hocs/withUserCompany';
import { getUrl } from '../../../utils/http';
import { FILE } from '../../../constants/urls';
import { ImageWrapper, PreviewWrapper } from './ImageLoader';

const LogoLoader = props => {
    const { t } = useTranslation();

    const onChangeUpload = info => {
        switch (info.file.status) {
            case 'done':
                props.onChange(info.file.response.id);
                break;
            case 'error':
                message.error(t('form.avatarLoadError'));
                break;
            default:
                break;
        }
    }

    const removeFile = () => {
        props.onChange(null);
    }

    const { input: { value, name }, hidePreview, companyCode } = props;
    const uploadProps = {
        showUploadList: false,
        headers: {
            'company-code': companyCode,
        },
        accept: 'image/*',
        action: getUrl(FILE),
        onChange: onChangeUpload
    };

    return value ?
        <PreviewWrapper>
            { !hidePreview &&
                <ImageWrapper>
                    <img src={getUrl(FILE, { id: value })} width={200} alt={`${name}-preview`} />
                </ImageWrapper>
            }
            <Upload {...uploadProps}>
                <Button
                    icon={<EditOutlined />}
                    shape='circle' />
            </Upload>
            <Button
                icon={<DeleteOutlined />}
                type='danger'
                shape='circle'
                onClick={removeFile} />
        </PreviewWrapper> :
        <Upload {...uploadProps}>
            <Button>
                <UploadOutlined /> { t('form.loadImage') }
            </Button>
        </Upload>;
}

export default withFieldWrapper(withUserCompany(LogoLoader));
