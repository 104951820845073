import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { append, assocPath, map, remove } from 'ramda';

import ModalsContext from '../../contexts/ModalsContext';
import { mapIndexed } from '../../utils/ramdaAdditions';
import * as modalActions from '../../constants/actionTypes';

import BotModal from '../views/bot/BotModal';
import BotWidgetModal from '../views/bot/BotWidgetModal';
import VacancyWidgetModal from '../views/vacancy/VacancyWidgetModal';
import BotPrintModal from '../views/bot/BotPrintModal';
import ExperiumManualModal from '../views/vacancy/ExperiumManualModal';
import AvatarModal from '../views/dialog/AvatarModal';
import UserAdminModal from '../views/usersAdmin/UserAdminModal';
import CompanyModal from '../views/companies/CompanyModal';
import CompanyLicenseModal from '../views/companies/CompanyLicenseModal';
import LandingBuilderModal from '../views/landing/LandingBuilderModal';
import LandingCodeModal from '../views/landing/LandingCodeModal';
import BotCreateModal from '../views/bot/BotCreateModal';
import VacancyQuestionModal from '../views/vacancy/VacancyQuestionModal';
import VacancyQuestionsModal from '../views/vacancy/VacancyQuestionsModal';
import VacancyLanguageModal from '../views/vacancy/VacancyLanguageModal';
import VacancyTranslationModal from '../views/vacancy/VacancyTranslationModal';
import VacancyQuestionLanguageModal from '../views/vacancy/VacancyQuestionLanguageModal';
import UnitModal from '../views/units/UnitModal';
import PublicVacanciesPrintModal from '../views/vacancy/PublicVacanciesPrintModal';
import LandingLinkModal from '../views/landing/LandingLinkModal';
import QuestionPresetModal from '../views/forms/question/QuestionPresetModal';
import QrBannerModal from '../views/qr/QrBannerModal';
import QrModal from '../views/qr/QrModal';
import QrVacancyLinkModal from '../views/qr/QrVacancyLinkModal';
import QrUnitLinkModal from '../views/qr/QrUnitLinkModal';
import MapModal from '../views/vacancy/MapModal';
import VacancyBannerModal from '../views/banners/VacancyBannerModal';
import UnitBannerModal from '../views/banners/UnitBannerModal';
import DialogVacancyModal from '../forms/vacancyForm/DialogVacancyModal';
import TerminalModal from '../forms/vacancyForm/TerminalModal';
import VacancyLandingModal from '../forms/vacancyForm/VacancyLandingModal';
import FormPresetModal from '../views/forms/formPreset/FormPresetModal';
import ConfirmModal from '../Confirm';
import ClassifiersModal from '../views/settings/classifieers/ClassifiersModal';
import ClassifierLanguageModal from '../views/settings/classifieers/ClassifierLanguageModal';
import ClassifiersLanguageModal from '../views/settings/classifieers/ClassifiersLanguageModal';
import ClassifierOptionModal from '../views/settings/classifieers/ClassifierOptionModal';
import DomainModal from '../views/domains/DomainModal';
import CompanyDictionaryModal from '../views/settings/companyDictionaries/CompanyDictionaryModal';
import CompanyDictionaryItemModal from '../views/settings/companyDictionaries/CompanyDictionaryItemModal';
import CompanyDictionaryImportModal from '../views/settings/companyDictionaries/CompanyDictionaryImportModal';
import LandingLinkedVacanciesModal from '../views/landing/LandingLinkedVacanciesModal';
import LandingLinkVacanciesModal from '../views/landing/LandingLinkVacanciesModal';
import SelectClassifiersModal from '../forms/formComponents/SelectClassifiersModal';
import DomainWidgetModal from '../views/domains/DomainWidgetModal';
import DomainWidgetPreviewModal from '../views/domains/DomainWidgetPreviewModal';
import DocumentTypeModal from '../views/settings/DocumentTypeModal';
import PdaModal from '../views/applicant/PdaModal';
import UtmParamsModal from '../views/landing/UtmParamsModal';
import DomainLicenseInfoModal from '../views/domains/DomainLicenseInfoModal';
import FormSelectModal from '../views/forms/formPreset/FormSelectModal';
import UserImportModal from '../views/usersAdmin/UserImportModal';
import DictionaryLanguageModal from '../views/settings/dictionaries/DictionaryLanguageModal';
import DictionariesImportModal from '../views/settings/dictionaries/DictionariesImportModal';
import DictionaryItemModal from '../views/settings/dictionaries/DictionaryItemModal';
import ResponsibleModal from '../views/vacancy/ResponsibleModal';
import QueueEntryModal from '../views/settings/QueueEntryModal';
import CompanyLicenseLogModal from '../views/companies/CompanyLicenseLogModal';
import JuristicEntityModal from '../views/companies/JuristicEntityModal';
import PdaLanguageModal from '../views/settings/PdaLanguageModal';

const modalComponents = {
    [modalActions.OPEN_BOT_MODAL]: BotModal,
    [modalActions.OPEN_BOT_WIDGET_MODAL]: BotWidgetModal,
    [modalActions.OPEN_VACANCY_WIDGET_MODAL]: VacancyWidgetModal,
    [modalActions.OPEN_BOT_PRINT_MODAL]: BotPrintModal,
    [modalActions.OPEN_EXPERIUM_MANUAL_MODAL]: ExperiumManualModal,
    [modalActions.OPEN_AVATAR_MODAL]: AvatarModal,
    [modalActions.OPEN_USER_ADMIN_MODAL]: UserAdminModal,
    [modalActions.OPEN_COMPANY_MODAL]: CompanyModal,
    [modalActions.OPEN_COMPANY_LICENSE_MODAL]: CompanyLicenseModal,
    [modalActions.OPEN_LANDING_BUILDER_MODAL]: LandingBuilderModal,
    [modalActions.OPEN_LANDING_CODE_MODAL]: LandingCodeModal,
    [modalActions.OPEN_BOT_CREATE_MODAL]: BotCreateModal,
    [modalActions.OPEN_VACANCY_QUESTION_MODAL]: VacancyQuestionModal,
    [modalActions.OPEN_VACANCY_QUESTIONS_MODAL]: VacancyQuestionsModal,
    [modalActions.OPEN_VACANCY_LANGUAGE_MODAL]: VacancyLanguageModal,
    [modalActions.OPEN_VACANCY_QUESTION_LANGUAGE_MODAL]: VacancyQuestionLanguageModal,
    [modalActions.OPEN_UNIT_MODAL]: UnitModal,
    [modalActions.OPEN_PUBLIC_VACANCIES_PRINT_MODAL]: PublicVacanciesPrintModal,
    [modalActions.OPEN_VACANCY_BANNER_MODAL]: VacancyBannerModal,
    [modalActions.OPEN_VACANCY_TRANSLATION_MODAL]: VacancyTranslationModal,
    [modalActions.OPEN_UNIT_BANNER_MODAL]: UnitBannerModal,
    [modalActions.OPEN_LANDING_LINK_MODAL]: LandingLinkModal,
    [modalActions.OPEN_QUESTION_PRESET_MODAL]: QuestionPresetModal,
    [modalActions.OPEN_QR_BANNER_MODAL]: QrBannerModal,
    [modalActions.OPEN_QR_BUILDER_MODAL]: QrModal,
    [modalActions.OPEN_QR_VACANCY_LINK_MODAL]: QrVacancyLinkModal,
    [modalActions.OPEN_QR_UNIT_LINK_MODAL]: QrUnitLinkModal,
    [modalActions.OPEN_MAP_MODAL]: MapModal,
    [modalActions.OPEN_DIALOG_VACANCY_MODAL]: DialogVacancyModal,
    [modalActions.OPEN_TERMINAL_MODAL]: TerminalModal,
    [modalActions.OPEN_VACANCY_LANDING_MODAL]: VacancyLandingModal,
    [modalActions.OPEN_VACANCY_FORM_PRESET_MODAL]: FormPresetModal,
    [modalActions.OPEN_CONFIRM_MODAL]: ConfirmModal,
    [modalActions.OPEN_CLASSIFIER_MODAL]: ClassifiersModal,
    [modalActions.OPEN_CLASSIFIER_OPTION_MODAL]: ClassifierOptionModal,
    [modalActions.OPEN_CLASSIFIER_LANGUAGE_MODAL]: ClassifierLanguageModal,
    [modalActions.OPEN_CLASSIFIERS_LANGUAGE_MODAL]: ClassifiersLanguageModal,
    [modalActions.OPEN_DOMAIN_MODAL]: DomainModal,
    [modalActions.OPEN_COMPANY_DICTIONARY_MODAL]: CompanyDictionaryModal,
    [modalActions.OPEN_COMPANY_DICTIONARY_ITEM_MODAL]: CompanyDictionaryItemModal,
    [modalActions.OPEN_COMPANY_DICTIONARY_IMPORT_MODAL]: CompanyDictionaryImportModal,
    [modalActions.OPEN_LANDING_LINKED_VACANCIES_MODAL]: LandingLinkedVacanciesModal,
    [modalActions.OPEN_LANDING_LINK_VACANCIES_MODAL]: LandingLinkVacanciesModal,
    [modalActions.OPEN_SELECT_CLASSIFIERS_MODAL]: SelectClassifiersModal,
    [modalActions.OPEN_DOMAIN_WIDGET_MODAL]: DomainWidgetModal,
    [modalActions.OPEN_DOMAIN_WIDGET_PREVIEW_MODAL]: DomainWidgetPreviewModal,
    [modalActions.OPEN_DOCUMENT_TYPE_MODAL]: DocumentTypeModal,
    [modalActions.OPEN_PDA_HTML_MODAL]: PdaModal,
    [modalActions.OPEN_UTM_PARAMS_MODAL]: UtmParamsModal,
    [modalActions.OPEN_DOMAIN_LICENSE_INFO_MODAL]: DomainLicenseInfoModal,
    [modalActions.OPEN_FORM_SELECT_MODAL]: FormSelectModal,
    [modalActions.OPEN_USER_IMPORT_MODAL]: UserImportModal,
    [modalActions.OPEN_DICTIONARY_LANGUAGE_MODAL]: DictionaryLanguageModal,
    [modalActions.OPEN_DICTIONARIES_IMPORT_MODAL]: DictionariesImportModal,
    [modalActions.OPEN_DICTIONARY_ITEM_MODAL]: DictionaryItemModal,
    [modalActions.OPEN_RESPONSIBLE_MODAL]: ResponsibleModal,
    [modalActions.OPEN_QUEUE_ENTRY_MODAL]: QueueEntryModal,
    [modalActions.OPEN_COMPANY_LICENSE_LOG_MODAL]: CompanyLicenseLogModal,
    [modalActions.OPEN_JURISTIC_ENTITY_MODAL]: JuristicEntityModal,
    [modalActions.OPEN_OPD_LANGUAGE_MODAL]: PdaLanguageModal,
};

export default WrappedComponent => props => {
    const [modals, setModals] = useState([]);
    const location = useLocation();

    const open = (type, params) => {
        const modal = {
            type,
            params,
            visible: true
        };

        setModals(state => append(modal, state));
    }

    const closeModal = index => {
        setModals(state => assocPath([index, 'close'], true, state));
    }

    const hide = index => {
        setModals(state => assocPath([index, 'visible'], false, state));
    }

    const show = index => {
        setModals(state => assocPath([index, 'visible'], true, state));
    }

    const removeModal = index => {
        setModals(state => remove(index, 1, state));
    }

    const clean = () => setModals([]);

    useEffect(() => {
        clean();
    }, [location.pathname]);

    const getModalComponent = ({ type, params, visible, close }, index, length) => {
        const actions = map(action => action.bind(null, index), { close: closeModal, hide, show, remove: removeModal });
        const ModalComponent = modalComponents[type];

        const modalProps = {
            visible: index + 1 === length && visible && !close,
            width: 1024,
            onCancel: actions.close,
            afterClose: () => close && actions.remove()
        };

        return <ModalComponent {...actions} key={index} index={index} modal={modalProps} params={params} />;
    }

    return <ModalsContext.Provider value={{
        open,
        clean
    }}>
        <WrappedComponent {...props} />
        { mapIndexed((modal, index) => getModalComponent(modal, index, modals.length), modals) }
    </ModalsContext.Provider>;
}
