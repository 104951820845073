import React, { useContext, useState } from 'react';
import { Switch, message } from 'antd';
import { assocPath, forEach, is, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import ActionsContext from '../../contexts/ActionsContext';

const EnabledSwitch = props => {
    const { t } = useTranslation();
    const [pending, setPending] = useState(false);
    const { item, path, SwitchComponent, disabled, revalidateAction } = props;
    const Switcher = SwitchComponent || Switch;
    const actions = useContext(ActionsContext);

    const onChange = value => {
        if (!props.disabled) {
            setPending(true);
            props.action(assocPath(path.split('.'), value, item))
                .then(() => {
                    if (is(Array, revalidateAction)) {
                        forEach(action => actions.revalidateAction(action), revalidateAction);
                    } else {
                        actions.revalidateAction(revalidateAction);
                    }
                    setPending(false);
                    message.success(t('app.statusChangeSuccess'));
                })
                .catch(() => {
                    setPending(false);
                    message.success(t('app.statusChangeError'));
                });
        }
    }

    return (
        <Switcher
            onChange={onChange}
            checked={!!pathOr(false, path.split('.'), item)}
            loading={pending}
            path={path}
            disabled={disabled} />
    );
}

EnabledSwitch.defaultProps = {
    path: 'published',
    disabled: false
};

export default EnabledSwitch;
