import React, { Fragment } from 'react';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';

const SERVER_FORMATS = {
    'dd.MM.y': 'DD.MM.YYYY',
    'y': 'YYYY',
};

const DateTime = props => {
    const { format } = props;
    const date = props.time || props.date;

    const dateString = moment(date).format(SERVER_FORMATS[format] || 'DD.MM.YYYY');
    const timeString = moment(date).format('HH:mm');

    return (
        <div className='date-time-string'>
            { date && (
                <Fragment>
                    <CalendarOutlined /> { dateString }
                </Fragment>
            )}
            { props.time && (
                <span className='time'>{ timeString }</span>
            )}
        </div>
    );
}

export default DateTime;
