import React from 'react';
import { Modal } from 'antd';

import LandingBuilderModalContent from './LandingBuilderModalContent';

const LandingBuilderModal = props => {
    const { modal } = props;

    return <Modal
        {...modal}
        className='landing-modal'
        title={null}
        width='100%'
        footer={null}
        closable={false}
        destroyOnClose>
        <LandingBuilderModalContent {...props} />
    </Modal>;
}

export default LandingBuilderModal;
