import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import CompanyForm from '../../forms/CompanyForm';
import { postCompanyHandler, putCompanyHandler } from '../../../actions/handlers';
import ActionsContext from '../../../contexts/ActionsContext';
import { COMPANY } from '../../../constants/urls';

const CompanyModal = props => {
    const { t } = useTranslation();
    const { modal, params, close } = props;
    const { revalidateAction } = useContext(ActionsContext);

    return (
        <Modal
            {...modal}
            title={params ? t('company.editCompany') : t('company.addCompany')}
            footer={null}
        >
            <CompanyForm
                item={params}
                formAction={params ? putCompanyHandler : postCompanyHandler}
                onSuccess={() => {
                    revalidateAction(COMPANY.stringify());

                    if (params) {
                        message.success(t('company.editSuccess'));
                        close();
                    }
                }}
            />
        </Modal>
    );
}

export default CompanyModal;
