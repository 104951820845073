import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';

const PasswordRecoveryForm = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Field
                name='email'
                component={Input}
                icon={<MailOutlined />}
                placeholder='Email' />
            <SubmitButton>
                { t('guest.passwordRecovery') }
            </SubmitButton>
        </Fragment>
    );
}

const validationSchema = () => yup.object().shape({
    email: yup.string().email().nullable().required(),
});

export default withFormWrapper(PasswordRecoveryForm, {
    mapPropsToValues: () => ({}),
    validationSchema
});
