import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { IS_ADMIN, NOT_ADMIN, ONLY_ADMIN, ONLY_MANAGER } from '../constants/roles';
import SecureRoute from './SecureRoute';
import NoMatch from './NoMatch';
import AlreadyLogin from './layout/user/AlreadyLogin';

import Vacancies from './views/vacancy/Vacancies';
import Vacancy from './views/vacancy/Vacancy';
import Applicants from './views/applicant/Applicants';
import Applicant from './views/applicant/Applicant';
import Profile from './views/profile/Profile';
import DialogList from './views/dialog/DialogList';
import Dialog from './views/dialog/Dialog';
import Settings from './views/settings/Settings';
import SettingsBots from './views/settings/SettingsBots';
import SettingsForms from './views/forms/SettingsForms';
import Domains from './views/domains/Domains';
import SettingsDictionaries from './views/settings/SettingsDictionaries';
import Admins from './views/usersAdmin/Admins';
import Companies from './views/companies/Companies';
import JuristicEntities from './views/companies/JuristicEntities';
import Units from './views/units/Units';
import VacancyCreate, { ExperiumVacancyEdit, ExperiumVacancySubmitted } from './views/vacancy/VacancyCreate';
import QrBanners from './views/qr/QrBanners';
import CompanyCreate from './views/companies/CompanyCreate';
import CallRequests from './views/call/CallRequests';
import SmsLogs from './views/call/SmsLogs';
import FormPreset from './views/forms/formPreset/FormPreset';
import UsersAdmin from './views/usersAdmin/UsersAdmin';
import BotsSettings from './views/bot/BotsSettings';
import CompanyDictionaryItem from './views/settings/companyDictionaries/CompanyDictionaryItem';
import Landings from './views/landing/Landings';
import AuthLog from './views/auth/AuthLog';
import Dictionaries from './views/settings/dictionaries/Dictionaries';
import QuestionsPresets from './views/forms/question/QuestionsPresets';
import Reports from './views/reports/Reports';
import UserAdmin from './views/usersAdmin/UserAdmin';

const UserRoutes = () => (
    <Switch>
        <SecureRoute path='/' exact component={Vacancies} roles={NOT_ADMIN} />
        <SecureRoute path='/vacancy/create' component={VacancyCreate} roles={NOT_ADMIN} />
        <SecureRoute path='/vacancy/:id/:tab?' component={Vacancy} roles={NOT_ADMIN} />
        <SecureRoute path='/applicants/:id' component={Applicant} roles={NOT_ADMIN} />
        <SecureRoute path='/applicants' component={Applicants} roles={NOT_ADMIN} />
        <SecureRoute path='/forms/formPresets/add' component={FormPreset} roles={NOT_ADMIN} />
        <SecureRoute path='/forms/formPresets/:id' component={FormPreset} roles={NOT_ADMIN} />
        <SecureRoute path='/domains' component={Domains} roles={NOT_ADMIN} />
        <SecureRoute path='/forms' component={SettingsForms} roles={NOT_ADMIN} />
        <SecureRoute path='/settings/bots' component={SettingsBots} roles={ONLY_MANAGER} hideFunctionalLicense />
        <SecureRoute path='/settings/dictionaries/companyDictionaries/:id' component={CompanyDictionaryItem} roles={ONLY_MANAGER} />
        <SecureRoute path='/settings/dictionaries' component={SettingsDictionaries} roles={ONLY_MANAGER} />
        <SecureRoute path='/settings' component={Settings} roles={ONLY_MANAGER} />
        <SecureRoute path='/reports' component={Reports} roles={IS_ADMIN} />
        <SecureRoute path='/profile' component={Profile} />
        <SecureRoute path='/dialogs/:bot/messages/:dialog' component={Dialog} roles={NOT_ADMIN} fullLicense hideFunctionalLicense />
        <SecureRoute path='/dialogs' component={DialogList} roles={NOT_ADMIN} fullLicense hideFunctionalLicense />
        <SecureRoute path='/admins' component={Admins} roles={ONLY_ADMIN} withoutCompany />
        <SecureRoute path='/companies/create' component={CompanyCreate} roles={ONLY_ADMIN} withoutCompany />
        <SecureRoute path='/companies/entities' component={JuristicEntities} roles={ONLY_ADMIN} withoutCompany redirectTo='/'/>
        <SecureRoute path='/companies' component={Companies} roles={ONLY_ADMIN} withoutCompany redirectTo='/'/>
        <SecureRoute path='/units' component={Units} roles={NOT_ADMIN} hideFunctionalLicense />
        <SecureRoute path='/banners' component={QrBanners} roles={NOT_ADMIN} fullLicense hideFunctionalLicense />
        <SecureRoute path='/callrequests' component={CallRequests} roles={NOT_ADMIN} fullLicense hideFunctionalLicense />
        <SecureRoute path='/sms' component={SmsLogs} roles={ONLY_ADMIN} withoutCompany />
        <SecureRoute path='/users' component={UsersAdmin} roles={ONLY_ADMIN} withoutCompany />
        <SecureRoute path='/user/:id/:tab?' component={UserAdmin} roles={IS_ADMIN} />
        <SecureRoute path='/auth' component={AuthLog} roles={ONLY_ADMIN} withoutCompany />
        <SecureRoute path='/bots' component={BotsSettings} roles={ONLY_ADMIN} withoutCompany fullLicense />
        <SecureRoute path='/landings' component={Landings} roles={NOT_ADMIN} fullLicense />
        <SecureRoute path='/dictionaries/:type?' component={Dictionaries} roles={ONLY_ADMIN} withoutCompany />
        <SecureRoute path='/questions' component={QuestionsPresets} roles={ONLY_ADMIN} withoutCompany />
        <Route path='/registration/:id' component={AlreadyLogin} />
        <Route path='/password-recovery/:token' component={AlreadyLogin} />

        <Route component={NoMatch} />
    </Switch>
);

export const ExperiumUserRoutes = () => (
    <Switch>
        <SecureRoute path='/experium/vacancy/create' component={VacancyCreate} roles={NOT_ADMIN} redirectTo='/experium' />
        <SecureRoute path='/experium/vacancy/edit/:id' component={ExperiumVacancyEdit} roles={NOT_ADMIN} redirectTo='/experium' />
        <SecureRoute path='/experium/vacancy/:id' component={ExperiumVacancySubmitted} roles={NOT_ADMIN} redirectTo='/experium' />
        <Route path='/experium' component={() => 'Нет прав'} />
    </Switch>
);

export default UserRoutes;
