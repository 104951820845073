import React from 'react';
import { Field } from 'react-final-form';
import { compose, pick, path, not } from 'ramda';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Select from './formComponents/Select';
import withFormWrapper from '../hocs/withFormWrapper';
import withCompanyCodeContext from '../hocs/withCompanyCodeContext';
import { COMPANY } from '../../constants/urls';
import useSWR from 'swr';

const CompanyCodeForm = props => {
    const { t } = useTranslation();
    const history = useHistory();
    const fieldSettings = !props.userCompanies ? {
        actionUrl: COMPANY.stringify(),
        actionUrlParams: {
            pagination: {
                offset: 0, limit: 0
            }
        },
        swr: useSWR
    } : {
        options: props.userCompanies,
    };

    const onChangeCode = code => {
        props.changeCompanyCode(code);
        !code && history.push('/companies');
    }

    return <Field
        name='companyCode'
        component={Select}
        placeholder={t('company.chooseCompany')}
        onChange={onChangeCode}
        namePath='name'
        valuePath='code'
        isOptionDisabled={compose(not, path(['enabled']))}
        allowClear
        searchable
        {...fieldSettings}
        disableClear
    />;
}

export default withCompanyCodeContext(withFormWrapper(CompanyCodeForm, {
    autoComplete: 'off',
    mapPropsToValues: pick(['companyCode'])
}));
