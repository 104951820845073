import React, { useContext, useState } from 'react';
import { Button, message, Popconfirm, Select } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, OrderedListOutlined, DownloadOutlined } from '@ant-design/icons';
import { assoc, filter, includes, path } from 'ramda';
import { useTranslation } from 'react-i18next';

import TableList from '../../../table/TableList';
import withUserCompany from '../../../hocs/withUserCompany';
import { COMPANY_DICTIONARY } from '../../../../constants/urls';
import { deleteCompanyDictionaryHandler } from '../../../../actions/handlers';
import ActionsContext from '../../../../contexts/ActionsContext';
import ModalsContext from '../../../../contexts/ModalsContext';
import { OPEN_COMPANY_DICTIONARY_IMPORT_MODAL, OPEN_COMPANY_DICTIONARY_MODAL } from '../../../../constants/actionTypes';
import { AVAILABLE_LANGUAGES } from '../../../../constants/dictionary';

const CompanyDictionaries = props => {
    const { t } = useTranslation();
    const actionUrl = COMPANY_DICTIONARY.stringify();
    const [language, setLanguage] = useState(['en', 'ua']);
    const { revalidateAction } = useContext(ActionsContext);
    const modals = useContext(ModalsContext);

    const getLanguageColumn = index => {
        return {
            title: <div>
                { t('settings.chooseLanguage') }
                <Select
                    value={language[index]}
                    onChange={lang => {
                        const value = assoc(index, lang, language);
                        setLanguage(value);
                    }}
                    style={{ width: 90, marginLeft: 10 }}>
                    { filter(lang => lang === language[index] || !includes(lang, language), AVAILABLE_LANGUAGES).map(lang =>
                        <Select.Option key={`language-${lang}-${index}`} value={lang}>
                            { lang.toUpperCase() } <span className={`flag flag-${lang}`} />
                        </Select.Option>
                    )}
                </Select>
            </div>,
            key: 'translation',
            render: path(['translations', 'name', language[index]])
        };
    }

    const getColumns = () => {
        return [
            {
                title: t('settings.dictionaryName'),
                dataIndex: 'name',
                key: 'name',
                render: (title, item) => (
                    <span className='span-link' onClick={() => props.history.push(`/settings/dictionaries/companyDictionaries/${item.id}`)}>
                        { title }
                    </span>
                ),
            },
            getLanguageColumn(0),
            getLanguageColumn(1),
            {
                title: t('settings.parentDictionary'),
                key: 'parent',
                render: (item) => {
                    const parentName = path(['_relations', 'parent', 'name'], item);

                    return (
                        <span className='span-link'>
                            { parentName }
                        </span>
                    );
                },
            },
            {
                key: 'actions',
                width: 150,
                render: (item, _, index) => (
                    <div className='section-buttons'>
                        <Button
                            id={`dictionary-items-btn-${index}`}
                            icon={<OrderedListOutlined />}
                            onClick={() => props.history.push(`/settings/dictionaries/companyDictionaries/${item.id}`)}
                        />
                        <Button
                            id={`edit-item-btn-${index}`}
                            className={`edit-item-btn`}
                            icon={<EditOutlined />}
                            onClick={() => modals.open(OPEN_COMPANY_DICTIONARY_MODAL, { item })}
                        />
                        <Popconfirm
                            title={<div className='table-confirm-title'>
                                <p>{t('settings.deleteDictionaryAttention')}</p>
                                <p>{t('settings.deleteDictionaryMessage', { name: `${item.name || ''}` })}</p>
                                {t('settings.deleteDictionaryConfirm', { name: `${item.name || ''}` })}
                            </div>}
                            okText={t('settings.yes')}
                            cancelText={t('settings.no')}
                            onConfirm={() => deleteCompanyDictionaryHandler(item.id)
                                .then(() => {
                                    message.success(t('settings.deleteDictionarySuccess'))
                                    revalidateAction(actionUrl);
                                }).catch(() => message.error(t('settings.deleteDictionaryError')))
                            }
                            placement='left'
                        >
                            <Button
                                id={`delete-item-btn-${index}`}
                                className={`delete-item-btn`}
                                type='danger'
                                icon={<DeleteOutlined />}
                            />
                        </Popconfirm>
                    </div>
                ),
            }
        ];
    }

    const getButtons = () => {
        return <div className='company-dictionaries-buttons'>
            <Button
                icon={<DownloadOutlined />}
                onClick={() => modals.open(OPEN_COMPANY_DICTIONARY_IMPORT_MODAL)}
            >
                { t('settings.importDictionary') }
            </Button>
            <Button
                id='add-item-btn'
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => modals.open(OPEN_COMPANY_DICTIONARY_MODAL)}
            >
                { t('settings.addDictionary') }
            </Button>
        </div>;
    }

    const { companyId } = props;

    return companyId ? (
        <TableList
            staticFilter={ { company: companyId } }
            headers={{
                'Accept-Language': 'ru',
            }}
            className='company-dictionaries-table'
            actionUrl={actionUrl}
            columns={getColumns()}
            buttons={getButtons()}
            relations={['parent']}
        />
    ) : null;
}

export default withUserCompany(CompanyDictionaries);
